.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 1em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  z-index: 10;
  max-width: 28em;
}

.goldMedal {
  color: var(--qrencia-gold-medal-color);
}

.silverMedal {
  color: var(--qrencia-silver-medal-color);
}

.bronzeMedal {
  color: var(--qrencia-bronze-medal-color);
}

.agGridMobile {
  display: none;
}

.agGridDesktop {
  display: block;
}
.championshipHeading {
  margin-top: 2em;
  margin-bottom: 0.5em;
  font-size: 1.2em;
  font-weight: 500 !important;
}
@media (max-width: 1500px) {
  .agGridMobile {
    display: block;
  }

  .agGridDesktop {
    display: none;
  }
}
