.rankSectionContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
}

.rankContainer {
  height: 100vh;
  width: 50%;
  display: flex;
  justify-content: center;
  background-color: var(--qrencia-white);
  position: relative;
  overflow: hidden;
}

.bgImageLeft {
  position: absolute;
  top: 2.5em;
  left: 2.5em;
  width: 21%;
}

.bgImageRight {
  position: absolute;
  bottom: 18em;
  right: 2em;
  width: 16%;
}

.rankBoardContainer {
  position: absolute;
  bottom: 0;
}

.rankBoardImage {
  width: 44em;
}

.rankNumberContainer {
  position: absolute;
  bottom: 16em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  font-size: 0.95em;
  font-weight: bold;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.rankNumber {
  font-size: 12em;
  font-weight: 800 !important;
  color: var(--performance-page-rank-color);
  line-height: 1;
}

.rankText {
  font-size: 1.2em;
  color: var(--performance-page-rank-content);
}

.rankContentContainer {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--performance-page-skyblue-bg);
  height: 100vh;
  padding: 2em 2em 2em 3em;
}

.rankContentContainerWidth {
  width: 90%;
}

.rankHolderName {
  color: var(--performance-page-rank-message-blue);
  padding-bottom: 1em;
}

.rankHolderName strong {
  color: var(--performance-page-rank-content-blue) !important;
}

.rankMessage {
  color: var(--performance-page-rank-message-blue);
  padding-bottom: 4em;
  font-size: 1.35em;
  font-weight: 450;
}

.letsAnalyzeText strong {
  color: var(--performance-page-rank-skyblue) !important;
  font-size: 1.2em;
}

.yourPerformanceText {
  font-size: 0.98em;
  color: var(--performance-page-rank-message-blue);
  font-weight: 420 !important;
}

@media (min-width: 1024px) {
  .letsAnalyzeText strong {
    font-size: 1.4em;
  }
}

@media (min-width: 768px) and (max-width: 1240px) {
  .rankBoardImage {
    width: 37.5em;
  }

  .rankNumberContainer {
    position: absolute;
    bottom: 14em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .bgImageRight {
    position: absolute;
    bottom: 18em;
    right: 10px;
    width: 16%;
  }
}

@media (max-width: 912.5px) {
  .rankSectionContainer {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .rankBoardImage {
    width: 23em;
  }

  .rankNumberContainer {
    position: absolute;
    bottom: 7em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .rankNumber {
    font-size: 8em;
    font-weight: 800 !important;
    color: var(--performance-page-rank-color);
    line-height: 1;
  }

  .bgImageLeft {
    position: absolute;
    top: 1em;
    left: 1em;
    width: 21%;
  }

  .bgImageRight {
    position: absolute;
    bottom: 1em;
    right: 10px;
    width: 16%;
  }

  .rankContainer {
    height: 52vh;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--qrencia-white);
    position: relative;
    overflow: hidden;
  }

  .rankContentContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--performance-page-skyblue-bg);
    height: 48vh;
    padding: 1em;
  }

  .rankMessage {
    padding-bottom: 1em;
    font-size: 1.2em;
  }
}

@media (min-width: 300px) and (max-width: 580.5px) {
  .rankBoardImage {
    width: 22em;
  }

  .rankNumberContainer {
    position: absolute;
    bottom: 7em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .rankNumber {
    font-size: 8em;
    font-weight: 800 !important;
    line-height: 1;
  }

  .rankHolderName {
    text-align: center;
  }

  .rankMessage {
    text-align: center;
    padding-bottom: 1.2em;
    font-size: 1em;
  }

  .letsAnalyzeText {
    text-align: center;
  }

  .yourPerformanceText {
    text-align: center;
  }
}
