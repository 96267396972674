.createObrFormContainer {
  padding: 1em;
  background-color: var(--qrencia-white);
  border-radius: 0.8em;
}

.showExpertObrCards {
  display: none;
}
.gridIcon {
  font-size: 1.3em !important;
  padding: 0% 4% 2.5% 4% !important;
  margin-top: -0.34em !important;
}
.showExpertObrGrid {
  display: block;
}

.expertObrSelectedSkillsList {
  font-size: 0.8em;
}

.expertObrcreateButton {
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  font-size: 0.8em;
  padding: 0.5em 0.6em;
  width: 6.5em;
}

.obrBackgroundColor {
  background-color: var(--qrencia-grey-section);
}

.expertObrcreateButton .spinner {
  animation: spin-animation 0.5s infinite;
}

.expertObrFormInputBox {
  margin: 0em 1em 0em 0em;
}

.expertObrEmailContainer {
  margin-top: -1em;
}

.expertObrCreateButtonContainer {
  margin-top: 0.9em;
}

.expertObrFormInputBox .expertObrEmail {
  height: 3em;
  font-size: 0.8em !important;
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
}

.submitExpertObrAdminBtn {
  font-size: 0.8em;
}

.obrGridHeader {
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  font-weight: bold;
}
.userRoleDropdown {
  margin-bottom: 2em;
  width: 20em;
}
.createObrFormContainer > .ag-theme-alpine .ag-cell,
.createObrFormContainer > .ag-theme-alpine .ag-header-cell {
  border: none;
}

.createObrFormContainer > .ag-theme-alpine .ag-row {
  border-bottom: none;
}

.activeObrCardsColumn {
  padding: 1em;
  font-size: 0.8em;
  color: var(--qrencia-dark-blue);
}

.obrGreenText {
  color: var(--qrencia-success-green);
}

.obrBlueDarkText {
  color: var(--qrencia-blue-darker-contrast);
}

.obrOrangeColorText {
  color: var(--qrencia-orange-button);
}

.obrBackButton {
  font-size: 1.5em;
  color: var(--qrencia-white);
  background-color: var(--qrencia-dark-blue);
  border-radius: 1em;
  cursor: pointer;
}

.obrRedText {
  color: var(--qrencia-error-red);
}
.remarksText {
  height: 5em !important;
  resize: none;
}

.obrGridTable {
  border: 1px solid var(--qrencia-grid-grey);
  border-collapse: collapse;
}

.obrGridTable td {
  padding: 8px;
}

.expertObrCards {
  border-left: 0.5em solid var(--qrencia-dark-blue);
  border-radius: 0.4em;
  color: var(--qrencia-dark-blue);
  padding: 0em 1em;
}
.obrDetailsColumContainer {
  margin-top: 1em;
}

.obrCardsContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0.6em;
  margin-top: 0.5em;
}
.switchContainer {
  margin-top: 1em;
  margin-right: 1.1em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}
.switchText {
  margin-right: 1em;
  font-size: 0.8em;
  font-weight: 500;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.switchText input {
  margin-left: 1em;
  margin-top: 0.35em;
}
.obrCardsButtonContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0.6em;
  margin: 0.5em 0;
}

.obrDetailsCommentRow {
  margin-top: 1.5em;
}

.obrCommentBox {
  font-size: 0.9em;
}

.obrGridActionBox {
  display: flex;
  gap: 0.2em;
  justify-content: space-around;
  margin-top: 0.9em;
}

.expertDetailsLabel {
  color: var(--qrencia-dark-blue);
  font-size: 0.8em;
  font-weight: 500 !important;
}

.expertDetailsText {
  font-size: 0.8em;
  width: 300px;
}
.expertEmailText {
  font-size: 0.8em;
  max-width: 500px;
  word-break: break-all !important;
}
.emailColumn {
  word-break: break-all !important;
}
.expertDetailsText > ul {
  margin-bottom: 0;
}
.expertDetailsListElement {
  list-style: none;
  font-size: 0.8em;
  display: inline;
}

.expertDetailsButtonRow {
  margin-top: 1.5em;
}

.expertDetailsButton {
  font-size: 0.8em;
  padding: 0.2em 0;
  width: 8em;
  border: none;
}

.expertDetailsGreenButton,
.expertDetailsGreenButton:active,
.expertDetailsGreenButton:focus,
.expertDetailsGreenButton:hover {
  font-size: 0.8em;
  padding: 0.2em 0;
  width: 8em;
  border: none;
  text-align: center;
  margin-bottom: 0.8em;
  background-color: var(--qrencia-success-green) !important;
}

.expertDetailsRedButton,
.expertDetailsRedButton:active,
.expertDetailsRedButton:focus,
.expertDetailsRedButton:hover {
  font-size: 0.8em;
  padding: 0.2em 0;
  width: 8em;
  border: none;
  text-align: center;
  /* margin-top: 0.5em; */
  background-color: var(--qrencia-error-red) !important;
}

.expertDetailsAmberButton,
.expertDetailsAmberButton:active,
.expertDetailsAmberButton:focus,
.expertDetailsAmberButton:hover {
  font-size: 0.8em;
  padding: 0.2em 0;
  width: 8em;
  border: none;
  text-align: center;
  margin-bottom: 0.8em;
  background-color: var(--qrencia-orange-button) !important;
}

.expertObrDownloadIcon {
  font-size: 1.2em;
  color: var(--qrencia-dark-blue);
  font-weight: 700;
  cursor: pointer;
}

.expertObrStatusFieldGreen {
  color: var(--qrencia-success-green);
}

.expertObrStatusFieldRed {
  color: var(--qrencia-error-red);
}
.editButtonRow {
  margin-top: 0.4em;
  text-align: right;
}
.editScreenButton {
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  font-size: 0.7em;
  padding: 0.3em 0.4em;
  width: 6.5em;
  margin-right: 0.5em;
}
.expertObrStatusFieldAmber {
  color: var(--qrencia-orange-button);
}

.expertObrStatusFieldBlue {
  color: var(--qrencia-dark-blue);
}

.expertObrStatusField {
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg);
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  font-weight: 600 !important;
}

span.expertObrStatusField {
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg) !important;
  color: var(--qrencia-text-black);
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  font-weight: 600 !important;
}

span.expertObrStatusFieldRed {
  color: var(--qrencia-error-red) !important;
  background-color: var(--qrencia-competition-info-bg) !important;
}

span.expertObrStatusFieldAmber {
  color: var(--qrencia-orange-button) !important;
  background-color: var(--qrencia-competition-info-bg) !important;
}

span.expertObrStatusFieldBlue {
  color: var(--qrencia-dark-blue) !important;
  background-color: var(--qrencia-competition-info-bg) !important;
}

span.expertObrStatusFieldGreen {
  color: var(--qrencia-success-green);
}

.obrDetailsHeaderColumContainer {
  margin-top: 1em;
}

.expertObrCardsIcon {
  color: var(--qrencia-dark-blue);
}

.expertObrCardTickIcon {
  color: var(--qrencia-success-green);
  font-size: 2em;
  margin-left: -3.5px;
}

.expertObrCardCrossIcon {
  color: var(--qrencia-error-red);
  font-size: 1em;
}

.obrSectionHeading {
  font-size: 1em;
  color: var(--qrencia-black);
}

.expertObrRowLeftBorder {
  /* border-left: 0.1em solid var(--qrencia-dark-blue); */
}

.obrDetailsColumHrContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0.5em;
  margin-top: 1.5em;
  color: var(--qrencia-dark-blue);
  font-size: 1em;
}

@media (min-width: 561px) and (max-width: 1290px) {
  .expertObrFormInputBox {
    margin: 0em 0em 0em 0em;
  }

  .expertObrFormInputBox .expertObrEmail {
    height: 2.5em;
    font-size: 0.8em !important;
  }

  .expertObrcreateButton {
    background-color: var(--qrencia-dark-blue);
    color: var(--qrencia-white);
    font-size: 0.8em;
    height: 2.5em;
  }
}

@media (min-width: 561px) and (max-width: 991px) {
  .showExpertObrCards {
    display: block;
  }

  .showExpertObrGrid {
    display: none;
  }
}

.adminSubmitObrFormRow {
  margin-top: 0.4em;
}

@media (max-width: 561.5px) {
  .expertObrFormInputBox {
    margin: 0em 0em 0em 0em;
    padding: 0em 0.2em !important;
  }
  .switchContainer {
    margin-top: 0.7em;
    margin-right: 1.1em;
    margin-bottom: 0em;
    display: flex;
    align-items: center;
  }
  .createObrFormContainer {
    padding: 0.5em 0.5em 1.4em 0.5em;
    background-color: var(--qrencia-white);
    border-radius: 0.8em;
  }

  .expertObrcreateButton {
    font-size: 0.7em;
  }

  .expertObrFormInputBox .expertObrEmail {
    font-size: 0.7em !important;
  }

  .showExpertObrCards {
    display: block;
  }

  .showExpertObrGrid {
    display: none;
  }

  .expertObrSelectedSkillsList {
    font-size: 0.7em;
  }

  .expertObrCards {
    padding: 0;
  }
}

.expertDetailsLabelObrId {
  font-size: 0.9em !important;
  color: var(--qrencia-dark-blue);
  font-weight: 500 !important;
}

.commentQuillEditor {
  margin-top: 1em;
}

.skillsList {
  padding: 0;
}

.marginTop1em {
  margin-top: 1em;
}

.loadMoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
}

.expertDetailsLinkLabel,
.expertDetailsLinkLabel:hover,
.expertDetailsLinkLabel:active,
.expertDetailsLinkLabel:focus {
  text-decoration: none;
  font-size: 0.75em !important;
  cursor: pointer;
  color: var(--qrencia-text-black);
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg);
  border-radius: 0.4em;
  /* width: 8em; */
  width: fit-content;
  text-align: center;
  padding: 1px 8px;
}
