.dashboardCards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--qrencia-white);
  padding: 1em;
  border-radius: 10px;
  margin-bottom: 3%;
}
.competitionCard {
  color: var(--qrencia-white);
  background-color: var(--qrenica-competition-differentiator-color);
}
.courseCard {
  color: var(--qrencia-white);
  background-color: var(--qrenica-course-differentiator-color);
}
.teachersCard {
  color: var(--qrencia-white);
  background-color: var(--qrencia-activity-snapshot-iconthree-bg);
}
.studentCard {
  color: var(--qrencia-white);
  background-color: var(--qrenica-nudge-differentiator-color);
}
.workshopCard {
  background-color: var(--qrencia-workshop-differentiator-color);
}
.countCards {
  margin-right: 2.2em;
  width: 10em;
  border: none;
  outline: none;
}
.count {
  color: var(--qrencia-white);
}
.cardCount {
  font-size: 1.8em;
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: -0.2em !important;
}
.cardTitle {
  font-size: 1em;
  color: var(--qrencia-white);
  font-weight: 500 !important;
  text-align: center;
}
@media (max-width: 1110px) {
  .countCards {
    margin-top: 1em;
  }
}
@media (max-width: 990px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 14em;
  }
}
@media (max-width: 584px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 11.2em;
  }
  .dashboardCards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 584px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 11.2em;
  }
  .dashboardCards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 494px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 16em;
  }
  .dashboardCards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
