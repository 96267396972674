.saveButton {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 100%;
  padding: 0.5em;
  text-align: right;
}

.saveButton>Button {
  font-size: 0.8em;
  padding: 0.5em 2em;
}

.tableHeadings {
  text-align: center;
}

.tableSubHeadings {
  background-color: var(--qrencia-light-blue-icon-background) !important;
  color: var(--qrencia-text-black) !important;
  font-size: 0.85em;
  font-weight: 600 !important;
  text-align: center;
  vertical-align: middle;
  min-width: 22vw;
}

.tableDesc {
  color: var(--qrencia-text-black) !important;
  font-size: 0.8em;
  font-weight: 600 !important;
  /* text-align: center; */
  vertical-align: middle;
}

.tableMarksInputField ::placeholder {
  font-size: 0.85em;
}

.skillContainerBox {
  min-height: 25vh;
  max-height: 35vh;
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  overflow: auto;
}

.skillContainer {
  margin-top: 1%;
  display: grid;
  align-items: center;
  justify-items: left;
  flex-wrap: wrap;
  overflow-y: auto;
  padding-bottom: 4%;
  grid-template-columns: repeat(3, 1fr);
}

.interestitem {
  background-color: var(--qrencia-obr-comment-bg);
  border-radius: 20px;
  padding: 4px 10px;
  margin: 8px;
  font-size: 0.7em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  font-weight: 500;
  text-align: center;
}

.selected {
  position: relative;
  border: 2px solid var(--qrencia-success-green);
  color: var(--qrencia-text-black);
  overflow: hidden;
  transition:
    border-color 0.3s ease,
    transform 0.3s ease;
}

.selected:hover {
  border-color: var(--qrencia-success-green);
  transform: scale(1);
}

.warningMessage {
  margin: 0.5em 0;
}

.cardContainer {
  margin-top: -10px;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
}

.headingIcons {
  margin-top: -0.1em;
  margin-right: 0.3%;
}

.warningMessage svg {
  font-size: 1.5em !important;
  padding: 0;
}

.warningMessage p {
  font-size: 0.9em !important;
  font-weight: 500 !important;
  padding: 0.5em;
}

/* Overlay using a pseudo-element */
.selected::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(125, 111, 234, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.marksForm {
  width: 40%;
  display: flex;
  justify-content: end;
  padding-bottom: 0.5em;
  color: var(--qrencia-grey-text);
  font-weight: 400 !important;
}

.selected:hover::before {
  opacity: 1;
}

.marksInputFeild {
  font-size: 0.6em;
  text-align: center;
}

.TotalMarks {
  text-align: center;
  font-size: 0.6em;
}

.remarksInput {
  font-size: 0.6em;
  margin-top: 0.7em;
  height: 70px;
}

.recommendationInput {
  font-size: 0.6em;
  height: 106px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.selected::before {
  animation: fadeIn 0.3s ease forwards;
}

.selected::before {
  animation: fadeIn 0.3s ease forwards;
}

.interestitem span {
  margin-right: 8px;
  font-size: 0.7em;
}

.tableCourseDesc {
  font-size: 0.6em;
}

.tableContainer {
  overflow-x: auto;
  width: 100%;
  margin: 1rem 0;
}

.markingTable {
  /* min-width: 100em; */
  width: 100%;
  margin-bottom: 0;
}

.errorText {
  font-size: 0.65em;
  color: var(--qrencia-error-red);
}

/* Table cells */
.markingTable td,
.markingTable th {
  vertical-align: middle;
}

@media (max-width: 900px) {
  .tableSubHeadings {
    font-size: 0.8em;
  }

  .tableDesc {
    font-size: 0.8em;
  }

  .tableCourseDesc {
    font-size: 0.7em;
  }
}

@media (max-width: 767px) {
  .tableSubHeadings {
    font-size: 0.7em;
  }

  .marksForm {
    font-size: 0.9em;
    padding-bottom: 0.6em;
  }

  .marksInputFeild {
    font-size: 0.7em;
  }

  .interestitem {
    font-size: 0.65em;
  }

  .tableDesc {
    font-size: 0.7em;
  }

  .tableCourseDesc {
    font-size: 0.6em;
  }

  .skillContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 540px) {
  .warningMessage svg {
    font-size: 1.2em !important;
  }

  .tableSubHeadings {
    font-size: 0.7em;
  }

  .tableCourseDesc {
    font-size: 0.6em;
  }

  .tableDesc {
    font-size: 0.7em;
  }

  .warningMessage p {
    font-size: 0.65em !important;
  }
}

@media (min-width: 540px) {

  .markingTable td:first-child,
  .markingTable th:first-child {
    position: sticky;
    left: -14px;
    background: var(--qrencia-white);
    z-index: 1;
  }
}

@media (max-width: 425px) {
  .interestitem {
    padding: 0px 8px;
    margin: 4px;
    font-size: 0.65em;
  }

  .tableSubHeadings {
    min-width: 25vw;
  }

  .skillContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}