.toastHeader {
  background-color: var(--qrencia-error-toast-header-bg);
  font-size: 0.9em;
}
.toastBodyContainer {
  color: var(--qrencia-error-red);
  background-color: var(--qrencia-white);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  font-size: 0.85em;
}
.toastBody {
  z-index: 100;
}
