.navigationLinks {
  display: contents;
}
.accordion-item.disabled .accordion-header {
  pointer-events: none;
  opacity: 0.6;
  color: var(--qrencia-icons) !important;
}
.qrenciaIdText {
  font-size: 0.8em;
}
.disabledComponent {
  pointer-events: none;
  opacity: 0.75;
  color: var(--qrencia-icons) !important;
  font-weight: 400 !important;
}
.disabledSideNavigationComponent {
  pointer-events: none;
  opacity: 0.6;
  color: var(--qrencia-icons) !important;
}
.sideNavigationItem {
  margin-top: 10% !important;
  text-decoration: none;
  /* padding: 6%; */
  cursor: pointer;
}

.sideNavigationItemMobileView {
  display: none;
}

.sideNavigationItem a {
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 0.76em;
  color: var(--qrencia-text-black);
  font-weight: 500;
  letter-spacing: 0.11px;
  padding: 6%;
}

.payoutButton {
  border-radius: 30px;
  font-size: 0.6em;
  padding: 2% 4%;
  font-weight: 500;
}

/* .sideNavigationItem:has(a.active) {
  background-color: var(--qrencia-dark-blue);
  border-radius: 26px;
  box-shadow: 0 5px 20px 0 rgba(0, 68, 255, 0.3);
} */

.sideNavigationItem>a.active {
  background-color: var(--qrencia-dark-blue);
  border-radius: 26px;
  box-shadow: 0 5px 20px 0 rgba(0, 68, 255, 0.3);
}

.sideNavigationItem a.active .linkIcons {
  color: var(--qrencia-white) !important;
}

.sideNavigationItem a.active .navigationLinks {
  color: var(--qrencia-white) !important;
}

.navigationLinks {
  font-weight: 500 !important;
}

.sideNavigationListSection {
  width: 100%;
  display: block;
}

.linkIcons {
  font-size: 1.2em;
  color: var(--qrencia-icons);
  margin-right: 12%;
  margin-left: 6%;
}
.linkIconsDropdown {
  /* margin-right: 13% !important; */
  margin-left: 6.2% !important;
}
.linkIconsSubMenu {
  font-size: 1.14em !important;
}

.adminSubMenuIcon {
  font-size: 1.38em !important;
}

.profileSection {
  width: 100%;
  display: flex;
  margin-block: auto;
  justify-content: left;
  margin-bottom: 15%;
  padding: 0% 0% 0% 4%;
  gap: 5px;
}

.profileImage {
  border-radius: 50px;
  width: 100%;
  display: block;
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.profileImageDiv {
  max-width: 33%;
  position: relative;
  height: fit-content;
  /* font-size: 1.2em; */
}
.ambassadorIcon {
  position: absolute;
  font-size: 0.8em;
  top: 88%;
  left: 88%;
  transform: translate(-50%, -50%);
  color: var(--qrencia-white);
  background-color: var(--qrencia-golden-color);
  border-radius: 50%;
  padding: 2px;
  border: 0.5px solid var(--qrencia-white);
}
.userNameDiv {
  width: 70%;
  margin-left: 2%;
  font-size: 0.92em;
  font-weight: 600 !important;
  align-self: center;
  margin-top: 3%;
}

.userName {
  font-weight: 600 !important;
}

.referButtonSection {
  display: none;
  margin-top: 8%;
}

.referButtonInnerSection {
  width: 70%;
  text-align: center;
}

.referButton {
  font-size: 0.7em;
  padding: 3% 2%;
}

.notificationSidebarSection {
  width: 30%;
  text-align: center;
  margin-block: auto;
}

.notificationSidebarImage {
  font-size: 1.2em;
}

.notificationSidebarBadge {
  position: absolute;
  border-radius: 9.8px;
  font-size: 0.5em;
  margin-left: -12px;
  height: 14px;
}

.sidebarToggleButton:focus,
.sidebarToggleButton.active {
  outline: none;
  box-shadow: none;
}

.userInitials {
  font-weight: 500;
  background-color: var(--qrencia-dark-blue);
  padding: 24% 0%;
  text-align: center;
  color: var(--qrencia-white);
  border-radius: 50px;
  height: 60px;
  width: 60px;
  display: block;
  font-size: 1.4em;
  position: relative;
}

.accordion-button {
  border: none;
  margin-top: 10% !important;
  text-decoration: none;
}

.accordion-item:last-of-type {
  border: none !important;
}

.accordion-button:focus {
  box-shadow: none;
  border: none;
}

.accordion-item:first-of-type .accordion-button {
  padding: 6%;
}

.accordion-button:active {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  font-size: 0.38em;
  font-weight: 500;
  background: transparent;
  border: none;
  color: var(--qrencia-text-black);
}

.accordion-item:last-of-type .accordion-button:not(.collapsed) {
  font-size: 0.38em;
  font-weight: 500;
  background: transparent;
  border: none;
  outline: none;
  color: var(--qrencia-text-black);
  box-shadow: none;
}

.accordion-body {
  padding: 0% 0% 0% 10%;
}

.accordion-button::after {
  background-size: 1.3em;
  font-weight: 500;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}

.footerSection {
  background: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
}

.footerSocialMediaLinkSection {
  padding: 0.5% 5.5% 0.5% 6%;
  display: flex;
}

.footerLowerSections {
  width: 33%;
  font-size: 0.75em;
  margin-top: 0.4%;
}

.footerIconsLowerSections {
  width: 33%;
  display: flex;
  justify-content: flex-end;
}

.socialLinkIcons {
  width: 90%;
  text-align: right;
  width: 25px;
  height: 25px;
}

.socialLinkIconSection {
  margin-left: 3%;
  margin-right: 3%;
}

.footerTermsPrivacyLowerSections {
  width: 33%;
  text-align: center;
}

.footerTermsPrivacyLink,
.footerTermsPrivacyLink:hover {
  color: var(--qrencia-white);
  font-size: 0.75em;
  text-decoration: none;
}

.sideNavigation {
  min-height: 100% !important;
}

@media (min-width: 1290px) {
  .sideNavigation {
    width: 100%;
    background-color: var(--qrencia-white);
    margin-bottom: 6%;
    border-radius: 32px 32px 0px 0px;
    align-items: flex-start;
    box-shadow: 0 3px 21px 0 rgba(100, 100, 100, 0.12);
  }

  .linkIconsSubMenu {
    font-size: 1.2em !important;
  }
}

@media (min-width: 991.5px) and (max-width: 1290px) {
  .sideNavigation {
    width: 100%;
    background-color: var(--qrencia-white);
    margin-bottom: 6%;
    border-radius: 32px 32px 0px 0px;
    align-items: flex-start;
    box-shadow: 0 3px 21px 0 rgba(100, 100, 100, 0.12);
  }

  .userInitials {
    padding: 22% 0%;
    height: 45px;
    width: 45px;
    font-size: 1em;
  }

  .profileImage {
    height: 45px;
    width: 45px;
  }
}

@media (min-width: 991.5px) {
  .sideNavigation {
    width: 100%;
    background-color: var(--qrencia-white);
    margin-bottom: 6%;
    border-radius: 32px 32px 0px 0px;
    align-items: flex-start;
    box-shadow: 0 3px 21px 0 rgba(100, 100, 100, 0.12);
  }

  .sideNavigationListSection {
    margin-top: 4%;
  }

  .sideNavigationContent {
    padding-left: 6%;
    padding-right: 6%;
  }
}

@media (max-width: 991.8px) {
  .referButtonSection {
    display: flex;
    margin-top: 8%;
  }
  .qrenciaIdText {
    font-size: 0.7em;
  }
}

@media (min-width: 1291px) and (max-width: 1419px) {
  .sideNavigationItem a {
    font-size: 0.68em !important;
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    font-size: 0.39em !important;
  }

  .accordion-item:last-of-type .accordion-button:not(.collapsed) {
    font-size: 0.37em !important;
  }
}

@media (min-width: 1120px) and (max-width: 1290px) {
  .userNameDiv {
    font-size: 0.85em;
    margin-top: 3%;
  }

  .linkIcons {
    margin-right: 6%;
  }
  .linkIconsDropdown {
    /* margin-right: 8% !important; */
    margin-left: 6.6% !important;
  }

  .sideNavigationItem a {
    font-size: 0.76em;
  }
}

@media (max-width: 1290px) {
  .sideNavigationItem a {
    padding: 6% 0%;
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    font-size: 0.36em;
  }

  .accordion-item:last-of-type .accordion-button:not(.collapsed) {
    font-size: 0.37em;
  }

  .accordion-item:first-of-type .accordion-button {
    padding: 6% 0%;
  }

  .accordion-button::after {
    background-size: 1.2em;
    font-weight: 500;
  }

  .accordion-button::after {
    position: inherit;
    top: 2px;
  }

  .accordion-button:not(.collapsed)::after {
    position: inherit;
    top: -4px;
  }
}

@media (min-width: 768px) and (max-width: 1120px) {
  .userNameDiv {
    font-size: 0.82em;
  }

  .userInitials {
    padding: 22% 0%;
    height: 42px;
    width: 42px;
    font-size: 1em;
  }

  .profileImage {
    height: 42px;
    width: 42px;
  }

  .sideNavigationItem a {
    font-size: 0.8em;
  }
}

@media (max-width: 990px) {
  .footerLowerSections {
    width: 40%;
    font-size: 0.65em;
    margin-top: 0.7%;
  }

  .footerTermsPrivacyLink,
  .footerTermsPrivacyLink:hover {
    font-size: 0.65em;
  }

  .footerTermsPrivacyLowerSections {
    width: 40%;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .linkIcons {
    margin-right: 8%;
  }

  .linkIconsDropdown {
    /* margin-right: 9% !important; */
    margin-left: 7% !important;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    font-size: 0.45em;
  }

  .accordion-item:last-of-type .accordion-button:not(.collapsed) {
    font-size: 0.45em;
  }
}

@media (min-width: 990px) and (max-width: 1120px) {
  .linkIcons {
    margin-right: 6%;
  }
  .linkIconsDropdown {
    margin-left: 7% !important;
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    font-size: 0.35em;
  }

  .accordion-item:last-of-type .accordion-button:not(.collapsed) {
    font-size: 0.35em;
  }

  .accordion-button::after {
    position: inherit;
    top: 2px;
  }

  .accordion-button:not(.collapsed)::after {
    position: inherit;
    top: -5px;
  }
}

@media (min-width: 990px) and (max-width: 1140px) {
  .sideNavigationItem a {
    font-size: 0.64em;
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    font-size: 0.33em;
  }

  .accordion-item:last-of-type .accordion-button:not(.collapsed) {
    font-size: 0.33em;
  }

  .accordion-button::after {
    position: inherit;
    top: 4px;
  }

  .accordion-button:not(.collapsed)::after {
    position: inherit;
    top: -5px;
  }
}

@media (min-width: 767px) and (max-width: 1290px) {
  .footerIconsLowerSections {
    margin-right: 0%;
  }

  .footerSocialMediaLinkSection {
    padding: 0.5% 2.5% 0.5% 3%;
    display: flex;
  }
}

@media (min-width: 767px) and (max-width: 1200px) {
  .socialLinkIconSection {
    margin-right: 0%;
  }

  .footerIconsLowerSections {
    margin-right: 0%;
  }
}

@media (max-width: 767px) {
  .footerSocialMediaLinkSection {
    padding: 3% 2% 3% 2%;
  }

  .footerLowerSections {
    width: 100%;
    text-align: center;
    font-size: 0.65em;
    margin-top: 0.4%;
  }

  .footerTermsPrivacyLink,
  .footerTermsPrivacyLink:hover {
    font-size: 0.65em;
  }

  .footerTermsPrivacyLowerSections {
    width: 100%;
    margin-top: 4%;
    padding-left: 0%;
  }

  .footerSocialMediaLinkSection {
    display: block;
  }

  .footerIconsLowerSections {
    width: 100%;
    margin-top: 4%;
    display: flex;
    justify-content: center;
  }

  .socialLinkIconSection {
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
  .linkIconsDropdown {
    /* margin-right: 14% !important; */
    margin-left: 7.5% !important;
  }
}

@media (min-width: 975px) and (max-width: 991.5px) {
  .sidebarToggleButton {
    top: -89px !important;
    margin-top: 2.5% !important;
    padding: 1.25% !important;
  }
}

@media (min-width: 945px) and (max-width: 975px) {
  .sidebarToggleButton {
    top: -86px !important;
    margin-top: 2.5% !important;
    padding: 1.2% !important;
  }
}

@media (min-width: 900px) and (max-width: 945px) {
  .sidebarToggleButton {
    top: -83px !important;
    margin-top: 2.5% !important;
    padding: 1.05% !important;
  }
}

@media (min-width: 875px) and (max-width: 900px) {
  .sidebarToggleButton {
    top: -79px !important;
    margin-top: 2.5% !important;
    padding: 1% !important;
  }
}

@media (min-width: 850px) and (max-width: 875px) {
  .sidebarToggleButton {
    top: -75px !important;
    margin-top: 2.5% !important;
    padding: 1% !important;
  }
}

@media (min-width: 805px) and (max-width: 850px) {
  .sidebarToggleButton {
    top: -76px !important;
    margin-top: 2.5% !important;
    padding: 1% !important;
  }
}

@media (min-width: 768px) and (max-width: 805px) {
  .sidebarToggleButton {
    top: -71px !important;
    margin-top: 2.5% !important;
    padding: 1% !important;
  }
}

@media (min-width: 818px) and (max-width: 822px) {
  .sidebarToggleButton {
    top: -75px !important;
    margin-top: 2.5% !important;
    padding: 1% !important;
  }
}

@media (min-width: 870px) and (max-width: 991.5px) {
  .sideNavigationListSection {
    width: 23% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.5px) {
  .sideNavigationItemMobileView {
    display: block;
  }

  .userInitials {
    padding: 22% 0%;
    height: 45px;
    width: 45px;
    font-size: 1em;
  }

  .profileImage {
    height: 45px;
    width: 45px;
  }

  .sideNavigationListSection {
    width: 25.8%;
    min-height: 100vh;
    background-color: var(--qrencia-white);
    margin-bottom: 6%;
    border-radius: 0px;
    align-items: flex-start;
    padding: 2%;
    position: absolute;
    z-index: 100;
    top: -73%;
    left: -1.5%;
    box-shadow: 0 21px 21px 0 rgba(100, 100, 100, 0.12);
  }

  .sidebarToggleButton {
    position: absolute;
    top: -89px;
    font-size: x-small;
    color: var(--qrencia-text-black);
    border: 1px solid var(--qrencia-text-black);
    margin-top: 2.5%;
    padding: 1.25%;
  }

  .userNameDiv {
    font-size: 0.9em;
  }

  .referButton {
    font-size: 0.7em;
    padding: 3%;
  }

  .notificationSidebarImage {
    font-size: 1.2em;
  }

  .notificationSidebarBadge {
    font-size: 0.5em;
    margin-left: -10px;
  }

  .sideNavigationItem a {
    font-size: 0.78em !important;
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    font-size: 0.39em !important;
  }

  .accordion-item:last-of-type .accordion-button:not(.collapsed) {
    font-size: 0.39em !important;
  }
}

@media (max-width: 767.5px) {
  .sideNavigationItemMobileView {
    display: block;
  }

  .userInitials {
    padding: 22% 0%;
    height: 45px;
    width: 45px;
    font-size: 1em;
  }

  .profileImage {
    height: 45px;
    width: 45px;
  }

  .sideNavigationListSection {
    width: 35%;
    min-height: 100vh;
    background-color: var(--qrencia-white);
    margin-bottom: 6%;
    border-radius: 0px;
    align-items: flex-start;
    padding: 4%;
    position: absolute;
    z-index: 100;
    top: -55%;
    left: -4%;
    box-shadow: 0 21px 21px 0 rgba(100, 100, 100, 0.12);
  }

  .sidebarToggleButton {
    position: absolute;
    top: -50px;
    font-size: x-small;
    color: var(--qrencia-text-black);
    border: 1px solid var(--qrencia-text-black);
    margin-top: 2.5%;
    padding: 1.5%;
  }

  .userNameDiv {
    font-size: 0.92em;
  }

  .referButton {
    font-size: 0.6em;
    padding: 3%;
  }

  .notificationSidebarImage {
    font-size: 1em;
  }

  .notificationSidebarBadge {
    font-size: 0.5em;
    margin-left: -10px;
  }

  .sideNavigationItem a {
    font-size: 0.77em;
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    font-size: 0.4em;
  }

  .accordion-item:last-of-type .accordion-button:not(.collapsed) {
    font-size: 0.4em;
  }
}

@media (min-width: 480px) and (max-width: 540px) {
  .sideNavigationListSection {
    width: 45%;
  }

  .sidebarToggleButton {
    top: -50px;
    margin-top: 1.5%;
    padding: 1.5%;
    font-size: xx-small;
  }
}

@media (min-width: 541px) and (max-width: 590px) {
  .sidebarToggleButton {
    top: -43px;
    margin-top: 0%;
    padding: 1.5%;
  }
}

@media (min-width: 590px) and (max-width: 668px) {
  .sidebarToggleButton {
    top: -56px;
    margin-top: 0%;
    padding: 1.5%;
  }
}

@media (min-width: 740px) and (max-width: 768px) {
  .sidebarToggleButton {
    top: -58px;
    margin-top: 0%;
    padding: 1.2%;
    position: absolute;
  }
}

@media (min-width: 690px) and (max-width: 740px) {
  .sidebarToggleButton {
    top: -59px;
    margin-top: 0%;
    padding: 1.2%;
    position: absolute;
  }
}

@media (min-width: 660px) and (max-width: 690px) {
  .sidebarToggleButton {
    top: -59px;
    margin-top: 0%;
    padding: 1.5%;
  }
}

@media (min-width: 620px) and (max-width: 659px) {
  .sidebarToggleButton {
    top: -51px !important;
    margin-top: 0%;
    padding: 1.5%;
  }
}

@media (min-width: 600px) and (max-width: 620px) {
  .sidebarToggleButton {
    top: -48px;
    margin-top: 0%;
    padding: 1.5%;
  }
}

@media (min-width: 580px) and (max-width: 600px) {
  .sidebarToggleButton {
    top: -55px;
    margin-top: 0%;
    padding: 1.5%;
  }
  .sideNavigationItem a {
    font-size: 0.85em;
  }
}

@media (min-width: 550px) and (max-width: 580px) {
  .sidebarToggleButton {
    top: -52px;
    margin-top: 0%;
    padding: 1.5%;
  }
  .sideNavigationItem a {
    font-size: 0.78em;
  }
}

@media (min-width: 510px) and (max-width: 550px) {
  .sidebarToggleButton {
    top: -48px;
    margin-top: 0%;
    padding: 1.5%;
  }
}

@media (min-width: 480px) and (max-width: 510px) {
  .sidebarToggleButton {
    top: -45px;
    margin-top: 0%;
    padding: 1.5%;
  }
}

@media (max-width: 480px) {
  .sideNavigationListSection {
    width: 50%;
  }

  .userInitials {
    padding: 19% 0%;
    height: 40px;
    width: 40px;
    font-size: 1em;
  }

  .sidebarToggleButton {
    top: -43px;
    margin-top: 0%;
    padding: 1.2%;
  }

  .sideNavigationListSection {
    top: -24%;
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    font-size: 0.48em;
  }

  .accordion-item:last-of-type .accordion-button:not(.collapsed) {
    font-size: 0.42em;
  }
  .sideNavigationItem a {
    font-size: 0.81em;
  }
}

@media (min-width: 389px) and (max-width: 394px) {
  .sidebarToggleButton {
    top: -40px;
    margin-top: 0%;
    padding: 1.2%;
  }
}

@media (max-width: 376px) {
  .sidebarToggleButton {
    top: -39px;
    margin-top: 0%;
    padding: 1.2%;
  }

  .sideNavigationListSection {
    top: -27%;
  }
}

@media (min-width: 400px) and (max-width: 415px) {
  .sidebarToggleButton {
    top: -42px;
    margin-top: 0%;
    padding: 1.2%;
  }
}

@media (min-width: 450px) and (max-width: 480px) {
  .sidebarToggleButton {
    top: -46px !important;
    margin-top: 0%;
    padding: 1.2%;
  }
}

@media (min-width: 535px) and (max-width: 541px) {
  .sidebarToggleButton {
    top: -50px !important;
  }
}

@media (max-width: 356px) {
  .sidebarToggleButton {
    top: -30px;
    margin-top: 0%;
    padding: 0.5%;
  }
}

@media (max-width: 380px) {
  .sideNavigationListSection {
    width: 65%;
  }
}

@media (max-width: 767px) {
  .socialLinkIcons {
    width: 90%;
    text-align: right;
    width: 20px !important;
    height: 20px !important;
  }
}

@media (max-width: 550px) {
  .socialLinkIcons {
    width: 90%;
    text-align: right;
    width: 25px !important;
    height: 25px !important;
  }
  .linkIconsDropdown {
    /* margin-right: 14% !important; */
    margin-left: 7.2% !important;
  }
}
@media (max-width: 400px) {
  .accordion-button::after {
    position: inherit;
    top: 4px;
  }

  .accordion-button:not(.collapsed)::after {
    position: inherit;
    top: -4px;
  }
  .linkIconsDropdown {
    margin-right: 13% !important;
    margin-left: 7% !important;
  }
}
@media (max-width: 380px) {
  .linkIconsDropdown {
    margin-right: 12.8% !important;
    margin-left: 7.4% !important;
  }
}
@media (min-width: 768px) and (max-width: 818px) {
  .footerSocialMediaLinkSection {
    padding: 0.5% 1% 0.5% 1%;
  }
  .footerLowerSections {
    width: 33%;
    font-size: 0.65em;
    margin-top: 0.9%;
  }
}
