.newsSection {
  background-color: var(--qrencia-white);
}

.newsCarouselSection {
  padding: 2% 11% 8% 11%;
}

.newsTitle {
  font-size: 1.1em;
  color: var(--qrencia-text-black);
  font-weight: 600;
}
.linkedText {
  text-decoration: none;
}
.newsDescription {
  font-size: 0.82em;
  color: var(--qrencia-text-black);
  font-size: 400 !important;
  display: -webkit-box;
  text-align: justify;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.newsImage {
  /* height: 280px; */
  border-radius: 7px 7px 0px 0px;
}

.newsCard {
  border: 2px solid var(--qrencia-cards-border);
  outline: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  min-height: 490px;
  border-radius: 8px;
}

.cursorPointer {
  cursor: pointer;
}
.cursorDefault {
  cursor: default;
}
.newsCardBody {
  padding: 2.5%;
}

.backgrounImageSection {
  padding-top: 6%;
  margin-bottom: -8%;
  text-align: right;
}

.backgroundDotsImage {
  width: 20%;
}

.titleText {
  color: var(--qrencia-text-black);
  font-weight: 600;
  line-height: 1.2em;
  margin-top: -28px;
  font-size: 2em;
  text-align: center;
}
.blueTriangleImage {
  margin-right: 256px;
  width: 2%;
}
.newsHeadingSection {
  text-align: center;
}
.cardImageSection {
  position: relative;
}
.videoPlayIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  height: 80px;
  width: 80px;
}
@media (max-width: 990px) {
  .newsTitle {
    font-size: 1em !important;
  }
}
@media (min-width: 990px) and (max-width: 1185px) {
  .titleText {
    font-size: 1.7em;
    margin-top: -23px;
  }
  .blueTriangleImage {
    margin-right: 225px;
    width: 2%;
  }
}

@media (min-width: 767px) and (max-width: 990px) {
  .titleText {
    font-size: 1.5em;
    margin-top: -20px;
  }
  /* .newsImage {
    height: 220px !important;
  } */
  .blueTriangleImage {
    margin-right: 185px;
    width: 2.5%;
  }
}
@media (min-width: 767px) and (max-width: 1290px) {
  .newsCarouselSection {
    padding: 2% 7.5% 5% 7.5%;
  }
  /* .newsImage {
    height: 240px;
  } */
  .newsCard {
    min-height: 450px;
  }
}

@media (max-width: 767px) {
  .newsCarouselSection {
    padding: 2% 1.5% 3.5% 1.5%;
  }
  .titleText {
    font-size: 1.5em;
    margin-top: -19px;
  }
  /* .newsImage {
    height: 190px;
  } */
  .blueTriangleImage {
    margin-right: 180px;
    width: 4%;
  }
  .newsDescription {
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .backgrounImageSection {
    padding-top: 0%;
  }
}
@media (max-width: 800px) {
  .newsCard {
    min-height: 380px;
  }
}
@media (min-width: 480px) and (max-width: 540px) {
  .titleText {
    font-size: 1.3em;
    margin-top: -21px;
  }

  .blueTriangleImage {
    margin-right: 168px;
    width: 5.5%;
  }
  .videoPlayIcon {
    height: 60px;
    width: 60px;
  }
}
@media (max-width: 480px) {
  .titleText {
    font-size: 1.12em;
    font-weight: 650;
  }
  .blueTriangleImage {
    margin-right: 148px;
    width: 6%;
  }
  .newsSection {
    background-color: var(--qrencia-white);
    padding: 4% 0% 4% 0%;
  }
  .newsDescription {
    font-size: 0.68em;
  }
  .newsTitle {
    font-size: 0.77em !important;
    margin-top: 1%;
  }
  .videoPlayIcon {
    height: 50px;
    width: 50px;
  }
}

@media (min-width: 768px) and (max-width: 819px) {
  .titleText {
    font-size: 1.35em;
  }
  .blueTriangleImage {
    margin-right: 175px;
    width: 3.5%;
  }
  .videoPlayIcon {
    height: 70px;
    width: 70px;
  }
}
