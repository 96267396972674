.heading {
  font-size: 1em;
  font-weight: 650;
  color: var(--qrencia-text-black);
  margin: 0.5em 0.5em 1em 0.5em;
}
.searchInputBox {
  width: 40%;
  border-radius: 4px;
}

.resetSearchIcon {
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 400;
  margin-left: -1.2em;
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-select-box-background);
}
.inputBoxContainer {
  padding: 0.8em 0em;
  margin-bottom: 1em;
  margin-top: 0.4em;
}
.publishedCardsSection {
  min-height: 55vh;
}
.showEditSection {
  min-height: 82vh;
}
