.dashboardContainer {
  background-color: var(--qrencia-white);
  width: 100%;
  padding: 1em;
  border-radius: 5px;
  margin-top: 1em;
  margin-bottom: 2em;
}
.dashboardCards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.cardTitle {
  font-size: 0.96em;
  color: var(--qrencia-white);
  font-weight: 500 !important;
  text-align: center;
}
.cardCount {
  font-size: 1.5em;
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: -0.2em !important;
}
.enrolmentCount {
  color: var(--qrencia-white);
}
.enrolmentCard {
  color: var(--qrencia-white);
  background-color: var(--qrenica-competition-differentiator-color);
  /* background: linear-gradient(to bottom right, #efb04e 0%, #f5d081 100%); */
}
.attemptCard {
  background-color: var(--qrenica-course-differentiator-color);
  /* background: linear-gradient(to bottom right, #34b4b3 0%, #66d1cf 100%); */
}
.submissionCard {
  background-color: var(--qrenica-nudge-differentiator-color);
  /* background: linear-gradient(to bottom right, #7d6fea 0%, #9698fe 100%); */
}
.absentCard {
  background-color: #ef7c4e;
  /* background: linear-gradient(to bottom right, #ef7c4e 0%, #ffb07d 100%); */
}
.campaignExpense {
  background-color: #eb984e;
  /* background: linear-gradient(to bottom right, #eb984e 0%, #f5b67a 100%); */
}
.expertWithBoq {
  background-color: #0a877a;
  /* background: linear-gradient(to bottom right, #4db6ac 0%, #543806 100%); */
}
.boqPercentageText {
  font-size: 0.7em !important;
  color: var(--qrencia-white) !important;
}
.notAttemptCard {
  background-color: var(--qrencia-workshop-differentiator-color);
  /* background: linear-gradient(to bottom right, #e77a7b 0%, #f6a8ab 100%); */
}
.competitionCard {
  background-color: var(--qrenica-competition-differentiator-color);
  /* background: linear-gradient(to bottom right, #efb04e 0%, #f5d081 100%); */
}
.workshopCard {
  background-color: var(--qrencia-workshop-differentiator-color);
  /* background: linear-gradient(to bottom right, #e77a7b 0%, #f6a8ab 100%); */
}
.nudgeCard {
  background-color: var(--qrenica-nudge-differentiator-color);
  /* background: linear-gradient(to bottom right, #7d6fea 0%, #9698fe 100%); */
}
.courseCard {
  background-color: var(--qrenica-course-differentiator-color);
  /* background: linear-gradient(to bottom right, #34b4b3 0%, #66d1cf 100%); */
}
.costCard {
  background-color: #95d3ff;
}
.feeCollectedCard {
  background-color: #00b75c;
  /* background: linear-gradient(to bottom right, #00b75c 0%, #66d2a3 100%); */
}
.ageGroupCard {
  background-color: #0099cc;
  /* background: linear-gradient(to bottom right, #0099cc 0%, #33b2d9 100%); */
}
.ageGroupCard_0 {
  background-color: #eb984e;
  /* background: linear-gradient(to bottom right, #eb984e 0%, #f5b181 100%); */
}
.ageGroupCard_1 {
  background-color: #03a9f4;
  /* background: linear-gradient(to bottom right, #03a9f4 0%, #67c9f7 100%); */
}
.ageGroupCard_2 {
  background-color: #4db6ac;
  /* background: linear-gradient(to bottom right, #4db6ac 0%, #82c8c2 100%); */
}
.ageGroupCard_3 {
  background-color: #ff8a65;
  /* background: linear-gradient(to bottom right, #ff8a65 0%, #ffab91 100%); */
}
.ageGroupCard_4 {
  background-color: #7d6fea;
  /* background: linear-gradient(to bottom right, #7d6fea 0%, #b39ddb 100%); */
}
.expertCard {
  background-color: var(--qrencia-dark-blue);
  /* background: linear-gradient(to bottom right, #224b7f 0%, #2d7fb6 100%); */
}
.revenueColor {
  background-color: var(--twitter-blue);
  /* background: linear-gradient(to bottom right, #1da1f2 0%, #7ddbf5 100%); */
}
.academyCard {
  background-color: var(--qrencia-academy-card-top-border);
}
.attemptCount {
  color: var(--qrencia-white);
}
.submissionCount {
  color: var(--qrencia-white);
}
.absentCount {
  color: var(--qrencia-white);
}
.notAttemptCount {
  color: var(--qrencia-white);
}
.detailsLabel {
  color: var(--qrencia-dark-blue);
  font-size: 0.85em;
  font-weight: 500 !important;
}
.attemptsPercentage {
  color: var(--qrenica-course-differentiator-color);
  /* background: linear-gradient(to bottom right, #34b4b3 0%, #66d1cf 100%); */
  height: 1.2em;
  font-size: 0.6em !important;
}
.detailsLinkLabel,
.detailsLinkLabel:hover,
.detailsLinkLabel:active,
.detailsLinkLabel:focus {
  text-decoration: none;
  font-size: 0.75em !important;
  cursor: pointer;
  color: var(--qrencia-text-black);
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg);
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  padding: 1px 8px;
}
.countCards {
  margin-right: 1.2em;
  margin-top: 1.2em;
  min-width: 8.5em;
  border: none;
  outline: none;
  width: fit-content;
}
.insightCards {
  margin-right: 1.2em;
  margin-top: 1.2em;
  min-width: 18em;
  border: none;
  outline: none;
  width: fit-content;
}
.detailsText {
  font-size: 0.85em;
}
.detailsColumContainer {
  margin-top: 1em;
}
.percentageSpan {
  font-size: 0.5em;
  color: var(--qrencia-white);
  font-weight: 500 !important;
}
.dateFilterText {
  cursor: pointer;
  background-color: var(--qrencia-date-filter-card-color);
  color: var(--qrencia-white);
  border-radius: 5px;
  text-align: center;
  padding: 0.2em 1em;
  min-width: 11em;
  width: fit-content;
  font-size: 0.78em;
  line-height: 2.6em;
  height: 3em;
  margin-top: 1em;
}
.dateFilterTextDisabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.obrGridHeader {
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  font-weight: bold;
}
@media (max-width: 990px) {
  .countCards {
    margin-right: 2em;
    margin-top: 1.2em;
    min-width: 10em;
  }
}
@media (max-width: 584px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    min-width: 10em;
  }
  .dashboardCards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 584px) {
  .countCards {
    margin-right: 2em;
    margin-top: 1.2em;
    min-width: 10em;
  }
  .dashboardCards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .cardTitle {
    font-size: 0.92em;
  }
  .cardCount {
    font-size: 1.4em;
    font-weight: 600 !important;
    text-align: center;
    margin-bottom: -0.2em !important;
  }
}

@media (max-width: 518px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 16em;
  }
  .dashboardCards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .suggestiveCompetitionSearchComponent > button {
    font-size: 0.7em;
    padding: 0.5em 1em;
  }
  .selectButton {
    height: 2.4em;
    font-size: 0.6em;
    line-height: 1em;
  }
}
