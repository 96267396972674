.modalYesButton,
.modalYesButton:hover,
.modalYesButton:active,
.modalYesButton:focus {
  font-size: 0.75em;
  width: 6.4em;
  background-color: var(--qrencia-success-green) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalNoButton,
.modalNoButton:hover,
.modalNoButton:active,
.modalNoButton:focus {
  font-size: 0.75em;
  width: 6.4em;
  background-color: var(--cancel-red-button) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalCloseButton,
.modalCloseButton:hover,
.modalCloseButton:active,
.modalCloseButton:focus {
  font-size: 0.75em;
  width: 6.4em;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}
.offlinePaymentButton,
.offlinePaymentButton:hover,
.offlinePaymentButton:active,
.offlinePaymentButton:focus {
  font-size: 0.75em;
  width: 6.4em;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}
