.formContainer {
  margin-top: 1em;
  width: 100%;
  padding: 1em;
  background: var(--qrencia-white);
  border-radius: 5px;
}
.recipient {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1.5px solid var(--qrencia-input-bottom-border) !important;
  max-height: 10em;
  overflow-y: scroll;
}
.recipient::-webkit-scrollbar {
  display: none;
}
.recipient::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3) !important;
}

.recipientFocus {
  border-bottom: 1.5px solid var(--qrencia-select-box-border) !important;
}
.emailSubject {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.emailTo {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.emailLabels {
  color: var(--qrencia-grey-text);
  font-size: 0.9em;
  width: 6.5em;
}
.toLabel {
  margin-top: 0.6em;
}
.recipient > input {
  width: auto;
  flex-grow: 1;
}
.emailSubject > input,
.recipient > input,
.emailSubject > input:focus,
.recipient > input:focus {
  outline: none !important;
  border: none !important;
  background-color: var(--qrencia-white);
  border-bottom: 1.5px solid var(--qrencia-input-bottom-border) !important;
}
.recipient > input {
  margin-top: -0.4em;
  border-bottom: none !important;
}
.recipient > input:focus {
  border-bottom: none !important;
}
.emailSubject > input:focus {
  border-bottom: 1.5px solid var(--qrencia-select-box-border) !important;
}
.emailBody {
  margin-top: 1em;
}
.editor {
  margin-bottom: 1.5em;
}
.editor .ql-toolbar.ql-snow,
.editor .ql-container.ql-snow {
  border-top: 0px;
  height: 20em !important;
}
.editor > .quill {
  border: none !important;
}
.iconSection {
  margin-bottom: 2em;
  width: 100%;
  /* background-color: #d6eaf8; */
}
.iconSection > button {
  /* background-color: transparent; */
  outline: none;
  border: none;
  margin: 1.5em 1em 0em 0em;
  cursor: pointer;
  font-size: 0.8em;
  padding: 0.5em 1em;
}
.iconSection > button:hover {
  background-color: var(--qrencia-text-black);
  outline: none;
  border: none;
}
.iconSection > button > svg {
  margin-right: 0.2em;
}
.attachButton {
  position: relative;
  margin-right: 10em;
}
.attachButtonInput {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.filePreview {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75em;
  font-weight: 500;
  background-color: var(--qrencia-mail-file-preview-bg);
  width: max-content;
  max-width: 90%;
  margin: 0.5em 0em;
  padding: 0.2em 0.5em;
}
.filePreviewName {
  margin-right: 1em;
  color: var(--qrencia-attached-file-name);
  flex-grow: 1;
}
.filePreviewIcon {
  cursor: pointer;
  color: var(--qrencia-error-red);
  font-weight: 600;
}
.disableSend {
  opacity: 0.7 !important;
  background-color: var(--qrencia-dark-blue) !important;
  cursor: not-allowed;
}
@media (max-width: 480px) {
  .emailLabels {
    font-size: 0.78em;
    width: 6em;
  }
}
