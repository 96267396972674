.createCompContainer {
  width: 100%;
  margin: 0em auto;
  background-color: var(--qrencia-white);
  padding: 1em;
  border-radius: 10px;
  height: fit-content;
  margin-bottom: 2em;
  box-shadow: var(--qrencia-template-box-shadow);
}
.collapseContainer {
  border: 1px solid var(--qrencia-grey-border);
  border-radius: 2px;
  margin: 0em 0.2em 0.5em 0.2em;
}
.headerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--card-grey-background);
  height: 3em;
  padding: 1em 2em;
  font-size: 1em;
  font-weight: 500;
}
.heading {
  font-size: 0.9em;
}
.createCompHeading {
  font-size: 1em;
  font-weight: 650;
  color: var(--qrencia-text-black);
  margin: 0.5em 0.5em 1em 0.5em;
}
.modalClass {
  color: var(--qrencia-error-red);
}
.createButton {
  width: 100%;
  text-align: right;
  margin-top: 3em;
}
.createButton > button {
  font-size: 0.8em;
  padding: 0.6em 2em;
  outline: none;
  border: none;
}
.createComptitionButton {
  width: 8em;
  background-color: var(--qrencia-competition-tabs-success);
}
.createButton > button:hover {
  background-color: var(--qrencia-competition-tabs-success) !important;
}
.shareLink:hover {
  color: var(--qrencia-link-color);
}
.collapseIcons {
  cursor: pointer;
}

.spinner {
  animation: spin-animation 0.5s infinite;
}
.backButton {
  font-size: 1.5em;
  color: var(--qrencia-dark-blue);
  margin-left: 0.6em;
  cursor: pointer;
  margin-bottom: 0.1em;
  font-weight: 600;
}
.pageLoader {
  color: var(--qrencia-dark-blue);
  font-size: 2.2em;
  text-align: center;
  background-color: var(--qrencia-white);
  width: 100%;
  height: 2.5em;
  line-height: 2.5em;
  border: 1px solid var(--qrencia-cards-border);
}
.goBackButton,
.goBackButton:hover,
.goBackButton:active,
.goBackButton:focus {
  font-size: 1.1em;
  margin-bottom: 0.5em;
  background-color: var(--qrencia-white) !important;
  color: var(--qrencia-dark-blue) !important;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}
.errorContainer {
  background-color: var(--qrencia-white);
  padding: 1em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-cards-border);
  margin-bottom: 1em;
}
.copyLearningActivityContainer {
  width: 100%;
  /* padding: 0.4em 0.8em; */
}
@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@media (max-width: 1440px) {
  .collapseContainer {
    margin: 0.5 0.2em;
  }
  .headerSection {
    padding: 0.6em 1em;
    font-size: 0.9em;
  }
  .createCompContainer {
    padding: 0.8em;
  }
  .infoComponents {
    padding: 0.7em;
  }
}

@media (max-width: 990px) {
  .collapseContainer {
    margin: 0.2em;
  }
  .headerSection {
    padding: 0.6em 1em;
    font-size: 0.9em;
  }
  .createCompContainer {
    padding: 0.7em;
  }
  .infoComponents {
    padding: 0.7em;
  }
}
@media (max-width: 767px) {
  .collapseContainer {
    margin: 0.2em;
  }
  .headerSection {
    padding: 0.6em 1em;
    font-size: 0.9em;
  }
  .createCompContainer {
    padding: 0.7em;
  }
  .infoComponents {
    padding: 0.7em;
  }
}
@media (max-width: 540px) {
  .collapseContainer {
    margin: 0.2em;
  }
  .headerSection {
    padding: 0.6em 1em;
    font-size: 0.8em;
  }
  .createCompContainer {
    padding: 0.6em;
  }
  .infoComponents {
    padding: 0.5em;
  }
}
@media (max-width: 480px) {
  .createButton {
    text-align: center;
    margin-top: 2em;
    margin-bottom: 1em;
  }
  .infoComponents {
    padding: 0em;
  }
  .backButton {
    font-size: 1.5em;

    margin-left: 0em;
  }
}
