.detailsContainer {
  padding: 1em;
  background-color: var(--qrencia-white);
  border-radius: 0.8em;
}

.columnContainer {
  margin-top: 1em;
}
.expertDetailsLabel {
  color: var(--qrencia-dark-blue);
  font-size: 0.8em;
  font-weight: 500 !important;
}
.expertDetailsText {
  font-size: 0.8em;
  word-wrap: break-word;
}
.skillsList {
  padding: 0;
  margin-bottom: -0.2em;
}
.detailsHeading {
  font-size: 1em;
}
.selectButton {
  font-size: 0.8em !important;
  padding: 0.61em 1em;
  margin-top: 1em;
  width: 9em;
}
.expertDetailsListElement {
  list-style: none;
  font-size: 0.8em;
  display: inline;
}
.expertDetailsLinkLabel,
.expertDetailsLinkLabel:hover,
.expertDetailsLinkLabel:active,
.expertDetailsLinkLabel:focus {
  text-decoration: none;
  font-size: 0.75em !important;
  cursor: pointer;
  color: var(--qrencia-text-black);
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg);
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  padding: 1px 8px;
}
.detailsColumHrContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0.5em;
  margin-top: 1.5em;
  color: var(--qrencia-dark-blue);
  font-size: 1em;
}
.statusFieldAssociated {
  color: var(--qrencia-success-green);
}

span.statusFieldAssociated {
  color: var(--qrencia-success-green);
}
.statusFieldNotAssociated {
  color: var(--qrencia-error-red);
}
span.statusFieldNotAssociated {
  color: var(--qrencia-error-red) !important;
  background-color: var(--qrencia-competition-info-bg) !important;
}
.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
}
