@media (min-width: 690px) and (max-width: 767.5px) {
  .widgetSection {
    margin-top: 4%;
  }
}
@media (min-width: 635px) and (max-width: 690px) {
  .widgetSection {
    margin-top: 4%;
  }
}
@media (min-width: 540px) and (max-width: 635px) {
  .widgetSection {
    margin-top: 5%;
  }
}
@media (min-width: 480px) and (max-width: 539px) {
  .widgetSection {
    margin-top: 7%;
  }
}
@media (max-width: 480px) {
  .widgetSection {
    margin-top: 7%;
  }
}
