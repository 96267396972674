.loginFormSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* border-radius: 7px; */
  /* box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 33%); */
  /* margin-left: -2px; */
  min-height: 41rem;
  height: 100vh;
  flex: 1;
  background-color: var(--qrencia-white);
  padding: 2.5rem;
  /* min-height: 100vh; */
  font-size: 14.3px;
}

.formHeading {
  color: var(--qrencia-dark-blue);
  font-size: 1.5em;
  font-weight: 600;
  margin-top: 0.5%;
  margin-bottom: 6%;
  text-align: center;
}

.formSubHeading {
  color: var(--card-grey-title);
  font-size: 1em;
  font-weight: 400 !important;
  margin-top: 0.5%;
  margin-bottom: 2.5%;
}

.iButton {
  padding: 2px;
  background-color: var(--qrencia-dark-blue);
  border-radius: 50%;
  color: var(--qrencia-white);
  font-size: 0.9em;
  margin: 0 0 2px 7px;
}
.qrenciaLogoSection {
  padding-left: auto;
  padding-right: auto;
  margin: 2em 0em 2em 0em;
}
.qrenciaLogoSection a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
}
.formSection {
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  max-width: 34em !important;
}
.qrenciaLogo {
  height: 7em;
  object-fit: contain;
}
.inputLabel {
  font-size: 0.85em;
}

.formInputBox {
  width: 100%;
}

.loginButton {
  width: 100%;
  font-weight: 400 !important;
  font-size: 1em;
  padding: 6px 8px;
  margin: 0.6em 0;
}

.showHidePasswordIcon {
  text-align: right;
  padding-right: 2%;
}

.showHidePasswordIcon svg {
  margin-top: -4em;
}

.tncTextContainer {
  width: 100%;
  padding: 0 10px;
}

.tncText {
  color: var(--card-grey-title);
  font-size: 0.78em;
  font-weight: 400 !important;
  text-align: center;
}

.tncText > span {
  font-weight: 400 !important;
  color: #0d6efd !important;
}

.forgotPasswordContainer {
  margin-top: -1em;
}

.forgotPasswordText {
  color: var(--linkedin-blue);
  font-size: 0.78em;
  font-weight: 400 !important;
  text-align: right;
  cursor: pointer;
}

.tncTextHyper {
  text-decoration: none !important;
  cursor: pointer !important;
}

.unitInput {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 0.8em;
}

.unitInputBox {
  margin-bottom: 0;
  width: 100%;
}

.unitInputPrepend {
  color: var(--qrencia-text-black);
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  flex-grow: 0;
  font-weight: 400;
  font-size: 0.8em;
  padding: 0.57em;
}

.unitInputPrepend {
  border-right-width: 1px;
}

.displayNone {
  display: none;
}

.spinner {
  animation: spin-animation 0.5s infinite;
}

.loginModalSubtitle {
  font-size: 1em !important;
  font-weight: 450 !important;
  color: var(--qrencia-dark-blue);
}
.loginModalTitle {
  font-size: 1.1em !important;
  color: var(--qrencia-dark-blue);
  color: var(--qrencia-grey-text-color);
  margin-bottom: 0.4em;
}
.footerLoginText {
  font-weight: 500 !important;
}

.loginModalTimerHeader > button {
  margin-top: -2em !important;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@media (min-width: 767.5px) and (max-width: 769px) {
  .loginFormSection {
    margin-left: -15px !important;
    z-index: 1 !important;
    position: relative;
  }
}

@media (min-width: 1440px) and (max-width: 2500px) {
  .loginFormSection {
    padding: 4rem;
  }

  .inputLabel {
    font-size: 0.95em;
  }

  .tncText,
  .forgotPasswordText {
    font-size: 0.85em;
  }
}
@media (min-height: 1365px) and (max-height: 1367px) and (min-width: 1023px) and (max-width: 1025px) {
  .loginFormSection {
    max-height: 50em !important;
    min-height: 50em;
    font-size: 12px !important;
  }
}
@media (min-height: 767px) and (max-height: 768px) and (min-width: 1023px) and (max-width: 1025px) {
  .loginFormSection {
    max-height: 53em !important;
    min-height: 53em;
    font-size: 12px !important;
  }
}
@media (min-width: 1279px) and (max-width: 1281px) and (min-height: 1023px) and (max-height: 1025px) {
  .loginFormSection {
    max-height: 55em !important;
    min-height: 55em;
    font-size: 14px !important;
  }
}
@media (min-width: 901px) and (max-width: 1439px) {
  /* .formHeading {
    font-size: 1.4em;
  } */

  /* .forgotPasswordContainer{
        margin-top: -8%;
    } */
}
@media (max-width: 991px) {
  .loginFormSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    padding: 2rem;
    height: 35em;
    border-radius: 7px;
    box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 20%);
  }
  .qrenciaLogoSection {
    padding-left: auto;
    padding-right: auto;
    margin: 0em 0em 1em 0em;
  }
  .formSection {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 40em !important;
  }
  .qrenciaLogo {
    height: 6em;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .loginFormSection {
    padding: 2rem;
  }

  /* .formHeading {
    font-size: 1.4em;
  } */

  .formInputBox {
    width: 100%;
  }

  .unitInput {
    width: 100%;
  }

  .loginButton {
    width: 100%;
    font-size: 0.8em;
  }

  .tncTextContainer {
    width: 100%;
  }
  .loginModalTitle {
    font-size: 1.1em !important;
  }
  .loginModalSubtitle {
    font-size: 0.9em !important;
    color: var(--qrencia-dark-blue);
  }

  /* .forgotPasswordContainer{
        margin-top: -12%;
    } */
}

@media (min-width: 559px) and (max-width: 767px) {
  .loginFormSection {
    padding: 3rem;
    margin: 0;
  }

  /* .formHeading {
    font-size: 1.4em;
  } */

  .formInputBox {
    width: 100%;
  }

  .unitInput {
    width: 100%;
  }

  .loginButton {
    width: 100%;
  }

  .tncTextContainer {
    width: 100%;
  }

  .loginModalTitle {
    font-size: 1.1em !important;
  }
  .loginModalSubtitle {
    font-size: 1em !important;
    color: var(--qrencia-dark-blue);
  }
}

@media (min-width: 320px) and (max-width: 560px) {
  .loginFormSection {
    padding: 2rem;
    margin: 0;
  }
  .loginModalTitle {
    font-size: 0.95em !important;
  }
  .loginModalSubtitle {
    font-size: 0.9em !important;
    color: var(--qrencia-dark-blue);
  }

  /* .formHeading {
    font-size: 1.4em;
  } */

  .formInputBox {
    width: 100%;
  }

  .unitInput {
    width: 100%;
  }

  .loginButton {
    width: 100%;
  }

  .tncTextContainer {
    width: 100%;
  }
  .showHidePasswordIcon svg {
    margin-top: -3.6em;
  }

  /* .forgotPasswordContainer{
        margin-top: -8%;
    } */
}
