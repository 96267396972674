.studentLearningActivityTabsContainer {
  width: 100%;
}
.studentLearningActivityTabsContainer > ul {
  margin-left: 0% !important;
  margin-right: 0% !important;
  margin-bottom: 0% !important;
  font-weight: 500 !important;
  padding: 0em 1em;
}
.studentLearningActivityTabsContainer .nav-item {
  text-align: center !important;
  padding: 0em;
}

.studentLearningActivityTabsContainer > ul > li > .active {
  font-weight: 600 !important;
}
@media (min-width: 1150px) and (max-width: 1280px) {
  .studentLearningActivityTabsContainer > ul {
    font-size: 0.9em !important;
    margin-bottom: 0% !important;
  }
}
@media (max-width: 990px) {
  .studentLearningActivityTabsContainer > ul {
    margin-bottom: 0% !important;
  }
}
@media (max-width: 576px) {
  .studentLearningActivityTabsContainer > ul {
    font-size: 1em !important;
    margin-bottom: 0% !important;
    padding: 0em 0.8em !important;
  }
}
@media (max-width: 480px) {
  .studentLearningActivityTabsContainer > ul {
    font-size: 1.15em !important;
    margin-bottom: 0% !important;
  }
  .studentLearningActivityTabsContainer > ul {
    padding: 0em 0.5em !important;
  }
  .studentLearningActivityTabsContainer > ul > li .nav-item {
    padding-left: 0em !important;
    padding-right: 0em !important;
  }
  .studentLearningActivityTabsContainer > ul > li > button {
    padding-left: 0em !important;
    padding-right: 0em !important;
  }
}
@media (max-width: 440px) {
  .studentLearningActivityTabsContainer > ul {
    font-size: 1.1em !important;
    margin-bottom: 0% !important;
  }
  .studentLearningActivityTabsContainer > ul {
    padding: 0em 0em !important;
  }
}
@media (max-width: 400px) {
  .studentLearningActivityTabsContainer > ul {
    font-size: 1.04em !important;
    margin-bottom: 0% !important;
  }
  .studentLearningActivityTabsContainer > ul {
    padding: 0em 0em !important;
  }
}
@media (max-width: 380px) {
  .studentLearningActivityTabsContainer > ul {
    font-size: 1.02em !important;
    padding: 0em 0em !important;
  }
}
@media (max-width: 374px) {
  .studentLearningActivityTabsContainer > ul {
    font-size: 1em !important;
    padding: 0em 0em !important;
  }
}

@media (max-width: 368px) {
  .studentLearningActivityTabsContainer > ul {
    font-size: 0.95em !important;
    margin-bottom: 0% !important;
  }
}
