.sectionHeading {
  font-size: 1.025em;
  margin: 2% 0% 2% 2%;
  font-weight: 650;
  color: var(--qrencia-text-black);
}
.sectionDiv {
  margin: 2% 0%;
}
.adImageSection {
  margin-top: 1.2em;
}

.championBadge {
  display: flex;
  align-items: end;
  justify-self: end;
  position: absolute;
  top: 10px;
  right: 0px;
  font-size: 0.8em;
  margin-left: 3em;
  color: var(--qrencia-white) !important;
}

.statusWrapper {
  border-radius: 10px;
  text-align: center;
  padding: 6px 5px 6px 5px;
  position: absolute;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  color: var(--qrencia-text-black);
  font-weight: bold;
  text-decoration: none;
  border: 2px solid transparent;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.statusWrapper::before {
  content: '';
  position: absolute;
  inset: -2px;
  border-radius: 5px;
  background: linear-gradient(
    45deg,
    var(--qrencia-button-pink),
    var(--qrencia-golden-color),
    var(--qrencia-blue-background-course-cost)
  );
  background-size: 300%;
  z-index: -1;
  animation: animateBorder 4s ease infinite;
}

.statusWrapper::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 6px;
  z-index: -1;
}

@keyframes animateBorder {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media (min-width: 992px) and (max-width: 1290px) {
  .sectionHeading {
    font-size: 1em;
    margin-left: 3%;
  }
}
@media (max-width: 1286px) {
  .championBadge {
    top: 7px;
  }
}
@media (max-width: 1200px) {
  .championBadge {
    top: -10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sectionHeading {
    font-size: 1em;
  }
}

@media (max-width: 767px) {
  .championBadge {
    top: -75px;
    right: 80px;
  }
}

@media (max-width: 767px) {
  .sectionHeading {
    font-size: 0.95em;
  }
  .sectionDiv {
    margin: 4% 0%;
  }
}

.updateProfileDialogContainer {
  text-align: center;
  padding: 6% 0% 0% 0%;
  border-radius: 8px;
}
.dialogProfileGif {
  width: 22%;
  border-radius: 50%;
  object-fit: cover;
  background: white;
  border: 4px solid white;
}
.dialogProfileGifBackground {
  padding-top: 6%;
  height: 10em;
}
.avatarImage {
  position: absolute;
  z-index: 10;
}
.backdropImage {
  object-fit: cover;
  height: 7em;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: var(--qrencia-nudges-card-text-bg);
}
.buttonContainer {
  background: var(--qrencia-white);
  display: flex;
  align-items: last baseline;
  justify-content: space-between;
  padding: 1% 3%;
  font-size: 0.8em;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.dialogContent {
  background: var(--qrencia-white);
}
.dialogButton {
  width: 12em;
  min-height: 3em;
  margin: 2% auto;
  font-size: 0.85em;
}

.skipButton {
  background: var(--qrencia-white);
  text-decoration: underline;
  color: var(--qrencia-text-black);
  font-weight: 400 !important;
  cursor: pointer;
  margin-top: 5% !important;
}

.welcomeText {
  margin-top: -4%;
  font-weight: 600 !important;
  font-size: 1.1em;
}

.dialogContentText {
  padding: 2%;
}

@media (max-width: 767px) {
  .championBadge {
    top: -18px;
    right: auto;
    left: -24px;
  }

  .statusWrapper {
    padding: 3px 5px 3px 5px;
  }
}

@media (max-width: 675px) {
  .championBadge {
    top: -31px;
    right: auto;
    left: -21px;
    font-size: 0.7em;
  }
}
@media (max-width: 600px) {
  .championBadge {
    top: -29px;
    right: auto;
    left: -21px;
    font-size: 0.7em;
  }
}

@media (max-width: 580px) {
  .dialogProfileGifBackground {
    height: 11em;
  }
  .backdropImage {
    height: 7em;
  }
}
@media (max-width: 545px) {
  .championBadge {
    top: -27px;
    right: auto;
    left: -19px;
    font-size: 0.6em;
  }
}
@media (max-width: 500px) {
  .dialogProfileGifBackground {
    height: 10em;
  }
  .buttonContainer {
    padding: 1% 3%;
    font-size: 0.75em;
  }
  .dialogButton {
    width: 12em;
    height: auto;
    margin: 2% auto;
  }
  .backdropImage {
    height: 6em;
  }
}

@media (max-width: 475px) {
  .championBadge {
    top: -25px;
    right: auto;
    left: -19px;
    font-size: 0.6em;
  }
}
@media (max-width: 420px) {
  .dialogProfileGifBackground {
    height: 8em;
  }
  .backdropImage {
    height: 5.5em;
  }
}
@media (max-width: 400px) {
  .welcomeText {
    margin-top: -6%;
  }
}
