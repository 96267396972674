.bulkEnrolmentSheetContainer {
  background-color: var(--qrencia-white);
  width: 100%;
  padding: 1em;
  border-radius: 5px;
  margin-top: 1.5em;
  position: relative;
}

.buttons {
  font-size: 0.8em;
}
.templateButton {
  font-size: 0.8em;
  position: absolute;
  margin-right: 0em;
  right: 2em;
}
.uploadFileSection {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* flex-wrap: wrap; */
  margin-top: 2em;
}
.uploadFileSection > div {
  margin-right: 1em;
  width: 25em;
}
.uploadFileSection > button {
  height: 2.8em;
  margin-top: 1em;
  margin-right: 1em;
}

.fileInput {
  width: 24em;
}
@media (max-width: 1120px) {
  .fileInput {
    width: 20em;
  }
}
@media (max-width: 620px) {
  .fileInput {
    width: 17em;
  }
  .buttons {
    width: fit-content;
  }
}
@media (max-width: 575px) {
  .uploadFileSection > button {
    height: 2.8em;
    margin-top: 1.3em;
    margin-right: 1em;
    font-size: 0.7em;
    width: 8em;
  }
}
@media (max-width: 500px) {
  .uploadFileSection > button {
    height: 2.9em;
    margin-top: 1em;
    margin-right: 1em;
  }
}
@media (max-width: 480px) {
  .uploadFileSection > button {
    height: 3.2em;
    margin-top: 1.4em;
    font-size: 0.6em;
    width: 10em;
  }
  .templateButton {
    font-size: 0.7em;
  }
}
@media (max-width: 400px) {
  .uploadFileSection > button {
    height: 3.2em;
    margin-top: 1.4em;
    font-size: 0.6em;
    width: 12em;
  }
  .templateButton {
    font-size: 0.7em;
  }
}
