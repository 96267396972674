.container {
  display: flex;
  border-radius: 6px;
  border: 0.8px solid var(--qrencia-cards-border);
  width: fit-content;
  margin: 5px 0;
  margin-left: -4px;
}

.day {
  padding: 1px 4px;
  text-align: center;
  font-weight: 500;
  color: var(--qrencia-grey-text);
  font-size: 0.9em;
  transition: 0.2s all ease-out;
  cursor: pointer;
}

.activeDay {
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-white);
  font-weight: 600;
}

.activeDay:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 0px;
}

.activeDay:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
@media (min-width: 591px) and (max-width: 618px) {
  .day {
    padding: 1px 2px;
    text-align: center;
    font-weight: 450;
    color: var(--qrencia-grey-text);
    font-size: 0.8em;
    transition: 0.2s all ease-out;
    cursor: pointer;
  }
  .activeDay {
    color: var(--qrencia-dark-blue);
    background-color: var(--qrencia-white);
    font-weight: 600;
  }
}
