.cardSection {
  display: flex;
  margin: 0% -1.3%;
}

.cardBody {
  padding: 0;
}

.cardDiv {
  padding: 5% 5% 4% 5%;
  border-radius: 10px;
  margin-bottom: 4%;
  cursor: pointer;
}
.cardImage {
  border-radius: 8px;
  aspect-ratio: 825 / 550 !important;
  object-fit: cover;
}

.titleFixedHeight {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.mySchoolIcon {
  color: var(--qrencia-text-black);
  margin-left: 0.6em;
}
.widgetSchoolIcon {
  font-size: 1.05em;
}
.cardTitle {
  display: flex;
  justify-content: space-between;
  font-weight: 600 !important;
  font-size: 0.9em;
  height: 3em;
}

.competitionDateTimeFormat {
  color: var(--qrencia-cards-date-time-color);
  font-weight: 500 !important;
}

.courseTitleTooltip {
  background-color: var(--qrencia-white);
  font-weight: 600 !important;
  color: var(--qrencia-text-black) !important;
  font-size: 0.88em;
  z-index: 100;
  max-width: 30em;
  border: 1px solid var(--qrencia-grey-border);
}

.courseTitleTooltip > span {
  background-color: var(--qrencia-white);
  font-weight: 600 !important;
  color: var(--qrencia-text-black) !important;
  font-size: 0.88em;
  z-index: 100;
  max-width: 30em;
}
.creatorDetailsTooltip {
  background-color: var(--qrencia-white);
  color: var(--qrencia-text-black) !important;
  font-size: 0.88em;
  z-index: 100 !important;
  max-width: 22em;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  opacity: 1;
  padding: 0em !important;
}
.cardSubTitle {
  margin-top: 1.5%;
  font-size: 0.625em;
  font-weight: 500 !important;
  color: var(--qrencia-dark-blue);
}
.modeSubtitle {
  font-size: 1em !important;
  font-weight: 500 !important;
}

.cardTimeAndDate {
  font-size: 0.68em;
  display: flex;
  font-weight: 600 !important;
  margin: 1% 1% 1% 0;
  color: var(--qrencia-text-black) !important;
  margin-bottom: 0.4em !important;
}

.cardTimeAndDateHidden {
  color: var(--qrencia-white) !important;
}

.buttonSection {
  margin: 2% 0% 0% 1px;
  display: flex;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
}

.cardButton {
  font-size: 0.68em;
  padding: 2% 1%;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  text-align: center;
  font-weight: 500 !important;
  border-radius: 3px;
  margin: 2% 0%;
  width: 6.9em;
}
.linkedText {
  text-decoration: none;
  color: var(--qrencia-text-black);
}

.linkedText:hover {
  color: var(--qrencia-text-black);
}
.cardCancelButton,
.cardCancelButton:active,
.cardCancelButton:hover,
.cardCancelButton:focus {
  font-size: 0.75em;
  padding: 2.5%;
  background-color: var(--cancel-red-button) !important;
  border: 1px solid var(--cancel-red-button) !important;
  color: var(--qrencia-white) !important;
  text-align: center;
  font-weight: 500 !important;
  border-radius: 3px;
  margin: 2% 0%;
  width: 6.5em;
}

.cardUserProfileImageSection {
  display: flex;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 55%;
}

.cardEnrolledCountSection {
  font-weight: 600;
  font-size: 0.68em;
  margin-top: 2%;
  width: max-content;
  color: var(--qrencia-text-black);
  height: 2em;
}

.cardEnrolledText {
  display: contents;
  width: 100%;
}

.onlineIconCourse {
  color: var(--qrenica-course-differentiator-color) !important;
}
.onlineIconWorkshop {
  color: var(--qrencia-workshop-differentiator-color) !important;
}
.onlineIconCompetition {
  color: var(--qrenica-competition-differentiator-color) !important;
}
.onlineIconWorkshop {
  color: var(--qrencia-workshop-differentiator-color) !important;
}

.onlineModeIcon {
  margin-top: 7%;
  margin-left: auto;
  color: var(--qrencia-text-black) !important;
}

.cardProfileImage {
  width: 23px;
  height: 23px !important;
  border-radius: 18px;
  border: 2px solid var(--qrencia-white);
  position: relative;
  margin-right: -9px;
  object-fit: cover;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.88em;
  z-index: 100;
  min-width: 14em !important;
  max-width: 16em;
}

.tooltipMessagePrivate {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-size: 0.7em;
  z-index: 100;
  width: 80%;
}

.cardLink,
.cardLink:hover,
.cardLink:active,
.cardLink:focus {
  text-decoration: none;
  color: var(--qrencia-text-black);
  font-weight: 600;
}
.workshopLink {
  text-decoration: none;
}
.cardProfileImageSection {
  display: flex;
  margin-right: 4%;
}

.calendarIcon {
  font-size: 1.3em;
  margin-left: -0.1em;
  margin-top: 0.15em;
}

.dateTimeText {
  margin-top: 0.8%;
  color: var(--qrencia-text-black) !important;
}

.dateTimeTextHidden {
  color: var(--qrencia-white) !important;
}

.geoLocationIcon {
  font-size: 1.1em;
  margin-top: -2px;
}

.hiddenDateDiv {
  padding: 0.5em 0;
}

.subTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

.studentCourseCostText {
  font-size: 0.7em;
  font-weight: 600;
  color: var(--qrencia-white);
  background-color: var(--qrenica-course-differentiator-color);
  border-radius: 5px;
  line-height: 1.6;
  padding: 2px 6px;
  min-width: 4em;
  text-align: center;
}

.studentWorkshopCostText {
  font-size: 0.7em;
  font-weight: 600;
  color: var(--qrencia-white);
  background-color: var(--qrencia-workshop-differentiator-color);
  border-radius: 5px;
  line-height: 1.6;
  padding: 2px 6px;
  min-width: 4em;
  text-align: center;
}

.studentCompetitionInfoText {
  font-size: 0.7em;
  font-weight: 500;
  color: var(--qrencia-white);
  background-color: var(--qrenica-competition-differentiator-color);
  padding: 2px 6px;
  border-radius: 5px;
  line-height: 1.6;
  min-width: 4em;
  text-align: center;
}

.learningActivityCostInfo {
  font-size: 0.7em;
  font-weight: 500;
  color: var(--qrencia-text-black);
  background-color: var(--qrencia-cost-background);
  padding: 2px 7px;
  border-radius: 10px;
  line-height: 1.6;
  min-width: 4em;
  text-align: center;
}
.learningActivityCostInfo > svg {
  margin-bottom: 2px;
}
.courseCostInfoText {
  font-size: 0.6em;
  font-weight: 500;
  color: var(--qrencia-white);
  background-color: var(--qrenica-course-differentiator-color);
  padding: 1px 6px;
  border-radius: 10em;
  line-height: 1.6;
}

.workshopCostInfoText {
  font-size: 0.6em;
  font-weight: 500;
  color: var(--qrencia-white);
  background-color: var(--qrencia-workshop-differentiator-color);
  padding: 1px 6px;
  border-radius: 10em;
  line-height: 1.6;
}
.studentWorkshopCostText {
  font-size: 0.7em;
  font-weight: 600;
  color: var(--qrencia-white);
  background-color: var(--qrencia-workshop-differentiator-color);
  border-radius: 5px;
  line-height: 1.6;
  padding: 2px 6px;
  min-width: 4em;
  text-align: center;
}

.competitionCostInfoText {
  font-size: 0.6em;
  font-weight: 500;
  color: var(--qrencia-white);
  background-color: var(--qrenica-competition-differentiator-color);
  padding: 1px 6px;
  border-radius: 10em;
  line-height: 1.6;
}
.cardsCostInfoText {
  font-size: 0.6em;
  font-weight: 500;
  color: var(--qrencia-text-black);
  background-color: var(--qrencia-cost-background);
  padding: 1px 6px;
  border-radius: 10em;
  line-height: 1.6;
}
.cardsCostInfoText > svg {
  margin-top: -2px;
}

.cardUserProfileImageSectionExpert {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardUserProfileImageContainerExpert {
  display: flex;
  gap: 5px;
  display: flex;
  align-items: center;
  width: 50%;
}
.durationIcon {
  font-size: 1.2em;
}
.durationSeparator {
  color: #9c9c9c;
  font-weight: 500 !important;
}
.emptyDivForCalendar {
  height: 2em;
}
@media (min-width: 1140px) and (max-width: 1260px) {
  .cardSubTitle {
    font-size: 0.6em !important;
  }
}

@media (min-width: 992px) and (max-width: 1140.5px) {
  .cardSubTitle {
    font-size: 0.5em !important;
  }
}

@media (min-width: 1290px) and (max-width: 1415px) {
  .cardTimeAndDate {
    font-size: 0.63em;
  }

  .cardEnrolledCountSection {
    font-size: 0.63em;
  }
}

@media (min-width: 1180px) and (max-width: 1290px) {
  .cardTitle {
    font-weight: 600 !important;
    text-align: left;
    font-size: 0.88em;
  }

  .cardTimeAndDate {
    font-size: 0.58em;
  }

  .cardEnrolledCountSection {
    font-size: 0.58em;
  }

  .cardImage {
    /* height: 8em; */
  }

  .cardButton {
    font-size: 0.7em;
    padding: 2.5%;
    background-color: var(--qrencia-dark-blue) !important;
    color: var(--qrencia-white) !important;
    text-align: center;
    font-weight: 500 !important;
    border-radius: 3px;
  }

  .cardCancelButton,
  .cardCancelButton:active,
  .cardCancelButton:hover,
  .cardCancelButton:focus {
    font-size: 0.7em;
    padding: 2.5%;
    text-align: center;
    font-weight: 500 !important;
    border-radius: 3px;
  }
}

@media (min-width: 992px) and (max-width: 1180px) {
  .cardTitle {
    font-weight: 600 !important;
    text-align: left;
    font-size: 0.8em;
  }

  .cardSubTitle {
    font-size: 0.6em;
  }

  .cardTimeAndDate {
    font-size: 0.46em;
  }

  .cardEnrolledCountSection {
    font-size: 0.5em;
    margin-top: 3%;
  }

  .cardProfileImage {
    width: 19px;
    height: 19px !important;
  }

  .cardImage {
    /* height: 6.5em; */
  }

  .geoLocationIcon {
    font-size: 0.9em;
  }

  .cardButton {
    font-size: 0.6em;
    padding: 2.5%;
  }
  .studentCourseCostText {
    font-size: 0.6em;
  }
  .studentWorkshopCostText {
    font-size: 0.6em;
  }
  .studentCompetitionInfoText {
    font-size: 0.6em;
  }

  .cardCancelButton,
  .cardCancelButton:active,
  .cardCancelButton:hover,
  .cardCancelButton:focus {
    font-size: 0.6em;
    padding: 2.5%;
  }
}

@media (min-width: 1060px) and (max-width: 1180px) {
  .learningActivityCostInfo {
    font-size: 0.6em;
  }
}
@media (min-width: 1010px) and (max-width: 1060px) {
  .learningActivityCostInfo {
    font-size: 0.55em;
    padding: 2px 5px;
  }
}

@media (min-width: 992px) and (max-width: 1010px) {
  .learningActivityCostInfo {
    font-size: 0.52em;
    padding: 2px 4px;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .cardTitle {
    font-size: 0.9em;
  }

  .cardImage {
    /* height: 8em; */
  }

  .cardTimeAndDate {
    font-size: 0.62em;
  }

  .cardEnrolledCountSection {
    font-size: 0.62em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .geoLocationIcon {
    font-size: 1.15em;
  }
  .learningActivityCostInfo {
    font-size: 0.65em;
  }
}

@media (max-width: 767px) {
  .cardImage {
    /* height: 8.2em; */
  }
  .learningActivityCostInfo {
    font-size: 0.64em;
  }
}

@media (max-width: 580px) {
  .cardImage {
    /* height: 10.2em; */
  }

  .cardTitle {
    font-size: 0.95em;
  }

  .cardDiv {
    margin: 4% 0%;
  }

  .cardSubTitle {
    font-size: 0.625em;
  }

  .cardTimeAndDate {
    font-size: 0.65em;
  }

  .cardEnrolledCountSection {
    font-size: 0.68em;
  }

  .geoLocationIcon {
    font-size: 1.15em;
  }

  .buttonSection {
    margin: 2% 0% 0% 0%;
  }

  .cardButton {
    width: 7.5em;
  }

  .cardCancelButton,
  .cardCancelButton:active,
  .cardCancelButton:hover,
  .cardCancelButton:focus {
    width: 7.5em;
  }
  .emptyDivForCalendar {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 576px) {
  .cardDiv {
    max-width: 18em !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding: 3% !important;
  }
}

@media (max-width: 480px) {
  .cardEnrolledCountSection {
    font-size: 0.68em;
  }
}

.performancePageCardWidth {
  max-width: 17em;
  margin-left: auto;
  margin-right: auto;
}

.performanceAnalysisPageSlider {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.performanceAnalysisPageSlider .slick-prev:before,
.performanceAnalysisPageSlider .slick-next:before {
  color: var(--qrencia-dark-blue) !important;
  opacity: 1 !important;
}

.courseDiffColor {
  color: var(--qrenica-course-differentiator-color) !important;
}

.workshopDiffColor {
  color: var(--qrencia-workshop-differentiator-color) !important;
}

.competitionDiffColor {
  color: var(--qrenica-competition-differentiator-color) !important;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .performanceAnalysisPageSlider {
    width: 79%;
  }
}

@media (min-width: 1080px) and (max-width: 1200px) {
  .performanceAnalysisPageSlider {
    width: 82%;
  }
}

@media (min-width: 790px) and (max-width: 991px) {
  .performanceAnalysisPageSlider {
    width: 78%;
  }
}

@media (min-width: 580px) and (max-width: 991px) {
  .performanceAnalysisPageSlider {
    width: 78%;
  }
  .competitionAgeSection {
    font-size: 0.55em;
  }
}

@media (min-width: 580px) and (max-width: 1180px) {
  .performanceAnalysisPageSlider {
    width: 78%;
  }
  .competitionAgeSection {
    font-size: 0.55em;
  }
}
@media (min-width: 580px) and (max-width: 768px) {
  .performanceAnalysisPageSlider {
    width: 60%;
  }
}

@media (max-width: 580.5px) {
  .performanceAnalysisPageSlider {
    width: 86%;
    margin-bottom: 2em;
  }
}

.performanceAnalysisPageSlider .slick-next {
  color: var(--qrencia-dark-blue) !important;
  border: 1.25px solid var(--qrencia-dark-blue);
  border-radius: 12px;
  width: 45px;
  height: 23px;
  position: absolute !important;
  right: 50% !important;
  top: 25rem !important;
  transform: translateX(-50%) translateX(80px) !important;
}

.performanceAnalysisPageSlider .slick-next:hover {
  color: var(--qrencia-white) !important;
  background-color: var(--qrencia-dark-blue);
}

.performanceAnalysisPageSlider .slick-prev {
  color: var(--qrencia-dark-blue) !important;
  border: 1.25px solid var(--qrencia-dark-blue);
  border-radius: 12px;
  width: 45px;
  height: 23px;
  position: absolute !important;
  left: 50% !important;
  top: 25rem !important;
  transform: translateX(-50%) translateX(-40px) !important;
}

.performanceAnalysisPageSlider .slick-prev:hover {
  color: var(--qrencia-white) !important;
  background-color: var(--qrencia-dark-blue);
}

.performanceAnalysisPageSlider .slick-disabled {
  cursor: not-allowed;
  color: var(--qrencia-grey-text) !important;
  background-color: transparent;
  border: 1.25px solid var(--qrencia-grey-text);
}

.performanceAnalysisPageSlider .slick-disabled:hover {
  cursor: not-allowed;
  color: var(--qrencia-grey-text) !important;
  background-color: transparent;
  border: 1.25px solid var(--qrencia-grey-text);
}

@media (min-width: 576px) and (max-width: 580.5px) {
  .performancePageCardWidth {
    max-width: 18em !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding: 3% !important;
  }
}

@media (min-width: 1181px) and (max-width: 1290px) {
  .performanceAnalysisPageSlider .slick-next {
    top: 25rem !important;
  }

  .performanceAnalysisPageSlider .slick-prev {
    top: 25rem !important;
  }
}

@media (min-width: 995px) and (max-width: 1180px) {
  .performanceAnalysisPageSlider .slick-next {
    top: 23.5rem !important;
  }

  .performanceAnalysisPageSlider .slick-prev {
    top: 23.5rem !important;
  }
}

@media (min-width: 480px) and (max-width: 580.5px) {
  .performanceAnalysisPageSlider .slick-next {
    top: 26.9rem !important;
  }

  .performanceAnalysisPageSlider .slick-prev {
    top: 26.9rem !important;
  }
}

@media (min-width: 380px) and (max-width: 480.5px) {
  .performanceAnalysisPageSlider .slick-next {
    top: 26rem !important;
  }

  .performanceAnalysisPageSlider .slick-prev {
    top: 26rem !important;
  }
}

@media (min-width: 300px) and (max-width: 380.5px) {
  .performanceAnalysisPageSlider .slick-next {
    top: 24.6rem !important;
  }

  .performanceAnalysisPageSlider .slick-prev {
    top: 24.6rem !important;
  }
}
@media (max-width: 400px) {
  .cardTimeAndDate {
    font-size: 0.55em !important;
  }
}
@media (min-width: 992px) and (max-width: 1010px) {
  .cardSubTitle {
    font-size: 0.48em !important;
  }
}
