.cardContainer {
  border: 1.2px solid var(--qrencia-grey-border);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  margin-bottom: 3%;
  border-radius: 10px;
  display: flex;
  padding: 1.5% 2.5%;
  position: relative;
  margin-right: 0.5em;
}

.cardsSection {
  height: 53vh;
  overflow-y: auto;
}

.achievementEdit svg {
  display: flex;
  justify-self: end;
}

.competitionBgHexagon {
  background-color: var(--qrencia-competition-color-code);
}

.courseBgHexagon {
  background-color: var(--qrenica-course-differentiator-color);
}

.workshopBgHexagon {
  background-color: var(--qrencia-workshop-differentiator-color);
}

.hexagonContainer {
  position: relative;
  width: 55px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.rankIcons {
  width: 55px;
  height: 52px;
}

.circleContainer {
  position: relative;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.hexagonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--qrencia-white);
  font-size: 1.5em;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.shareIconPannelContainer {
  display: grid;
  position: absolute;
  right: -1.15em;
  top: 40%;
  gap: 5px;
}

.shareContainer {
  display: flex;
  width: 100%;
}

.cardHeading {
  width: 100%;
  font-size: 1em;
  font-weight: 500 !important;
}

.cardDate {
  font-size: 0.68em;
  color: var(--card-grey-title);
  font-weight: 500 !important;
  margin-top: 0.5%;
  width: 90%;
}

.cardSubHeading {
  font-size: 0.7em;
  color: var(--card-grey-title);
  font-weight: 500 !important;
  margin-top: 0.5%;
}

.certificateSection {
  color: var(--card-grey-title);
  font-size: 1em;
  width: 10%;
  text-align: center;
}

.shareIcon {
  font-size: 1.3em;
  background-color: var(--qrencia-white);
  color: var(--card-grey-title);
  border-radius: 20px;
  cursor: pointer;
  padding: 2px;
  width: 10%;
}

.shareOnLinkedIn {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnFacebook {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnTwitter {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareIcons {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnInstagramButton {
  background: -moz-linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: -webkit-linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  color: var(--qrencia-white);
  font-size: 1em;
  cursor: pointer;
}

.shareOnMailButton {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  cursor: pointer;
}

.copyToClipboard {
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  font-size: 0.95em;
  font-weight: bold;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.hexagonSection {
  width: 4em;
  display: inline-flex;
  justify-content: center;
  margin-top: 0.5%;
}

.contentSection {
  width: 92%;
  margin-left: 1em;
}

.approvedSeal {
  width: 3.7rem;
  font-size: 1.5em;
}

.editSwitch .form-check-input {
  transform: scale(0.55);
}

.editLabel {
  font-size: 0.85rem;
  font-weight: 400 !important;
  color: var(--qrencia-text-black);
}

@media (max-width: 1260px) {
  .cardHeading {
    font-size: 0.9em;
  }
}

@media (max-width: 1193px) {
  .cardHeading {
    font-size: 0.85em;
  }
}

@media (max-width: 990px) {
  .cardsSection {
    height: 54vh;
  }

  .editLabel {
    font-size: 0.75rem;
  }

  .cardHeading {
    font-size: 0.8em;
  }

  /* .achievementEdit {
    top: 12px;
    right: 8px;
  } */

  /* .achievementEdit svg {
    height: 0.9em;
  } */
}

@media (max-width: 836px) {
  .cardsSection {
    height: 54.8vh;
  }
}

@media (min-width: 767.8px) and (max-width: 867px) {
  .cardSubHeading {
    font-size: 0.65em;
    margin-bottom: 0.3em;
  }

  .cardDate {
    font-size: 0.6em;
  }

  .editLabel {
    font-size: 0.65rem;
  }
}

@media (min-width: 767.8px) and (max-width: 800px) {
  .cardSubHeading {
    font-size: 0.6em;
    margin-bottom: 0.3em;
  }

  .cardDate {
    font-size: 0.6em;
  }
}

@media (max-width: 767.8px) {
  .cardsSection {
    height: 315px;
    overflow-y: auto;
  }

  .cardHeading {
    font-size: 0.9em;
  }
}

@media (max-width: 467px) {
  .cardSubHeading {
    font-size: 0.6em;
    margin-bottom: 0.4em;
  }

  .editLabel {
    font-size: 0.65rem;
  }

  .cardDate {
    font-size: 0.58em;
  }

  .cardHeading {
    font-size: 0.8em;
    margin-bottom: 0.1em;
  }
}

.achievementEdit {
  margin-top: 0.2em;
  display: flex;
}

.shareContainer {
  display: block;
  width: 100%;
}

.verifiedSection {
  display: flex;
  width: 100%;
}

.certificateSection {
  color: var(--card-grey-title);
  font-size: 1em;
  text-align: left;
}

@media(min-width:1020px) {
  .achievementEdit {
    position: absolute;
    top: 10px;
    right: 8px;
  }

  .shareContainer {
    display: flex;
    width: 100%;
  }

  .certificateSection {
    color: var(--card-grey-title);
    font-size: 1em;
    width: 10%;
    text-align: center;
  }
}