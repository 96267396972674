.coursePageFaqAccordion .accordion-item:first-of-type .accordion-button {
    padding: 0 !important;
}

.coursePageFaqAccordion .accordion-button {
    margin-top: 0 !important;
    padding: 0 !important;
    color: var(--qrencia-dark-blue) !important;
}

.coursePageFaqAccordion .accordion-body {
    padding: 0 !important;
}