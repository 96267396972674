.container {
  /* padding: 2rem;
    width: 420px;
    height: 528px; */
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.contentContainer {
  padding: 1rem 2rem;
}

.modalContainer {
  width: 50%;
}

.modalBody {
  padding: 0 !important;
}

.illustration {
  /* width: 420px; */
  /* height: 247px; */
  object-fit: cover;
  width: 100%;
}

.illustrationContainer {
  background-color: #f5f5f5;
}

.textContainer {
  flex: 1;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 28px;
  font-weight: bold;
  /* color: #333; */
  /* margin-bottom: 10px; */
}

.description {
  /* font-size: 15px; */
  /* color: #555; */
  /* line-height: 1.5; */
  margin: 1rem 0 1.2rem;
}

.textHighlight {
  color: #007bff;
  font-weight: bold;
}

.buttonContainer {
  background: var(--qrencia-white);
  display: flex;
  align-items: last baseline;
  justify-content: space-between;
  /* padding: 1% 3%; */
  font-size: 0.8em;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: 0.5rem;
}

.closeButton {
  background: transparent;
  border: none;
  font-size: 2em;
  padding: 0%;
  display: flex;
  margin-top: -0.4em;
  float: inline-end;
}

.skipButton {
  background: var(--qrencia-white);
  color: var(--qrencia-grey-text);
  font-weight: 400 !important;
  cursor: pointer;
  margin-top: 5% !important;
}

.skipButton:hover {
  text-decoration: underline;
  cursor: pointer;
}

.button {
  background-color: #c2e2ff;
  /* Light blue */
  color: #003366;
  /* Dark blue */
  border: none;
  border-radius: 25px;
  /* Fully rounded corners */
  padding: 10px 20px;
  /* Adjust padding for a balanced look */
  font-size: 16px;
  /* Optional: Adjust font size */
  font-weight: bold;
  /* Bold text */
  cursor: pointer;
  /* Pointer cursor on hover */
  box-shadow: none;
  /* Flat look, no shadow */
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  /* Smooth hover transition */
}

.laterButton {
  font-size: 16px;
  color: var(--qrencia-grey-text);
  font-weight: 600;
  cursor: pointer;
  box-shadow: none;
}

.laterButton:hover {
  color: #333 !important;
  background-color: white !important;
}

.button:hover {
  background-color: #003366;
  /* Dark blue on hover */
  color: #ffffff;
  /* White text on hover */
}

.button:active {
  background-color: #002244;
  /* Slightly darker blue when clicked */
  color: #ffffff;
  /* White text */
}
