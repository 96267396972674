.activitySnapshotHeading {
  font-size: 1em;
  font-weight: bold;
  margin: 1em 0em 0.9em 0.5em;
}

.activityContainer {
  width: 100%;
  margin: 0em auto;
  background-color: var(--qrencia-white);
  box-shadow: 0 3px 21px 0 rgba(100, 100, 100, 0.12);
  padding: 1.8em 1em;
  border-radius: 8px;
  height: fit-content;
  margin-bottom: 3em;
}

.activityCardsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activityCard {
  margin: 0.5em;
  height: 7.5em;
  position: relative;
  padding: 1em 0.5em 0em 0.5em;
  border-radius: 5px;
}
.activityCardTeam {
  margin: 0.5em;
  height: 7.5em;
  position: relative;
  padding: 1em 0.5em 0em 0.5em;
  border-radius: 5px;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}
.iconContainer {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3em;
  width: 3em;
  line-height: 3em;
  color: var(--qrencia-white);
  border-radius: 50%;
  text-align: center;
  vertical-align: center;
}

.iconContainer > svg {
  font-size: 1.2em;
}

.title {
  font-size: 0.8em;
  text-align: center;
  margin: 1.4em;
  font-weight: 500;
}

.skillLabsTitle {
  margin: 1.4em 4em;
}

.count {
  font-size: 1.6em;
  text-align: center;
  font-weight: bold;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottomDisplayLine {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -80%);
  height: 2.5px;
  width: 3em;
  opacity: 0.6;
}

.activityCardOneBg {
  background-color: var(--qrencia-activity-snapshot-cardone-bg);
}

.activityCardTwoBg {
  background-color: var(--qrencia-activity-snapshot-cardtwo-bg);
}

.activityCardThreeBg {
  background-color: var(--qrencia-activity-snapshot-cardthree-bg);
}

.activityCardFourBg {
  background-color: var(--qrencia-activity-snapshot-cardfour-bg);
}

.cardOneIconBg {
  background-color: var(--qrencia-activity-snapshot-iconone-bg);
}

.cardTwoIconBg {
  background-color: var(--qrencia-activity-snapshot-icontwo-bg);
}

.cardThreeIconBg {
  background-color: var(--qrencia-activity-snapshot-iconthree-bg);
}

.cardFourIconBg {
  background-color: var(--qrencia-activity-snapshot-iconfour-bg);
}

@media (min-width: 1875px) and (max-width: 1940px) {
  .competitionsTitle {
    margin: 1.4em 3em;
  }
}

@media (max-width: 1600px) {
  .skillLabsTitle {
    margin: 1.4em 3em;
  }
}

@media (max-width: 1430px) {
  .title {
    font-size: 0.7em;
    text-align: center;
    margin: 1.4em;
  }

  .skillLabsTitle {
    margin: 1.4em 3em;
  }
}

@media (max-width: 1340px) {
  .title {
    font-size: 0.68em;
    text-align: center;
    margin: 1.4em;
  }

  .count {
    font-size: 1.2em;
    text-align: center;
  }

  .skillLabsTitle {
    margin: 1.4em 3.1em;
  }
}

@media (max-width: 1290px) {
  .title {
    font-size: 0.66em;
    text-align: center;
    margin: 1.4em;
  }
}

@media (max-width: 1262px) {
  .title {
    font-size: 0.65em;
    text-align: center;
    margin: 1.4em 1.2em;
  }
}

@media (max-width: 1230px) {
  .title {
    font-size: 0.64em;
    text-align: center;
    margin: 1.4em 1em;
  }
}

@media (max-width: 1200px) {
  .activityCard,
  .activityCardTeam {
    margin-bottom: 2em;
  }

  .activityContainer {
    padding: 1.8em 1em 0 1em;
  }
}

@media (min-width: 991px) and (max-width: 1201px) {
  .title {
    font-size: 0.65em;
    text-align: center;
    margin: 1.4em;
  }

  .count {
    font-size: 1.2em;
    text-align: center;
  }
}

@media (max-width: 990px) {
  .count {
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 760px) {
  .count {
    font-size: 1.6em;
    text-align: center;
    font-weight: bold;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .activityCardTeam {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .count {
    font-size: 1.6em;
    text-align: center;
    font-weight: bold;
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .activityCardTeam {
    width: 90%;
  }
}
