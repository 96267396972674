.micrositeBannerImgContainer {
  width: 100%;
  display: block;
  position: relative;
}

.micrositeTabletImgContainer {
  display: none;
  position: relative;
}

.micrositeMobileImgContainer {
  display: none;
  position: relative;
}

.micrositeWebBannerImage {
  width: 100%;
  height: 100%;
  aspect-ratio: 1550 / 450;
  object-fit: cover;
}

.micrositeBannerImage {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.micrositeBannerTitleContainer {
  position: absolute;
  top: 46%;
  left: 5%;
  width: 42%;
  text-align: left;
}

.micrositeBannerTitle {
  font-size: 1.9em;
  font-weight: 600 !important;
}
.micrositeBannerSubtitle {
  font-size: 1.1em;
  margin-top: 3px;
}

/* banner styles for ipad pro  */
@media (min-height: 1365px) and (max-height: 1367px) and (min-width: 1023px) and (max-width: 1025px) {
  .micrositeBannerImgContainer {
    display: none !important;
  }

  .micrositeTabletImgContainer {
    display: block !important;
  }
}

@media (min-width: 2000px) {
  .micrositeBannerImgContainer {
    display: block;
  }

  .micrositeTabletImgContainer {
    display: none;
  }

  .micrositeMobileImgContainer {
    display: none;
  }
}

@media (min-width: 1520px) and (max-width: 1999px) {
  .micrositeBannerImgContainer {
    display: block;
  }

  .micrositeTabletImgContainer {
    display: none;
  }

  .micrositeMobileImgContainer {
    display: none;
  }
}

@media (max-width: 1290px) {
  .micrositeBannerImgContainer {
    display: block;
  }
  .micrositeBannerTitleContainer {
    top: 46%;
    left: 5%;
    width: 43%;
  }

  .micrositeBannerTitle {
    font-size: 1.66em;
  }
  .micrositeBannerSubtitle {
    font-size: 1.1em;
  }
}

@media (max-width: 1162px) {
  .micrositeBannerImgContainer {
    display: block;
  }
  .micrositeBannerTitleContainer {
    top: 45%;
    left: 5%;
    width: 43%;
  }
  .micrositeBannerTitle {
    font-size: 1.55em;
  }
  .micrositeBannerSubtitle {
    font-size: 1.02em;
  }
}

@media (max-width: 1076px) {
  .micrositeBannerImgContainer {
    display: block;
  }
}
@media (max-width: 1040px) {
  .micrositeBannerTitleContainer {
    top: 45%;
    left: 5%;
    width: 43%;
  }
  .micrositeBannerTitle {
    font-size: 1.5em;
  }
  .micrositeBannerSubtitle {
    font-size: 0.96em;
  }
}
@media (max-width: 990px) {
  .micrositeBannerTitleContainer {
    top: 50%;
    left: 6%;
    width: 40%;
  }

  .micrositeBannerTitle {
    font-size: 1.4em;
  }
  .micrositeBannerSubtitle {
    font-size: 0.96em;
  }
}
@media (max-width: 800px) {
  .micrositeBannerTitleContainer {
    top: 50%;
    left: 6%;
    width: 40%;
  }

  .micrositeBannerTitle {
    font-size: 1.3em;
  }
  .micrositeBannerSubtitle {
    font-size: 0.92em;
  }
}
@media (max-width: 730px) {
  .micrositeBannerTitleContainer {
    top: 50%;
    left: 6%;
    width: 40%;
  }

  .micrositeBannerTitle {
    font-size: 1.25em;
  }
  .micrositeBannerSubtitle {
    font-size: 0.9em;
  }
}
@media (max-width: 690px) {
  .micrositeBannerTitleContainer {
    top: 50%;
    left: 6%;
    width: 40%;
  }

  .micrositeBannerTitle {
    font-size: 1.1em;
  }
  .micrositeBannerSubtitle {
    font-size: 0.88em;
  }
}
@media (max-width: 620px) {
  .micrositeBannerTitleContainer {
    top: 50%;
    left: 6%;
    width: 40%;
  }

  .micrositeBannerTitle {
    font-size: 1.05em;
  }
  .micrositeBannerSubtitle {
    font-size: 0.84em;
  }
}

@media (min-width: 1030px) and (max-width: 1144px) {
  .micrositeBannerImgContainer {
    display: block;
    width: 100%;
  }

  .micrositeTabletImgContainer {
    display: none;
  }

  .micrositeMobileImgContainer {
    display: none;
  }
}

@media (min-width: 991px) and (max-width: 1030px) {
  .micrositeBannerImgContainer {
    display: block;
    width: 100%;
  }

  .micrositeTabletImgContainer {
    display: none;
  }

  .micrositeMobileImgContainer {
    display: none;
  }
}

@media (min-width: 665px) and (max-width: 991.5px) {
  .micrositeBannerImgContainer {
    display: none;
  }

  .micrositeTabletImgContainer {
    display: block;
    width: 100%;
    aspect-ratio: 800 / 350;
    /* height: 350px; */
    object-fit: cover;
  }

  .micrositeMobileImgContainer {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 665.5px) {
  .micrositeBannerImgContainer {
    display: none;
  }

  .micrositeTabletImgContainer {
    display: block;
    width: 100%;
    aspect-ratio: 800 / 350;
    /* height: 350px; */
    object-fit: cover;
  }

  .micrositeMobileImgContainer {
    display: none;
  }
}
@media (max-width: 500px) {
  .micrositeBannerTitle {
    font-size: 0.9em;
  }
  .micrositeBannerSubtitle {
    font-size: 0.75em;
  }
}

@media (max-width: 480px) {
  .micrositeBannerTitleContainer {
    top: 26%;
    left: 50%;
    transform: translateX(-50%);
    width: 85%;
    text-align: center;
  }

  .micrositeBannerTitle {
    font-size: 0.88em;
  }
  .micrositeBannerSubtitle {
    font-size: 0.72em;
  }
  .micrositeBannerImgContainer {
    display: none;
  }

  .micrositeTabletImgContainer {
    display: none;
  }

  .micrositeMobileImgContainer {
    display: block;
    width: 100%;
    object-fit: cover;
    /* height: 400px; */
  }
}
@media (max-width: 400px) {
  .micrositeBannerTitleContainer {
    top: 26%;
  }
}

@media (max-width: 290px) {
  .micrositeBannerImgContainer {
    display: none;
  }

  .micrositeTabletImgContainer {
    display: none;
  }

  .micrositeMobileImgContainer {
    display: block;
    width: 100%;
    /* height: 36vh !important; */
    object-fit: cover;
  }
  .micrositeBannerTitleContainer {
    top: 28%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    text-align: center;
  }

  .micrositeBannerTitle {
    font-size: 0.7em;
  }
  .micrositeBannerSubtitle {
    font-size: 0.5em;
  }
}
