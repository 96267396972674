.cardsSection {
  padding: 1%;
}

.title {
  font-size: 0.7em;
  color: var(--qrencia-text-black);
  font-weight: 500;
}

.title:hover {
  color: var(--qrencia-text-black);
}

.linkedText {
  text-decoration: none;
  color: var(--qrencia-text-black);
}

.linkedText:hover {
  color: var(--qrencia-text-black);
}
.followSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 4px;
  border-radius: 2px;
  padding-bottom: 2px;
}
.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.8em;
  z-index: 100;
  width: 60%;
  opacity: 0.95;
}
.courseTitleTooltip {
  background-color: var(--qrencia-white);
  font-weight: 600 !important;
  color: var(--qrencia-text-black) !important;
  font-size: 0.88em;
  z-index: 100;
  max-width: 30em;
  border: 1px solid var(--qrencia-grey-border);
}

.courseTitleTooltip > span {
  background-color: var(--qrencia-white);
  font-weight: 500 !important;
  color: var(--qrencia-text-black) !important;
  font-size: 0.8em;
  z-index: 100;
  max-width: 28em;
}
.singleLine {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.linkedText {
  text-decoration: none;
  color: var(--qrencia-text-black);
}

.linkedText:hover {
  color: var(--qrencia-text-black);
}
.card {
  border: 1px solid var(--qrencia-cards-border);
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 3%;
  margin-bottom: 3%;
  width: 90%;
  display: flex;
  flex-direction: row;
}
.card:hover {
  transition: 0.3s all ease-in-out;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.thumbnailImage {
  object-fit: cover;
  width: 2.5em;
  aspect-ratio: 825/550;
}
.cardBody {
  padding: 3% 3% 3% 3%;
  height: 2.5em;
}

@media (min-width: 1100px) and (max-width: 1185px) {
  .title {
    font-size: 0.7em !important;
  }
}
@media (min-width: 990px) and (max-width: 1100px) {
  .title {
    font-size: 0.68em !important;
  }
}

@media (max-width: 990px) {
  .title {
    font-size: 0.65em !important;
  }
  .card {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .backgrounImageSection {
    padding-top: 0%;
  }
  .card {
    width: 100%;
  }
}

@media (max-width: 580px) {
  .card {
    margin-top: 0%;
    margin-bottom: 6%;
  }
  /* .thumbnailImage {
    height: auto;
    object-fit: cover !important;
  } */
}

@media (max-width: 480px) {
  .cardsSection {
    padding: 1% 0%;
  }
  .title {
    font-size: 0.65em !important;
  }
}
