.mainCompetitionDetailsSectionNavbar #navList {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.mainCompetitionDetailsSectionNavbar .navbar-expand {
  background-color: var(--qrencia-white);
  border-radius: 10px;
  padding: 0;
  border: 1px solid var(--qrencia-light-grey-border);
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

.mainCompetitionDetailsSectionNavbar a {
  padding: 0.5em 0.5em !important;
  font-weight: 600;
  font-size: 0.9em;
  color: var(--qrencia-text-black);
  cursor: pointer;
  text-decoration: none;
}

.mainCompetitionDetailsSectionNavbar .active {
  padding: 0.5em !important;
  color: var(--bs-navbar-active-color);
  border-bottom: 3px solid black !important;
  font-weight: 600;
  font-size: 0.9em;
  color: var(--qrencia-text-black);
}
.highlightSubmission {
  padding: 0.5em 0 !important;
  color: var(--bs-navbar-active-color);
  background-color: #c2e2ff;
  border-radius: 5px;

  border-bottom: 3px solid #c2e2ff !important;
  font-weight: 600;
  font-size: 0.9em;
  color: var(--qrencia-text-black);
}
.highlightSubmission .active {
  padding: 0.5em 0 !important;
  color: var(--bs-navbar-active-color) !important;
  background-color: #c2e2ff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.9em;
  color: var(--qrencia-text-black);
}

/* .quillListIndent .ql-indent-1 {
    list-style-position: inside;
}

.quillListIndent .ql-indent-2 {
    padding-left: 10px;
    list-style-position: inside;
}

.quillListIndent .ql-indent-3 {
    padding-left: 20px;
    list-style-position: inside;
} */

@media (max-width: 580px) {
  .mainCompetitionDetailsSectionNavbar a {
    font-size: 0.75em;
    flex-wrap: wrap;
    padding: 0.5em 0.5em !important;
  }

  .mainCompetitionDetailsSectionNavbar .active {
    font-size: 0.75em;
  }
}
@media (max-width: 475px) {
  .mainCompetitionDetailsSectionNavbar a {
    font-size: 0.64em;
    flex-wrap: wrap;
    padding: 0.4em 0.3em !important;
  }

  .mainCompetitionDetailsSectionNavbar .active {
    font-size: 0.65em;
  }
}
@media (max-width: 440px) {
  .mainCompetitionDetailsSectionNavbar a {
    font-size: 0.64em;
    flex-wrap: wrap;
    padding: 0.4em 0.3em !important;
  }

  .mainCompetitionDetailsSectionNavbar .active {
    font-size: 0.64em;
  }
}
@media (max-width: 390px) {
  .mainCompetitionDetailsSectionNavbar a {
    font-size: 0.6em;
    flex-wrap: wrap;
    padding: 0.4em 0.2em !important;
    font-weight: 550;
  }

  .mainCompetitionDetailsSectionNavbar .active {
    font-size: 0.6em;
  }
}
@media (max-width: 380px) {
  .mainCompetitionDetailsSectionNavbar a {
    font-size: 0.59em;
    flex-wrap: wrap;
    padding: 0.4em 0.2em !important;
    font-weight: 550;
  }

  .mainCompetitionDetailsSectionNavbar .active {
    font-size: 0.59em;
  }
}
