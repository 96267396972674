.actionCell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feeReminderIcon {
  font-size: 17px;
  color: gray;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.feeReminderIcon:hover {
  color: #FABF07  ;
}

.heading{
  font-size: 1rem;
  font-weight: 600;
  color: var(--qrencia-text-black);
  margin-bottom: 1rem;
}

:global(.ag-theme-alpine .ag-cell .ag-cell-value) {
  font-size: 10px !important;
}
.gridIcon {
  font-size: 1.2em !important;
  padding: 2% 2% 2% 2% !important;
  margin: 0.1em !important;
}
.exportButton {
  font-size: 0.8em;
}