.loaderIcon {
  text-align: center;
  font-size: 2em !important;
  color: var(--qrencia-dark-blue) !important;
}
.buttonRow > button {
  margin-right: 1em;
}
.assingedHeading {
  font-size: 1.2em;
  margin-bottom: 1em;
}
