.progressBarContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: Arial, sans-serif;
  }
  
  .progressBar {
    width: 300px;
    height: 0.6rem;
    background-color: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #ddd;
  }
  
  .fill {
    height: 100%;
    transition: width 0.3s ease;
  }
  
  /* Colors based on percentage */
  .low {
    background-color: #d9534f; /* Red */
  }
  
  .medium {
    background-color: #f0ad4e; /* Orange */
  }
  
  .high {
    background-color: #44c0e6; /* Green */
  }

  .complete {
    background-color: #49e971; /* Blue */
  }
  
  .percentageText {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  