.impactCreatedByQrenciaSection {
    padding: 8% 13% 8%;
    text-align: center;
    background-position: center;
}

.heading {
    color: var(--qrencia-text-black);
    font-weight: 600;
    line-height: 1.2em;
    margin-top: -23px;
    font-size: 2em;
    margin-bottom: 1.5%;
}

.blueTriangleImage {
    margin-right: 430px;
    width: 2.3%;
}

.ovalImages {
    text-align: left;
}

span {
    color: var(--qrencia-dark-blue);
    font-weight: 800;
}

.subHeading {
    font-weight: 400;
    font-size: 1.4em;
    margin-top: 1%;
    margin-bottom: 6%;
}

@media(min-width:1129px) {

    .pinkBgOval,
    .blueBgOval {
        width: 35%;
        margin-left: 12% !important;
    }

    .lightPinkBgOval,
    .purpleBgOval {
        width: 35%;
        margin-left: 20% !important;
    }
}

@media(min-width:767px) and (max-width:1290px) {
    .impactCreatedByQrenciaSection {
        padding: 6% 9%;
    }
}

@media(max-width:767px) {
    .impactCreatedByQrenciaSection {
        padding: 9% 3%;
    }
}

@media(min-width:990px) and (max-width:1185px) {
    .heading {
        font-size: 1.7em;
    }

    .blueTriangleImage {
        margin-right: 370px;
        width: 2.3%;
    }

    .subHeading {
        font-size: 1.1em;
    }
}

@media(min-width:767px) and (max-width:990px) {
    .heading {
        font-size: 1.5em;
    }

    .subHeading {
        font-size: 0.9em;
    }

    .blueTriangleImage {
        margin-right: 324px;
        width: 2.3%;
    }
}

@media(max-width:767px) {
    .heading {
        font-size: 1.5em;
    }

    .subHeading {
        font-size: 0.8em;
    }

    .blueTriangleImage {
        margin-right: 328px;
        width: 4%;
    }

    .lightPinkBgOval,
    .purpleBgOval,
    .pinkBgOval,
    .blueBgOval {
        width: 38%;
        margin-left: 55px !important;
    }

    .impactCreatedByQrenciaSection {
        background-image: none !important;
    }
}

@media(min-width:1130px) {

    .lightPinkBgOval,
    .purpleBgOval {
        margin-left: 25px;
    }
}

@media(min-width:767px) and (max-width:1130px) {

    .lightPinkBgOval,
    .purpleBgOval,
    .pinkBgOval,
    .blueBgOval {
        width: 50%;
    }
}

@media(min-width:500px) and (max-width:576px) {

    .lightPinkBgOval,
    .purpleBgOval,
    .pinkBgOval,
    .blueBgOval {
        width: 35%;
        margin-left: 50px !important;
    }
}

@media(min-width:538px) and (max-width:542px) {

    
    .pinkBgOval,
    .blueBgOval {
        margin-left: 50px !important;
        width: 28%;
    }
    .lightPinkBgOval,
    .purpleBgOval{
        margin-left: 50px !important;
        width: 28%;
        margin-top: 12px;
    }
}

@media(max-width:500px) {

    .lightPinkBgOval,
    .purpleBgOval,
    .pinkBgOval,
    .blueBgOval {
        width: 40%;
        margin-left: 20px !important;
    }
}

@media(min-width:480px) and (max-width:540px) {
    .heading {
        font-size: 1.3em;
        margin-top: -21px;
    }

    .blueTriangleImage {
        margin-right: 285px;
        width: 6%;
    }

}

@media(max-width:480px) {
    .heading {
        font-size: 1.12em;
    }

    .blueTriangleImage {
        margin-right: 245px;
        width: 6%;
        margin-bottom: 6px;
    }

    
    .pinkBgOval,
    .blueBgOval {
        width: 38% !important;
        margin-left: 22px !important;
    }

    .lightPinkBgOval,
    .purpleBgOval{
        width: 38% !important;
        margin-left: 24px !important;
        margin-top:15%;
    }

    .subHeading {
        font-size: 0.88em;
    }
}

@media(max-width:360px) {
    .heading {
        font-size: 1.12em;
    }
}
@media(min-width:768px) and (max-width:819px){
    .heading {
        font-size: 1.35em;
    }
    .blueTriangleImage{
        margin-right: 294px;
        width: 4.3%;
    }
}