.eventPage {
  background-color: #eaf3ff;
  width: 100%;
  min-height: 100vh;
  /* padding-top: 1em;
  padding-bottom: 1em; */
}
.authPage {
  padding-top: 1em;
  padding-bottom: 1em;
}
.quizContainer {
  background-color: var(--qrencia-white);
  box-shadow: 4px 4px 18px rgba(137, 141, 154, 0.3);
  border-radius: 10px;
  padding: 2em 3em;
  width: 80%;
  margin: auto;
  margin-top: 3em;
  margin-bottom: 3em;
}
.resultContainer {
  background-color: var(--qrencia-white);
  box-shadow: 4px 4px 18px rgba(137, 141, 154, 0.3);
  border-radius: 10px;
  padding: 2em 3em;
  width: 80%;
  margin: auto;
  margin-top: 3em;
  margin-bottom: 3em;
  text-align: center;
}
.resultScore {
  font-size: 1.5em;
  color: var(--qrencia-dark-blue);
  font-weight: 500;
  margin-top: 0.4em;
  margin-bottom: 0.5em;
}
.resultSuccessIcon {
  height: 2.8em;
  width: 2.8em;
  margin-left: auto;
  margin-right: auto;
}
.scoreSection {
  text-align: left;
  margin: 1em 0em;
  margin-top: 1.5em;
}
.successMessage {
  text-align: center;
  font-size: 1.2em;
}
.copyCodeIcon:active {
  border: none;
  outline: none;
}
.copyCodeIcon svg:active {
  border: none;
  outline: none;
}
.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.7em;
  z-index: 100;
  width: 12em;
  color: var(--qrencia-text-black);
  opacity: 1;
}

.successCodeMessage {
  font-size: 1.1em;
  margin-top: 0.5em;
}
.successCodeMessage span {
  font-weight: 450 !important;
  position: relative;
}
.successCodeMessage svg {
  border: none;
  outline: none;
}
.attemptBadge {
  color: var(--qrencia-white);
  background-color: var(--twitter-blue) !important;
  font-size: 0.7em;
  padding: 0.8em 1em;
}
.questionTextHeading {
  font-weight: 450;
  font-size: 0.8em;
  margin-bottom: 0.1em;
}
.inputFieldContainer input {
  border-radius: 5px !important;
}
.questionTextDescription {
  color: var(--qrencia-dark-blue);
  font-weight: 450;
  font-size: 1.1em;
  margin-bottom: 1em;
}
.buttonSection {
  text-align: right;
}
.radioLabelButton {
  font-weight: 500;
  font-size: 0.85em;
  background-color: var(--qrencia-white) !important;
  margin-left: 6px;
  padding: 0em 0.3em 0em 0em;
  z-index: 1;
  margin-bottom: 0.7em;
}
.formcheck input {
  padding: 7px !important;
  cursor: pointer;
  font-size: 0;
  margin-top: 2px;
}
.options {
  font-size: 1em;
  border-radius: 20px;
  /* color: var(--qrencia-text-black); */
  color: #474747;
  background-color: #eaf3ff;
  max-width: 25em;
  padding: 0.6em 1.2em;
  margin-bottom: 1.2em;
  margin-top: 1.2em;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  cursor: pointer;
}

.options:active {
  transform: scale(0.99);
}
.selectedOption {
  background-color: var(--twitter-blue) !important;
  color: #fffae5;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.formcheck label {
  margin-left: 6px;
}
.nextBtn {
  padding: 0.2em 1.4em;
  font-size: 0.9em;
  margin-top: 1.8em;
}
.nextBtn > svg {
  font-size: 0.8em;
}
.captchaContainer {
  background-color: var(--qrencia-white);
  box-shadow: 4px 4px 18px rgba(137, 141, 154, 0.3);
  border-radius: 10px;
  padding: 3em 1em 3em 1em;
  width: 50%;
  margin: auto;
}
.captchaContainer p {
  text-align: center;
}
.captchaPage {
  margin-top: 6em;
  margin-bottom: 6em;
}
.captchaPageHeading {
  text-align: center;
  font-size: 2em;
  color: var(--qrencia-dark-blue);
  font-weight: 400;
  margin-top: -0.8em;
}
.captchaPageSubtext {
  text-align: center;
  margin-bottom: 2em;
  font-size: 1.2em;
  margin-top: 0.4em;
}
.resultLoaderContainer {
  background-color: var(--qrencia-white);
  box-shadow: 4px 4px 18px rgba(137, 141, 154, 0.3);
  border-radius: 10px;
  padding: 3em 1em 3em 1em;
  width: 80%;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 1em;
}
.captchaContainer div {
  margin-left: auto;
  margin-right: auto;
}
.copyCodeIcon {
  cursor: pointer;
  position: relative;
}
.copyCodeIcon:active {
  transform: scale(0.9);
}
.preLoaderContainer {
  width: 100%;
  position: relative;
  height: 30vh;
}

.preLoaderDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrenciaGlobe {
  width: 80px;
  margin-top: -98px;
}

.loaderText {
  padding: 2em 0 0 0;
  font-size: 1em;
  text-align: center;
}

.loaderIcon {
  font-size: 6em !important;
  color: var(--qrencia-dark-blue) !important;
  text-align: center;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(5px);
  }
  60% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
.correctOption {
  background-color: #52be80 !important;
  color: #fffae5;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.wrongOption {
  background-color: #ec7063 !important;
  color: #fffae5;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  animation: shake 300ms ease;
}
.loader {
  --b: 8px;
  /* border thickness */
  --n: 12;
  /* number of dashes*/
  --g: 10deg;
  /* gap between dashes*/
  --c: var(--qrencia-dark-blue);
  /* the color */

  width: 120px;
  /* size */
  aspect-ratio: 1;
  border-radius: 100%;
  padding: 1px;
  background: conic-gradient(#0000, var(--c)) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: load 1s infinite steps(var(--n));
}
@media (max-width: 1200px) {
  .captchaPageHeading {
    font-size: 1.8em;
    margin-top: -0.8em;
  }
  .captchaPageSubtext {
    margin-bottom: 2em;
    font-size: 1em;
    margin-top: 0.4em;
  }
}
@media (max-width: 991px) {
  .captchaContainer {
    padding: 2em 1em;
    width: 60%;
  }
  .captchaPageHeading {
    font-size: 1.6em;
    margin-top: -0.8em;
  }
  .captchaPageSubtext {
    margin-bottom: 2em;
    font-size: 1em;
    margin-top: 0.4em;
  }
}
@media (max-width: 650px) {
  .quizContainer {
    padding: 1.5em 1.5em;
    width: 90%;
    margin: auto;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .resultContainer {
    padding: 1.5em 1em;
    width: 90%;
  }
  .questionTextHeading {
    font-size: 0.8em;
  }
  .questionTextDescription {
    font-size: 0.9em;
  }
  .attemptBadge {
    font-size: 0.65em;
  }
  .resultScore {
    font-size: 1.4em;
    color: var(--qrencia-dark-blue);
    font-weight: 500;
    margin-top: 0.4em;
    margin-bottom: 0.5em;
  }
  .resultSuccessIcon {
    height: 2.5em;
    width: 2.5em;
    margin-left: auto;
    margin-right: auto;
  }
  .captchaContainer {
    padding: 2em 1em;
    width: 80%;
  }
  .successMessage {
    text-align: center;
    font-size: 1.2em;
  }
  .successCodeMessage {
    font-size: 1em;
    margin-top: 0.5em;
  }
  .options {
    font-size: 0.9em;
  }
}
@media (max-width: 480px) {
  .quizContainer {
    padding: 1.5em 1em;
    width: 90%;
    margin: auto;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .captchaContainer {
    padding: 2em 1em;
    width: 90%;
  }
  .options {
    font-size: 0.8em;
  }
  .resultScore {
    font-size: 1.4em;
    color: var(--qrencia-dark-blue);
    font-weight: 500;
    margin-top: 0.4em;
    margin-bottom: 0.5em;
  }
  .resultSuccessIcon {
    height: 2.4em;
    width: 2.4em;
    margin-left: auto;
    margin-right: auto;
  }
  .scoreSection {
    text-align: left;
    margin: 1em 0em;
    margin-top: 1.5em;
  }
  .successMessage {
    text-align: center;
    font-size: 1.1em;
  }
  .successCodeMessage {
    font-size: 0.9em;
    margin-top: 0.5em;
  }
  .nextBtn {
    padding: 0.2em 1.4em;
    font-size: 0.75em;
    margin-top: 1.8em;
  }
  .nextBtn > svg {
    font-size: 0.75em;
  }
  .captchaPageHeading {
    font-size: 1.5em;
    margin-top: -0.8em;
  }
  .captchaPageSubtext {
    margin-bottom: 2em;
    font-size: 0.95em;
    margin-top: 0.4em;
  }
}
@media (max-width: 400px) {
  .captchaPageHeading {
    font-size: 1.4em;
    margin-top: -0.8em;
  }
  .captchaPageSubtext {
    margin-bottom: 2em;
    font-size: 0.95em;
    margin-top: 0.4em;
  }
}
@media (max-width: 375px) {
  .captchaContainer {
    padding: 1.8em 0.8em;
    width: 92%;
  }
}
@keyframes load {
  to {
    transform: rotate(1turn);
  }
}
