.courseTextColor {
  color: var(--qrenica-course-differentiator-color);
}

.competitionTextColor {
  color: var(--qrenica-competition-differentiator-color);
}

.detailsHeading {
  font-size: 1em;
  color: var(--qrencia-dark-blue);
}
.tableRow{
  padding-right: 0em;
  padding-left: 0em;
}
.detailsLabel {
  color: var(--qrencia-dark-blue);
  font-size: 0.85em;
  font-weight: 500 !important;
}
.detailsText {
  font-size: 0.85em;
}
.detailsColumContainer {
  margin-top: 1em;
}
.detailsLinkLabel,
.detailsLinkLabel:hover,
.detailsLinkLabel:active,
.detailsLinkLabel:focus {
  text-decoration: none;
  font-size: 0.75em !important;
  cursor: pointer;
  color: var(--qrencia-text-black);
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg);
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  padding: 1px 8px;
}
.cardTitle {
  font-size: 1.18em;
  color: var(--qrencia-white);
  font-weight: 500 !important;
  text-align: center;
}
.countCards {
  margin-right: 2.2em;
  margin-top: 1.2em;
  width: 12em;
  border: none;
  outline: none;
}
.dashboardCards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.enrolmentCount {
  color: var(--qrencia-white);
}
.cardValue {
  font-size: 1.8em;
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: -0.2em !important;
}
.modalCloseButton {
  font-size: 0.8em;
}
.enrolmentCard {
  color: var(--qrencia-white);
  background-color: var(--qrenica-competition-differentiator-color);
}
.attemptCard {
  background-color: var(--qrenica-course-differentiator-color);
}
.submissionCard {
  background-color: var(--qrenica-nudge-differentiator-color);
}
.absentCard {
  background-color: var(--qrencia-mentorship-differentiator-color);
}
.notAttemptCard {
  background-color: var(--qrencia-workshop-differentiator-color);
}
.noDataError {
  padding: 3em 1em;
  margin: 1em 0;
  text-align: center;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  color: var(--qrencia-grey-text);
  font-style: italic;
  font-weight: 400 !important;
  border: 1px solid var(--qrencia-cards-border);
}
.cardsContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0.6em;
  margin-top: 0.5em;
}
.learningActivityCards {
  border-left: 0.5em solid var(--qrencia-dark-blue);
  border-radius: 0.4em;
  color: var(--qrencia-dark-blue);
  padding: 0em;
}
.learningActivityCardsIcon {
  color: var(--qrencia-dark-blue);
}
.learningActivityCardsColumn {
  padding: 1em 0em;
  font-size: 0.8em;
  color: var(--qrencia-dark-blue);
}
.showGrid {
  display: block;
}
.showCards {
  display: none;
}
.eventTypeField {
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg);
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  font-weight: 600 !important;
}

span.eventTypeField {
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg) !important;
  /* color: var(--qrencia-text-black); */
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  font-weight: 600 !important;
}

.loadMoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
}
.obrCardsButtonContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0.6em;
  margin: 0.5em 0;
}
@media (max-width: 991px) {
  .showGrid {
    display: none;
  }
  .showCards {
    display: block;
  }
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 14em;
  }
  .cardValue {
    font-size: 1.6em;
    font-weight: 600 !important;
    text-align: center;
    margin-bottom: -0.2em !important;
  }
  .countCards {
    margin-right: 2.2em;
    margin-top: 1.2em;
    width: 11em;
  }
  .detailsLabel {
    font-size: 0.8em;
  }
  .detailsText {
    font-size: 0.8em;
  }
}
@media (max-width: 584px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 11.2em;
  }
  .dashboardCards {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  .cardValue {
    font-size: 1.5em;
  }
  .detailsLabel {
    font-size: 0.77em;
  }
  .detailsText {
    font-size: 0.77em;
  }
  .countCards {
    margin-right: 2.2em;
    margin-top: 1.2em;
    width: 10em;
  }
}
@media (max-width: 494px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 16em;
  }
  .dashboardCards {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  .cardValue {
    font-size: 1.4em;
  }
  .countCards {
    margin-right: 2.2em;
    margin-top: 1.2em;
    width: 10em;
  }
}
