.errorContainer {
  padding: 2em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-grey-border);
  margin: 2% 1%;
  text-align: center;
  background-color: var(--qrencia-white);
}

.editPopupHeading {
  margin-bottom: 0.5em !important;
  font-size: 1em !important;
  font-weight: 400;
}

.awardContainers {
  margin-left: 0.8em;
}

.learnerProfileContainer {
  padding: 0% 1% 1% 1%;
}

.learnerBannerSection {
  width: 100%;
  display: block;
  position: relative;
}

.achievmentTitle {
  font-size: 1.3em;
  font-weight: 600;
}

.achievmentHeader {
  padding: 0.6em 1em;
}

.statusWrapper {
  border-radius: 10px;
  text-align: center;
  padding: 6px 5px 6px 5px;
  position: absolute;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  color: var(--qrencia-text-black);
  font-weight: bold;
  text-decoration: none;
  border: 2px solid transparent;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.taglineSection {
  display: flex;
}

.editTaglineInputSection {
  display: flex;
  width: 100%;
}

.editTaglineInput {
  width: 95%;
}

.editButton {
  margin-left: 1%;
  height: 2.5em !important;
  font-size: 0.8em !important;
  padding: 0 1.5em !important;
}

.updateButton {
  margin-left: 1%;
  font-size: 0.8em !important;
  padding: 0 1.5em !important;
}

.statusWrapper::before {
  content: '';
  position: absolute;
  inset: -2px;
  border-radius: 5px;
  background: linear-gradient(
    45deg,
    var(--qrencia-button-pink),
    var(--qrencia-golden-color),
    var(--qrencia-blue-background-course-cost)
  );
  background-size: 300%;
  z-index: -1;
  animation: animateBorder 4s ease infinite;
}

.uploadFormLabel {
  font-weight: 500;
  font-size: 0.8em;
  background-color: var(--qrencia-white);
  margin-bottom: 0.3em;
}

.statusWrapper::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 6px;
  z-index: -1;
}

@keyframes animateBorder {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.championBadge {
  font-size: 0.4em;
  margin-left: 3em;
  color: var(--qrencia-white);
}

.learnerProfileBannerImage {
  width: 100%;
  height: 100%;
  aspect-ratio: 1550 / 450;
}

.learnerDetailSection {
  background: var(--qrencia-white);
  border-radius: 0% 0% 20px 20px;
  padding: 2.5%;
}

.learnerDetailsInnerSection {
  display: flex;
  align-items: center;
}

.profileImageSection {
  display: flex;
  justify-content: center;
  font-size: 2.5em;
  align-items: center;
  background: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  border-radius: 18px;
  width: 220px;
  height: 220px;
}

.userInititals {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 220px;
  border-radius: 16px;
  object-fit: cover;
}

.profileImage {
  width: 220px;
  height: 220px;
  border-radius: 16px;
  object-fit: cover;
}

.aboutLearnerSection {
  width: 100%;
  padding-left: 2%;
}

.learnerDetailsHeading {
  font-size: 2em;
  font-weight: 700;
  color: var(--qrencia-dark-blue);
}

.learnerDetailsSubHeading {
  font-size: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.learnerProgressBar {
  margin-top: 2em;
  height: 1em;
}

.learnerAwardsSection {
  display: flex;
  justify-content: flex-start;
  margin-top: 2%;
}

.awardSection {
  align-items: center;
  margin-right: 2.5%;
  padding: 1%;
  display: inline-flex;
}

.awardIcon {
  padding: 18%;
  font-size: 2.8em;
  background: var(--qrencia-white);
  box-shadow: rgba(67, 71, 85, 0.4) 0px 0px 0.35em;
  border-radius: 10px;
}

.imageBox {
  flex: 1 1 calc(25% - 1em);
  height: 12em;
  /* width: 12em; */
  border: 1px solid var(--qrencia-white) !important;
  padding: 0px !important;
  position: relative;
  overflow-clip-margin: content-box;
  cursor: pointer;
  overflow: hidden;
  border-radius: 2px;
  object-fit: cover;
  margin-right: 2px;
}

.awardTitle {
  font-size: 1.6em;
  font-weight: 700 !important;
}

.learnerBannerTitleSection {
  position: absolute;
  top: 46%;
  left: 5%;
  width: 42%;
  text-align: left;
}

.bannerTitle {
  font-size: 1.9em;
  font-weight: 600 !important;
}

.editImageButton {
  position: absolute;
  top: 10px;
  right: 20px;
  border-radius: 50%;
  width: 45px;
  height: 40px;
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  border: none;
  transition: 0.3s all ease;
  padding: 0;
}

.editBanner {
  font-size: 1.5em;
  transition: 0.3s all ease;
}
.editBanner:hover {
  color: var(--qrencia-dark-blue);
}
.editImageButton:hover {
  background-color: var(--qrencia-white) !important;
}
.editImageButton:hover svg {
  color: var(--qrencia-dark-blue);
}

.achievmentTitle {
  font-size: 1.3em;
  font-weight: 600;
}

.achievmentHeader {
  padding: 0.6em 1em;
}

.uploadInfo {
  font-size: 0.8em;
}

.infoButton {
  color: var(--qrencia-dark-blue);
  margin-left: 0.3em;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  z-index: 10;
  max-width: 25em;
}

.leftText {
  margin-right: 1em;
}
.rightText {
  margin-right: 1em;
}

.bannerDescription {
  font-size: 1.1em;
  margin-top: 3px;
}

.awardSubTitle {
  font-size: 0.8em;
  margin-top: -0.5em;
  white-space: nowrap;
  overflow-x: auto;
}

.learnerInformationSection {
  margin-bottom: 1%;
}

.awardSectionMobileView {
  display: none;
}

.errorMessage {
  color: var(--qrencia-error-message);
  font-size: 0.8em;
}

.shareIconPannelContainer {
  color: var(--qrencia-white);
  display: grid;
  position: fixed;
  padding: 1% 0.8% 0.5% 0.7%;
  top: 50%;
  right: 0%;
  background: var(--qrencia-dark-blue);
  border-top: 2px solid var(--qrencia-white);
  border-bottom: 2px solid var(--qrencia-white);
  border-left: 2px solid var(--qrencia-white);
  border-radius: 10px 0px 0px 10px;
  z-index: 1000;
}

.shareIcons {
  margin-bottom: 32%;
  border-radius: 20px;
  cursor: pointer;
  width: 23px;
  height: 23px;
}

.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  padding: 10%;
  width: 23px;
  height: 23px;
  margin-bottom: 32%;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.uploadForm {
  padding: 0 !important;
  font-size: 0.9em !important;
}

@media (max-width: 1290px) {
  .learnerDetailsHeading {
    font-size: 1.8em;
  }

  .bannerTitle {
    font-size: 1.5em;
  }

  .bannerDescription {
    font-size: 1em;
  }

  .learnerDetailsSubHeading {
    font-size: 1em;
  }

  .awardIcon {
    font-size: 2.8em;
  }

  .awardTitle {
    font-size: 1.2em;
  }

  .awardSubTitle {
    font-size: 0.65em;
  }
}

@media (max-width: 1080px) {
  .learnerDetailsHeading {
    font-size: 1.5em;
  }

  .bannerTitle {
    font-size: 1.2em;
  }

  .bannerDescription {
    font-size: 0.8em;
  }

  .learnerDetailsSubHeading {
    font-size: 1.1em;
  }

  .awardIcon {
    font-size: 2.5em;
  }

  .awardTitle {
    font-size: 1.2em;
  }

  .awardSubTitle {
    font-size: 0.61em;
  }
}

@media (max-width: 991.9px) {
  .awardSection {
    margin-right: 0%;
  }

  .learnerProgressBar {
    margin-top: 1.2em;
  }

  .learnerDetailsSubHeading {
    font-size: 1em;
  }
}

@media (max-width: 856px) {
  .awardSection {
    margin-right: 6%;
  }

  .learnerDetailsSubHeading {
    font-size: 1em;
  }
}

@media (max-width: 767.9px) {
  .learnerInformationSection {
    margin-bottom: 3%;
  }

  .awardSection {
    margin-right: 0%;
  }

  .bannerTitle {
    font-size: 1.1em;
  }

  .bannerDescription {
    font-size: 0.7em;
  }

  .learnerDetailsHeading {
    font-size: 1.2em;
  }

  .learnerDetailsSubHeading {
    font-size: 1em;
  }

  .learnerProgressBar {
    margin-top: 0.5em;
  }

  .awardIcon {
    font-size: 2em;
  }

  .awardTitle {
    font-size: 1em;
  }

  .editImageButton {
    width: 40px;
    height: 35px;
  }

  .editBanner {
    font-size: 1.3em;
  }
}

@media (min-width: 660px) and (max-width: 767.9px) {
  .learnerProgressBar {
    margin-top: 2em;
  }
}

@media (max-width: 660px) {
  .awardSection {
    margin-right: 8%;
  }

  .bannerTitle {
    font-size: 0.7em;
  }

  .bannerDescription {
    font-size: 0.4em;
  }
}

@media (max-width: 560px) {
  .learnerProfileContainer {
    padding: 0% 3% 3% 3%;
  }

  .awardSection {
    margin-right: 9%;
  }

  .awardSectionMobileView {
    display: block;
    margin-top: 4%;
  }

  .awardSectionDesktopView {
    display: none;
  }

  .profileImageSection {
    width: 100px;
    height: 100px;
  }

  .profileImage {
    width: 100px;
    height: 100px;
  }

  .editImageButton {
    width: 35px;
    height: 30px;
  }

  .editBanner {
    font-size: 1.1em;
  }

  .learnerDetailsSubHeading {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9em;
  }

  .bannerTitle {
    font-size: 0.4em;
    margin-bottom: 2%;
  }

  .bannerDescription {
    font-size: 0.3em;
  }
}

@media (min-width: 447px) and (max-width: 560px) {
  .awardSection {
    margin-right: 20%;
  }
}

@media (max-width: 467px) {
  .learnerDetailsHeading {
    font-size: 1.1em;
  }
}

@media (max-width: 435px) {
  .learnerDetailsHeading {
    font-size: 1em;
  }

  .championBadge {
    font-size: 0.39em;
  }
  .editImageButton {
    width: 30px;
    height: 25px;
  }

  .editBanner {
    font-size: 0.9em;
  }
}

@media (max-width: 400px) {
  .learnerDetailsHeading {
    font-size: 1em;
  }

  .championBadge {
    font-size: 0.39em;
    margin-left: 1.5em;
  }
}

@media (max-width: 385px) {
  .learnerDetailsHeading {
    font-size: 1em;
  }

  .championBadge {
    font-size: 0.35em;
    margin-left: 1.5em;
  }
}

@media (max-width: 380px) {
  .learnerDetailsHeading {
    font-size: 0.9em;
  }

  .championBadge {
    font-size: 0.4em;
    margin-left: 1.5em;
  }
}

@media (max-width: 365px) {
  .learnerDetailsHeading {
    font-size: 0.9em;
  }

  .championBadge {
    font-size: 0.37em;
    margin-left: 1.5em;
  }
}

@media (max-width: 355px) {
  .learnerDetailsHeading {
    font-size: 0.85em !important;
  }

  .championBadge {
    font-size: 0.34em;
    margin-left: 1.5em;
  }
}

@media (max-width: 992px) {
  .editButton {
    font-size: 0.75em !important;
  }

  .updateButton {
    font-size: 0.75em !important;
  }
}

@media (max-width: 900px) {
  .editButton {
    height: 2.3em !important;
    font-size: 0.75em !important;
  }

  .updateButton {
    font-size: 0.75em !important;
  }
}

@media (max-width: 476px) {
  .uploadFormLabel {
    font-size: 0.75em;
  }
}
@media (max-width: 438px) {
  .editButton {
    font-size: 0.65em !important;
  }

  .updateButton {
    font-size: 0.65em !important;
  }

  .uploadForm {
    font-size: 0.82em !important;
  }
}

@media (max-width: 410px) {
  .uploadFormLabel {
    font-size: 0.65em !important;
  }
}
