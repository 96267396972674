.obrGridHeader {
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  font-weight: bold;
}
.gridIcon {
  font-size: 0.9em !important;
  padding: 0% 4% 2.5% 4% !important;
  margin-top: -0.34em !important;
  margin-left: 5px;
}
.dateFilterText {
  cursor: pointer;
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  border-radius: 5px;
  text-align: center;
  padding: 0.2em 1em;
  min-width: 11em;
  width: fit-content;
  font-size: 0.8em;
  line-height: 2.6em;
  height: 3em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 1.2%;
}
.dateFilterText > svg {
  margin-left: 0.2em;
}
.dateFilterTextDisabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.downloadButton {
  font-size: 0.8em;
  margin-top: 1em;
}
.nudgeDetailsHeading {
  font-size: 1.1em;
  font-weight: 600;
}
.exportButton {
  font-size: 0.8em;
}
