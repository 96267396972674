.markAttendanceButton {
  font-size: 0.9em;
}
.noCoursesAvailableSection {
  font-size: 0.8em;
  text-align: left;
  padding: 2% 0%;
  margin: 6% 0% 4% 0%;
  font-weight: 400;
  border-radius: 10px;
  color: var(--qrencia-text-black);
}
@media (min-width: 1169px) and (max-width: 1286px) {
  .noCoursesAvailableSection {
    font-size: 0.8em;
  }
}

@media (min-width: 992px) and (max-width: 1168.5px) {
  .noCoursesAvailableSection {
    font-size: 0.8em;
  }
}
@media (min-width: 768px) and (max-width: 991.5px) {
  .noCoursesAvailableSection {
    font-size: 0.8em;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .noCoursesAvailableSection {
    margin-top: 1em;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
