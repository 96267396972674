.heading {
  font-size: 1rem;
  font-weight: 600;
  color: var(--qrencia-text-black);
  margin-bottom: 1rem;
}

:global(.ag-theme-alpine .ag-cell .ag-cell-value) {
  font-weight: normal !important;
  color: black !important;
  font-size: 10px !important;
}
.exportButton {
  font-size: 0.8em;
}
