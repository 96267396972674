.recentActivitiesContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.2rem;
  padding: 0.5rem 1em 1em 0em;
  height: 85%;
  overflow-y: auto;
}

.activityCard {
  display: flex;
  justify-content: space-between;
  transition: transform 0.3s ease;
  gap: 1rem;
}

.activityEdit svg {
  justify-self: end;
  display: flex;
}

.approvedSeal {
  /* width: 3.7rem; */
  font-size: 1.5em;
}

.editSwitch .form-check-input {
  transform: scale(0.55);
}

.editLabel {
  font-size: 0.85rem;
  font-weight: 400 !important;
  color: var(--qrencia-text-black);
}

.iconTextContainer {
  flex: 0 0 auto;
  justify-content: flex-start;
}

.titleCreatorContainer {
  flex: 8;
  justify-content: flex-start;
  position: relative;
}

.dateContainer {
  margin-top: 1%;
}

.activityTitle {
  font-size: 1rem;
  font-weight: 500 !important;
  margin: 1rem 0 0.5rem 0;
  color: #333;
}

.activityDate {
  font-size: 0.7rem;
  color: #666;
  font-weight: 500 !important;
}

.learningActivityName {
  color: var(--qrencia-text-black);
  font-weight: 600 !important;
}

.activityTextContainer {
  margin-bottom: 1%;
}

.selfDeclaredContainer {
  float: right;
}

.creatorText {
  font-weight: 500 !important;
  font-size: 0.8rem !important;
}

.creatorName {
  text-decoration: none;
}

.creatorName:hover {
  color: var(--qrencia-dark-blue);
}

.activityChip {
  padding: 5px 10px;
  border-radius: 12px;
  font-weight: 600 !important;
  font-size: 9px !important;
  text-transform: uppercase;
  margin-left: 10px;
}

.activityEdit {
  margin-top: 0.2em;
  display: flex;
}

@media(min-width:1020px) {
  .activityEdit {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 1260px) {
  .approvedSeal {
    /* width: 3.7rem; */
    font-size: 1.6em;
  }
}

@media (min-width: 1192px) and (max-width: 1260px) {
  .activityTextContainer {
    font-size: 0.9em;
  }
}

@media (max-width: 1193px) {
  .activityTextContainer {
    font-size: 0.85em;
  }

  .approvedSeal {
    font-size: 1.7em;
  }
}

@media (max-width: 992px) {
  .activityTextContainer {
    font-size: 0.8em;
  }

  .creatorText {
    font-size: 0.75rem;
  }

  .approvedSeal {
    font-size: 1.8em;
  }

  .editLabel {
    font-size: 0.75rem;
  }

  .activityEdit svg {
    font-size: 0.75rem;
  }
}

@media (min-width: 767.8px) and (max-width: 860px) {
  .achievementEdit {
    top: 22px;
    right: 8px;
  }

  .editLabel {
    font-size: 0.7rem;
  }
}

@media (max-width: 767.8px) {
  .activityEdit {
    display: flex;
  }

  .recentActivitiesContainer {
    padding: 0.5%;
  }

  .activityTextContainer {
    font-size: 0.9em;
  }

  .approvedSeal {
    font-size: 1.6em;
  }
}

@media (max-width: 565px) {
  .activityTextContainer {
    font-size: 0.8em;
  }

  .approvedSeal {
    font-size: 1.8em;
  }
}

@media (max-width: 436px) {
  .activityTextContainer {
    font-size: 0.75em;
  }

  .editLabel {
    font-size: 0.75rem;
  }

  .approvedSeal {
    font-size: 1.9em;
  }
}