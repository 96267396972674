.cardsSection {
  background-color: var(--qrencia-white);
  padding: 1% 12%;
}

.quizTitle {
  font-size: 0.9em;
  color: var(--qrencia-text-black);
  font-weight: 500;
}

.quizTitle:hover {
  color: var(--qrencia-text-black);
}

.linkedText {
  text-decoration: none;
  color: var(--qrencia-text-black);
}

.linkedText:hover {
  color: var(--qrencia-text-black);
}
.followSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 4px;
  border-radius: 2px;
  padding-bottom: 2px;
}
.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.8em;
  z-index: 100;
  width: 60%;
  opacity: 0.95;
}
.courseTitleTooltip {
  background-color: var(--qrencia-white);
  font-weight: 600 !important;
  color: var(--qrencia-text-black) !important;
  font-size: 0.88em;
  z-index: 100;
  max-width: 30em;
  border: 1px solid var(--qrencia-grey-border);
}
.quizParticipationCount {
  font-size: 0.75em;
}
.startButton {
  font-size: 0.82em;
  min-width: 6em;
}
.entriesSection svg {
  margin-top: -2px;
}
.courseTitleTooltip > span {
  background-color: var(--qrencia-white);
  font-weight: 500 !important;
  color: var(--qrencia-text-black) !important;
  font-size: 0.8em;
  z-index: 100;
  max-width: 28em;
}
.singleLine {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.shareIconPannelContainer {
  display: grid;
  position: absolute;
  right: -1.3em;
  top: 20px;
  gap: 5px;
}

.shareIcon {
  font-size: 1.2em;
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  border-radius: 20px;
  cursor: pointer;
  position: absolute;
  right: 7px;
  top: 7px;
  padding: 2px;
}

.shareOnLinkedIn {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnWhatsapp {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnFacebook {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnTwitter {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnInstagramButton {
  color: var(--qrencia-white);
  font-size: 1em;
  cursor: pointer;
}

.shareOnMailButton {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1em;
  cursor: pointer;
}
.shareIcons {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}
.copyToClipboard {
  color: var(--qrencia-card-blue);
  font-size: 1em;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  font-size: 1em;
  font-weight: bold;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.linkedText {
  text-decoration: none;
  color: var(--qrencia-text-black);
}

.linkedText:hover {
  color: var(--qrencia-text-black);
}
.quizCard {
  border: 1px solid var(--qrencia-cards-border);
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 4%;
  margin-bottom: 12px;
  width: 100%;
}
.quizCard:hover {
  transition: 0.2s all ease-in-out;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transform: scale(1.01);
  cursor: pointer;
}
.thumbnailImage {
  border-radius: 6px 6px 0px 0px;
  object-fit: cover;
  aspect-ratio: 825/550;
}
.quizCardBody {
  padding: 0% 2.5% 3% 2.5%;
}
.quizCardBorder {
  border-top: 2px solid var(--qrencia-dark-blue);
}
.academyCardBorder {
  border-top: 2px solid var(--qrencia-academy-card-top-border);
}
.sectionHeading {
  color: var(--qrencia-text-black);
  font-weight: 600;
  line-height: 1.2em;
  font-size: 2em;
  text-align: center;
  margin-bottom: 1em;
}
.loadMoreDiv {
  padding: 1em;
}
.typeSubtitle {
  color: var(--qrencia-dark-blue) !important;
  font-weight: 550 !important;
  margin-bottom: 4px;
}
.loadMore {
  text-align: center;
  background: var(--qrencia-white);
}

.loadMorebtn {
  background: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  padding: 0.5em 2em;
  text-decoration: none;
  letter-spacing: 0.03rem;
  color: var(--qrencia-white);
  background: var(--qrencia-dark-blue);
  border: 0.1em solid var(--qrencia-dark-blue);
  font-size: 0.8em;
  border-radius: 0.3em;
  font-weight: 500;
}

.loadMorebtn:hover {
  color: var(--qrencia-text-black);
  background: var(--qrencia-white);
  border: 0.1em solid var(--qrencia-text-black);
}
.leadImageText {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1em;
  font-weight: 500;
}
.flashingText {
  font-weight: bold;
  background: linear-gradient(
    to right,
    red,
    orange,
    green,
    blue,
    indigo,
    violet
  );
  background-size: 200% 100%;
  background-position: -100%;
  background-size: 200% 100%;
  background-position: -100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: flash 2.5s infinite;
}
.startButtonContainer {
  text-align: right;
  justify-content: flex-end !important;
}
@keyframes flash {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
}
@media (min-width: 992px) and (max-width: 1290px) {
  .shareIcon {
    right: 7px;
    top: 7px;
    padding: 2px;
  }
}
@media (min-width: 1100px) and (max-width: 1185px) {
  .quizTitle {
    font-size: 0.8em !important;
  }
}
@media (min-width: 990px) and (max-width: 1100px) {
  .quizTitle {
    font-size: 0.76em !important;
  }
}

@media (max-width: 990px) {
  .quizTitle {
    font-size: 0.8em !important;
  }
}
@media (max-width: 767px) {
  .cardsSection {
    padding: 1% 6% 1% 6%;
  }
}

@media (min-width: 990px) and (max-width: 1200px) {
  /* .thumbnailImage {
    height: 180px !important;
  } */
}

@media (min-width: 767px) and (max-width: 990px) {
  .shareIcon {
    right: 7px;
    top: 7px;
    padding: 2px;
  }
}

@media (min-width: 767px) and (max-width: 1290px) {
  .cardsSection {
    padding: 1% 8%;
  }
}

@media (max-width: 767px) {
  .skillsCardsSection {
    padding: 1% 3% 1% 3%;
  }

  .shareIcon {
    right: 7px;
    top: 7px;
    padding: 2px;
  }

  .backgrounImageSection {
    padding-top: 0%;
  }
  .expertSection {
    padding: 1% 10% 1% 10%;
  }
}
@media (max-width: 580px) {
  .quizCard {
    margin-top: 0%;
    margin-bottom: 6%;
  }
  .thumbnailImage {
    height: auto;
    object-fit: cover !important;
    /* aspect-ratio: 460/400; */
  }
  .shareIcon {
    right: 7px;
    top: 7px;
    padding: 2px;
  }
}
@media (max-width: 578px) {
  .expertSection {
    padding: 1% 18% 1% 18%;
  }
}
@media (min-width: 480px) and (max-width: 540px) {
  /* .thumbnailImage {
      height: 220px !important;
    } */
}

@media (max-width: 480px) {
  .cardsSection {
    padding: 1% 18% 1% 18%;
  }
  .loadMorebtn {
    padding: 1.5% 6%;
    letter-spacing: 0.03rem;
    font-size: 0.7em;
  }
  .quizTitle {
    font-size: 0.7em !important;
    margin-top: 1%;
  }
  .shareIcon {
    right: 7px;
    top: 7px;
    padding: 2px;
  }
}
@media (max-width: 420px) {
  .thumbnailImage {
    /* height: 220px !important; */
  }
  .cardsSection {
    padding: 1% 12% 1% 12%;
  }
}
