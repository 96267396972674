.containerSkillsCategory {
  padding: 3% 13% 2% 13%;
  margin: auto;
  display: flex;
  background: var(--qrencia-white);
  width: 100%;
}

.active {
  color: var(--qrencia-text-black);
  border-bottom: 0.32em solid var(--qrencia-text-black);
  cursor: pointer;
  padding-bottom:10px;
  font-weight: 650!important;
}

.containerSkillsCategory ul>li:hover,
.containerSkillsCategory ul>li:active{
  cursor: pointer;
  font-weight: 650!important;
}

.inactive {
  color: var(--qrencia-tabs-inactive);
  font-weight: 650!important;
  padding-bottom:13.5px;
}

.disableLink {
  text-decoration: none;
  color: var(--qrencia-tabs-inactive);
}

.listSkillsCategory {
  display: flex;
  position: relative;
  text-decoration: none;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding-top: 1.2%;
    padding-left: 1%;
    padding-right: 1%;
    padding-bottom: 0%;
  border: 0.06rem solid var(--qrencia-cards-border);
  width: 100%;
  border-radius: 0.5rem;
  font-size: 0.9em;
  font-weight: 600;
}

.containerSkillsCategory.listSkillsCategory>li {
  width: 18%;
  text-align: end;
  margin-right: 6%;
  font-weight: 600;
  font-size: 0.9em;
  text-align: center;
  color: var(--qrencia-text-black);
}

@media(min-width:1120px) and (max-width:1290px) {
  .listSkillsCategory {
    display: flex;
    text-decoration: none;
    justify-content: space-evenly;
    list-style: none;
    margin: 0;
    padding: 1.5% 0% 0% 0%;
  }

  .containerSkillsCategory.listSkillsCategory li {
    margin-right: 0.1em;
    font-weight: 600;
    font-size: 0.6em;
    text-align: center;
    color: var(--qrencia-text-black);
  }

  .active {
    position: relative;
    padding-bottom:10px;
  }
  .inactive{
    padding-bottom:13.5px;
  }
}

@media(min-width:993px) and (max-width:1119px) {
  .listSkillsCategory {
    display: flex;
    text-decoration: none;
    justify-content: space-evenly;
    list-style: none;
    margin: 0;
    padding: 1.5% 0% 0% 0%;
  }

  .containerSkillsCategory.listSkillsCategory li {
    margin-right: 0.1em;
    font-weight: 600;
    font-size: 0.6em;
    text-align: center;
    color: var(--qrencia-text-black);
  }

  .active {
    position: relative;
  }
}

@media(min-width:767px) and (max-width:992px) {
  .containerSkillsCategory.listSkillsCategory li {
    font-weight: 400;
    font-size: 0.6em;
    text-align: center;
    color: var(--qrencia-text-black);
    padding-bottom:10px;
  }

  .listSkillsCategory {
    display: flex;
    gap: 0rem;
    text-decoration: none;
    justify-content: space-around;
    list-style: none;
    margin: 0;
    padding:2% 0% 0% 0%;
    font-size: 0.8em;
  }

  .active {
    position: relative;
    padding-bottom:8px;
  }
  .inactive{
    padding-bottom:11.5px;
  }

}

@media(min-width:500px) and (max-width:767px) {
  .listSkillsCategory {
    margin-right: 0.1em;
    font-weight: 600;
    font-size: 0.75em;
    text-align: center;
    color: var(--qrencia-text-black);
    padding-top: 1.8%;
  }

  .active {
    position: relative;
    padding-bottom:7px;
  }
  .inactive{
    padding-bottom:10.5px;
  }
}

@media(max-width:500px) {
  .listSkillsCategory {
    margin-right: 0.1em;
    font-weight: 600;
    font-size: 0.6em;
    text-align: center;
    padding: 2.5% 2% 0% 2%;
  }

  .active {
    position: relative;
    padding-bottom: 10px;
  }
  .inactive{
    padding-bottom: 13.5px;
  }
}
@media (min-width: 767px) and (max-width: 1290px) {
  .containerSkillsCategory {
    padding: 3% 9% 2% 9%;
  }
}
@media(max-width:767px) {
  .containerSkillsCategory {
    padding: 6% 3% 2% 3%;
  }
}

@media(max-width:560px) {
  .listSkillsCategory {
    font-size: 0.75em;
    text-align: center;
    padding: 2.5% 2% 0% 2%;
    font-weight: 600;
  }
}
@media(min-width:480px) and (max-width:550px){
  .listSkillsCategory {
    font-size: 0.68em!important;
    text-align: center;
    padding: 2.5% 2% 0% 2%;
    font-weight: 600;
  }
}

@media(max-width:480px) {
  .listSkillsCategory {
    margin-right: 0.15em;
    font-weight: 600;
    font-size: 0.55em!important;
    text-align: center;
    padding: 3% 2% 0% 2%;
  }
}

@media(max-width:360px) {
  .listSkillsCategory {
    margin-right: 0.15em;
    font-weight: 600;
    font-size: 0.52em;
    text-align: center;
    padding: 2.5% 2% 0% 2%;
  }
}