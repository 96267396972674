.react-multi-carousel-item,
.react-multi-carousel-item:active,
.react-multi-carousel-item:focus,
.react-multi-carousel-item:hover,
.react-multi-carousel-item:visited {
  margin: 0 0.5%;
  border: none;
  outline: none;
}

.react-multiple-carousel__arrow {
  background-color: var(--qrencia-dark-blue);
  min-width: 38px;
  min-height: 38px;
}

/* Safari compatibility */
.newsSectionCarousel .react-multiple-carousel__arrow {
  max-width: 38px;
  max-height: 38px;
}

.react-multiple-carousel__arrow:hover {
  background-color: transparent;
}

.react-multiple-carousel__arrow--right {
  right: calc(4% + 1px);
  top: 92%;
  background: transparent;
}

.react-multiple-carousel__arrow--left {
  left: calc(88% + 1px);
  top: 92%;
  background: transparent;
}

.react-multiple-carousel__arrow--left::before {
  color: var(--qrencia-dark-blue);
  display: inline-block;
  border: 2px solid var(--qrencia-dark-blue);
  border-radius: 20px;
  padding-right: 95%;
  padding-left: 95%;
  transform: scale(0.7);
}

.react-multiple-carousel__arrow--left:hover::before,
.react-multiple-carousel__arrow--right:hover::before {
  color: var(--qrencia-white) !important;
  background-color: var(--qrencia-dark-blue);
}

.react-multiple-carousel__arrow--right::before {
  color: var(--qrencia-dark-blue);
  display: inline-block;
  border: 2px solid var(--qrencia-dark-blue);
  border-radius: 20px;
  padding-right: 85%;
  padding-left: 85%;
  transform: scale(0.7);
}

.react-multi-carousel-track {
  padding: 0% 0% 5% 0%;
}

.react-multi-carousel-item,
.react-multi-carousel-item:hover,
.react-multi-carousel-item:active,
.react-multi-carousel-item:focus,
.react-multi-carousel-item:visited {
  padding: 0px 30px;
  margin: 0%;
}

@media (min-width: 1290px) and (max-width: 1440px) {
  .react-multiple-carousel__arrow {
    min-width: 32px;
    min-height: 32px;
  }
  /*safari compatibility */
  .newsSectionCarousel .react-multiple-carousel__arrow {
    max-width: 32px;
    max-height: 32px;
  }

  .react-multiple-carousel__arrow::before {
    font-size: 19px;
  }

  .react-multiple-carousel__arrow--left::before {
    padding-right: 98%;
    padding-left: 98%;
  }

  .react-multiple-carousel__arrow--right::before {
    padding-right: 98%;
    padding-left: 98%;
  }
}

@media (min-width: 1060px) and (max-width: 1290px) {
  .react-multiple-carousel__arrow {
    min-width: 32px;
    min-height: 32px;
  }
  .newsSectionCarousel .react-multiple-carousel__arrow {
    max-width: 32px;
    max-height: 32px;
  }

  .react-multiple-carousel__arrow::before {
    font-size: 17px;
  }
}

@media (min-width: 1200px) and (max-width: 1290px) {
  .react-multiple-carousel__arrow--right {
    right: calc(4.5% + 1px);
    top: 92%;
  }

  .react-multiple-carousel__arrow--left {
    left: calc(87.5% + 1px);
    top: 92%;
  }

  .react-multiple-carousel__arrow--left::before {
    padding-right: 98%;
    padding-left: 98%;
    transform: scale(0.75);
  }

  .react-multiple-carousel__arrow--right::before {
    padding-right: 98%;
    padding-left: 98%;
    transform: scale(0.75);
  }
}
@media (min-width: 950px) and (max-width: 1199px) {
  .react-multiple-carousel__arrow--right {
    right: calc(5.2% + 1px) !important;
    top: 93%;
  }

  .react-multiple-carousel__arrow--left {
    left: calc(86% + 1px) !important;
    top: 93%;
  }

  .react-multiple-carousel__arrow--left::before {
    padding-right: 98% !important;
    padding-left: 98% !important;
    transform: scale(0.75) !important;
  }

  .react-multiple-carousel__arrow--right::before {
    padding-right: 98% !important;
    padding-left: 98% !important;
    transform: scale(0.75) !important;
  }
}
@media (min-width: 800px) and (max-width: 949px) {
  .react-multiple-carousel__arrow--right {
    right: calc(7% + 1px) !important;
    top: 94%;
  }

  .react-multiple-carousel__arrow--left {
    left: calc(83% + 1px) !important;
    top: 94%;
  }

  .react-multiple-carousel__arrow--left::before {
    padding-right: 120% !important;
    padding-left: 120% !important;
    transform: scale(0.75) !important;
  }

  .react-multiple-carousel__arrow--right::before {
    padding-right: 120% !important;
    padding-left: 120% !important;
    transform: scale(0.75) !important;
  }
}
@media (min-width: 768px) and (max-width: 800px) {
  .react-multi-carousel-track {
    padding: 0% 0% 6% 0%;
  }
  .react-multiple-carousel__arrow--right {
    right: calc(9% + 1px) !important;
    top: 92%;
  }

  .react-multiple-carousel__arrow--left {
    left: calc(77% + 1px) !important;
    top: 92%;
  }

  .react-multiple-carousel__arrow--left::before {
    padding-right: 120% !important;
    padding-left: 120% !important;
    transform: scale(0.75) !important;
  }

  .react-multiple-carousel__arrow--right::before {
    padding-right: 120% !important;
    padding-left: 120% !important;
    transform: scale(0.75) !important;
  }
}

@media (min-width: 940px) and (max-width: 1060px) {
  .react-multiple-carousel__arrow {
    min-width: 30px;
    min-height: 30px;
  }

  .newsSectionCarousel .react-multiple-carousel__arrow {
    max-width: 30px;
    max-height: 30px;
  }
  .react-multiple-carousel__arrow::before {
    font-size: 15px;
  }

  .react-multiple-carousel__arrow--right {
    right: calc(0% + 12px);
  }

  .react-multiple-carousel__arrow--left {
    left: calc(0% + 0px);
  }
}

@media (min-width: 800px) and (max-width: 940px) {
  .react-multiple-carousel__arrow {
    min-width: 25px;
    min-height: 25px;
  }
  .newsSectionCarousel .react-multiple-carousel__arrow {
    max-width: 25px;
    max-height: 25px;
  }

  .react-multiple-carousel__arrow::before {
    font-size: 15px;
  }

  .react-multiple-carousel__arrow--right {
    right: calc(0% + 0px);
  }

  .react-multiple-carousel__arrow--left {
    left: calc(0% + 0px);
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .react-multiple-carousel__arrow {
    min-width: 30px;
    min-height: 28px;
  }

  .newsSectionCarousel .react-multiple-carousel__arrow {
    max-width: 30px;
    max-height: 28px;
  }

  .react-multiple-carousel__arrow::before {
    font-size: 18px;
  }

  .react-multiple-carousel__arrow--right {
    right: calc(1% + 1px);
  }

  .react-multiple-carousel__arrow--left {
    left: calc(1% + 1px);
  }
}

@media (max-width: 540px) {
  .react-multiple-carousel__arrow {
    min-width: 33px;
    min-height: 33px;
  }
}

@media (max-width: 767.8px) {
  .react-multiple-carousel__arrow {
    display: none !important;
  }
}
