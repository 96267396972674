.counter {
  color: var(--qrencia-success-green);
  border: 1px solid var(--qrencia-success-green);
  border-radius: 5px;
  margin-bottom: 0% !important;
  padding: 0.26% 1% 0.26% 1%;
  text-align: center;
  width: 10em;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.9em;
}

.alertCounter {
  color: var(--qrencia-astrick-red);
  border: 1px solid var(--qrencia-astrick-red);
  border-radius: 5px;
  margin-bottom: 0% !important;
  padding: 0.26% 1% 0.26% 1%;
  text-align: center;
  width: 10em;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.9em;
}
.alertCounter > svg,
.counter > svg {
  margin-top: -0.2em;
}
@media (max-width: 650px) {
  .alertCounter,
  .counter {
    font-size: 0.85em;
  }
}
@media (max-width: 480px) {
  .alertCounter,
  .counter {
    font-size: 0.8em;
  }
}
@media (max-width: 375px) {
  .alertCounter,
  .counter {
    font-size: 0.7em;
  }
}
