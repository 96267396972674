.cardSection {
  display: flex;
  margin: 0% -1.3%;
}

.cardBody {
  padding: 0;
}

.cardDiv {
  padding: 5% 5% 4% 5%;
  border-radius: 10px;
  margin-bottom: 4%;
}
.dateTimeText {
  margin-top: 0.8%;
  color: var(--qrencia-text-black) !important;
}
.cardTimeAndDate {
  font-size: 0.68em;
  display: flex;
  font-weight: 600 !important;
  margin: 1% 1% 1% 0;
  color: var(--qrencia-text-black) !important;
}
.cardImage {
  border-radius: 8px;
  aspect-ratio: 825 / 550 !important;
  object-fit: cover;
  /* height: 9.2em; */
}

.cardTitle {
  display: flex;
  justify-content: space-between;
  font-weight: 600 !important;
  font-size: 0.88em;
}

.titleFixedHeight {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cardSubTitle {
  margin-top: 1.5%;
  font-size: 0.625em;
  font-weight: 500 !important;
  color: var(--qrencia-dark-blue);
}

.cardDurationDetails {
  font-size: 0.62em;
  display: flex;
  font-weight: 600 !important;
  margin: 1% 1% 1% 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  display: -webkit-box;
  /* -webkit-line-clamp: 1; */
  /* -webkit-box-orient: vertical; */
  /* overflow: hidden; */
  /* flex-wrap: wrap; */
}

.buttonSection {
  margin: 2% 0% 0% 1px;
  display: flex;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
}

.cardButton {
  font-size: 0.68em;
  padding: 2% 1%;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  text-align: center;
  font-weight: 500 !important;
  border-radius: 3px;
  margin: 2% 0%;
  width: 6.9em;
}

.cardCancelButton,
.cardCancelButton:active,
.cardCancelButton:hover,
.cardCancelButton:focus {
  font-size: 0.68em;
  padding: 2% 1%;
  background-color: var(--cancel-red-button) !important;
  border: 1px solid var(--cancel-red-button) !important;
  color: var(--qrencia-white) !important;
  text-align: center;
  font-weight: 500 !important;
  border-radius: 3px;
  margin: 2% 0%;
  width: 6.5em;
}

.cardUserProfileImageSection {
  display: flex;
  gap: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
  width: 55%;
}

.cardEnrolledCountSection {
  font-weight: 600;
  font-size: 0.68em;
  margin-top: 2%;
  width: max-content;
  color: var(--qrencia-text-black);
  align-items: center;
  display: flex;
}

.cardEnrolledText {
  display: contents;
}

.onlineIconCourse {
  color: var(--qrenica-course-differentiator-color) !important;
}

.onlineIconCompetition {
  color: var(--qrenica-competition-differentiator-color) !important;
}

.onlineModeIcon {
  margin-top: 7%;
  margin-left: auto;
  color: var(--qrencia-text-black) !important;
}

.cardProfileImage {
  width: 23px;
  height: 23px !important;
  border-radius: 18px;
  border: 2px solid var(--qrencia-white);
  position: relative;
  margin-right: -9px;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.88em;
  z-index: 100;
  width: 60%;
}

.courseTitleTooltip {
  background-color: var(--qrencia-white);
  font-weight: 600 !important;
  color: var(--qrencia-text-black) !important;
  font-size: 0.88em;
  z-index: 100;
  max-width: 30em;
  border: 1px solid var(--qrencia-grey-border);
}

.courseTitleTooltip > span {
  background-color: var(--qrencia-white);
  font-weight: 600 !important;
  color: var(--qrencia-text-black) !important;
  font-size: 0.88em;
  z-index: 100;
  max-width: 30em;
}
.courseTooltipBlue {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 450 !important;
  font-size: 1em;
  z-index: 100;
  max-width: 30em;
  border: 1px solid var(--qrencia-grey-border);
}

.courseTooltipBlue > span {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 450 !important;
  font-size: 1em;
  z-index: 100;
  max-width: 30em;
}

.courseEditTooltip {
  background-color: var(--qrencia-white);
  font-weight: 600 !important;
  color: var(--qrencia-text-black) !important;
  font-size: 0.83em;
  z-index: 100;
  max-width: 30em;
  border: 1px solid var(--qrencia-grey-border);
}

.courseEditTooltip > span {
  background-color: var(--qrencia-white);
  font-weight: 600 !important;
  color: var(--qrencia-text-black) !important;
  font-size: 0.83em;
  z-index: 100;
  max-width: 30em;
}
.cardLink,
.cardLink:hover,
.cardLink:active,
.cardLink:focus {
  text-decoration: none;
  color: var(--qrencia-text-black);
  font-weight: 600;
}

.cardProfileImageSection {
  display: flex;
  margin-right: 4%;
}

.courseCalendarIcon {
  font-size: 1.3em;
  margin-left: -0.1em;
}

.courseCalendarIcon {
  display: flex;
}

.courseCostInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0.8em;
  width: max-content;
}

.costText {
  margin-top: 0.8%;
  color: var(--qrencia-text-black) !important;
}

.geoLocationIcon {
  font-size: 1.1em;
  margin-top: -2px;
}

.subTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

@media (min-width: 1390px) and (max-width: 1415px) {
  .cardDurationDetails {
    font-size: 0.6em;
  }

  .cardEnrolledCountSection {
    font-size: 0.65em;
  }
}

@media (min-width: 1290px) and (max-width: 1390px) {
  .cardDurationDetails {
    font-size: 0.58em;
  }

  .cardEnrolledCountSection {
    font-size: 0.65em;
  }
}

@media (min-width: 1180px) and (max-width: 1290px) {
  .cardTitle {
    font-weight: 600 !important;
    text-align: left;
    font-size: 0.88em;
  }

  .cardDurationDetails {
    font-size: 0.6em;
  }

  .cardEnrolledCountSection {
    font-size: 0.58em;
  }

  .cardImage {
    /* height: 7.8em; */
  }

  .cardButton {
    font-size: 0.7em;
    padding: 2.5%;
    background-color: var(--qrencia-dark-blue) !important;
    color: var(--qrencia-white) !important;
    text-align: center;
    font-weight: 500 !important;
    border-radius: 3px;
  }

  .cardCancelButton,
  .cardCancelButton:active,
  .cardCancelButton:hover,
  .cardCancelButton:focus {
    font-size: 0.7em;
    padding: 2.5%;
    text-align: center;
    font-weight: 500 !important;
    border-radius: 3px;
  }
}
@media (max-width: 1250px) and (min-width: 1060px) {
  .cardTimeAndDate {
    font-size: 0.55em;
  }
}
@media (max-width: 1060px) and (min-width: 992px) {
  .cardTimeAndDate {
    font-size: 0.5em;
  }
}

@media (min-width: 992px) and (max-width: 1180px) {
  .cardTitle {
    font-weight: 600 !important;
    text-align: left;
    font-size: 0.8em;
  }

  .cardSubTitle {
    font-size: 0.6em;
  }

  .cardDurationDetails {
    font-size: 0.65em;
  }

  .cardEnrolledCountSection {
    font-size: 0.5em;
    margin-top: 3%;
  }

  .cardProfileImage {
    width: 19px;
    height: 19px !important;
  }

  .cardImage {
    /* height: 8em; */
  }

  .geoLocationIcon {
    font-size: 0.9em;
  }

  .cardButton {
    font-size: 0.6em;
    padding: 2.5%;
    width: 6.8em;
  }

  .cardCancelButton,
  .cardCancelButton:active,
  .cardCancelButton:hover,
  .cardCancelButton:focus {
    font-size: 0.6em;
    padding: 2.5%;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .cardTitle {
    font-size: 0.9em;
  }

  .cardImage {
    /* height: 8em; */
  }

  .cardDurationDetails {
    font-size: 0.62em;
  }

  .cardEnrolledCountSection {
    font-size: 0.62em;
  }
}
@media (max-width: 850px) and (min-width: 800px) {
  .cardTimeAndDate {
    font-size: 0.55em;
  }
}
@media (max-width: 800px) and (min-width: 767px) {
  .cardTimeAndDate {
    font-size: 0.52em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .geoLocationIcon {
    font-size: 1.15em;
  }
}

@media (max-width: 767px) {
  .cardImage {
    /* height: 8.2em; */
  }
}

@media (max-width: 575px) {
  .cardImage {
    /* height: 10.2em; */
  }

  .cardTitle {
    font-size: 0.9em;
  }

  .cardSubTitle {
    font-size: 0.625em;
  }

  .cardDurationDetails {
    font-size: 0.7em;
  }

  .cardEnrolledCountSection {
    font-size: 0.68em;
  }

  .geoLocationIcon {
    font-size: 1.15em;
  }

  .buttonSection {
    margin: 2% 0% 0% 0%;
  }

  .cardButton {
    width: 7.4em;
  }

  .cardCancelButton,
  .cardCancelButton:active,
  .cardCancelButton:hover,
  .cardCancelButton:focus {
    width: 7.5em;
  }
}

@media (max-width: 480px) {
  .cardEnrolledCountSection {
    font-size: 0.68em;
  }

  .cardButton {
    margin-right: 1em;
  }
}

.cardDurationDetailsMicroSite {
  flex-wrap: wrap;
  font-size: 0.65em !important;
}

@media (min-width: 991px) and (max-width: 1290px) {
  .cardDurationDetailsMicroSite {
    font-size: 0.6em !important;
  }
}

@media (min-width: 1290.5px) {
  .cardDurationDetailsMicroSite {
    font-size: 0.66em !important;
  }
}

@media (min-width: 992px) and (max-width: 1180px) {
  .cardImage {
    /* height: 7em; */
  }
}
@media (min-width: 1140px) and (max-width: 1260px) {
  .cardSubTitle {
    font-size: 0.6em !important;
  }
}
@media (min-width: 1140px) and (max-width: 1160px) {
  .cardSubTitle {
    font-size: 0.58em !important;
  }
}
@media (min-width: 992px) and (max-width: 1010px) {
  .cardSubTitle {
    font-size: 0.48em !important;
  }
}
