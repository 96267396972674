.breadcrumbs a {
    text-decoration: none;
    font-weight: 500;
    color: var(--qrencia-text-black);  
}

.breadcrumbs {
    font-weight: 500;
    color: var(--qrencia-text-black);
    cursor: pointer;
    padding: 0% 8%;
    padding-top: 1em;
    background: var(--qrencia-white);
    font-size: 0.85em;
}

.breadcrumbs span {
    font-weight: 600!important;
    color: var(--qrencia-text-black);
    margin-left:0%!important;
}

.heading {
    font-size: 1.8em;
    font-weight: 700 !important;
}

.headingHistory {
    font-size: 1.4em;
    font-weight: 700 !important;
    margin-top: 1em;
}

.content {
    font-size: 0.9em;
}

.shareIconPannelContainer{
    color: var(--qrencia-white);
    display: grid;
    position: fixed;
    padding: 1% 0.8% 0.5% 0.7%;
    top: 50%;
    right: 0%;
    background: var(--qrencia-dark-blue);
    border-top: 2px solid var(--qrencia-white);
    border-bottom: 2px solid var(--qrencia-white);
    border-left: 2px solid var(--qrencia-white);
    border-radius: 10px 0px 0px 10px;
    z-index: 1000;
}
.shareIcons {
    margin-bottom: 32%;
    border-radius: 20px;
    cursor: pointer;
    width:23px;
    height: 23px;
}

.showLinkCopiedIcon{
    color: var(--qrencia-success-green);
    padding: 10%;
    width:23px;
    height: 23px;
    margin-bottom: 32%;
    background-color: var(--qrencia-white);
    border-radius: 20px;
    cursor: pointer;
}

@media(min-width:2401px) {
    .breadcrumbs {
        padding: 0% 8%;
        padding-top: 1em;
        font-size: 1em;
    }

}

@media(min-width:2000px) and (max-width:2400px) {
    .breadcrumbs {
        padding-top: 1.2em;
        font-size: 1em;
    }

}


@media(min-width:1651px) and (max-width:1999px) {
    .breadcrumbs {
        padding-top: 1.1em;
        font-size: 1em;
    }

}

@media (max-width:540px) {
    .breadcrumbs {
        padding-top: 1.1em;
        font-size: 0.6em;
    }

}

@media (min-width:541px)and (max-width:767px) {
    .breadcrumbs {
        padding-top: 1.1em;
        font-size: 0.8em;
    }

}

@media (min-width:768px)and (max-width:1024px) {
    .breadcrumbs {
        padding-top: 1.1em;
        font-size: 0.8em;
    }

}
@media(min-width:990px) and (max-width:1290px){
    .shareIconPannelContainer{
        top:48%;
    }
    .shareIcons {
        width:20px;
        height: 20px;
    }
    
    .showLinkCopiedIcon{
        width:20px;
        height: 20px;
    }
}
@media(min-width:768px) and (max-width:990px){
    .shareIconPannelContainer{
        top:46%;
    }
    .shareIcons {
        width:20px;
        height: 20px;
    }
    
    .showLinkCopiedIcon{
        width:20px;
        height: 20px;
    }
}
@media(min-width:480px) and (max-width:767.5px){
    .shareIconPannelContainer{
        top:45%;
    }
    .shareIcons {
        width:18px;
        height: 18px;
    }
    
    .showLinkCopiedIcon{
        width:18px;
        height: 18px;
    }
}
@media(max-width:480px){
    .shareIconPannelContainer{
        top:44%;
    }
    .shareIcons {
        width:18px;
        height: 18px;
    }
    
    .showLinkCopiedIcon{
        width:17px;
        height: 17px;
        padding: 5% 0% 5% 0%;
    }
}