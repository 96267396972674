.heading {
  font-size: 1em;
  font-weight: 650;
  color: var(--qrencia-text-black);
  margin: 0.5em 0.5em 1em 0.5em;
}
.suggestiveCompetitionSearchComponent {
  background-color: var(--qrencia-white);
  width: 100%;
  padding: 1em;
  min-height: 4.2em;
  border-radius: 5px;
}
.errorMessageDiv {
  margin-bottom: -1em;
}
.suggestiveSearchInputContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: -0.17em;
}
.selectButton {
  font-size: 0.8em !important;
  padding: 0.61em 1em;
}
.suggestiveCompetitionSearchComponent input {
  flex: 1;
}
/* .suggestiveCompetitionSearchComponent > button {

} */
@media (max-width: 480px) {
  .suggestiveCompetitionSearchComponent > button {
    font-size: 0.7em;
    padding: 0.5em 1em;
  }
  .selectButton {
    height: 2.4em;
    font-size: 0.6em;
    line-height: 1em;
  }
}
