.formLabel {
  font-weight: 500;
  font-size: 0.8em;
  background-color: var(--qrencia-white);
  margin-left: 3px;
  padding: 0em 0.3em;
  z-index: 1;
  background-image: linear-gradient(
    to bottom,
    var(--qrencia-white),
    var(--qrencia-select-box-background)
  );
  position: relative;
}

.formInput,
.selectInput {
  height: 3em;
  font-size: 0.8em !important;
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  margin-top: -18px;
  text-align: left !important;
}

.formInput::placeholder {
  color: #b1b0b0;
}

.formInput:read-only {
  height: 3em;
  font-size: 0.8em !important;
  border: solid 1px var(--qrencia-select-box-border);
  margin-top: -18px;
}

.formLabelDisabled {
  font-weight: 500;
  font-size: 0.8em;
  background-color: var(--qrencia-white);
  margin-left: 3px;
  padding: 0em 0.3em;
  z-index: 1;
  background-image: linear-gradient(
    to bottom,
    var(--qrencia-white),
    var(--qrencia-disabled-input-bg)
  );
}

.formTextAreaInput {
  resize: none;
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  height: 6em !important;
  margin-top: -18px;
}

.textEditorInactive {
  cursor: not-allowed;
  opacity: 0.5;
}

.textEditor {
  width: 100%;
  margin-top: -18px;
}

.uploadFileInput {
  margin-top: -18px;
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  padding-right: 6em !important;
}

input[type='text']:disabled,
input[type='file']:disabled,
input[type='date']:disabled,
input[type='number']:disabled,
input[type='time']:disabled,
input[type='url']:disabled,
.form-control:disabled,
.form-select:disabled {
  background: var(--qrencia-disabled-input-bg) !important;
  border: solid 1px var(--qrencia-disabled-input-border) !important;
  cursor: not-allowed;
}

.fileUpload {
  position: relative;
}

.fileUpload input[type='file']::file-selector-button {
  position: absolute;
  right: -1.8em;
  top: 2.08em;
  height: 3.9em;
  line-height: 1.1em;
  z-index: 1;
  width: 9em !important;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.dateTimeEmptyInput input[type='date']::-webkit-datetime-edit,
.dateTimeEmptyInput input[type='time']::-webkit-datetime-edit {
  color: var(--qrencia-placeholder-color);
  text-align: left !important;
}

.dateTimeInput input[type='date'],
.dateTimeInput input[type='time'] {
  text-align: left !important;
}

.dateTimeInput input[type='date']::-webkit-date-and-time-value,
.dateTimeInput input[type='time']::-webkit-date-and-time-value {
  text-align: left !important;
}

.dateTimeInput input[type='date']::-webkit-datetime-edit,
.dateTimeInput input[type='time']::-webkit-datetime-edit {
  text-align: left !important;
}

@media (min-width: 991px) and (max-width: 1010px) {
  .formLabel {
    font-size: 0.78em;
  }

  .formLabelDisabled {
    font-size: 0.78em;
  }

  .formInput,
  .selectInput {
    font-size: 0.78em !important;
  }

  .formInput:read-only {
    font-size: 0.78em !important;
  }
}

@media (min-width: 575px) and (max-width: 592px) {
  .formLabel {
    font-size: 0.76em;
  }

  .formLabelDisabled {
    font-size: 0.76em;
  }

  .formInput,
  .selectInput {
    font-size: 0.76em !important;
  }

  .formInput:read-only {
    font-size: 0.76em !important;
  }
}

/* file input styles for mobiles */
@media (max-width: 500px) {
  .fileUpload input[type='file']::file-selector-button {
    position: absolute;
    right: -0.6em;
    top: 2.9em;
    height: 2.2em;
    line-height: 1em;
    z-index: 1;
    width: 9em !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (min-width: 1500px) {
  .formInput,
  .selectInput {
    font-size: 0.8em;
  }
}

@media (min-width: 541px) and (max-width: 760px) {
  .formInput,
  .selectInput {
    font-size: 0.75em;
  }
}

@media (min-width: 761px) and (max-width: 900px) {
  .formInput,
  .selectInput {
    font-size: 0.8em;
  }
}

@media (min-width: 900px) and (max-width: 1500px) {
  .formInput,
  .selectInput {
    font-size: 0.8em;
  }
}

@media (max-width: 540px) {
  .formInput .selectInput {
    font-size: 0.8em;
    margin-bottom: 0.2em;
    padding: 0em;
  }
}

@media (max-width: 480px) {
  /* .formInput {
    height: 3em;
    font-size: 0.6em;
    margin-bottom: 0.2em;
  } */
  .uploadFileInput {
    padding-right: 7em !important;
  }
}
