.modalBtn {
  font-size: 0.8em;
  padding: 0.5em 0.8em;
  margin-right: 0.5em;
  margin-top: 0.5em;
}

.greetingText {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
  font-size: 0.9em;
  text-align: center;
}
