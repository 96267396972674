.courseCardContainer {
  margin-top: -2%;
  cursor: pointer;
}
.todaysActivityContainer {
  background-color: var(--qrencia-white);
  border-radius: 10px;
}
.courseCardSection {
  padding: 0%;
  margin-top: 4%;
}
.loadMore {
  margin-bottom: 1em;
}
.learningActivityCardSection {
  padding: 2% 1%;
}
.courseCard {
  border: none;
}

.learningActivityCard {
  border: none;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.learningActivityTitle {
  font-size: 0.85em;
}
.courseCardBody {
  background: var(--qrencia-calendar-widget-card-background);
  border-radius: 12px;
  padding: 6%;
}
.learningActivityCardBody {
  background: var(--qrencia-calendar-widget-card-background);
  border-radius: 12px;
  padding: 4.5%;
}
.courseCardBody {
  background: var(--qrencia-upcoming-course-widget-bg) !important;
}

.workshopCardBody {
  background: var(--qrencia-workshop-card-background) !important;
}

.dateTimeSection {
  font-size: 0.72em;
  color: var(--qrencia-text-black);
  display: flex;
  margin-bottom: 0.4em;
}
.liveCompetition {
  font-weight: 450 !important;
  color: var(--qrencia-text-black);
  font-size: 0.95em;
  background-color: rgb(255, 144, 144);
  padding: 1px 4px;
  text-align: center;
  border-radius: 4px;
  margin-right: 3px;
}
.enrolButton {
  font-weight: 400 !important;
  color: var(--qrencia-white);
  font-size: 0.9em;
  background-color: var(--qrencia-dark-blue);
  padding: 2px 7px;
  text-align: center;
  border-radius: 4px;
  margin-right: 3px;
}
.dateTimeText {
  font-weight: 500 !important;
  margin-top: 0.25%;
}
.widgitLocationSpan {
  font-weight: 400 !important;
}
.courseTitle {
  font-size: 0.92em;
  margin: 1.5% 0% 1.5% 0%;
  font-weight: 600 !important;
  color: var(--qrencia-text-black) !important;
  text-decoration: none !important;
}
.nudgeTitle {
  font-size: 0.92em;
  margin: 1.5% 0% 1.5% 0%;
  font-weight: 600 !important;
  color: var(--qrencia-text-black) !important;
  text-decoration: none !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.courseTitle > a {
  color: var(--qrencia-text-black) !important;
  text-decoration: none !important;
}
.nudgeTitle > a {
  color: var(--qrencia-text-black) !important;
  text-decoration: none !important;
}

.calendarIcon {
  margin-top: 0.5%;
  margin-right: 1%;
  font-size: 1.1em;
}

.orangeCalendarIcon {
  color: var(--qrenica-competition-differentiator-color);
  margin-top: 0.85%;
  margin-right: 1%;
  font-size: 1.1em;
}
.workshopCalendarIcon {
  color: var(--qrencia-workshop-differentiator-color);
  margin-top: 0.85%;
  margin-right: 1%;
  font-size: 1.1em;
}
.endDateIcon {
  font-size: 1.25em !important;
  margin-left: -1px;
}

.blueCalendarIcon {
  color: var(--qrenica-course-differentiator-color);
  margin-top: 0.5%;
  margin-right: 1%;
  font-size: 1.1em;
}

.locationIcon {
  color: var(--qrencia-dark-blue);
  font-size: 1.05em;
  cursor: pointer;
}
.widgetCardsLocationIcon {
  color: var(--qrencia-dark-blue);
  font-size: 1.35em;
}

.competitionOnlineModeText {
  color: var(--qrencia-dark-blue);
  font-size: 0.8em;
  display: contents;
}

.courseCardMode {
  color: var(--qrencia-text-black) !important;
  font-size: 0.82em;
  font-weight: 400 !important;
}
.alert {
  color: var(--qrencia-astrick-red);
  font-size: 1.2em;
}
.linkSeperator {
  color: var(--qrencia-grey-text-calendar);
  font-weight: 300 !important;
  margin: 0% 1%;
}
.markAttendanceButton {
  font-size: 0.7em;
}
.iButton {
  padding: 2px;
  background-color: var(--qrencia-dark-blue);
  border-radius: 50%;
  color: var(--qrencia-white);
  font-size: 0.9em;
  cursor: pointer;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-size: 0.85em;
  width: 20em;
}

.expertNameText {
  font-size: 0.65em;
  font-weight: 600 !important;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

@media (min-width: 1290px) and (max-width: 1390px) {
  .cardTitle {
    height: 2.7em;
  }
}
@media (min-width: 1187px) and (max-width: 1286px) {
  .dateTimeSection {
    font-size: 0.7em;
  }

  .courseTitle {
    font-size: 0.78em;
  }
  .nudgeTitle {
    font-size: 0.78em;
  }

  .locationIcon {
    font-size: 0.95em;
  }
  .widgetCardsLocationIcon {
    font-size: 1.35em;
  }

  .iButton {
    font-size: 0.85em;
  }

  .competitionOnlineModeText {
    color: var(--qrencia-dark-blue);
    font-size: 0.75em;
  }

  .courseCardMode {
    font-size: 0.75em;
  }

  .courseCardBody {
    padding: 6% 3%;
  }
  .learningActivityCardBody {
    padding: 4% 2%;
  }

  .linkSeperator {
    margin: 0% 0.5%;
  }
  .tooltipMessage {
    font-size: 0.8em;
  }
}

@media (min-width: 1095px) and (max-width: 1187px) {
  .dateTimeSection {
    font-size: 0.65em;
  }

  .courseTitle {
    font-size: 0.78em;
  }
  .nudgeTitle {
    font-size: 0.78em;
  }

  .locationIcon {
    font-size: 0.9em;
  }
  .widgetCardsLocationIcon {
    font-size: 1.3em;
  }

  .iButton {
    font-size: 0.8em;
  }

  .competitionOnlineModeText {
    color: var(--qrencia-dark-blue);
    font-size: 0.72em;
  }

  .courseCardMode {
    font-size: 0.72em;
  }

  .courseCardBody {
    padding: 6% 3%;
  }
  .learningActivityCardBody {
    padding: 4% 2%;
  }

  .linkSeperator {
    margin: 0% 0.2%;
  }
}

@media (min-width: 992px) and (max-width: 1095px) {
  .dateTimeSection {
    font-size: 0.6em;
  }

  .courseTitle {
    font-size: 0.78em;
  }
  .nudgeTitle {
    font-size: 0.78em;
  }

  .locationIcon {
    font-size: 0.9em;
  }
  .widgetCardsLocationIcon {
    font-size: 1.3em;
  }
  .tooltipMessage {
    font-size: 0.75em;
  }

  .iButton {
    font-size: 0.75em;
  }

  .competitionOnlineModeText {
    color: var(--qrencia-dark-blue);
    font-size: 0.68em;
  }

  .courseCardMode {
    font-size: 0.72em;
  }

  .courseCardBody {
    padding: 6% 3%;
  }
  .learningActivityCardBody {
    padding: 4% 2%;
  }

  .linkSeperator {
    margin: 0% 0.2%;
  }
}

@media (min-width: 872px) and (max-width: 991.5px) {
  .dateTimeSection {
    font-size: 0.68em;
  }

  .courseTitle {
    font-size: 0.78em;
  }
  .nudgeTitle {
    font-size: 0.78em;
  }

  .locationIcon {
    font-size: 0.95em;
  }
  .widgetCardsLocationIcon {
    font-size: 1.35em;
  }

  .iButton {
    font-size: 0.85em;
  }

  .competitionOnlineModeText {
    color: var(--qrencia-dark-blue);
    font-size: 0.75em;
  }

  .courseCardMode {
    font-size: 0.75em;
  }

  .courseCardBody {
    padding: 6% 4%;
  }
  .learningActivityCardBody {
    padding: 4% 2%;
  }
  .linkSeperator {
    margin: 0% 0.5%;
  }
}

@media (min-width: 768px) and (max-width: 871.5px) {
  .dateTimeSection {
    font-size: 0.63em;
  }

  .courseTitle {
    font-size: 0.78em;
  }
  .nudgeTitle {
    font-size: 0.78em;
  }

  .locationIcon {
    font-size: 0.95em;
  }
  .widgetCardsLocationIcon {
    font-size: 1.35em;
  }

  .iButton {
    font-size: 0.85em;
  }

  .competitionOnlineModeText {
    font-size: 0.75em;
  }

  .courseCardMode {
    font-size: 0.75em;
  }

  .courseCardBody {
    padding: 6% 4%;
  }
  .learningActivityCardBody {
    padding: 4% 2%;
  }

  .linkSeperator {
    margin: 0% 0.5%;
  }
  .tooltipMessage {
    font-size: 0.75em;
  }
}

@media (min-width: 480px) and (max-width: 767.5px) {
  .dateTimeSection {
    font-size: 0.72em;
  }

  .courseTitle {
    font-size: 0.88em;
  }
  .nudgeTitle {
    font-size: 0.88em;
  }
  .locationIcon {
    font-size: 0.95em;
  }
  .widgetCardsLocationIcon {
    font-size: 1.35em;
  }

  .iButton {
    font-size: 0.85em;
  }

  .competitionOnlineModeText {
    font-size: 0.75em;
  }

  .courseCardMode {
    font-size: 0.75em;
  }

  .courseCardBody {
    padding: 4% 4%;
  }
  .learningActivityCardBody {
    padding: 2% 2%;
  }

  .linkSeperator {
    margin: 0% 0.5%;
  }
  .tooltipMessage {
    font-size: 0.75em;
  }
  .courseCardSection {
    padding: 0%;
    margin-top: 2%;
  }
}

@media (max-width: 480px) {
  .dateTimeSection {
    font-size: 0.72em;
  }
  .tooltipMessage {
    font-size: 0.75em;
  }

  .courseTitle {
    font-size: 0.88em;
  }
  .nudgeTitle {
    font-size: 0.88em;
  }

  .locationIcon {
    font-size: 0.95em;
  }
  .widgetCardsLocationIcon {
    font-size: 1.35em;
  }

  .iButton {
    font-size: 0.9em;
  }
  .competitionOnlineModeText {
    font-size: 0.8em;
  }

  .courseCardMode {
    font-size: 0.8em;
  }

  .courseCardBody {
    padding: 5% 4%;
  }
  .learningActivityCardBody {
    padding: 3% 2%;
  }

  .linkSeperator {
    margin: 0% 0.5%;
  }
}

.enrolText {
  font-size: 0.78em;
  margin-top: 0.3em;
  color: var(--qrencia-white);
  background: var(--qrencia-dark-blue);
}
