.cardContainer {
  height: fit-content;
  padding: 0.8em;
  margin: 1em 0em;
  background-color: var(--qrencia-white);
  transition: 0.3s all ease-in-out;
  border: 0.5px solid var(--qrencia-certificate-preview-border);
}

.questionContainer {
  display: flex;
  outline: none;
  align-items: flex-start;
  justify-content: space-between;
}

.question {
  font-size: 0.85em;
  flex-grow: 1;
  max-width: 95%;
  text-align: justify;
  font-weight: 500;
  color: var(--qrencia-dark-blue);
}

.cardContainer:hover {
  box-shadow: 0 3px 21px 0 rgba(100, 100, 100, 0.2);
}

.addIcon {
  font-size: 1em;
  font-weight: 600;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  margin-top: 0.2em;
}

.crossIcon {
  transform: rotate(45deg);
}

.answerContainer {
  font-size: 0.8em;
  margin-top: 01em;
  text-align: justify;
}

@media (max-width: 990px) {
  .answer p {
    font-size: 1em;
  }
}
@media (max-width: 480px) {
  .question {
    font-size: 0.75em;
  }
}
