.aboutExpertContainer {
    margin: 1em 0;
    padding: 0.5em 1em;
    border-radius: 10px;
    background-color: #e7f2ff;
    border: 1px solid #badaff;
}

.aboutExpertHeading {
    font-size: 1em;
    font-weight: 600 !important;
    color: var(--qrencia-grey-text-color);
}

.aboutExpertSubHeading {
    font-size: 0.8em;
    color: var(--qrencia-grey-text-color);
}

.skillsContainer {
    padding: 0.75em 1em;
    background-color: var(--qrencia-white);
    border: 1px solid var(--qrencia-cards-border);
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
}

.skillsText {
    font-size: 0.8em;
    font-weight: 500 !important;
}

.skillsContainer a,
.skillsContainer a:hover,
.skillsContainer a:focus,
.skillsContainer a:active {
    color: var(--qrencia-dark-blue) !important;
    background-color: #c8e1ff;
    padding: 0px 6px 2px 6px;
    border-radius: 50%;
}

.skillsContainer svg {
    font-size: 0.9em;
}

.videoReviewSection {
    padding: 0.5em 1.2em 1.2em 1.2em;
    background-color: var(--qrencia-white);
    border-radius: 12px;
    border: 1px solid var(--qrencia-cards-border);
    height: 20% !important;
}

.advertismentSection {
    padding: 1.2em;
    background-color: var(--qrencia-white);
    border-radius: 12px;
    border: 1px solid var(--qrencia-cards-border);
}

.promotionVideoSection {
    flex: 4.5;
    position: relative;
}

.videoContent {
    width: 100%;
    border-radius: 12px;
    background-color: var(--qrencia-text-black);
    aspect-ratio: 3/2.1;
}

.advertismentImage {
    width: 100%;
    border-radius: 12px;
}

.videoIcon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12%;
    position: absolute;
    cursor: pointer;
}

.closeButton {
    top: 1%;
    right: 1%;
    width: 8%;
    position: absolute;
    cursor: pointer;
    font-size: 1.5em;
    color: var(--qrencia-white);
}

.showOnLargeScreen {
    display: block;
}

.showOnMobileScreen {
    display: none;
}

@media (min-width: 768px) and (max-width: 990px) {
    .aboutExpertHeading {
        font-size: 0.88em;
    }

    .skillsText {
        font-size: 0.72em;
    }

    .skillsContainer svg {
        font-size: 0.75em;
    }

    .skillsContainer {
        padding: 0.75em 1em;
        gap: 0.5em;
    }

    .skillsContainer a,
    .skillsContainer a:hover,
    .skillsContainer a:focus,
    .skillsContainer a:active {
        padding: 0px 7.5px 2px 6.5px;
    }

    .videoReviewSection {
        padding: 0.5em 0.9em 0.9em 0.9em;
    }

    .aboutExpertContainer {
        padding: 0.5em 0.9em 0.9em 0.9em;
    }

    .advertismentSection {
        padding: 0.9em 0.9em 0.9em 0.9em;
    }
}

@media (max-width: 767.5px) {
    .showOnLargeScreen {
        display: none;
    }

    .showOnMobileScreen {
        display: block;
    }
    .aboutExpertHeading {
        font-size: 0.96em;
    }

    .skillsText {
        font-size: 0.72em;
    }

    .skillsContainer svg {
        font-size: 0.75em;
    }

    .skillsContainer {
        padding: 0.75em 1em;
        gap: 0.5em;
        font-size: 1.05em;
    }

    .skillsContainer a,
    .skillsContainer a:hover,
    .skillsContainer a:focus,
    .skillsContainer a:active {
        padding: 0px 7.5px 2px 6.5px;
    }

    .videoReviewSection {
        padding: 0.5em 0.9em 0.9em 0.9em;
    }

    .aboutExpertContainer {
        padding: 0.5em 0.9em 0.9em 0.9em;
    }

    .advertismentSection {
        padding: 0.9em 0.9em 0.9em 0.9em;
    }
}