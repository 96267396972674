.competitionBannerImgContainer {
  width: 100%;
  /* height: 55vh !important; */
  /* object-fit: cover; */
  display: block;
  position: relative;
}

.competitionTabletImgContainer {
  display: none;
  position: relative;
}

.competitionMobileImgContainer {
  display: none;
  position: relative;
}

.competitionWebBannerImage {
  width: 100%;
  height: 100%;
  aspect-ratio: 1550 / 450;
  object-fit: cover;
}

.competitionBannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.competitionBannerTitleContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32em;
  text-align: center;
}

.competitionBannerTitle {
  font-size: 2.6em;
  font-weight: 600 !important;
  color: var(--qrencia-white);
}

/* banner styles for ipad pro  */
@media (min-height: 1365px) and (max-height: 1367px) and (min-width: 1023px) and (max-width: 1025px) {
  .competitionBannerImgContainer {
    display: none !important;
  }

  .competitionTabletImgContainer {
    display: block !important;
  }
}

@media (min-width: 2000px) {
  .competitionBannerImgContainer {
    display: block;
  }

  .competitionTabletImgContainer {
    display: none;
  }

  .competitionMobileImgContainer {
    display: none;
  }
}

@media (min-width: 1520px) and (max-width: 1999px) {
  .competitionBannerImgContainer {
    display: block;
  }

  .competitionTabletImgContainer {
    display: none;
  }

  .competitionMobileImgContainer {
    display: none;
  }
}

@media (max-width: 1290px) {
  .competitionBannerImgContainer {
    /* height: 40vh !important; */
    display: block;
  }

  .competitionBannerTitleContainer {
    width: 28em;
  }

  .competitionBannerTitle {
    font-size: 2.2em;
  }
}

@media (max-width: 1162px) {
  .competitionBannerImgContainer {
    /* height: 40vh !important; */
    display: block;
  }
}

@media (max-width: 1076px) {
  .competitionBannerImgContainer {
    /* height: 40vh !important; */
    display: block;
  }
}

@media (max-width: 990px) {
  .competitionBannerImgContainer {
    /* height: 40vh !important; */
  }

  .competitionBannerTitleContainer {
    width: 20em;
  }

  .competitionBannerTitle {
    font-size: 1.9em;
  }
}

@media (min-width: 1030px) and (max-width: 1144px) {
  .competitionBannerImgContainer {
    display: block;
    width: 100%;
    /* height: 40vh !important; */
    /* object-fit: cover; */
  }

  .competitionTabletImgContainer {
    display: none;
  }

  .competitionMobileImgContainer {
    display: none;
  }
}

@media (min-width: 991px) and (max-width: 1030px) {
  .competitionBannerImgContainer {
    display: block;
    width: 100%;
    /* height: 38vh !important; */
    /* object-fit: cover; */
  }

  .competitionTabletImgContainer {
    display: none;
  }

  .competitionMobileImgContainer {
    display: none;
  }
}

@media (min-width: 665px) and (max-width: 991.5px) {
  .competitionBannerImgContainer {
    display: none;
  }

  .competitionTabletImgContainer {
    display: block;
    width: 100%;
    aspect-ratio: 800 / 350;
    /* height: 38vh !important; */
    /* height: 350px; */
    object-fit: cover;
  }

  .competitionMobileImgContainer {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 665.5px) {
  .competitionBannerImgContainer {
    display: none;
  }

  .competitionTabletImgContainer {
    display: block;
    width: 100%;
    aspect-ratio: 800 / 350;
    /* height: 38vh !important; */
    /* height: 350px; */
    object-fit: cover;
  }

  .competitionMobileImgContainer {
    display: none;
  }

  .competitionBannerTitleContainer {
    width: 17em;
  }

  .competitionBannerTitle {
    font-size: 1.6em;
  }
}

@media (max-width: 480px) {
  .competitionBannerImgContainer {
    display: none;
  }

  .competitionTabletImgContainer {
    display: none;
  }

  .competitionMobileImgContainer {
    display: block;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 375 / 400;
    /* height: 50vh !important; */
    /* height: 400px; */
  }

  .competitionBannerTitleContainer {
    width: 80%;
  }

  .competitionBannerTitle {
    font-size: 1.4em;
  }
}

@media (max-width: 290px) {
  .competitionBannerImgContainer {
    display: none;
  }

  .competitionTabletImgContainer {
    display: none;
  }

  .competitionMobileImgContainer {
    display: block;
    width: 100%;
    /* height: 36vh !important; */
    object-fit: cover;
  }
}
