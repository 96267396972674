.welcomeTextDiv {
  width: 40%;
  margin: 10% auto;
  /* text-align: justify; */
  height: 88vh;
}

.stepwiseContainer {
  width: 50%;
  text-align: center;
  margin: 3% auto 0;
}

.stepProgress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.barCircleDiv {
  display: flex;
  align-items: center;
  flex: 1;
}

.nextButtonDiv {
  background: white;
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0.5%;
}

.nextButton {
  width: 12.5vw !important;
  margin: 0% auto;
  padding: 0%;
}

/* ------------ Progress bar -----*/

.progressBarLineDark,
.progressBarLineLight {
  flex: 1;
  height: 1vh;
  background: var(--qrencia-dark-blue);
  border-radius: 1px;
  margin-left: -0.1em;
}

.progressBarLineLight {
  background: var(--qrencia-dark-grey-border);
}

.step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  position: relative;
  z-index: 1;
}

.stepCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--qrencia-dark-blue);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  margin-left: -0.1em;
}

.step .current {
  margin-left: -1%;
}

.step .completed {
  margin-left: -1%;
}

.animatedContent {
  display: block;
  overflow: hidden;
  white-space: nowrap;
}

.welcomeHeading {
  font-weight: 600;
  animation: animateCaption 1.25s ease-out forwards;
}

.welcomeContent {
  overflow: hidden;
  white-space: normal;
  max-width: 35vw;
  opacity: 0;
  animation: animateCaption 1.25s ease-out forwards;
}

.welcomeFinishingContent {
  overflow: hidden;
  white-space: normal;
  max-width: 35vw;
  opacity: 0;
  animation: animateCaption 1.25s ease-out forwards;
  font-size: 500;
}

.contentAnimation {
  color: var(--qrencia-text-black);
  font-weight: 500!important;
  text-align: left;
  animation: animateCaption 1.25s ease-out forwards;
}

.submitButton {
  font-weight: 400 !important;
  font-size: 0.9em;
  padding: 0.2%;
  margin: 0.6% 3%;
  border-radius: 8px;
  width: 10%;
}
@keyframes animateCaption {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@media only screen and (max-width: 1120px) and (max-height: 600px) {
  .welcomeTextDiv {
    width: 60%;
    margin: 15% auto;
  }
  .stepwiseContainer {
    width: 70vw;
  }
  /* .progressBarLineDark {
    width: 21.3vw;
  }
  .progressBarLineLight {
    width: 21.3vw;
  } */
}

@media only screen and (max-width: 882px) and (max-height: 350px) {
  .welcomeTextDiv {
    width: 60%;
    margin: 15% auto;
  }
}

@media (min-width: 1024px) {
  .nextButtonDiv {
    padding: 0;
  }
}
@media (max-width: 1200) {
  .stepwiseContainer {
    width: 65vw;
  }
}

@media (max-width: 1024) {
  .stepwiseContainer {
    width: 70vw;
  }
}

@media (max-width: 991px) {
  .welcomeTextDiv {
    width: 60%;
    margin: 35% auto;
  }
  .stepwiseContainer {
    width: 70vw;
  }
}

@media (max-width: 768px) {
  .stepwiseContainer {
    width: 85vw;
  }
  .submitButton {
    width: 90% !important;
    font-size: 0.9em;
    margin: 1.5% auto;
  }
  /* .progressBarLineDark {
    width: 24.9vw;
  }
  .progressBarLineLight {
    width: 25.2vw;
  } */
  .welcomeContent {
    max-width: 60vw;
  }
  .welcomeFinishingContent{
    max-width: 60vw;
  }
  .welcomeTextDiv {
    width: 70%;
    margin: 25% auto;
  }
}

@media (max-width: 480px) {
  .welcomeTextDiv {
    width: 80%;
    margin: 35% auto;
  }
  .stepwiseContainer {
    width: 90vw;
  }
  .progressBarLineDark {
    height: 0.8vh;
  }

  .progressBarLineLight {
    height: 0.8vh;
  }

  .stepCircle {
    font-size: 1em;
  }

  .stepText {
    font-size: 13px;
    margin-bottom: 3%;
    margin-top: 11%;
  }

  .nextButton {
    width: 90% !important;
    font-size: 0.8em;
    margin: 1.5% auto;
    padding: 0.6%;
  }
  .welcomeContent {
    max-width: 60vw;
  }
  .welcomeFinishingContent{
    max-width: 60vw;
  }
}

@media (max-width: 425px) {
  .welcomeTextDiv {
    margin: 45% auto;
  }
  .stepText {
    font-size: 13px;
    margin-bottom: 3%;
    margin-top: 15%;
  }
}
