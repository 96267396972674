.profileCard {
  height: 22px;
  border: 1.5px solid var(--qrencia-select-box-border);
  width: max-content;
  margin: 2px 2px;
  padding: 3px 6px;
  line-height: 16px;
  border-radius: 10px;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.4em;
  cursor: pointer;
}
.profileCard:hover {
  cursor: pointer;
  /* background: var(--twitter-blue); */
  background-color: var(--qrencia-select-box-background);
}
.profileCard > svg {
  margin-left: 0.6em;
  cursor: pointer;
  color: var(--qrencia-astrick-red);
  font-weight: 600 !important;
}
.studentImage {
  width: 20px;
  height: 20px !important;
  border-radius: 50%;
  border: 2px solid var(--qrencia-white);
  margin-left: -5px;
}
