.courseDuration {
  font-size: 65%;
  display: flex;
  font-weight: 600 !important;
  margin: 1.5% 1% 1% 0;
}
.activityTypeCompetition {
  color: var(--qrenica-competition-differentiator-color);
}

.activityTypeCourse {
  color: var(--qrenica-course-differentiator-color);
}
.activityTypeWorkshop {
  color: var(--qrencia-workshop-differentiator-color);
}
@media (min-width: 1321px) and (max-width: 1378px) {
  .courseDuration {
    font-size: 62%;
  }
}
@media (min-width: 1251px) and (max-width: 1320px) {
  .courseDuration {
    font-size: 58%;
  }
}
@media (min-width: 1190px) and (max-width: 1250px) {
  .courseDuration {
    font-size: 55%;
  }
}
@media (min-width: 1100px) and (max-width: 1190px) {
  .courseDuration {
    font-size: 50%;
  }
}
@media (min-width: 1025px) and (max-width: 1100px) {
  .courseDuration {
    font-size: 46%;
  }
}
@media (min-width: 991px) and (max-width: 1024px) {
  .courseDuration {
    font-size: 44%;
  }
}
@media (min-width: 768px) and (max-width: 820px) {
  .courseDuration {
    font-size: 60%;
  }
}
@media (max-width: 767px) {
  .courseDuration {
    font-size: 69%;
  }
}
