.learningActivityHeading {
  font-size: 1em;
  font-weight: bold;
  margin: 2em 0em 0.9em 0.5em;
}
.activityContainer {
  width: 100%;
  background-color: var(--qrencia-white);
  box-shadow: 0 3px 21px 0 rgba(100, 100, 100, 0.12);
  padding: 0.4em 0em;
  border-radius: 8px;
  height: fit-content;
  /* max-height: 82vh; */
  margin-bottom: 4em;
  /*overflow: scroll;*/
  padding-bottom: 1em;
}
.calendarDatesSection {
  width: 60%;
  margin: 1.5em auto;
  border: 1px solid #d5d8dc;
  padding: 0.8em 1em;
  border-radius: 1em;
}
.noResultsText {
  font-size: 1em;
  padding: 1em;
  text-align: center;
}

.cardsContainer {
  margin-top: 1.6em;
  padding: 0em 1.2em;
}

@media (max-width: 990px) {
  .calendarDatesSection {
    width: 80%;
    padding: 0.7em 0.9em;
    margin: 1.4em auto;
  }
}
@media (max-width: 768px) {
  .calendarDatesSection {
    width: 80%;
    padding: 0.7em 0.9em;
    margin: 1.4em auto;
  }
}
@media (max-width: 540px) {
  .calendarDatesSection {
    width: 90%;
    padding: 0.6em 0.8em;
    margin: 1em auto;
  }
}
@media (max-width: 480px) {
  .calendarDatesSection {
    width: 95%;
    padding: 0.6em 0.7em;
    margin: 0.7em auto;
  }
  .noResultsText {
    font-size: 0.8em;
    padding: 0.5em;
  }
  .cardsContainer {
    padding: 0em 0em;
  }
}
