.loaderIcon {
  text-align: center;
  font-size: 2em !important;
  color: var(--qrencia-dark-blue) !important;
}
.participantDetailsTable {
  overflow: scroll;
  margin-bottom: 2%;
}
.modalHeading {
  font-size: 1.2em;
  font-weight: 600;
}
.tableContainer {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.tableContainer::-webkit-scrollbar {
  width: 0;
}
.tableHead th,
.tableBody td {
  width: 16.66%;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}
.teamNameHeadings {
  font-size: 1.2em;
  color: var(--qrencia-dark-blue);
  margin-top: 1.5em;
  font-weight: 600;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.tableHead {
  text-align: center;
  font-size: 1em;
  background-color: var(--qrencia-light-blue-icon-background);
}

.tableData {
  font-size: 0.8em;
}

.tableBody {
  text-align: center;
}

.tableBody > tr > td {
  vertical-align: middle;
  font-size: 0.8em;
  -webkit-touch-callout: none;
  user-select: text;
  -webkit-user-select: text;
  text-decoration: none;
  -webkit-text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
.tableBodyRow {
  border-spacing: 20px 0px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 2em;
  font-size: 0.8em;
}
.teamDetailsNotAvailable {
  font-size: 0.9em;
  text-align: center;
  margin: 2% 0% 1% 0%;
  font-weight: 400;
  border-radius: 10px;
  color: var(--qrencia-text-black);
}
