.advertismentMainContainer {
    height: 100vh;
    width: 100%;
    background-color: var(--performance-page-skyblue-bg);
}

.broughtToYouByContainer {
    width: 80%;
    background-color: var(--qrencia-white);
    padding: 2em;
    height: 90vh;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
}

.broughtToYouByText {
    font-size: 1em;
    font-weight: 500 !important;
    padding: 0 0 10px 0;
    font-style: italic;
    color: var(--qrencia-grey-text-color);
}

.sponserText {
    font-size: 1.5em;
    font-weight: 600 !important;
    color: var(--qrencia-dark-blue);
}

.adTitleText {
    font-size: 1em;
    font-weight: 550 !important;
    color: var(--qrencia-grey-text-color);
}

.adSubTitleText {
    font-size: 0.9em;
    font-weight: 500 !important;
    color: var(--qrencia-grey-text-color);
}

.advertismentImageContainer {
    width: 100%;
    height: 100vh;
    padding: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em;
}

.dedicatedAdvertismentContainer {
    padding: 0 !important;
}

.advertismentImage {
    width: 90%;
    max-height: 100vh !important;
    max-width: 100vh !important;
}

.advertismentDedicatedImage {
    width: 90%;
    max-height: 100vh !important;
    max-width: 100vh !important;
    aspect-ratio: 1;
}

@media (min-width: 991px) and (max-width: 1200px) {
    .broughtToYouByContainer {
        width: 90%;
    }

    .advertismentImage {
        width: 90%;
    }

    .advertismentDedicatedImage {
        width: 90%;
    }
}

@media (min-width: 767px) and (max-width: 991.5px) {
    .broughtToYouByContainer {
        width: 100%;
    }

    .advertismentImageContainer {
        padding: 1em;
    }

    .advertismentImage {
        width: 95%;
    }

    .advertismentDedicatedImage {
        width: 95%;
    }
}

@media (min-width: 580px) and (max-width: 767.5px) {
    .broughtToYouByContainer {
        width: 90%;
        height: 35vh;
        justify-content: center;
    }

    .broughtToYouByText {
        font-size: 1em;
    }

    .sponserText {
        font-size: 1.5em;
        font-weight: 600 !important;
        color: var(--qrencia-dark-blue);
    }

    .adTitleText {
        font-size: 1em;
        font-weight: 500 !important;
    }

    .adSubTitleText {
        font-size: 0.85em;
    }

    .advertismentImageContainer {
        height: 65vh;
        padding: 1em;
    }

    .advertismentImage {
        width: 95%;
    }

    .advertismentDedicatedImage {
        width: 56%;
    }
}

@media (min-width: 425px) and (max-width: 580.5px) {
    .broughtToYouByContainer {
        width: 90%;
        height: 40vh;
        justify-content: center;
    }

    .broughtToYouByText {
        font-size: 0.9em;
        text-align: center;
    }

    .sponserText {
        font-size: 1.3em;
        font-weight: 600 !important;
        color: var(--qrencia-dark-blue);
    }

    .adTitleText {
        font-size: 0.9em;
        font-weight: 500 !important;
    }

    .adSubTitleText {
        font-size: 0.85em;
    }

    .advertismentImageContainer {
        height: 60vh;
        padding: 0.8em;
    }

    .advertismentImage {
        width: 95%;
    }

    .advertismentDedicatedImage {
        width: 65%;
    }
}

@media (max-width: 425.5px) {
    .broughtToYouByContainer {
        width: 90%;
        height: 50vh;
        justify-content: center;
    }

    .broughtToYouByText {
        font-size: 0.8em;
        text-align: left;
    }

    .sponserText {
        font-size: 1.2em;
        font-weight: 600 !important;
        color: var(--qrencia-dark-blue);
        text-align: center;
    }

    .adTitleText {
        font-size: 0.8em;
        font-weight: 500 !important;
        text-align: center;
    }

    .adSubTitleText {
        font-size: 0.75em;
    }

    .advertismentImageContainer {
        height: 50vh;
        padding: 0.5em;
    }

    .advertismentImage {
        width: 95%;
    }

    .advertismentDedicatedImage {
        width: 76%;
    }
}