.shareIconPannelContainerParent {
  position: relative;
}

.shareIconPannelContainer {
  display: flex;
  right: 10%;
  top: -2em;
  gap: 5px;
  background-color: var(--qrencia-white);
  padding: 5px;
  border-radius: 5px;
  width: max-content;
}

.shareIcon {
  font-size: 1.6em !important;
  background-color: var(--qrencia-card-blue);
  color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
  right: 15px;
  top: 6em;
  padding: 2px;
  margin: 0 0 5px 0.5em;
}
.downloadParticipantSheetSection {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
}
.shareOnLinkedIn {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1.5em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnFacebook {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1.5em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnTwitter {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1.5em;
  border-radius: 20px;
  cursor: pointer;
}
.expiredText {
  color: var(--qrencia-error-red) !important;
}
.activeText {
  color: var(--qrencia-success-green) !important;
}
.shareOnMailButton {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1.5em;
  cursor: pointer;
}

.copyToClipboard {
  color: var(--qrencia-card-blue);
  font-size: 1.5em;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  font-size: 1.5em;
  font-weight: bold;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.modalYesButton,
.modalYesButton:hover,
.modalYesButton:active,
.modalYesButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--qrencia-success-green) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalNoButton,
.modalNoButton:hover,
.modalNoButton:active,
.modalNoButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--cancel-red-button) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalCloseButton,
.modalCloseButton:hover,
.modalCloseButton:active,
.modalCloseButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalAlertIcon {
  font-size: 3em;
  color: var(--cancel-red-button);
  text-align: center;
}
.downloadBtn {
  width: fit-content;
  text-align: center;
  padding-left: 0.7em;
  padding-right: 0.7em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.reminderEmail {
  width: fit-content;
  text-align: center;
  padding-left: 0.4em;
  padding-right: 0.4em;
}
.downloadZip {
  width: fit-content;
  text-align: center;
  padding-left: 0.7em;
  padding-right: 0.7em;
  margin-right: 10px;
  margin-top: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.modalSuccessIcon {
  font-size: 3em;
  color: var(--qrencia-success-green);
  text-align: center;
}

.modalErrorIcon {
  font-size: 3em;
  color: var(--qrencia-error-red);
  text-align: center;
}

.modalAlertText {
  font-size: 0.9em;
  text-align: center;
}

.modalBody {
  padding: 1.5em 1em 1em;
}

.modalFooter {
  display: flex;
  justify-content: center;
  padding: 0.5em 1em 1.5em 1em;
  border: none;
  gap: 1em;
}

.disabledButton {
  cursor: not-allowed !important;
}

.tableLabelColumn {
  width: 35% !important;
  font-weight: 500;
}

.tableDescriptionColumn {
  width: 65% !important;
}

.tableHeaderOne {
  width: 20% !important;
  font-weight: 500;
}

.tableHeaderTwo {
  width: 30% !important;
  font-weight: 500;
}

.tableHeaderThree {
  width: 25% !important;
  font-weight: 500;
}

.tableHeaderFour {
  width: 25% !important;
  font-weight: 500;
}

.tableDescriptionColumnOne {
  width: 20% !important;
}

.tableDescriptionColumnTwo {
  width: 30% !important;
}

.tableDescriptionColumnThree {
  width: 25% !important;
}

.tableDescriptionColumnFour {
  width: 25% !important;
}

.pageLoader {
  color: var(--qrencia-dark-blue);
  font-size: 2.2em;
  text-align: center;
}
.participantDetailsTable {
  overflow: scroll;
  margin-bottom: 2%;
}
.tableHeaderEnrolledDate {
  min-width: 10em !important;
  font-weight: 500;
}
.goBackButton,
.goBackButton:hover,
.goBackButton:active,
.goBackButton:focus {
  font-size: 1.1em;
  margin-bottom: 0.5em;
  background-color: var(--qrencia-white) !important;
  color: var(--qrencia-dark-blue) !important;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.costRow {
  text-decoration: line-through;
  margin-right: 0.2em;
  font-size: 1em;
  color: var(--qrencia-faq-icon-color);
}

.costContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1em;
}

.paymentTable {
  font-size: 1em;
}

.addedFaqs {
  position: relative;
  background-color: var(--qrencia-faq-card-bg);
  border: 1px solid var(--qrencia-nudges-card-border);
  border-radius: 10px;
  padding: 1em 0.6em 0.6em 0.6em;
  margin: 1em auto;
  box-shadow: var(--qrencia-template-box-shadow);
  transition: 0.2s all ease-in-out;
}

.addedFaqs:hover {
  box-shadow: 0 3px 21px 0 rgba(100, 100, 100, 0.2);
}

.displayedFaqs {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  padding: 0em 0.4em 0em 0.4em;
}

.questions {
  font-weight: 520;
  font-size: 0.9em;
  width: 100%;
}

.answers {
  font-size: 0.8em;
  margin-top: 10px;
  text-align: justify;
}

.spocHeadingText {
  font-size: 1em;
  font-weight: 500 !important;
  padding: 0 0 1em 0;
}

@media (max-width: 990px) {
  .quillDescription p {
    font-size: 1em;
  }
  .answers p {
    font-size: 1em;
  }
}
@media (max-width: 580px) {
  .paymentTable {
    font-size: 0.9em;
  }
  .downloadBtn {
    padding-left: 0.7em;
    padding-right: 0.7em;
  }
  .downloadZip {
    font-size: 0.72em;
  }
}

@media (max-width: 480px) {
  .paymentTable {
    font-size: 0.8em;
  }

  .tableHeaderOne {
    font-size: 0.85em;
  }

  .tableHeaderTwo {
    font-size: 0.85em;
  }

  .tableHeaderThree {
    font-size: 0.85em;
  }

  .tableHeaderFour {
    font-size: 0.85em;
  }

  .questions {
    font-weight: 500;
    font-size: 0.8em;
  }

  .answers {
    font-size: 0.75em;
  }
}
