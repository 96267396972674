.updateInvitationPage {
  background-color: #e8f3ff;
  min-height: 70vh;
  width: 100%;
  padding-top: 4em;
  padding-bottom: 5em;
}

.detailsContainer {
  background-color: var(--qrencia-white);
  box-shadow: 4px 4px 18px rgba(137, 141, 154, 0.3);
  border-radius: 10px;
  padding: 1em 1em 1.3em 1em;
  width: 80%;
  margin: auto;
}

.heading {
  color: var(--qrencia-text-black);
  font-weight: 600;
  line-height: 1.2em;
  margin-top: -23px;
  font-size: 1.8em;
  margin-bottom: 1.5%;
}
.detailsHeading {
  font-size: 1.1em;
}
.blueTriangleImage {
  /* margin-right: 430px; */
  width: 30px;
  margin-left: -10px;
}

.headingSection {
  width: fit-content;
  position: relative;
  margin: 0em auto 1.5em auto;
  text-align: left;
  max-width: 80%;
}
.columnContainer {
  margin-top: 1em;
}
.expertDetailsLabel {
  color: var(--qrencia-dark-blue);
  font-size: 0.9em;
  font-weight: 500 !important;
}
.expertDetailsText {
  font-size: 0.9em;
  word-wrap: break-word;
}
.expertDetailsLinkLabel,
.expertDetailsLinkLabel:hover,
.expertDetailsLinkLabel:active,
.expertDetailsLinkLabel:focus {
  text-decoration: none;
  font-size: 0.75em !important;
  cursor: pointer;
  color: var(--qrencia-text-black);
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg);
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  padding: 1px 8px;
}
.buttonDiv{
  width: 100%;
  text-align: center;
}
.warningDiv{
  width: 100%;
  text-align:-webkit-center;
}

.selectButton {
  font-size: 0.8em !important;
  padding: 0.61em 1em;
  margin-top: 1em;
  width: 8em;
  margin-right: 1em;
}
.preLoaderContainer {
  height: 100vh;
  width: 100%;
  position: relative;
}

.preLoaderDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrenciaGlobe {
  width: 80px;
  margin-top: -98px;
}

.loaderText {
  padding: 2em 0 0 0;
  font-size: 1em;
  text-align: center;
}

.loaderIcon {
  font-size: 6em !important;
  color: var(--qrencia-dark-blue) !important;
  text-align: center;
}

.warningMessage {
  margin: 1em 0;
  background-color: var(--qrencia-extended-page-fun-fact-background);
  border-radius: 6px;
  border: 1px solid var(--instagram-yellow);
  width: fit-content;
  word-wrap: break-word;
}

.warningMessage svg {
  font-size: 1.5em !important;
  padding: 0;
  color: var(--instagram-yellow);
}

.warningMessage p {
  font-size: 0.75em !important;
  font-weight: 500 !important;
  color: var(--instagram-yellow);
  padding: 0.5em;
}
@media (max-width: 580px) {
  .warningMessage svg {
    font-size: 1.2em !important;
  }

  .warningMessage p {
    font-size: 0.65em !important;
  }
}
@media (min-width: 990px) and (max-width: 1185px) {
  .heading {
    font-size: 1.7em;
  }

  .blueTriangleImage {
    width: 28px;
    margin-left: -10px;
  }
}
@media (max-width: 900px) {
  .warningMessage {
    width: 90%;
  }
}
@media (min-width: 819px) and (max-width: 990px) {
  .heading {
    font-size: 1.5em;
  }

  .blueTriangleImage {
    width: 25px;
    margin-left: -10px;
  }
}
@media (min-width: 768px) and (max-width: 818px) {
  .heading {
    font-size: 1.35em;
  }
  .blueTriangleImage {
    width: 22px;
    margin-left: -12px;
  }
}
@media (min-width: 501px) and (max-width: 767px) {
  .blueTriangleImage {
    width: 22px;
    margin-left: -12px;
  }
}
@media (max-width: 500px) {
  .blueTriangleImage {
    margin-left: -12px;
    width: 20px;
  }
}
@media (max-width: 767px) {
  .heading {
    font-size: 1.35em;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 1.12em;
    font-weight: 650 !important;
  }
}

@media (max-width: 360px) {
  .heading {
    font-size: 1.12em;
  }
}
@media (max-width: 480px) {
  .blueTriangleImage {
    margin-left: -12px;
    width: 20px;
    margin-top: -5px;
  }
}
.loader {
  --b: 8px;
  /* border thickness */
  --n: 12;
  /* number of dashes*/
  --g: 10deg;
  /* gap between dashes*/
  --c: var(--qrencia-dark-blue);
  /* the color */

  width: 120px;
  /* size */
  aspect-ratio: 1;
  border-radius: 100%;
  padding: 1px;
  background: conic-gradient(#0000, var(--c)) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: load 1s infinite steps(var(--n));
}

@keyframes load {
  to {
    transform: rotate(1turn);
  }
}
