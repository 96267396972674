.pageContainer {
  width: 90%;
  margin: 2em auto 4em auto;
  padding: 1.5em 3em;
  background-color: var(--qrencia-white);
  border-radius: 5px;
}
.errorBox{
  margin: 0em 0.8em;
}
.heading {
  font-size: 1.4em;
  color: var(--qrencia-text-black);
  margin: 0.5em 0.5em 0.8em 0em;
}
.postFeedbackHeading {
  font-size: 1.2em;
  margin: 2em 0.5em 0.1em 0em;
}
.searchInputBox {
  width: 30%;
  border-radius: 4px;
}
.resetSearchIcon {
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 400;
  margin-left: -1.2em;
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-select-box-background);
}
.inputBoxContainer {
  padding: 0.8em 0em;
  margin-bottom: 1em;
  margin-top: 0.4em;
}
.loaderContainer {
  padding: 2em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-grey-border);
  width: 80%;
  margin: 1em auto;
  text-align: center;
  background-color: var(--qrencia-white);
  margin-top: 5em;
}

.loaderIcon {
  font-size: 3em !important;
  color: var(--qrencia-dark-blue) !important;
}
.courseHeading {
  font-size: 1.2em;
  font-weight: 600 !important;
  color: var(--qrencia-grey-text-color);
}
.sessionExpiredText {
  font-size: 1em;
}
.sessionExpiredText > span {
  color: var(--linkedin-blue);
  font-weight: 600 !important;
  cursor: pointer;
}
.loginSection {
  margin-top: 4em;
}
.tableContainer {
  display: block;
}
.studentCardContainer {
  display: none;
  overflow-y: scroll;
  max-height: 50em;
  /* background-color: #f8f9f9; */
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media (min-width: 768px) and (max-width: 990px) {
  .courseHeading {
    font-size: 1.05em;
  }
  .searchInputBox {
    width: 50%;
    border-radius: 4px;
  }
}
@media (max-width: 767.5px) {
  .courseHeading {
    font-size: 0.96em;
  }
  .searchInputBox {
    width: 60%;
    border-radius: 4px;
  }
  .pageContainer {
    width: 90%;
    margin: 1em auto;
    padding: 1.5em 2em;
  }
  .tableContainer {
    display: none;
  }
  .studentCardContainer {
    display: block;
  }
}
@media (max-width: 500px) {
  .courseHeading {
    font-size: 1em;
    font-weight: 600 !important;
  }
  .searchInputBox {
    width: 100%;
    border-radius: 4px;
  }
  .heading {
    font-size: 1.2em;
    color: var(--qrencia-text-black);
    margin: 0.5em 0.5em 0.8em 0em;
  }
  .postFeedbackHeading {
    font-size: 1em;
    margin: 2em 0.5em 0.1em 0em;
  }
}
@media (max-width: 400px) {
  .pageContainer {
    padding: 1em;
  }
}
