.videoContainer {
  position: relative;
}

.videoContent {
  width: 100%;
  border-radius: 1rem;
  position: relative;
  background-color: var(--qrencia-text-black);
  aspect-ratio: 1.2;
  border: 1px solid var(--qrencia-dark-grey-border) !important;
}


.videoIcon {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  position: absolute;
  cursor: pointer;
}

.videoTitle {
  margin-top: 0.5em;
  margin-left: 0.3em;
  margin-bottom: 0.5em;
  color: var(--qrencia-grey-text-color);
}

.closeButton {
  top: 2%;
  right: 4%;
  width: 10%;
  position: absolute;
  cursor: pointer;
  font-size: 2em;
  color: var(--qrencia-white);
}

@media (max-width: 576px) {
  .videoTitle {
    margin-top: 0.7em;
    margin-bottom: 2em;
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .videoTitle {
    margin-top: 0.7em;
    margin-bottom: 1em;
    font-size: 1.1em;
  }
}