.skillPageRaiseNudgeHeading {
  font-size: 1.025em;
  margin: 2% 0% 2% 2%;
  font-weight: 650;
  color: var(--qrencia-text-black);
}

.skillPageRaiseNudgeForm {
  background-color: var(--qrencia-white);
  border-radius: 10px;
}

.skillPageRaiseNudgeFormInputBox {
  margin: 0 0 1em 0;
}

.skillPageRaiseNudgeFormInputBox .skillPageRaiseNudgeTypeahead .form-control {
  height: 2.7em;
  font-size: 0.8em !important;
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  margin-top: -18px !important;
  border-right: none;
  width: 100%;
  flex: 1;
  flex-grow: 2;
}

.skillPageRaiseNudgeForm .formLabel,
.externalNudgeForm .formLabel {
  position: relative;
}

.skillPageRaiseNudgeForm .dropdown-menu,
.externalNudgeForm .dropdown-menu {
  inset: 10px auto auto -31px !important;
  margin-left: 2.2em;
  font-size: 0.9em;
  padding: 0;
  border-radius: 0;
  border: solid 1px var(--qrencia-select-box-border);
}

.removeFileOption {
  margin-left: 2px;
  font-size: 0.8em !important;
  margin-top: 2px;
}

.skillPageRaiseNudgeForm .dropdown-item:focus,
.skillPageRaiseNudgeForm .dropdown-item:hover,
.skillPageRaiseNudgeForm .dropdown-item:active,
.skillPageRaiseNudgeForm .dropdown-item:visited,
.externalNudgeForm .dropdown-item:focus,
.externalNudgeForm .dropdown-item:hover,
.externalNudgeForm .dropdown-item:active,
.externalNudgeForm .dropdown-item:visited {
  background: var(--qrencia-select-box-background) !important;
}

.skillPageRaiseNudgeForm .descriptionLabel,
.externalNudgeForm .descriptionLabel {
  font-weight: 500;
  font-size: 0.85em;
  margin-left: 6px;
  padding: 0em 0.3em;
  margin-bottom: 0;
  z-index: 1;
  background-image: linear-gradient(
    to bottom,
    var(--qrencia-white),
    var(--qrencia-select-box-background)
  );
  position: relative;
}
.skillPageRaiseNudgeTypeaheadContainer {
  display: flex;
  width: 100%;
}
.skillPageRaiseNudgeTypeaheadContainer .skillPageRaiseNudgeTypeahead {
  flex: 2;
}
.skillPageRaiseNudgeTypeaheadContainer .skillPageRaiseNudgeTypeahead:focus,
.skillPageRaiseNudgeTypeaheadContainer .skillPageRaiseNudgeTypeahead:active {
  border-right: none;
}
.skillPageRaiseNudgeTypeaheadContainer .dropdown-item.active,
.dropdown-item:active {
  background-color: var(--qrencia-select-box-background);
}

.skillsPageTypeaheadClearIcon {
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  height: 2.15em;
  margin-top: -1.12em;
  border-left: none;
  cursor: pointer;
  width: 1.2em;
}
.skillPageRaiseNudgeQuillInputBox {
  height: 100%;
  /* font-size: 1.2em !important; */
  margin-top: -10px !important;
}

.skillPageRaiseNudgeQuillInputBox p,
.skillPageRaiseNudgeQuillInputBox ul,
.skillPageRaiseNudgeQuillInputBox ol {
  font-size: 1em !important;
}

.switchContainer {
  margin-top: 1.2em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.switchText {
  margin-right: 1em;
  font-size: 0.8em;
  width: fit-content;
}
.switchContainer > label {
  line-height: 1em;
  margin-top: auto;
}
.checkBox {
  vertical-align: middle !important;
  margin-right: 1em;
}

.skillPageRaiseNudgeForm .ql-editor p,
.externalNudgeForm .ql-editor p {
  font-size: 1.02em;
}

.skillPageRaiseNudgeForm .ql-editor {
  border: solid 1px var(--qrencia-select-box-border);
  border-top: none !important;
  min-height: 6em !important;
}
.externalNudgeForm .ql-editor {
  border: solid 1px var(--qrencia-select-box-border);
  border-top: none !important;
  min-height: 15em !important;
}
.skillPageRaiseNudgeForm .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
  min-height: 6em !important;
}
.externalNudgeForm .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
  min-height: 15em !important;
}

.skillPageRaiseNudgeForm .ql-toolbar,
.externalNudgeForm .ql-toolbar {
  border: solid 1px var(--qrencia-select-box-border) !important;
  background: var(--qrencia-select-box-background);
}

.skillPageRaiseNudgeForm .ql-snow .ql-tooltip,
.externalNudgeForm .ql-snow .ql-tooltip {
  z-index: 10;
}

.skillPageRaiseNudgeForm .ql-container.ql-snow,
.externalNudgeForm .ql-container.ql-snow {
  border: none !important;
}

.skillPageRaiseNudgeForm .submitButtonContainer,
.externalNudgeForm .submitButtonContainer {
  text-align: center;
  padding: 1em 0 0 0;
  margin-top: -1em;
}

.skillPageRaiseNudgeForm .submitButton,
.externalNudgeForm .submitButton {
  font-size: 0.8em;
}

.skillPageRaiseNudgeForm .formErrorMessage,
.externalNudgeForm .formErrorMessage {
  color: var(--qrencia-error-red);
  font-size: 0.8em;
  margin-bottom: 0;
}

.skillPageRaiseNudgeForm .uploadFileLabel,
.externalNudgeForm .uploadFileLabel {
  font-weight: 500;
  font-size: 0.85em;
  margin-left: 6px;
  padding: 0em 0.3em;
  margin-bottom: 0;
  position: absolute;
  z-index: 1;
  top: -15px;
  background-image: linear-gradient(
    to bottom,
    var(--qrencia-white),
    var(--qrencia-select-box-background)
  );
}

.skillPageRaiseNudgeFileUpload {
  position: relative;
}
.raiseNudgeFileUpload {
  margin-top: -1em;
}
.skillPageRaiseNudgeFileUpload input[type="file"]::file-selector-button {
  position: absolute;
  right: -0.7em;
  top: -0.59em;
  height: 4em;
  line-height: 1.1em;
  z-index: 1;
  width: 9em !important;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

/* file input styles for mobiles */
@media (max-width: 500px) {
  .skillPageRaiseNudgeFileUpload input[type="file"]::file-selector-button {
    position: absolute;
    right: 0.6em;
    top: 0.4em;
    height: 2.2em;
    line-height: 1em;
    z-index: 1;
    width: 9em !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.skillPageRaiseNudgeBackButton {
  font-size: 1em;
  color: var(--qrencia-dark-blue) !important;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin-bottom: 0.5em;
}

.skillPageRaiseNudgeIButton {
  font-size: 1em;
  color: var(--qrencia-placeholder-color);
  outline: none;
}

.skillPageRaiseNudgeTooltip {
  z-index: 10;
  font-size: 0.9em;
  font-weight: 400 !important;
  color: var(--qrencia-text-black);
  background-color: var(--qrencia-nudges-card-text-bg);
}

.skillPageRaiseNudgeForm .spinner,
.externalNudgeForm .spinner {
  animation: spin-animation 0.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@media (min-width: 992px) and (max-width: 1290px) {
  .skillPageRaiseNudgeHeading {
    font-size: 1em;
    margin-left: 3%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .skillPageRaiseNudgeHeading {
    font-size: 1em;
  }
}

@media (max-width: 767px) {
  .skillPageRaiseNudgeHeading {
    font-size: 0.95em;
  }
  .switchContainer {
    margin-bottom: 0.6em;
    margin-top: 0em;
  }
}

@media (max-width: 520px) {
  .skillPageRaiseNudgeForm,
  .externalNudgeForm {
    padding: 0;
  }

  .skillPageRaiseNudgeForm .submitButton,
  .externalNudgeForm .submitButton {
    font-size: 0.85em;
    padding: 2px 10px;
  }

  .skillPageRaiseNudgeForm .ql-editor,
  .externalNudgeForm .ql-editor {
    font-size: 1em;
  }

  .skillPageRaiseNudgeForm .ql-editor p,
  .externalNudgeForm .ql-editor p {
    font-size: 1em;
  }

  .skillPageRaiseNudgeBackButton {
    font-size: 1.2em;
  }

  .skillPageRaiseNudgeTooltip {
    width: 80%;
  }
}
