.copyIcon {
  margin-left: 10px;
  cursor: pointer;
  color: var(--qrencia-dark-blue);
}

.copyIcon:hover {
  color: var(--qrencia-light-blue);
  transform: scale(1.2);
}

.copySuccess {
  color: var(--qrencia-success-green);
  margin-top: 5px;
}

.title {
  text-align: center;
  margin: 1rem 0 0;
  font-weight: 600;
  font-size: 1.5rem;
}

.textContainer {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.closeButton {
  position: absolute;
  top: 3px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2rem;
  /* font-weight: bold; */
  cursor: pointer;
  color: #000;
}

.closeButton:hover {
  color: var(--qrencia-error-message);
}

.buttonContainer {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 2rem 1rem;
}

.whatsappButton {
  background: #25d366 !important;
  border-radius: 2rem;
  border: none;
  padding: 3% 5%;
}

.whatsappButton:hover {
  background: #128c7f !important;
}

.callUsButton {
  /* background: #25d366; */
  border-radius: 2rem;
  border: none;
  padding: 3% 5%;
}

.callUsButton:hover {
  opacity: 1.2;
}

@media (max-width: 446px) {
  .buttonContainer {
    gap: 0rem;
    margin: 2rem 0rem;
  }

  .callUsButton {
    margin-right: 1rem;
  }
}
@media (max-width: 396px) {
  .callUsButton {
    font-size: 0.8rem;
  }

  .whatsappButton {
    font-size: 0.8rem;
  }
}
