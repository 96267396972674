.categoryTabContainer {
  background: var(--qrencia-obr-comment-bg);
  width: 100%;
  border: none;
  margin: 0% 0% 3% 0%;
}

.profileSkillCategoryDiv {
  width: 100%;
}

#profileSkillCategoryFilterTabs .nav-item {
  padding: 0% 1%;
  border-radius: 6px;
}

#profileSkillCategoryFilterTabs .nav-tabs {
  border-radius: 6px;
  border: none;
  padding: 0.5% 1% 0.5% 0%;
  margin: 0% 0% 2% 0%;
  justify-content: space-between;
}

.profileSkillCategoryDiv .nav-tabs .nav-item .nav-link.active {
  font-size: 0.9em;
  border-bottom: none !important;
  font-weight: 500;
  background: white !important;
  color: var(--qrencia-text-black);
  border-radius: 6px;
  padding: 5px 10px;
  margin: 0px 5px;
}

.profileSkillCategoryDiv .nav-tabs .nav-link {
  font-size: 0.9em;
  color: var(--qrencia-tabs-inactive);
  font-weight: 500;
  border: none;
  width: max-content;
  padding: 5px;
  margin: 0px 5px;
}

@media (max-width: 1350px) {
  .profileSkillCategoryDiv .nav-tabs .nav-item .nav-link.active {
    font-size: 0.8em;
    padding: 5px 10px;
    margin: 0px 5px;
  }

  .profileSkillCategoryDiv .nav-tabs .nav-link {
    font-size: 0.8em;
    width: max-content;
    padding: 5px;
    margin: 0px 5px;
  }
  .categoryTabContainer {
    padding: 1%;
  }
}
@media (max-width: 1200px) {
  .profileSkillCategoryDiv .nav-tabs .nav-item .nav-link.active {
    font-size: 0.75em;
    min-width: 7em;
    padding: 5px;
    margin: 0px 5px;
    width: max-content;
  }

  .profileSkillCategoryDiv .nav-tabs .nav-link {
    font-size: 0.75em;
    padding: 5px;
    margin: 0px 5px;
  }
  .categoryTabContainer {
    padding: 1%;
  }
}
@media (max-width: 991px) {
  .categoryTabContainer {
    flex-wrap: nowrap;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 0; /* Safari and Chrome */
    height: 0;
  }
  .profileSkillCategoryDiv .nav-tabs .nav-item .nav-link.active {
    padding: 5px;
    margin: 0px 5px;
  }

  .profileSkillCategoryDiv .nav-tabs .nav-link {
    font-size: 0.7em;
    width: max-content;
    padding: 5px;
    margin: 0px 5px;
  }
}
@media (max-width: 768px) {
  .profileSkillCategoryDiv .nav-tabs .nav-item .nav-link.active {
    min-width: 7em;
    padding: 5px;
    margin: 0px 5px;
  }

  .profileSkillCategoryDiv .nav-tabs .nav-link {
    font-size: 0.7em;
    color: var(--qrencia-tabs-inactive);
    font-weight: 500;
    padding: 5px;
    margin: 0px 5px;
    border: none;
  }
}
@media (max-width: 650px) {
  .profileSkillCategoryDiv .nav-tabs .nav-link {
    font-size: 0.7em;
    padding: 5px;
    margin: 0px 5px;
  }
  .profileSkillCategoryDiv .nav-tabs .nav-item .nav-link.active {
    font-size: 0.7em;
    padding: 5px;
    margin: 0px 5px;
  }
}
@media (max-width: 480px) {
  .categoryTabContainer {
    flex-wrap: inherit;
    overflow: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    -webkit-overflow-scrolling: auto;
  }
  @supports (-webkit-touch-callout: none) {
    /* iOS-specific styles */
    .categoryTabContainer {
      padding-left: 12em;
    }
  }

  .categoryTabContainer::-webkit-scrollbar {
    display: none;
  }

  .categoryTabContainer::-webkit-scrollbar {
    width: 0; /* Safari and Chrome */
    height: 0;
  }

  .profileSkillCategoryDiv .nav-tabs .nav-link {
    font-size: 0.8em !important;
    margin: 0px 5px;
    margin-top: -2px;
  }
  .profileSkillCategoryDiv .nav-tabs .nav-item .nav-link.active {
    padding: 3px 7px;
    margin: 0px 5px;
    font-size: 0.8em !important;
  }
}
