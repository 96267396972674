.section {
  width: 85%;
  margin: 1em auto;
}

.messageSection {
  width: 85%;
  margin: 0.2em auto 0.3em auto;
}
.expertsImageContainer {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding: 0em 1em;
}
.expertsImageContainer > img {
  width: 100%;
  border-radius: 5px;
}
.successfulExpertsContainer {
  padding: 0px 12px;
}
.successfulExpertsVideoContainer {
  font-size: 0.9em;
}
.qrenciaForExpertsSection {
  background-color: var(--qrencia-blue-background);
  padding: 4% 7%;
  display: flex;
  border-radius: 8px;
}
.expertVideoSection {
  padding: 0% 1%;
}

@media (max-width: 990px) {
  .qrenciaForExpertsSection {
    display: block;
  }
}
@media (min-width: 540px) and (max-width: 1290px) {
  .section {
    width: 95%;
  }
  .messageSection {
    width: 95%;
  }
  .expertsImageContainer {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .successfulExpertsContainer {
    padding: 0px 12px;
  }
}
@media (max-width: 900px) {
  .expertVideoSection {
    padding: 0% 1.5%;
  }
}
@media (max-width: 540px) {
  .section {
    width: 92%;
  }
  .messageSection {
    width: 92%;
  }
}
@media (max-width: 480px) {
  .qrenciaForExpertsSection {
    padding: 8% 7% 2% 7%;
  }
}
