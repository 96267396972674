.signUpFormSection {
  background-color: var(--qrencia-white);
  padding: 0.9em 2.4em;
  /* border-radius: 7px; */
  /* box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 33%); */
  /* margin-left: -5px; */
  font-size: 14.3px;
  min-height: 48rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.formHeading {
  color: var(--qrencia-dark-blue);
  font-size: 1.5em;
  font-weight: 600;
  margin-top: 0.5%;
  margin-bottom: 4%;
  /* width: 90%; */
  text-align: center;
}
.qrenciaLogoSection {
  padding-left: auto;
  padding-right: auto;
  /* margin-top: -5em; */
  margin: 1em auto 0.9em auto;
}
.qrenciaLogoSection a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
}
.formSection {
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  max-width: 34em !important;
}
.qrenciaLogo {
  height: 7em;
  object-fit: contain;
}
.nameSection {
  display: flex;
  gap: 1em;
}
.nameField {
  width: 50%;
}

.formSubHeading {
  color: var(--card-grey-title);
  font-size: 1em;
  font-weight: 400 !important;
  margin-top: 0.5%;
  margin-bottom: 2.5%;
}

.iButton {
  padding: 2px;
  background-color: var(--qrencia-dark-blue);
  border-radius: 50%;
  color: var(--qrencia-white);
  font-size: 0.9em;
  margin: 0 0 2px 7px;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-size: 0.9em;
  width: 22em;
  z-index: 10;
}

.inputLabel {
  font-size: 0.85em !important;
}

.formInputBox {
  margin-bottom: 0.5em;
  width: 100%;
  padding: 7px 6px 7px 6px !important;
  /* background-color: var(--qrencia-white); */
}

.showHidePasswordIcon {
  text-align: right;
  position: absolute;
  right: 9px;
}

.showHidePasswordIcon svg {
  margin-top: -5em;
  cursor: pointer;
}

.signupButton {
  width: 100%;
  font-weight: 400 !important;
  font-size: 1em;
  padding: 6px 8px;
  margin: 0.6em 0;
}

.tncTextContainer {
  margin-top: auto;
  width: 100%;
  text-align: center;
}

.tncText {
  color: var(--card-grey-title) !important;
  font-size: 0.85em !important;
  font-weight: 400 !important;
}

.tncTextHyper {
  text-decoration: none !important;
}

.loginTextContainer {
  text-align: center;
  padding: 5px 10px 0 10px;
}

.unitInput {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 0.5em;
  margin-top: -10px;
}

.unitInputBox {
  margin-bottom: 0;
  width: 100%;
  border: solid 1px var(--qrencia-select-box-border);
  background: var(--qrencia-select-box-background);
}

.mobileLabel {
  z-index: 1;
  font-weight: 500;
  background-color: var(--qrencia-white);
  margin-left: 6px;
  padding: 0em 0.3em;
  position: relative;
}

.unitInputPrepend {
  color: var(--qrencia-text-black);
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  flex-grow: 0;
  font-weight: 400;
  font-size: 0.8em;
  padding: 0.57em;
  /* height: 2.8em; */
}

.unitInputPrepend {
  border-right-width: 1px;
}

.displayNone {
  display: none;
}

.errorMessage {
  font-size: 0.8em;
  color: red;
}

.errorMessageText {
  color: red;
  font-weight: 400;
  font-size: 0.8em;
  background-color: rgb(255, 224, 224);
  border: 1px solid red;
  border-radius: 3px;
  text-align: center;
  padding: 2px;
  width: 100%;
  margin-bottom: 6px;
}

.successMessageText {
  color: green;
  font-weight: 400;
  font-size: 0.8em;
  background-color: rgb(225, 255, 225);
  border: 1px solid green;
  border-radius: 3px;
  text-align: center;
  padding: 2px;
  width: 100%;
  margin-bottom: 6px;
}

.successScreenContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 20%);
  border-radius: 7px;
  background-color: var(--qrencia-white);
  padding: 2em 2em;
}

.loaderContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 20%);
  border-radius: 7px;
  background-color: var(--qrencia-white);
  padding: 2em 2em;
}
.loaderContainer > div {
  margin: auto 1em;
}
.loginFormContainer {
  width: 100%;
  margin-top: 1em;
}
.signupSuccessSalutation {
  font-size: 1.8em;
  margin-top: 1%;
  margin-bottom: 2%;
  color: var(--qrencia-text-black);
  text-align: center;
}
.signupSuccessSalutation > svg {
  color: var(--qrencia-error-red);
  font-size: 1.2em;
}
.signupSuccessTitle {
  font-size: 1.4em;
  margin-top: 1%;
  color: var(--qrencia-text-black);
  text-align: center;
}

.signupSuccessSubHeading {
  font-size: 1.1em;
  margin-top: 1%;
  color: var(--qrencia-text-black);
  margin-bottom: 1%;
  text-align: center;
}

.signupSuccessSubtitle {
  margin-top: 4%;
  margin-bottom: 1%;
  font-size: 1em !important;
  text-align: center;
}

.signupUsername {
  color: var(--qrencia-dark-blue);
  font-weight: 800;
}

.spinner {
  animation: spin-animation 0.5s infinite;
}

.positionRelative {
  position: relative;
}

.verifyOtpText {
  position: absolute;
  right: 6px;
  margin-top: -32px;
  cursor: pointer;
}

.verifyOtpText p {
  font-weight: 500 !important;
  font-size: 0.8em;
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-select-box-background);
  padding: 0 3px;
}

.verifyEmailContainer {
  padding: 12px 8px;
  background: var(--qrencia-white);
  border: solid 1px var(--qrencia-select-box-border);
  background-color: var(--qrencia-select-box-background);
  margin-top: -18px;
}

.emailContainer {
  display: flex;
  justify-content: space-between;
}

.emailDisabledText {
  font-size: 0.85em;
  font-weight: 500 !important;
  color: var(--qrencia-grey-text);
  word-break: break-word;
  padding-right: 5px;
}

.editEmailText {
  font-size: 0.82em;
  font-weight: 500 !important;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.editEmailText svg {
  margin-top: 2px;
  margin-right: 2px;
}

.otpInfoText {
  font-size: 0.8em;
  font-weight: 400 !important;
}

.emailOtpInputContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.emailOtpInputBox {
  width: max-content !important;
}

.emailOtpInputBox input {
  margin-right: 10px !important;
  margin: 8px 0;
  background-color: var(--qrencia-white);
}

.resendOtpButton {
  background-color: var(--qrencia-select-box-background);
  outline: none;
  text-decoration: none;
  border: none;
  font-size: 0.8em;
  font-weight: 500;
  padding: 4px 0;
  width: 7em;
  text-align: center;
  cursor: pointer;
}
@media (min-height: 1365px) and (max-height: 1367px) and (min-width: 1023px) and (max-width: 1025px) {
  .signUpFormSection {
    max-height: 50em !important;
    min-height: 50em;
    font-size: 12px !important;
  }
}
@media (min-height: 767px) and (max-height: 768px) and (min-width: 1023px) and (max-width: 1025px) {
  .signUpFormSection {
    max-height: 53em !important;
    min-height: 53em;
    font-size: 12px !important;
  }
}
@media (min-width: 1279px) and (max-width: 1281px) and (min-height: 1023px) and (max-height: 1025px) {
  .signUpFormSection {
    max-height: 55em !important;
    min-height: 55em;
    font-size: 14px !important;
  }
}
@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@media (max-width: 1290px) {
  .tncText {
    color: var(--card-grey-title) !important;
    font-size: 0.9em !important;
    font-weight: 400 !important;
  }
}

@media (min-width: 1440px) and (max-width: 2500px) {
  .signUpFormSection {
    padding: 1.2em 2.4em;
  }

  .inputLabel {
    font-size: 0.95em;
  }

  .tncText {
    font-size: 0.85em;
  }
}

@media (min-width: 1185.5px) and (max-width: 1270.5px) {
  .signupSuccessSalutation {
    font-size: 1.5em;
  }

  .signupSuccessTitle {
    font-size: 1.2em;
  }

  .signupSuccessSubHeading {
    font-size: 0.9em;
  }
}

@media (min-width: 1028.5px) and (max-width: 1185px) {
  .signupSuccessSalutation {
    font-size: 1.5em;
  }

  .signupSuccessTitle {
    font-size: 1.1em;
  }

  .signupSuccessSubHeading {
    font-size: 0.9em;
  }

  .tncText {
    font-size: 0.85em !important;
  }
}

@media (min-width: 899px) and (max-width: 1028px) {
  .signupSuccessSalutation {
    font-size: 1.45em;
  }

  .signupSuccessTitle {
    font-size: 1em;
  }

  .signupSuccessSubHeading {
    font-size: 0.8em;
  }

  .tncText {
    font-size: 0.8em !important;
  }
}

@media (min-width: 768px) and (max-width: 899px) {
  .signUpFormSection {
    padding: 1.3rem 2rem;
  }

  .formInputBox {
    width: 100%;
  }

  .unitInput {
    width: 100%;
  }

  .signupButton {
    width: 100%;
  }

  .tncTextContainer {
    width: 100%;
  }

  .errorMessageText {
    width: 100%;
  }

  .successMessageText {
    width: 100%;
  }

  .signupSuccessSalutation {
    font-size: 1.1em;
  }

  .signupSuccessTitle {
    font-size: 0.8em;
  }

  .signupSuccessSubHeading {
    font-size: 0.72em;
  }

  .tncText {
    font-size: 0.7em !important;
  }

  .successScreenContainer {
    padding: 2em 1em;
  }
}
@media (min-height: 1023px) and (max-height: 1025px) and (min-width: 767px) and (max-width: 769px) {
  .signUpFormSection {
    max-height: 55em;
  }
}
@media (max-width: 767.5px) {
  .signupSuccessSalutation {
    font-size: 1.2em;
  }

  .signupSuccessTitle {
    font-size: 0.9em;
  }

  .signupSuccessSubHeading {
    font-size: 0.78em;
  }

  .tncText {
    font-size: 0.77em !important;
  }
  .formSection {
    width: 100%;
  }
  .qrenciaLogo {
    height: 6em;
  }
}

@media (max-width: 480px) {
  .signupSuccessSalutation {
    font-size: 1.2em;
  }

  .signupSuccessTitle {
    font-size: 1em;
  }

  .signupSuccessSubHeading {
    font-size: 0.8em;
  }
}

@media (min-width: 559px) and (max-width: 767.5px) {
  .signUpFormSection {
    padding: 1em 2.5rem;
    margin: 0;
  }

  .formInputBox {
    width: 100%;
  }

  .unitInput {
    width: 100%;
  }

  .signupButton {
    width: 100%;
  }

  .tncTextContainer {
    width: 100%;
  }

  .errorMessageText {
    width: 100%;
  }

  .successMessageText {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 560px) {
  .signUpFormSection {
    padding: 1.2rem 1.5rem;
    margin: 0;
  }

  .formInputBox {
    width: 100%;
  }

  .unitInput {
    width: 100%;
  }

  .signupButton {
    width: 100%;
  }

  .tncTextContainer {
    width: 100%;
  }

  .errorMessageText {
    width: 100%;
  }

  .successMessageText {
    width: 100%;
  }

  .verifyOtpText {
    margin-top: -31px;
  }

  .verifyOtpText p {
    font-size: 0.72em;
  }
}

@media (max-width: 991.5px) {
  .signUpFormSection {
    height: auto;
    border-radius: 7px;
    box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 20%);
  }
}

@media (max-width: 375.5px) {
  .emailOtpInputBox input {
    margin-right: 6px !important;
    width: 28px !important;
    height: 28px !important;
  }
}

@media (max-width: 580px) {
  .nameSection {
    display: block;
  }
  .nameField {
    width: 100%;
  }
}
