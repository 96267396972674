.skillList {
    background: var(--qrencia-white);
    padding: 0.5% 7% 4% 7%;
}

.skillThreeColumn {
    display: flex;
    justify-content: space-between;
    color: var(--qrencia-text-black);
}

.skillThreeColumn>div {
    padding: 0.6em 0.2em;
}

.skillThreeColumn h4 {
    font-size: 1em;
    padding: 0.6em;
    text-align: center;
    border-top-right-radius: 0.3em;
    border-top-left-radius: 0.3em;
}

.skillThreeColumn ul {
    background: var(--qrencia-extended-page-light-blue-background);
    list-style: none;
    margin: 0.4em auto;
    padding-left: 0.8rem;
}

.interestSection h4 {
    background: var(--qrencia-carousel-green-card-background);
    color: var(--qrencia-white);
    padding: 0.7em;
}

.requiredSection h4 {
    background: var(--qrencia-orange-header);
    color: var(--qrencia-white);
    padding: 0.7em;
}

.useSection h4 {
    background: var(--qrencia-skill-put-to-use-header);
    color: var(--qrencia-white);
    padding: 0.7em;
}

.heading {
    font-size: 1.6em;
    font-weight: 700 !important;
    padding: 2% 1% 2% 1%;
    text-align: center;
}

.whereCanYouPutIcon {
    color: var(--qrencia-skill-put-to-use-header);
    font-size: 1.4em;
    vertical-align: sub;
    margin-right: 2%;
}

.couldBeForYouIcon {
    color: var(--qrencia-carousel-green-card-background);
    font-size: 1.45em;
    vertical-align: sub;
    margin-right: 1.5%;
}

.skillsRequiredToBeGoodInIcon {
    color: var(--qrencia-orange-header);
    font-size: 1.6em;
    vertical-align: sub;
    margin-right: 2%;
}


.listBackground {
    background: var(--qrencia-extended-page-light-blue-background);
    padding: 1%;
    font-size: 0.9em;
    min-height: 13em;
    border-bottom-right-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    vertical-align: center;
    line-height: 2.5em;
}

.listBackground li:active {
    border-bottom: 0em;
    cursor: none;
}

@media(min-width:3000px) {
    .skillThreeColumn h4 {
        font-size: 1.8em;
        padding: 0.8em;
    }

    .heading {
        font-size: 2.5em;
    }


    .skillThreeColumn ul {
        font-size: 1.6em;
    }

    .skillThreeColumn>div {
        width: 30%;
    }
}

@media(min-width:2600px) and (max-width:3000px) {
    .skillThreeColumn {
        display: flex;
        justify-content: space-between;
        color: var(--qrencia-text-black);
    }

    .heading {
        font-size: 2.4em;
    }

    .skillList {
        background: var(--qrencia-white);
        padding: 1% 7% 4% 7%;
    }

    .skillThreeColumn h4 {
        font-size: 1.6em;
        padding: 0.8em;
    }

    .skillThreeColumn ul {
        font-size: 1.5em;
    }

    .skillThreeColumn>div {
        width: 30%;
    }
}

@media(min-width:2000px) and (max-width:2600px) {
    .whereCanYouPutIcon {
        font-size: 1.3em;
        vertical-align: sub;
        margin-right: 2%;
    }

    .heading {
        font-size: 2.2em;
    }

    .couldBeForYouIcon {
        font-size: 1.35em;
        vertical-align: sub;
        margin-right: 1.5%;
    }

    .skillsRequiredToBeGoodInIcon {
        font-size: 1.5em;
        vertical-align: sub;
        margin-right: 2%;
    }

    .interestSection h4 {
        color: var(--qrencia-white);
        padding: 1em;
    }

    .requiredSection h4 {
        color: var(--qrencia-white);
        padding: 1em;
    }

    .useSection h4 {
        color: var(--qrencia-white);
        padding: 1em;
    }

    .listBackground {
        background: var(--qrencia-extended-page-light-blue-background);
        padding: 1%;
        font-size: 1em;
        min-height: 13em;
        border-bottom-right-radius: 0.3em;
        border-bottom-left-radius: 0.3em;
        vertical-align: center;
        line-height: 3em;
    }

    .listBackground li:active {
        border-bottom: 0em;
        cursor: none;
    }

    .skillThreeColumn h4 {
        font-size: 1.7em;
        padding: 1em;
    }

    .skillThreeColumn ul {
        font-size: 1.7em;
    }

    .skillThreeColumn>div {
        width: 30%;
    }
}

@media(min-width:1701px) and (max-width:1999px) {
    .skillThreeColumn h4 {
        font-size: 1.4em;
        padding: 0.7em;
    }

    .heading {
        font-size: 1.8em;
    }

    .skillThreeColumn ul {
        font-size: 1.2em;
    }

    .skillThreeColumn>div {
        width: 33%;
    }

}

@media(min-width:1025px) and (max-width:1700px) {

    .skillThreeColumn>div {
        margin: 0em 0.8em;
        padding: 0em;
        width: 33%;
    }

}

@media(min-width:769px) and (max-width:1024px) {

    .skillThreeColumn {
        display: flex;
        background: var(--qrencia-white);
        justify-content: space-between;
        color: var(--qrencia-text-black);

    }

    .skillThreeColumn ul {
        font-size: 0.75em;
    }

    .skillThreeColumn h4 {
        font-size: 0.8em;
        padding-left: 0.6em;
        padding-right: 0.6em;
    }

    .listBackground {
        background: var(--qrencia-extended-page-light-blue-background);
        padding-left: 0em;
        font-size: 0.9em;

    }

    .skillThreeColumn>div {
        margin: 0em 0.8em;
        padding: 0em;
        width: 33%;
    }

    .whereCanYouPutIcon {
        color: var(--qrencia-skill-put-to-use-header);
        font-size: 1.4em;
        vertical-align: sub;
        margin-right: 2%;
    }

    .couldBeForYouIcon {
        color: var(--qrencia-skill-page-could-be-header);
        font-size: 1.45em;
        vertical-align: sub;
        margin-right: 1.5%;
    }

    .skillsRequiredToBeGoodInIcon {
        color: var(--qrencia-skill-required-header);
        font-size: 1.6em;
        vertical-align: sub;
        margin-right: 2%;
    }
}

@media(min-width:884px) and (max-width:1024px) {
    .skillThreeColumn h4 {
        font-size: 0.88em;
    }

    .skillThreeColumn ul {
        font-size: 0.9em;
    }

    .skillThreeColumn>div {
        margin: 0em 0.78em;
        padding: 0em;
        width: 33%;
    }

}

@media(min-width:768px) and (max-width:991px) {
    .heading {
        font-size: 1.3em;
    }
    .skillList {
        padding: 1.5% 7% 4% 7%;
    }
}

@media(max-width:768px) {
    .skillThreeColumn {
        display: block;
        background: var(--qrencia-white);
        justify-content: space-around;
        color: var(--qrencia-text-black);
    }

}

@media(max-width:560px) {
    .skillThreeColumn {
        display: block;
        background: var(--qrencia-white);
        justify-content: space-around;
        color: var(--qrencia-text-black);
    }

    .skillThreeColumn h4 {
        font-size: 0.8em;
        padding: 0.7em;
    }

    .skillThreeColumn ul {
        font-size: 0.8em;
    }
}

@media(min-width:780px) and (max-width:993px) {

    .skillList {
        padding: 1% 7% 4% 7%;
    }
}

@media(max-width:767px) {
    .skillList {
        padding: 1.5% 7% 6% 7%;
    }

    .heading {
        font-size: 1.1em;
            padding: 2% 1% 1% 1%;
    }
}
@media(max-width:480px) {
    .skillList {
        padding: 4% 7% 6% 7%;
    }
}