.heading {
  font-size: 1rem;
  font-weight: 600;
  color: var(--qrencia-text-black);
  margin-bottom: 1rem;
}

:global(.ag-theme-alpine .ag-cell .ag-cell-value) {
  font-weight: normal !important;
  color: black !important;
  font-size: 1em !important;
}

.exportButton {
  font-size: 0.8em;
}

.marksIcon:hover {
  background: none !important;
  color: var(--qrencia-black) !important;
}

.toggleBtn {
  background-color: none;
}
.toggleBtn:hover {
  background: none !important;
  color: var(--qrencia-black) !important;
}

.editButton {
  font-size: 1.6em;
  cursor: pointer;
  color: var(--qrencia-text-black);
}

.buttonTransition {
  transition: all 0.5s ease-in-out;
}

.viewAndEditGridSpan {
  color: var(--qrencia-text-black) !important;
  font-weight: normal !important;
  font-size: 1em !important;
}

.backBtn {
  font-size: 2.5em;
  color: var(--qrencia-dark-blue);
  position: absolute;
  top: 5px;
  cursor: pointer;
  left: 12px;
}

.editSection {
  background-color: var(--qrencia-white);
  padding: 3em 0 1em 0;
  border-radius: 8px;
  margin-bottom: 1em;
  position: relative;
}

.exportButton {
  font-size: 0.8em;
}

.tabContainer {
  width: 100%;
  margin: 0em auto;
  background-color: var(--qrencia-white);
  padding: 1em;
  border-radius: 10px;
  height: fit-content;
  margin-bottom: 2em;
  box-shadow: var(--qrencia-template-box-shadow);
}

.buttonColumn {
  display: flex;
  justify-content: space-evenly;
  color: var(--qrencia-black);
  /* margin-top: 0.8em; */
}

.viewConatiner {
  padding: 1em;
  background-color: var(--qrencia-white);
  border-radius: 0.8em;
}

.gridIconMargin {
  margin-right: 1.3em;
}
.reportIcon {
  color: var(--qrencia-text-black) !important;
}
.reportIcon:focus {
  background: none !important;
  color: var(--qrencia-text-black) !important;
}
.reportIcon:hover {
  background: none !important;
  color: var(--qrencia-text-black) !important;
}

.gridIcon {
  font-size: 1.5em !important;
  padding: 0% !important;
  margin-top: -0.34em !important;
  background: none !important;
  border: none !important;
  color: var(--qrencia-text-black);
}

.viewConatiner > .ag-theme-alpine .ag-cell,
.viewConatiner > .ag-theme-alpine .ag-header-cell {
  border: none;
}

.viewConatiner > .ag-theme-alpine .ag-row {
  border-bottom: none;
}

.columnContainer {
  margin-top: 1em;
}

.detailsLabel {
  color: var(--qrencia-dark-blue);
  font-size: 0.8em;
  font-weight: 500 !important;
}

.detailsText {
  font-size: 0.8em;
  width: 300px;
}

.descriptionText {
  font-size: 0.8em;
}

.expertEmailText {
  font-size: 0.8em;
  max-width: 500px;
  word-break: break-all !important;
}

.emailColumn {
  word-break: break-all !important;
}

.detailsText > ul {
  margin-bottom: 0;
}

.editButtonRow {
  margin-top: 0.4em;
  text-align: right;
}

@media (max-width: 561.5px) {
  .viewConatiner {
    padding: 0.5em 0.5em 1.4em 0.5em;
    background-color: var(--qrencia-white);
    border-radius: 0.8em;
  }
}

.backButton {
  font-size: 1.5em;
  color: var(--qrencia-white);
  background-color: var(--qrencia-dark-blue);
  border-radius: 1em;
  cursor: pointer;
}

.sectionHeading {
  font-size: 1em;
  color: var(--qrencia-black);
}

@media (max-width: 1440px) {
  .tabContainer {
    padding: 0.8em;
  }
}

@media (max-width: 990px) {
  .tabContainer {
    padding: 0.7em;
  }
}

@media (max-width: 767px) {
  .tabContainer {
    padding: 0.7em;
  }
}

@media (max-width: 540px) {
  .tabContainer {
    padding: 0.6em;
  }
}

@media (max-width: 900px) {
  :global(.ag-theme-alpine .ag-cell .ag-cell-value) {
    font-weight: normal !important;
    color: black !important;
    font-size: 0.9em !important;
  }
}

@media (max-width: 576px) {
  :global(.ag-theme-alpine .ag-cell .ag-cell-value) {
    font-weight: normal !important;
    color: black !important;
    font-size: 0.8em !important;
  }
}

@media (max-width: 400px) {
  :global(.ag-theme-alpine .ag-cell .ag-cell-value) {
    font-weight: normal !important;
    color: black !important;
    font-size: 0.7em !important;
  }
}
