.copyLearningActivityInputContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-top: 1.2em; */
  margin-bottom: 0.8em;
}
.inputBox {
  min-width: 60%;
}
.copyButton {
  margin-left: 2em;
  font-size: 0.8em;
  margin-top: 0.85em;
  height: 2.8em;
}
.modalYesButton,
.modalYesButton:hover,
.modalYesButton:active,
.modalYesButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--qrencia-success-green) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalNoButton,
.modalNoButton:hover,
.modalNoButton:active,
.modalNoButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--cancel-red-button) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}
.switchContainer {
  margin-top: 1em;
  margin-bottom: 0.8em;
  margin-left: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.switchText {
  margin-right: 1em;
  font-size: 0.88em;
  font-weight: 450;
  width: fit-content;
  display: flex;
  align-items: center;
}
.switchText input {
  margin-left: 0.8em;
  margin-top: 0.4em;
}
.switchContainer > label {
  line-height: 1em;
}
.infoButton {
  font-size: 1em;
  margin-top: 0.1em;
  margin-left: 0.2em;
  color: var(--qrencia-dark-blue) !important;
}
.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  z-index: 10;
  max-width: 32em;
}

@media (min-width: 768px) and (max-width: 990px) {
  .switch {
    width: 1.4em;
    height: 0.8em;
  }
  .switchContainer {
    margin-top: 1em;
    margin-left: 1em;
  }
  .switchText {
    margin-right: 0.6em;
    font-size: 0.8em;
  }
  .inputBox {
    min-width: 70%;
  }
}

@media (max-width: 540px) {
  .switchContainer {
    margin-top: 1em;
    margin-left: 1em;
  }
  .switchText {
    margin-right: 0.5em;
    font-size: 0.8em;
    margin-left: -0.8em;
  }
  .inputBox {
    font-size: 0.9em;
    min-width: 70%;
  }
  .switch {
    width: 1.6em;
    height: 0.8em;
  }
  .copyButton {
    margin-left: 2em;
    font-size: 0.8em;
    margin-top: 0.85em;
  }
}
@media (max-width: 440px) {
  .inputBox {
    font-size: 0.9em;
    min-width: 80%;
  }
  .copyButton {
    margin-left: 1.5em;
    font-size: 0.75em;
    margin-top: 0.85em;
  }
}
