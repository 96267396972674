.searchInputBox {
  width: 40%;
  border-radius: 4px;
}
.manageCoursesHeading {
  font-size: 1em;
  font-weight: 650;
  color: var(--qrencia-text-black);
  margin: 0.5em 0.5em 1em 0.5em;
}
.resetSearchIcon {
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 400;
  margin-left: -1.2em;
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-select-box-background);
}
.inputBoxContainer {
  padding: 0.8em;
  margin-bottom: 1em;
  margin-top: 0.4em;
}
.viewCourseDetailsSection {
  background-color: var(--qrencia-white);
  padding: 1em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-cards-border);
  margin-bottom: 1em;
}
