.suggestedSearchContainer {
  background-color: var(--qrencia-white);
  width: 100%;
  padding: 1em;
  height: 5em;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  border-radius: 5px;
}

.suggestedSearchContainerInput {
  flex: 0.7;
  margin-right: 1em;
}

.suggestedSearchContainer > button {
  font-size: 0.8em;
  padding: 0.5em 1em;
}

#suggestedSearch.rbt-menu.dropdown-menu {
  inset: 10px auto auto -31px !important;
  margin-left: 2.2em;
  font-size: 0.9em;
  padding: 0;
  border-radius: 0;
  border: solid 1px var(--qrencia-select-box-border);
}

#suggestedSearch .dropdown-item:focus,
#suggestedSearch .dropdown-item:hover,
#suggestedSearch .dropdown-item:active,
#suggestedSearch .dropdown-item:visited {
  background: var(--qrencia-select-box-background) !important;
}

.mailFormEditor .ql-container {
  font-size: 0.9em;
  min-height: 20em !important;
}

.suggestedSearchContainer .suggestedSearchLabel {
  font-weight: 500;
  font-size: 0.8em;
  background-color: var(--qrencia-white);
  margin-left: 3px;
  padding: 0em 0.3em;
  z-index: 1;
  background-image: linear-gradient(
    to bottom,
    var(--qrencia-white),
    var(--qrencia-select-box-background)
  );
  position: relative;
}

.suggestedSearchContainer .SuggestedSearchInputBox {
  margin-top: -10px;
}

.suggestedSearchContainer .comppossButton {
  margin-bottom: 2px;
}

@media (max-width: 480px) {
  .suggestedSearchContainerInput {
    font-size: 0.8em;
    flex: 0.7;
    margin-right: 1em;
  }

  .suggestedSearchContainer > button {
    font-size: 0.7em;
    padding: 0.5em 1em;
  }
}
