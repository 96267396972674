.learnerIntroductionContainer {
  width: 100%;
  display: flex;
  gap: 1%;
}

.headingSection {
  display: flex;
}

.errorMessage {
  color: var(--qrencia-error-message);
  font-size: 0.9em;
  margin-top: 1%;
}

.editButton {
  height: 2.5em !important;
  font-size: 0.8em !important;
  padding: 0 1.5em !important;
}

.introductionTextArea {
  height: 34vh;
  overflow-y: auto;
}

.heading {
  width: 60%;
}

.location {
  width: 40%;
  text-align: end;
  margin-top: -0.2em;
}

.learnerIntroductionBox {
  flex: 1;
  background-color: var(--qrencia-white);
  border-radius: 1em;
  margin-bottom: 1%;
  padding: 2% 2.5% 2% 2.5%;
}

.learnerSkillsProwessSection {
  flex: 1;
  background-color: var(--qrencia-white);
  border-radius: 1em;
  margin-bottom: 1%;
  padding: 2% 2.5% 2% 2.5%;
}

.learnerIntroductionBox h2 {
  color: var(--qrencia-text-black);
  font-weight: 700;
  font-size: 1.4em;
}

.learnerIntroductionBox p,
.learnerIntroductionBox textarea {
  font-size: 1em;
  margin-top: 0.3em;
}

.editIconSection {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.4em;
}

.learnerIntroductionBox textarea {
  height: -webkit-fill-available;
}

.editIconSection {
  text-align: end;
}

.learnerSkillsProwessSection h2 {
  color: var(--qrencia-text-black);
  font-weight: 700;
  font-size: 1.4em;
}

.learnerSkillsProwessSection span {
  font-size: 1rem;
  font-weight: 200;
  color: var(--qrencia-dark-blue);
}

.greenTickIcon {
  color: var(--qrencia-silver-medal-color);
  font-size: 1.2em;
  cursor: pointer;
}

.iconText {
  font-size: 1em;
  cursor: pointer;
  display: inline-block;
  color: var(--card-grey-title);
}

.introductionText {
  margin-right: 1%;
}

.locationIcon {
  font-size: 1.5em !important;
  margin-top: -0.2em;
}

.learnerProfileLocation {
  font-size: 0.7rem;
  color: var(--card-grey-title);
  font-weight: 500 !important;
}

.skillsListContainer {
  height: 34vh;
  overflow-y: auto;
}

.learnerSkillSection {
  margin-bottom: 1.2em;
  margin-right: 1%;
}

.learnerProgressBar {
  height: 0.6em;
  background-color: var(--qrencia-dark-blue) !important;
  margin-top: 0.8em;
}

.learnerSkillProwess {
  font-size: 0.9em;
  font-weight: 500 !important;
}

.IntroductionEditTextArea {
  border-radius: 1em;
  background: var(--qrencia-select-box-background);
  border: 0.5em solid var(--qrencia-select-box-border);
  font-size: 0.8em !important;
  height: 8em;
  margin-top: -18px;
  text-align: left !important;
}

@media (max-width: 1290px) {
  .learnerSkillsProwessSection h2 {
    font-size: 1.2em;
  }

  .learnerIntroductionBox h2 {
    font-size: 1.2em;
  }
}

@media (max-width: 767.8px) {
  .learnerIntroductionContainer {
    flex-direction: column;
    gap: 0px;
    /* margin-bottom: 3%; */
  }

  .heading {
    width: 50%;
  }

  .location {
    width: 50%;
    text-align: end;
  }

  .learnerIntroductionBox {
    width: 100%;
    border-radius: 1em;
    margin-bottom: 3%;
    padding: 2% 2.5% 2% 2.5%;
  }

  .learnerSkillsProwessSection {
    width: 100%;
    border-radius: 1em;
    margin-bottom: 1%;
    padding: 2% 2.5% 2% 2%;
  }

  .learnerIntroductionBox p,
  .learnerIntroductionBox textarea {
    font-size: 0.9em;
    margin-top: 0.3em;
    height: 200px;
  }

  .learnerSkillsProwessSection h2 {
    font-size: 1.1em;
  }

  .learnerIntroductionBox h2 {
    font-size: 1.1em;
  }

  .skillsListContainer {
    height: auto;
    overflow-y: auto;
  }

  .introductionTextArea {
    height: auto;
    overflow-y: auto;
  }
}

@media (max-width: 437px) {
  .learnerIntroductionBox {
    padding: 4% 2.5% 3% 2.5%;
  }

  .learnerSkillsProwessSection {
    padding: 4% 2.5% 3% 2.5%;
  }
}

@media (max-width: 992px) {
  .editButton {
    font-size: 0.75em !important;
  }
}

@media (max-width: 900px) {
  .editButton {
    height: 2.3em !important;
    font-size: 0.75em !important;
  }
}

@media (max-width: 438px) {
  .editButton {
    font-size: 0.65em !important;
  }
}
