.gridIcon {
  font-size: 0.9em !important;
  padding: 0% 4% 2.5% 4% !important;
  margin-top: -0.34em !important;
  margin-left: 5px;
}

.obrGreenText {
  color: var(--qrencia-success-green);
}

.paymentActionBox {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 2.2em;
  padding: 0 20%;
  text-align: center;
  justify-content: space-between;
}

.iconWrapper {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
}

.iconWrapper:hover {
  /* transform: scale(1.1); */
  /* color: #224b7f; */
}

.cleanIcon {
  font-size: 1.2rem;
  color: #292929;
  transition: transform 0.2s ease;
}

.reminderClockIcon {
  font-size: 1.1rem;
  color: #292929;
  transition: transform 0.2s ease;
}

.emailIcon:hover {
  color: rgb(190, 164, 76);
}

.learnerPassIcon:hover {
  color: rgb(216, 118, 118);
}

.reminderIcon:hover {
  color: rgb(216, 118, 118);
}

.obrBlueDarkText {
  color: var(--qrencia-blue-darker-contrast);
}

.obrOrangeColorText {
  color: var(--qrencia-orange-button);
}

.obrBackButton {
  font-size: 1.5em;
  color: var(--qrencia-white);
  background-color: var(--qrencia-dark-blue);
  border-radius: 1em;
  cursor: pointer;
}

.obrRedText {
  color: var(--qrencia-error-red);
}

.sectionContainer {
  padding: 1em;
  background-color: var(--qrencia-white);
  border-radius: 0.8em;
}

.exportButton {
  font-size: 0.8em;
}

.copySuccess {
  background-color: var(--qrencia-success-green);
  outline: 1px solid var(--qrencia-success-green);
  border: 1px solid var(--qrencia-success-green);
}

.copySuccess:hover {
  background-color: var(--qrencia-success-green) !important;
  outline: 1px solid var(--qrencia-success-green) !important;
  border: 1px solid var(--qrencia-success-green) !important;
}

.imageIcons {
  height: 12px;
  width: 12px;
  object-fit: cover;
}