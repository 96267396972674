.tabContainer {
    width: 100%;
    margin: 0em auto;
    background-color: var(--qrencia-white);
    padding: 1em;
    border-radius: 10px;
    height: fit-content;
    margin-bottom: 2em;
    box-shadow: var(--qrencia-template-box-shadow);
}

@media (max-width: 1440px) {
    .tabContainer {
        padding: 0.8em;
    }
}

@media (max-width: 990px) {
    .tabContainer {
        padding: 0.7em;
    }
}

@media (max-width: 767px) {
    .tabContainer {
        padding: 0.7em;
    }
}

@media (max-width: 540px) {
    .tabContainer {
        padding: 0.6em;
    }
}