.brandBuildingSection {
  position: relative;
}

.brandBuilding {
  padding-top: 2rem;
}

.featureCarousel {
  padding: 2.3rem 3rem 0 3rem;
  border-radius: 6px;
}

.pageHeading {
  font-size: 1.9rem;
  font-weight: 600;
  color: var(--qrencia-grey-text-color);
}

.brandContent {
  background-color: white;
  margin-bottom: 1.6rem;
  border-radius: 6px;
  padding: 0.4rem 0.4rem;
  overflow: auto;
}

.brandBuildingSection .carousel_item {
  position: relative;
  will-change: transform, opacity;
  overflow: hidden;
}

.brandDesciption {
  display: flex;
}

.brandContent h3 {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.1rem;
  color: var(--qrencia-grey-text-color);
  padding-top: 0.1rem;
}

.brandContent p {
  font-size: 0.7rem;
  font-weight: 300 !important;
  color: var(--qrencia-grey-text-color);
}

.featureIconImage {
  width: 100%;
  border-radius: 6px;
}

.brandImg {
  display: inline-block;
  float: left;
  border-radius: 6px;
  margin-right: 0.4rem;
  width: 9%;
}

.brandBuildingSection :global(.carousel-inner) {
  height: auto !important;
}

.brandBuildingSection :global(.carousel-indicators) {
  position: absolute;
  bottom: -180px;
  width: 20%;
  left: -210px;
  z-index: 2;
  display: flex;
  transform: none;
  list-style: none;
  gap: 0.1em;
}

.brandBuildingSection :global(.carousel-indicators [data-bs-target]) {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: darkgrey !important;
  border: 1px solid darkgrey !important;
}

.brandBuildingSection :global(.carousel-indicators .active) {
  background-color: var(--qrencia-grey-text-color) !important;
  border: 1px solid var(--qrencia-grey-text-color) !important;
}

.buildBrandImage {
  width: 100%;
}

.brandImg svg {
  font-size: 1.6rem;
}

@media (max-width: 1360px) {
  .brandBuildingSection :global(.carousel-indicators) {
    position: absolute;
    bottom: -180px;
    width: 20%;
    left: -180px;
    z-index: 2;
    display: flex;
    transform: none;
    list-style: none;
    gap: 0.1em;
  }
}

@media (max-width: 1030px) {
  .brandImg {
    width: 12%;
    margin-top: 0.2rem;
  }
}

@media (max-width: 1200px) {
  .brandImg {
    width: 10%;
  }
}

@media (max-width: 1100px) {
  .brandImg {
    width: 10%;
  }
}

@media (max-width: 1182px) {
  .brandBuildingSection :global(.carousel-indicators) {
    position: absolute;
    bottom: -190px;
    width: 20%;
    left: -165px;
    z-index: 2;
    display: flex;
    transform: none;
    list-style: none;
    gap: 0.1em;
  }
}

@media (max-width: 1133px) {
  .brandBuildingSection :global(.carousel-indicators) {
    position: absolute;
    bottom: -200px;
    width: 20%;
    left: -152px;
    z-index: 2;
    display: flex;
    transform: none;
    list-style: none;
    gap: 0.1em;
  }
}

@media (max-width: 1020px) {
  .featureCarousel {
    padding: 2.3rem 3rem 2rem 3rem;
  }

  .brandBuildingSection :global(.carousel-indicators) {
    left: -125px;
    bottom: -190px;
  }
}

@media (max-width: 1090px) {
  .buildBrandImage {
    position: absolute;
    bottom: 0;
    width: 48%;
  }
}

@media (max-width: 900px) {
  .brandImg {
    width: 12%;
  }
}

@media (max-width: 890px) {
  .brandBuildingSection :global(.carousel-indicators) {
    left: -95px;
    bottom: -190px;
  }
}

@media (max-width: 800px) {
  .brandImg {
    width: 15%;
  }
}

@media (max-width: 475px) {
  .pageHeading {
    font-size: 1.3rem !important;
  }

  .brandBuilding {
    padding-top: 1.2rem;
  }

  .buildBrandImage {
    width: 100% !important;
  }
}

/* @media (max-width: 614px) {
 
} */

@media (max-width: 684px) {
  .brandBuildingSection :global(.carousel-indicators) {
    position: absolute;
    bottom: -190px !important;
    width: 24%;
    left: 345px !important;
    z-index: 2;
    display: flex;
    transform: none;
    list-style: none;
    gap: 0.1em;
  }

  .pageHeading {
    font-size: 1.4rem !important;
  }
}
@media (max-width: 648px) {
  .pageHeading {
    font-size: 1.2rem !important;
  }
}

@media (max-width: 626px) {
  .brandBuildingSection :global(.carousel-indicators) {
    bottom: -190px !important;
    left: 320px !important;
  }

  .brandBuildingSection :global(.carousel-indicators [data-bs-target]) {
    width: 6px !important;
    height: 6px !important;
  }
}

@media (max-width: 600px) {
  .brandBuildingSection :global(.carousel-indicators) {
    bottom: -190px !important;
    left: 300px !important;
  }
}

@media (min-width: 767px) and (max-width: 814px) {
  .brandContent h3 {
    font-size: 0.8rem;
  }

  .pageHeading {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .brandBuildingSection :global(.carousel-indicators) {
    position: absolute;
    bottom: -190px;
    width: 24%;
    left: 365px;
    z-index: 2;
    display: flex;
    transform: none;
    list-style: none;
    gap: 0.1em;
  }

  .buildBrandImage {
    width: 60%;
    position: unset;
  }

  .brandBuildingSection {
    padding: 1rem 0rem 0 0rem !important;
  }

  .studentPassImageSection {
    text-align: center;
  }

  .featureCarousel {
    padding: 2.3rem 1rem 0 1rem;
    border-radius: 6px;
  }

  .brandBuildingSection :global(.carousel-indicators [data-bs-target]) {
    width: 7px;
    height: 7px;
  }

  .brandImg {
    width: 6.5%;
    margin-top: 0rem;
  }
}

@media (max-width: 768px) {
  .brandContent p {
    font-size: 0.7rem;
  }

  .brandContent h3 {
    font-size: 0.8rem;
  }

  .pageHeading {
    text-align: center;
    font-size: 1.7rem;
  }

  .brandBuildingSection {
    padding: 1rem 1.5rem;
  }
}

@media (max-width: 600px) {
  .brandContent {
    height: 52px;
  }
}

@media (max-width: 575px) {
  .pageHeading {
    font-size: 1.3rem;
  }

  .brandBuilding {
    padding-top: 1.2rem;
  }

  .buildBrandImage {
    width: 100% !important;
  }

  .brandImg {
    width: 9% !important;
    margin-top: 0rem !important;
  }

  .brandContent {
    min-height: 65px;
  }
}

@media (max-width: 540px) {
  .brandBuildingSection :global(.carousel-indicators) {
    bottom: -190px !important;
    left: 270px !important;
  }
}

@media (max-width: 513px) {
  .pageHeading {
    font-size: 1.1rem !important;
  }
}

@media (max-width: 500px) {
  .brandBuildingSection :global(.carousel-indicators) {
    bottom: -190px !important;
    left: 245px !important;
  }
}

@media (max-width: 480px) {
  .pageHeading {
    font-size: 0.9rem !important;
  }

  .brandContent {
    min-height: 82px;
  }
}

@media (max-width: 460px) {
  .brandContent p {
    font-size: 0.7rem;
  }

  .brandContent h3 {
    font-size: 0.8rem;
  }

  .brandImg {
    width: 15% !important;
    margin-top: 0.3rem !important;
  }

  .brandBuildingSection :global(.carousel-indicators) {
    bottom: -190px !important;
    left: 215px !important;
  }
}

@media (max-width: 422px) {
  .pageHeading {
    font-size: 0.9rem !important;
  }
  .brandContent {
    min-height: 82px !important;
  }

  .brandImg {
    width: 16% !important;
    margin-top: 0.3rem !important;
  }
}

@media (max-width: 416px) {
  .pageHeading {
    font-size: 0.8rem !important;
  }
}

@media (max-width: 400px) {
  .brandBuildingSection :global(.carousel-indicators) {
    bottom: -190px !important;
    left: 195px !important;
  }

  .pageHeading {
    font-size: 0.8rem !important;
  }
}

@media (max-width: 379px) {
  .featureCarousel {
    padding: 2.3rem 0.7rem 0 0.7rem;
    border-radius: 6px;
  }
  .pageHeading {
    font-size: 0.7rem !important;
  }
}
@media (max-width: 360px) {
  .brandContent {
    min-height: 88px !important;
  }

  .brandBuildingSection :global(.carousel-indicators) {
    bottom: -190px !important;
    left: 165px !important;
  }
}
