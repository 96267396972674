.learnerPassContainer {
  display: flex;
  /* Align items in a row */
  align-items: center;
  /* Vertically center items */
  gap: 1rem;
  /* Add spacing between Button and h4 */
}

.statusWrapper {
  border-radius: 10px;
  text-align: center;
  padding: 0 5px;
  margin-top: -2em;
  position: absolute;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  color: var(--qrencia-text-black);
  font-weight: bold;
  text-decoration: none;
  border: 2px solid transparent;
  z-index: 1;

}

.statusWrapper::before {
  content: "";
  position: absolute;
  inset: -2px;
  border-radius: 5px;
  background: var(--qrencia-error-message);
  background-size: 300%;
  z-index: -1;
}

.statusChipText {
  font-weight: 500;
  font-size: 0.6rem;
}

.statusWrapper::after {
  content: "";
  position: absolute;
  inset: 0;
  /* Matches button background */
  border-radius: 6px;
  z-index: -1;
}

.learnerPassButton {
  display: flex;
  align-items: center;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  font-size: 0.85em;
  width: max-content;
  cursor: pointer;
  margin-right: 1rem;
  font-weight: 500;
}

.numberOfDaysRemaining {
  margin: 0;
  font-size: 1rem;
  /* Adjust font size to match button */
  margin-right: 1rem;
}

.buttonIcon {
  font-size: 1.2em;
  margin-top: -1%;
  color: var(--qrencia-bright-golden);
}

@media (max-width: 1305px) {
  .learnerPassButton {
    padding: 0.2rem 0.5rem;
  }
}

@media (max-width: 1200px) {
  .numberOfDaysRemaining {
    font-size: 0.8rem;
  }

  .learnerPassButton {
    margin-right: 0;
  }
}

@media (max-width: 1180px) {
  .learnerPassButton {
    padding: 0.2rem 0.5rem;
  }
}

@media (max-width: 1152px) {
  .learnerPassButton {
    padding: 0.2rem 0.3rem;
  }
}

@media (max-width: 1133px) {
  .numberOfDaysRemaining {
    font-size: 0.9rem;
  }
}

@media (max-width: 1085px) {
  .numberOfDaysRemaining {
    font-size: 0.8rem;
  }
}

@media (max-width: 1045px) {
  .learnerPassButton {
    font-size: 0.7em;
  }

  .numberOfDaysRemaining {
    margin-right: 0.4rem;
  }
}

@media (max-width: 1050px) {
  .numberOfDaysRemaining {
    font-size: 0.8rem;
  }
}

@media (max-width: 690px) {
  .numberOfDaysRemaining {
    font-size: 0.7rem;
  }

  .statusChipText {
    font-weight: 500;
    font-size: 0.5rem;
  }

  .learnerPassButton {
    font-size: 0.6rem !important;
  }
}

@media (max-width: 1013px) {
  .learnerPassButton {
    padding: 0.2rem 0.3rem;
    font-size: 0.7rem;
    margin-right: 0;
  }
}

@media (max-width: 990px) {
  .learnerPassButton {
    padding: 0.6rem 0.3rem;
    font-size: 0.7rem;
    margin-right: 0;
  }
}

@media (max-width: 563px) {
  .learnerPassButton {
    padding: 0.6rem 0.6rem;
    font-size: 0.5rem !important;
    margin-right: 0;
  }
}

@media (max-width: 546px) {
  .numberOfDaysRemaining {
    font-size: 0.6rem;
  }
}

@media (max-width: 521px) {
  .list {
    gap: 0;
  }
}

@media (max-width: 505px) {
  .learnerPassContainer {
    gap: 0.5rem;
  }
}

@media (max-width: 489px) {
  .learnerPassContainer {
    gap: 0.5rem;
  }

  .learnerPassButton {
    padding: 0.6rem 0.3rem;
  }
}

@media (max-width: 473px) {
  .list {
    padding-left: 3%;
  }
}

@media (max-width: 460px) {
  .learnerPassButton {
    padding: 0.6rem 0.3rem;
  }
}

@media (max-width: 441px) {
  .LearnerPassButton_learnerPassContainer__WTBkM {
    gap: 0.2rem;
  }
}

@media (max-width: 436px) {
  .learnerPassButton {
    padding: 0.5rem 0.2rem;
  }
}

@media (max-width: 429px) {
  .numberOfDaysRemaining {
    font-size: 0.5rem;
  }
}

@media (max-width: 397px) {
  .learnerPassContainer {
    gap: 0.2rem;
  }
}

@media (max-width: 387px) {
  .numberOfDaysRemaining {
    margin-right: 0.2rem;
  }
}

@media (max-width: 365px) {
  .numberOfDaysRemaining {
    margin-right: 0.1rem;
  }
}

@media (max-width: 363px) {
  .learnerPassButton {
    padding: 0.6rem 0.1rem;
  }
}