.qrenciaLogoSection {
    width: 20%;
}

.navItemSection {
    width: 80%;
}


.exploreSkillsText {
    font-weight: 400;
    font-size: 1.3em;
}

.skillName {
    font-weight: 700;
    font-size: 2em;
}

.list {
    display: flex;
    gap: 1rem;
    text-decoration: none;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 2% 0%;
}

.listItem {
    width: 18%;
    text-align: end;
}


.loginButton {
    text-decoration: none;
    letter-spacing: 1px;
    color: var(--qrencia-text-black);
    background: var(--qrencia-white);
    border-radius: 4px;
    border: 1px solid var(--qrencia-text-black);
    font-size: 0.9em;
    font-weight: 500;
    padding: 5% 32%;
}

.signUpButton {
    text-decoration: none;
    letter-spacing: 1px;
    color: var(--qrencia-white);
    background: var(--qrencia-dark-blue);
    border-radius: 4px;
    border: 1px solid var(--qrencia-dark-blue);
    font-size: 0.9em;
    font-weight: 500;
    padding: 5% 32%;
}

.loginButton:hover {
    color: var(--qrencia-white);
    background: var(--qrencia-dark-blue);
    border: 1px solid var(--qrencia-dark-blue);
}

.signUpButton:hover {
    color: var(--qrencia-text-black);
    background: var(--qrencia-white);
    border: 1px solid var(--qrencia-text-black);
}

.ispBannerImgContainer {
    width: 100%;
    height: 38vh !important;
    object-fit: cover;
    display: block;

}

.ispTabletImgContainer {
    display: none;
}

.ispMobileImgContainer {
    display: none;
}

.ispBannerTextOverLay {
    margin-top: -10em;
    text-align: center;

}

.ispBannerImage {
    width: 100%;
    height: 38vh !important;
    object-fit: cover;
}

@media (min-width:2000px) {
    .exploreSkillsText {
        font-weight: 500;
        font-size: 2em;
    }

    .skillName {
        font-weight: 700;
        font-size: 2.4em;
    }

    .ispBannerImgContainer {
        display: block;
    }

    .ispTabletImgContainer {
        display: none;
    }

    .ispMobileImgContainer {
        display: none;
    }
}

@media (min-width:1520px) and (max-width:1999px) {
    .exploreSkillsText {
        font-weight: 400;
        font-size: 1.6em;
    }

    .skillName {
        font-weight: 700;
        font-size: 2em;
    }

    .ispBannerImgContainer {
        display: block;
    }

    .ispTabletImgContainer {
        display: none;
    }

    .ispMobileImgContainer {
        display: none;
    }

    .ispBannerImage {
        height: 38vh !important;
    }
}

@media(min-width:680px) and (max-width:991.5px) {
    .ispBannerImage {
        height: 38vh !important;
    }

    .ispBannerImgContainer {
        display: none;
    }

    .ispTabletImgContainer {
        display: block;
        height: 38vh !important;
    }

    .ispMobileImgContainer {
        display: none;
    }

}

@media(max-width:679px) {
    .ispBannerImgContainer {
        height: 38vh !important;
    }

}

@media(max-width:991.5px) {
    .ispBannerImgContainer {
        height: 38vh !important;
    }

    .exploreSkillsText {
        font-weight: 400;
        font-size: 1.1em;
    }

    .skillName {
        font-weight: 700;
        font-size: 1.5em;
    }
}

@media(max-width:876px) {
    .ispBannerImgContainer {
        display: none;
    }

    .ispTabletImgContainer {
        display: block;
    }

    .ispMobileImgContainer {
        display: none;
    }

}

@media(max-width:768px) {
    .ispBannerImgContainer {
        display: none;
    }

    .ispTabletImgContainer {
        display: block;
        width: 100%;
        height: 38vh !important;
        object-fit: cover;
    }

    .ispMobileImgContainer {
        display: none;
    }
}

@media (min-width:1144px) and (max-width:1290px) {
    .listItem {
        width: 18%;
    }

    .loginButton {
        font-size: 0.9em;
        padding: 5% 28%;
    }

    .signUpButton {
        font-size: 0.9em;
        padding: 5% 28%;
    }

    .ispBannerImgContainer {
        display: block;
    }

    .ispTabletImgContainer {
        display: none;
    }

    .ispMobileImgContainer {
        display: none;
    }
}

@media (min-width:1030px) and (max-width:1144px) {

    .listItem {
        width: 20%;
    }

    .loginButton {
        padding: 5% 28%;
    }

    .signUpButton {
        padding: 5% 28%;
    }

    .qrenciaLogo {
        width: 60%;
    }

    .ispBannerImgContainer {
        display: block;
    }

    .ispTabletImgContainer {
        display: none;
    }

    .ispMobileImgContainer {
        display: none;
    }
}

@media (min-width:900px) and (max-width:1030px) {
    .listItem {
        width: 22%;
    }

    .loginButton {
        padding: 5% 26%;
    }

    .signUpButton {
        padding: 5% 26%;
    }

    .qrenciaLogo {
        width: 65%;
    }
}

@media (min-width:665px) and (max-width:900px) {
    .listItem {
        width: 22%;
    }

    .loginButton {
        padding: 5% 22%;
        font-size: 0.8em;
    }

    .signUpButton {
        padding: 5% 22%;
        font-size: 0.8em;
    }

    .qrenciaLogo {
        width: 70%;
    }

    .ispBannerImgContainer {
        display: none;
    }

    .ispTabletImgContainer {
        display: block;
        width: 100%;
        height: 38vh !important;
        object-fit: cover;
    }

    .ispMobileImgContainer {
        display: none;
    }

}

@media (min-width:480px) and (max-width:665px) {
    .listItem {
        width: 23%;
    }

    .loginButton {
        padding: 5% 18%;
        font-size: 0.65em;
    }

    .signUpButton {
        padding: 5% 18%;
        font-size: 0.65em;
    }

    .qrenciaLogo {
        width: 75%;
    }

    .ispBannerImgContainer {
        display: none;
    }

    .ispTabletImgContainer {
        display: block;
        width: 100%;
        height: 38vh !important;
        object-fit: cover;
    }

    .ispMobileImgContainer {
        display: none;
    }
}

@media (max-width:480px) {
    .listItem {
        width: 30%;
    }

    .loginButton {
        padding: 5% 18%;
        font-size: 0.65em;
    }

    .signUpButton {
        padding: 5% 18%;
        font-size: 0.65em;
    }

    .qrenciaLogo {
        width: 100%;
    }

    .ispBannerImgContainer {
        display: none;
    }

    .ispTabletImgContainer {
        display: none;
    }

    .ispMobileImgContainer {
        display: block;
        width: 100%;
        height: 38vh !important;
        object-fit: cover;
    }

    .ispBannerTextOverLay {
        margin-top: -15em;
        text-align: center;

    }
}

@media (max-width:480px) and (max-height:720px) {

    .ispBannerImgContainer {
        display: none;
    }

    .ispTabletImgContainer {
        display: none;
    }

    .ispMobileImgContainer {
        display: block;
        width: 100%;
        height: 38vh !important;
        object-fit: cover;
    }

    .ispBannerTextOverLay {
        margin-top: -12em;
        text-align: center;

    }

}

@media (max-width:290px) {
    .listItem {
        width: 35%;
    }

    .loginButton {
        padding: 5% 15%;
        font-size: 0.6em;
    }

    .signUpButton {
        padding: 5% 15%;
        font-size: 0.6em;
    }

    .qrenciaLogo {
        width: 100%;
    }

    .ispBannerImgContainer {
        display: none;
    }

    .ispTabletImgContainer {
        display: none;
    }

    .ispMobileImgContainer {
        display: block;
        width: 100%;
        height: 36vh !important;
        object-fit: cover;
    }

    .ispBannerTextOverLay {
        margin-top: -15em;
        text-align: center;

    }

}