.trophyIcon {
  height: 2em;
  width: 2em;
}

.scoroardHeadingSection {
  text-align: center;
  margin: 0em 0em 1.5em 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.trophySection {
  text-align: center;
  margin: 0.5em 0em 0em 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.scoreboardHeading {
  font-size: 1.6em;
  color: var(--qrencia-dark-blue);
  font-weight: 600 !important;
  margin-bottom: -0.2em;
  margin-top: 0em;
}

.tableContent {
  width: 97%;
  background-color: var(--qrencia-white);
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  border: 1px solid var(--qrencia-cards-border);
  margin-bottom: 4em;
  padding: 1em;
  min-height: 20em;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.scoreboardFooterMessage {
  font-size: 0.8em;
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  padding: 0.6em 1em;
}
@media (max-width: 991px) {
  .trophyIcon {
    height: 1.6em;
    width: 1.6em;
    margin: 0em 2em;
  }

  .scoreboardHeading {
    font-size: 2em;
    margin-bottom: -0.2em;
  }

  .tableContent {
    min-height: 18em;
    width: 95%;
  }

  .scoroardHeadingSection {
    margin: 0em 0em 1em 0em;
  }

  .trophySection {
    margin-top: 0em;
  }
}

@media (max-width: 768px) {
  .trophyIcon {
    height: 1.4em;
    width: 1.4em;
    margin: 0em 2em;
  }

  .scoreboardHeading {
    font-size: 1.8em;
    margin-bottom: -0.2em;
  }
}

@media (max-width: 560px) {
  .trophyIcon {
    height: 1.4em;
    width: 1.4em;
    margin: 0em 1em;
  }

  .scoreboardHeading {
    font-size: 1.4em;
    margin-bottom: -0.2em;
  }

  .tableContent {
    min-height: 18em;
    width: 95%;
  }
  .scoreboardFooterMessage {
    font-size: 0.7em;
  }
}
