.resetFormSection {
  background-color: var(--qrencia-white);
  padding: 3rem 2.5rem;
  /* box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 33%); */
  /* border-radius: 7px; */
  /* margin-left: -2px; */
  /* height: 31.5rem; */
  min-height: 41rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  font-size: 14.8px;
}

.formHeading {
  color: var(--qrencia-dark-blue);
  font-size: 1.5em;
  font-weight: 600;
  margin-top: 0.5%;
  margin-bottom: 6%;
  text-align: center;
}

.formSubHeading {
  color: var(--card-grey-title);
  font-size: 1em;
  font-weight: 400 !important;
  margin-top: 0.5%;
  margin-bottom: 2.5%;
}
.qrenciaLogoSection {
  padding-left: auto;
  padding-right: auto;
  margin: 2em auto;
}
.qrenciaLogoSection a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
}
.formSection {
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  max-width: 34em !important;
}
.qrenciaLogo {
  height: 7em;
  object-fit: contain;
}
.iButton {
  padding: 2px;
  background-color: var(--qrencia-dark-blue);
  border-radius: 50%;
  color: var(--qrencia-white);
  font-size: 0.9em;
  margin: 0 0 2px 7px;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-size: 0.9em;
  width: 20em;
}

.inputLabel {
  font-size: 0.85em !important;
}

.formInputBox {
  width: 100%;
}

.showHidePasswordIcon {
  text-align: right;
  padding-right: 2%;
  margin-bottom: -25px;
}
.passwordError {
  margin-bottom: 10px;
}
.showHidePasswordIcon svg {
  margin-top: -4.2em;
  cursor: pointer;
}

.resetButton {
  width: 100%;
  font-weight: 400 !important;
  font-size: 0.9em;
  padding: 6px 8px;
  margin: 0.6em 0;
  margin-top: 2em;
}

.tncTextContainer {
  width: 100%;
}

.tncText {
  color: var(--card-grey-title) !important;
  font-size: 0.85em !important;
  font-weight: 400 !important;
}

.tncTextHyper {
  text-decoration: none !important;
}

.loginTextContainer {
  text-align: center;
  padding: 5px 10px 0 10px;
}

.displayNone {
  display: none;
}

.errorMessage {
  font-size: 0.8em;
  color: red;
}

.successScreenContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 20%);
  border-radius: 7px;
  background-color: var(--qrencia-white);
  padding: 2em 3em;
}
.signupSuccessSalutation {
  font-size: 1.8em;
  margin-top: 1%;
  margin-bottom: 1%;
  color: var(--qrencia-text-black);
}
.signupSuccessTitle {
  font-size: 1.2em;
  margin-top: 1%;
  color: var(--qrencia-text-black);
  margin-bottom: 1%;
  text-align: center;
}
.signupSuccessSubtitle {
  margin-top: 1%;
  margin-bottom: 1%;
  font-size: 1em !important;
}
.signupUsername {
  color: var(--qrencia-dark-blue);
  font-weight: 800;
}
.loginSpinner {
  text-align: center;
}
.loginSpinner > svg {
  color: var(--qrencia-dark-blue);
}
.spinner {
  animation: spin-animation 0.5s infinite;
}
@media (min-height: 1365px) and (max-height: 1367px) and (min-width: 1023px) and (max-width: 1025px) {
  .resetFormSection {
    max-height: 50em !important;
    min-height: 50em;
    font-size: 12px !important;
  }
}
@media (min-height: 767px) and (max-height: 768px) and (min-width: 1023px) and (max-width: 1025px) {
  .resetFormSection {
    max-height: 53em !important;
    min-height: 53em;
    font-size: 12px !important;
  }
}
@media (min-width: 1279px) and (max-width: 1281px) and (min-height: 1023px) and (max-height: 1025px) {
  .resetFormSection {
    max-height: 55em !important;
    min-height: 55em;
    font-size: 14px !important;
  }
}
@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@media (max-width: 1290px) {
  .tncText {
    color: var(--card-grey-title) !important;
    font-size: 0.9em !important;
    font-weight: 400 !important;
  }
}
@media (min-width: 1440px) and (max-width: 2500px) {
  .inputLabel {
    font-size: 0.95em;
  }
  .resetFormSection {
    padding: 4rem;
  }

  .tncText,
  .forgotPasswordText {
    font-size: 0.85em;
  }
}
@media (min-width: 1186px) and (max-width: 1290px) {
  .signupSuccessTitle {
    font-size: 1.1em;
  }
}
@media (min-width: 990px) and (max-width: 1185px) {
  .signupSuccessSalutation {
    font-size: 1.5em;
  }
  .signupSuccessTitle {
    font-size: 0.96em;
  }
  /* .formHeading {
    font-size: 1.2em;
  } */
  .tncText {
    font-size: 0.85em !important;
  }
}
@media (max-width: 991px) {
  .resetFormSection {
    height: 31.5rem;
    border-radius: 7px;
    box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 20%);
  }
}
@media (min-width: 900px) and (max-width: 990px) {
  .signupSuccessTitle {
    font-size: 0.95em;
  }
  .tncText {
    font-size: 0.8em !important;
  }
  .successScreenContainer {
    padding: 2em 2em;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .resetFormSection {
    padding: 3rem 1.5rem;
  }

  .formInputBox {
    width: 100%;
  }

  .resetButton {
    width: 100%;
    font-size: 0.9em;
  }

  .tncTextContainer {
    width: 100%;
  }
  .errorMessageText {
    width: 100%;
  }
  .successMessageText {
    width: 100%;
  }
  .signupSuccessSalutation {
    font-size: 0.95em;
  }
  .signupSuccessTitle {
    font-size: 0.88em;
  }
  /* .formHeading {
    font-size: 1.2em;
  } */
  .successScreenContainer {
    padding: 2em 2em;
  }
  .tncText {
    font-size: 0.8em !important;
  }
}
@media (min-width: 560px) and (max-width: 767px) {
  .resetFormSection {
    padding: 4rem;
  }
}
@media (max-width: 767px) {
  .signupSuccessSalutation {
    font-size: 1.2em;
  }
  .signupSuccessTitle {
    font-size: 0.9em;
  }
  /* .formHeading {
    font-size: 1.2em;
  } */
  .qrenciaLogoSection {
    margin: 1em auto;
  }
  .qrenciaLogo {
    height: 6em;
  }
}
@media (max-width: 480px) {
  .signupSuccessSalutation {
    font-size: 1.2em;
  }
  .signupSuccessTitle {
    font-size: 0.9em;
  }
  /* .formHeading {
    font-size: 1.2em;
  } */
}
@media (min-width: 559px) and (max-width: 767.5px) {
  .resetFormSection {
    padding: 3em 2.5rem;
    margin: 0;
  }

  .formInputBox {
    width: 100%;
  }

  .unitInput {
    width: 100%;
  }

  .resetButton {
    width: 100%;
  }

  .tncTextContainer {
    width: 100%;
  }
  .errorMessageText {
    width: 100%;
  }
  .successMessageText {
    width: 100%;
  }
  /* .formHeading {
    font-size: 1.2em;
  } */
}

@media (min-width: 320px) and (max-width: 560px) {
  .formInputBox {
    width: 100%;
  }
  .resetFormSection {
    padding: 2rem;
  }

  .resetButton {
    width: 100%;
    font-size: 0.85em;
  }

  .tncTextContainer {
    width: 100%;
  }
  .errorMessageText {
    width: 100%;
  }
  .successMessageText {
    width: 100%;
  }
  .showHidePasswordIcon svg {
    margin-top: -4em;
    cursor: pointer;
  }
  /* .formHeading {
    font-size: 1.2em;
  } */
}
