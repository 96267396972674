.awardsTab {
  border-bottom: none !important; /* Hide bottom border */
}

.tabsContainer {
  background-color: white;
  padding-top: 3%;
  border-radius: 1%;
}
.awardsTab ul {
  padding-left: 2% !important;
}
