.cardContainer {
  padding: 0.7em;
  background-color: var(--qrencia-judge-card-bg);
  margin: 0.8em 0em;
  max-width: 95%;
  min-width: 15em;
  border-radius: 0.5em;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  transition: 0.1s all ease-in-out;
  position: relative;
}
.cardContainer:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.nameText {
  font-size: 1em;
  font-weight: 550;
  color: var(--qrencia-judge-card-name);
}
.mailText {
  font-size: 0.75em;
  font-weight: 550;
  color: var(--qrencia-judge-card-email);
  max-width: 100%;
  overflow: hidden;
}
.cardRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.2em;
}
.cardRow svg {
  cursor: pointer;
  margin: 0em 0.45em;
  transition: 0.2s all ease;
  font-size: 1.1em;
  border: none;
  outline: none;
}
.cardRow svg:hover {
  transform: scale(1.2);
}
.cardRow svg:active {
  transform: scale(0.9);
  border: none;
  outline: none;
}
.bottomRow {
  margin-top: 0.8em;
}
.iconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sendLinkBox {
  cursor: pointer;
  text-align: center;
  background-color: var(--qrencia-competition-tabs-success);
  padding: 0.3em 0.4em;
  border-radius: 0.2em;
  transition: 0.1s all ease;
}
.sendLinkBox:hover {
  transform: scale(1.05);
}
.sendLinkBox:active {
  transform: scale(0.9);
}
.sendLink {
  font-size: 0.7em;
  color: (--qrencia-white);
}
.copyMessage {
  position: absolute;
  top: -1em;
  font-size: 0.64em;
  border-radius: 1em;
  right: 1em;
  padding: 0.3em 0.8em;
}
.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
}
.tooltipMessage:active {
  outline: none;
  border: none;
}
@media (max-width: 540px) {
  .cardContainer {
    width: 18em;
    padding: 0.4em;
    margin: 0.5em 0.5em;
  }
  .nameText {
    font-size: 0.9em;
    font-weight: 550;
  }
  .mailText {
    font-size: 0.6em;
    font-weight: 550;
  }
  .sendLinkBox {
    padding: 0.2em 0.3em;
  }
}
@media (max-width: 420px) {
  .cardContainer {
    width: 17em;
    padding: 0.4em;
    margin: 0.5em 0.5em;
  }
  .copyMessage {
    top: -1.4em;
    font-size: 0.6em;
  }
}
