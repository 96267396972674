.paddingLeftZero {
  padding-left: 0 !important;
}

.competitionsCardSection {
  display: flex;
}

.cardBody {
  padding: 0;
}

.competitionCard {
  padding: 0.8em;
  max-width: 15em;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.forgotPasswordContainer {
  margin-top: -1em;
}

.forgotPasswordText {
  color: var(--linkedin-blue);
  font-size: 0.78em;
  font-weight: 400 !important;
  text-align: right;
  cursor: pointer;
}

.competitionCard img {
  height: 7.9em;
}

.cardImage {
  border-radius: 8px;
}

.loginText {
  cursor: pointer !important;
  color: var(--qrencia-link-color);
}

.competitionName {
  display: flex;
  justify-content: space-between;
  font-weight: 600 !important;
  margin: 0.5em 0 0.3em 0;
  font-size: 0.9em;
}

.uniqueIdText {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 0.7em;
  padding: 0 2px;
  border-radius: 4px;
  font-weight: 500 !important;
  background-color: var(--qrencia-white);
}

.copyCodeIcon {
  cursor: pointer;
  font-size: 1.2em !important;
}

.copyCodeTickIcon {
  cursor: pointer;
  font-size: 1.2em !important;
  color: var(--qrencia-success-green);
  background-color: var(--qrencia-white);
}

.compTimeAndDate {
  font-size: 0.6em;
  display: flex;
  font-weight: 600 !important;
  margin: 0.4em 0;
}

.buttonSection {
  margin: 0.4em 0;
  display: flex;
  gap: 1em;
}

.viewButton {
  font-size: 0.75em;
  padding: 4px;
  width: 7em;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  text-align: center;
  font-weight: 400 !important;
  border-radius: 3px;
  margin: 0.3em 0;
}

.cancelButton,
.cancelButton:hover,
.cancelButton:active,
.cancelButton:focus {
  font-size: 0.75em;
  padding: 4px;
  width: 7em;
  background-color: var(--cancel-red-button) !important;
  color: var(--qrencia-white) !important;
  text-align: center;
  font-weight: 400 !important;
  border-radius: 3px;
  margin: 0.3em 0;
  border: none;
}

.modalYesButton,
.modalYesButton:hover,
.modalYesButton:active,
.modalYesButton:focus {
  font-size: 0.75em;
  min-width: 6em;
  background-color: var(--qrencia-success-green) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalNoButton,
.modalNoButton:hover,
.modalNoButton:active,
.modalNoButton:focus {
  font-size: 0.75em;
  min-width: 6em;
  background-color: var(--cancel-red-button) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.nudgeCost {
  width: fit-content;
  background-color: var(--qrenica-nudge-differentiator-color);
  border-radius: 20px;
  padding: 2px 1em;
  font-size: 0.8em;
  color: var(--qrencia-white);
  font-weight: 500 !important;
  margin-top: 20px;
}

.modalCloseButton,
.modalCloseButton:hover,
.modalCloseButton:active,
.modalCloseButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalAlertIcon {
  font-size: 3em;
  color: var(--cancel-red-button);
  text-align: center;
}

.modalMessage {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}

.transactionId {
  font-size: 0.8em;
  font-weight: 600;
  color: var(--qrencia-dark-blue);
}

.verifyCheckImg {
  height: 40px;
  width: 40px;
}

.modalSuccessIcon {
  font-size: 3em;
  color: var(--qrencia-success-green);
  text-align: center;
}

.modalErrorIcon {
  font-size: 3em;
  color: var(--qrencia-error-red);
  text-align: center;
}

.modalAlertText {
  font-size: 0.9em;
  text-align: center;
}

.modalBody {
  padding: 1.5em 1em 1em;
}

.modalFooter {
  display: flex;
  justify-content: center;
  padding: 0.5em 1em 1.5em 1em;
  border: none;
  gap: 1em;
}

.competitionsUserProfileImageSection {
  display: flex;
  gap: 5px;
}

.competitionsProfileImagesSection {}

.competitionsEnrolledCountSection {
  font-weight: 600;
  font-size: 0.65em;
  width: 64%;
  margin-top: 0.5em;
  width: max-content;
}

.onlineIcon {
  margin-left: auto;
  color: var(--qrencia-dark-blue) !important;
}

.profileImage {
  width: 22px;
  height: 22px !important;
  right: 2%;
  border-radius: 18px;
  border: 1px solid var(--qrencia-white);
  position: relative;
  margin-right: -12%;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
}

.shareIconPannelContainer {
  display: grid;
  position: absolute;
  right: -1.15em;
  top: 40%;
  gap: 5px;
}

.shareIcon {
  font-size: 1.2em;
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  border-radius: 20px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 8em;
  padding: 2px;
}

.shareIconUpcomingCards {
  font-size: 1.2em;
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  border-radius: 20px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 2px;
}

.shareIconPannelContainerUpcomingCards {
  display: grid;
  position: absolute;
  right: -1.15em;
  top: 20px;
  gap: 5px;
}

.shareOnLinkedIn {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnFacebook {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnTwitter {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareIcons {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnInstagramButton {
  background: -moz-linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: -webkit-linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  color: var(--qrencia-white);
  font-size: 1em;
  cursor: pointer;
}

.shareOnMailButton {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  cursor: pointer;
}

.copyToClipboard {
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  font-size: 0.95em;
  font-weight: bold;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.competitionCard a,
p {
  text-decoration: none;
  color: var(--qrencia-text-black);
  font-weight: 600;
}

.loadMoreDiv {
  padding: 2em;
}

.loadMore {
  text-align: center;
}

.loadMorebtn {
  background: var(--qrencia-white);
  color: black;
  padding: 4px 1em;
  text-decoration: none;
  border-radius: 1em;
  font-size: 0.8em;
  font-weight: 500;
  border: 1px solid var(--qrencia-dark-grey-border) !important;
}

.loadMorebtn:hover {
  color: var(--qrencia-text-black);
  background: var(--qrencia-grey-border);
}

@media (min-width: 992px) and (max-width: 1290px) {
  .ageRange {
    background: var(--qrencia-grey-border);
    border-radius: 1.2rem;
    font-size: 0.68em;
    font-weight: 600;
    padding: 1.4% 4%;
    text-align: center;
  }

  .competitionName {
    font-weight: 600 !important;
    text-align: left;
    font-size: 0.9em;
  }

  .competitionCard img {
    height: 8em;
  }

  .shareIcon {
    position: absolute;
    right: 18px;
    top: 6.2em;
    padding: 2px;
  }

  .shareIconUpcomingCards {
    right: 15px;
    top: 15px;
    padding: 2px;
  }

  .uniqueIdText {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

@media (min-width: 1545px) {
  .shareIcon {
    top: 8em;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .ageRange {
    background: var(--qrencia-grey-border);
    border-radius: 1.2rem;
    font-size: 0.68em;
    font-weight: 600;
    padding: 1.4% 4%;
    text-align: center;
  }

  .competitionName {
    font-size: 0.9em;
  }

  .competitionCard img {
    height: 8em;
  }

  .shareIcon {
    position: absolute;
    right: 20px;
    top: 8.5em;
    padding: 2px;
  }

  .uniqueIdText {
    position: absolute;
    top: 20px;
    right: 22px;
  }

  .shareIconUpcomingCards {
    right: 20px;
    top: 19px;
    padding: 2px;
  }
}

@media (max-width: 767px) {
  .ageRange {
    background: var(--qrencia-grey-border);
    border-radius: 1.2rem;
    font-size: 0.7rem;
    font-weight: 700;
    padding: 1.2% 4%;
  }

  .competitionCard img {
    height: 8.2em;
  }

  .shareIcon {
    position: absolute;
    right: 20px;
    top: 6.2em;
    padding: 2px;
  }

  .uniqueIdText {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .shareIconUpcomingCards {
    right: 20px;
    top: 20px;
    padding: 2px;
  }

  .nudgeCost {
    font-size: 0.6em;
    min-width: 6em;
    padding: 4px 1em;
    margin-top: -8px;
    text-align: center;
  }
}

@media (max-width: 580px) {
  .competitionCard img {
    height: 8.2em;
  }

  .ageRange {
    border-radius: 1.2rem;
    font-size: 0.68em;
    font-weight: 600;
    padding: 1.4% 5%;
    text-align: center;
  }

  .competitionName {
    font-size: 0.8em;
  }

  .competitionCard {
    margin-top: 0%;
    margin-bottom: 6%;
  }

  .shareIcon {
    position: absolute;
    right: 30px;
    top: 8em;
    padding: 2px;
  }

  .uniqueIdText {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .shareIconUpcomingCards {
    right: 18px;
    top: 18px;
    padding: 2px;
  }
}

@media (max-width: 480px) {
  .loadMorebtn {
    font-size: 0.8em;
  }

  .shareIcon {
    position: absolute;
    right: 25px;
    top: 12.8em;
    padding: 2px;
  }

  .uniqueIdText {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  .shareIconUpcomingCards {
    right: 25px;
    top: 25px;
    padding: 2px;
  }
}

.spinner {
  animation: spin-animation 0.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@media (min-width: 992px) and (max-width: 1180px) {
  .shareIcon {
    position: absolute;
    right: 18px;
    top: 5em;
    padding: 2px;
  }
}

@media (min-width:1181px) and (max-width: 1252px) {
  .shareIcon {
    position: absolute;
    right: 18px;
    top: 5.7em;
    padding: 2px;
  }
}

@media (min-width:992px) and (max-width: 1080px) {
  .shareIcon {
    position: absolute;
    right: 12px;
    top: 4.5em;
    padding: 2px;
  }
}

@media (min-width:898px) and (max-width: 940px) {
  .shareIcon {
    position: absolute;
    right: 20px;
    top: 8em;
    padding: 2px;
  }
}

@media (min-width:824px) and (max-width: 897.8px) {
  .shareIcon {
    position: absolute;
    right: 20px;
    top: 7em;
    padding: 2px;
  }
}

@media (min-width:767px) and (max-width: 823.8px) {
  .shareIcon {
    position: absolute;
    right: 20px;
    top: 6.2em;
    padding: 2px;
  }
}

@media (min-width:717px) and (max-width: 767px) {
  .shareIcon {
    position: absolute;
    right: 20px;
    top: 10em;
    padding: 2px;
  }
}

@media (min-width:665px) and (max-width: 716.8px) {
  .shareIcon {
    position: absolute;
    right: 20px;
    top: 9em;
    padding: 2px;
  }
}

@media (min-width:611px) and (max-width: 664.8px) {
  .shareIcon {
    right: 20px;
    top: 8em;
  }
}

@media (min-width:579px) and (max-width:610.8px) {
  .shareIcon {
    top: 7.5em;
    padding: 2px;
  }
}

@media (min-width:575px) and (max-width:579px) {
  .shareIcon {
    top: 7.5em;
    padding: 2px;
  }
}

@media (min-width:438px) and (max-width:455px) {
  .shareIcon {
    top: 12em;
  }
}

@media (min-width:408px) and (max-width:437.8px) {
  .shareIcon {
    top: 11em;
  }
}

@media (min-width:360px) and (max-width: 407.88px) {
  .shareIcon {
    top: 9.6em;
  }
}

@media (min-width:1290px) and (max-width: 1495px) {
  .shareIcon {
    top: 6.6em !important;
  }
}