.calendarDatesSection {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2%;
}

.calendarDate {
  padding: 6% 2% 3% 2%;
  border-radius: 35px;
  text-align: center;
  cursor: pointer;
}

.calendarDate.selected {
  background-color: var(--qrencia-selected-date-background-blue);
  color: var(--qrencia-text-black) !important;
}

.calendarDate.selected .calendarDateWeekDay {
  color: var(--qrencia-text-black) !important;
  font-weight: 550 !important;
}

.calendarDateWeekDay {
  font-weight: 500 !important;
  color: var(--qrencia-grey-text-calendar);
  font-size: 0.925em;
  margin-bottom: 12px;
}

.calendarDateDay {
  font-weight: 600 !important;
  font-size: 1.05em;
}

.calendarDateMonth {
  font-weight: normal !important;
  font-size: 0.8em;
}

.noCompetitionsAvailableSection {
  font-size: 0.9em;
  text-align: center;
  padding: 2% 0%;
  margin: 6% 0% 4% 0%;
  font-weight: 400;
  border-radius: 10px;
  color: var(--qrencia-text-black);
}

@media (min-width: 1169px) and (max-width: 1286px) {
  .calendarDateWeekDay {
    font-size: 0.75em;
  }

  .calendarDateDay {
    font-size: 0.74em;
  }

  .calendarDateMonth {
    font-size: 0.72em;
  }

  .noCompetitionsAvailableSection {
    font-size: 0.8em;
  }
}

@media (min-width: 992px) and (max-width: 1168px) {
  .calendarDateWeekDay {
    font-size: 0.73em;
  }

  .calendarDateDay {
    font-size: 0.72em;
  }

  .calendarDateMonth {
    font-size: 0.72em;
  }

  .calendarDate {
    padding: 6% 1% 3% 1%;
    border-radius: 35px;
    text-align: center;
    cursor: pointer;
  }
  .noCompetitionsAvailableSection {
    font-size: 0.8em;
  }
}

@media (min-width: 768px) and (max-width: 991.5px) {
  .calendarDateWeekDay {
    font-size: 0.75em;
  }

  .calendarDateDay {
    font-size: 0.73em;
  }

  .calendarDateMonth {
    font-size: 0.72em;
  }

  .calendarDate {
    padding: 6% 1% 3% 1%;
    border-radius: 35px;
    text-align: center;
    cursor: pointer;
  }
  .noCompetitionsAvailableSection {
    font-size: 0.8em;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .calendarDate {
    padding: 6% 2% 3% 2%;
  }

  .calendarDateWeekDay {
    font-size: 0.9em;
  }

  .calendarDateDay {
    font-size: 0.88em;
  }

  .calendarDateMonth {
    font-size: 0.85em;
  }
}

@media (max-width: 480px) {
  .calendarDate {
    padding: 6% 2% 3% 2%;
  }

  .calendarDateWeekDay {
    font-size: 0.85em;
  }

  .calendarDateDay {
    font-size: 0.83em;
  }

  .calendarDateMonth {
    font-size: 0.82em;
  }
}
@media (max-width: 400px) {
  .calendarDate {
    padding: 6% 2% 3% 2%;
  }

  .calendarDateWeekDay {
    font-size: 0.8em;
  }

  .calendarDateDay {
    font-size: 0.76em;
  }

  .calendarDateMonth {
    font-size: 0.78em;
  }
}
