.userInitials {
  font-weight: 500;
  background-color: var(--qrencia-dark-blue);
  padding: 1px;
  text-align: center;
  color: var(--qrencia-white);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: inline-block;
  font-size: 1em;
  line-height: 26px;
  margin: 2% 0.5%;
}
