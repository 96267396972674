.impactCreatedCardSection {
    margin-top: -8%;
}

.heading {
    font-size: 3.5em;
    font-weight: 700;
    color: var(--qrencia-text-black);
    font-family: 'Inter';
    margin-top:6%;
}

.subHeading {
    font-size: 1em;
    font-weight: 600;
    color: var(--qrencia-text-black);
    font-family: 'Inter';
}

@media(min-width: 1185px) and(max-width:1290px) {
    .heading {
        font-size: 3.8em;
    }

    .subHeading {
        font-size: 1em;
    }
}

@media (min-width: 767px) and (max-width: 1185px) {
    .heading {
        font-size: 3em;
    }

    .subHeading {
        font-size: 0.85em;
    }
}

@media (max-width: 767px) {
    .heading {
        font-size: 2.5em;
    }

    .subHeading {
        font-size: 0.8em;
    }

    .impactCreatedCardSection {
        margin-top: -32%;
    }


}
@media (min-width:767px) and (max-width: 767.8px) {
    .impactCreatedCardSection {
        margin-top: -41%!important;
    }
}
@media(min-width:768px) and (max-width:1129px){
    .impactCreatedCardSection {
        margin-top: -10%!important;
    }
}

@media(max-width:576px) {
    .impactCreatedCardSection {
        margin-top: -30%;
    }

    .heading {
        font-size: 1.8em;
    }

    .subHeading {
        font-size: 0.7em;
    }
}

@media(max-width:500px) {
    .impactCreatedCardSection {
        margin-top: -32%!important;
    }
    .heading {
        font-size: 1.5em;
    }
}
@media(max-width:480px) {
    .impactCreatedCardSection {
        margin-top: -41%!important;
    }
    .heading {
        margin-top:15%;
    }
}
@media (min-width: 538px) and (max-width: 542px) {
    .impactCreatedCardSection {
        margin-top: -26%!important;
    }
}