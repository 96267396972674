.nudgeCardBody {
    background: var(--student-nudge-widget-bg) !important;
}

.nudgeIconColor {
    color: var(--qrenica-nudge-differentiator-color);
    margin-top: 0.5%;
    margin-right: 1%;
    font-size: 1.1em;
}

.nudgeSummary {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.nudgeModalTitle {
    font-size: 1em;
    font-weight: 550;
    padding-left: 0.5em;
}

.cursorPointer{
    cursor: pointer !important;
}