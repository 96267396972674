input[class^='ag-'] {
  padding: 0 !important;
}

.allParticipantsGrid span {
  color: var(--qrencia-text-black);
}

.ag-header-cell-label {
  justify-content: center;
  color: var(--qrencia-text-black);
}

.errorSuccessAlertDeclareResult {
  font-size: 1.1em;
  margin-bottom: -2em;
}

.errorSuccessAlertDownloadStudentResult {
  font-size: 0.82em !important;
}

.ag-header-cell,
.ag-header-group-cell {
  font-size: 0.9em;
  background: var(--qrencia-light-blue-icon-background);
}

.ag-header-cell:hover,
.ag-header-group-cell:hover {
  font-size: 0.9em;
  background: var(--qrencia-light-blue-icon-background) !important;
}

.ag-header-cell-text {
  color: var(--qrencia-text-black);
}

.ag-icon-filter {
  display: none !important;
  color: var(--qrencia-text-black);
}

.ag-row {
  border-bottom: none;
}

.ag-row-focus {
  outline: none;
}

.ag-center-cols-viewport {
  overflow-x: scroll;
}

.ag-center-cols-viewport::-webkit-scrollbar {
  display: none;
}

.multi-line-header {
  white-space: normal !important;
  text-align: center;
}

.ag-body-horizontal-scroll {
  overflow-x: hidden !important;
}

.no-type-conversion {
  text-transform: none;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-ltr .ag-cell-range-single-cell,
.ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-rtl .ag-cell-range-single-cell,
.ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: 1px solid var(--qrencia-light-grey-border);
  outline: none;
}

/* .ag-header-cell-text {
    color: var(--qrencia-text-black);
} */
.ag-theme-alpine .ag-row {
  background-color: transparent !important;
}

.ag-ltr .ag-cell:focus {
  outline: none;
}

.ag-ltr .ag-cell {
  text-align: center;
  border: 1px solid var(--qrencia-light-grey-border);
  font-size: 0.85em;
  padding: 0 0.5%;
}

.gridButton {
  font-size: 0.9em !important;
  font-weight: 500 !important;
  padding: 2px 12px 3px;
  margin-top: -0.34em;
}

.gridIconButton {
  font-size: 0.8em !important;
  font-weight: 500 !important;
  padding: 2px 12px 3px;
  margin-top: -0.34em;
}

.gridGreenButton {
  background-color: var(--qrencia-competition-tabs-success);
  border: none;
  font-size: 0.9em !important;
  font-weight: 500 !important;
  padding: 2px 12px 3px;
  margin-top: -0.34em;
}

.gridGreenButton:hover {
  background-color: var(--qrencia-competition-tabs-success) !important;
}

.gridIcon {
  font-size: 1.3em;
}

.expertViewCompetitionResultViewSection {
  background-color: var(--qrencia-white);
  border-radius: 10px;
}

.expertViewCompetitionResultViewSection .detailsHeading {
  font-size: 0.8em !important;
  font-weight: 500 !important;
}

.expertViewCompetitionResultViewSection .detailsSubHeading {
  font-size: 0.8em !important;
  font-weight: 400 !important;
  padding-bottom: 7px;
  overflow-wrap: anywhere;
}

.goBackButton,
.goBackButton:hover,
.goBackButton:active,
.goBackButton:focus {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  background-color: var(--qrencia-white) !important;
  color: var(--qrencia-dark-blue) !important;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.reportButtonSection {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.reportButtonSection button {
  min-width: 8em;
  height: 2.5em;
}

.reportButtonSection div button {
  min-width: 8em;
  height: 2.5em;
}

.expertViewCompetitionDownloadResultBtn {
  margin-top: 1em;
  margin-left: 1em;
  min-width: 10em !important;
}

.expertViewCompetitionDownloadCertificateBtn {
  margin-top: 1em;
  font-size: 0.75em;
  margin-right: 1em;
}

@media (max-width: 580px) {
  .ag-root-wrapper {
    font-size: 0.95em;
  }

  .ag-ltr .ag-cell {
    font-size: 0.95em;
  }

  .errorSuccessAlertDeclareResult {
    font-size: 1.2em;
  }

  .expertViewCompetitionResultViewSection .detailsHeading {
    font-size: 0.7em !important;
    font-weight: 500 !important;
  }

  .expertViewCompetitionResultViewSection .detailsSubHeading {
    font-size: 0.7em !important;
    font-weight: 400 !important;
    padding-bottom: 7px;
  }
}

@media (max-width: 576px) {
  .gridButton {
    font-size: 0.7em !important;
  }
}

@media (max-width: 420px) {
  .ag-paging-panel > * {
    margin: 0em auto;
  }
}

@media (max-width: 400px) {
  .gridButton {
    font-size: 0.5em !important;
  }
}

@media (min-width: 375px) and (max-width: 536px) {
  .reportButtonSection {
    display: block;
  }
}

@media (min-width: 767px) and (max-width: 792px) {
  .reportButtonSection {
    display: block;
  }
}
