.pageContainer {
  background-color: var(--qrencia-white);
  border-radius: 10px;
  width: 90%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 1em auto;
  padding: 1.5em;
}
.pendingEnrolmentHeading {
  font-size: 1.2em;
  margin-top: 1em;
  font-weight: 600;
}
.loaderContainer {
  padding: 2em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-grey-border);
  width: 80%;
  margin: 1em auto;
  text-align: center;
  background-color: var(--qrencia-white);
}
.loginSection {
  margin-top: 4em;
}
.loaderIcon {
  font-size: 3em !important;
  color: var(--qrencia-dark-blue) !important;
}
.sessionExpiredText {
  font-size: 1em;
}
.sessionExpiredText > span {
  color: var(--linkedin-blue);
  font-weight: 600 !important;
  cursor: pointer;
}

@media (max-width: 480px) {
  .pendingEnrolmentHeading {
    font-size: 1em;
    margin-top: 1em;
    font-weight: 500;
  }
}
