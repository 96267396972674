.leftSectionMainCnt {
  padding: 0 0 0 0.5em;
}

.profilePicMainCnt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: nowrap;
  gap: 1em;
  padding: 1em 0;
}

.expertCountryFlag {
  width: 5%;
  margin-top: -0.4%;
}

.profilePicPreviewCnt {
  display: flex;
}

.previewProfilePic {
  display: block;
  width: 100px !important;
  height: 100px !important;
  border-radius: 100%;
}

.smallerText {
  font-size: 0.8em !important;
  font-weight: 500 !important;
  color: var(--qrencia-text-black);
}

.profilePicPreviewDetails {
  display: block;
}
.modalTitle {
  font-size: 1.2em;
}
.nudgePopup {
  max-width: 1px !important;
  max-height: 1px !important;
}
.errorContainer {
  padding: 2em 1em;
  margin: 1em 0;
  text-align: center;
  background-color: var(--qrencia-white);
  color: var(--qrencia-grey-text);
  font-style: italic;
  font-weight: 400 !important;
}

.expertName {
  font-size: 1.3em;
  font-weight: 600 !important;
  color: var(--qrencia-dark-blue);
}

.expertSchoolName {
  font-size: 0.9em;
  font-weight: 500 !important;
  color: var(--qrencia-grey-text-color);
}

.infoBarContainer {
  padding: 1em;
  background-color: #e7f2ff;
  border: 1px solid #badaff;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
}
.ratingCnt {
  cursor: pointer;
}
.ratingStars {
  color: var(--qrencia-golden-color) !important;
  font-size: 1.2em !important;
  margin: 0;
}

.ratingText {
  font-size: 0.8em;
  font-weight: 500 !important;
  color: var(--qrencia-grey-text-color);
  margin-left: 5px;
}

.halfStar {
  font-size: 0.8em;
  margin-top: -1px;
  margin-left: 1px;
  margin-right: 1px;
}
.studentEngagedCnt {
  display: flex;
  align-items: center;
  height: 100%;
}

.studentEngagedCnt p {
  font-size: 0.8em;
  font-weight: 500 !important;
  color: var(--qrencia-grey-text-color);
}

.studentEngagedCnt svg {
  font-size: 1.2em !important;
  color: var(--qrencia-dark-blue);
  margin-right: 6px;
}

.aboutExpertSection {
  margin: 0.8em 0 0 0;
  padding: 1em;
  background-color: var(--qrencia-white);
  border: 1px solid var(--qrencia-cards-border);
  border-radius: 10px;
}

.aboutExpertHeading {
  font-size: 1.2em;
  font-weight: 600 !important;
  color: var(--qrencia-grey-text-color);
}
.followUnfollowButton {
  margin-top: 8px;
  font-size: 0.7em;
  padding: 1.8px 12px;
  border-radius: 12px;
  margin-right: 1em;
}
.following {
  color: var(--qrencia-white);
  background-color: var(--qrencia-dark-blue);
}

.follow {
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-white);
  border: 1px solid var(--qrencia-dark-blue);
  font-weight: 500;
}
.writeReview {
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-white);
  border: 1px solid var(--qrencia-dark-blue);
  font-weight: 500;
  vertical-align: middle;
}
.aboutExpertSubHeading {
  font-size: 0.8em;
  color: var(--qrencia-grey-text-color);
}

.organizationContactContainer {
  display: flex;
  padding: 0.5em 0 0 0;
  justify-content: space-between;
  row-gap: 5px;
  column-gap: 1em;
  flex-wrap: wrap;
}

.organizationContactInnerCnt {
  display: flex;
  align-items: baseline;
}

.organizationContactInnerCnt svg {
  font-size: 1em !important;
  color: var(--qrencia-dark-blue);
  margin-right: 6px;
}

.organizationContactInnerCnt p {
  font-size: 0.8em !important;
  color: var(--qrencia-grey-text-color);
}

.organizationContactInnerCnt a,
.organizationContactInnerCnt a:hover,
.organizationContactInnerCnt a:focus,
.organizationContactInnerCnt a:active {
  color: var(--qrencia-grey-text-color) !important;
  font-weight: 500 !important;
  text-decoration: none;
}

.locationIcon {
  font-size: 1.2em !important;
}

.orgNameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.shareIconPannelContainer {
  display: flex;
  gap: 0.8em;
  align-items: center;
}

.shareIconPannelContainer a,
.shareIconPannelContainer a:hover,
.shareIconPannelContainer a:focus,
.shareIconPannelContainer a:active {
  color: var(--qrencia-dark-blue) !important;
  background-color: #c8e1ff;
  padding: 0px 6px 2px 6px;
  border-radius: 50%;
}

.shareIconPannelContainer svg {
  font-size: 0.9em;
}

.coursesSection {
  margin: 0.5em 0;
  padding: 1em 0;
}
.initialsSection {
  height: 3.3em;
  width: 3.3em;
  background-color: var(--qrencia-course-skill-name-color);
  color: var(--qrencia-dark-blue);
  text-align: center;
  font-size: 1.2em;
  line-height: 3.3em;
  font-weight: 500;
  min-width: 2.5em;
  border-radius: 50%;
  font-weight: 600;
}
.mediaContentContainer {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8em;
  flex-wrap: wrap;
}
.imageBox {
  flex: 1 1 calc(25% - 1em);
  height: 12em;
  /* width: 12em; */
  border: 1px solid var(--qrencia-white) !important;
  padding: 0px !important;
  position: relative;
  overflow-clip-margin: content-box;
  cursor: pointer;
  overflow: hidden;
  border-radius: 2px;
  object-fit: cover;
  margin-right: 2px;
}
.gallaryImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  transition: transform 200ms;
  position: absolute;
  top: 0;
  left: 0;
}
.fadeImageBackground {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.55);
  height: 12em;
  width: 12em;
  z-index: 1;
  border-radius: 2px;
  width: 100%;
}
.gallaryImage:hover {
  transform: scale(1.15);
}
.overLay {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 200ms;
  color: var(--qrencia-white);
  font-size: 1.5em;
  font-weight: 500;
}
.overLay:hover {
  font-size: 1.7em;
}
.gallaryVideoPlayIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3em;
  width: 3em;
}
@media (min-width: 1300px) {
  .organizationContactContainer {
    width: 80%;
  }
}
/* @media (max-width: 991px) {
  .imageBox {
    flex: 1 1 calc(33.33% - 1em); 
  }
} */
@media (max-width: 768px) {
  .infoBarContainer {
    padding: 1em;
    background-color: #e7f2ff;
    border: 1px solid #badaff;
    display: flex;
    justify-content: unset;
    border-radius: 10px;
    align-items: center;
    gap: 0.5em;
    flex-wrap: wrap;
  }

  .studentEngagedCnt {
    width: 48%;
  }

  .ratingCnt {
    width: 48%;
  }
  .imageBox {
    flex: 1 1 calc(50% - 1em);
  }
  .fadeImageBackground {
    flex: 1 1 calc(50% - 1em);
  }
}

@media (max-width: 500px) {
  .expertName {
    font-size: 1.1em;
  }

  .expertCountryFlag {
    width: 8%;
    margin-top: -0.4%;
  }

  .expertSchoolName {
    font-size: 0.85em;
  }

  .previewProfilePic {
    display: block;
    width: 80px !important;
    height: 80px !important;
    border-radius: 100%;
  }

  .aboutExpertHeading {
    font-size: 1em;
    font-weight: 600 !important;
  }

  .aboutExpertSubHeading {
    font-size: 0.75em;
  }

  .studentEngagedCnt p {
    font-size: 0.75em;
  }

  .organizationContactInnerCnt p {
    font-size: 0.75em !important;
  }

  .shareIconPannelContainer svg {
    font-size: 0.8em !important;
    vertical-align: unset;
  }

  .shareIconPannelContainer a,
  .shareIconPannelContainer a:hover,
  .shareIconPannelContainer a:focus,
  .shareIconPannelContainer a:active {
    padding: 1px 7px 0px 6px;
  }

  .infoBarContainer {
    padding: 1em;
    background-color: #e7f2ff;
    border: 1px solid #badaff;
    display: flex;
    justify-content: unset;
    border-radius: 10px;
    align-items: center;
    gap: 0.5em;
    flex-wrap: wrap;
  }

  .studentEngagedCnt {
    width: 98%;
  }

  .ratingCnt {
    width: 98%;
  }

  .organizationContactInnerCnt {
    width: 100%;
  }
}

@media (max-width: 990px) {
  .leftSectionMainCnt {
    padding: 0 0 0 0em;
  }
}

@media (max-width: 480px) {
  .shareIconPannelContainer svg {
    font-size: 0.65em !important;
    vertical-align: unset;
  }

  .shareIconPannelContainer a,
  .shareIconPannelContainer a:hover,
  .shareIconPannelContainer a:focus,
  .shareIconPannelContainer a:active {
    padding: 0px 7px 0px 6px;
  }

  .shareIconPannelContainer {
    gap: 0.4em;
  }
  .followUnfollowButton {
    margin-top: 8px;
    font-size: 0.65em;
    padding: 1px 14px;
    border-radius: 10px;
  }
  .imageBox {
    height: 10em !important;
    width: 10em !important;
  }
}
@media (max-width: 390px) {
  .imageBox {
    height: 9em !important;
    width: 9em !important;
  }
}
@media (max-width: 376px) {
  .shareIconPannelContainer svg {
    font-size: 0.7em !important;
    vertical-align: unset;
  }

  .shareIconPannelContainer a,
  .shareIconPannelContainer a:hover,
  .shareIconPannelContainer a:focus,
  .shareIconPannelContainer a:active {
    padding: 0px 6px 0px 6px;
  }

  .shareIconPannelContainer {
    gap: 0.4em;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .previewProfilePic {
    width: 80px !important;
    height: 80px !important;
  }

  .expertName {
    font-size: 1.05em;
  }

  .expertSchoolName {
    font-size: 0.85em;
  }

  .infoBarContainer {
    font-size: 0.85em;
    gap: 0.5em;
  }

  .aboutExpertHeading {
    font-size: 1.05em;
  }

  .aboutExpertSubHeading {
    font-size: 0.8em;
    color: var(--qrencia-grey-text-color);
  }

  .shareIconPannelContainer a,
  .shareIconPannelContainer a:hover,
  .shareIconPannelContainer a:focus,
  .shareIconPannelContainer a:active {
    padding: 0px 7.5px 2px 6.5px;
  }

  .shareIconPannelContainer svg {
    font-size: 0.75em;
  }
}

@media (max-width: 767.5px) {
  .previewProfilePic {
    width: 80px !important;
    height: 80px !important;
  }

  .expertName {
    font-size: 1.05em;
  }

  .expertSchoolName {
    font-size: 0.85em;
  }

  .infoBarContainer {
    font-size: 0.85em;
    gap: 0.5em;
  }

  .aboutExpertHeading {
    font-size: 0.96em;
  }

  .aboutExpertSubHeading {
    font-size: 0.8em;
    color: var(--qrencia-grey-text-color);
  }

  .shareIconPannelContainer a,
  .shareIconPannelContainer a:hover,
  .shareIconPannelContainer a:focus,
  .shareIconPannelContainer a:active {
    padding: 0px 7.5px 0px 6.5px;
  }

  .shareIconPannelContainer svg {
    font-size: 0.75em;
  }
}

@media (max-width: 376px) {
  .writeReview {
    /* margin-left: 0.3em; */
    font-size: 0.6em;
  }
  .followUnfollowButton {
    margin-top: 8px;
    font-size: 0.6em;
    padding: 1px 8px;
    border-radius: 10px;
  }
}
