.qTalkBreadcrumbs a {
    text-decoration: none;
    font-weight: 500;
    color: var(--qrencia-text-black);
}

.qTalkBreadcrumbs a:hover {
    font-weight: 505;
}

.qTalkBreadcrumbs {
    font-weight: 500;
    color: var(--qrencia-text-black);
    cursor: pointer;
    padding: 0% 8%;
    padding-top: 1em;
    background: var(--qrencia-white);
}

.qTalkBreadcrumbs span {
    font-weight: 500;
    color: var(--qrencia-text-black);
}