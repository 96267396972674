.calendarDatesSection {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2%;
}

.calendarDate {
  padding: 6% 2% 3% 2%;
  border-radius: 35px;
  text-align: center;
  cursor: pointer;
}

.calendarDate.selected {
  background-color: var(--qrencia-selected-date-background);
  color: var(--qrencia-text-black) !important;
}

.calendarDate.selected .calendarDateWeekDay {
  color: var(--qrencia-text-black) !important;
  font-weight: 500 !important;
}

.calendarDateWeekDay {
  font-weight: 500 !important;
  color: var(--qrencia-grey-text-calendar);
  font-size: 0.925em;
  margin-bottom: 12px;
}

.calendarDateDay {
  font-weight: 600 !important;
  font-size: 1.05em;
}

.calendarDateMonth {
  font-weight: 400 !important;
  font-size: 0.8em;
}

.noCompetitionsAvailableSection {
  font-size: 0.8em;
  text-align: left;
  padding: 2% 0%;
  margin: 6% 0% 4% 0%;
  font-weight: 400;
  border-radius: 10px;
  color: var(--qrencia-text-black);
}

@media (min-width: 1169px) and (max-width: 1286px) {
  .calendarDateWeekDay {
    font-size: 0.75em;
  }

  .calendarDateDay {
    font-size: 0.74em;
  }

  .calendarDateMonth {
    font-size: 0.72em;
  }

  .noCompetitionsAvailableSection {
    font-size: 0.8em;
  }
}

@media (min-width: 992px) and (max-width: 1168.5px) {
  .calendarDateWeekDay {
    font-size: 0.7em;
  }

  .calendarDateDay {
    font-size: 0.72em;
  }

  .calendarDateMonth {
    font-size: 0.68em;
  }

  .calendarDate {
    padding: 6% 3px 3% 2px;
    border-radius: 35px;
    text-align: center;
    cursor: pointer;
  }

  .noCompetitionsAvailableSection {
    font-size: 0.8em;
  }
}

@media (min-width: 768px) and (max-width: 991.5px) {
  .calendarDateWeekDay {
    font-size: 0.7em;
  }

  .calendarDateDay {
    font-size: 0.73em;
  }

  .calendarDateMonth {
    font-size: 0.7em;
  }

  .calendarDate {
    padding: 6% 3px 3% 2px;
    border-radius: 35px;
    text-align: center;
    cursor: pointer;
  }

  .noCompetitionsAvailableSection {
    font-size: 0.8em;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .calendarDate {
    padding: 6% 2% 3% 2%;
  }

  .calendarDateWeekDay {
    font-size: 0.9em;
  }

  .calendarDateDay {
    font-size: 0.88em;
  }

  .calendarDateMonth {
    font-size: 0.85em;
  }

  .calendarDatesSection {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .noCompetitionsAvailableSection {
    margin-top: 1em;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 480px) {
  .calendarDate {
    padding: 6% 2% 3% 2%;
  }

  .calendarDateWeekDay {
    font-size: 0.85em;
  }

  .calendarDateDay {
    font-size: 0.83em;
  }

  .calendarDateMonth {
    font-size: 0.82em;
  }

  .calendarDatesSection {
    width: 100%;
  }
}

@media (max-width: 370px) {
  .calendarDatesSection {
    width: 100%;
  }
}
