.detailsLabel {
  color: var(--qrencia-dark-blue);
  font-size: 0.85em;
  font-weight: 500 !important;
}
.detailsText {
  font-size: 0.85em;
}
.detailsLinkLabel,
.detailsLinkLabel:hover,
.detailsLinkLabel:active,
.detailsLinkLabel:focus {
  text-decoration: none;
  font-size: 0.75em !important;
  cursor: pointer;
  color: var(--qrencia-text-black);
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg);
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  padding: 1px 8px;
}
.cardTitle {
  font-size: 1.18em;
  color: var(--qrencia-white);
  font-weight: 500 !important;
  text-align: center;
}
.countCards {
  margin-right: 2.2em;
  margin-top: 1.2em;
  width: 12em;
  border: none;
  outline: none;
}
.dashboardCards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.enrolmentCount {
  color: var(--qrencia-white);
}
.cardValue {
  font-size: 1.8em;
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: -0.2em !important;
}
.exportButton {
  font-size: 0.8em;
}
.modalCloseButton {
  font-size: 0.8em;
}
.enrolmentCard {
  color: var(--qrencia-white);
  background-color: var(--qrenica-competition-differentiator-color);
}
.attemptCard {
  background-color: var(--qrenica-course-differentiator-color);
}
.submissionCard {
  background-color: var(--qrenica-nudge-differentiator-color);
}
.absentCard {
  background-color: var(--qrencia-mentorship-differentiator-color);
}
.notAttemptCard {
  background-color: var(--qrencia-workshop-differentiator-color);
}
.detailsColumContainer {
  margin-top: 1em;
}
.cardValue {
  font-size: 1.8em;
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: -0.2em !important;
}
.modalCloseButton {
  font-size: 0.8em;
}
.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
}
.selectButton {
  font-size: 0.8em !important;
  padding: 0.61em 1em;
  margin-top: 1em;
  /* width: 9em; */
}

.modalFooter {
  text-align: right;
  margin-top: 1em;
}
.creatorNameHeading {
  font-size: 1.1em;
  color: var(--qrencia-dark-blue);
}
.revenueCollectedTest {
  font-size: 1em;
  color: var(--qrencia-dark-blue);
}
.competitionTextColor {
  color: var(--qrenica-competition-differentiator-color);
}
.courseTextColor {
  color: var(--qrenica-course-differentiator-color);
}
.workshopTextColor {
  color: var(--qrencia-workshop-differentiator-color);
}
.buttonRow {
  margin-top: 1em;
  text-align: right;
}
.buttonRow > button {
  margin-left: 0.5em;
}
@media (max-width: 991px) {
  .showGrid {
    display: none;
  }
  .showCards {
    display: block;
  }
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 14em;
  }
  .cardValue {
    font-size: 1.6em;
    font-weight: 600 !important;
    text-align: center;
    margin-bottom: -0.2em !important;
  }
  .countCards {
    margin-right: 2.2em;
    margin-top: 1.2em;
    width: 11em;
  }
  .detailsLabel {
    font-size: 0.8em;
  }
  .detailsText {
    font-size: 0.8em;
  }
}
@media (max-width: 584px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 11.2em;
  }
  .dashboardCards {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  .cardValue {
    font-size: 1.5em;
  }
  .detailsLabel {
    font-size: 0.77em;
  }
  .detailsText {
    font-size: 0.77em;
  }
  .countCards {
    margin-right: 2.2em;
    margin-top: 1.2em;
    width: 10em;
  }
}
@media (max-width: 494px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 16em;
  }
  .dashboardCards {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  .cardValue {
    font-size: 1.4em;
  }
  .countCards {
    margin-right: 2.2em;
    margin-top: 1.2em;
    width: 10em;
  }
}
