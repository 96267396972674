.heading {
  font-size: 1em;
  font-weight: 600;
  color: var(--qrencia-text-black);
  margin: 0.5em 0.5em 1em 0.5em;
}
.suggestiveCompetitionSearchComponent {
  background-color: var(--qrencia-white);
  width: 100%;
  padding: 1em;
  min-height: 4.2em;
  border-radius: 5px;
}
.errorMessageDiv {
  margin-bottom: -1em;
}
.suggestiveSearchInputContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.selectButton {
  font-size: 0.8em !important;
  padding: 0.61em 1em;
  margin-top: 1em;
}
.suggestiveCompetitionSearchComponent input {
  flex: 1;
}
.dashboardContainer {
  background-color: var(--qrencia-white);
  width: 100%;
  padding: 1em;
  border-radius: 5px;
  margin-top: 1em;
  margin-bottom: 2em;
}
.gridIcon {
  margin: 0px 3px;
}
.dashboardCards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.cardTitle {
  font-size: 1.18em;
  color: var(--qrencia-white);
  font-weight: 500 !important;
  text-align: center;
}
.cardCount {
  font-size: 1.8em;
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: -0.2em !important;
}
.enrolmentCount {
  color: var(--qrencia-white);
}
.enrolmentCard {
  color: var(--qrencia-white);
  background-color: var(--qrenica-competition-differentiator-color);
}
.attemptCard {
  background-color: var(--qrenica-course-differentiator-color);
}
.submissionCard {
  background-color: var(--qrenica-nudge-differentiator-color);
}
.absentCard {
  background-color: var(--qrencia-mentorship-differentiator-color);
}
.notAttemptCard {
  background-color: var(--qrencia-workshop-differentiator-color);
}
.workshopCard {
  background-color: var(--qrencia-workshop-differentiator-color);
}
.nudgeCard {
  background-color: var(--qrenica-nudge-differentiator-color);
}
.courseCard {
  background-color: var(--qrenica-course-differentiator-color);
}
.costCard {
  background-color: var(--qrencia-cost-background);
}
/* .errorMessageDiv {
  margin-bottom: -1em;
} */
.attemptCount {
  color: var(--qrencia-white);
}
.submissionCount {
  color: var(--qrencia-white);
}
.absentCount {
  color: var(--qrencia-white);
}
.notAttemptCount {
  color: var(--qrencia-white);
}
.detailsLabel {
  color: var(--qrencia-dark-blue);
  font-size: 0.85em;
  font-weight: 500 !important;
}
.attemptsPercentage {
  color: var(--qrenica-course-differentiator-color);
  height: 1.2em;
  font-size: 0.6em !important;
}
.detailsLinkLabel,
.detailsLinkLabel:hover,
.detailsLinkLabel:active,
.detailsLinkLabel:focus {
  text-decoration: none;
  font-size: 0.75em !important;
  cursor: pointer;
  color: var(--qrencia-text-black);
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg);
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  padding: 1px 8px;
}
.countCards {
  margin-right: 2.2em;
  margin-top: 1.2em;
  width: 12em;
  border: none;
  outline: none;
}

.detailsText {
  font-size: 0.85em;
}
.detailsColumContainer {
  margin-top: 1em;
}
.percentageSpan {
  font-size: 0.5em;
  color: var(--qrencia-white);
  font-weight: 500 !important;
}
.dateFilterText {
  cursor: pointer;
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  border-radius: 5px;
  text-align: center;
  padding: 0.2em 1em;
  min-width: 11em;
  width: fit-content;
  font-size: 0.78em;
  line-height: 2.6em;
  height: 3em;
  margin-top: 1em;
}
.dateFilterText > svg {
  margin-left: 0.5em;
}
.dateFilterTextDisabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.obrGridHeader {
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  font-weight: bold;
}
@media (max-width: 990px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 14em;
  }
}
@media (max-width: 767px) {
  .dateFilterText {
    margin-left: 1em;
  }
}
@media (max-width: 584px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 11.2em;
  }
  .dashboardCards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 584px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 11.2em;
  }
  .dashboardCards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 494px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 16em;
  }
  .dashboardCards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .suggestiveCompetitionSearchComponent > button {
    font-size: 0.7em;
    padding: 0.5em 1em;
  }
  .selectButton {
    height: 2.4em;
    font-size: 0.6em;
    line-height: 1em;
  }
}
