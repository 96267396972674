.nudgeFormContainer {
  width: 85%;
  margin: 1.5em auto;
  border: 1px solid var(--qrencia-cards-border);
  border-radius: 10px;
}
@media (min-width: 540px) and (max-width: 1290px) {
  .nudgeFormContainer {
    width: 95%;
  }
}

@media (max-width: 540px) {
  .nudgeFormContainer {
    width: 92%;
  }
}
