.desktopCarousel {
  display: block;
}

.tabletCarousel {
  display: none;
}

.mobileCarousel {
  display: none;
}

.carouselImage {
  width: 100%;
  /* height: 66vh !important; */
  object-fit: cover;
}

.carouselItem {
  /* height: 66vh; */
}

.carouselCaption {
  text-align: left;
  left: 9.8%;
  width: 50%;
  color: var(--qrencia-text-black);
  top: 5rem;
  animation: animateCaption 1.25s ease-out forwards;
}

.CarouselButton {
  margin-top: 3%;
  font-size: 0.8em;
  padding: 1.6% 5%;
  font-weight: 500;
  letter-spacing: 0.36px;
  background: var(--qrencia-dark-blue) !important;
  border-radius: 4px;
}

@keyframes animateCaption {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.headingText {
  font-size: 3em;
  font-weight: 600;
}

.subHeadingText {
  font-size: 1.4em;
  margin-top: 4%;
  padding-right: 27%;
}

.desktopCarousel {
  display: block;
}

@media (max-width: 1335px) {
  .carouselCaption {
    top: 3rem;
  }
}

@media (max-width: 1290px) {
  .carouselCaption {
    width: 48%;
  }
}

@media (max-width: 1243px) {
  .subHeadingText {
    padding-right: 19%;
  }
}

@media (max-width: 1162px) {
  .subHeadingText {
    padding-right: 23%;
  }
}

@media (max-width: 990px) {
  .headingText {
    font-size: 2.3em;
  }

  .carouselCaption {
    width: 50%;
  }
}

@media (min-width: 540px) and (max-width: 990px) {
  .desktopCarousel {
    display: none;
  }

  .tabletCarousel {
    display: block;
  }

  .mobileCarousel {
    display: none;
  }

  .carouselCaption {
    left: 6%;
    top: 13rem;
    width: 52% !important;
  }
}

@media (min-width: 1023px) and (max-width: 1025px) {
  .desktopCarousel {
    display: none;
  }

  .tabletCarousel {
    display: block;
  }

  .mobileCarousel {
    display: none;
  }

  .carouselCaption {
    left: 5%;
    top: 2rem;
  }
}

@media (max-width: 720px) {
  .carouselCaption {
    top: 10rem;
  }
}

@media (max-width: 687px) {
  .carouselCaption {
    top: 7rem;
    width: 59% !important;
  }
}

@media (max-width: 611px) {
  .carouselCaption {
    top: 5rem;
    width: 59% !important;
  }
}

@media (max-width: 540px) {
  .tabletCarousel {
    display: none;
  }
  .desktopCarousel {
    display: none;
  }

  .mobileCarousel {
    display: block;
  }

  .subHeadingText {
    padding-right: 0;
  }

  .carouselCaption {
    left: 0%;
    width: 100% !important;
    top: 1rem;
    padding: 1% 4.5% 4.5%;
    text-align: center;
  }

  .headingText {
    font-size: 1.45em !important;
  }

  .subHeadingText {
    margin-top: 4%;
  }
}

@media (max-width: 480px) {
  .CarouselButton {
    font-weight: 400;
  }

  .carouselCaption {
    left: 0%;
    padding: 4.5% 4.5% 4.5% 4.5%;
    width: 100% !important;
    top: 0rem !important;
    text-align: center;
  }

  .headingText {
    font-size: 1.35em !important;
  }

  .subHeadingText {
    margin-top: 4%;
  }
}

@media (max-width: 1290px) {
  .headingText {
    font-size: 2.7em;
  }
}

@media (max-width: 1162px) {
  .carouselCaption {
    width: 51%;
  }
}

@media (max-width: 1076px) {
  .headingText {
    font-size: 2.2em;
  }
}

@media (max-width: 990px) {
  .headingText {
    font-size: 2em;
  }
}

@media (max-width: 876px) {
  .headingText {
    font-size: 2.2em;
  }

  .carouselCaption {
    width: 62%;
  }
}

@media (max-width: 480px) and (max-height: 800px) {
  .carouselCaption {
    left: 0%;
    padding: 2% 4.5% 4.5% 4.5%;
    width: 100% !important;
    top: 2rem;
    text-align: center;
  }

  .headingText {
    font-size: 1.35em !important;
  }

  .subHeadingText {
    margin-top: 4%;
  }
}

@media (min-height: 800px) and (max-width: 480px) {
  .carouselCaption {
    left: 0%;
    padding: 4.5% 4.5% 4.5% 4.5%;
    width: 100% !important;
    top: 2rem;
    text-align: center;
  }

  .headingText {
    font-size: 1.35em !important;
  }

  .subHeadingText {
    margin-top: 4%;
  }
}

@media (min-width: 820px) and (min-height: 1179px) and (max-width: 821px) {
  .carouselImage {
    width: 100%;
    object-fit: cover;
  }
}

@media (min-width: 912px) and (min-height: 1368px) and (max-width: 913px) {
  .carouselImage {
    width: 100%;
    object-fit: cover;
  }
}

@media (min-width: 768px) and (min-height: 1024px) and (max-width: 769px) {
  .carouselImage {
    width: 100%;
    object-fit: cover;
  }
}

@media (min-width: 990px) and (max-width: 1200px) and (min-height: 600px) {
  .carouselImage {
    width: 100%;
    object-fit: cover;
  }
}

@media (min-width: 1023px) and (max-width: 1025px) and (min-height: 600px) and (max-height: 602px) {
  .carouselImage {
    width: 100%;
    object-fit: cover;
  }
}

@media (min-width: 1023px) and (min-height: 1365px) and (max-width: 1025px) and (max-height: 1367px) {
  .carouselImage {
    width: 100%;
    /* height: 40vh !important; */
    object-fit: cover;
  }

  .carouselItem {
    /* height: 40vh !important; */
  }
}

@media (min-width: 1155px) and (max-width: 1275px) {
  .headingText {
    font-size: 2.5em;
  }
}

@media (min-width: 1076px) and (max-width: 1155px) {
  .headingText {
    font-size: 2.3em;
  }
}

@media (min-width: 767.5px) and (max-width: 878px) {
  .headingText {
    font-size: 1.7em;
  }
}

@media (min-width: 480px) and (max-width: 767.5px) {
  .headingText {
    font-size: 1.5em;
  }
}
@media (min-width: 990px) and (max-width: 1185px) {
  .subHeadingText {
    font-size: 1.1em;
  }
}
@media (min-width: 767px) and (max-width: 990px) {
  .subHeadingText {
    font-size: 0.9em;
  }
}
@media (max-width: 767px) {
  .subHeadingText {
    font-size: 0.9em;
  }
}

@media (max-width: 540px) {
  .CarouselButton {
    padding: 1.5% 3%;
  }
}

@media (min-width: 480px) and (max-width: 540px) {
  .subHeadingText {
    font-size: 0.8em;
  }
}

@media (max-width: 480px) {
  .subHeadingText {
    font-size: 0.75em !important;
  }
  .CarouselButton {
    margin-top: 2%;
  }
}
@media (max-width: 445px) {
  .subHeadingText {
    margin-top: 2%;
  }
  .headingText {
    font-size: 0.95em !important;
  }
  .CarouselButton {
    padding: 1.5% 3%;
    font-size: 0.7em;
  }
}

@media (max-width: 394px) {
  .subHeadingText {
    font-size: 0.6em !important;
  }
  .headingText {
    font-size: 0.9em !important;
  }

  .CarouselButton {
    margin-top: 2%;
  }
}

@media (max-width: 356px) {
  .subHeadingText {
    font-size: 0.58em !important;
  }
  .headingText {
    font-size: 0.95em !important;
  }
}
