.tabContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  margin: 0em 1em 0em 1em;
}
.tabPill {
  text-align: center;
  cursor: pointer;
  border-radius: 20px;
  font-weight: 600;
  font-size: 1.2em;
  color: var(--card-grey-title);
  margin-right: 2em;
}
.activeTab {
  z-index: 1;
  text-decoration: underline;
  text-decoration-color: var(--qrencia-dark-blue);
  text-underline-offset: 5px;
  text-decoration-thickness: 2.5px;
  color: var(--qrencia-text-black);
}

@media (max-width: 768px) {
  .tabPill {
    font-size: 0.8em;
  }
  .activeTab {
    text-underline-offset: 3px;
  }
}

@media (max-width: 425px) {
  .tabPill {
    font-size: 1em;
  }
}

@media (max-width: 400px) {
  .tabPill {
    font-size: 0.9em;
  }
}

@media (max-width: 360px) {
  .tabPill {
    font-size: 0.8em;
  }
}
