.heatmapSection {
  /* margin-bottom: 1%; */
  margin-top: 1%;
  padding: 2% 2.5% 2% 2.5%;
  background-color: var(--qrencia-white);
  border-radius: 20px;
}

.heatmapContainer {
  width: 100%;
  background-color: var(--qrencia-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.heatmapHeading {
  color: var(--qrencia-text-black);
  font-weight: 700;
  font-size: 1.4em;
  margin-bottom: 2%;
}

.calHeatmap {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}

.laLink {
  text-decoration: none;
  cursor: pointer;
}

.boosterText {
  margin: 0 2rem 2rem;
}

.chevronIcon {
  text-align: center;
  padding-bottom: 1em;
  user-select: none;
}

.iconRight {
  color: var(--qrencia-dark-blue);
  border-radius: 10px;
  transform: scale(0.7);
  transform: scale(2);
  cursor: pointer;
}

.iconLeft {
  color: var(--qrencia-dark-blue);
  border-radius: 10px;
  transform: scale(0.7);
  transform: scale(2);
  margin-right: 1.5em;
  cursor: pointer;
}

@media (max-width: 1290px) {
  .heatmapHeading {
    font-size: 1.2em;
  }
}

@media (max-width: 910px) {
  .heatmapSection {
    padding: 2% 2.5% 2% 2.5%;
  }
}

@media (max-width: 767.8px) {
  .heatmapSection {
    margin-top: 3%;
    padding: 2%;
  }

  .heatmapHeading {
    font-size: 1.1em;
  }
}

@media (max-width: 437px) {
  .heatmapSection {
    padding: 4% 2.5% 3% 2.5%;
  }
}