.expertCompDetailsAccordion tr > td {
  width: 10%;
}

.expertCompDetailsAccordion .accordion-item:first-of-type .accordion-button {
  padding: 0.8em 1.2em;
  font-size: 0.43em;
  font-weight: 500;
  background: var(--qrencia-template-background-color);
  color: var(--qrencia-text-black);
  border-bottom: 1px solid var(--bs-accordion-border-color) !important;
}

.expertCompDetailsAccordion .accordion-body {
  padding: 1em;
}

.expertCompDetailsAccordion .accordion-button {
  padding: 0.8em 1.2em;
  margin-top: 0 !important;
  text-decoration: none;
  font-size: 0.43em !important;
  font-weight: 500;
  background: var(--qrencia-template-background-color) !important;
  border: none;
  color: var(--qrencia-text-black);
  border-bottom: 1px solid var(--bs-accordion-border-color) !important;
}

.expertCompDetailsAccordion .accordion-item:last-of-type .accordion-button {
  font-size: 0.43em !important;
  font-weight: 500;
  background: var(--qrencia-template-background-color);
  color: var(--qrencia-text-black);
  padding: 0.8em 1.2em;
}

.expertCompDetailsAccordion .accordion-item:last-of-type {
  border: inherit !important;
}

.expertCompDetailsAccordion .accordion-button .accordion-button:focus {
  box-shadow: initial !important;
  border: initial !important;
}

.expertCompDetailsAccordion .accordion-button {
  box-shadow: initial !important;
  border-bottom: 1px solid var(--bs-accordion-border-color) !important;
}

.expertCompDetailsAccordion
  .accordion-item:last-of-type
  .accordion-button.collapsed {
  border: 1px solid var(--bs-accordion-border-color) !important;
  border-top: none !important;
}

.expertCompDetailsAccordion
  .accordion-item:last-of-type
  .accordion-button:not(.collapsed) {
  border: 1px solid var(--bs-accordion-border-color) !important;
  border-top: none !important;
}

.expertCompDetailsAccordion .expertAccordionContentSection {
  font-size: 0.79em !important;
  font-weight: 400;
}

.expertCompDetailsAccordion .fontBold {
  font-weight: 500 !important;
}

.expertCompDetailsAccordion .expertCompleteCompButtonCnt {
  display: flex;
  justify-content: end;
  padding: 0 0 1em 0;
}

.expertCompDetailsAccordion .expertNoParticipantsErroMessage {
  padding: 3em 1em;
  margin: 1em 0;
  text-align: center;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  color: var(--qrencia-grey-text);
  font-style: italic;
  font-weight: 400 !important;
  border: 1px solid var(--qrencia-cards-border);
}

.expertEditCompetitionsIcon {
  font-size: 2.5em;
  padding: 2px 5px;
  color: var(--qrencia-dark-blue);
  cursor: pointer;
}

.expertCompDetailsAccordion .accordion-button:not(.collapsed)::after {
  position: inherit;
  top: -4px;
  right: 4px;
}
td .ql-editor {
  padding: 12px 0px !important;
}

@media (max-width: 580px) {
  .expertCompDetailsAccordion .expertAccordionContentSection {
    font-size: 0.72em !important;
  }

  .expertCompDetailsAccordion .expertNoParticipantsErroMessage {
    font-size: 0.8em !important;
  }

  .expertCompDetailsAccordion .accordion-button:not(.collapsed)::after {
    right: 6px;
  }
}

.expertCompDetailsAccordion .expertSpinnerIcon {
  animation: spin-animation 0.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
