@mixin object-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// JS - css
.container {
  display: flex;
  height: 88.7vh;
}

.completeProfileCnt {
  background-color: var(--qrencia-template-background-color);
  padding: 0;
}

.leftSection {
  top: 0;
  bottom: 0;
  overflow-y: hidden;
  width: 30vw;
}

.profilecontainer {
  background-color: #d5e9f6;
  width: 100%;
  height: 100%;
  background-image: var(--background-image);
  background-size: contain;
  padding: 3em 1em 3em 2em;
}

.profilePictureContainer {
  position: relative;
  width: fit-content;
}

.deleteProfilePicture {
  font-size: 1em;
  top: 70%;
  right: 3%;
  position: absolute;
  height: 1.7em;
  width: 1.7em;
  line-height: 1.7em;
  padding: auto;
  border-radius: 50%;
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  z-index: 200;
  text-align: center;
  cursor: pointer;
}

.deleteProfilePicture svg {
  font-size: 1em;
  margin-left: auto;
  margin-right: auto;
}

.profilePhoto {
  position: relative;
  border: 8px solid var(--qrencia-dark-blue);
  margin-bottom: 2%;
  width: 11.5em;
  height: 11.5em;
  background-color: var(--qrencia-white);
  border-radius: 50%;
  overflow: hidden;
}

.profilePhoto img {
  width: 100%;
  display: block;
  margin: -0.5em auto;
  border: none;
  object-fit: cover;
}

.profileOverlayInput {
  width: 100%;
  cursor: pointer;
  position: absolute;
  top: 75%;
}

.editEmailText {
  font-size: 0.82em;
  font-weight: 500 !important;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.editEmailText svg {
  margin-top: 2px;
  margin-right: 2px;
}

.profilePicInputBox {
  opacity: 0;
  position: absolute;
  top: 75%;
  z-index: 22;
}

.sessionExpiredText {
  font-size: 1em;
}

.sessionExpiredText>span {
  color: var(--linkedin-blue);
  font-weight: 600 !important;
  cursor: pointer;
}

.sessionExpiredContainer {
  padding: 2em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-grey-border);
  width: 80%;
  margin: 1em auto;
  text-align: center;
  background-color: var(--qrencia-white);
  margin-top: 5em;
}

.rightSection {
  flex: 1;
  overflow-y: auto;
  padding: 3%;
}

.formGroup input {
  width: 100%;
  margin-bottom: 2.5%;
  font-size: 13px;
  padding: 10px 35px 10px 15px !important;
  border: none;
  border-radius: 6px;
  background: #fff;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.formLabel {
  display: flex;
  margin-bottom: 5px;
  justify-content: end;
  font-size: 13px;
  color: var(--qrencia-dark-blue);
}

.verifySpan {
  color: var(--qrencia-success-green);
}

.emptySpan {
  margin-top: 1.5em;
}

#disabledEmailInput {
  padding-left: 10px !important;
  background-color: red;
  border: none !important;
}

#dobInput {
  padding-left: 10px !important;
  border: none !important;
}

.formGroup .formInputPlaceholder {
  color: var(--qrencia-placeholder-color) !important;
}

.errorSuccessContainer {
  position: relative;
  width: 100%;
}

.errorSuccessMessage {
  position: absolute;
  top: -4em;
  right: -1%;
  width: 55%;
}

.formGroup select {
  width: 100%;
  margin-bottom: 2.5%;
  font-size: 13px;
  border: none;
  padding: 10px 35px 10px 15px !important;
  border-radius: 6px;
  background: var(--qrencia-white);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.cardContainer {
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
}

.interestCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--qrencia-white) !important;
  min-height: 10vh;
  margin: 2em 1em 1em 1em;
}

.skillContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
  padding-bottom: 4%;
}

.skillContainerBox {
  min-height: 25vh;
  max-height: 35vh;
}

.interestitem {
  background-color: var(--qrencia-obr-comment-bg);
  border-radius: 20px;
  padding: 4px 10px;
  margin: 5px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  font-weight: 500;
}

.selectedSkillPill {
  width: max-content;
}

.selectedSkillSection {
  display: flex;
  flex-wrap: wrap;
  margin: 0em 0.5em 0em 0.5em;
}

.closeIcon {
  font-size: 1em;
  position: relative;
  right: -0.4em;
}

.selected {
  position: relative;
  border: 2px solid #7d6fea;
  color: black;
  overflow: hidden;
  transition: border-color 0.3s ease, transform 0.3s ease;
}

.selected:hover {
  border-color: #5a4ed8;
  transform: scale(1.05);
}

.submitButtonCnt {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: var(--bs-body-bg);
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
}

.submitButton {
  font-weight: 400 !important;
  font-size: 0.85em;
  padding: 0.2%;
  margin-top: 0.6%;
  margin-bottom: 0.6%;
  margin-left: 1em;
  margin-right: 1em;
  border-radius: 8px;
  // width: 10%;
  width: 10em;
}

.personalDetailsDiv {
  margin-top: 5%;
}

.tabContentHeading {
  margin: 2em 1em 1em 1em;
  font-weight: 500 !important;
}

/// JS- css ends

.otpPopup {
  margin-top: 10%;
}

.dialogActionButton {
  text-align: center;
}

.infoIcon {
  width: 6%;
  margin-right: 2%;
  color: var(--performance-page-rank-skyblue);
}

.dialogHeading {
  font-size: 1.5em;
  font-weight: 600;
  color: var(--card-grey-title);
}

.verifyMobileModal {
  padding: 6%;
}

.otpInputContainer {
  margin: 6% auto;
}

.cancelButton {
  margin-left: 4%;
  background: var(--qrencia-white);
  color: var(--qrencia-dark-blue);
}

.actionBtnContainer {
  display: flex;
  justify-content: end;
}

.otpInfoText {
  font-size: 0.8em;
  font-weight: 400 !important;
}

.otpInputContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.otpInputBox {
  width: max-content !important;
}

.otpInputBox input {
  margin-right: 10px !important;
  margin: 8px 0;
  background-color: var(--qrencia-white);
}

.resendOtpButton {
  background-color: var(--qrencia-select-box-background);
  outline: none;
  text-decoration: none;
  border: none;
  font-size: 0.8em;
  font-weight: 500;
  padding: 4px 0;
  width: 7em;
  text-align: center;
  cursor: pointer;
}

.dialogInputLabel {
  z-index: 1;
  margin-left: 2%;
  background: white;
  font-weight: 500;
  margin-left: 3px;
  padding: 0 0.3em;
  position: relative;
  padding: 0% 1%;
  background-image: linear-gradient(to bottom,
      var(--qrencia-white),
      var(--qrencia-select-box-background));
}

.formHeader {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.cardTitle {
  font-size: 0.98em;
  color: var(--qrencia-white);
  font-weight: 500 !important;
  text-align: center;
}

.cardheader {
  display: flex;
  width: 100%;
  align-items: center;
  color: #224b7f;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #f5f5f5;
  background-color: #ffffff;
}

.profileinfocontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 95%;
  height: 47vh;
}

.formContainer {
  padding-left: 0%;
  padding-right: 0%;
}

.tabHeading {
  cursor: pointer;
}

.profileText {
  font-size: 18px;
  margin-top: 3%;
}

.profileText h2 {
  font-weight: 600;
}

.toggleIcon {
  margin-left: auto;
  cursor: pointer;
  font-size: 24px;
}

.toggleIcon svg {
  vertical-align: middle;
}

.inputWrapper {
  position: relative;
}

.inputWrapper textarea {
  background-color: var(--qrencia-white);
  border: none;
  border-radius: 6px;
  padding: 10px 35px 10px 15px !important;
}

.codeText {
  margin-left: 30%;
}

.formGroup input:focus {
  border-color: #0a6bb0;
  background: #fff;
  outline: none;
}

.formGroup .inputIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: grey;
  pointer-events: none;
}

/* Overlay using a pseudo-element */
.selected::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(125,
      111,
      234,
      0.1);
  /* Using the border color with transparency */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.selected:hover::before {
  opacity: 1;
}

/* Add animation for the overlay */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.selected::before {
  animation: fadeIn 0.3s ease forwards;
}

.interestitem span {
  margin-right: 8px;
  font-size: 16px;
}

.updatetext {
  font-size: 14px;
  color: #555;
}

.cardheader h2 {
  margin: 0;
  font-size: 18px;
}

.cardbody {
  width: 100%;
}

.formHeading {
  font-size: 1.2em;
  font-weight: 600;
  padding-left: 0.5em;
}

.labelText {
  font-size: 0.85em;
  font-weight: 600;
  margin-bottom: 5px;
}

.formInputBox {
  margin-bottom: 1em;
  padding: 6px !important;
}

.inputLabelsRow {
  padding: 0em;
  padding-bottom: 0.6rem;
}

.formInputBoxVerifyEmail {
  width: 50%;
  display: block;
  margin-bottom: 0.5em;
}

.navTabs {
  width: 100%;
  margin-top: -2%;
  margin-bottom: 10px;
}

.navTabs>ul {
  margin-left: 0.2em !important;
}

.profilePicCnt {
  margin: 1em;
}

.profilePicPreviewCnt {
  display: flex;
  align-items: center;
}

.previewProfilePic {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 2px solid var(--qrencia-dark-blue);
}

.initialProfilePreview {
  display: flex;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 2px solid var(--qrencia-dark-blue);
  font-size: 2.6em;
  justify-content: center;
  align-items: center;
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-select-box-background);
  font-weight: 600 !important;
}

.logoutButton {
  font-weight: 400 !important;
  font-size: 0.8em;
  padding: 2px 10px;
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-white);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* For Edge, IE, and other legacy browsers */
body {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.verifyOtpButton {
  font-weight: 300 !important;
  font-size: 0.9em;
  padding: 2px 20px;
}

.requestOtpButton {
  font-weight: 300 !important;
  font-size: 0.9em;
  padding: 2px 20px;
  margin: 0.6em 0;
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-white);
  margin-right: 2em;
}

.verifyOtpButton[disabled] {
  cursor: not-allowed !important;
}

.requestOtpButton[disabled] {
  cursor: not-allowed !important;
}

.selectedSkillsCnt {
  border-left: 2px solid var(--qrencia-disabled-input-border);
  height: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.selectedSkillsList {
  list-style-type: none !important;
  padding: 0 0 0 0.5em;
  margin-bottom: 0;
}

.selectedSkillsCheckBox {
  width: 20px;
  margin-right: 10px;
}

.selectedSkillsName {
  padding: 0 0.5em;
  display: inline-flex;
}

.verifyMobileContainer {
  padding: 12px 8px;
  background: var(--qrencia-white);
  border: solid 1px var(--qrencia-select-box-border);
  background-color: var(--qrencia-select-box-background);
  margin-top: -18px;
  width: 100%;
}

.mobileContainer {
  display: flex;
  justify-content: space-between;
}

.mobileDisabledText {
  font-size: 0.85em;
  font-weight: 500 !important;
  color: var(--qrencia-grey-text);
  word-break: break-word;
  padding-right: 5px;
}

.iButtonVerifyPending {
  color: var(--qrencia-error-red);
  margin: 0 5px;
  border-radius: 50%;
  font-size: 1.1em;
}

.iButtonVerified {
  color: var(--qrencia-success-green);
  margin: 0 5px;
  border-radius: 50%;
  font-size: 1.1em;
}

.tooltipVerifyPending {
  background-color: rgb(255, 206, 206);
  color: var(--qrencia-error-red) !important;
  font-size: 0.9em;
  width: 20em;
}

.tooltipVerified {
  background-color: rgb(208, 255, 195);
  color: var(--qrencia-success-green) !important;
  font-size: 0.9em;
  width: 20em;
}

.errorMessage {
  font-size: 0.8em;
  color: var(--qrencia-error-red);
}

.spinner {
  animation: spin-animation 0.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@media (max-width: 991px) {
  .errorSuccessMessage {
    // position: relative;
    // top: -7em;
    // right: 0;
    top: 0em;
    position: relative;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .formInputBoxVerifyEmail {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .leftSection {
    width: 35vw;
  }

  .selectedSkillsCnt {
    border-top: 2px solid var(--qrencia-disabled-input-border);
    border-left: none;
    height: 100%;
    padding: 1em 0;
  }

  .logoutButton {
    font-size: 0.75em;
    padding: 1.5px 20px;
  }

  .submitButton {
    font-size: 0.8em;
    padding: 4px 30px;
    margin: 0.6em;
    width: 14em;
    margin-right: 2%;
  }

  .verifyOtpButton {
    font-size: 0.85em;
    padding: 2px 20px;
    margin: 0.6em 0;
  }

  .requestOtpButton {
    font-size: 0.85em;
    padding: 2px 20px;
    margin: 0.6em 0;
    color: var(--qrencia-dark-blue);
    background-color: var(--qrencia-white);
    margin-right: 2em;
  }

  .formInputBoxVerifyEmail {
    width: 80%;
  }
}

@media (max-width: 740px) {
  .container {
    flex-direction: column;
    height: unset;
  }

  .completeProfileCnt {
    padding: 0;
  }

  .formInputBoxVerifyEmail {
    width: 100%;
  }

  .leftSection {
    width: 100%;
  }

  .submitButtonCnt {
    justify-content: center;
    position: fixed;
    z-index: 100;
    padding: 1%;
  }

  .rightSection {
    margin-bottom: 20vh;
  }

  .otpPopup {
    margin-top: 26%;
  }

  .emptySpan {
    margin-top: 0.2em;
  }
}

@media (max-width: 425px) {
  .completeProfileCnt {
    padding: 0;
  }

  .personalDetailsDiv {
    padding-bottom: 25%;
  }

  .submitButton {
    font-size: 0.75em;
    padding: 1%;
    width: 12em;
    margin: 0.6em 1em;
  }

  .formInputBoxVerifyEmail {
    width: 100%;
  }

  .profilecontainer {
    padding: 3em 1em 3em 1.8em;
  }

  .profilePhoto {
    width: 8em;
    height: 8em;
    border: 6px solid var(--qrencia-dark-blue);
    margin-bottom: 5%;
  }

  .profilePicInputBox {
    top: 68%;
  }

  .leftSectionUserName {
    font-size: large;
    font-weight: 700;
    margin-bottom: 4%;
    margin-top: 7%;
  }

  .interestitem {
    padding: 0px 8px;
    margin: 4px;
    font-size: 10px;
  }

  .dialogHeading {
    font-size: 1.2em;
  }

  .otpPopup {
    margin-top: 26%;
  }

  .tabContentHeading {
    margin: 2em 1em 1em 1em;
  }

  .resendOtpButton {
    width: 4.5em;
  }

  .dialogActionButton {
    font-size: 0.9em;
  }

  .deleteProfilePicture {
    font-size: 1em;
    top: 70%;
    right: 3%;
    position: absolute;
    height: 1.4em;
    width: 1.4em;
    line-height: 1.2em;
    z-index: 200;
  }

  .deleteProfilePicture svg {
    font-size: 1em;
    margin-left: auto;
    margin-right: auto;
  }

  .emptySpan {
    margin-top: 0.2em;
  }
}

@media (max-width: 375px) and (min-width: 415px) {
  .resendOtpButton {
    width: 4em;
  }

  .dialogActionButton {
    font-size: 0.85em;
  }
}

@media (max-width: 375px) {
  .leftSectionUserName {
    margin-bottom: 2%;
    margin-top: 5%;
  }

  .resendOtpButton {
    width: 3em;
  }

  .dialogActionButton {
    font-size: 0.8em;
  }
}

@media (max-width: 320px) {
  .leftSectionUserName {
    margin-bottom: 4%;
    margin-top: 7%;
  }
}

@media (max-width: 740px) {
  .profilecontainer {
    padding: 3em 1em 3em 2.4em;
  }
}

@media (max-width: 680px) {
  .profilecontainer {
    padding: 3em 1em 3em 2.2em;
  }
}

@media (max-width: 500px) {
  .profilecontainer {
    padding: 3em 1em 3em 1.8em;
  }
}