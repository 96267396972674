.profitCard {
    background-color: var(--qrencia-white);
    border-radius: 6px;
    padding: 1.5rem 0 2.5rem 0;
}

.sectionDesc {
    text-align: center;
    margin-bottom: 1.8rem;
    font-size: 2rem;
    font-weight: 700 !important;
    color: var(--qrencia-dark-blue);
}

.cardImage {
    width: 100%;
    margin-bottom: 1.8rem;
}

.cardThumbnailImage {
    width: 100%;
    border-radius: 15px;
    margin-bottom: 2rem;
}

.cardHeading {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--qrencia-grey-text-color);
}

.cardHeading strong {
    font-size: 1.4rem;
    font-weight: 600;
}

.cardStatus {
    padding: 0 1.4rem;
}

.cardDesc {
    color: var(--qrencia-grey-text-color);
    font-weight: 400 !important;
}

@media (max-width: 990px) {
    .sectionDesc {
        font-size: 1.8rem;
    }

    .cardHeading {
        font-size: 1.4rem;
    }

    .cardThumbnailImage {
        margin-bottom: 1.3rem;
    }

    .cardDesc {
        font-size: 0.9rem;
    }
}

@media (max-width: 768px) {
    .sectionDesc {
        font-size: 1.7rem;
    }

    .cardHeading {
        font-size: 1.4rem;
    }

    .cardThumbnailImage {
        margin-bottom: 1.3rem;
    }

    .cardDesc {
        font-size: 0.9rem;
    }

    .profitCard {
        padding: 0.8rem 0 0 0;
    }

    .cardStatus {
        margin-bottom: 2rem;
    }
}

@media (max-width: 575px) {
    .sectionDesc {
        font-size: 1.5rem;
    }
}

@media (max-width: 540px) {
    .sectionDesc {
        font-size: 1.5rem;
        margin-bottom: 1.3rem;
    }

    .cardHeading {
        font-size: 1.5rem;
    }

    .cardHeading strong {
        font-size: 1.5rem;
        font-weight: 600;
    }

    .cardThumbnailImage {
        margin-bottom: 1.3rem;
    }

    .cardDesc {
        font-size: 0.9rem;
    }

    .profitCard {
        padding: 0.8rem 0 0 0;
    }

}

@media (max-width: 475px) {
    .sectionDesc {
        font-size: 1.3rem;
    }

    .cardImage {
        margin-bottom: 1.2rem;
    }

    .cardHeading {
        font-size: 1.4rem;
    }

    .cardHeading strong {
        font-size: 1.4rem;
    }

    .cardStatus {
        margin-bottom: 1.3rem;
    }
}

@media (max-width: 440px) {
    .sectionDesc {
        font-size: 1.3rem;
        margin-bottom: 1.3rem;
    }

    .cardHeading {
        font-size: 1.4rem;
    }

    .cardHeading strong {
        font-size: 1.4rem;
        font-weight: 600;
    }

    .cardThumbnailImage {
        margin-bottom: 1rem;
    }

    .cardDesc {
        font-size: 0.9rem;
    }

    .profitCard {
        padding: 0.8rem 0 0 0;
    }

}