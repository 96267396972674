.myActivitiesSection {
  display: none;
  z-index: 100;
}
.mobileViewRightWidgetSection {
  display: none;
}
.myActivitiesButton,
.myActivitiesButton:hover,
.myActivitiesButton:active,
.myActivitiesButton:focus,
.myActivitiesButton:visited {
  font-size: 0.75em;
  padding: 0.5% 0% 0.5% 1.5%;
}
.activityIcon {
  font-size: 1.6em;
}
@media (min-width: 690px) and (max-width: 767.5px) {
  .myActivitiesSection {
    text-align: end;
    position: absolute;
    width: 99%;
    top: 70px;
    display: block;
  }
  .mobileViewRightWidgetSection {
    display: block;
  }
}
@media (min-width: 635px) and (max-width: 690px) {
  .myActivitiesSection {
    text-align: end;
    position: absolute;
    width: 99%;
    top: 65px;
    display: block;
  }
  .mobileViewRightWidgetSection {
    display: block;
  }
}
@media (min-width: 600px) and (max-width: 635px) {
  .myActivitiesSection {
    text-align: end;
    position: absolute;
    width: 99%;
    top: 60px;
    display: block;
  }
  .mobileViewRightWidgetSection {
    display: block;
  }
}
@media (min-width: 540px) and (max-width: 600px) {
  .myActivitiesSection {
    text-align: end;
    position: absolute;
    width: 99%;
    top: 72px;
    display: block;
  }
  .mobileViewRightWidgetSection {
    display: block;
  }
}
@media (min-width: 480px) and (max-width: 540px) {
  .myActivitiesSection {
    text-align: end;
    position: absolute;
    width: 99%;
    top: 65px;
    display: block;
  }
  .mobileViewRightWidgetSection {
    display: block;
  }
  .myActivitiesButton,
  .myActivitiesButton:hover,
  .myActivitiesButton:active,
  .myActivitiesButton:focus,
  .myActivitiesButton:visited {
    font-size: 0.7em;
  }
}
@media (max-width: 480px) {
  .myActivitiesSection {
    text-align: end;
    position: absolute;
    width: 99%;
    top: 55px;
    display: block;
  }
  .mobileViewRightWidgetSection {
    display: block;
  }
  .myActivitiesButton,
  .myActivitiesButton:hover,
  .myActivitiesButton:active,
  .myActivitiesButton:focus,
  .myActivitiesButton:visited {
    font-size: 0.7em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .templateHeight {
    min-height: 88vh;
  }
}
@media (min-width: 580px) and (max-width: 767.5px) {
  .templateHeight {
    min-height: 76vh;
  }
}
@media (max-width: 579px) {
  .templateHeight {
    min-height: 77.5vh;
  }
}
