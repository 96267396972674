.errorContainer {
  padding: 2em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-grey-border);
  width: 80%;
  margin: 1em auto;
  text-align: center;
  background-color: var(--qrencia-white);
}

.shareIconPannelContainer {
  color: var(--qrencia-white);
  display: grid;
  position: fixed;
  padding: 1% 0.8% 0.5% 0.7%;
  top: 50%;
  right: 0%;
  background: var(--qrencia-dark-blue);
  border-top: 2px solid var(--qrencia-white);
  border-bottom: 2px solid var(--qrencia-white);
  border-left: 2px solid var(--qrencia-white);
  border-radius: 10px 0px 0px 10px;
  z-index: 1000;
}

.shareIcons {
  margin-bottom: 32%;
  border-radius: 20px;
  cursor: pointer;
  width: 23px;
  height: 23px;
}

.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  padding: 10%;
  width: 23px;
  height: 23px;
  margin-bottom: 32%;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}
.micorsiteContainer {
  padding-left: 6%;
  padding-right: 6%;
}
@media (min-width: 990px) and (max-width: 1290px) {
  .shareIconPannelContainer {
    top: 48%;
  }

  .shareIcons {
    width: 20px;
    height: 20px;
  }

  .showLinkCopiedIcon {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .shareIconPannelContainer {
    top: 46%;
  }

  .shareIcons {
    width: 20px;
    height: 20px;
  }

  .showLinkCopiedIcon {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 480px) and (max-width: 767.5px) {
  .shareIconPannelContainer {
    top: 45%;
  }

  .shareIcons {
    width: 18px;
    height: 18px;
  }

  .showLinkCopiedIcon {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 480px) {
  .shareIconPannelContainer {
    top: 44%;
  }

  .shareIcons {
    width: 18px;
    height: 18px;
  }

  .showLinkCopiedIcon {
    width: 17px;
    height: 17px;
    padding: 5% 0% 5% 0%;
  }
}
