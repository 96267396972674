body {
  background-color: var(--qrencia-white);
}

.termsAndConditionsMainContainer {
  width: 100%;
  padding: 0% 10%;
  background-color: var(--qrencia-white);
  border-top: 1px solid var(--qrencia-grey-border);
  background-repeat: no-repeat;
}

.termsAndConditionsHeadingTitle>p>span,.termsAndConditionsDate>p>span,.termsAndConditionsDescription>p>span {
  font-weight: 600!important;
  color: var(--qrencia-dark-blue);
  font-size: 1.2em;
}
.termsAndConditionsHeadingTitle>h1>span>u>strong,.termsAndConditionsDate>h1>span>u>strong,.termsAndConditionsDescription>h1>span>u>strong {
  font-weight: 600;
  color: var(--qrencia-dark-blue);
}

.termsAndConditionsHeadingTitle>p>strong,.termsAndConditionsDate>p>strong,.termsAndConditionsDescription>p>strong {
  font-weight: 500;
}

.termsAndConditionsMainContainer .termsAndConditionsSubContainer {
  width: 100%;
  padding: 4% 0%;
  height: auto;
}


.termsAndConditionsMainContainer
  .termsAndConditionsSubContainer
  .termsAndConditionsHeading {
  width: 100%;
  text-align: center;

}

.termsAndConditionsMainContainer
  .termsAndConditionsSubContainer
  .termsAndConditionsHeading
  .termsAndConditionsHeadingTitle {
  width: auto;
  float: none;
  text-align: center;
  color: var(--qrencia-dark-blue);
  font-weight: 600;
  display: inline-block;
  border-bottom: 3.5px solid var(--qrencia-dark-blue);
  padding-bottom: 4px;
  font-size: 28px;

}


.termsAndConditionsMainContainer
  .termsAndConditionsSubContainer
  .termsAndConditionsDate {
  width: 100%;
  text-align: right;
  margin-top: 1%;
  font-size: 0.9em;
  color: var(--qrencia-text-black);
  margin-bottom: 20px;
}

@media (max-width: 1112px) {
  .termsAndConditionsMainContainer {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 480px) {
  .termsAndConditionsMainContainer {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 480px) {
  .termsAndConditionsMainContainer .termsAndConditionsSubContainer {
    margin-bottom: 15px;
  }
  .termsAndConditionsMainContainer
  .termsAndConditionsSubContainer
  .termsAndConditionsDate {
  font-size: 0.8em;
}
}

@media (max-width: 767px) {
  .termsAndConditionsMainContainer
    .termsAndConditionsSubContainer
    .termsAndConditionsHeading
    .termsAndConditionsHeadingTitle {
    font-size: 1.3em;
  }

  .termsAndConditionsMainContainer
    .termsAndConditionsSubContainer
    .termsAndConditionsHeading
    .termsAndConditionsDate {
    font-size: 12px;
  }

  p {
    font-size: 0.8em;
  }
  .termsAndConditionsDescription>ul>li {
    font-size: 0.8em;
  }
  .termsAndConditionsDescription>ol>li {
    font-size: 0.8em;
  }

  .termsAndConditionsHeadingTitle>p>strong,.termsAndConditionsDate>p>strong,.termsAndConditionsDescription>p>strong {
    font-size: 16px;
  }
  .termsAndConditionsHeadingTitle>h1>span>u>strong,.termsAndConditionsDate>h1>span>u>strong,.termsAndConditionsDescription>h1>span>u>strong {
    font-size: 16px;
  }
  .termsAndConditionsHeadingTitle>p>span,.termsAndConditionsDate>p>span,.termsAndConditionsDescription>p>span {
    font-size: 1.1em;
  }
}
@media (min-width: 992px) {
  .termsAndConditionsMainContainer {
    text-align: justify;
  }
}
@media (min-width: 1500px) {
  .termsAndConditionsMainContainer {
    padding: 0% 10%;

  }
}
@media (min-width:767px) and (max-width: 1499px) {
  .termsAndConditionsMainContainer {
    padding: 0% 8%;
  }
}
@media(max-width:767px){
  .termsAndConditionsMainContainer {
    padding: 0% 5%;
  }
}
@media(min-width:767px) and (max-width:991px){
  .termsAndConditionsMainContainer
  .termsAndConditionsSubContainer
  .termsAndConditionsHeading
  .termsAndConditionsHeadingTitle {
  font-size: 1.5em;
}

.termsAndConditionsMainContainer
  .termsAndConditionsSubContainer
  .termsAndConditionsHeading
  .termsAndConditionsDate {
  font-size: 12px;
}

p {
  font-size: 0.9em;
}
.termsAndConditionsDescription>ul>li {
  font-size: 0.9em;
}
.termsAndConditionsDescription>ol>li {
  font-size: 0.9em;
}

.termsAndConditionsHeadingTitle>p>strong,.termsAndConditionsDate>p>strong,.termsAndConditionsDescription>p>strong {
  font-size: 16px;
}
.termsAndConditionsHeadingTitle>h1>span>u>strong,.termsAndConditionsDate>h1>span>u>strong,.termsAndConditionsDescription>h1>span>u>strong {
  font-size: 20px;
}
.termsAndConditionsHeadingTitle>p>span,.termsAndConditionsDate>p>span,.termsAndConditionsDescription>p>span {
  font-size: 1.2em;
}
}

@media(min-width:991px) and (max-width:1290px){
  .termsAndConditionsMainContainer
  .termsAndConditionsSubContainer
  .termsAndConditionsHeading
  .termsAndConditionsHeadingTitle {
  font-size: 1.5em;
}

.termsAndConditionsMainContainer
  .termsAndConditionsSubContainer
  .termsAndConditionsHeading
  .termsAndConditionsDate {
  font-size: 12px;
}

p {
  font-size: 0.95em;
}
.termsAndConditionsDescription>ul>li {
  font-size: 0.95em;
}
.termsAndConditionsDescription>ol>li {
  font-size: 0.95em;
}

.termsAndConditionsHeadingTitle>p>strong,.termsAndConditionsDate>p>strong,.termsAndConditionsDescription>p>strong {
  font-size: 16px;
}
.termsAndConditionsHeadingTitle>h1>span>u>strong,.termsAndConditionsDate>h1>span>u>strong,.termsAndConditionsDescription>h1>span>u>strong {
  font-size: 20px;
}
.termsAndConditionsHeadingTitle>p>span,.termsAndConditionsDate>p>span,.termsAndConditionsDescription>p>span {
  font-size: 1.2em;
}
}