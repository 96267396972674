.weakSectionContainer {
    height: 100vh;
    width: 100%;
    background-color: var(--performance-weak-section-bg);
}

.topHeadingContainer {
    width: 80%;
    padding: 5% 5em 2em 5em;
    height: 30vh;
    display: flex;
    align-items: center;
}

.headingText {
    font-size: 3.2em;
    color: var(--qrencia-grey-text-color);
}

.chartContainer {
    padding: 2% 4em 2% 5em;
    height: 70vh;
}

.weakAspectchart{
    width: 90%;
}

@media (min-width: 768px) and (max-width: 1200px) {
    .topHeadingContainer {
        width: 100%;
        padding: 5% 3em 2em 5em;
    }
    .headingText {
        font-size: 2.8em;
    }
    .chartContainer {
        padding: 5% 5em;
    }
}

@media (min-width: 580px) and (max-width: 768.5px) {
    .topHeadingContainer {
        width: 100%;
        padding: 2.5em;
    }

    .headingText {
        font-size: 2.8em;
        text-align: center;
    }

    .chartContainer {
        padding: 2em 3em;
    }
}

@media (max-width: 580.5px) {
    .topHeadingContainer {
        width: 100%;
        padding: 1em;
    }

    .headingText {
        font-size: 2.1em;
        text-align: center;
    }

    .chartContainer {
        padding: 1em 1.2em;
    }
}