.contentMainContainer {
    background-color: var(--qrencia-white);
    box-shadow: 4px 4px 18px rgba(137, 141, 154, .3);
    border-radius: 20px;
    padding: 1em 1em 1.3em 1em;
}

.contentTabContainer {
    padding-top: 0.5em;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    justify-content: space-around;
}

.tabItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    width: calc(20% - 30px);
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    margin: 0 2.5%;
    border-bottom: 5px solid #f0f0f0;
}

.tabItem:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}

.tabItemActive {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    border-bottom: 5px solid var(--qrencia-dark-blue);
}

.tabItemActive .tabTitleText{
    color: var(--qrencia-dark-blue) !important;
}

.tabItem .tabIconImage {
    opacity: 0.8;
}

.tabItem:hover .tabIconImage {
    opacity: 1;
}

.tabItemActive .tabIconImage {
    opacity: 1;
}

.tabIconImage {
    width: 35px;
    height: 35px;
    margin-bottom: 0.5em;
}

.tabTitleText {
    font-size: 0.9em;
    font-weight: 400 !important;
    text-align: center;
    margin-bottom: 0.8em;
}

.yourPassionSectionContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    justify-content: space-between;
    padding: 0.5em 1em 0 1em;
}

.contentSection {
    width: 45%;
    padding: 1.2em 0.8em 0.5em 0.8em;
    max-height: 100%;
}

.contentSection ul {
    padding-left: 1em;
    margin-bottom: 1em;
}

.contentSection li {
    margin-bottom: 0.5em;
}

.contentTitle {
    font-size: 1.5em;
    font-weight: 600 !important;
    margin-bottom: 1em;
}

.contentDescription {
    font-size: 0.9em;
}

.mediaSectionImageContainer{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.mediaSection {
    width: 45%;
    padding: 1em 0.5em 0 0;
    max-height: 100%;
}

.contentMediaFile {
    width: 80%;
}

.contentButton {
    font-size: 0.85em;
    padding: 6px 18px;
    border-radius: 3px;
}

/* video styles */

.videoThumbnailImage {
    cursor: pointer;
    width: 80%;
    border-radius: 12px;
}

.thumbnailImage{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
}

.positionRelative {
    position: relative;
}

.videoPlayIcon {
    width: 3em;
    position: absolute;
    cursor: pointer;
    left: 50%;
    top: 50%;
    transform: translate(55%, -55%);
}

@media(min-width:1400px) {
    .mediaSection {
        width: 45%;
    }

    .contentSection {
        width: 50%;
    }

    .contentTitle {
        font-size: 1.45em;
    }

    .contentDescription {
        font-size: 0.94em;
    }

    .contentMainContainer{
        padding: 1em 2.5em 1.3em 2.5em;
    }

    .contentMediaFile {
        width: 90%;
    }

    .videoThumbnailImage {
        width: 90%;
    }
}

@media(min-width:1801px) and (max-width:2100px) {
    .contentMainContainer{
        padding: 1.2em 3.5em 1.4em 3.5em;
    }

    .contentDescription {
        font-size: 1em;
    }

    .mediaSection {
        width: 42%;
    }

    .contentSection {
        width: 55%;
    }

    .contentTitle {
        font-size: 1.7em;
    }
}

@media(min-width:1540px) and (max-width:1800px) {
    .contentMainContainer{
        padding: 1.2em 3em 1.4em 3em;
    }

    .contentDescription {
        font-size: 0.94em;
    }

    .mediaSection {
        width: 42%;
    }

    .contentSection {
        width: 55%;
    }

    .contentTitle {
        font-size: 1.6em;
    }
}

@media(min-width:1240px) and (max-width:1400px) {
    .mediaSection {
        width: 48%;
    }

    .contentSection {
        width: 52%;
    }
}

@media(min-width:991px) and (max-width:1240.5px) {
    .tabIconImage {
        width: 35px;
        height: 35px;
    }

    .tabTitleText {
        font-size: 0.85em;
    }

    .contentTitle {
        font-size: 1.1em;
    }

    .contentDescription {
        font-size: 0.8em;
    }

    .mediaSection {
        width: 44%;
    }

    .contentSection {
        width: 56%;
    }

    .videoPlayIcon {
        transform: translate(40%, -55%);
    }
}

@media(min-width:840px) and (max-width:991px) {
    .tabIconImage {
        width: 30px;
        height: 30px;
    }

    .tabTitleText {
        font-size: 0.8em;
    }

    .contentTitle {
        font-size: 1em;
        margin-bottom: 1em;
    }

    .contentDescription {
        font-size: 0.7em;
    }

    .contentSection {
        width: 55%;
        padding-left: 0;
        padding-right: 0.5em;
    }

    .contentButton {
        font-size: 0.76em;
        padding: 4px 16px;
    }

    .tabItem {
        width: calc(20%);
        margin: 0 2%;
    }

    .contentMediaFile {
        width: 95%;
    }

    .videoThumbnailImage {
        width: 95%;
    }

    .videoPlayIcon {
        transform: translate(-40%, -55%);
    }
}

@media(min-width:768px) and (max-width:840px) {
    .tabIconImage {
        width: 30px;
        height: 30px;
    }

    .tabTitleText {
        font-size: 0.76em;
    }

    .contentTitle {
        font-size: 1em;
        margin-bottom: 1em;
    }

    .contentDescription {
        font-size: 0.68em;
    }

    .mediaSection {
        width: 40%;
    }

    .contentSection {
        width: 60%;
        padding-left: 0;
        padding-right: 0.5em;
    }

    .contentButton {
        font-size: 0.76em;
        padding: 4px 16px;
    }

    .tabItem {
        width: calc(20%);
        margin: 0 2%;
    }

    .contentMediaFile {
        width: 100%;
    }

    .videoThumbnailImage {
        width: 100%;
    }

    .videoPlayIcon {
        transform: translate(-40%, -55%);
    }
}

@media(min-width:580px) and (max-width:767.5px) {
    .tabIconImage {
        width: 30px;
        height: 30px;
    }

    .tabTitleText {
        font-size: 0.76em;
    }

    .contentTitle {
        font-size: 1.3em;
    }

    .contentDescription {
        font-size: 0.8em;
    }

    .tabItem {
        width: calc(20%);
        margin: 0 1.5%;
    }

    .contentSection {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .mediaSection {
        width: 70%;
    }

    .contentMediaFile{
        width: 80%;
    }

    .contentButton {
        font-size: 0.72em;
        padding: 5px 18px;
    }

    .videoPlayIcon {
        transform: translate(-55%, -55%);
    }

    .thumbnailImage{
        justify-content: center;
    }

    .mediaSectionImageContainer{
        justify-content: center;
    }
}

@media(min-width:480px) and (max-width:580px) {
    .tabIconImage {
        width: 25px;
        height: 25px;
    }

    .tabTitleText {
        font-size: 0.68em;
    }

    .contentTitle {
        font-size: 1em;
    }

    .contentDescription {
        font-size: 0.7em;
    }

    .tabItem {
        width: 20%;
        margin: 0 0.8%;
    }

    .contentSection {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .mediaSection {
        width: 80%;
    }

    .contentMediaFile{
        width: 95%;
    }

    .videoThumbnailImage {
        width: 95%;
    }

    .contentButton {
        font-size: 0.72em;
        padding: 4px 16px;
    }

    .videoPlayIcon {
        transform: translate(0, -55%);
    }

    .videoPlayIcon {
        transform: translate(-55%, -55%);
    }

    .thumbnailImage{
        justify-content: center;
    }

    .mediaSectionImageContainer{
        justify-content: center;
    }
}

@media(min-width:320px) and (max-width:480px) {
    .tabIconImage {
        width: 24px;
        height: 24px;
    }

    .tabTitleText {
        font-size: 0.55em;
    }

    .contentTitle {
        font-size: 1em;
    }

    .contentDescription {
        font-size: 0.64em;
    }

    .contentMainContainer {
        padding: 1em 0.6em 1em 0.6em;
    }

    .yourPassionSectionContainer {
        padding: 1em 0 0.5em 0;
    }

    .tabItem {
        width: 20%;
        margin: 0 0.5%;
    }

    .contentSection {
        width: 100%;
        padding-bottom: 0;
    }

    .mediaSection {
        width: 95%;
        padding-right: 0;
    }

    .contentMediaFile{
        width: 97%;
    }

    .videoThumbnailImage {
        width: 97%;
    }

    .contentTabContainer {
        padding-top: 0;
    }

    .contentButton {
        font-size: 0.7em;
        padding: 4px 16px;
    }

    .videoPlayIcon {
        transform: translate(-55%, -55%);
    }

    .thumbnailImage{
        justify-content: center;
    }

    .mediaSectionImageContainer{
        justify-content: center;
    }
}

@media(max-width:767.5px) {

    .yourPassionSectionContainer {
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
    }

    .contentDescription {
        text-align: left;
    }

    .contentTitle {
        text-align: left;
    }
}