.errorContainer {
  padding: 2em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-grey-border);
  width: 80%;
  margin: 1em auto;
  text-align: center;
  background-color: var(--qrencia-white);
}

.loaderIcon {
  font-size: 3em !important;
  color: var(--qrencia-dark-blue) !important;
}

.spinner {
  animation: spin-animation 0.5s infinite;
}
.agGridMobile {
  display: none;
}
.agGridDesktop {
  display: block;
}
.doneText {
  font-weight: 500;
  color: green;
}
.inProgressText {
  font-weight: 500;
  color: var(--qrencia-dark-blue);
}
.notMarkedText {
  font-weight: 500;
  color: red;
}
@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.thumbnailSection {
  padding: 1.3%;
  display: flex;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  margin: 0.5% 0 0 0;
  border: 1px solid var(--qrencia-light-grey-border);
}

.thumbnailContainer {
  width: 12em;
}

.thumbnail {
  width: 100%;
  /* height: -webkit-fill-available; */
  object-fit: cover;
  border-radius: 10px;
  aspect-ratio: 825/550;
}

.thumbnailDetailsSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 1%;
  gap: 2px;
}

.competitionTitle {
  font-size: 1.3em;
  font-weight: 600;
}

.competitionDescription {
  font-size: 0.98em;
  font-weight: 400;
}

.location {
  display: contents;
  color: var(--qrencia-text-black);
  font-weight: 400 !important;
}

.headingSection {
  display: flex;
  margin-bottom: 1%;
  margin-top: 2%;
}

.hyphenText {
  display: contents;
}

.participantSection {
  padding: 1% 2% 3% 2%;
}

.headingSectionDiv {
  width: 70%;
}

.judingCountDiv {
  width: 30%;
}

.sectionHeading {
  font-size: 1.6em;
  font-weight: 600;
}

.participantsJudgedCount {
  color: var(--card-grey-title);
  font-size: 1em;
  text-align: end;
  margin-top: 1%;
}

@media (min-width: 991px) and (max-width: 1290px) {
  .sectionHeading {
    font-size: 1.4em;
  }

  .participantsJudgedCount {
    font-size: 0.85em;
  }

  .competitionTitle {
    font-size: 1.25em;
  }

  .competitionDescription {
    font-size: 0.94em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sectionHeading {
    font-size: 1.4em;
  }

  .participantsJudgedCount {
    font-size: 0.8em;
  }

  .headingSectionDiv {
    width: 60%;
  }

  .thumbnailContainer {
    width: 10em;
  }

  .judingCountDiv {
    width: 40%;
  }

  .competitionTitle {
    font-size: 1.2em;
  }

  .competitionDescription {
    font-size: 0.83em;
  }
}

@media (max-width: 767.5px) {
  .sectionHeading {
    font-size: 1.2em;
  }

  .participantsJudgedCount {
    font-size: 0.75em;
  }

  .headingSectionDiv {
    width: 50%;
  }

  .judingCountDiv {
    width: 50%;
  }

  .participantSection {
    padding: 6% 2% 10% 2%;
  }

  .thumbnailContainer {
    width: 10em;
  }

  .competitionTitle {
    font-size: 1em;
  }

  .competitionDescription {
    font-size: 0.8em;
  }

  .thumbnailSection {
    padding: 2.3%;
  }

  .thumbnailDetailsSection {
    padding-left: 2%;
  }

  .agGridMobile {
    display: block;
  }
  .agGridDesktop {
    display: none;
  }
}

@media (max-width: 480px) {
  .sectionHeading {
    font-size: 1.2em;
  }
  .thumbnail {
    height: -webkit-fill-available;
    object-fit: fill;
  }

  .headingSection {
    padding: 0% 1%;
  }

  .gridSection {
    padding: 0% 1%;
  }

  .participantsJudgedCount {
    font-size: 0.66em;
  }

  .headingSectionDiv {
    width: 50%;
  }

  .judingCountDiv {
    width: 50%;
  }

  .thumbnailSection {
    flex-wrap: wrap;
    margin-bottom: 5%;
  }

  .thumbnailDetailsSection {
    padding-left: 0;
    padding-top: 0.5em;
  }

  .thumbnailContainer {
    width: 100%;
    /* height: 150px; */
  }

  .thumbnailSection {
    padding: 3.3%;
  }
}
