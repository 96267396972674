.competitionSectionContainer {
    background-color: var(--performance-page-skyblue-bg);
    height: 100vh;
    width: 100%;
}

.topHeadingContainer {
    padding: 15vh 0 0 5em;
    width: 80%;
    height: 40%;
}

.headingText {
    font-size: 3em;
    color: var(--qrencia-grey-text-color);
}

.subHeadingText {
    font-size: 1.4em;
    color: var(--qrencia-dark-blue);
    padding: 2em 0 0 0;
}

.InfoBoxesContainer {
    display: flex;
    gap: 2em;
    height: 60%;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 0 1em 15vh 5em;
}

.InfoBoxesCard {
    background-color: var(--qrencia-white);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 15%;
    height: 28vh;
    transition: all 0.5s ease-out;
}

.InfoBoxesCard:hover {
    box-shadow: 0 3px 21px 0 rgba(100, 100, 100, 0.6);
}

.InfoBoxesCardYourScore {
    background-color: var(--qrencia-white);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 18.5%;
    height: 36vh;
    transition: all 0.5s ease-out;
}

.InfoBoxesCardYourScore:hover {
    box-shadow: 0 3px 21px 0 rgba(100, 100, 100, 0.6);
}

.InfoBoxesNumber {
    font-size: 2.7em;
    font-weight: 700 !important;
    color: var(--qrencia-grey-text-color);
}

.InfoBoxesText {
    font-size: 0.9em;
}

.borderTopPurple {
    border-top: 1.5em solid var(--performance-page-purple-border);
}

.borderTopGreen {
    border-top: 1.5em solid var(--performance-page-highscore-border);
}

.borderTopSkyBlue {
    border-top: 1.5em solid var(--performance-page-aqua-border);
}

.borderTopRed {
    border-top: 1.5em solid var(--performance-page-weak-aspects-bg);
}

.borderTopYellow {
    border-top: 1.5em solid var(--performance-page-midscore-border);
}

@media (min-width: 768px) and (max-width: 1024px) {
    .topHeadingContainer {
        padding: 10vh 0 0 3em;
        width: 90%;
    }

    .InfoBoxesContainer {
        display: flex;
        gap: 1em;
        padding-left: 3em;
    }

    .InfoBoxesCard {
        width: 17%;
        height: 24vh;
    }

    .InfoBoxesCardYourScore {
        width: 19%;
        height: 36vh;
    }

    .InfoBoxesNumber {
        font-size: 2.6em;
        font-weight: 700 !important;
    }

    .InfoBoxesText {
        font-size: 0.8em;
    }
}

@media (min-width: 580px) and (max-width: 768.5px) {
    .headingText {
        font-size: 2.2em;
        text-align: center;
    }

    .subHeadingText {
        text-align: center;
    }

    .topHeadingContainer {
        padding: 8vh 0 0 3em;
        width: 90%;
    }

    .InfoBoxesContainer {
        display: flex;
        gap: 1em;
        width: 100%;
        flex-wrap: wrap;
        padding-bottom: 4vh;
        justify-content: center;
        align-content: center;
        padding: 0 2em 1em 2em;
    }

    .InfoBoxesCard {
        width: 9em;
        height: 20vh;
    }

    .InfoBoxesCardYourScore {
        width: 9em;
        height: 20vh;
    }

    .InfoBoxesNumber {
        font-size: 2.2em;
        font-weight: 700 !important;
    }

    .InfoBoxesText {
        font-size: 0.8em;
    }
}

@media (max-width: 580.5px) {
    .headingText {
        font-size: 2em;
        text-align: center;
    }

    .subHeadingText {
        font-size: 1em;
        padding: 0.5em 0 0 0;
        text-align: center;
    }

    .topHeadingContainer {
        padding: 10% 10px 0 10px;
        width: 100%;
        height: 35%;
    }

    .InfoBoxesContainer {
        display: flex;
        gap: 1em;
        width: 100%;
        flex-wrap: wrap;
        padding-bottom: 4vh;
        justify-content: center;
        align-content: center;
        padding: 0 1.5em 1em 1.5em;
        height: 65%;
    }

    .InfoBoxesCard {
        width: 8em;
        height: 18vh;
        padding: 5px;
    }

    .InfoBoxesCardYourScore {
        width: 9em;
        height: 18vh;
        padding: 5px;
    }

    .InfoBoxesNumber {
        font-size: 2em;
        font-weight: 600 !important;
    }

    .InfoBoxesText {
        font-size: 0.8em;
    }
}