.mainContainer {
  padding: 1em;
  background-color: var(--qrencia-white);
  border-radius: 10px;
}

.mainHeadingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.mainHeading {
  font-size: 1.025em;
  margin: 0 0 0 0.6em;
  font-weight: 600 !important;
  color: var(--qrencia-text-black);
}

.downloadButton {
  font-size: 0.75em;
  padding: 2px 8px;
  margin-bottom: 4px;
}
.viewAttachmentButton {
  font-size: 0.75em;
  padding: 2px 8px;
  margin-top: 1em;
  cursor: pointer;
}
.detailHeading {
  font-size: 0.8em;
  font-weight: 500 !important;
}
.responseSeparator {
  width: 95%;
  height: 0.4px;
  background-color: var(--qrenica-nudge-differentiator-color);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5em;
  opacity: 0.2;
}
.linkText {
  text-decoration: none !important;
}
.offeringsContainer {
  padding: 1em 1em 0.3em 1em;
  border-top: 1px solid rgb(231, 231, 231);
}
.reviews {
  font-size: 0.88em;
}

.detailHeadingBlue {
  font-size: 0.8em;
  font-weight: 500 !important;
  color: var(--qrencia-dark-blue) !important;
}

.detailSubHeading {
  font-size: 0.76em !important;
  margin-bottom: 1em;
}

.detailSubHeading p {
  font-size: 0.95em;
}
.expertCardContainer {
  background-color: var(--qrencia-nudges-card-background);
  border: 1px solid var(--qrencia-nudges-card-border);
  border-radius: 8px;
  padding: 0.6em;
}

.responsesContainer {
  padding-left: 0.85em;
}

.responsesHeading {
  font-size: 0.9em;
  font-weight: 500 !important;
  margin-bottom: 0.5em;
}

.columnPadding {
  padding: 0 0.8em 0.8em 0;
}

.cardsPadding {
  padding: 0 5px;
}

.profilePicMainCnt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: nowrap;
  gap: 8px;
  cursor: pointer;
}
.showExpertFileButton {
  font-size: 0.8em;
  margin-top: 1em;
  cursor: pointer;
}
.profilePicCnt {
  margin: 1em 0.5em;
}

.profilePicPreviewCnt {
  display: flex;
  align-items: center;
}

.previewProfilePic {
  display: block;
  width: 50px !important;
  height: 50px !important;
}

.previewProfilePicOnModal {
  display: block;
  width: 36px !important;
  height: 36px !important;
  object-fit: cover;
}

.initialProfilePreview {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid var(--qrencia-white);
  font-size: 1.3em !important;
  justify-content: center;
  align-items: center;
  color: var(--qrencia-dark-blue);
  background-color: #e5e5e5;
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}

.initialProfilePreviewOnModal {
  display: flex;
  width: 36px;
  height: 36px;
  /* border-radius: 100%; */
  font-size: 0.9em !important;
  justify-content: center;
  align-items: center;
  color: var(--qrencia-dark-blue);
  background-color: #e5e5e5;
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}

.profilePicPreviewDetails {
  display: block;
}

.profilePicPreviewDetails p {
  margin-bottom: 0 !important;
}

.viewResponseButton {
  font-size: 0.75em;
  text-align: center;
  margin-left: auto;
  padding: 1px 8px 0.5px 8px;
}

.expertCardName {
  font-size: 0.85em;
  font-weight: 450 !important;
}

.loadMoreContainer {
  text-align: center;
}

.expertNameContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  align-items: center;
}

.expertName {
  font-size: 0.9em;
  font-weight: 550 !important;
  color: var(--qrencia-dark-blue) !important;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loaderContainer svg {
  font-size: 3em !important;
  color: var(--qrencia-dark-blue) !important;
}

.modalHeader {
  padding: 0.5em 1em;
}

.modalHeader p {
  font-size: 0.9em !important;
  font-weight: 500 !important;
}

.ratingStars {
  color: var(--qrencia-golden-color) !important;
  font-size: 1.4em !important;
  margin: 0;
}
.expertRatingStarts {
  color: var(--qrencia-golden-color) !important;
  font-size: 0.9em !important;
  margin: 0;
  margin-top: -1em;
}
.semicolon {
  color: var(--qrencia-text-black) !important;
  font-weight: 450 !important;
}

.submitRatingIconContainer {
  display: inline;
}

.submitRatingIcon {
  margin: 0 0 4px 0.5em;
  font-size: 1.4em;
  color: var(--qrencia-dark-blue);
  cursor: pointer;
}

.submitRatingIconSuccess {
  margin: 0 0 4px 0.5em;
  font-size: 1.4em;
  color: var(--qrencia-success-green);
  cursor: pointer;
}

.submitRatingIconError {
  margin: 0 0 4px 0.5em;
  font-size: 1.4em;
  color: var(--qrencia-error-red);
  cursor: pointer;
}

.ratingTitle {
  font-size: 0.7em;
  display: inline;
  margin-right: 10px;
  color: var(--qrencia-placeholder-color);
}
.responseContainer {
  background-color: var(--qrencia-nudges-card-background);
}
.responseCardContainer {
  position: relative;
}

.responseCard {
  padding: 1em 1em 2em 1em;
  border-radius: 8px;
  border-top-left-radius: 0;
  background-color: var(--qrencia-nudges-card-background);
  margin: 0 0 1em 1em;
}

.responseCard p,
.responseCard ul,
.responseCard ol {
  font-size: 0.76em !important;
}
.studentResponseCard {
  margin-bottom: 10px;
  padding-top: 2em;
  padding-left: 1.5em;
}
.studentResponseCard p,
.studentResponseCard ul,
.studentResponseCard ol {
  font-size: 0.76em !important;
}

.responseCard::before {
  content: "";
  border-width: 0px 14px 16px 0px;
  border-style: solid;
  border-color: transparent #fff;
  position: absolute;
  top: 0;
  left: 2px;
  transform: rotate(180deg);
  background-color: var(--qrencia-nudges-card-background);
}

.responseDateAndTime {
  font-size: 0.7em;
  font-weight: 400 !important;
  position: absolute;
  bottom: 0.5em;
  right: 1.2em;
  color: var(--qrencia-placeholder-color);
}
.studentResponseDateTime {
  font-size: 0.7em;
  font-weight: 400 !important;
  position: absolute;
  top: 0.8em;
  left: 2em;
  color: var(--qrencia-placeholder-color);
}
.bottomContainer {
  margin: 0 0;
  display: flex;
  gap: 2.2%;
}

.detailsGroup {
  display: inline-flex;
  padding: 0;
  align-items: center;
  margin-bottom: 0.5em;
}

.detailsIconSection {
  padding: 8.5px 9px;
  margin-right: 5px;
  background-color: var(--qrencia-nudges-card-text-bg);
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.detailsIcon {
  font-size: 13px !important;
  color: var(--qrencia-dark-blue);
}

.detailsSubsection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.detailsHeading {
  font-size: 0.75em;
  font-weight: 400 !important;
  margin-top: -2px;
}

.detailsSubHeading {
  font-size: 0.75em;
  font-weight: 500 !important;
}

.downloadButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.descriptionText p,
.descriptionText ul,
.descriptionText ol {
  color: var(--qrencia-dark-blue) !important;
  font-size: 0.75em;
  font-weight: 400 !important;
}
.exploreOfferingsHeading {
  font-size: 0.85em;
  margin-top: 5px;
}
@media (max-width: 400px) {
  .responseCard {
    font-size: 0.8em !important;
    line-height: 21px;
  }

  .downloadButton {
    font-size: 0.68em;
    padding: 2px 8px;
    margin-bottom: 4px;
  }
}

@media (max-width: 991.5px) {
  .ratingTitle {
    display: block;
  }
  .bottomContainer {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .semicolon {
    display: none;
  }

  .bottomContainer {
    flex-wrap: wrap;
  }
}

@media (max-width: 425px) {
  .mainContainer {
    padding: 1em 0.5em;
  }

  .detailHeading {
    font-size: 0.75em;
  }

  .detailHeadingBlue {
    font-size: 0.75em;
  }

  .detailSubHeading {
    font-size: 0.7em !important;
  }

  .detailsHeading {
    font-size: 0.7em;
  }

  .detailsSubHeading {
    font-size: 0.7em;
  }

  .detailsIconSection {
    padding: 7.5px 8px;
  }

  .expertNameContainer {
    flex-wrap: wrap;
    gap: 1em;
  }

  .responseCard {
    font-size: 0.9em !important;
  }

  .descriptionText p,
  .descriptionText ul,
  .descriptionText ol {
    font-size: 0.7em;
  }
}
.exploreOfferingsHeading {
  font-size: 0.8em;
  margin-top: 5px;
  /* margin-bottom: 10px; */
}

/* ----------- Expert Exclusive styles ----------- */

.formErrorMessage {
  color: var(--qrencia-error-red);
  font-size: 0.8em;
  margin-bottom: 0;
}

.submitButtonContainer {
  text-align: center;
  padding: 1em 0;
}

.submitButton {
  font-size: 0.8em;
}

.previousResponsesContainer {
  margin-top: 0.5em;
  padding: 1em 0 0.6em;
  border-top: 1px solid rgb(239, 239, 239);
}

.fallBackMessage {
  padding: 3em 1em;
  margin: 0;
  font-size: 0.8em;
  text-align: center;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  color: var(--qrencia-grey-text);
  font-style: italic;
  font-weight: 400 !important;
  border: 1px solid var(--qrencia-cards-border);
}

@media (max-width: 520px) {
  .submitButton {
    font-size: 0.85em;
    padding: 2px 10px;
  }
}

@media (max-width: 400px) {
  .fallBackMessage {
    font-size: 0.75em;
  }
}
