.cardContainer {
  height: fit-content;
  padding: 0.8em;
  margin: 1em 0em;
  background-color: var(--qrencia-white);
  box-shadow: var(--qrencia-template-box-shadow);
  transition: 0.5s all ease-in-out;
  border: 1px solid var(--qrencia-certificate-preview-border);
}
.questionContainer {
  display: flex;
  outline: none;
  align-items: flex-start;
  justify-content: space-between;
}
.question {
  font-size: 0.8em;
  flex-grow: 1;
  max-width: 95%;
  font-weight: 500;
  color: var(--qrencia-text-black);
}
.cardContainer:hover {
  box-shadow: 0 3px 21px 0 rgba(100, 100, 100, 0.2);
}
.addIcon {
  font-size: 0.9em;
  font-weight: 600;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  margin-top: 0.2em;
}
.crossIcon {
  transform: rotate(45deg);
}
.answerContainer {
  font-size: 0.8em;
  margin-top: 01em;
}
.respondedOnSection {
  font-size: 0.9em;
  text-align: right;
  margin-top: 1em;
}
.viewButton {
  font-size: 0.75em;
  margin-top: 0.8em;
}
.quillContainer {
  margin-top: 1em;
}
.submitButton {
  font-size: 0.9em;
  margin-top: 0.8em;
  margin-right: 1em;
}
.modalErrorIcon {
  font-size: 3em;
  color: var(--qrencia-error-red);
  text-align: center;
}

.modalAlertText {
  font-size: 0.9em;
  text-align: center;
}

.modalBody {
  padding: 1.5em 1em 1em;
}

.modalFooter {
  display: flex;
  justify-content: center;
  padding: 0.5em 1em 1.5em 1em;
  border: none;
  gap: 1em;
}
.modalCloseButton,
.modalCloseButton:hover,
.modalCloseButton:active,
.modalCloseButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalAlertIcon {
  font-size: 3em;
  color: var(--cancel-red-button);
  text-align: center;
}

.modalSuccessIcon {
  font-size: 3em;
  color: var(--qrencia-success-green);
  text-align: center;
}

@media (max-width: 990px) {
  .answer p {
    font-size: 1em;
  }
  .textEditor p {
    font-size: 1em;
  }
}
@media (max-width: 600px) {
  .respondedOnSection {
    font-size: 0.8em;
  }
}
