.highLightsContainer{
    display: flex;
    padding:3% 13%;
}
.imageContainer{
    width: 50%;
}
.highLight{
    width: 50%;
}
.highLightBulletPoints>ul{
    font-size: 0.8em;
    margin-top: 0.9em;
}
.highLighText{
    color: var(--qrencia-dark-blue);
    font-weight: 600;
    line-height: 1.2em;
    font-size: 1.5em;
}
.qTalkEventImage{
    width: 100%;
}
.orangeFrame {
    margin-bottom: -95px;
}

.blueFrame {
    text-align: right;
    margin-top: -95px;
}
