.paddingLeftZero {
  padding-left: 0 !important;
}

.competitionsCardSection {
  display: flex;
}

.cardBody {
  padding: 0;
}

.competitionCard {
  padding: 0.8em;
  max-width: 15em;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.competitionCard img {
  height: 7.9em;
}

.cardImage {
  border-radius: 8px;
}

.calendarIconOnMicrosite {
  margin: 1px 2px 0 0;
}
.endDateIcon {
  font-size: 1.2em !important;
  margin-left: -1px;
}
.competitionName {
  display: flex;
  justify-content: space-between;
  font-weight: 600 !important;
  margin: 0.5em 0 0.3em 0;
  font-size: 0.9em;
}

.uniqueIdText {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 0.7em;
  padding: 0 2px;
  border-radius: 4px;
  font-weight: 500 !important;
  background-color: var(--qrencia-white);
}

.compTimeAndDate {
  font-size: 0.6em;
  display: flex;
  font-weight: 600 !important;
  margin: 0.4em 0;
}

.buttonSection {
  margin: 0.4em 0;
  display: flex;
  gap: 1em;
}

.viewButton {
  font-size: 0.75em;
  padding: 4px;
  width: 7em;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  text-align: center;
  font-weight: 400 !important;
  border-radius: 3px;
  margin: 0.3em 0;
}

.cancelButton,
.cancelButton:hover,
.cancelButton:active,
.cancelButton:focus {
  font-size: 0.75em;
  padding: 4px;
  width: 7em;
  background-color: var(--cancel-red-button) !important;
  color: var(--qrencia-white) !important;
  text-align: center;
  font-weight: 400 !important;
  border-radius: 3px;
  margin: 0.3em 0;
  border: none;
}

.modalYesButton,
.modalYesButton:hover,
.modalYesButton:active,
.modalYesButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--qrencia-success-green) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalNoButton,
.modalNoButton:hover,
.modalNoButton:active,
.modalNoButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--cancel-red-button) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalCloseButton,
.modalCloseButton:hover,
.modalCloseButton:active,
.modalCloseButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalAlertIcon {
  font-size: 3em;
  color: var(--cancel-red-button);
  text-align: center;
}

.modalSuccessIcon {
  font-size: 3em;
  color: var(--qrencia-success-green);
  text-align: center;
}

.modalErrorIcon {
  font-size: 3em;
  color: var(--qrencia-error-red);
  text-align: center;
}

.modalAlertText {
  font-size: 0.9em;
  text-align: center;
}

.modalBody {
  padding: 1.5em 1em 1em;
}

.modalFooter {
  display: flex;
  justify-content: center;
  padding: 0.5em 1em 1.5em 1em;
  border: none;
  gap: 1em;
}

.competitionsUserProfileImageSection {
  display: flex;
  gap: 5px;
}

.competitionsEnrolledCountSection {
  font-weight: 600;
  font-size: 0.65em;
  width: 64%;
  margin-top: 0.5em;
  width: max-content;
}

.onlineIcon {
  margin-left: auto;
  color: var(--qrencia-dark-blue) !important;
}

.profileImage {
  width: 22px;
  height: 22px !important;
  right: 2%;
  border-radius: 18px;
  border: 1px solid var(--qrencia-white);
  position: relative;
  margin-right: -12%;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
}

.shareIconPannelContainer {
  display: grid;
  position: absolute;
  right: -1.15em;
  top: 40%;
  gap: 5px;
}

.shareIcon {
  font-size: 1.2em;
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  border-radius: 20px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 7em;
  padding: 2px;
}

.shareIcons {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnInstagramButton {
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  color: var(--qrencia-white);
  font-size: 1em;
  cursor: pointer;
}

.shareOnMailButton {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1em;
  cursor: pointer;
}

.copyToClipboard {
  color: var(--qrencia-card-blue);
  font-size: 1em;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  font-size: 1em;
  font-weight: bold;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.competitionCard a,
p {
  text-decoration: none;
  color: var(--qrencia-text-black);
  font-weight: 600;
}

.loadMoreDiv {
  padding: 1em;
}

.loadMore {
  text-align: center;
}

.loadMorebtn {
  background: var(--qrencia-white);
  color: black;
  padding: 4px 1em;
  text-decoration: none;
  border-radius: 1em;
  font-size: 0.8em;
  font-weight: 500;
  border: 1px solid var(--qrencia-dark-grey-border) !important;
}

.loadMorebtn:hover {
  color: var(--qrencia-text-black);
  background: var(--qrencia-grey-border);
}

@media (min-width: 992px) and (max-width: 1290px) {
  .ageRange {
    background: var(--qrencia-grey-border);
    border-radius: 1.2rem;
    font-size: 0.68em;
    font-weight: 600;
    padding: 1.4% 4%;
    text-align: center;
  }

  .competitionName {
    font-weight: 600 !important;
    text-align: left;
    font-size: 0.9em;
  }

  .competitionCard img {
    height: 8em;
  }
  .shareIcon {
    right: 18px;
    top: 5.8em;
    padding: 2px;
  }
  .uniqueIdText {
    top: 20px;
    right: 20px;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .ageRange {
    background: var(--qrencia-grey-border);
    border-radius: 1.2rem;
    font-size: 0.68em;
    font-weight: 600;
    padding: 1.4% 4%;
    text-align: center;
  }

  .competitionName {
    font-size: 0.9em;
  }

  .competitionCard img {
    height: 8em;
  }
  .shareIcon {
    right: 20px;
    top: 6.2em;
    padding: 2px;
  }
  .uniqueIdText {
    top: 20px;
    right: 22px;
  }
}

@media (max-width: 767px) {
  .ageRange {
    background: var(--qrencia-grey-border);
    border-radius: 1.2rem;
    font-size: 0.7rem;
    font-weight: 700;
    padding: 1.2% 4%;
  }

  .competitionCard img {
    height: 8.2em;
  }
  .shareIcon {
    right: 20px;
    top: 6.2em;
    padding: 2px;
  }
  .uniqueIdText {
    top: 20px;
    right: 20px;
  }
}

@media (max-width: 580px) {
  .competitionCard img {
    height: 8.2em;
  }

  .ageRange {
    border-radius: 1.2rem;
    font-size: 0.68em;
    font-weight: 600;
    padding: 1.4% 5%;
    text-align: center;
  }

  .competitionName {
    font-size: 0.8em;
  }

  .competitionCard {
    margin-top: 0%;
    margin-bottom: 6%;
  }
  .shareIcon {
    right: 30px;
    top: 8em;
    padding: 2px;
  }
  .uniqueIdText {
    top: 30px;
    right: 30px;
  }
}

@media (max-width: 480px) {
  .loadMorebtn {
    font-size: 0.8em;
  }
  .shareIcon {
    right: 25px;
    top: 8.2em;
    padding: 2px;
  }
  .uniqueIdText {
    top: 25px;
    right: 25px;
  }
}

.spinner {
  animation: spin-animation 0.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@media (min-width: 992px) and (max-width: 1180px) {
  .shareIcon {
    right: 18px;
    top: 5em;
    padding: 2px;
  }
}
