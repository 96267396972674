.exploreSkillIcon {
  font-size: 1.6em;
  margin-right: 9%;
  margin-left: 5%;
}

.competitionAndBerylSection {
  display: inline-flex;
  background-color: var(--qrencia-beryl-background);
  padding: 6px 12px 10px 12px;
  border-radius: 30px;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.statusWrapper {
  display: inline-flex;
  margin-left: 1%;
  border-radius: 10px;
  text-align: center;
  padding: 0 5px;
  position: absolute;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  color: var(--qrencia-text-black);
  font-weight: bold;
  text-decoration: none;
  border: 2px solid transparent;
  z-index: 1;
  transition: all 0.3s ease-in-out;

}

.statusWrapper::before {
  content: "";
  position: absolute;
  inset: -2px;
  border-radius: 5px;
  background: linear-gradient(45deg, var(--qrencia-button-pink), var(--qrencia-golden-color), var(--qrencia-blue-background-course-cost));
  background-size: 300%;
  z-index: -1;
  animation: animateBorder 4s ease infinite;
}

.statusWrapper::after {
  content: "";
  position: absolute;
  inset: 0;
  /* Matches button background */
  border-radius: 6px;
  z-index: -1;
}

@keyframes animateBorder {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.statusChipText {
  font-weight: 500;
  font-size: 0.6rem;
}

.competitionSection {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.competitionCount {
  color: var(--qrencia-beryls-count-purple);
  font-weight: 600 !important;
  font-size: 1.6rem !important;
  margin-bottom: -5px;
}

.logoImages {
  height: 18px;
  width: 18px;
}

.competitionText {
  font-weight: 600 !important;
  font-size: 0.75rem !important;
}

.berylSeprationBorder {
  background: var(--qrencia-beryl-sepration-border);
  padding: 16px 1px;
  margin: 0 3px;
  opacity: 0.2;
}

@media (min-width: 991px) and (max-width: 1290px) {
  .competitionCount {
    color: var(--qrencia-beryls-count-purple);
    font-weight: 600 !important;
    font-size: 1.1rem !important;
  }

  .competitionText {
    font-weight: 600 !important;
    font-size: 0.7rem !important;
  }

  .berylSeprationBorder {
    background: var(--qrencia-beryl-sepration-border);
    padding: 16px 1px;
    /* margin: 0 0.6em; */
    opacity: 0.2;
  }
}

@media (min-width: 550px) and (max-width: 562px) {
  .statusChipText {
    font-size: 0.6rem;
  }
}

@media (min-width: 541px) and (max-width: 550px) {
  .statusWrapper {
    padding: 0 6px;
    margin-left: 0;
  }
}

@media (min-width: 991px) and (max-width: 1180px) {
  .competitionCount {
    color: var(--qrencia-beryls-count-purple);
    font-weight: 600 !important;
    font-size: 1rem !important;
  }

  .competitionText {
    font-weight: 600 !important;
    font-size: 0.6rem !important;
  }

  .berylSeprationBorder {
    background: var(--qrencia-beryl-sepration-border);
    padding: 16px 1px;
    opacity: 0.2;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .competitionCount {
    color: var(--qrencia-beryls-count-purple);
    font-weight: 600 !important;
    font-size: 1rem !important;
  }

  .competitionText {
    font-weight: 600 !important;
    font-size: 0.6rem !important;
  }

  .berylSeprationBorder {
    background: var(--qrencia-beryl-sepration-border);
    padding: 16px 1px;
    opacity: 0.2;
  }
}

@media (max-width: 768px) {
  .competitionCount {
    color: var(--qrencia-beryls-count-purple);
    font-weight: 600 !important;
    font-size: 1rem !important;
  }

  .competitionText {
    font-weight: 600 !important;
    font-size: 0.6rem !important;
  }

  .berylSeprationBorder {
    background: var(--qrencia-beryl-sepration-border);
    padding: 16px 1px;
    opacity: 0.2;
  }
}