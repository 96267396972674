.topScoreSectionContainer {
    width: 100%;
    height: 100vh;
    background-color: var(--performance-page-top-score-orange-bg);
}

.topHeadingContainer {
    width: 44em;
    padding: 4em 0 0 5em;
    height: 25vh;
}

.topHeading {
    font-size: 3.2em;
    color: var(--qrencia-grey-text-color);
}

.bottomContainer {
    width: 100%;
    display: flex;
    height: 75vh;
}

.leftImagesContainer {
    width: 40%;
    position: relative;
}

.leftCharacterImage {
    position: absolute;
    bottom: 0;
    left: 2em;
    width: 39em;
}

.rightContentContainer {
    width: 60%;
    display: flex;
    justify-content: center;
    gap: 1em;
    align-items: flex-end;
    padding-bottom: 13%;
    padding-right: 5%;
}

.scoreCard {
    background: var(--qrencia-white);
    width: 15em;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    transition: all 0.5s ease-out;
}

.scoreCard:hover {
    box-shadow: 0 3px 21px 0 rgba(100, 100, 100, 0.6);
}

.lowScoreCard {
    border-top: 20px solid var(--performance-page-lowscore-border);
    height: 45%;
}

.highScoreCard {
    border-top: 20px solid var(--performance-page-midscore-border);
    height: 80%;
}

.midScoreCard {
    border-top: 20px solid var(--performance-page-highscore-border);
    height: 60%;
}

.scoreHeading {
    font-size: 1.2em;
    font-weight: 700;
    word-break: break-word;
    text-align: center;
}

.scoreSubHeading {
    font-size: 0.9em;
    font-weight: 400;
    word-break: break-word;
    text-align: center;
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .leftCharacterImage {
        position: absolute;
        bottom: 0;
        left: 2em;
        width: 32em;
    }

    .scoreCard {
        width: 13em;
    }
}

@media (min-width: 1024px) and (max-width: 1126.5px) {
    .leftCharacterImage {
        position: absolute;
        bottom: 0;
        left: 1em;
        width: 27em;
    }

    .scoreCard {
        width: 13em;
    }
}

@media (min-width: 1126px) and (max-width: 1200.5px) {
    .leftCharacterImage {
        position: absolute;
        bottom: 0;
        left: 1em;
        width: 29em;
    }

    .scoreCard {
        width: 13em;
    }
}

@media (min-width: 768px) and (max-width: 1024.5px) {
    .topHeadingContainer {
        padding: 4em 0 0 3em;
        height: 25vh;
    }

    .topHeading {
        font-size: 3em;
    }

    .rightContentContainer {
        width: 100%;
        justify-content: center;
        padding: 0 0 20vh 0;
    }

    .leftImagesContainer {
        display: none;
    }

    .leftCharacterImage {
        display: none;
    }

    .scoreCard {
        width: 13em;
    }

    .scoreHeading {
        font-size: 1em;
    }

    .scoreSubHeading {
        font-size: 0.8em;
    }
}

@media (min-width: 580px) and (max-width: 768.5px) {
    .topHeadingContainer {
        padding: 14vh 1em 0 1em;
        height: 25vh;
        width: 100%;
    }

    .topHeading {
        font-size: 2.6em;
        text-align: center;
    }

    .rightContentContainer {
        width: 100%;
        justify-content: center;
        padding: 0 0 16vh 0;
    }

    .leftImagesContainer {
        display: none;
    }

    .leftCharacterImage {
        display: none;
    }

    .scoreCard {
        width: 10em;
    }

    .scoreHeading {
        font-size: 1em;
    }

    .scoreSubHeading {
        font-size: 0.8em;
    }
}

@media (max-width: 580.5px) {
    .topHeadingContainer {
        padding: 6vh 1em 0 1em;
        height: 25vh;
        width: 100%;
    }

    .topHeading {
        font-size: 2em;
        text-align: center;
    }

    .rightContentContainer {
        width: 100%;
        justify-content: center;
        padding: 0 0 6vh 0;
        flex-direction: column;
        align-items: center;
    }

    .leftImagesContainer {
        display: none;
    }

    .leftCharacterImage {
        display: none;
    }

    .scoreCard {
        width: 14em;
    }

    .scoreHeading {
        font-size: 1.1em;
    }

    .scoreSubHeading {
        font-size: 0.9em;
    }
}