.switchContainer {
  /* margin-top: 0.5em; */
  margin-left: 1em;
  margin-right: 1.1em;
  margin-bottom: 1em;
  display: block;
  justify-content: flex-start;
  align-items: center;
}

.removeIcon {
  margin-top: auto;
  margin-bottom: 1.5%;
  margin-left: 2%;
}

.mainClassSection {
  display: flex;
}

.switchText {
  font-size: 0.8em;
  width: fit-content;
  display: flex;
  align-items: center;
}

.switchText input {
  margin-top: 0.25em;
  margin-right: 1em;
}

.infoButton {
  font-size: 1em;
  margin-left: 0.15em;
  color: var(--qrencia-dark-blue) !important;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  z-index: 10;
  max-width: 30em;
}

.selectedClassesSection {
  font-size: 0.8em;
}

.mainContainer {
  padding-top: 0.6em;
  display: block;
  align-items: center;
}

.warningMessage {
  margin: 1em 0;
  background-color: var(--qrencia-extended-page-fun-fact-background);
  border-radius: 6px;
  border: 1px solid var(--instagram-yellow);
  /* width: max-content; */
}

.clubQrenciaClassDropdown {
  width: 12em;
}

.sectionsContainer {
  margin-top: 0.7em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.sectionsHeading {
  font-size: 0.8em;
  margin-top: -8px;
}

.warningMessage svg {
  font-size: 1.5em !important;
  padding: 0;
  color: var(--instagram-yellow);
}

.newButton {
  font-size: 0.6em;
  margin-top: 1.5em;
  padding: 0.4em;
}

.horizontalLine {
  text-align: center;
  margin: 1em auto;
  width: 95%;
}

.warningMessage p {
  font-size: 0.75em !important;
  font-weight: 500 !important;
  color: var(--instagram-yellow);
  padding: 0.5em;
}

.warningInfoContainer {
  margin: 0px 10px;
}

.nextBtn {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 100%;
  padding: 0.6em;
  text-align: right;
}

.nextBtn>Button {
  font-size: 0.8em;
  padding: 0.6em 2em;
}

@media (min-width: 768px) and (max-width: 990px) {
  .switch {
    width: 1.4em;
    height: 0.8em;
  }

  .switchContainer {
    margin-top: 1em;
    margin-left: 1em;
  }

  .switchText {
    margin-right: 0.6em;
    font-size: 0.75em;
  }
}

@media (max-width: 540px) {
  .switchContainer {
    margin-top: 1em;
    margin-left: 1em;
  }

  .switchText {
    margin-right: 0.5em;
    font-size: 0.75em;
  }

  .switch {
    width: 1.6em;
    height: 0.8em;
  }

  .warningMessage svg {
    font-size: 1.2em !important;
  }

  .warningMessage p {
    font-size: 0.65em !important;
  }

  .nextBtn {
    margin-top: 1.5em;
    width: 100%;
    padding: 0.6em;
    text-align: center;
  }

  .nextBtn>Button {
    font-size: 0.8em;
    padding: 0.6em 2em;
  }
}

@media (max-width: 500px) {
  .tooltipMessage {
    max-width: 22em;
  }
}