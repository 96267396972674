.externalLinkTitle {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 12%;
}

.externalLinkListElements,
.externalLinkListElements:hover,
.externalLinkListElements:active,
.externalLinkListElements:visited,
.externalLinkListElements:focus {
  font-size: 0.75em;
  line-height: 1.375em;
  text-decoration: none !important;
  list-style-type: none;
  text-align: left;
  margin-bottom: 10%;
  cursor: pointer;
  border: none;
}

.skillsOnQrencia {
  margin-top: 2.4rem;
}

.externalLinkListElements:first-child {
  margin-right: 0rem;
}

.eoiLink,
.eoiLink:hover {
  text-decoration: none;
  color: #fff;
}

@media (max-width: 1444px) {
  .skillsOnQrencia {
    margin-top: 2.1rem;
  }
}

@media (max-width: 1225px) {
  .skillsOnQrencia {
    margin-top: 2rem;
  }
}

@media (max-width: 990px) {
  .externalLinkTitle {
    font-size: 0.75em;
  }

  .skillsOnQrencia {
    margin-top: 1.7rem;
  }

  .externalLinkListElements,
  .externalLinkListElements:hover,
  .externalLinkListElements:active,
  .externalLinkListElements:visited,
  .externalLinkListElements:focus {
    font-size: 0.65em;
  }
}

@media (max-width: 767.5px) {
  .externalLinkListElements,
  .externalLinkListElements:hover,
  .externalLinkListElements:active,
  .externalLinkListElements:visited,
  .externalLinkListElements:focus {
    text-align: center;
    margin-bottom: 2%;
  }

  .externalLinkTitle {
    margin-bottom: 2%;
  }
}

@media (min-width: 767.6px) and (max-width: 781px) {
  .externalLinkTitle {
    font-size: 0.72em;
  }
}

@media (min-width: 850px) and (max-width: 860px) {
  .externalLinkTitle {
    font-size: 0.74em;
  }
}

@media (min-width: 990px) and (max-width: 1485px) {
  .externalLinkTitle {
    font-size: 0.86em;
  }
}
@media (max-width: 866px) {
  .skillsOnQrencia {
    margin-top: 1.6rem;
  }
}
@media (max-width: 768px) {
  .skillsOnQrencia {
    margin-top: 0;
  }
}
