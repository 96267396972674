.contentSection {
    background-color: var(--qrencia-white);
    box-shadow: 0 3px 21px 0 rgba(100, 100, 100, 0.12);
    border-radius: 12px;
    width: 100%;
    padding: 1.5%;
    height: fit-content;
    margin-bottom: 2%;
}
.formSubmitButtonSection{
    width:100%;
    justify-content: center;
}
.formSubmitButton{
    width:10%;
    margin:2% 0%;
    padding: 0.4% 2%;
    font-size: 0.9em;
}

.spinner {
    animation: spin-animation 0.5s infinite;
  }
  
  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

@media(max-width:767.5px){
    .contentSection {
        padding: 2.5%;
    }
    .formSubmitButton{
        width:18%;
    }
}
@media(min-width:767.5px) and (max-width:1045px){
    .formSubmitButton{
        width:14%;
    }
}
@media(max-width:480px){
    .formSubmitButton{
        width:24%;
        font-size: 0.85em;
        padding: 0.6% 2%;
    }
}