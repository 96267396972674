.expertsRecommendationsSection {
  background-color: var(--qrencia-white);
  padding: 0.8% 2.5% 2% 2.5%;
  border-radius: 20px;
  /* margin-bottom: 1%; */
  margin-top: 1%;
}

.expertsList {
  height: 40vh;
  overflow-y: auto;
  margin-bottom: 0%;
  padding-left: 0%;
}

.editButton {
  height: 2.5em !important;
  font-size: 0.8em !important;
  padding: 0 1.5em !important;
}

.addButton {
  width: 100%;
  height: fit-content;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.experts {
  width: 35%;
}

.recommendations {
  width: 75%;
}

.recommendationContainer {
  display: flex;
}

.expertsRecommendations {
  color: var(--qrencia-text-black);
  font-weight: 700;
  font-size: 1.4em;
  /* margin-bottom: 2%; */
}

.editLabel {
  font-size: 0.85rem;
  font-weight: 400 !important;
  color: var(--qrencia-text-black);
}

.editSwitch .form-check-input {
  transform: scale(0.55);
}

.userInitials {
  background-color: var(--qrencia-dark-blue);
  text-align: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: inline-block;
  padding-top: 10px;
  float: left;
  color: var(--qrencia-white);
  font-weight: 600;
}

.expertName {
  background-color: var(--qrencia-file-upload-container-bg);
  list-style: none;
  border-radius: 16px;
  cursor: pointer;
  padding: 0.4rem 0.4rem 0.4rem 0.7rem;
  margin-bottom: 1.2rem;
  overflow: auto;
  margin-right: 2%;
  opacity: 0.7;
}

.profileImage {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  float: left;
  object-fit: cover;
}

.expertContent {
  display: inline-block;
  padding-left: 1rem;
  padding-top: 0.2rem;
}

.expertDesignation {
  font-size: 0.7rem;
  margin-top: 3%;
  font-weight: 600 !important;
  color: rgba(126, 126, 126, 0.7);
}

.activeExpert {
  background-color: var(--qrencia-blue-background);
  opacity: 1;
}

.inactiveText {
  font-size: 0.85em;
  font-weight: 400 !important;
}

.recommendedByName {
  font-size: 0.9em;
  margin: 0 0 0.1rem 0;
}

.expertWrapper {
  height: 100%;
}

.recommendations {
  background-color: var(--qrencia-blue-background);
  border-radius: 16px;
  padding: 1rem;
  position: relative;
  margin-left: 1.5%;
}

.recommendations svg {
  font-size: 5rem;
  color: var(--qrencia-grey-quotes);
}

.firstQuote svg {
  transform: rotateY(180deg);
  margin-top: -2%;
  margin-left: -1%;
}

.lastQuote svg {
  position: absolute;
  bottom: 0%;
  right: 0%;
}

.recommendationDate {
  display: flex;
  justify-content: end;
  position: absolute;
  right: 2%;
  top: 8%;
  font-weight: 500 !important;
  font-size: 0.9em;
  color: var(--qrencia-silver-medal-color);
}

.recommendationDesc {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 1rem;
  text-align: center;
  width: 95%;
  height: 18vh;
  overflow-y: auto;
  line-height: 2em;
}

.errorContainer {
  padding: 2em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-grey-border);
  width: 80%;
  margin: 1em auto;
  text-align: center;
  background-color: var(--qrencia-white);
}

.editRecommendationWrapper {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 0.2rem;
}

@media (max-width: 767px) {
  .expertsRecommendationsSection {
    margin-top: 0;
  }
}

@media (max-width: 992px) {
  .expertDesignation {
    font-size: 0.7rem;
  }

  .recommendedByName {
    font-size: 0.9rem;
  }
}

@media (max-width: 810px) {
  .profileImage {
    width: 40px;
    height: 40px;
  }

  .userInitials {
    width: 40px;
    height: 40px;
    font-size: 0.9em;
  }

  .expertContent {
    padding-left: 0.6rem;
  }
}

@media (max-width: 910px) {
  .recommendations svg {
    font-size: 3rem;
  }

  .recommendedByName {
    font-size: 0.8rem;
  }

  .experts {
    width: 100%;
  }

  .recommendations {
    width: 100%;
    margin-left: 0%;
  }

  .recommendationContainer {
    display: block;
  }

  .expertsList {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0;
    height: auto;
  }

  .expertName {
    flex: 0 0 auto;
    width: 40%;
    margin-right: 1rem;
  }

  .recommendations {
    margin-top: 3%;
    min-height: 30vh;
    padding: 1rem;
  }

  .expertsRecommendationsSection {
    padding: 0.2% 2.5% 2% 2.5%;
  }
}

@media (max-width: 700px) {
  .expertName {
    flex: 0 0 auto;
    width: 45%;
    margin-right: 1rem;
  }
}

@media (max-width: 667px) {
  .expertName {
    flex: 0 0 auto;
    width: 45%;
    margin-right: 1rem;
  }

  .userInitials {
    width: 36px;
    height: 36px;
    font-size: 0.8em;
  }

  .profileImage {
    width: 36px;
    height: 36px;
  }

  .expertDesignation {
    font-size: 0.6rem;
  }
}

@media (max-width: 576px) {
  .experts ul {
    padding: 0;
  }

  .expertName {
    width: 49%;
  }
}

@media (max-width: 530px) {
  .expertName {
    width: 52%;
  }

  .userInitials {
    width: 33px;
    height: 33px;
    font-size: 0.8em;
    padding-top: 8px;
  }

  .profileImage {
    width: 33px;
    height: 33px;
  }

  .recommendedByName {
    font-size: 0.7rem;
  }

  .expertDesignation {
    font-size: 0.6rem;
  }
}

@media (max-width: 500px) {
  .expertName {
    width: 62%;
  }
}

@media (max-width: 458px) {
  .expertName {
    width: 65%;
  }

  .recommendationDesc {
    font-size: 0.8rem;
  }
}

@media (max-width: 400px) {
  .expertName {
    width: 75%;
  }
}

@media (max-width: 336px) {
  .recommendations svg {
    font-size: 4rem;
  }
}

@media (max-width: 1290px) {
  .expertsRecommendations {
    font-size: 1.2em;
  }
}

@media (max-width: 767px) {
  .expertsRecommendations {
    font-size: 1.1em;
  }

  .recommendationDate {
    font-size: 0.8em;
  }
}

@media (max-width: 767.8px) {
  .expertsRecommendationsSection {
    margin-bottom: 3%;
  }
}

@media (max-width: 438px) {
  .expertsRecommendationsSection {
    padding: 1% 2.5% 3% 2.5%;
  }
}

@media (max-width: 992px) {
  .editButton {
    font-size: 0.75em !important;
  }
}

@media (max-width: 900px) {
  .editButton {
    height: 2.3em !important;
    font-size: 0.75em !important;
  }
}

@media (max-width: 438px) {
  .editButton {
    font-size: 0.65em !important;
  }
}
