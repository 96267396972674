.widgetHeading {
    font-size: 0.95em;
    font-weight: 600;
    color: var(--qrencia-text-black);
    margin-bottom: 0;
}

.widgetSection {
    display: flex;
    padding: 3% 0.5% 0% 0.5%;
}

.quickLinkSection {
    background-color: var(--qrencia-white);
    border-radius: 10px;
    margin-bottom: 4%;
    padding: 2% 3.5%;
    margin-left: 0.5%;
    margin-right: 0.5%;
}

.headingSection {
    width: 80%;
    margin-bottom: 0;
    margin-top: 0;
}

.iconSection {
    width: 20%;
    text-align: end;
}

.expandMinimizeIcon {
    vertical-align: top !important;
    font-size: 1.3em;
    margin-top: -1.5px;
    cursor: pointer;
}

.collapseDiv {
    margin-bottom: 2%;
}

.noActivityAvailableSection {
    font-size: 0.8em;
    text-align: left;
    padding: 2% 0%;
    margin: 6% 0% 4% 0%;
    font-weight: 400;
    border-radius: 10px;
    color: var(--qrencia-text-black);
}

@media(min-width:1169px) and (max-width:1286px) {

    .noActivityAvailableSection {
        font-size: 0.8em;
    }
}

@media(min-width:990px) and (max-width:1290px) {
    .widgetHeading {
        font-size: 0.8em;
    }
}

@media(min-width:992px) and (max-width:1168px) {
    .noActivityAvailableSection {
        font-size: 0.8em;
    }
}

@media(min-width:768px) and (max-width:990px) {
    .widgetHeading {
        font-size: 0.8em;
    }

    .expandMinimizeIcon {
        font-size: 1em;
    }

    .headingSection {
        width: 94%;
    }

    .iconSection {
        width: 6%;
    }

    .noActivityAvailableSection {
        font-size: 0.75em;
    }
}

@media(min-width:480px) and (max-width:767px) {
    .widgetHeading {
        font-size: 0.9em;
    }

    .expandMinimizeIcon {
        font-size: 1.2em;
    }

    .headingSection {
        width: 90%;
    }

    .iconSection {
        width: 10%;
    }

    .quickLinkSection {
        margin-bottom: 4%;
        padding: 1em;
        margin-left: 0.5%;
        margin-right: 10%;
    }

    .noActivityAvailableSection{
        margin: 1em 0;
        padding: 0;
    }

    .collapseDiv {
        margin-top: 2%;
        margin-bottom: 2%;
    }

    .widgetSection {
        padding: 1.5% 0.5% 0% 0;
    }
}

@media(max-width:480px) {
    .widgetHeading {
        font-size: 0.9em;
    }

    .expandMinimizeIcon {
        font-size: 1em;
    }

    .headingSection {
        width: 90%;
        margin-bottom: 0%;
        margin-top: 0%;
    }

    .iconSection {
        width: 10%;
    }

    .expandMinimizeIcon {
        font-size: 0.9em;
        margin-top: -1.5px;
    }

    .collapseDiv {
        margin-top: 2%;
        margin-bottom: 2%;
    }
}