.errorText {
    color: var(--qrencia-astrick-red);
    border: 1px solid var(--qrencia-astrick-red);
    border-radius: 4px;
    margin-bottom: 0% !important;
    padding: 0.26% 4% 0.6% 4%;
    text-align: center;
}

.successText {
    color: var(--qrencia-success-green);
    border: 1px solid var(--qrencia-success-green);
    border-radius: 4px;
    margin-bottom: 0% !important;
    padding: 0.26% 4% 0.6% 4%;
    text-align: center;
}

.alertBox {
    padding: 0%;
    font-size: 0.85em;
    width: 80%;
    justify-content: center;
    margin: 2% auto 1% auto;
}

@media(max-width:767px) {
    .alertText {
        padding: 1% 8% 1% 4%;
    }

    .alertBox {
        width: 100%;
        margin: 6% auto;
    }
}