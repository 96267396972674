.statusWrapper {
    display: inline-flex;
    border-radius: 2rem;
    text-align: center;
    padding: 0 16px;
    height: 1.7rem;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-around;
  }
  
  .statusChipText {
    font-weight: 600;
  }
  