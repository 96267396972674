.heading {
  font-size: 1em;
  font-weight: 650;
  color: var(--qrencia-text-black);
  margin: 0.5em 0.5em 1em 0.5em;
}
.suggestiveCompetitionSearchComponent {
  background-color: var(--qrencia-white);
  width: 100%;
  padding: 1em;
  min-height: 5em;
  border-radius: 5px;
}
.errorMessageDiv {
  margin-bottom: -1em;
}
.suggestiveSearchInputContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.suggestiveCompetitionSearchComponent input {
  margin-right: 1em;
  flex: 1;
}
.suggestiveCompetitionSearchComponent > button {
  font-size: 0.8em;
  padding: 0.5em 1em;
}
@media (max-width: 480px) {
  .suggestiveCompetitionSearchComponent > button {
    font-size: 0.7em;
    padding: 0.5em 1em;
  }
}
