.mainContainerSection {
  margin: 2em 1em 16em 8%;
}

.dateColumn {
  display: inline;
  line-height: 1.8em;
}

.flexDisplay {
  display: flex;
}

.warningMessageForTeamSubmission {
  margin-bottom: 1%;
}

.marginSkillSection {
  margin-top: 0.4em;
}

.thumbnailSection {
  padding: 1.2em;
  display: flex;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  margin: 1em 0 0 0;
  border: 1px solid var(--qrencia-light-grey-border);
}

.basicDetail {
  margin-bottom: 1%;
}

.thumbnailContainer {
  width: 14em;
}

.thumbnail {
  width: 100%;
  border-radius: 10px;
  aspect-ratio: 825/550;
  object-fit: cover;
}

.nameLink {
  text-decoration: none;
  color: var(--qrencia-text-black) !important;
}

.thumbnailDetailsSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 1em;
  gap: 2px;
}

.quillEditor {
  padding: 12px 0px !important;
}

.highlightSubmissions {
  color: red;
  padding: 0.5em 0 !important;
  border-bottom: 3px solid black !important;
  font-weight: 600;
  font-size: 0.9em;
  color: var(--qrencia-text-black);
}

.competitionDate {
  font-size: 0.9em !important;
  font-weight: 500 !important;
}

.competitionLink {
  font-size: 0.9em !important;
  font-weight: 500 !important;
}

.competitionSkillHashtag {
  font-size: 0.9em !important;
  font-weight: 500 !important;
  background-color: #c2e2ff;
  border-radius: 20px;
  padding: 2px 10px 2px 7px;
  margin-top: 5px;
}

.competitionNavBarContainer {
  width: 100%;
  position: sticky;
  top: 0;
  padding: 0.5em 0;
  background-color: var(--qrencia-template-background-color);
  z-index: 100;
}

.competitionDetailsSection {
  padding: 1em;
  margin: 0 0 0.5em 0;
  background-color: var(--qrencia-white);
  border: 1px solid var(--qrencia-light-grey-border);
  border-radius: 10px;
}

.competitionDetailsHeading {
  font-size: 1.1em !important;
  font-weight: 600 !important;
}

.competitionDetailsSubsection {
  border-left: 2px dashed #c2e2ff;
}

.competitionDetailsListSection {
  border-left: 2px dashed #d8d8d8;
  padding: 0 0 0 20px;
}

.competitionDetailsListSection ul li:before {
  border: 2px solid rgba(39, 73, 125, 0.24);
  height: 20px;
  width: 20px;
  border: 2px solid #c6c5c5;
  background: #fff;
  left: -31px;
  margin: auto;
}

.competitionDetailsSubHeading {
  font-size: 0.93em !important;
  font-weight: 400 !important;
  position: relative;
  list-style-type: none;
  word-break: break-word;
  line-height: 24px;
}

.profilePicCnt {
  margin: 1em 0.5em;
}

.profilePicPreviewCnt {
  display: flex;
  align-items: center;
}

.attachedFiles {
  margin-top: 10px;
  margin-bottom: 10px;
}

.filePreview {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75em;
  font-weight: 500;
  background-color: var(--qrencia-mail-file-preview-bg);
  /* width: max-content; */
  max-width: 90% !important;
  margin: 0.5em 0em;
  padding: 0.2em 0.5em;
}

.filePreviewName {
  margin-right: 1em;
  color: var(--qrencia-attached-file-name);
  flex-grow: 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.filePreviewIcon {
  cursor: pointer;
  color: var(--qrencia-error-red);
  font-weight: 600;
}

.disabledRemoveFile {
  cursor: default;
}

.previewProfilePic {
  display: block;
  width: 90px !important;
  height: 90px !important;
  border-radius: 100%;
  border: 2px solid var(--qrencia-dark-blue);
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.competitionTitle {
  font-size: 1.2em !important;
  font-weight: 600 !important;
  max-width: 75%;
}

.askQuestionButton {
  font-size: 0.8em;
  font-weight: 450;
  outline: none;
  border: none;
  background-color: var(--qrencia-course-skill-name-color);
  border-radius: 3px;
  color: var(--qrencia-text-black);
  height: 2.4em;
  width: 10em !important;
  /* margin-left: 1em; */
}

.askQuestionButtonBelow {
  display: none;
  font-size: 0.8em;
  font-weight: 450;
  outline: none;
  border: none;
  background-color: var(--qrencia-course-skill-name-color);
  border-radius: 3px;
  color: var(--qrencia-text-black);
  height: 2.4em;
  width: 10em !important;
  margin-top: 0.9em;
}

.initialProfilePreview {
  display: flex;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  border: 2px solid var(--qrencia-dark-blue);
  font-size: 2.2em !important;
  justify-content: center;
  align-items: center;
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-select-box-background);
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}

.smallerText {
  font-size: 0.8em !important;
  font-weight: 500 !important;
  color: var(--qrencia-text-black);
}

.profilePicPreviewDetails {
  display: block;
}

.profilePicPreviewDetails p {
  margin-bottom: 0 !important;
}

.profilePicMainCnt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: nowrap;
  gap: 1em;
}

.competitionChallengeContainer {
  background-color: var(--qrencia-light-blue-icon-background);
  padding: 1em 2em;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  margin-bottom: 2%;
}

.downloadChallengeButton {
  font-size: 0.8em !important;
  padding: 4px 10px;
  color: var(--qrencia-white) !important;
  margin: 1em 0;
}

.uploadChallengeButton {
  font-size: 0.8em !important;
  margin-top: 1.5px;
  padding: 5px 10px;
  color: var(--qrencia-white) !important;
  width: 8em;
}

.downloadSolutionButton {
  font-size: 0.8em !important;
  padding: 4px 4px;
  color: var(--qrencia-dark-blue) !important;
  width: 8em;
  text-decoration: underline;
}

.competitionAdsSection {
  display: none;
}

.uploadButtonContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
  gap: 1em;
  margin: 1em 0;
}

.uploadButtonContainer>div>input {
  width: 100%;
}

.uploadButtonContainer>div>p {
  font-size: 0.8em !important;
}

.downloadButtonContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  gap: 0.1em;
  margin: 0.1em 0;
}

.downloadButtonContainer>.fileExistsMessage {
  font-size: 0.8em;
}

.YourPerformanceContainer {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 0.5em;
}

.YourPerformanceCard {
  width: calc(50% - 100px);
  height: 120px;
  font-size: 1em;
  background-color: var(--qrencia-white);
  border-radius: 8px;
  position: relative !important;
  box-shadow: var(--qrencia-your-performance-card-shadow);
  margin-right: 10px;
}

.YourPerformanceCardImage {
  width: 100%;
  height: 100%;
  border-radius: 8px !important;
  object-fit: cover;
}

.YourPerformanceDownloadText {
  font-size: 0.9em !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  color: var(--qrencia-text-black) !important;
  position: absolute;
  top: 30px;
  left: 10px;
}

.reviewCard {
  width: calc(50% - 100px);
  height: 120px;
  font-size: 1em;
  background-color: var(--qrencia-white);
  border-radius: 8px;
  position: relative !important;
  box-shadow: var(--qrencia-your-performance-card-shadow);
  margin-right: 10px;
}

.rewardsSectionTextContainer {
  position: absolute;
  top: 65%;
  left: 22px;
  width: 56%;
  height: 100%;
  transform: translate(0px, -50%);
}

.rewardsText {
  font-size: 0.95em !important;
  font-weight: 700 !important;
  line-height: 1.5 !important;
  color: var(--qrencia-white) !important;
}

.rewardsImage {
  width: 100%;
  height: 100%;
  border-radius: 8px !important;
  object-fit: fill;
}

.rewardsPrizeMoney {
  color: var(--qrencia-white) !important;
}

.YourPerformanceDownloadButton {
  position: absolute;
  top: 75px;
  left: 10px;
  font-size: 0.8em !important;
  padding: 4px 10px;
  color: var(--qrencia-white) !important;
}

.detailsAgeLimit {
  margin-bottom: 0 !important;
  display: inline-block;
}

.iButton {
  margin: 0 5px;
  font-size: 1.2em;
  font-weight: 600;
}

.tooltipMessage {
  font-size: 0.8em !important;
  font-weight: 500 !important;
  background-color: var(--qrencia-carousel-blue-image-border);
  width: 35%;
  color: var(--qrencia-text-black);
  z-index: 200;
}

.competitionDetailsContainer {
  margin-bottom: 8px !important;
}

.guidelinesText h1 {
  font-size: 2em;
  font-weight: 600;
}

.guidelinesText h2 {
  font-size: 1.5em;
  font-weight: 600;
}

.guidelinesText h3 {
  font-size: 1.17em;
  font-weight: 600;
}

.guidelinesText h4 {
  font-size: 1em;
  font-weight: 600;
}

.guidelinesText h5 {
  font-size: 0.83em;
  font-weight: 600;
}

.guidelinesText h6 {
  font-size: 0.67em;
  font-weight: 600;
}

.spinner {
  animation: spin-animation 0.5s infinite;
}

.uploadSolution {
  position: relative;
  margin-bottom: 0.5em;
  width: 25em;
}

.uploadSolution p {
  font-size: 0.8em !important;
}

.solutionUploadInputBox {
  height: 2.8em;
}

.uploadInputBox input[type="file"]::file-selector-button {
  position: absolute;
  right: -4px;
  top: 0;
  height: 3.94em;
  line-height: 1.1em;
  z-index: 9;
  width: 9em !important;
  border-radius: 0;
}

.solutionInputPadding {
  padding-right: 6em !important;
}

.scoreboardIcon {
  height: 1.2em;
  width: 1.2em;
  margin-right: 5px;
  margin-top: -5px;
}

.viewTeamDetailsButton {
  cursor: pointer;
  color: var(--qrencia-dark-blue);
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@media (min-width: 2000px) {
  .mainContainerSection {
    margin: 2em 1em 1em 8% !important;
  }
}

@media (min-width: 1400px) {
  .mainContainerSection {
    margin: 2em 1em 16em 8%;
  }
}

@media (min-width: 991px) {
  .thumbnailDetailsSection {
    width: 100%;
  }
}

@media (max-width: 1300px) {
  .YourPerformanceCard {
    width: calc(50% - 60px);
    font-size: 0.9em;
  }
}

@media (max-width: 1150px) {
  .competitionTitle {
    font-size: 1.1em !important;
    font-weight: 600 !important;
    max-width: 70%;
  }

  .askQuestionButton {
    font-size: 0.75em;
    height: 2.4em;
    width: 9em !important;
  }
}

@media (max-width: 1120px) {
  .YourPerformanceCard {
    width: calc(55% - 50px);
    font-size: 0.9em;
  }

  .askQuestionButton {
    font-size: 0.75em;
    height: 2.4em;
    width: 9em !important;
  }
}

@media (max-width: 991.5px) {
  .mainContainerSection {
    margin: 1.5em 8% 14em 8%;
  }

  .competitionTitle {
    font-size: 1.2em !important;
  }

  .competitionAdsSection {
    margin-top: 1%;
    display: block;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border-radius: 10px;
    margin: 0.5em 0 1em 0;
  }

  .competitionAdImage {
    width: 100%;
    border-radius: 10px;
  }

  .YourPerformanceCard {
    width: calc(60% - 100px);
    font-size: 0.9em;
  }

  .askQuestionButtonBelow {
    display: block;
  }

  .askQuestionButton {
    display: none;
  }
}

@media (max-width: 850px) {
  .YourPerformanceCard {
    width: calc(50% - 30px);
    font-size: 0.9em;
  }
}

@media (max-width: 768px) {
  .YourPerformanceCard {
    width: calc(50% - 20px);
    font-size: 0.9em;
  }

  .mainContainerSection {
    margin: 1.5em 8% 8em 8%;
  }
}

@media (max-width: 650px) {
  .YourPerformanceCard {
    width: calc(50% - 30px);
    font-size: 0.9em;
  }

  .uploadSolution {
    width: 22em;
  }
}

@media (max-width: 580px) {
  .mainContainerSection {
    margin: 1em 8% 1em 8%;
  }

  .uploadChallengeButton {
    padding: 4px 10px;
    width: 7em;
  }

  .thumbnailSection {
    margin-top: -0.5em;
  }

  .downloadButtonContainer {
    flex-wrap: wrap;
  }

  .downloadSolutionButton {
    padding: 4px 0;
  }

  .uploadButtonContainer {
    flex-direction: column;
    gap: 0.5em;
  }

  .uploadInputBox {
    width: 100%;
  }

  .YourPerformanceCard {
    width: calc(50% - 20px);
    font-size: 0.9em;
  }
}

@media (max-width: 540px) {
  .uploadSolution {
    max-width: 90%;
  }
}

@media (max-width: 500px) {
  .thumbnailSection {
    flex-wrap: wrap;
  }

  .dateColumn {
    line-height: 1.6em;
  }

  .thumbnailDetailsSection {
    padding-left: 0;
    padding-top: 0.5em;
  }

  .thumbnailContainer {
    width: 100%;
  }

  .YourPerformanceCard {
    width: calc(100% - 20px);
    font-size: 0.8em;
  }

  .profilePicMainCnt {
    flex-wrap: wrap;
  }

  .tooltipMessage {
    width: 50%;
  }

  .competitionChallengeContainer {
    padding: 1em;
  }

  .uploadSolution {
    max-width: 75%;
  }

  .rewardsText {
    font-size: 1.2em !important;
  }

  .attachedFiles {
    max-width: 85%;
  }
}

@media (max-width: 400px) {
  .attachedFiles {
    max-width: 75%;
  }
}

@media (max-width: 390px) {
  .uploadSolution {
    max-width: 70%;
  }
}

@media (max-width: 360px) {
  .uploadSolution {
    max-width: 65%;
  }
}

/* ----------------List Styling--------------- */

.competitionDetailsSection p,
.competitionDetailsSection strong,
.competitionDetailsSection ul,
.competitionDetailsSection li,
.competitionDetailsSection span {
  font-size: 15px;
  word-break: break-word;
  font-weight: 400;
}

@media (max-width: 800px) {

  .competitionDetailsSection p,
  .competitionDetailsSection strong,
  .competitionDetailsSection ul,
  .competitionDetailsSection li,
  .competitionDetailsSection span {
    font-size: 14px;
  }
}

.competitionDetailsSection>ul,
.competitionDetailsSection>ol {
  border-left: 2px dashed var(--qrencia-dark-blue);
  padding: 0 0 0 20px;
}

.competitionDetailsSection>ul>li,
.competitionDetailsSection>ol>li {
  position: relative;
  list-style-type: none;
  word-break: break-word;
}

.competitionDetailsSection>ul>li:last-child,
.competitionDetailsSection>ol>li:last-child {
  margin-bottom: 0;
}

.competitionDetailsSection>ul>li:before,
.competitionDetailsSection>ul>li:after,
.competitionDetailsSection>ol>li:before,
.competitionDetailsSection>ol>li:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  margin: auto;
}

.competitionDetailsSection>ul>li:before,
.competitionDetailsSection>ol>li:before {
  height: 16px;
  width: 16px;
  border: 2px solid var(--qrencia-dark-blue);
  background: var(--qrencia-white);
  left: -29px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.competitionDetailsSection>ul>li:after,
.competitionDetailsSection>ol>li:after {
  height: 6px;
  width: 6px;
  background: var(--qrencia-dark-blue);
  left: -24px;
  top: 0;
  bottom: 0;
}

.competitionDetailsSection>ul>li>ul,
.competitionDetailsSection>ul>li>ol,
.competitionDetailsSection>ol>li>ul,
.competitionDetailsSection>ol>li>ol {
  border-left: 0px;
  margin-left: 0;
  padding-left: 30px;
}

.competitionDetailsSection>ul>li>ul>li:after,
.competitionDetailsSection>ul>li>ul>li:before,
.competitionDetailsSection>ul>li>ol>li:after,
.competitionDetailsSection>ul>li>ol>li:before,
.competitionDetailsSection>ol>li>ul>li:after,
.competitionDetailsSection>ol>li>ul>li:before,
.competitionDetailsSection>ol>li>ol>li:after,
.competitionDetailsSection>ol>li>ol>li:before {
  display: none;
}

.competitionDetailsSection>ul>li>ul>li,
.competitionDetailsSection>ol>li>ul>li {
  list-style-type: disc;
}

.competitionDetailsSection>ul>li>ul>li>li,
.competitionDetailsSection>ol>li>ul>li>li {
  list-style-type: circle;
}

.competitionDetailsSection>ul>li>ol>li,
.competitionDetailsSection>ol>li>ol>li {
  list-style-type: decimal;
}

.competitionDetailsSection>ul>li>ol>li>li,
.competitionDetailsSection>ol>li>ol>li>li {
  list-style-type: circle;
}

@media (max-width: 800px) {

  .competitionDetailsSection>ul,
  .competitionDetailsSection>ol {
    border-left: 1px dashed var(--qrencia-dark-blue);
    padding: 0 0 0 15px;
  }

  .competitionDetailsSection>ul>li:before,
  .competitionDetailsSection>ol>li:before {
    height: 15px;
    width: 15px;
    border: 1px solid var(--qrencia-dark-blue);
    left: -23px;
  }

  .competitionDetailsSection>ul>li:after,
  .competitionDetailsSection>ol>li:after {
    height: 5px;
    width: 5px;
    background: var(--qrencia-dark-blue);
    left: -18px;
  }
}

.competitionDetailsSection strong {
  font-weight: 600;
}

.competitionDetailsSection strong a,
.competitionDetailsSection strong u,
.competitionDetailsSection strong span {
  font-weight: 600;
}

.competitionDetailsSection a {
  word-break: break-word;
  color: #0073e6;
  cursor: pointer;
}