.scoreboardTable {
  margin-left: auto;
  margin-right: auto;
  font-size: 0.9em;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.scoreboardTable::-webkit-scrollbar {
  display: none;
}

.profileImage {
  height: 2em;
  width: 2em;
  object-fit: cover;
  margin-right: 0.8em;
  border-radius: 15px;
}

.learnerNameData {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.learnerInitials {
  height: 1.8em;
  width: 1.8em;
  font-size: 1em;
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-course-skill-name-color);
  line-height: 1.8em;
  text-align: center;
  margin-right: 0.8em;
  font-weight: 600;
  border-radius: 15px;
}

.tableHead {
  background-color: var(--qrencia-light-blue-icon-background);
}

.tableHeadings {
  text-align: center;
  font-size: 1em;
}

.tableData {
  font-size: 0.95em;
}

.tableBody {
  text-align: center;
}

.statusContainer {
  width: 85%;
  margin: 1em auto;
  border-radius: 5px;
  text-wrap: auto;
}

.tableBody>tr>td {
  vertical-align: middle;
  font-size: 0.9em;
  -webkit-touch-callout: none;
  user-select: text;
  -webkit-user-select: text;
  text-decoration: none;
  -webkit-text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.goldMedal {
  color: var(--qrencia-gold-medal-color);
}

.silverMedal {
  color: var(--qrencia-silver-medal-color);
}

.bronzeMedal {
  color: var(--qrencia-bronze-medal-color);
}

.viewButton {
  width: 6em;
  font-size: 0.9em;
}

.tableBodyRow {
  border-spacing: 20px 0px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 2em;
}

.modalHeading {
  font-size: 1em;
  margin-bottom: 1em;
}

.modalCloseButton {
  font-size: 0.8em;
  width: 6em;
  text-align: center;
}

.tableGenderHeading {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  font-size: 1.1em;
  font-weight: 500 !important;
}

.judgingEnabledWarningMessage {
  margin: 1em 0;
  background-color: var(--qrencia-extended-page-fun-fact-background);
  border-radius: 6px;
  border: 1px solid var(--instagram-yellow);
  width: max-content;
}

.judgingEnabledWarningMessage svg {
  font-size: 1.5em !important;
  padding: 0;
  color: var(--instagram-yellow);
}

.judgingEnabledWarningMessage p {
  font-size: 0.75em !important;
  font-weight: 500 !important;
  color: var(--instagram-yellow);
  padding: 0.5em;
}

@media (max-width: 991px) {
  .profileImage {
    height: 1.8em;
    width: 1.8em;
    object-fit: cover;
    margin-right: 0.8em;
  }

  .learnerInitials {
    height: 1.8em;
    width: 1.8em;
    line-height: 1.8em;
    font-size: 1em;
    text-align: center;
    margin-right: 0.6em;
  }

  .tableData {
    font-size: 0.9em;
  }

  .tableBody>tr>td {
    vertical-align: middle;
    font-size: 0.8em;
  }
}

@media (max-width: 768px) {
  .profileImage {
    height: 1.5em;
    width: 1.5em;
    object-fit: cover;
    margin-right: 0.8em;
  }

  .tableData {
    font-size: 0.8em;
  }

  .tableBody>tr>td {
    vertical-align: middle;
    font-size: 0.7em;
  }
}

@media (max-width: 560px) {
  .scoreboardTable {
    font-size: 0.85em;
    min-height: 18em;
  }

  .tableGenderHeading {
    font-size: 0.9em;
  }
}

@media (min-width: 540px) and (max-width: 1290px) {
  .statusContainer {
    width: 95%;
  }
}

@media (max-width: 540px) {
  .statusContainer {
    width: 98% !important;
  }
}