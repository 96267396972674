.carousel-inner {
  /* height: 66vh; */
}

.carousel-indicators [data-bs-target] {
  background-color: var(--qrencia-white) !important;
  border: 1px solid var(--qrencia-dark-blue) !important;
}

.carousel-indicators {
  position: absolute;
  bottom: 0;
  width: 20%;
  left: 73%;
  z-index: 2;
  display: flex;
  transform: rotate(90deg);
  margin-bottom: 13rem;
  list-style: none;
  gap: 0.3em;
}

.carousel-indicators .active {
  background-color: var(--qrencia-dark-blue) !important;
  border: 2px solid var(--qrencia-dark-blue) !important;
  width: 10%;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

@media (max-width: 540px) {
  .carousel-inner {
    /* height: 85vh; */
  }

  .carousel-indicators {
    bottom: 12%;
    left: 72%;
  }

  .carousel-indicators [data-bs-target] {
    height: 6px;
    width: 6px;
    margin-right: 2px;
    margin-left: 2px;
  }
}

@media (max-width: 426px) {
  .carousel-indicators {
    left: 69%;
    width: 20%;
  }

  .carousel-indicators [data-bs-target] {
    height: 5px;
    width: 5px;
  }
}

@media (max-width: 376px) {
  .carousel-indicators {
    left: 69%;
    width: 22%;
  }

  .carousel-indicators [data-bs-target] {
    height: 5px;
    width: 5px;
  }
}

@media (max-width: 321px) {
  .carousel-indicators {
    left: 66%;
    width: 25%;
  }

  .carousel-indicators [data-bs-target] {
    height: 3.5px;
    width: 3.5px;
  }
}
@media (min-width: 480px) and (max-width: 767.5px) {
  .carousel-inner {
    /* height: 55vh !important; */
  }

  .carousel-indicators {
    bottom: -12%;
    left: 72%;
  }

  .carousel-indicators [data-bs-target] {
    width: 9px;
    height: 9px;
    border-radius: 50%;
  }
}

@media (max-width: 480px) and (min-height: 800px) {
  .carousel-inner {
    /* height: 38vh; */
  }

  .carousel-indicators {
    bottom: -14%;
    left: 72%;
  }

  .carousel-indicators [data-bs-target] {
    height: 6px;
    width: 6px;
    margin-right: 2px;
    margin-left: 2px;
  }
}
@media (max-width: 480px) and (max-height: 800px) {
  .carousel-inner {
    /* height: 42vh; */
  }

  .carousel-indicators {
    bottom: -28%;
    left: 71%;
  }

  .carousel-indicators [data-bs-target] {
    height: 6px;
    width: 6px;
    margin-right: 2px;
    margin-left: 2px;
  }
}
@media (max-height: 680px) and (max-width: 480px) {
  .carousel-inner {
    /* height: 44vh !important; */
  }

  .carousel-indicators {
    bottom: -28%;
    left: 71%;
  }

  .carousel-indicators [data-bs-target] {
    height: 6px;
    width: 6px;
    margin-right: 2px;
    margin-left: 2px;
  }
}
@media (min-width: 820px) and (min-height: 1179px) and (max-width: 821px) {
  .carousel-inner {
    /* height: 45vh; */
  }
}

@media (min-width: 912px) and (min-height: 1368px) and (max-width: 913px) {
  .carousel-inner {
    /* height: 40vh; */
  }
}

@media (min-width: 768px) and (min-height: 1024px) and (max-width: 769px) {
  .carousel-inner {
    /* height: 45vh !important; */
  }
}

@media (min-width: 990px) and (max-width: 1200px) and (min-height: 600px) {
  .carousel-inner {
    /* height: 60vh !important; */
  }
}

@media (min-width: 1023px) and (max-width: 1025px) and (min-height: 600px) and (max-height: 602px) {
  .carousel-inner {
    /* height: 78vh !important; */
  }
}

@media (min-width: 1023px) and (min-height: 1365px) and (max-width: 1025px) and (max-height: 1367px) {
  .carousel-inner {
    /* height: 40vh !important; */
  }
}
