.participantCountDesktop {
  display: block;
  font-weight: 500 !important;
  color: var(--card-grey-title) !important;
}

.participantCountMoblie {
  display: none;
  font-weight: 500 !important;
  color: var(--card-grey-title) !important;
}

.dobInput input[type="date"],
.dobInput input[type="time"] {
  text-align: left !important;
}

.dobInput input[type="date"]::-webkit-date-and-time-value,
.dobInput input[type="time"]::-webkit-date-and-time-value {
  text-align: left !important;
}

.dobInput input[type="date"]::-webkit-datetime-edit,
.dobInput input[type="time"]::-webkit-datetime-edit {
  text-align: left !important;
}

.addButton {
  margin: 1em auto;
  font-size: 0.6em;
  font-weight: 500;
  text-align: left;
  padding: 0.4em 1em;
  margin-right: 2%;
}

.removeButton {
  margin: 1em auto;
  margin-top: 1.7em;
  font-size: 0.6em;
  font-weight: 500;
  text-align: left;
  padding: 0.61em 1em;
  margin-right: 2%;
  background-color: var(--cancel-red-button);
  border: none;
  outline: none;
}

.removeButton:hover,
.removeButton:active,
.removeButton:focus {
  background-color: var(--cancel-red-button) !important;
}

.teamEnrolmentModal::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: none;
}

.teamEnrolmentModal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.modalHeading {
  font-size: 1.1em;
}

.modalSubtitle {
  font-size: 1.05em;
  color: var(--card-grey-title);
}

.teamParticipantCountSection {
  font-size: 01em !important;
  color: var(--card-grey-title);
}

.participantCount {
  font-weight: 400;
  color: var(--qrencia-text-black);
}

.qrenciaIdRow {
  display: flex;
  justify-content: end;
}

.qrenciaIdContainer {
  border: 1px solid var(--qrencia-grid-grey);
  width: 18em;
  border-radius: 0.5em;
  border-radius: 10px;
  margin-top: 0.5em;
}

.switchContainer {
  margin-bottom: 0.5em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5em;
}

.formHeading {
  display: block;
}

.switchText {
  font-size: 0.8em;
  width: fit-content;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: var(--card-grey-title);
}

.switchText input[type="checkbox"] {
  margin-right: 0.5em;
  width: 2em;
}

.orTextSection {
  text-align: center;
  font-size: 0.85em;
  color: var(--card-grey-title);
  font-weight: 600;
  margin-top: 0.7em;
  margin-bottom: 0.5em;
}

.switchText input[type="checkbox"]:checked {
  background-color: var(--linkedin-blue);
  border-color: var(--linkedin-blue);
}

.qrenciaIdFormInput {
  width: 12em;
  margin-bottom: 0.3em;
}

.qrenciaIdFormInputContainer {
  display: flex;
  align-items: center;
}

input[type="text"]:disabled,
input[type="file"]:disabled,
input[type="date"]:disabled,
input[type="number"]:disabled,
input[type="time"]:disabled,
input[type="url"]:disabled,
.form-control:disabled,
.form-select:disabled {
  background: var(--qrencia-disabled-input-bg) !important;
  border: solid 1px var(--qrencia-disabled-input-border) !important;
  cursor: not-allowed;
}

.qrenciaIdFormInputSection {
  display: flex;
  width: 58%;
}

.qrenciaIdFormTextSection {
  display: flex;
  margin-right: 1em;
  width: 42%;
  align-items: center;
}

.addQrenciaIdButton {
  font-size: 0.7em !important;
  padding: 0.61em 0.8em;
  width: 4em;
  height: 3.2em;
  margin-top: 0.07em;
  margin-left: 1em;
}

.qrenciaIdErrorText {
  width: 100%;
  margin-top: 0.2em;
}

.addTeamButton {
  width: 100%;
  padding: 0.5em 1em;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: 600;
  color: var(--card-grey-title);
  border-radius: 5px;
  margin-bottom: 1em;
  background-color: var(--qrencia-add-team-button);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.addTeamButton:hover {
  transition: 0.2s all ease-in-out;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transform: scale(1.009);
  cursor: pointer;
}

.addTeamButtonDisabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.addTeamButtonDisabled:hover {
  transition: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transform: scale(1);
  cursor: not-allowed;
}

.container>.teamDetailsForm {
  flex: 0.75;
  border: 1px solid var(--qrencia-select-box-border);
  border-radius: 1em;
  margin-right: 1em;
  padding: 1em 0.5em;
}

.participantNavTabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid var(--qrencia-grid-grey);
  border-radius: 10px;
  margin-top: 1em;
  z-index: 1;
  overflow: auto;
  max-width: 50em;
}

.participantNavTabText {
  cursor: pointer;
  padding: 0.5em 2em;
  font-size: 0.8em;
  text-align: center !important;
  margin-bottom: 0em;
  border-radius: 0.3em;
  margin: 0.5em 0em;
  max-width: 16em;
  min-width: 11em;
  color: var(--card-grey-title);
  font-weight: 600;
}

.activeParticipantNavTabText {
  background-color: var(--qrencia-file-upload-container-bg);
  text-align: center !important;
}

.participantDetailsSection {
  margin-bottom: 0.5em;
  border: 1px solid var(--qrencia-grid-grey);
  border-top: none;
  padding: 1em 0.5em;
  padding-bottom: 1em;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-top: -0.3em;
  background-color: var(--qrencia-team-participant-details-background);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.teamOverviewSectionDesktop {
  flex: 0.25;
  border: 1px solid var(--qrencia-select-box-border);
  border-radius: 1em;
}

.teamNameSection {
  border-top: 1px solid var(--qrencia-select-box-border);
  padding: 0.6em 1em;
  overflow-y: scroll;
  height: 16em;
  scrollbar-width: none;
}

.teamNameSection::-webkit-scrollbar {
  display: none;
}

.overviewHeading {
  margin-top: 0.5em;
  margin-left: 0.8em;
  font-size: 1.1em;
  font-weight: 500;
  color: var(--card-grey-title);
}

.teamNameBox {
  font-size: 0.8em;
  margin: 0.2em 0px 0.6em 0px;
  padding: 0.5em 1em;
  padding-right: 2em;
  border-radius: 1px;
  cursor: pointer;
  position: relative;
  font-weight: 600;
  color: var(--card-grey-title);
  background-color: var(--qrencia-team-background-color);
}

.teamNameBox:hover {
  transition: 0.2s all ease-in-out;
  transform: scale(1.009);
  cursor: pointer;
}

.activeTeam {
  color: var(--qrencia-text-black);
  border-left: 0.6em solid var(--qrencia-dark-blue);
}

.participantCount {
  width: 100%;
  font-size: 0.8em;
  text-align: right;
  margin-top: 1.5em;
  font-weight: 500;
  color: var(--card-grey-title);
}

.participantCountHeading {
  color: var(--card-grey-title);
}

.participantCountHeading>svg {
  height: 1.25em;
  width: 1.25em;
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  border-radius: 3px;
  padding: 0.25em;
}

.teamParticipants {
  margin-bottom: 0.5%;
}

.removeTeamIcon {
  position: absolute;
  top: 1em;
  right: 0.6em;
  transform: translateY(-50%);
  font-size: 1.2em;
}

.teamNameInputBox {
  margin-left: -0.5em;
}

.totalAmountSection {
  border-top: 1px solid var(--qrencia-select-box-border);
  width: 100%;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.totalAmountSection>h6 {
  font-size: 0.85em;
}

.checkCircle {
  font-size: 0.8em;
  color: var(--qrencia-success-green);
  margin-top: -2px;
  margin-left: 2px;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  z-index: 10;
  max-width: 32em;
}

.modalFooter {
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.errorMessageContainer {
  flex: 0.5;
  width: 100%;
  margin-left: -3.5em;
}

.buttonRow {
  text-align: right;
  flex: 0.5;
}

.buttonRow>button {
  margin-left: 0.5em;
  height: 2.2em;
}

.modalButton {
  font-size: 0.8em;
  outline: none;
  border: none;
  width: 10em;
}

.viewTeamButton {
  font-size: 0.8em;
  outline: none;
  border: none;
}

.closeButton,
.closeButton:hover,
.closeButton:active,
.closeButton:focus {
  background-color: var(--qrencia-white) !important;
  color: var(--qrencia-dark-blue) !important;
  outline: 1px solid var(--qrencia-dark-blue) !important;
  font-weight: 500;
}

.payOfflineButton,
.payOfflineButton:hover,
.payOfflineButton:active,
.payOfflineButton:focus {
  background-color: var(--qrencia-success-green) !important;
  color: var(--qrencia-white) !important;
}

.closeOfflinePaymentButton {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

@media (max-width: 1200px) {
  .modalButton {
    width: 8em;
  }

  .container {
    display: block;
  }

  .teamOverviewSectionDesktop {
    margin-top: 1.5em;
  }

  .errorMessageContainer {
    margin-left: -2.5em;
  }

  .qrenciaIdFormInputContainer {
    display: block;
  }

  .qrenciaIdFormInputSection {
    margin-top: 0.5em;
    width: 100%;
  }

  .qrenciaIdFormTextSection {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .teamOverviewSectionDesktop {
    margin-top: 1.5em;
  }
}

@media (max-width: 991px) {
  .modalFooter {
    display: block;
  }

  .qrenciaIdFormInputContainer {
    display: block;
  }

  .qrenciaIdFormInputSection {
    margin-top: 0.5em;
    width: 100%;
  }

  .qrenciaIdFormTextSection {
    width: 100%;
  }

  .buttonRow {
    text-align: center;
  }

  .buttonRow>button {
    height: auto;
  }

  .errorMessageContainer {
    margin-left: 0em;
  }
}

@media (max-width: 768px) {
  .participantNavTabText {
    font-size: 0.75em;
  }

  .switchText {
    font-size: 0.75em;
  }
}

@media (max-width: 540px) {
  .qrenciaIdContainer {
    width: 100%;
  }

  .buttonRow {
    text-align: center;
  }

  .qrenciaIdContainer {
    margin-bottom: 1em;
  }

  .qrenciaIdRow {
    display: block;
  }

  .qrenciaIdRow {
    text-align: center;
  }

  .modalButton {
    font-size: 0.75em;
  }

  .participantCount {
    font-size: 0.7em;
    text-align: left;
  }

  .participantCountMoblie {
    display: inline;
  }

  .participantCountDesktop {
    display: none;
  }
}