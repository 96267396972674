.qrenciaForExpertsContent {
  padding: 6% 13% 6% 13%;
}

.qrenciaForExpertsSectionDesktop {
  background: #e8f3ff;
  display: block;
}
.qrenciaForExpertsSectionTablet {
  background: #e8f3ff;
  display: none;
}
.qrenciaForExpertsSectionMobile {
  background: #e8f3ff;
  display: none;
}
.backgrounImageSection {
  padding-bottom: 2%;
  margin-top: -2%;
  text-align: right;
  position: relative;
}

.discoverContentSection {
  width: 100%;
}

.discoverPassionImage {
  width: 100%;
  border-radius: 8px;
  height: auto;
}

.heading {
  color: var(--qrencia-text-black);
  font-weight: 600;
  line-height: 1.2em;
  margin-top: -24px;
  font-size: 2em;
  margin-bottom: 2%;
}

.blueTriangleImage {
  margin-left: -12px;
  margin-top: -8px;
  width: 3%;
}
span {
  color: var(--qrencia-dark-blue);
  font-weight: 800;
}
.titleText {
  color: var(--qrencia-orange-heading);
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.2em;
}
.subHeading {
  background: var(--qrencia-grey-section);
  padding: 0.5% 2%;
  border-radius: 50px;
  display: inline;
  font-size: 0.8em;
  font-weight: 600;
  margin-top: 8%;
}

.imageContainer {
  position: relative;
}
.expertDesktopImage {
  width: 100%;
  object-fit: contain;
  border-radius: 20px;
}
.expertTabletImage {
  display: none;
}
.expertMobileImage {
  display: none;
}
.textBoxDesktop {
  background-color: var(--qrencia-white);
  height: 75%;
  width: 30%;
  position: absolute;
  top: 12%;
  right: 5%;
  border-radius: 30px;
  padding: 2% 2%;
}
.textBoxTablet {
  display: none;
}
.textBoxMobile {
  display: none;
}
.textBoxContent {
  position: relative;
  height: 100%;
  width: 100%;
}
.boxHeadingDesktop {
  font-size: 125%;
  font-weight: 600 !important;
  letter-spacing: 1px;
  position: absolute;
  top: 0.5%;
}
.contentListDesktop {
  /* font-size: 1em; */
  /* font-size: calc(0.7vw + 0.7vh); */
  font-size: calc(0.6vw + 50%);
  margin-top: 3%;
  margin-bottom: 3%;
  height: 45%;
  position: absolute;
  top: 4%;
  margin-top: auto;
  margin-bottom: auto;
}
.contentListDesktop > ul {
  padding-left: 0.4em !important;
  list-style-type: none;
}
.contentListDesktop > ul > li {
  margin-bottom: 0.75em;
  margin-bottom: calc(0.1vw + 7%);
  font-weight: 500 !important;
}
.contentListDesktop > ul > li > img {
  height: 1.6em;
  width: 1.6em;
  margin-right: 0.6em;
}
.getStartedButtonDesktop {
  width: 100%;
  border-radius: 30px;
  text-align: center;
  /* font-size: 0.9em; */
  font-size: calc(0.6vw + 50%);
  padding: 0.4em 0.8em;
  position: absolute;
  bottom: 0em;
}
.getStartedButtonDesktop > svg {
  color: var(--qrencia-white);
}
.blueTriangleImage {
  margin-left: -12px;
  margin-top: -8px;
  width: 3%;
}
@media (min-width: 1500px) {
  .contentListDesktop {
    /* font-size: 1.25em; */
    font-size: calc(0.6vw + 50%);
    margin-top: 2%;
    margin-bottom: 3%;
    height: 45%;
    top: 1.5%;
  }
  .qrenciaForExpertsContent {
    padding: 6% 14% 6% 14%;
  }
}
@media (min-width: 1700px) {
  .contentListDesktop {
    /* font-size: 1.3em; */
    font-size: calc(0.6vw + 50%);
    margin-top: 2%;
    margin-bottom: 3%;
    height: 45%;
    top: 2%;
  }
}
@media (max-width: 1215px) {
  .contentListDesktop {
    /* font-size: 1.15em; */
    font-size: calc(0.6vw + 50%);
    margin-top: 2%;
    margin-bottom: 3%;
    height: 45%;
    position: absolute;
    top: 0%;
  }
  .qrenciaForExpertsContent {
    padding: 6% 9% 6% 9%;
  }
  .blueTriangleImage {
    width: 5%;
  }
}
@media (max-width: 1120px) {
  .boxHeadingDesktop {
    font-size: 100%;
    letter-spacing: 1px;
    top: 0.5%;
  }
  .contentListDesktop {
    /* font-size: 1em; */
    font-size: calc(0.6vw + 50%);
    height: 45%;
    top: 1.5%;
  }
}
@media (max-width: 1050px) {
  .boxHeadingDesktop {
    font-size: 95%;
    letter-spacing: 1px;
    top: 0.5%;
  }
  .contentListDesktop {
    /* font-size: 0.96em; */
    font-size: calc(0.6vw + 50%);
    margin-top: 2%;
    margin-bottom: 3%;
    height: 45%;
    top: 0%;
  }
}
@media (max-width: 1005px) {
  .boxHeadingDesktop {
    font-size: 95%;
    letter-spacing: 1px;
    top: 0.5%;
  }
  .contentListDesktop {
    /* font-size: 0.9em; */
    font-size: calc(0.6vw + 50%);
    margin-top: 2%;
    margin-bottom: 3%;
    height: 45%;
    top: 0%;
  }
}

@media (max-width: 991px) {
  .expertDesktopImage {
    display: none;
  }
  .expertTabletImage {
    width: 100%;
    object-fit: contain;
    border-radius: 20px;
    display: block;
  }
  .textBoxDesktop {
    display: none;
  }
  .textBoxTablet {
    background-color: var(--qrencia-white);
    height: 52%;
    width: 100%;
    position: absolute;
    top: 50%;
    border-radius: 20px;
    padding: 1em 1.5em 1em 1.5em;
    display: block;
  }
  .boxHeadingTablet {
    font-size: 130%;
    font-weight: 600 !important;
    position: absolute;
    top: 0%;
  }
  .contentListTablet {
    /* font-size: 1.05em; */
    font-size: calc(0.75vw + 50%);
    margin-top: 3%;
    margin-bottom: 3%;
    top: -5%;
    position: absolute;
    text-align: left;
  }
  .contentListTablet > ul {
    padding-left: 1em !important;
  }
  .contentListTablet > ul > li {
    margin-bottom: calc(0.1vw + 4%) !important;
  }
  .getStartedButtonTablet {
    width: 100%;
    border-radius: 30px;
    text-align: center;
    /* font-size: 0.8em; */
    font-size: calc(0.7vw + 55%);
    padding: 0.7em 1em;
    position: absolute;
    bottom: 0em;
  }
  .contentListTablet > ul > li > img {
    height: 1.4em;
    width: 1.4em;
    margin-right: 0.5em;
  }
  .contentListTablet > ul {
    padding-left: 0.4em !important;
    list-style-type: none;
  }
  .titleText {
    font-size: 0.8em;
  }
  .contentListTablet > ul {
    padding-left: 0em !important;
  }
  .contentListTablet > ul > li {
    margin-bottom: 0.3em;
    font-weight: 500 !important;
  }
  .blueTriangleImage {
    width: 5.8%;
  }
  .qrenciaForExpertsSectionDesktop {
    display: none;
  }
  .qrenciaForExpertsSectionTablet {
    display: block;
  }
  .qrenciaForExpertsSectionMobile {
    display: none;
  }
}
@media (max-width: 900px) {
  .boxHeadingTablet {
    font-size: 130%;
    top: 0.5%;
  }
  .textBoxTablet {
    height: 54%;
  }
  .contentListTablet {
    /* font-size: 0.9em; */
    font-size: calc(0.75vw + 50%);
    margin-top: 3%;
    margin-bottom: 3%;
    height: 45%;
    top: -6%;
  }
  .contentListTablet > ul > li {
    margin-bottom: calc(0.1vw + 5%) !important;
  }
  .getStartedButtonTablet {
    text-align: center;
    font-size: calc(0.7vw + 55%);
    padding: 0.6em 1em;
    position: absolute;
    bottom: 0em;
  }
}

@media (max-width: 820px) {
  .textBoxTablet {
    height: 56%;
  }
  .boxHeadingTablet {
    font-size: 125%;
  }
  .contentListTablet {
    font-size: calc(0.7vw + 50%);
    margin-top: 3%;
    margin-bottom: 3%;
    top: -7%;
  }
  .contentListTablet > ul > li {
    margin-bottom: 0.4em;
  }
  .getStartedButtonTablet {
    width: 100%;
    border-radius: 30px;
    text-align: center;
    font-size: calc(0.7vw + 55%);
    padding: 0.7em 1em;
    position: absolute;
    bottom: 0em;
  }
}
@media (max-width: 767px) {
  .boxHeadingTablet {
    font-size: 125%;
  }
  .textBoxTablet {
    height: 57%;
  }
  .contentListTablet {
    font-size: calc(0.75vw + 50%);
    margin-top: 3%;
    margin-bottom: 3%;
    top: -6%;
  }
  .contentListTablet > ul {
    padding-left: 1em !important;
  }
  .contentListTablet > ul > li {
    margin-bottom: calc(0.1vw + 6%) !important;
  }
  .getStartedButtonTablet {
    border-radius: 30px;
    text-align: center;
    font-size: calc(0.7vw + 55%);
    padding: 0.7em 1em;
    position: absolute;
    bottom: 0em;
    left: 0em;
  }
  .titleText {
    font-size: 0.8em;
  }
  .qrenciaForExpertsContent {
    padding: 8% 3% 12% 3%;
  }

  .blueTriangleImage {
    margin-left: -550px;
  }
}
@media (max-width: 690px) {
  .textBoxTablet {
    height: 57%;
    padding: 1em 2em 1em 2em;
  }
  .boxHeadingTablet {
    font-size: 125%;
  }
  .contentListTablet {
    font-size: calc(0.75vw + 50%);
    margin-top: 2.7%;
    margin-bottom: 3%;
    top: -8%;
  }
  .contentListTablet > ul {
    padding-left: 0.2em !important;
  }
  .contentListTablet > ul > li {
    margin-bottom: calc(0.1vw + 2%);
  }
  .getStartedButtonTablet {
    border-radius: 30px;
    text-align: center;
    font-size: calc(0.7vw + 55%);
    padding: 0.7em 1em;
    position: absolute;
    bottom: 0em;
    left: 0em;
  }
}
@media (max-width: 640px) {
  .textBoxTablet {
    height: 58%;
  }
  .boxHeadingTablet {
    font-size: 120%;
  }
  .contentListTablet {
    font-size: calc(0.75vw + 50%);
    margin-top: 2.7%;
    margin-bottom: 3%;
    top: -8%;
  }
  .contentListTablet > ul {
    padding-left: 0em !important;
  }
  .contentListTablet > ul > li {
    margin-bottom: calc(0.1vw + 2%);
  }
  .getStartedButtonTablet {
    border-radius: 20px;
    text-align: center;
    font-size: 0.8em;
    padding: 0.7em 1em;
    position: absolute;
    bottom: 0em;
    left: 0em;
  }
}
@media (max-width: 590px) {
  .textBoxTablet {
    height: 62%;
    padding: 1em 1.5em 1em 1.5em;
  }
  .boxHeadingTablet {
    font-size: 120%;
  }
  .contentListTablet {
    font-size: calc(0.75vw + 55%);
    margin-top: 2.7%;
    margin-bottom: 3%;
    top: -8%;
  }
  .contentListTablet > ul {
    padding-left: 0em !important;
  }
  .contentListTablet > ul > li {
    margin-bottom: 0.3em;
    margin-bottom: calc(0.1vw + 4%);
  }
  .getStartedButtonTablet {
    border-radius: 20px;
    text-align: center;
    font-size: 0.7em;
    padding: 0.7em 1em;
    position: absolute;
    bottom: 0em;
    left: 0em;
  }
  .qrenciaForExpertsContent {
    padding: 8% 3% 18% 3%;
  }
  .blueTriangleImage {
    margin-left: -450px;
  }
}
@media (max-width: 576px) {
  .textBoxTablet {
    display: none;
  }
  .textBoxMobile {
    background-color: var(--qrencia-white);
    height: 56%;
    width: 100%;
    position: absolute;
    top: 50%;
    border-radius: 20px;
    padding: 1em 2em 1em 2em;
    display: block;
  }
  .expertTabletImage {
    display: none;
  }
  .expertMobileImage {
    width: 100%;
    object-fit: contain;
    border-radius: 20px;
    display: block;
  }
  .boxHeadingMobile {
    font-size: 110%;
    text-align: left;
    font-weight: 600 !important;
  }
  .getStartedButtonMobile {
    border-radius: 30px;
    width: 100%;
    text-align: center;
    font-size: calc(0.75vw + 60%);
    padding: 0.7em 1em;
    position: absolute;
    bottom: 0em;
    left: 0em;
  }
  .contentListMobile {
    position: absolute;
    font-size: 0.85em;
    margin-top: 2.7%;
    margin-bottom: 3%;
    top: -8% !important;
  }
  .contentListMobile > ul {
    padding-left: 0.4em !important;
    list-style-type: none;
    text-align: left;
  }
  .contentListMobile > ul > li {
    font-weight: 500 !important;
    margin-bottom: calc(0.1vw + 5%);
  }
  .contentListMobile > ul > li > img {
    height: 1.5em;
    width: 1.5em;
    margin-right: 0.5em;
  }
  .qrenciaForExpertsContent {
    padding: 8% 3% 16% 3%;
  }
  .qrenciaForExpertsSectionDesktop {
    display: none;
  }
  .qrenciaForExpertsSectionTablet {
    display: none;
  }
  .qrenciaForExpertsSectionMobile {
    display: block;
  }
}
@media (max-width: 530px) {
  .textBoxTablet {
    display: none;
  }
  .textBoxMobile {
    height: 57%;
    padding: 1em 2em 1em 2em;
  }
  .expertTabletImage {
    display: none;
  }
  .expertMobileImage {
    width: 100%;
    object-fit: contain;
    border-radius: 20px;
    display: block;
  }
  .boxHeadingMobile {
    font-size: 100%;
    text-align: left;
  }
  .contentListMobile {
    font-size: 0.8em;
    margin-top: 2.7%;
    margin-bottom: 3%;
    top: -3%;
  }
  .contentListMobile > ul {
    padding-left: 0em !important;
    text-align: left;
  }
  .contentListMobile > ul > li {
    margin-bottom: calc(0.1vw + 4%);
  }
  .getStartedButtonMobile {
    border-radius: 30px;
    width: 100%;
    text-align: center;
    font-size: calc(0.75vw + 60%);
    padding: 0.7em 1em;
    position: absolute;
    bottom: 0em;
    left: 0em;
  }
  .blueTriangleImage {
    margin-left: -470px;
  }
}
@media (max-width: 512px) {
  .blueTriangleImage {
    margin-left: -400px;
  }
}
@media (max-width: 500px) {
  .textBoxTablet {
    display: none;
  }
  .textBoxMobile {
    height: 62%;
    padding: 1em 1.5em 1em 1.5em;
  }
  .expertTabletImage {
    display: none;
  }
  .expertMobileImage {
    width: 100%;
    object-fit: contain;
    border-radius: 20px;
    display: block;
  }
  .boxHeadingMobile {
    font-size: 100%;
    text-align: left;
  }
  .contentListMobile {
    font-size: 0.8em;
    margin-top: 2.7%;
    margin-bottom: 3%;
    top: -10%;
  }
  .contentListMobile > ul {
    padding-left: 0em !important;
    text-align: left;
  }
  .contentListMobile > ul > li {
    margin-bottom: 0.6em;
  }
  .getStartedButtonMobile {
    border-radius: 30px;
    width: 100%;
    text-align: center;
    font-size: calc(0.75vw + 60%);
    padding: 0.7em 1em;
    position: absolute;
    bottom: 0em;
    left: 0em;
  }
  .blueTriangleImage {
    margin-left: -410px;
  }
}

@media (max-width: 470px) {
  .textBoxMobile {
    height: 66%;
  }
  .qrenciaForExpertsContent {
    padding: 8% 3% 19% 3%;
  }
}
@media (max-width: 447px) {
  .blueTriangleImage {
    margin-left: -340px;
  }

  .textBoxMobile {
    height: 68%;
  }
  .qrenciaForExpertsContent {
    padding: 8% 3% 28% 3%;
  }
  .contentListMobile > ul > li {
    margin-bottom: 0.5em !important;
    padding-left: 0em;
  }
}

@media (max-width: 435px) {
  .textBoxMobile {
    height: 72%;
    /* padding: 1em 1.5em 1em 1.5em; */
  }
  .qrenciaForExpertsContent {
    padding: 8% 3% 28% 3%;
  }
  .contentListMobile > ul > li {
    margin-bottom: 0.5em !important;
    padding-left: 0em;
  }
}
@media (max-width: 400px) {
  .textBoxMobile {
    height: 75%;
    padding: 1em 1.5em 1em 1.5em;
  }
  .contentListMobile {
    font-size: 0.78em;
    margin-top: 2.7%;
    margin-bottom: 3%;
    top: -8%;
  }
  .contentListMobile > ul > li {
    margin-bottom: 0.45em !important;
    padding-left: 0em;
  }
  .contentListMobile > ul {
    padding-left: 0.5em !important;
  }
}

@media (max-width: 380px) {
  .textBoxMobile {
    height: 75%;
    padding: 1em 1.5em 1em 1.5em;
  }

  .contentListMobile > ul > li {
    margin-bottom: 0.3em !important;
    padding-left: 0em;
  }

  .getStartedButtonMobile {
    border-radius: 30px;
    width: 100%;
    text-align: center;
    font-size: calc(0.75vw + 60%);
    padding: 0.65em 1em;
    position: absolute;
    bottom: 0em;
    left: 0em;
  }
}
