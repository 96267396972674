.submitButton {
  font-size: 0.8em;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}
.modalBody {
  height: 60vh !important;
  overflow-y: scroll;
  scrollbar-width: none;
}
.editButton {
  font-size: 0.8em;
  margin-top: 0.1em;
  margin-bottom: 0.9em;
  margin-right: 0.4em;
}
.responseCardContainer {
  position: relative;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.responseCard {
  padding: 1.5em 1.5em 2em 1em;
  border-radius: 8px;
  border-top-left-radius: 0;
  background-color: var(--qrencia-nudges-card-background);
  margin: 0 0 1em 1em;
}

.editedText {
  font-size: 0.7em;
  font-weight: 400 !important;
  color: var(--qrencia-placeholder-color);
  position: absolute;
  top: 0.4em; 
  left: 2.1em;
}

.responseCard p,
.responseCard ul,
.responseCard ol {
  font-size: 0.76em !important;
  margin-bottom: 0.2em;
}
.responseCard::before {
  content: "";
  border-width: 0px 14px 16px 0px;
  border-style: solid;
  border-color: transparent #fff;
  position: absolute;
  top: 0;
  left: 2px;
  transform: rotate(180deg);
  background-color: var(--qrencia-nudges-card-background);
}
.responseDateAndTime {
  font-size: 0.7em;
  font-weight: 400 !important;
  position: absolute;
  bottom: 1.2em;
  right: 1.2em;
  color: var(--qrencia-placeholder-color);
}
.previousRemarksHeading {
  font-size: 1em;
  margin: 0.5em 0.5em 1.5em 0.5em;
}
.editIcon {
  display: inline;
  position: absolute;
  text-align: right;
  top: 3px;
  right: 5px;
  cursor: pointer;
}
.deleteIcon {
  display: inline;
  position: absolute;
  text-align: right;
  top: 3px;
  right: 30px;
  cursor: pointer;
}
.deleteMessage {
  font-size: 1em;
  margin: 1em 1em 2em 1em;
}
.heading {
  font-size: 1.2em;
}
.loaderIcon {
  text-align: center;
  font-size: 2em !important;
  color: var(--qrencia-dark-blue) !important;
}
.editFeedbackContainer {
  margin-top: 2em;
}
.reviewContainer {
  margin-top: 0.3em;
  margin-bottom: 0.5em;
  padding: 0.6em 0.8em;
}
.profileImageSection {
  display: flex;
  justify-content: flex-start;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.reviewInitials {
  height: 2.8em;
  width: 2.8em;
  background-color: var(--qrencia-course-skill-name-color);
  color: var(--qrencia-text-black);
  text-align: center;
  font-size: 0.9em;
  line-height: 2.5em;
  font-weight: 500;
  min-width: 2.5em;
  margin-right: 1em;
}
.activityName {
  font-size: 0.8em;
  font-weight: 500 !important;
  color: var(--qrencia-grey-text-color);
  opacity: 0.7;
  margin-top: 5px;
}
.reviewerName {
  font-size: 0.9em;
  font-weight: 450 !important;
  color: var(--qrencia-dark-blue) !important;
}
.profilePicture {
  height: 2.8em;
  width: 2.8em;
  object-fit: cover;
}
@media (min-width: 1700px) {
  .reviewerName {
    font-size: 0.98em;
    font-weight: 450 !important;
    color: var(--qrencia-dark-blue) !important;
  }
  .profilePicture {
    height: 2.6em;
    width: 2.6em;
  }
}

@media (max-width: 400px) {
  .reviewerName {
    font-size: 0.7em;
  }
  .reviewInitials {
    height: 2.4em;
    width: 2.4em;
    font-size: 0.85em;
    line-height: 2.4em;
    font-weight: 500;
    min-width: 2.4em;
    margin-right: 1em;
  }
}
