.viewJudgeContainer {
  font-size: 0.79em !important;
  font-weight: 400;
  padding: 0.5em;
}
.judgeHeadingText {
  font-size: 1.2em;
  margin: 1em 0em 1em 0.6em;
  font-weight: 550;
}
.viewJudgesRow {
  justify-content: space-between;
}
.addViewJudgesContainer {
  padding: 1em 0em;
}
.nextBtn {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 100%;
  padding: 0.6em;
  text-align: right;
}
.nextBtn > Button {
  font-size: 0.8em;
  padding: 0.6em 2em;
}
@media (max-width: 780px) {
  .judgeHeadingText {
    font-size: 1em;
  }
}

@media (max-width: 580px) {
  .judgeHeadingText {
    font-size: 0.9em;
  }
  .viewJudgeContainer {
    font-size: 0.72em !important;
  }
  .alertMessageContainer {
    padding: 0px 10px;
  }
}
