.datePresets {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
.datePresetLabel {
  color: var(--qrencia-dark-blue);
  font-weight: 500 !important;
  cursor: pointer;
}
.customDateSelectButton {
  font-size: 0.8em;
  text-align: left;
}
.fromText {
  line-height: 2.5em;
  margin-left: -10px !important;
  font-weight: 450px !important;
}
.buttonSection {
  margin-top: 1.5em;
  text-align: left;
}
