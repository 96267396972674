.card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 4%;
  border: 1px solid var(--qrencia-cards-border);
  height: 9em;
  cursor: pointer;
}

.imageContainer {
  width: 40%;
  padding: 0.6em;
}

.cardImage {
  width: 100%;
  height: 7.5em;
  object-fit: fill;
  border-radius: 8px;
}
.cardTimeAndDate {
  font-size: 0.68em;
  display: flex;
  font-weight: 600 !important;
  margin: 1% 1% 1% 0;
  color: var(--qrencia-text-black) !important;
  margin-bottom: 0.4em !important;
}
.cardBody {
  width: 57%;
  height: 7em;
  padding: 0.1em 0.3em 0.6em 0.3em;
  position: relative;
  margin-top: -1em;
}

.rank {
  background-color: var(--qrencia-competition-rank-background);
  font-size: 0.7em;
  font-weight: 600 !important;
  border-radius: 10px;
  width: fit-content;
  text-align: center;
  padding: 1% 5%;
}

.noRankText {
  background-color: var(--qrencia-competition-rank-background);
  font-size: 0.7em;
  font-weight: 500 !important;
  border-radius: 10px;
  width: fit-content;
  text-align: center;
  padding: 1% 5%;
}

.noRankText svg {
  margin-top: -2px;
}

.cardTitle {
  display: flex;
  justify-content: space-between;
  font-weight: 600 !important;
  font-size: 0.85em;
  margin: 0.3em 0em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.titleTooltipMessage {
  background-color: var(--qrencia-white);
  font-weight: 600 !important;
  color: var(--qrencia-text-black) !important;
  font-size: 0.88em;
  z-index: 100;
  max-width: 30em;
  transition: all 0.1s ease;
  border: 1px solid var(--qrencia-grey-border);
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.85em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  width: 18em;
  z-index: 99;
}
.iconSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: -13px;
  right: 0em;
}

.iconSection > svg,
.iconSection > a > svg,
.iconSection > div > svg {
  margin: 0em 0.3em;
  cursor: pointer;
  border: none;
  font-size: 1em;
  outline: none;
}

.performanceReportLink {
  text-decoration: none;
  color: var(--qrencia-text-black);
}

.performanceReportLink:hover {
  text-decoration: none;
  color: var(--qrencia-text-black);
}

.activityTypeCompetition {
  font-size: 0.6em;
  font-weight: 500 !important;
  color: var(--qrenica-competition-differentiator-color);
  margin-top: -5px;
}

.activityTypeCourse {
  font-size: 0.6em;
  font-weight: 500 !important;
  color: var(--qrenica-course-differentiator-color);
  margin-top: -5px;
}

.nudgesContainer {
  padding: 0 !important;
}

.nudgeModalTitle {
  font-size: 1em;
  font-weight: 550;
  padding-left: 0.5em;
}
.downloadCertificateLink > span {
  cursor: pointer;
}
@media (min-width: 1385px) and (max-width: 1560px) {
  .card {
    height: 9em;
  }
  .iconSection {
    bottom: -20px;
  }
  .cardTimeAndDate {
    font-size: 0.6em;
  }
}
@media (max-width: 1385px) and (min-width: 1348px) {
  .card {
    height: 10em;
  }
  .iconSection {
    bottom: -20px;
  }
  .cardBody {
    margin-top: -2em;
  }
  .imageContainer {
    margin-top: -1em;
  }
  .cardTimeAndDate {
    font-size: 0.62em;
  }
}
@media (min-width: 1250px) and (max-width: 1347px) {
  .card {
    height: 11em;
  }
  .iconSection {
    bottom: -30px;
  }
  .cardBody {
    margin-top: -2.5em;
  }
  .imageContainer {
    margin-top: -1em;
  }
  .cardTimeAndDate {
    font-size: 0.62em;
  }
}

@media (min-width: 990px) and (max-width: 1290px) {
  .iconSection > svg,
  .iconSection > div > svg {
    font-size: 1em;
  }
  .cardTimeAndDate {
    font-size: 0.58em;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .courseDateTime {
    font-size: 0.9em;
    line-height: 1.6;
  }
  .card {
    height: 12em;
  }
  .iconSection {
    bottom: -40px;
  }
  .cardTitle {
    font-size: 0.8em;
  }

  .iconSection > svg,
  .iconSection > div > svg {
    font-size: 1em;
  }
}

@media (max-width: 767px) {
  .courseDateTime {
    font-size: 0.9em;
    line-height: 1.6;
  }

  .cardTitle {
    font-size: 0.8em;
  }
}

@media (max-width: 480px) {
  .nudgesContainer {
    padding: 0 10px 0 10px !important;
  }
}

@media (min-width: 992px) and (max-width: 1070px) {
  .nudgesTimeDate {
    font-size: 0.88em;
  }
}

@media (min-width: 619.5px) and (max-width: 767px) {
  .nudgesTimeDate {
    font-size: 0.9em;
  }
}

@media (min-width: 576px) and (max-width: 585px) {
  .nudgesTimeDate {
    font-size: 0.9em;
  }
}
@media (min-width: 480px) and (max-width: 486px) {
  .iconSection {
    bottom: -23px;
  }
}
@media (min-width: 398.2px) and (max-width: 460px) {
  .iconSection {
    bottom: -23px;
  }
}
@media (max-width: 398px) {
  .card {
    height: 11.2em;
  }
  .iconSection {
    bottom: -25px;
  }
  .cardTimeAndDate {
    font-size: 0.6em;
  }
}
