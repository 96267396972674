.skillsCardsSection {
  background: var(--qrencia-white);
  display: flex;
  padding: 1% 13%;
}

.cardBody {
  padding: 7% 5% 3% 5%;
  margin-top: 3%;
  margin-bottom: 2%;
}

.skillsCard {
  margin-top: 4%;
  margin-bottom: 2%;
}

.ageRange {
  background: var(--qrencia-grey-border);
  border-radius: 1.2rem;
  width: fit-content;
  font-size: 0.75em;
  font-weight: 600;
  padding: 1.4% 4%;
  text-align: left;
}

.skillName {
  font-weight: 700 !important;
}

.skillsCard a,
p {
  text-decoration: none;
  color: var(--qrencia-text-black);
  font-weight: 700;
}

.loadMoreDiv {
  padding: 1em;
}

.loadMore {
  text-align: center;
  background: var(--qrencia-white);
}

.loadMorebtn {
  background: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  padding: 0.5em 2em;
  text-decoration: none;
  letter-spacing: 0.03rem;
  color: var(--qrencia-white);
  background: var(--qrencia-dark-blue);
  border-radius: 0.3em;
  border: 0.1em solid var(--qrencia-dark-blue);
  font-size: 0.8em;
  font-weight: 500;
}

.loadMorebtn:hover {
  color: var(--qrencia-text-black);
  background: var(--qrencia-white);
  border: 0.1em solid var(--qrencia-text-black);
}

@media (min-width: 767px) and (max-width: 1290px) {
  .skillsCardsSection {
    padding: 1% 9% 1% 9%;
  }
}

@media (max-width: 767px) {
  .skillsCardsSection {
    padding: 1% 3% 1% 3%;
  }
}

@media (min-width: 1120px) and (max-width: 1290px) {
  .ageRange {
    background: var(--qrencia-grey-border);
    border-radius: 1.2rem;
    font-size: 0.68em;
    font-weight: 600;
    padding: 1.4% 4%;
    text-align: center;
  }

  .skillName {
    font-weight: 700 !important;
    text-align: left;
    margin-top: 6%;
    font-size: 1em;
  }

}

@media (min-width: 767px) and (max-width: 1119px) {
  .ageRange {
    background: var(--qrencia-grey-border);
    border-radius: 1.2rem;
    font-size: 0.68em;
    font-weight: 600;
    padding: 1.4% 4%;
    text-align: center;
  }

  .skillName {
    font-size: 0.9em;
  }

}

@media (max-width: 767px) {
  .ageRange {
    background: var(--qrencia-grey-border);
    border-radius: 1.2rem;
    font-size: 0.7rem;
    font-weight: 700;
    padding: 1.2% 4%;
  }

}

@media (max-width: 580px) {

  .ageRange {
    border-radius: 1.2rem;
    font-size: 0.68em;
    font-weight: 600;
    padding: 1.4% 5%;
    text-align: center;
  }

  .skillName {
    font-size: 0.8em;
  }

  .skillsCard {
    margin-top: 0%;
    margin-bottom: 6%;
  }
}

@media (max-width: 480px) {
  .loadMorebtn {
    border-radius: 0.2em;
    padding: 1.5% 6%;
    letter-spacing: 0.03rem;
    font-size: 0.7em;
    font-weight: 500;
  }
}
