.buttonSection {
  align-items: center;
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
}
.submitButton {
  font-size: 0.8em;
  margin-right: 0.4em;
}
.modalBody {
  height: 40vh !important;
  overflow-y: scroll;
}
.editButton {
  font-size: 0.8em;
  margin-top: 0.1em;
  margin-bottom: 0.9em;
  margin-right: 0.4em;
}
.responseCardContainer {
  position: relative;
}

.responseCard {
  padding: 1em 1em 2em 1em;
  border-radius: 8px;
  border-top-left-radius: 0;
  background-color: var(--qrencia-nudges-card-background);
  margin: 0 0 1em 1em;
}

.responseCard p,
.responseCard ul,
.responseCard ol {
  font-size: 0.76em !important;
}
.responseCard::before {
  content: "";
  border-width: 0px 14px 16px 0px;
  border-style: solid;
  border-color: transparent #fff;
  position: absolute;
  top: 0;
  left: 2px;
  transform: rotate(180deg);
  background-color: var(--qrencia-nudges-card-background);
}
.responseDateAndTime {
  font-size: 0.7em;
  font-weight: 400 !important;
  position: absolute;
  bottom: 0.5em;
  right: 1.2em;
  color: var(--qrencia-placeholder-color);
}
.previousRemarksHeading {
  font-size: 1em;
  margin-top: 1em;
}
.editIcon {
  width: 100%;
  position: absolute;
  text-align: right;
  top: 3px;
  right: 5px;
  cursor: pointer;
}
.heading {
  font-size: 1.2em;
}
