.heading {
  font-size: 1em;
  font-weight: 600;
  color: var(--qrencia-text-black);
  margin: 0.5em 0.5em 1em 0.5em;
}

.participantDetailsTable {
  overflow: scroll;
  margin-bottom: 2%;
}

.tableLabelColumn {
  width: 35% !important;
  font-weight: 500;
}

.tableHeaderOne {
  min-width: 7em !important;
  font-weight: 500;
  font-size: 0.85em;
}

.tableDescriptionColumnOne {
  min-width: 7em !important;
  font-size: 0.85em;
}

.goBackButton {
  font-size: 1em;
  color: var(--qrencia-dark-blue);
  margin-bottom: 0.5em;
  margin-top: 0.2em;
}

.tableDescriptionColumn {
  width: 65% !important;
}

.suggestiveCompetitionSearchComponent {
  background-color: var(--qrencia-white);
  width: 100%;
  padding: 1em;
  min-height: 4.2em;
  border-radius: 5px;
}

.errorMessageDiv {
  margin-bottom: -1em;
}

.suggestiveSearchInputContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.selectButton {
  font-size: 0.8em !important;
  padding: 0.61em 1em;
}

.suggestiveCompetitionSearchComponent input {
  flex: 1;
}

.dashboardContainer {
  background-color: var(--qrencia-white);
  width: 100%;
  padding: 1em;
  border-radius: 5px;
  margin-top: 2em;
}

.dashboardCards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.viewButton {
  background-color: var(--qrencia-white);
  outline: none;
  border: none;
}

.viewButton>svg {
  color: var(--qrencia-dark-blue);
  font-size: 1.2em;
}

.cardTitle {
  font-size: 1.18em;
  /* color: var(--qrencia-dark-blue); */
  color: var(--qrencia-white);
  font-weight: 500 !important;
  text-align: center;
}

.cardCount {
  font-size: 1.8em;
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: -0.2em !important;
}

.nudgeResponseSection {
  font-size: 1em;
}

.enrolmentCount {
  color: var(--qrencia-white);
  /* color: var(--qrenica-competition-differentiator-color); */
}

.creatorNameHeading {
  font-size: 1em;
  margin-top: 1em;
  color: var(--qrencia-dark-blue);
}

.tableDetailsHeading {
  font-size: 1em;
  font-weight: 500;
  padding-left: 0.9em;
  margin-bottom: 0.4em;
  margin-top: 1.5em;
  margin-bottom: 0.6em;
}

.enrolmentCard {
  color: var(--qrencia-white);
  background-color: var(--qrenica-competition-differentiator-color);
}

.attemptCard {
  background-color: var(--qrenica-course-differentiator-color);
}

.submissionCard {
  background-color: var(--qrenica-nudge-differentiator-color);
}

.absentCard {
  background-color: var(--qrencia-mentorship-differentiator-color);
}

.notAttemptCard {
  background-color: var(--qrencia-workshop-differentiator-color);
}

/* .errorMessageDiv {
    margin-bottom: -1em;
  } */
.attemptCount {
  color: var(--qrencia-white);
  /* color: var(--qrenica-course-differentiator-color); */
}

.submissionCount {
  color: var(--qrencia-white);
  /* color: var(--qrenica-nudge-differentiator-color); */
}

.absentCount {
  color: var(--qrencia-white);
  /* color: var(--qrencia-workshop-differentiator-color); */
}

.notAttemptCount {
  color: var(--qrencia-white);
  /* color: var(--qrencia-mentorship-differentiator-color); */
}

.detailsLabel {
  color: var(--qrencia-dark-blue);
  font-size: 0.85em;
  font-weight: 500 !important;
}

.attemptsPercentage {
  color: var(--qrenica-course-differentiator-color);
  height: 1.2em;
  font-size: 0.6em !important;
}

.detailsLinkLabel,
.detailsLinkLabel:hover,
.detailsLinkLabel:active,
.detailsLinkLabel:focus {
  text-decoration: none;
  font-size: 0.75em !important;
  cursor: pointer;
  color: var(--qrencia-text-black);
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg);
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  padding: 1px 8px;
}

.countCards {
  margin-right: 2.2em;
  margin-top: 1.2em;
  width: 12em;
  border: none;
  outline: none;
}

.creatorBrandingPageLink {
  text-decoration: none;
  color: var(--qrencia-text-black);
}

.detailsText {
  font-size: 0.85em !important;
}

.detailsText p {
  font-size: 1em !important;
}

.detailsColumContainer {
  margin-top: 1em;
}

.percentageSpan {
  font-size: 0.5em;
  color: var(--qrencia-white);
  font-weight: 500 !important;
}

.obrBackButton {
  font-size: 1.5em;
  color: var(--qrencia-white);
  background-color: var(--qrencia-dark-blue);
  border-radius: 1em;
  cursor: pointer;
}

.enrolledStudentHeading {
  font-size: 1em;
  font-weight: 500;
  padding-left: 0.9em;
  margin-bottom: 0.2em;
}

.studentListSection {
  margin-top: 2em;
}

.greenText {
  color: var(--qrencia-success-green);
}

.redText {
  color: var(--qrencia-error-red);
}

@media (max-width: 990px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 14em;
  }
}

@media (max-width: 584px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 11.2em;
  }

  .dashboardCards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 584px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 11.2em;
  }

  .dashboardCards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 494px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 16em;
  }

  .dashboardCards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .suggestiveCompetitionSearchComponent>button {
    font-size: 0.7em;
    padding: 0.5em 1em;
  }

  .selectButton {
    height: 2.4em;
    font-size: 0.6em;
    line-height: 1em;
  }
}