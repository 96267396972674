.containerSearchSkill {
  display: flex;
  justify-content: flex-start;
  background: var(--qrencia-white);
  padding: 0% 12%;
}
.quizSearchContainer {
  display: flex;
  justify-content: flex-start;
  background: var(--qrencia-white);
  padding: 3% 12% 1% 12%;
}

.containerSearchSkill > div {
  margin: 0.2em 0.9em;
}
.quizSearchContainer > div {
  margin: 0.2em 0.9em;
}
.containerSearchItem {
  width: 22vw;
  font-size: 1em;
  color: var(--qrencia-text-black) !important;
}

.containerSearchItem input {
  font-size: 0.8em;
  width: 22vw;
  color: var(--qrencia-text-black);
  margin-right: 1em;
}

.resetButton {
  margin-top: 0.4%;
  height: 34px;
  margin-left: 15px;
  font-size: 0.85em;
}
.resetButtonQuiz {
  margin-top: 0.4%;
  height: 34px;
  margin-left: 15px;
  font-size: 0.7em;
}
.noDataError {
  background-color: var(--qrencia-white);
  padding: 2% 13%;
  padding-bottom: 3.5%;
}

.noDataText {
  background-color: var(--qrencia-white);
  padding: 1.5% 2%;
  padding-bottom: 3.5%;
  border: 1px solid var(--bs-border-color);
  border-radius: 9px;
  width: 74%;
  margin: 3% auto;
}
.noDataText p {
  font-size: 0.9em !important;
}
.headingBlue {
  color: var(--qrencia-dark-blue);
  margin-bottom: 2%;
}
.exploreLink {
  color: var(--qrencia-dark-blue) !important;
  font-weight: 600;
}
.errorText {
  padding: 3em 1em;
  text-align: center;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  color: var(--qrencia-grey-text);
  font-style: italic;
  font-weight: 400 !important;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid var(--qrencia-cards-border);
}

.shareIconPannelContainer {
  color: var(--qrencia-white);
  display: grid;
  position: fixed;
  padding: 1% 0.8% 0.5% 0.7%;
  top: 50%;
  right: 0%;
  background: var(--qrencia-dark-blue);
  border-top: 2px solid var(--qrencia-white);
  border-bottom: 2px solid var(--qrencia-white);
  border-left: 2px solid var(--qrencia-white);
  border-radius: 10px 0px 0px 10px;
  z-index: 500;
}

.stickyHeader {
  padding: 10px 0; /* Optional: Add padding for spacing */
  background-color: #fff; /* Background color to match your header */
}

/* Adjustments for mobile view */
@media (max-width: 768px) {
  .stickyHeader {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
    z-index: 450; /* Ensure it's above other elements */
    padding: 6% 0; /* Adjust padding for smaller screens */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
  }
}
@media (min-width: 767px) and (max-width: 1290px) {
  .containerSearchSkill {
    padding: 0% 8% 0% 8%;
  }
  .quizSearchContainer {
    padding: 3% 8% 1% 8%;
  }
  .noDataError {
    padding: 2% 9% 3.5% 9%;
  }

  .containerSearchSkill > div {
    margin: 0.2em 0.7em;
  }
  .quizSearchContainer > div {
    margin: 0.2em 0.7em;
  }
  .headingBlue {
    font-size: 1rem;
  }
  .noDataText {
    font-size: 0.8em !important;
    margin-bottom: 12%;
    margin-top: 4%;
  }
  .noDataSubText {
    margin-top: 4%;
  }
}

@media (min-width: 900px) and (max-width: 1290px) {
  .containerSearchItem {
    width: 19em;
  }

  .containerSearchItem input {
    width: 24em;
  }
  .headingBlue {
    font-size: 1rem;
  }
  .noDataText {
    font-size: 0.8em !important;
    margin-bottom: 12%;
    margin-top: 4%;
  }
  .noDataSubText {
    margin-top: 2%;
  }
}

@media (min-width: 767px) and (max-width: 899px) {
  .containerSearchItem {
    width: 18em;
  }

  .containerSearchItem input {
    width: 24em;
  }

  .containerSearchItem input {
    font-size: 0.75em;
  }

  .headingBlue {
    font-size: 1rem;
  }
  .noDataText {
    font-size: 0.8em !important;
    margin-bottom: 12%;
    margin-top: 4%;
  }
  .noDataSubText {
    margin-top: 2%;
  }
}

@media (max-width: 767px) {
  .containerSearchSkill {
    padding: 0% 10% 0% 10%;
  }
  .quizSearchContainer {
    padding: 3% 10% 1% 6%;
  }
  .noDataError {
    padding: 3% 3% 5% 3%;
  }

  .containerSearchSkill > div {
    margin: 0.8em 0.8em;
  }
  .quizSearchContainer > div {
    margin: 0.8em 0.8em;
  }

  .containerSearchItem {
    width: 15.5em;
  }

  .containerSearchItem input {
    width: 24em;
  }

  .containerSearchItem input {
    font-size: 0.7em;
  }

  .resetButton {
    margin-top: 13px;
    height: 33px;
    margin-left: 30px;
    font-size: 0.75em;
  }
  .resetButtonQuiz {
    margin-top: 13px;
    height: 33px;
    margin-left: 30px;
    font-size: 0.7em;
  }
  .headingBlue {
    font-size: 0.9rem;
  }
  .noDataText {
    font-size: 0.7em !important;
    margin-bottom: 12%;
    margin-top: 4%;
  }
  .noDataSubText {
    margin-top: 4%;
  }
  .stickyHeader {
    padding-top: 3%;
  }
}
@media (max-width: 650px) {
  .stickyHeader {
    padding-top: 4.5%;
  }
}
@media (max-width: 578px) {
  .containerSearchSkill {
    padding: 0% 18% 0% 18%;
  }
  .quizSearchContainer {
    padding: 3% 18% 1% 6%;
  }
  .resetButton,
  .resetButtonQuiz {
    margin-left: 30px;
  }
  .headingBlue {
    font-size: 0.9rem;
  }
  .noDataText {
    font-size: 0.7em !important;
    margin-bottom: 10%;
    margin-top: 4%;
  }
  .noDataSubText {
    margin-top: 2%;
  }
  .stickyHeader {
    padding-top: 5.5%;
    padding-bottom: 2%;
  }
}
@media (max-width: 550px) {
  .containerSearchItem {
    width: 70%;
  }

  .containerSearchItem input {
    width: 100%;
  }
  .stickyHeader {
    padding-top: 7%;
  }

  .containerSearchItem input {
    font-size: 0.5em;
  }

  .containerSearchItem select {
    font-size: 0.5em;
  }
  .resetButton,
  .resetButtonQuiz {
    height: 30px;
    margin-left: 14px;
  }
}
@media (max-width: 480px) {
  .resetButton {
    padding: 0% !important;
    font-size: 0.6em;
    height: 22px;
    width: 13%;
    margin-top: 1.8em;
  }
  .resetButtonQuiz {
    padding: 0% !important;
    font-size: 0.58em;
    height: 22px;
    width: 13%;
    margin-top: 1.8em;
  }
  .containerSearchItem input {
    padding: 2% 4% !important;
  }
  .containerSearchItem select {
    padding: 1% !important;
  }
  .quizSearchContainer {
    padding: 1% 12% 1% 18%;
  }
  .headingBlue {
    font-size: 0.9rem;
  }
  .noDataText {
    font-size: 0.65em !important;
    margin-bottom: 10%;
    margin-top: 4%;
  }
  .noDataSubText {
    margin-top: 2%;
  }
}
@media (max-width: 420px) {
  .expertImage {
    height: 200px !important;
  }
  .containerSearchSkill {
    padding: 1% 12% 1% 12%;
  }
  .quizSearchContainer {
    padding: 1% 12% 1% 12%;
  }
  .resetButton,
  .resetButtonQuiz {
    margin-left: 9px;
  }
}

@media (max-width: 370px) {
  .containerSearchItem input {
    font-size: 0.45em;
  }
  .resetButton,
  .resetButtonQuiz {
    margin-left: 8px;
  }
}
