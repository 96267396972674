.obrGridTable {
  border: 1px solid var(--qrencia-grid-grey);
  border-collapse: collapse;
}

.obrGridTable td {
  padding: 8px;
}

.obrGridHeader {
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  font-weight: bold;
}
.noDataContainer {
  border: var(--qrencia-grid-grey);
  height: 4em;
  width: 100%;
  background-color: var(--qrencia-white);
  border-radius: 10px;
}
.noDataContainer > p {
  margin-top: 1.5em;
}
.container {
  padding: 1em 0.6em;
  background-color: var(--qrencia-white);
  border-radius: 0.8em;
}
.cardsContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0.6em;
  margin-top: 0.5em;
}
.learningActivityCards {
  border-left: 0.5em solid var(--qrencia-dark-blue);
  border-radius: 0.4em;
  color: var(--qrencia-dark-blue);
  padding: 0em;
}
.learningActivityCardsIcon {
  color: var(--qrencia-dark-blue);
}
.learningActivityCardsColumn {
  padding: 1em;
  font-size: 0.8em;
  color: var(--qrencia-dark-blue);
}
.showGrid {
  display: block;
  /* margin-bottom: 3em; */
}
.showCards {
  display: none;
  /* margin-bottom: 3em; */
}
.textGreen {
  color: var(--qrencia-success-green);
}

.textRed {
  color: var(--qrencia-error-red);
}
.textBlack {
  color: var(--qrencia-text-black);
}

.eventTypeField {
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg);
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  font-weight: 600 !important;
}

span.eventTypeField {
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg) !important;
  /* color: var(--qrencia-text-black); */
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  font-weight: 600 !important;
}
/* .obrGridActionBox {
  margin-top: 0.9em;
} */
.loadMoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
}
.obrCardsButtonContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0.6em;
  margin: 0.5em 0;
}
@media (max-width: 991px) {
  .showGrid {
    display: none;
  }
  .showCards {
    display: block;
  }
}
