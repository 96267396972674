.expertSection {
  background-color: var(--qrencia-white);
  padding: 1% 12%;
}

.expertTitle {
  font-size: 0.9em;
  color: var(--qrencia-text-black);
  font-weight: 600;
}

.expertTitle:hover {
  color: var(--qrencia-text-black);
}

.linkedText {
  text-decoration: none;
  color: var(--qrencia-text-black);
}

.linkedText:hover {
  color: var(--qrencia-text-black);
}
.followSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 4px;
  border-radius: 2px;
  padding-bottom: 2px;
}
.starSection {
  margin-right: 2px;
}
.ratingStars {
  color: var(--qrencia-golden-color) !important;
  font-size: 0.65em !important;
  margin-left: 2px;
}
.halfStar {
  color: var(--qrencia-golden-color) !important;
  font-size: 0.86em !important;
  margin-left: 2px;
  margin-right: 1px;
  margin-top: -0.5px;
}
.topExpertFlag {
  font-size: 0.65em;
  /* margin-left: auto; */
  /* margin-right: auto; */
  margin-right: 2px;
  margin-top: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.topExpertFlag p {
  color: var(--qrencia-grey-text);
}
.topExpertFlag > svg {
  color: var(--qrencia-dark-blue);
}
.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.8em;
  z-index: 100;
  width: 60%;
  opacity: 0.95;
}
.courseTitleTooltip {
  background-color: var(--qrencia-white);
  font-weight: 600 !important;
  color: var(--qrencia-text-black) !important;
  font-size: 0.88em;
  z-index: 100;
  max-width: 30em;
  border: 1px solid var(--qrencia-grey-border);
}

.courseTitleTooltip > span {
  background-color: var(--qrencia-white);
  font-weight: 500 !important;
  color: var(--qrencia-text-black) !important;
  font-size: 0.8em;
  z-index: 100;
  max-width: 28em;
}

.expertCard {
  border: 1px solid var(--qrencia-cards-border);
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 4%;
  margin-bottom: 12px;
  width: 100%;
}
.expertCard:hover {
  transition: 0.3s all ease-in-out;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.followUnfollowButton {
  margin-top: 4px;
  font-size: 0.6em;
  padding: 1px 7px;
  border-radius: 12px;
}
.following {
  color: var(--qrencia-white);
  background-color: var(--qrencia-dark-blue);
}

.follow {
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-white);
  border: 1px solid var(--qrencia-dark-blue);
  font-weight: 500;
}
.expertImage {
  height: 215px;
  border-radius: 6px 6px 0px 0px;
  object-fit: cover;
}

.blueText {
  color: var(--qrencia-dark-blue);
}

.expertCardBody {
  padding: 5% 2.5% 3% 2.5%;
}
.expertCardBorder {
  border-top: 2px solid var(--qrencia-dark-blue);
}
.academyCardBorder {
  border-top: 2px solid var(--qrencia-academy-card-top-border);
}
.sectionHeading {
  color: var(--qrencia-text-black);
  font-weight: 600;
  line-height: 1.2em;
  font-size: 2em;
  text-align: center;
  margin-bottom: 1em;
}

.singleLine {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.expertSkills {
  font-size: 0.74em;
  width: fit-content;
  color: var(--qrencia-grey-text);
}
.expertCity {
  font-size: 0.74em;
  width: fit-content;
  margin-top: 4px;
  color: var(--qrencia-grey-text);
}
.loadMoreDiv {
  padding: 1em;
}

.loadMore {
  text-align: center;
  background: var(--qrencia-white);
}

.loadMorebtn {
  background: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  padding: 0.5em 2em;
  text-decoration: none;
  letter-spacing: 0.03rem;
  color: var(--qrencia-white);
  background: var(--qrencia-dark-blue);
  border: 0.1em solid var(--qrencia-dark-blue);
  font-size: 0.8em;
  border-radius: 0.3em;
  font-weight: 500;
}

.loadMorebtn:hover {
  color: var(--qrencia-text-black);
  background: var(--qrencia-white);
  border: 0.1em solid var(--qrencia-text-black);
}
.leadImageText {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1em;
  font-weight: 500;
}
.leadImageText > span {
  color: var(--qrencia-text-black) !important;
}
/* .spanTextAgency {
  color: var(--qrencia-academy-card-top-border) !important;
} */
@media (min-width: 991px) and (max-width: 1110px) {
  .followSection {
    min-height: 2.8em;
  }
  .ratingStars {
    font-size: 0.62em !important;
    margin-left: 1.5px;
  }
  .halfStar {
    font-size: 0.82em !important;
    margin-left: 1.5px;
    margin-right: 1px;
    margin-top: -1px;
  }
  .followUnfollowButton {
    padding: 1px 6px;
  }
  .topExpertFlag {
    font-size: 0.62em;
    margin-right: 1.5px;
  }
  .followUnfollowButton {
    margin-top: 4px;
    font-size: 0.54em;
    padding: 1px 5px;
    border-radius: 12px;
  }
}
@media (max-width: 1020px) and (min-width: 991px) {
  .ratingStars {
    font-size: 0.62em !important;
    margin-right: 2.5px;
  }
  .halfStar {
    font-size: 0.82em !important;
    margin-right: 2.5px;
    margin-top: -0.8px;
  }
}
@media (min-width: 767px) and (max-width: 820px) {
  .followSection {
    min-height: 2.9em;
  }
  .ratingStars {
    margin-right: 3.5px;
  }
  .halfStar {
    font-size: 0.85em !important;
    margin-left: 1.5px;
    margin-right: 1px;
    margin-top: -0.8px;
  }
}

@media (max-width: 767px) {
  .expertSection {
    padding: 1% 6% 1% 6%;
  }
}

@media (min-width: 1100px) and (max-width: 1185px) {
  .expertTitle {
    font-size: 0.8em !important;
  }
}
@media (min-width: 990px) and (max-width: 1100px) {
  .expertTitle {
    font-size: 0.76em !important;
  }
}

@media (max-width: 990px) {
  .expertTitle {
    font-size: 0.8em !important;
  }
}

@media (min-width: 990px) and (max-width: 1200px) {
  .expertImage {
    height: 180px !important;
  }
}

@media (min-width: 767px) and (max-width: 990px) {
  .expertImage {
    height: 200px !important;
  }
}

@media (min-width: 767px) and (max-width: 1290px) {
  .expertSection {
    padding: 1% 8%;
  }
}

@media (max-width: 767px) {
  .skillsCardsSection {
    padding: 1% 3% 1% 3%;
  }

  .expertImage {
    height: 215px !important;
  }

  .backgrounImageSection {
    padding-top: 0%;
  }
  .expertSection {
    padding: 1% 10% 1% 10%;
  }
}
@media (max-width: 580px) {
  .expertCard {
    margin-top: 0%;
    margin-bottom: 6%;
  }
  .expertImage {
    /* height: 220px !important; */
    /* object-fit: fill; */
    height: auto;
    object-fit: cover !important;
    aspect-ratio: 460/400;
    /* 460px wide and 400px */
  }
}
@media (max-width: 578px) {
  .expertSection {
    padding: 1% 18% 1% 18%;
  }
}
@media (min-width: 480px) and (max-width: 540px) {
  /* .expertImage {
    height: 220px !important;
  } */
}

@media (max-width: 480px) {
  .expertSkills {
    font-size: 0.68em;
    width: fit-content;
    color: var(--qrencia-grey-text);
  }
  .expertCity {
    font-size: 0.68em;
    width: fit-content;
    color: var(--qrencia-grey-text);
  }
  .expertSection {
    padding: 1% 18% 1% 18%;
  }
  .expertTitle {
    font-size: 0.7em !important;
    margin-top: 1%;
  }

  .vecterBgImage {
    transform: translate(0, -100%) scaleX(-1);
  }

  .expertImage {
    /* height: 220px !important; */
    object-fit: cover;
  }
  .loadMorebtn {
    padding: 1.5% 6%;
    letter-spacing: 0.03rem;
    font-size: 0.7em;
  }
}
@media (max-width: 420px) {
  .expertImage {
    /* height: 220px !important; */
  }
  .expertSection {
    padding: 1% 12% 1% 12%;
  }
}
