.skillsMainContainer {
    background-color: var(--qrencia-white);
    background-position-y: -35%;
    background-size: cover;
}

@media(min-width:500px) and (max-width:767px) {
    .skillsMainContainer {
        background-size: auto !important;
        background-position-y: 10% !important;
        background-position-x: 10% !important;
    }
}

@media(max-width:500px) {
    .skillsMainContainer {
        background-size: auto !important;
        background-position-y: 10% !important;
        background-position-x: 10% !important;
    }
}