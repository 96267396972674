.reviewContainer {
  margin-top: 0.3em;
  margin-bottom: 0.5em;
  padding: 0.6em 0.8em;
}
.profileImageSection {
  display: flex;
  justify-content: flex-start;
}
.reviewInitials {
  height: 2.5em;
  width: 2.5em;
  background-color: var(--qrencia-course-skill-name-color);
  color: var(--qrencia-text-black);
  text-align: center;
  font-size: 0.9em;
  line-height: 2.5em;
  font-weight: 500;
  min-width: 2.5em;
  margin-right: 1em;
}

.reviewerName {
  font-size: 0.82em;
  font-weight: 450 !important;
  color: var(--qrencia-dark-blue) !important;
}
.courseTitle {
  font-size: 0.72em;
  font-weight: 450 !important;
  opacity: 0.6;
  color: var(--qrencia-text-black) !important;
}
.reviewComment {
  font-size: 0.85em;
  max-width: 100%;
  font-weight: 450;
  margin-top: 0.8em;
}

.respondedOnSection {
  font-size: 0.68em;
  font-weight: 500;
  margin-top: 0.5em;
  opacity: 0.7;
}

.cardContainer {
  height: fit-content;
  padding: 0.8em;
  margin: 1em 0em;
  background-color: var(--qrencia-white);
  box-shadow: var(--qrencia-template-box-shadow);
  transition: 0.5s all ease-in-out;
  border: 1px solid var(--qrencia-certificate-preview-border);
}
.ratingStars {
  color: var(--qrencia-golden-color) !important;
  font-size: 0.8em !important;
  margin: 0;
  margin-top: -1em;
}
.ratingText {
  font-size: 0.72em;
  font-weight: 500 !important;
  color: var(--qrencia-grey-text-color);
  margin-left: 0.4em;
}
.ratingStarContainer {
  margin-top: -5px;
}
.profilePicture {
  height: 2.4em;
  width: 2.4em;
}
@media (min-width: 1700px) {
  .reviewComment {
    font-size: 1em;
  }
  .reviewerName {
    font-size: 0.98em;
    font-weight: 450 !important;
    color: var(--qrencia-dark-blue) !important;
  }
  .profilePicture {
    height: 2.6em;
    width: 2.6em;
  }
  .reviewInitials {
    height: 2.6em;
    width: 2.6em;
  }
}
@media (max-width: 400px) {
  .reviewInitials {
    height: 2.4em;
    width: 2.4em;
    font-size: 0.85em;
    line-height: 2.4em;
    font-weight: 500;
    min-width: 2.4em;
    margin-right: 1em;
  }

  .reviewerName {
    font-size: 0.7em;
  }

  .reviewComment {
    font-size: 0.78em;
  }

  .respondedOnSection {
    font-size: 0.65em;
  }
}
