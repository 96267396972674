.skillsCardsSection {
    display: flex;
    padding: 3% 0% 1% 0%;
}

.skillsCard {
    margin-top: 0%;
    margin-bottom: 3%;
}

.skillthumbnailImage {
    width: 100%;
}

.ageRange {
    background: var(--qrencia-grey-border);
    border-radius: 1.2rem;
    width: fit-content;
    font-size: 0.75em;
    font-weight: 600;
    padding: 1.4% 4%;
    text-align: left;
}

.cardBody {
    padding: 7% 5% 3% 5%;
    margin-top: 3%;
    margin-bottom: 2%;
}

.skillName {
    font-weight: 700 !important;
    text-align: left;
    /* margin-top: 6%; */
}

.skillsCard a,
p {
    text-decoration: none;
    color: var(--qrencia-text-black);
    font-weight: 700;
}

@media(min-width:1120px) and (max-width:1290px) {
    .ageRange {
        background: var(--qrencia-grey-border);
        border-radius: 1.2rem;
        font-size: 0.68em;
        font-weight: 600;
        padding: 1.4% 4%;
        text-align: center;
    }

    .skillName {
        font-weight: 700 !important;
        text-align: left;
        /* margin-top: 6%; */
        font-size: 1em;
    }
}

@media(min-width:767px) and (max-width:1119px) {
    .ageRange {
        background: var(--qrencia-grey-border);
        border-radius: 1.2rem;
        font-size: 0.68em;
        font-weight: 600;
        padding: 1.4% 4%;
        text-align: center;
    }

    .skillName {
        font-size: 0.9em;
    }

}


@media(max-width:767px) {
    .ageRange {
        background: var(--qrencia-grey-border);
        border-radius: 1.2rem;
        font-size: 0.7rem;
        font-weight: 700;
        padding: 1.2% 4%;
    }

}

@media(max-width:580px) {

    .ageRange {
        border-radius: 1.2rem;
        font-size: 0.68em;
        font-weight: 600;
        padding: 1.4% 5%;
        text-align: center;
    }

    .skillName {
        font-size: 0.8em;
    }

    .skillsCard {
        margin-top: 0%;
        margin-bottom: 6%;
    }
}