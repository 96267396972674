.container {
  display: flex;
  border-radius: 6px;
  border: 1.5px solid var(--qrencia-dark-blue);
  width: fit-content;
}
.day {
  padding: 0.4em;
  width: 3em;
  text-align: center;
  font-weight: 480;
  color: var(--qrencia-dark-blue);
  font-size: 0.85em;
  transition: 0.2s all ease-out;
  cursor: pointer;
}
.activeDay {
  color: var(--qrencia-white);
  background-color: var(--qrencia-dark-blue);
  transition: 0.1s all ease-in-out;
  border-left: 0.1px solid var(--qrencia-white);
}
.activeDay:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 0px;
}
.activeDay:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
