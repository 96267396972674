.journeyAheadSection {
  background-color: var(--qrencia-blue-background);
  padding: 3% 7%;
}

.section p {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 2rem;
  font-weight: 700 !important;
  color: var(--qrencia-dark-blue);
}

.successfulExpertTitle {
  color: var(--qrencia-workshop-differentiator-color);
}

.qrenciaForExpertsTitle {
  color: var(--qrencia-orange-heading);
}

.journeryAheadTitle {
  color: var(--qrencia-purple-heading);
}

.whatsappCommunitySection {
  background-color: var(--qrencia-whatsapp-color);
  padding: 0.8% 7%;
  display: flex;
  align-items: center;
}

.successfulExpertsSection {
  padding: 3% 7%;
}

.successfulExpertsSectionEOI {
  padding: 2%;
  background-color: var(--qrencia-white);
  border: 1px solid var(--qrencia-cards-border);
  border-radius: 6px;
}

.qrenciaForExpertsSection {
  background-color: var(--qrencia-blue-background);
  padding: 4% 7%;
  display: flex;
}

.qrenciaForExpertsSectionEOI {
  background-color: var(--qrencia-blue-background);
  padding: 4%;
  display: flex;
  border-radius: 10px;
}

.titleText {
  font-size: 1.1em;
  font-weight: 600;
  line-height: 1em;
}

.titleTextEOI {
  font-size: 0.8em;
  font-weight: 600;
  line-height: 1em;
}

.subTitle {
  color: var(--qrencia-text-black);
  font-weight: 700;
  line-height: 1.2em;
  margin-top: -26px;
  font-size: 2em;
}

.expertSubTitleEOI {
  font-size: 0.8em;
  font-weight: 600 !important;
  color: var(--qrencia-grey-text-color);
  line-height: 1.2em;
  margin-top: -26px;
}

.blueTriangleImage {
  margin-left: -19px;
  width: 2.2em;
}

.description {
  color: var(--qrencia-text-black);
  font-weight: 500 !important;
  font-size: 1.2em;
}

.videoContainer {
  margin-top: 2.5%;
}

.whatsappIcon {
  height: 42px;
  width: 42px;
}

.journeyAheadBanner {
  width: 100%;
}

.whatsappCommunitySectionTitle {
  color: var(--qrencia-white);
  font-size: 1.6em;
  margin-left: 1.5%;
  margin-bottom: 0% !important;
}

.journeySubTitle {
  margin-top: 1%;
}

.whatsappCommunitySectionButton {
  background-color: var(--qrencia-white);
  color: var(--qrencia-whatsapp-color);
  border: none;
  border-radius: 30px;
  font-weight: 600;
  padding: 0% 2%;
  margin-left: 2%;
  font-size: 1.2em;
}

.qrenciaForExpertsContentSection {
  width: 70%;
}

.qrenciaForExpertsVideoSection {
  width: 30%;
}

.qrenciaForExpertsCardTitle {
  font-weight: 700;
  font-size: 2.5em;
  color: var(--qrencia-text-black);
}

.qrenciaForExpertsCardSubTitle {
  font-weight: 600 !important;
  font-size: 1.1em;
}

.qrenciaForExpertsCardsBackgroundImage {
  margin-top: -8em;
  margin-left: -3em;
  width: 6em;
}

.welcomeExpertsQrenciaForExpertsCardsSection {
  margin-top: 8%;
}

.unicodeArrow {
  font-weight: 700;
  font-family: 'Arial, sans-serif';
  color: var(--qrencia-text-black);
}

.subTitleEOI {
  font-size: 1.2em;
  font-weight: 600 !important;
  color: var(--qrencia-grey-text-color);
  line-height: 1.2em;
  margin-top: -26px;
}

@media (min-width: 767px) and (max-width: 1290px) {
  .blueTriangleImage {
    margin-left: -19px;
    width: 2.2em;
  }

  .whatsappIcon {
    height: 35px;
    width: 35px;
  }

  .whatsappCommunitySectionTitle {
    font-size: 1.1em;
  }

  .whatsappCommunitySectionButton {
    font-size: 0.95em;
  }

  .qrenciaForExpertsCardTitle {
    font-size: 2.2em;
  }

  .qrenciaForExpertsCardSubTitle {
    font-size: 1em;
  }

  .qrenciaForExpertsCardsBackgroundImage {
    margin-top: -8em;
    margin-left: -2em;
    width: 5em;
  }
}

@media (min-width: 900px) and (max-width: 1160px) {
  .blueTriangleImage {
    width: 2.2em;
  }

  .whatsappIcon {
    height: 26px;
    width: 26px;
  }

  .whatsappCommunitySectionTitle {
    font-size: 0.8em;
  }

  .whatsappCommunitySectionButton {
    font-size: 0.75em;
  }
}

@media (min-width: 990px) and (max-width: 1185px) {
  .subTitle {
    font-size: 1.7em;
  }
}

@media (max-width: 900px) {
  .qrenciaForExpertsSection {
    display: block;
  }

  .qrenciaForExpertsSectionEOI {
    display: block;
  }

  .qrenciaForExpertsContentSection {
    width: 100%;
  }

  .qrenciaForExpertsVideoSection {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
  }

  .subTitleEOI {
    font-size: 1.1em;
  }
}

@media (max-width: 767px) {
  .section p {
    font-size: 1.7rem;
  }

  .videoSection {
    width: 50%;
  }
}

@media (max-width: 576px) {
  .section p {
    font-size: 1.5rem;
  }

  .videoSection {
    width: 100%;
  }
}

@media (min-width: 767px) and (max-width: 990px) {
  .subTitle {
    font-size: 1.5em;
  }

  .titleText {
    font-size: 0.8em;
  }

  .description {
    font-size: 0.8em;
  }

  .blueTriangleImage {
    margin-left: -21px;
    width: 2em;
  }

  .qrenciaForExpertsCardTitle {
    font-size: 2em;
  }

  .qrenciaForExpertsCardSubTitle {
    font-size: 1em;
  }

  .qrenciaForExpertsCardsBackgroundImage {
    margin-top: -8em;
    margin-left: -2em;
    width: 6em;
  }

  .successfulExpertsSectionEOI {
    padding: 2% 3%;
  }
}

@media (max-width: 500px) {
  .blueTriangleImage {
    margin-left: -290px;
  }

  .qrenciaForExpertsVideoSection {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0em;
    margin-bottom: 2em;
  }
}

@media (min-width: 501px) and (max-width: 767px) {
  .blueTriangleImage {
    margin-left: -288px;
    width: 1.5em;
  }

  .qrenciaForExpertsCardsBackgroundImage {
    width: 3em;
  }

  .whatsappIcon {
    height: 24px;
    width: 24px;
  }

  .whatsappCommunitySectionTitle {
    font-size: 0.8em;
  }

  .whatsappCommunitySectionButton {
    font-size: 0.75em;
  }

  .qrenciaForExpertsVideoSection {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .blueTriangleImage {
    margin-left: -20px;
    width: 2.2em;
  }

  .subTitle {
    font-size: 1.5em;
  }

  .titleText {
    font-size: 0.8em;
  }

  .description {
    font-size: 0.8em;
  }

  .qrenciaForExpertsCardTitle {
    font-size: 1.9em;
  }

  .qrenciaForExpertsCardSubTitle {
    font-size: 1em;
  }

  .qrenciaForExpertsCardsBackgroundImage {
    width: 5em;
  }

  .welcomeExpertsNumberSection {
    text-align: center;
  }

  .subTitleEOI {
    font-size: 1em;
  }

  .successfulExpertsSectionEOI {
    padding: 2% 3%;
  }
}

@media (max-width: 540px) {
  .subTitleEOI {
    font-size: 0.9em;
  }
}

@media (min-width: 480px) and (max-width: 559px) {
  .whatsappIcon {
    height: 19px;
    width: 19px;
  }

  .whatsappCommunitySectionTitle {
    font-size: 0.65em;
  }

  .whatsappCommunitySectionButton {
    font-size: 0.65em;
  }

  .qrenciaForExpertsCardTitle {
    font-size: 1.8em;
  }

  .qrenciaForExpertsCardSubTitle {
    font-size: 0.9em;
  }

  .qrenciaForExpertsCardsBackgroundImage {
    width: 5em;
  }
}

@media (max-width: 480px) {
  .journeyAheadSection {
    padding: 8% 7%;
  }

  .successfulExpertsSection {
    padding: 8% 7% 2% 7%;
  }

  .successfulExpertsSectionEOI {
    padding: 5%;
  }

  .qrenciaForExpertsSection {
    padding: 8% 7% 2% 7%;
  }

  .qrenciaForExpertsSectionEOI {
    padding: 8% 4% 2% 4%;
  }

  .subTitle {
    font-size: 1.25em;
    font-weight: 650;
  }

  .blueTriangleImage {
    margin-left: -13px;
    width: 1.8em;
  }

  .whatsappIcon {
    height: 18px;
    width: 18px;
  }

  .whatsappCommunitySectionTitle {
    font-size: 0.55em;
  }

  .whatsappCommunitySectionButton {
    font-size: 0.5em;
    margin-left: 1.1%;
  }

  .qrenciaForExpertsCardTitle {
    font-size: 1.8em;
  }

  .qrenciaForExpertsCardSubTitle {
    font-size: 0.9em;
  }

  .qrenciaForExpertsCardsBackgroundImage {
    width: 5em;
  }

  .welcomeExpertsQrenciaForExpertsCardsSection {
    margin-top: 14%;
  }
}

@media (max-width: 430px) {
  .qrenciaForExpertsCardTitle {
    font-size: 1.6em;
  }

  .qrenciaForExpertsCardSubTitle {
    font-size: 0.85em;
  }

  .qrenciaForExpertsCardsBackgroundImage {
    width: 5em;
  }
}

@media (max-width: 380px) {
  .qrenciaForExpertsCardTitle {
    font-size: 1.5em;
  }

  .qrenciaForExpertsCardSubTitle {
    font-size: 0.75em;
  }

  .qrenciaForExpertsCardsBackgroundImage {
    width: 4em;
  }
}

@media (max-width: 360px) {
  .subTitle {
    font-size: 1.2em !important;
  }

  .whatsappIcon {
    height: 18px;
    width: 18px;
  }

  .whatsappCommunitySectionTitle {
    font-size: 0.5em;
  }

  .whatsappCommunitySectionButton {
    font-size: 0.5em;
    margin-left: 1%;
  }
}
