.container {
  background-color: var(--qrencia-white);
  width: 100%;
  padding: 1em;
  min-height: 4.2em;
  border-radius: 5px;
}

.obrGridTable {
  border: 1px solid var(--qrencia-grid-grey);
  border-collapse: collapse;
}

.obrGridTable td {
  padding: 8px;
}

.obrGridHeader {
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  font-weight: bold;
}
.selectButton {
  margin-top: 1em;
  font-size: 0.8em !important;
  padding: 0.61em 1em;
  width: 8em;
}
.noDataAvailableBox {
  padding: 3em 1em;
  margin: 1em 0;
  text-align: center;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  color: var(--qrencia-grey-text);
  font-style: italic;
  font-weight: 400 !important;
  border: 1px solid var(--qrencia-cards-border);
}

.noDataAvailableBox {
  font-size: 0.8em;
}
