.expertOnBoardingContainer {
  padding: 0.9em;
}

.expertOnBoardingHeading {
  font-size: 1.025em;
  margin: 2% 0% 1% 2%;
  font-weight: 650;
  color: var(--qrencia-text-black);
}
.userRoleBackground {
  padding: 1em;
  background-color: var(--qrencia-white);
  border-radius: 0.8em;
  margin-top: 2em;
}
.userRoleDropdown {
  width: 20em;
  margin-bottom: 0.2em;
}
@media (max-width: 425px) {
  .expertOnBoardingContainer {
    padding: 0.9em 0;
  }
}
