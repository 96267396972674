.logoSection {
  background-color: var(--qrencia-blue-background);
  overflow: hidden;
  padding: 1rem 0 1.5rem 0;
  border-radius: 6px;
  position: relative;
}

.logoDescription p {
  color: var(--qrencia-dark-blue);
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: 1rem;
  padding-left: 6.7%;
}

.logoContainer {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.logoBar {
  display: flex;
  white-space: nowrap;
  will-change: transform;
}

.carouselItem {
  flex: 0 0 auto;
  margin: 0 10px;
  display: flex;
  background-color: var(--qrencia-white);
  height: 100px;
  width: 100px;
  border-radius: 50%;
  justify-content: flex-start;
  align-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: column;
}

.logoImage {
  width: 85%;
  height: 100%;
  object-fit: contain;
}
