.desktopImgContainer {
  display: block;
  object-fit: contain;
}
.mobileImgContainer {
  display: none;
}
.imageContainer {
  position: relative;
}
.imageContainer button {
  min-width: 11em;
  width: max-content !important;
  position: absolute;
}

.expertDesktopButton {
  top: 81%;
  left: 89%;
  transform: translate(-50%, -50%);
  font-size: 0.7em !important;
}

.expertMobileButton {
  font-size: 0.65em !important;
  top: 90%;
  left: 76%;
  transform: translate(-50%, -50%);
}

.bannerImage {
  width: 100%;
  border-radius: 10px;
}
@media (max-width: 1200px) {
  .expertDesktopButton {
    font-size: 0.6em !important;
  }
}
@media (max-width: 850px) {
  .expertDesktopButton {
    font-size: 0.45em !important;
  }
}

@media (max-width: 768px) {
  .expertDesktopButton {
    font-size: 0.6em !important;
  }
}

@media (max-width: 660px) {
  .expertDesktopButton {
    font-size: 0.55em !important;
  }
}
@media (max-width: 560px) {
  .expertDesktopButton {
    font-size: 0.45em !important;
  }
}
@media (max-width: 480px) {
  .mobileImgContainer {
    display: block;
    object-fit: cover;
  }
  .desktopImgContainer {
    display: none;
  }
  .expertMobileButton {
    min-width: 11em !important;
    font-size: 0.65em !important;
    top: 75%;
    left: 80%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 400px) {
  .expertMobileButton {
    min-width: 11em !important;
    font-size: 0.57em !important;
    top: 78%;
    left: 77%;
    transform: translate(-50%, -50%);
  }
}
