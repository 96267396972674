.heading {
  font-size: 1em;
  font-weight: 650;
  color: var(--qrencia-text-black);
  margin: 0.5em 0.5em 1em 0.5em;
}

.buttons {
  font-size: 0.8em;
  width: fit-content;
  padding: 0.6em 1em;
}

.feedbackUploadSection {
  background-color: var(--qrencia-white);
  width: 100%;
  padding: 1em;
  min-height: 4.2em;
  border-radius: 5px;
}
.feedbackResponseSection {
  background-color: var(--qrencia-white);
  width: 100%;
  padding: 1em;
  min-height: 4.2em;
  border-radius: 5px;
  margin-top: 2%;
  margin-bottom: 2%;
}

.uploadFileSection {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.uploadFileSection > div {
  margin-right: 1em;
  width: 25em;
}

.uploadFileSection > button {
  height: 2.8em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-right: 1em;
}

.fileInput {
  width: 24em;
}

.preLoaderContainer {
  height: 100vh;
  width: 100%;
  position: relative;
}

.preLoaderDiv {
  position: absolute;
  top: 55%;
  left: 55%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrenciaGlobe {
  width: 80px;
  margin-top: -98px;
}

.loaderText {
  padding: 2em 0 0 0;
  font-size: 1em;
  text-align: center;
}

.loaderIcon {
  font-size: 6em !important;
  color: var(--qrencia-dark-blue) !important;
  text-align: center;
}
.feedbackResponseText {
  font-size: 0.9em;
}
.spinner {
  animation: spin-animation 0.5s infinite;
}
.tableHeader {
  font-size: 0.9em;
}
.tableRow {
  font-size: 0.9em;
}
.tableRowMarks {
  text-align: center;
  vertical-align: middle;
}
.tableHeaderMarks {
  font-size: 0.9em;
  text-align: center;
}
.tableRowCriteria {
  vertical-align: middle;
}
.happyEmoji {
  font-size: 1.6em;
  color: var(--qrencia-success-green);
}
.sadEmoji {
  font-size: 1.6em;
  color: var(--cancel-red-button);
}
@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.loader {
  --b: 8px;
  /* border thickness */
  --n: 12;
  /* number of dashes*/
  --g: 10deg;
  /* gap between dashes*/
  --c: var(--qrencia-dark-blue);
  /* the color */

  width: 120px;
  /* size */
  aspect-ratio: 1;
  border-radius: 100%;
  padding: 1px;
  background: conic-gradient(#0000, var(--c)) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: load 1s infinite steps(var(--n));
}

@keyframes load {
  to {
    transform: rotate(1turn);
  }
}

@media (max-width: 1120px) {
  .fileInput {
    width: 20em;
  }
}
@media (max-width: 991.5px) {
  .preLoaderDiv {
    top: 50%;
    left: 50%;
  }
  .qrenciaGlobe {
    width: 60px;
    margin-top: -80px;
  }
  .loader {
    width: 100px;
  }
}
@media (max-width: 767.5px) {
  .uploadFileSection {
    display: block;
  }
}
@media (max-width: 620px) {
  .fileInput {
    width: 18em;
  }
}

@media (max-width: 500px) {
  .uploadFileSection > button {
    height: 2.9em;
    margin-top: 1em;
    margin-right: 1em;
  }
}

@media (max-width: 480px) {
  .uploadFileSection > button {
    height: 3.4em;
    margin-top: 1em;
    padding: 0.6em 1em;
    font-size: 0.7em;
  }
  .uploadFileSection > div {
    margin-right: 1em;
    width: 370px;
  }
}
@media (max-width: 420px) {
  .uploadFileSection > div {
    margin-right: 1em;
    width: 100%;
  }
}
