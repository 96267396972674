.achievementsSection {
  display: flex;
  gap: 1%;
  /* margin-bottom: 1%; */
  height: 68vh;
}

.adSection {
  width: 50%;
}

/* .fullAdSection {
  margin-bottom: 1%;
} */

.achievementAd {
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.recentActivitiesAd {
  width: 100%;
}

.recentActivitiesContainer {
  width: 50%;
  padding: 1% 2.5% 2% 2.5%;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  overflow: hidden;
}

.achievementsContainer {
  width: 50%;
  padding: 0.8% 2.5% 2% 2.5%;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  overflow-y: hidden;
}

.containerHeading {
  color: var(--qrencia-text-black);
  font-weight: 700;
  font-size: 1.4em;
  margin-bottom: 2%;
}

.achievementBtn {
  height: 2.5em !important;
  font-size: 0.8em !important;
  padding: 0 1.5em !important;
}

@media (max-width: 1290px) {
  .containerHeading {
    font-size: 1.2em;
  }
}

@media (max-width: 1080px) {
  .cardsSection {
    height: 51.5vh;
  }
}

@media (max-width: 992px) {
  .achievementBtn {
    font-size: 0.75em !important;
  }
}

@media (max-width: 900px) {
  .achievementBtn {
    height: 2.3em !important;
    font-size: 0.75em !important;
  }
}

@media (min-width: 767px) and (max-width: 845px) {
  .achievementBtn {
    padding: 0 1em !important;
  }
}

@media (max-width: 767px) {
  .containerHeading {
    font-size: 1.1em;
  }
}

@media (max-width: 767.8px) {
  .achievementsSection {
    display: block;
    height: auto;
  }

  .recentActivitiesContainer {
    width: 100%;
    margin-top: 2%;
    margin-bottom: 3%;
    height: 400px;
    padding-bottom: 2em;
  }

  .adSection {
    width: 100%;
    margin-top: 2%;
  }

  .achievementsContainer {
    width: 100%;
    margin-bottom: 3%;
    height: 400px;
    padding: 0.8% 2.5% 2% 2.5%;
  }

  .achievementAd {
    margin-bottom: 3%;
  }
}

@media (max-width: 560px) {
  .achievementsContainer {
    padding: 0% 2.5% 2% 2.5%;
  }
}

@media (max-width: 438px) {
  .recentActivitiesContainer {
    padding: 1% 2.5% 3% 2.5%;
  }

  .achievementsContainer {
    padding: 0% 2.5% 3% 2.5%;
  }

  .achievementBtn {
    font-size: 0.65em !important;
  }
}