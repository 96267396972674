.pageContainer {
  width: 90%;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4em;
}
.loaderContainer {
  padding: 2em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-grey-border);
  width: 80%;
  margin: 1em auto;
  text-align: center;
  background-color: var(--qrencia-white);
  margin-top: 5em;
}
.loaderIcon {
  font-size: 3em !important;
  color: var(--qrencia-dark-blue) !important;
}
.nudgesHeading {
  font-size: 1.4em;
  margin-top: 2em;
  font-weight: 600;
}
.backButtonContainer {
  background-color: var(--qrencia-dark-blue);
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.backArrowbutton {
  color: var(--qrencia-white);
  font-size: 0.8em;
  cursor: pointer;
}
.viewDetailsContainer {
  padding: 1em;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
@media (max-width: 991px) {
  .nudgesHeading {
    font-size: 1.2em;
    margin-top: 2em;
  }
}
@media (max-width: 500px) {
  .nudgesHeading {
    font-size: 1em;
    margin-top: 2em;
  }
  .pageContainer {
    width: 92%;
  }
}
@media (max-width: 425px) {
  .viewDetailsContainer {
    padding: 1em 0.5em;
  }
}
