.modal-video-body {
    max-width: 1280px;
}
.modal-video {
    background-color: rgba(0,0,0,.8);
}
.modal-video-close-btn { 
    width: 22px;
    height: 25px;
}
@media(max-width:721px){
    .modal-video-close-btn {
        width: 18px;
        top: -30px;
    }
}