.headingSection {
  margin-bottom: 1.2em;
  text-align: left;
}
.heading {
  font-size: 1.6em;
  text-align: left;
  font-weight: 600;
  margin-bottom: 0.2em;
  margin-top: 0.5em;
  padding: 0em 0.5em;
}
.headingCostContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nudgeCost {
  width: fit-content;
  background-color: var(--qrenica-nudge-differentiator-color);
  border-radius: 20px;
  padding: 1px 1em;
  font-size: 0.9em !important;
  color: var(--qrencia-white) !important;
  font-weight: 500 !important;
  margin-top: 10px;
}

.subHeading {
  color: var(--qrencia-dark-blue);
  font-size: 1.4em;
  margin-bottom: 1.5em;
  font-weight: 500;
}
@media (max-width: 991px) {
  .heading {
    font-size: 1.4em;
  }
  .subHeading {
    font-size: 1.1em;
  }
  .nudgeCost {
    border-radius: 20px;
    padding: 1px 1em;
    font-size: 0.8em !important;
  }
}
@media (max-width: 768px) {
  .heading {
    font-size: 1.2em;
  }
  .subHeading {
    font-size: 1.1em;
  }
}
@media (max-width: 580px) {
  .heading {
    font-size: 1.1em;
  }
  .subHeading {
    font-size: 1em;
  }
  .nudgeCost {
    margin-top: 8px;
    padding: 1px 1em;
    font-size: 0.7em !important;
  }
}
