.copyIcon {
  margin-left: 10px;
  cursor: pointer;
  color: var(--qrencia-dark-blue);
}

.copyIcon:hover {
  color: var(--qrencia-light-blue); /* Change color on hover */
  transform: scale(1.2); /* Slightly enlarge the icon */
}

.copySuccess {
  color: var(--qrencia-success-green);
  margin-top: 5px;
}

.verifyCheckImg {
  height: 40px;
  width: 40px;
  margin-bottom: 1rem;
}

.paymentIdContainer {
  font-size: 0.9rem;
}

.paymentId {
  font-size: 0.9rem;
}

.title {
  font-size: 1.5rem;
}

.closeButton {
  position: absolute;
  top: 3px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2rem;
  /* font-weight: bold; */
  cursor: pointer;
  color: #000;
}

.closeButton:hover {
  color: var(--qrencia-error-message);
}

.buttonContainer {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 2rem 1rem;
}

.whatsappButton {
  background: #25d366 !important;
  border-radius: 2rem;
  border: none;
  padding: 3% 5%;
}

.whatsappButton:hover {
  background: #128c7f !important;
}

.callUsButton {
  /* background: #25d366; */
  border-radius: 2rem;
  border: none;
  padding: 3% 5%;
}

.callUsButton:hover {
  opacity: 1.2;
}

@media (max-width: 467px) {
  .buttonContainer {
    gap: 0rem;
    margin: 2rem 0;
  }

  .callUsButton {
    margin-right: 1rem;
    font-size: 0.8rem;
  }

  .whatsappButton {
    font-size: 0.8rem;
  }
}
@media (max-width: 378px) {
  .buttonContainer {
    gap: 0rem;
    margin: 2rem 0;
  }

  .whatsappButton {
    font-size: 0.8rem;
  }

  .callUsButton {
    font-size: 0.8rem;
  }
}
