.dashboardCards {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: var(--qrencia-white);
  padding: 1em;
  border-radius: 10px;
}

.competitionCard {
  color: var(--qrencia-white);
  background-color: var(--qrenica-competition-differentiator-color);
}

.courseCard {
  color: var(--qrencia-white);
  background-color: var(--qrenica-course-differentiator-color);
}

.teachersCard {
  color: var(--qrencia-white);
  background-color: var(--qrencia-activity-snapshot-iconthree-bg);
}

.studentCard {
  color: var(--qrencia-white);
  background-color: var(--qrenica-nudge-differentiator-color);
}

.workshopCard {
  background-color: var(--qrencia-workshop-differentiator-color);
}

.countCards {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  width: 22%;
  border: none;
  outline: none;
}

.count {
  color: var(--qrencia-white);
}

.cardCount {
  font-size: 1.8em;
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: -0.2em !important;
}

.cardTitle {
  font-size: 0.95em;
  color: var(--qrencia-white);
  font-weight: 500 !important;
  text-align: center;
}

@media (max-width: 990px) {
  .countCards {
    margin-top: 1.2em;
  }

  .cardTitle {
    font-size: 0.8em;
    color: var(--qrencia-white);
    font-weight: 500 !important;
    text-align: center;
  }
}

@media (max-width: 584px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 11.2em;
  }

  .dashboardCards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 584px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 1.2em;
    width: 11.2em;
  }

  .dashboardCards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 494px) {
  .countCards {
    margin-right: 2.5em;
    margin-top: 0.4em;
    width: 14em;
  }

  .dashboardCards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}