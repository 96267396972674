.fileOBRUploadSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.fileOBRUploadInput {
  flex-grow: 1;
}

.obrTypeahead .dropdown-menu {
  inset: 6px auto auto -28px !important;
  margin-left: 2.2em;
  font-size: 0.8em;
  padding: 0;
  border-radius: 0;
  border: solid 1px var(--qrencia-select-box-border);
}

.obrInputontainer {
  margin-top: -1em;
  font-size: 1em;
}

.addCentreButtonRow {
  text-align: left;
}

.remainingChars {
  font-size: 0.7em;
  font-weight: 500 !important;
}
.addCentreFormButton {
  margin: 1em auto;
  font-size: 0.6em;
  font-weight: 500;
  text-align: left;
  padding: 0.4em 1em;
  margin-right: 2%;
}

.removeCentreButton {
  margin: 1em 0.8em;
  font-size: 0.8em;
  width: 8em;
  margin-left: -0.1em;
}

.tooltipMessage {
  font-size: 0.8em !important;
  font-weight: 500 !important;
  background-color: var(--qrencia-carousel-blue-image-border);
  width: 30% !important;
}

.userName {
  font-weight: 500 !important;
  display: inline;
  font-size: 0.96em;
}

.tooltipMessageDownload {
  font-size: 0.9em !important;
  font-weight: 500 !important;
  background-color: var(--qrencia-carousel-blue-image-border);
  width: 16em !important;
  z-index: 100;
  text-align: center;
  color: var(--qrencia-text-black);
}
.galleryTooltip {
  display: block;
}
.tooltipMessageAboutYourself {
  font-size: 0.9em !important;
  font-weight: 500 !important;
  background-color: var(--qrencia-carousel-blue-image-border);
  width: 28em !important;
  z-index: 100;
  text-align: left;
  color: var(--qrencia-text-black);
}

.iButton {
  padding: 2px;
  background-color: var(--qrencia-dark-blue);
  border-radius: 50%;
  color: var(--qrencia-white);
  font-size: 0.9em;
  margin: 0 0 2px 2px;
  cursor: pointer;
}

.fileOBRUploadSection > button {
  margin-top: 0.2em;
  font-size: 1.2em;
  color: var(--qrencia-dark-blue);
  outline: none;
  border: none;
  background: var(--qrencia-white);
}

.boldText {
  font-weight: 600;
  font-size: 1.025em;
  font-style: normal !important;
}

.buttonSection {
  width: 100%;
  text-align: center;
  margin-top: 2em;
}

.buttonSection > button {
  margin: 1em 0.8em;
  font-size: 0.8em;
}

.saveButton {
  width: 12em;
}

.reviewButton {
  width: 14em;
}

.headingBar > h5 {
  font-size: 1.2em;
}

.formSectionHeading {
  font-size: 1em;
  margin-top: 1%;
}

.container {
  background-color: var(--qrencia-white);
  width: 85%;
  margin: 1em auto;
  padding: 1em 1em 2em 1em;
  border-radius: 5px;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  z-index: 10;
  width: max-content;
}

.tncText {
  color: var(--card-grey-title) !important;
}

.tncTextHyper {
  text-decoration: none !important;
}

.feedbackComponent {
  margin-top: 2em;
}

.removeBtn {
  outline: none;
  background-color: var(--qrencia-white);
  border: none;
  color: var(--qrencia-white);
  padding: 0.6em 0.4em 0.6em 0.4em;
  border-radius: 0.3em;
  /* width: 2em; */
}

.removeBtn > svg {
  font-size: 1em;
  color: var(--qrencia-text-black);
}

.adminFeedbackHeading {
  font-weight: 500;
  font-size: 0.9em;
}

.feedbackComment {
  border: 1px solid var(--qrencia-obr-comment-bg);
  height: fit-content;
  min-height: 5em;
  padding: 0.5em;
  font-size: 0.9em;
}
.feedbackComment > ul {
  margin-bottom: 0;
}
.feedbackText > p,
.feedbackText > ul,
.feedbackText > li {
  color: var(--qrencia-grey-text) !important;
}
.feedbackText > ul {
  margin-bottom: 0;
}
.headingBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
  margin-top: 1em;
  padding: 0em 0.6em;
}

.rejectedInfo {
  background: var(--cancel-red-button);
  height: 2em;
  width: max-content;
  border-radius: 5px;
  padding: 0em 0.5em;
  color: var(--qrencia-white);

  line-height: 2em;
}

.switchContainer {
  margin-top: 2em;
}

.switchText {
  margin-left: 0.3em;
  font-size: 0.9em;
  width: fit-content;
  display: flex;
  align-items: center;
}
.switchText input {
  width: 20px;
  margin-right: 0.6em;
}
.checkboxContainer {
  margin-top: 1em;
  margin-bottom: 0.5em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkboxText {
  font-weight: 500;
  margin-right: 1em;
  font-size: 0.95em;
  width: fit-content;
  display: flex;
  align-items: center;
}
.checkboxText input {
  margin-left: 0.8em;
  margin-top: 0.3em;
}
.checkboxContainer > label {
  line-height: 1em;
}
.statusContainer {
  width: 85%;
  margin: 1em auto;
  border-radius: 5px;
}

.pageLoader {
  color: var(--qrencia-dark-blue);
  font-size: 3em;
}

.errorMessage {
  color: red;
  font-size: 0.8em;
}
.tooltipMessageDownload {
}
@media (max-width: 990px) {
  .headingBar > h5 {
    font-size: 1em;
  }

  .formSectionHeading {
    font-size: 0.9em;
  }
  .galleryTooltip {
    display: none;
  }
  .checkboxText {
    font-size: 0.9em;
  }
}

@media (max-width: 767px) {
  .obrRejectedSection {
    margin-top: -60px;
  }
}

@media (max-width: 580px) {
  .headingBar {
    display: block;
  }

  .rejectedInfo {
    font-size: 0.9em;
  }

  .adminFeedbackHeading {
    font-size: 0.8em;
  }

  .feedbackComment {
    font-size: 0.8em;
  }
}

@media (max-width: 480px) {
  .headingBar > h5 {
    font-size: 0.96em;
  }

  .obrRejectedImage {
    width: 20%;
  }

  .headingBar {
    padding: 0em 0.8em;
  }

  .buttonSection > button {
    margin: 1em 5px;
    font-size: 0.7em;
    width: 9.5em;
    padding: 3px 0;
  }

  .tooltipMessageDownload {
    width: 15em !important;
  }
  .tooltipMessageAboutYourself {
    width: 22em !important;
  }
}

.formOBRLabel {
  font-weight: 500;
  font-size: 0.8em;
  background-color: var(--qrencia-white);
  margin-left: 3px;
  padding: 0em 0.3em;
  z-index: 1;
  background-image: linear-gradient(to bottom, var(--qrencia-white), var(--qrencia-select-box-background));
  position: relative;
}

.formOBRInput,
.selectOBRInput {
  height: 3em;
  font-size: 0.8em !important;
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  margin-top: -18px;
  text-align: left !important;
}

.formOBRInput:read-only {
  height: 3em;
  font-size: 0.8em !important;
  background: var(--qrencia-white);
  border: solid 1px var(--qrencia-select-box-border);
  margin-top: -18px;
}

.formOBRLabelDisabled {
  font-weight: 500;
  font-size: 0.8em;
  background-color: var(--qrencia-white);
  margin-left: 6px;
  padding: 0em 0.3em;
  background-image: linear-gradient(to bottom, var(--qrencia-white), var(--qrencia-disabled-input-bg));
}

.formOBRTextAreaInput {
  resize: none;
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  height: 6em !important;
  margin-top: -18px;
}

.uploadOBRFileInput {
  margin-top: -16px;
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  position: relative;
  overflow: visible !important;
  padding-right: 6.8em !important;
}

.fileOBRUpload input[type='file']::file-selector-button {
  position: absolute;
  right: -1.4em;
  top: 0.6em;
  height: 3.8em;
  line-height: 1.1em;
  z-index: 9;
  width: 9em !important;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 1140px) and (max-width: 1380px) {
  .fileOBRUpload input[type='file']::file-selector-button {
    position: absolute;
    right: -1em;
    top: 1.2em;
    height: 2.6em;
    line-height: 1.1em;
    z-index: 9;
    width: 9em !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (min-width: 992px) and (max-width: 1140px) {
  .fileOBRUpload input[type='file']::file-selector-button {
    position: absolute;
    right: -1em;
    top: 1.6em;
    height: 2.6em;
    line-height: 1.1em;
    z-index: 9;
    width: 9em !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (min-width: 785px) and (max-width: 840px) {
  .fileOBRUpload input[type='file']::file-selector-button {
    position: absolute;
    right: -0.9em;
    top: 1.2em;
    height: 2.6em;
    line-height: 1.1em;
    z-index: 9;
    width: 9em !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (min-width: 767px) and (max-width: 785px) {
  .fileOBRUpload input[type='file']::file-selector-button {
    position: absolute;
    right: -0.9em;
    top: 1.4em;
    height: 2.6em;
    line-height: 1.1em;
    z-index: 9;
    width: 9em !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (max-width: 540px) {
  .fileOBRUpload input[type='file']::file-selector-button {
    position: absolute;
    right: -0.9em;
    top: 1.4em;
    height: 2.6em;
    line-height: 1.1em;
    z-index: 9;
    width: 9em !important;
    border-bottom-right-radius: 0;
  }
}

.dateTimeOBREmptyInput input[type='date']::-webkit-datetime-edit,
.dateTimeOBREmptyInput input[type='time']::-webkit-datetime-edit {
  color: var(--qrencia-placeholder-color);
}

@media (min-width: 1500px) {
  .formOBRInput,
  .selectOBRInput {
    font-size: 0.8em;
  }
}

@media (min-width: 541px) and (max-width: 760px) {
  .formOBRInput,
  .selectOBRInput {
    font-size: 0.75em;
  }
}

@media (min-width: 761px) and (max-width: 900px) {
  .formOBRInput,
  .selectOBRInput {
    font-size: 0.8em;
  }
}

@media (min-width: 900px) and (max-width: 1500px) {
  .formOBRInput,
  .selectOBRInput {
    font-size: 0.8em;
  }
}

@media (min-width: 540px) and (max-width: 1290px) {
  .container {
    width: 95%;
    padding: 0.2em 1em 2em 1em;
  }
}

@media (max-width: 540px) {
  .formOBRInput .selectOBRInput {
    font-size: 0.8em;
    margin-bottom: 0.2em;
    padding: 0em;
  }

  .container {
    width: 92%;
    padding: 0.2em 0em 2em 0em;
  }

  .formOBRInput,
  .selectOBRInput {
    font-size: 0.75em !important;
  }

  .formOBRInput:read-only {
    font-size: 0.75em !important;
  }

  .formOBRTextAreaInput {
    font-size: 0.75em !important;
  }

  .uploadOBRFileInput {
    font-size: 0.75em !important;
  }

  .removeBtn {
    margin-left: 0.2em;
    /* width: 1.2em; */
    font-size: 0.8em;
  }

  .fileOBRUploadSection > button {
    margin-top: 0.2em;
  }

  .obrTypeahead .dropdown-menu {
    font-size: 0.75em !important;
  }

  .obrInputontainer {
    margin-top: -1em;
    font-size: 0.9em;
  }
}

@media (min-width: 992px) and (max-width: 1140px) {
  .formOBRInput,
  .selectOBRInput {
    font-size: 0.67em !important;
  }

  .formOBRInput:read-only {
    font-size: 0.67em !important;
  }

  .formOBRTextAreaInput {
    font-size: 0.67em !important;
  }

  .uploadOBRFileInput {
    font-size: 0.67em !important;
  }

  .removeBtn {
    margin-left: 0.2em;
    /* width: 1.2em; */
    font-size: 0.8em;
  }

  .fileOBRUploadSection > button {
    margin-top: 0.2em;
  }

  .obrTypeahead .dropdown-menu {
    font-size: 0.67em !important;
  }

  .obrInputontainer {
    margin-top: -1.4em;
    font-size: 0.85em;
  }
}

@media (min-width: 767px) and (max-width: 785px) {
  .formOBRInput,
  .selectOBRInput {
    font-size: 0.75em !important;
  }

  .formOBRInput:read-only {
    font-size: 0.75em !important;
  }

  .formOBRTextAreaInput {
    font-size: 0.75em !important;
  }

  .uploadOBRFileInput {
    font-size: 0.75em !important;
  }

  .removeBtn {
    margin-left: 0.2em;
    /* width: 1.2em; */
    font-size: 0.8em;
  }

  .fileOBRUploadSection > button {
    margin-top: 0.2em;
  }

  .obrTypeahead .dropdown-menu {
    font-size: 0.75em !important;
  }

  .obrInputontainer {
    margin-top: -1em;
    font-size: 0.9em;
  }
}

@media (min-width: 540px) and (max-width: 1290px) {
  .statusContainer {
    width: 95%;
  }
}

@media (max-width: 540px) {
  .statusContainer {
    width: 92% !important;
  }
}
