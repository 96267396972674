.fileUploadContainer {
  position: relative;
  height: 2.2em;
  max-width: 100%;
  padding: 0em 0.3em;
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  text-align: left !important;
  margin-top: 0.8em;
  padding-right: 1em;
}
.fileUploadLabel {
  position: absolute;
  top: -10px;
  font-weight: 500;
  font-size: 0.8em;
  background-color: var(--qrencia-white);
  margin-left: -2px;
  padding: 0em 0.3em;
  z-index: 2;
  background-image: linear-gradient(
    to bottom,
    var(--qrencia-white),
    var(--qrencia-select-box-background)
  );
}
.fileUploadLabelDisabled {
  font-weight: 500;
  font-size: 0.8em;
  background-color: var(--qrencia-white);
  margin-left: 3px;
  padding: 0em 0.3em;
  z-index: 1;
  background-image: linear-gradient(
    to bottom,
    var(--qrencia-white),
    var(--qrencia-disabled-input-bg)
  );
}
.fileUploadInput {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}
.fileUploadContainerDisabled {
  border: solid 1px var(--qrencia-disabled-input-border) !important;
  background-color: var(--qrencia-disabled-input-bg) !important;
}
.fileText {
  margin-top: 0.6em;
  margin-left: 0.2em;
  font-size: 0.8em;
  white-space: nowrap;
  overflow: hidden;
  color: var(--qrencia-placeholder-color);
  text-overflow: ellipsis;
  max-width: 80% !important;
}
.chooseButton {
  position: absolute;
  top: 0;
  right: -5px;
  font-size: 0.6em;
  height: 100%;
  font-weight: 600;
  padding: 0.4em 1.4em;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  z-index: 10;
  max-width: 25em;
}
.infoButton {
  font-size: 1em;
  color: var(--qrencia-dark-blue) !important;
}
@media (min-width: 1290px) and (max-width: 1370px) {
  .chooseButtonObr {
    position: absolute;
    /* top: 20%; */
    /* right: 5px; */
    font-size: 0.55em;
    /* height: 60%; */
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .fileUploadLabel {
    z-index: 0;
  }
}
@media (min-width: 992px) and (max-width: 1210px) {
  .chooseButtonObr {
    position: absolute;
    /* top: 20%; */
    /* right: 5px; */
    font-size: 0.55em;
    /* height: 60%; */
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .fileUploadLabel {
    z-index: 0;
  }
}
@media (min-width: 768px) and (max-width: 820px) {
  .chooseButtonObr {
    position: absolute;
    /* top: 20%; */
    /* right: 5px; */
    font-size: 0.55em;
    /* height: 60%; */
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .fileUploadLabel {
    z-index: 0;
  }
}
@media (min-width: 991px) and (max-width: 1010px) {
  .fileUploadLabel {
    font-size: 0.78em;
  }
  .fileUploadLabelDisabled {
    font-size: 0.78em;
  }
}
@media (min-width: 575px) and (max-width: 592px) {
  .fileUploadLabel {
    font-size: 0.76em;
  }
  .fileUploadLabelDisabled {
    font-size: 0.76em;
  }
}

@media (max-width: 575px) {
  .fileUploadContainer {
    /* max-width: 80%; */
  }
}

@media (max-width: 500px) {
  .chooseButton {
    position: absolute;
    /* top: 18%; */
    /* right: 5px; */
    font-size: 0.55em;
    /* height: 65%; */
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .fileUploadLabel {
    z-index: 0;
  }
  .tooltipMessage {
    max-width: 22em;
  }
  .fileText {
    margin-top: 0.8em;
    font-size: 0.72em;
  }
}

@media (max-width: 444px) {
  .chooseButton {
    font-size: 0.44em;
  }

  .fileText {
    margin-top: 1.1em;
    font-size: 0.62em;
  }
}

@media (max-width: 370px) {
  .fileText {
    margin-top: 0.8em;
    font-size: 0.68em;
  }
}
