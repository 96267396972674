.activitiesSectionContent {
    display: flex;
    padding: 2% 8% 3% 8%;
}

.activitiesSection {
    background: var(--qrencia-extended-page-light-blue-background);
}

.activitiesContentSection {
    width: 100%;
}

.activitiesPassionImage {
    width: 100%;
    border-radius: 8px;
    height: auto;
}

span {
    color: var(--qrencia-dark-blue);
    font-weight: 800;
}

.heading {
    text-align: center;
    color: var(--qrencia-text-black);
    font-weight: 700;
    line-height: 1.2em;
    margin-top: -24px;
    font-size: 1.6em;
    margin-bottom: 0.8%;
    padding: 2% 1% 0% 1%;
}

.blueTriangleImage {
    margin-right: 580px;
    width: 2.5%;
}

.subHeading {
    font-size: 1.2em;
    margin-top: 0.5%;
    text-align: center;
    font-weight: 400;
}

.description {
    font-weight: 600 !important;
    margin-top: 4%;
    font-size: 0.92em;
}


@media(max-width:767px) {

    .activitiesContentSection {
        text-align: left;
    }
}

@media(min-width:990px) and (max-width:1185px) {
    .activitiesPointerSection {
        padding: 4% 0 0 0;
        margin-right: 0;
    }

    .subHeading {
        font-size: 1em;
    }

    .blueTriangleImage {
        margin-right: 490px;
        width: 3%;
    }
}

@media(min-width:767px) and (max-width:990px) {
    .description {
        font-size: 0.8em;
    }

    .backgroundDotsImage {
        display: none;
    }

    .activitiesContentSection {
        margin-bottom: 4%;
    }

    .activitiesImageSection {
        width: 40%;
    }

    .blueTriangleImage {
        margin-right: 430px;
        width: 3.5%;
    }

    .subHeading {
        font-size: 0.9em;
    }
}

@media(max-width:767px) {
    .description {
        font-size: 0.8em;
        text-align: left;
    }

    .activitiesSectionContent {
        display: block;
    }

    .activitiesImageSection {
        width: 100%;
    }

    .activitiesContentSection {
        padding-left: 0;
    }

    .backgroundDotsImage {
        display: none;
    }

    .blueTriangleImage {
        margin-right: 432px;
        width: 4%;
    }

    .subHeading {
        font-size: 0.9em;
    }
}

@media(min-width:480px) and (max-width:540px) {

    .blueTriangleImage {
        margin-right: 432px;
        width: 4%;
    }

    .subHeading {
        font-size: 0.8em;
    }

    .blueTriangleImage {
        margin-right: 380px;
        width: 4%;
    }

}

@media(max-width:480px) {

    .blueTriangleImage {
        margin-right: 330px;
        width: 6%;
    }

    .subHeading {
        font-size: 0.88em;
        margin-bottom: 5%;
    }

}

@media(max-width:360px) {

    .blueTriangleImage {
        margin-right: 330px;
        width: 6%;
    }

    .subHeading {
        font-size: 0.88em;
        margin-bottom: 5%;
    }
}

@media(min-width:768px) and (max-width:819px) {
    .heading {
        font-size: 1.35em;
    }

    .blueTriangleImage {
        margin-right: 392px;
    }
}

@media(min-width:3000px) {
    .heading {
        font-size: 2.5em;
    }
}

@media(min-width:2600px) and (max-width:3000px) {
    .heading {
        font-size: 2.4em;
    }
}


@media(min-width:2000px) and (max-width:2600px) {
    .heading {
        font-size: 2.2em;
    }
}

@media(min-width:1701px) and (max-width:1999px) {
    .heading {
        font-size: 1.8em;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .heading {
        font-size: 1.3em;
    }
}

@media(max-width:767px) {
    .heading {
        font-size: 1.1em;
        padding: 2% 1% 1% 1%;
    }
}
@media(min-width:1025px) and (max-width:1290px) {
    .activitiesSectionContent {
        padding: 2% 8% 3% 8%;
    }
}
@media(min-width:994px) and (max-width:991px) {
    .activitiesSectionContent {
        padding: 2% 8% 3% 8%;
    }
}


@media(min-width:780px) and (max-width:991px) {
    .activitiesSectionContent {
        padding: 3% 8% 4% 8%;
    }
}

@media(max-width:767px) {
    .activitiesSectionContent {
        padding: 7% 8% 6% 8%;
    }
}
@media(max-width:540px) {
    .activitiesSectionContent {
        padding: 7% 8% 6% 8%;
    }

}