.raiseNudgeSectionContainer {
    height: 100vh;
    width: 100%;
}
.modalBody{
    padding: 0;
}
.topHeadingContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 30vh;
}

.headingText {
    font-size: 3.2em;
    padding: 0 0 20px 0;
    color: var(--qrencia-grey-text-color);
}

.subHeadingText {
    font-size: 1.4em;
    color: var(--qrencia-dark-blue);
}

.bottomContentContainer {
    display: flex;
    justify-content: center;
    height: 70vh;
    flex-direction: column;
    padding: 0 4em;
}

.qrenciaGlobeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.qrenciaGlobeLeft {
    width: 320px;
}

.weakCreteriaContainer {
    background-color: var(--performance-page-raise-nudge-lightblue-bg);
    padding: 10px 3em;
    width: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: max-content;
    gap: 10px;
}

.weakCreteria {
    background-color: var(--performance-page-inner-container-bg);
    width: 100%;
    border-radius: 20px;
    padding: 0.5em 1.5em;
    margin: 0.8% 0;
}

.weakCreteria p {
    font-size: 1em;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.modalTitle {
    font-size: 1em;
}

.errorContainer {
    padding: 3em 1em;
    margin: 1em 0;
    text-align: center;
    background-color: var(--qrencia-white);
    color: var(--qrencia-grey-text);
    font-style: italic;
    font-weight: 400 !important;
}

.raiseNudgeButtonDisabled,
.raiseNudgeButtonDisabled:active,
.raiseNudgeButtonDisabled:hover,
.raiseNudgeButtonDisabled:focus {
    background-color: var(--performance-page-disabled-button) !important;
    cursor: not-allowed !important;
}

@media (min-width: 768px) and (max-width: 1200px) {
    .bottomContentContainer {
        padding: 0 2em;
    }

    .topHeadingContainer {
        height: 26vh;
    }

    .bottomContentContainer {
        height: 74vh;
        padding: 0 2em 0 1em;
    }

    .headingText {
        font-size: 2.8em;
        padding: 0 0.5em 20px 0.5em;
        color: var(--qrencia-grey-text-color);
    }

    .subHeadingText {
        font-size: 1.4em;
        color: var(--qrencia-dark-blue);
        padding: 0 0.5em;
    }

    .qrenciaGlobeContainer{
        gap: 1em;
    }

    .qrenciaGlobeLeft {
        width: 250px;
    }

    .weakCreteriaContainer {
        padding: 1em 1.5em;
    }
}

@media (min-width: 580px) and (max-width: 768.5px) {
    .bottomContentContainer {
        padding: 0 2em;
    }

    .topHeadingContainer {
        height: 28vh;
    }

    .bottomContentContainer {
        height: 72vh;
    }

    .headingText {
        font-size: 2.2em;
        padding: 0 0.5em 20px 0.5em;
        color: var(--qrencia-grey-text-color);
        text-align: center;
    }

    .subHeadingText {
        font-size: 1.1em;
        color: var(--qrencia-dark-blue);
        padding: 0 0.5em;
        text-align: center;
    }

    .qrenciaGlobeContainer{
        flex-direction: column;
        gap: 1em;
    }

    .qrenciaGlobeLeft {
        width: 7em;
    }

    .weakCreteriaContainer {
        padding: 1em 1.5em;
    }

    .weakCreteria p {
        font-size: 1em;
    }

    .buttonContainer{
        justify-content: center;
    }
}

@media (max-width: 580.5px) {
    .bottomContentContainer {
        padding: 0 1.5em;
    }

    .topHeadingContainer {
        height: 30vh;
    }

    .bottomContentContainer {
        height: 70vh;
    }

    .headingText {
        font-size: 2em;
        padding: 0 0.5em 20px 0.5em;
        color: var(--qrencia-grey-text-color);
        text-align: center;
    }

    .subHeadingText {
        font-size: 1em;
        color: var(--qrencia-dark-blue);
        padding: 0 20px;
        text-align: center;
    }

    .weakCreteriaContainer {
        padding: 1em 1.5em;
    }

    .weakCreteria {
        padding: 0.5em 1em;
    }

    .weakCreteria p {
        font-size: 0.9em;
    }

    .raiseNudgeButton {
        font-size: 0.8em !important;
        padding: 2px 6px;
    }

    .qrenciaGlobeContainer{
        flex-direction: column;
        gap: 1em;
    }

    .qrenciaGlobeLeft {
        width: 7em;
    }

    .buttonContainer{
        justify-content: center;
    }
}