.discoverYourPassionContent {
  display: flex;
  padding: 6% 13% 6% 13%;
}

.discoverYourPassionSection {
  background: #e8f3ff;
}

.backgrounImageSection {
  padding-bottom: 2%;
  margin-top: -2%;
  text-align: right;
  position: relative;
}

.discoverContentSection {
  width: 100%;
}
.discoverPassionImage {
  width: 100%;
  border-radius: 8px;
  height: auto;
}

.heading {
  color: var(--qrencia-text-black);
  font-weight: 600;
  line-height: 1.2em;
  margin-top: -24px;
  font-size: 2em;
  margin-bottom: 2%;
}

.blueTriangleImage {
  margin-left: -12px;
  margin-top: -8px;
  width: 3%;
}

span {
  color: var(--qrencia-dark-blue);
  font-weight: 800;
}

.subHeading {
  background: var(--qrencia-grey-section);
  padding: 0.5% 2%;
  border-radius: 50px;
  display: inline;
  font-size: 0.8em;
  font-weight: 600;
  margin-top: 8%;
}

.description {
  font-weight: 600 !important;
  margin-top: 4%;
  font-size: 0.92em;
}

.discoverButton {
  margin-top: 4%;
  font-size: 0.9em;
  padding: 1% 3%;
  font-weight: 500;
}

.backgroundDotsImage {
  text-align: right;
  display: inline-block;
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 8em;
  width: 6%;
  object-fit: cover;
  height: 8em;
}

.videoPlayIcon {
  margin-top: -17%;
  margin-left: 13%;
  width: 4%;
  position: absolute;
  cursor: pointer;
}

.videoThumbnailImage {
  cursor: pointer;
  width: 94.5%;
  position: relative;
  border-radius: 12px;
}

@media (min-width: 1440px) {
  .discoverYourPassionContent {
    padding: 6% 14% 6% 14%;
  }
}

@media (min-width: 767px) and (max-width: 1290px) {
  .discoverYourPassionContent {
    padding: 6% 9% 6% 9%;
  }
}

@media (max-width: 767px) {
  .discoverYourPassionContent {
    padding: 8% 3% 9% 3%;
  }

  .discoverContentSection {
    text-align: center;
  }
  .videoPlayIcon {
    margin-top: -50%;
    margin-left: 46%;
    width: 9%;
  }
}

@media (min-width: 990px) and (max-width: 1185px) {
  .heading {
    font-size: 1.7em;
  }
  .blueTriangleImage {
    width: 5%;
  }
  .discoverPointerSection {
    padding: 4% 0 0 0;
    margin-right: 0;
  }
}

@media (min-width: 767px) and (max-width: 990px) {
  .heading {
    font-size: 1.5em;
  }

  .subHeading {
    font-size: 0.8em;
  }

  .description {
    font-size: 0.8em;
  }

  .blueTriangleImage {
    width: 5.8%;
  }
  .backgroundDotsImage {
    display: none;
  }
  .discoverContentSection {
    margin-bottom: 4%;
    /* text-align: center; */
  }
  /* .discoverYourPassionContent{
      flex-direction: column;
      align-items: center;
  } */
  .subscribeButton {
    text-align: left;
  }
  .discoverImageSection {
    width: 40%;
  }
}

@media (max-width: 500px) {
  .blueTriangleImage {
    margin-left: -428px;
  }
}

@media (min-width: 501px) and (max-width: 767px) {
  .blueTriangleImage {
    margin-left: -435px;
  }
}

@media (max-width: 767px) {
  .heading {
    font-size: 1.5em;
  }

  .subHeading {
    font-size: 0.72em;
  }

  .description {
    font-size: 0.8em;
    text-align: left;
  }

  .discoverYourPassionContent {
    display: block;
  }

  .discoverImageSection {
    width: 100%;
  }

  .discoverContentSection {
    padding-left: 0;
  }
  .backgroundDotsImage {
    display: none;
  }
}
@media (min-width: 480px) and (max-width: 540px) {
  .heading {
    font-size: 1.3em;
    margin-top: -21px;
  }

  .blueTriangleImage {
    margin-right: -70px;
    width: 4%;
  }
}
@media (max-width: 480px) {
  .heading {
    font-size: 1.12em;
    font-weight: 650 !important;
  }
  .discoverButton {
    margin-top: 4%;
    font-weight: 500;
    font-size: 0.7em;
    padding: 1.5% 3%;
  }
}

@media (max-width: 360px) {
  .heading {
    font-size: 1.12em;
  }
}
@media (max-width: 480px) {
  .blueTriangleImage {
    margin-left: -320px;
    margin-top: -15px;
    width: 6%;
  }
}
@media (min-width: 768px) and (max-width: 819px) {
  .heading {
    font-size: 1.35em;
  }
}
