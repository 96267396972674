.participantsDetailsContainer {
  margin: 1em 0em;
  padding: 1.5em 2em;
  background-color: var(--qrencia-light-blue-icon-background);
  border-radius: 10px;
  border: 1px solid var(--qrencia-light-grey-border);
}

.InstructionsContainer {
  margin: 1em 0em;
  padding: 2em 2em 1em 2em;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  border: 1px solid var(--qrencia-light-grey-border);
}

.participantsDetailsContainer p {
  font-size: 1em;
}

.instructionList {
  font-size: 0.98em;
}

.participantsDetailsContainer strong {
  font-weight: 700 !important;
}

.InstructionsContainer p {
  font-size: 1em;
}

.InstructionsContainer strong {
  font-weight: 700 !important;
}

.competitionDetailsContainer {
  padding: 2em;
  background-color: var(--qrencia-white);
}

.competitionTitle {
  font-size: 1.3em;
  font-weight: 600 !important;
}

.tableHeadings {
  text-align: center;
}

.instructionContainer {
  padding: 2em;
  background-color: var(--qrencia-white);
}

.backButtonDiv {
  background-color: var(--qrencia-dark-blue);
  border-radius: 50%;
  height: 1.6em;
  width: 1.6em;
  margin-top: -1em;
  margin-bottom: 0.8em;
  margin-left: 0.6em;
  position: relative;
  cursor: pointer;
}

.backButtonDiv > svg {
  color: var(--qrencia-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8em;
}

.instructionTitle {
  font-size: 1em;
  font-weight: 600 !important;
}

.downloadJudingSheetContainer {
  margin: 1em;
}

.downloadJudingSheetButton {
  font-size: 0.88em;
  margin: 0 1em 0 0;
}

.submitButton {
  font-size: 0.88em;
}

.allotmarksContainer {
  padding: 2em 1em;
  background-color: var(--qrencia-white);
}
.teamMembersList {
  font-size: 0.9em;
}
.allotmarksContainer th {
  color: var(--qrencia-text-black);
  background-color: var(--qrencia-light-blue-icon-background);
  font-weight: 600 !important;
  text-align: center;
  vertical-align: middle;
  font-size: 0.95em;
}

.inputBoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputMarksBox {
  width: 80%;
  text-align: center;
  font-size: 1em;
  padding: 2px !important;
}

.allotmarksContainer td {
  font-size: 0.95em;
  vertical-align: middle;
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.aspectMarksContainer {
  text-align: center;
}

.submitButton {
  margin-top: 1em;
}

.errorContainer {
  padding: 2em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-grey-border);
  width: 80%;
  margin: 1em auto;
  text-align: center;
  background-color: var(--qrencia-white);
}

.loaderIcon {
  font-size: 3em !important;
  color: var(--qrencia-dark-blue) !important;
}

.spinner {
  animation: spin-animation 0.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.hyphenText {
  display: contents;
}

.thumbnailSection {
  padding: 1.3%;
  display: flex;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  margin: 1em;
  border: 1px solid var(--qrencia-light-grey-border);
}

.thumbnailContainer {
  width: 12em;
}

.thumbnail {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  aspect-ratio: 825/550;
}

.thumbnailDetailsSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 1%;
  gap: 2px;
}

.competitionDescription {
  font-size: 0.98em;
  font-weight: 400;
}

.location {
  display: contents;
  color: var(--qrencia-text-black);
  font-weight: 400 !important;
}

.judgingEnabledWarningMessage {
  margin: 1em 0;
  background-color: var(--qrencia-extended-page-fun-fact-background);
  border-radius: 6px;
  border: 1px solid var(--instagram-yellow);
  width: max-content;
}

.judgingEnabledWarningMessage svg {
  font-size: 1.5em !important;
  padding: 0;
  color: var(--instagram-yellow);
}

.judgingEnabledWarningMessage p {
  font-size: 0.75em !important;
  font-weight: 500 !important;
  color: var(--instagram-yellow);
  padding: 0.5em;
}

@media (min-width: 768px) and (max-width: 1290px) {
  .inputMarksBox {
    font-size: 1em;
  }

  .allotmarksContainer th {
    font-size: 0.87em;
  }

  .allotmarksContainer td {
    font-size: 0.87em;
  }

  .downloadJudingSheetButton {
    font-size: 0.8em;
  }

  .submitButton {
    font-size: 0.8em;
  }
}

@media (min-width: 581px) and (max-width: 767.5px) {
  .inputMarksBox {
    font-size: 1em;
  }

  .allotmarksContainer th {
    font-size: 0.85em;
  }

  .allotmarksContainer td {
    font-size: 0.85em;
  }

  .downloadJudingSheetButton {
    font-size: 0.8em;
  }

  .submitButton {
    font-size: 0.8em;
  }
}

@media (min-width: 990px) and (max-width: 1290px) {
  .inputMarksBox {
    font-size: 1em;
  }

  .allotmarksContainer th {
    font-size: 0.95em;
  }

  .allotmarksContainer td {
    font-size: 0.95em;
  }
}

@media (max-width: 580px) {
  .inputMarksBox {
    width: 100%;
  }

  .allotmarksContainer th {
    font-size: 0.82em !important;
  }

  .allotmarksContainer td {
    font-size: 0.82em !important;
    vertical-align: top;
  }

  .allotmarksContainer {
    padding: 1em 0.5em;
  }

  .downloadJudingSheetButton {
    font-size: 0.75em;
    margin: 0 1em 0 0;
  }

  .submitButton {
    font-size: 0.75em;
  }

  .downloadJudingSheetContainer {
    margin: 0.5em;
  }

  .instructionContainer {
    padding: 1em;
    font-size: 0.8em !important;
  }

  .participantsDetailsContainer {
    margin: 1em 0em;
    padding: 1em;
  }

  .InstructionsContainer {
    margin: 1em 0em;
    padding: 1em 1em 0.5em 1em;
    font-size: 0.9em;
  }

  .participantsDetailsContainer p {
    font-size: 0.9em;
  }

  .instructionList {
    font-size: 0.9em;
  }

  .InstructionsContainer p {
    font-size: 0.9em;
  }

  .submitButton {
    font-size: 0.75em;
    margin-top: -0.5em;
  }

  .thumbnailSection {
    margin: 1em 0.5em;
  }

  .backButtonDiv {
    margin-top: 0em;
  }
}

@media (max-width: 580px) {
  .judgingEnabledWarningMessage svg {
    font-size: 1.2em !important;
  }

  .judgingEnabledWarningMessage p {
    font-size: 0.6em !important;
  }
}

@media (max-width: 500px) {
  .thumbnail {
    /* height: -webkit-fill-available; */
    /* object-fit: fill; */
    aspect-ratio: 825/550;
    object-fit: cover;
  }
}

@media (max-width: 420px) {
  .inputMarksBox {
    width: 100%;
  }

  .allotmarksContainer th {
    font-size: 0.75em !important;
  }

  .allotmarksContainer td {
    font-size: 0.75em !important;
  }

  .allotmarksContainer {
    padding: 0.5em;
  }
}

@media (min-width: 991px) and (max-width: 1290px) {
  .sectionHeading {
    font-size: 1.4em;
  }

  .participantsJudgedCount {
    font-size: 0.85em;
  }

  .competitionTitle {
    font-size: 1.25em;
  }

  .competitionDescription {
    font-size: 0.94em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .thumbnailContainer {
    width: 10em;
  }

  .judingCountDiv {
    width: 40%;
  }

  .competitionTitle {
    font-size: 1.2em;
  }

  .thumbnailContainer {
    width: 10em;
  }

  .competitionDescription {
    font-size: 0.9em;
  }

  .participantsDetailsContainer p {
    font-size: 0.9em;
  }

  .instructionList {
    font-size: 0.9em;
  }

  .InstructionsContainer p {
    font-size: 0.9em;
  }
}

@media (max-width: 767.5px) {
  .thumbnailContainer {
    width: 10em;
  }

  .competitionTitle {
    font-size: 1.02em;
  }

  .competitionDescription {
    font-size: 0.85em;
  }

  .participantsDetailsContainer p {
    font-size: 0.85em;
  }

  .instructionList {
    font-size: 0.85em;
  }

  .InstructionsContainer p {
    font-size: 0.85em;
  }

  .thumbnailSection {
    padding: 2.3%;
  }

  .thumbnailDetailsSection {
    padding-left: 2%;
  }
}

@media (max-width: 480px) {
  .thumbnailSection {
    flex-wrap: wrap;
    margin-bottom: 5%;
  }

  .thumbnailDetailsSection {
    padding-left: 0;
    padding-top: 0.5em;
  }

  .thumbnailContainer {
    width: 100%;
    /* height: 150px; */
  }

  .thumbnailSection {
    padding: 3.3%;
  }

  .competitionTitle {
    font-size: 1em !important;
  }

  .competitionDescription {
    font-size: 0.8em;
  }

  .participantsDetailsContainer p {
    font-size: 0.8em;
  }

  .instructionList {
    font-size: 0.9em;
  }

  .InstructionsContainer p {
    font-size: 0.9em;
  }

  .submitButton {
    width: 25%;
  }
}
