.recentActivityBtn {
  font-size: 0.8em;
  padding: 0.3em 1em;
}

.recentActivityTitle {
  font-size: 1.3em;
  font-weight: 600;
}

.recentActivityHeader {
  padding: 0.6em 1em;
}

.recentActivityErrorText {
  font-size: 0.75em;
  color: var(--qrencia-error-red);
}

@media (max-width: 540px) {
  .recentActivityHead {
    width: 90%;
    padding-left: 3em;
  }
}

@media (max-width: 430px) {
  .recentActivityHead {
    width: 100%;
    padding-left: 0em;
  }
}