.bannerImage {
  width: 100%;
}
.desktopImgContainer {
  width: 86.3%;
  margin: 0 auto;
}

@media (max-width: 1290px) {
  .desktopImgContainer {
    width: 96.7%;
    margin: 0 auto;
  }
}

@media (min-width: 993px) {
  .tabletImgContainer {
    display: none;
  }
  .mobileImgContainer {
    display: none;
  }
}

@media (max-width: 993px) {
  .tabletImgContainer {
    width: 97.3%;
    margin: 0 auto;
  }
}

@media (min-width: 767px) and (max-width: 992px) {
  .desktopImgContainer {
    display: none;
  }
  .mobileImgContainer {
    display: none;
  }
}

@media (max-width: 767px) {
  .mobileImgContainer {
    width: 97.8%;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .desktopImgContainer {
    display: none;
  }
  .tabletImgContainer {
    display: none;
  }
}
