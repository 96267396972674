.advertisementImageWeb,
.advertisementImageTab,
.advertisementImageMobile {
    width: 100%;
    margin: 4% 0%;
    border-radius: 10px;
    padding: 0% 0.1%;
}

.advertisementImageWeb {
    display: block;
}

.advertisementImageTab {
    display: none;
}

.advertisementImageMobile {
    display: none;
}

@media(min-width:1180px) and (max-width:1290px) {

    .advertisementImageWeb,
    .advertisementImageTab,
    .advertisementImageMobile {

        padding: 0% 0.5%;
    }
}

@media(min-width:992px) and (max-width:1179px) {

    .advertisementImageWeb,
    .advertisementImageTab,
    .advertisementImageMobile {

        padding: 0% 0.8%;
    }
}

@media(min-width:768px) and (max-width:991px) {

    .advertisementImageWeb,
    .advertisementImageMobile {
        display: none;
    }

    .advertisementImageTab {
        display: block;
    }

    .advertisementImageWeb,
    .advertisementImageTab,
    .advertisementImageMobile {

        padding: 0% 0.9%;
    }
}

@media(max-width:767px) {

    .advertisementImageWeb,
    .advertisementImageTab {
        display: none;
    }

    .advertisementImageMobile {
        display: block;
    }

    .advertisementImageWeb,
    .advertisementImageTab,
    .advertisementImageMobile {

        padding: 0% 0.5%;
    }
}

@media(max-width:480px) {

    .advertisementImageWeb,
    .advertisementImageTab,
    .advertisementImageMobile {

        padding: 0% 1.4%;
    }
}