.addRubricsTable {
  margin: 1em auto;
}

.addRubricsTable td,
.addRubricsTable td {
  font-size: 0.8em;
}

.addRubricsTable input,
.addRubricsTable select {
  font-size: 0.8em;
}

.customizeRubricsHeading {
  margin: 1em 1em;
  font-size: 1em;
  font-weight: 550;
  text-align: left;
}

.headers {
  font-size: 0.8em;
  text-align: left;
}

.generalInfoContainer {
  padding: 1em;
  background-color: var(--qrencia-competition-info-bg);
  line-height: 2em;
  margin: 1em 1em 0.5em 1em;
  border-radius: 0.2em;
}

.inactiveComponent {
  opacity: 0.7;
  cursor: not-allowed;
}

.generalInfoHeading {
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 0.5em;
}

.infoButton {
  font-size: 1em;
  color: var(--qrencia-dark-blue) !important;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  z-index: 10;
  max-width: 32em;
}

.generalInfoText {
  font-size: 0.8em;
  text-align: justify;
  color: var(--qrencia-grey-text);
}

.checkboxContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkboxContainer>input {
  flex: 0.1;
  width: 4%;
}

.checkboxContainer>p {
  flex-grow: 1;
  font-size: 0.8em;
  color: var(--qrencia-grey-text);
}

.tableRow {
  position: relative;
  position: -webkit-sticky;
}

.warningErrorContainer {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.nextBtn {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 100%;
  padding: 0.6em;
  text-align: right;
}

.nextBtn>Button {
  font-size: 0.8em;
  padding: 0.6em 2em;
}

.aspectCheckbox {
  text-align: left;
}

.switchContainer {
  margin-top: 2em;
  margin-left: 1em;
  margin-bottom: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.switchText {
  margin-right: 1em;
  font-size: 0.9em;
  width: fit-content;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.switchText input {
  margin-left: 0.8em;
  margin-top: 0.3em;
}

.switchContainer>label {
  line-height: 1em;
}

.warningMessage {
  margin: 1em 0;
  background-color: var(--qrencia-extended-page-fun-fact-background);
  border-radius: 6px;
  border: 1px solid var(--instagram-yellow);
  font-weight: bold;
}

.warningMessage p {
  font-size: 0.75em !important;
  font-weight: 500 !important;
  color: var(--instagram-yellow);
  padding: 0.5em;
}

.warningInfoContainer {
  margin: 0px 10px;
}

.criteriaName {
  font-size: 1em;
}

@media (min-width: 768px) and (max-width: 990px) {
  .switch {
    width: 1.4em;
    height: 0.8em;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(0.6em);
    -ms-transform: translateX(0.6em);
    transform: translateX(0.6em);
  }

  .slider:before {
    height: 0.6em;
    width: 0.6em;
    left: 2px;
    bottom: 2px;
  }

  .switchContainer {
    margin-top: 1em;
    margin-left: 1em;
  }

  .switchText {
    margin-right: 0.6em;
    font-size: 0.8em;
  }
}

@media (max-width: 540px) {
  .switchContainer {
    margin-top: 1em;
    margin-left: 1em;
  }

  .switchText {
    margin-right: 0.5em;
    font-size: 0.8em;
  }

  .switch {
    width: 1.6em;
    height: 0.8em;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(0.7em);
    -ms-transform: translateX(0.7em);
    transform: translateX(0.7em);
  }

  .slider:before {
    height: 0.6em;
    width: 0.6em;
    left: 2px;
    bottom: 2px;
  }

  .addRubricsTable tr {
    padding: 0em;
  }

  .nextBtn {
    margin-top: 1.5em;
    width: 100%;
    padding: 0.6em;
    text-align: center;
  }

  .nextBtn>Button {
    font-size: 0.8em;
    padding: 0.6em 2em;
  }

  .warningMessage svg {
    font-size: 1.2em !important;
  }

  .warningMessage p {
    font-size: 0.65em !important;
  }
}

@media (max-width: 480px) {
  .tooltipMessage {
    font-size: 0.8em;
    max-width: 28em;
  }
}