.mySubmissionContainer {
    background-color: var(--qrencia-white);
    padding: 2% 2.5% 2% 2.5%;
    border-radius: 2em;
    margin-bottom: 1%;
}
.mySubmissionContainer h2 {
    color: var(--qrencia-text-black);
    font-weight: 700;
    font-size: 1.4em;
}

.mediaSection {
    margin: 0.5em 0;
    padding: 1em 0;
  }
.mediaContentContainer {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.8em;
    flex-wrap: wrap;
  }
.imageBox {
    flex: 1 1 calc(25% - 1em);
    height: 12em;
    /* width: 12em; */
    border: 1px solid var(--qrencia-white) !important;
    padding: 0px !important;
    position: relative;
    overflow-clip-margin: content-box;
    cursor: pointer;
    overflow: hidden;
    border-radius: 2px;
    object-fit: cover;
    margin-right: 2px;
  }
.gallaryImage {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    transition: transform 200ms;
    position: absolute;
    top: 0;
    left: 0;
  }
  .gallaryImage:hover {
    transform: scale(1.15);
  }
  .gallaryVideoPlayIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 3em;
    width: 3em;
  }
  .overLay {
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 200ms;
    color: var(--qrencia-white);
    font-size: 1.5em;
    font-weight: 500;
  }
  .overLay:hover {
    font-size: 1.7em;
  }
.gallaryImage:hover {
    transform: scale(1.01);
    transition: 0.2s ease-in-out;
  }
  .fadeImageBackground {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.55);
    height: 12em;
    width: 12em;
    z-index: 1;
    border-radius: 2px;
    width: 100%;
  }

  @media (max-width: 768px) {
    .imageBox {
        flex: 1 1 calc(50% - 1em);
      }
    .fadeImageBackground {
      flex: 1 1 calc(50% - 1em);
    }
  }
  @media (max-width: 560px) {
  
    .gallaryImage {
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: fill;
      border-radius: 10px;
    }
  }  
  
@media (max-width: 480px) {
    .shareIconPannelContainer svg {
      font-size: 0.65em !important;
      vertical-align: unset;
    }
  
    .shareIconPannelContainer a,
    .shareIconPannelContainer a:hover,
    .shareIconPannelContainer a:focus,
    .shareIconPannelContainer a:active {
      padding: 0px 7px 0px 6px;
    }
  
    .shareIconPannelContainer {
      gap: 0.4em;
    }
    .followUnfollowButton {
      margin-top: 8px;
      font-size: 0.65em;
      padding: 1px 14px;
      border-radius: 10px;
    }
    .imageBox {
      height: 10em !important;
      width: 10em !important;
    }
  }
  @media (max-width: 390px) {
    .imageBox {
      height: 9em !important;
      width: 9em !important;
    }
  }