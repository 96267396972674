.medalTable {
  width: 92%;
  margin: 1em auto;
}

.medalTable td,
.medalTable td {
  font-size: 0.8em;
}

.medalTable tr {
  height: 3em;
}

.medalTable input,
.medalTable select {
  font-size: 0.9em;
}

.customizeRubricsHeading {
  margin: 1em 1em;
  font-size: 1em;
  font-weight: 550;
}

.goldMedal {
  color: var(--qrencia-gold-medal-color);
}

.silverMedal {
  color: var(--qrencia-silver-medal-color);
}

.bronzeMedal {
  color: var(--qrencia-bronze-medal-color);
}

.headers {
  font-size: 0.8em;
  text-align: center;
  vertical-align: middle;
}

.medalRowData {
  vertical-align: middle;
}

.inputField {
  width: 5em;
}

.medalCriteriaInputs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 15em;
}

.medalInfoText {
  margin-left: 1em;
}

.warningMessage {
  margin: 1em 0;
  background-color: var(--qrencia-extended-page-fun-fact-background);
  border-radius: 6px;
  border: 1px solid var(--instagram-yellow);
  margin-left: 4%;
  max-width: 90%;
}

.warningMessage svg {
  font-size: 1.5em !important;
  padding: 0;
  color: var(--instagram-yellow);
}

.warningMessage p {
  font-size: 0.75em !important;
  font-weight: 500 !important;
  color: var(--instagram-yellow);
  padding: 0.5em;
}

.awardTypeInput {
  margin: 0.5em;
}

.generalInfoContainer {
  padding: 1em;
  background-color: var(--qrencia-competition-info-bg);
  line-height: 2em;
  margin: 1em 1em 0.5em 1em;
  border-radius: 0.2em;
}

.generalInfoHeading {
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 0.5em;
}

.generalInfoText {
  font-size: 0.8em;
  color: var(--qrencia-grey-text);
}

.instituteTypeHeading {
  width: 92%;
  margin: 0em auto;
  margin-top: 0.8em;
  font-weight: 500;
}

.switchContainer {
  margin-top: 1em;
  margin-left: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.switchContainer>label {
  line-height: 1em;
}

.switchText {
  margin-right: 1em;
  font-size: 0.9em;
  width: fit-content;
  display: flex;
  align-items: center;
}

.switchText input {
  margin-left: 0.8em;
  margin-top: 0.3em;
}

@media (min-width: 768px) and (max-width: 990px) {
  .switch {
    width: 1.4em;
    height: 0.8em;
  }

  .switchContainer {
    margin-top: 1em;
    margin-left: 1em;
  }

  .switchText {
    margin-right: 0.6em;
    font-size: 0.8em;
  }
}

@media (max-width: 580px) {
  .warningMessage svg {
    font-size: 1.2em !important;
  }

  .warningMessage p {
    font-size: 0.65em !important;
  }

  .switchContainer {
    margin-top: 1em;
    margin-left: 1em;
  }

  .switchText {
    margin-right: 0.5em;
    font-size: 0.8em;
  }

  .switch {
    width: 1.6em;
    height: 0.8em;
  }
}