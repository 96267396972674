.buttonIcon {
  height: 2.8rem;
}

@media (max-width: 600px) {
  .buttonIcon {
    height: 2.5rem;
  }
}

@media (max-width: 441px) {
  .buttonIcon {
    height: 2.3rem;
  }
}

@media (max-width: 405px) {
  .buttonIcon {
    height: 2rem;
  }
}

@media (max-width: 381px) {
  .buttonIcon {
    height: 1.7rem;
  }
}

@media (max-width: 357px) {
  .buttonIcon {
    height: 1.5rem;
  }
}