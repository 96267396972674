.adminCreateObrTypeahead .dropdown-menu {
  inset: 6px auto auto -28px !important;
  margin-left: 2.2em;
  font-size: 0.8em;
  padding: 0;
  border-radius: 0;
  border: solid 1px var(--qrencia-select-box-border);
}
.adminCreateObrTypeaheadMultiple .rbt-input-multi {
  height: 2.9em;
  overflow-y: auto;
}
.adminCreateObrTypeaheadMultiple .rbt-input-multi .rbt-input-main {
  height: 2.7em !important;
  outline: none !important;
  border: none !important;
}
.obrStatusContainer {
  width: 100%;
  padding: 0.5em 1em;
  text-align: right;
  position: relative;
}
.obrStatusBox {
  padding: 0.5em;
  height: 1.8em;
  font-size: 0.8em;
  width: max-content;
  line-height: 0.8em;
  border-radius: 0.4em;
  background-color: var(--qrencia-competition-info-bg) !important;
  position: absolute;
  top: 0em;
  right: 1em;
}
.obrStatusApproved {
  color: var(--qrencia-success-green);
}
.obrStatusRejected {
  color: var(--qrencia-error-red) !important;
}
.obrStatusDraft {
  color: var(--qrencia-orange-header) !important;
}
.obrStatusReview {
  color: var(--qrencia-dark-blue) !important;
}
.obrStatusCreated {
  color: var(--qrencia-text-black);
}
