* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

:root {
  --qrencia-white: #ffffff;
  --qrencia-text-black: #000000;
  --qrencia-dark-blue: #224b7f;
  --qrencia-blue-darker-contrast: #1c3d66;
  --qrencia-orange-heading: #efaf4b;
  --qrencia-lemon-yellow-heading: #fcf9df;
  --qrencia-light-blue-background: #f5faff;
  --qrencia-grey-border: #cecece;
  --qrencia-dark-grey-border: #bbbbbb;
  --qrencia-card-light-blue-background: #effcf4;
  --qrencia-card-light-blue-border: #c2efcf;
  --qrencia-card-light-grey-background: #dfd4f5;
  --qrencia-card-light-grey-border: #dfd4f5;
  --qrencia-card-light-orange-background: #fcf9df;
  --qrencia-card-light-orange-border: #eae6c3;
  --qrencia-beryls-count-purple: #7d6fea;
  --qrencia-card-blue: #0161b5;
  --qrencia-card-purple: #7d6fea;
  --qrencia-purple-heading: #9287d2;
  --qrencia-template-background-color: #f9f9f9;
  --qrencia-card-blue-link-color: #5f7a96;
  --qrencia-button-pink: #dc3086;
  --qrencia-icons: #7e84a3;
  --qrencia-beryl-sepration-border: #3a7cbe;
  --qrencia-beryl-background: rgba(184, 210, 235, 0.2);
  --qrencia-logo-border: #d1d1d1;
  --qrencia-select-box-border: #cbdef7;
  --qrencia-select-box-background: #f4f9fe;
  --qrencia-user-name: #131523;
  --qrencia-cards-border: #dedede;
  --qrencia-card-green-span: #d3fcda;
  --qrencia-competition-carousel-mode-text: #befff2;
  --qrencia-carousel-green-card-background: #00b592;
  --qrencia-carousel-blue-card-background: #0061b5;
  --qrencia-carousel-purple-card-background: #7d6fea;
  --qrencia-carousel-card-green-span: #07866d;
  --qrencia-carousel-card-blue-span: #054d8b;
  --qrencia-carousel-card-purple-span: #4636c3;
  --qrencia-carousel-green-image-border: #c2f5e9;
  --qrencia-carousel-blue-image-border: #a2d3fd;
  --qrencia-carousel-purple-image-border: hsl(248, 97%, 85%);
  --qrencia-tabs-border: #d5ebdc;
  --qrencia-tabs-bcakground: #fcfffd;
  --qrencia-load-more-button-background: #e5e5e5;
  --qrencia-grey-text: #7e7e7e;
  --qrencia-sepration-line: #e0e0e0;
  --raised-nudge-background: #dfe4fc;
  --raised-nudge-text: #131523;
  --card-grey-title: #575757;
  --card-grey-background: #f5f5f5;
  --qrencia-glance-card-blue-background: #effcf4;
  --qrencia-glance-card-purple-background: #f7f4fe;
  --qrencia-glance-card-orange-background: #fcf9df;
  --qrencia-glance-card-blue-border: #c2e5cf;
  --qrencia-glance-card-purple-border: #dfd4f5;
  --qrencia-glance-card-orange-border: #eae6c3;
  --qrencia-grey-section: #e2dcf5;
  --qrencia-what-more-blue-card: #f2fafe;
  --qrencia-what-more-pink-card: #fefaf2;
  --qrencia-extended-page-header: #e1ebf3;
  --qrencia-skill-page-could-be-header: #34b4b3;
  --qrencia-skill-required-header: #f4aa78;
  --qrencia-skill-put-to-use-header: #7d6fea;
  --qrencia-extended-page-light-blue-background: #eff4f8;
  --qrencia-extended-page-fun-fact-background: #ffefd5;
  --qrencia-seach-form-border: #818a91;
  --qrencia-tabs-inactive: #333333;
  --qrencia-orange-header: #fabe61;
  --qrencia-competition-tabs-success: #5ab34d;
  --qrencia-competition-tabs-active: #ecfdff;
  --qrencia-competition-scroll: #85929e;
  --qrencia-competition-info-bg: #e5e7e9;
  font-family: 'Poppins', sans-serif;
  --qrencia-orange-header: #fabe61;
  --qrencia-error-message: #e5686e;
  --qrencia-disabled-input-bg: #f5f5f5;
  --qrencia-disabled-input-border: #dddddd;
  --qrencia-profile-dropdown-background: #e9ecef;
  --qrencia-astrick-red: #ff0000;
  --linkedin-blue: #0072b1;
  --facebook-blue: #2d68c4;
  --instagram-yellow: #f09433;
  --instagram-orange: #e6683c;
  --instagram-red: #ff0000;
  --cancel-red-button: #d63500;
  --instagram-pink: #cc2366;
  --instagram-darkpink: #bc1888;
  --twitter-blue: #1da1f2;
  --qrencia-error-red: #ff0000;
  --qrencia-success-green: #008800;
  --qrencia-transparent: #ffffff00;
  --qrencia-selected-date-background: #f7f1e5;
  --qrencia-grey-text-calendar: #b7b7b7;
  --qrencia-calendar-widget-card-background: #f7f5f1;
  --qrencia-competition-color-code: #efb04e;
  --qrencia-light-blue-icon-background: #e4f0ff;
  --qrencia-light-grey-border: #efefef;
  --qrencia-your-performance-card-shadow: 1px 1px 7px 0px rgb(0 0 0 / 20%);
  --qrencia-template-box-shadow: 0 3px 21px 0 rgba(100, 100, 100, 0.12);
  --qrencia-file-upload-container-border: #e8daef;
  --qrencia-file-upload-container-bg: #f3f3f3;
  --qrencia-selected-date-background-blue: #dff1f8;
  --qrencia-activity-snapshot-cardone-bg: #fef4f5;
  --qrencia-activity-snapshot-cardtwo-bg: #fefef4;
  --qrencia-activity-snapshot-cardthree-bg: #f4fefb;
  --qrencia-activity-snapshot-cardfour-bg: #fef8f4;
  --qrencia-activity-snapshot-iconone-bg: #db969d;
  --qrencia-activity-snapshot-icontwo-bg: #cece83;
  --qrencia-activity-snapshot-iconthree-bg: #80d6bc;
  --qrencia-activity-snapshot-iconfour-bg: #e3ae8a;
  --qrencia-competition-rank-background: #d3fcda;
  --qrencia-placeholder-color: #626567;
  --qrencia-learning-activity-tabs-border: #d0d0d0;
  --qrencia-nudges-card-background: #f1f9ff;
  --qrencia-nudges-card-border: #9ec9ff;
  --qrencia-nudges-card-text-bg: #c5dfff;
  --qrencia-attached-file-name: #15c;
  --qrencia-golden-color: #daa520;
  --qrencia-input-bottom-border: #e5e7e9;
  --qrencia-mail-file-preview-bg: #f5f5f5;
  --qrencia-judge-card-bg: #f4fbfe;
  --qrencia-judge-card-name: #2980b9;
  --qrencia-judge-card-email: #626567;
  --qrencia-grid-grey: #cccccc;
  --qrencia-obr-comment-bg: #eee;
  --qrencia-obr-rejected-bg: #ff9e9e;
  --qrencia-obr-cards-background: #f7f8fa;
  --qrencia-success-message: #5ab34d;
  --qrencia-orange-button: #ff9b00;
  --qrencia-addfaq-text-bg: #f7f4fe;
  --qrencia-faq-card-bg: #f7fcff;
  --qrencia-error-toast-header-bg: #ffd6cd;
  --qrencia-certificate-preview-border: #eee;
  --qrencia-link-color: #0d6efd;
  --qrencia-grey-text-color: #363739;
  --qrencia-faq-icon-color: #525252;
  --qrencia-ask-course-question-btn: #aedd7e;
  --qrencia-course-skill-name-color: #c2e2ff;
  --qrencia-expert-widget-name: #224b7f;
  --qrencia-upcoming-course-widget-bg: #f1f7f7;
  --performance-page-weak-aspects-bg: #e77a7b;
  --performance-page-raise-nudge-bg: #224b7f;
  --performance-page-rank-color: #efb04e;
  --performance-page-rank-content: #083d65;
  --performance-page-skyblue-bg: #eff5f9;
  --performance-page-rank-content-blue: #224b7f;
  --performance-page-rank-skyblue: #468dc3;
  --performance-page-top-score-orange-bg: #f7ead2;
  --performance-page-lowscore-border: #7d6fea;
  --performance-page-midscore-border: #efb04e;
  --performance-page-highscore-border: #34b4b3;
  --performance-weak-section-bg: #f5dfe2;
  --performance-page-purple-border: #7d6fea;
  --performance-page-aqua-border: #40c6e6;
  --performance-page-raise-nudge-lightblue-bg: #ecf3f8;
  --performance-page-inner-container-bg: #c6d9e8;
  --performance-page-skill-stamp-text: #004aad;
  --performance-page-disabled-button: #4a678b;
  --performance-page-rank-message-blue: #062e51;
  --performance-page-reward-section-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(19, 19, 26, 0.3) 0px 0px 8px;
  --qrenica-course-differentiator-color: #34b4b3;
  --qrenica-competition-differentiator-color: #efb04e;
  --qrenica-nudge-differentiator-color: #7d6fea;
  --qrencia-workshop-differentiator-color: #e77a7b;
  --qrencia-workshop-card-background: #f9f2f4;
  --student-nudge-widget-bg: #f5f4ff;
  --background-light-blue: #e7f2ff;
  --border-blue-color: #cedfe8;
  --qrencia-blue-background-course-cost: #32b6b3;
  --qrencia-activity-cards-border: #d0e0e9;
  --qrencia-cost-background: #d6eaf8;
  --qrencia-academy-card-top-border: #ffc66d;
  --qrencia-date-filter-card-color: #34495e;
  --qrencia-attendance-action-button-color: #e5e8e8;
  --qrencia-present-button-color: #52be80;
  --qrencia-absent-button-color: #ec7063;
  --qrencia-blue-background: #e8f3ff;
  --qrencia-whatsapp-color: #2eb743;
  --qrencia-cards-date-time-color: #424949;
  --qrencia-gold-medal-color: #d4b401;
  --qrencia-silver-medal-color: #c0c0c0;
  --qrencia-bronze-medal-color: #97784d;
  --qrencia-add-team-button: #ececec;
  --qrencia-team-background-color: #ddf0ff;
  --qrencia-team-participant-details-background: #f8fcff;
  --qrencia-grey-background: #e7e7e7;
  --qrencia-grey-quotes: #cad1da;
  --qrencia-bright-golden: #fcc200;
  --qrencia-popular-yellow-tag: #ffbf00;
  --qrencia-price-text: #b0b0b0;
  --qrencia-slider-bg-one: #e8f1ff;
  --qrencia-slider-bg-two: #eaced2;
  --qrencia-slider-bg-three: #c7e4e6;
  --qrencia-slider-bg-four: #f5ead6;
  --qrencia-slider-bg-five: #ddd4f2;
  --qrencia-yellow-background: #f7ead4;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: var(--qrencia-template-background-color) !important;
}

.learnerProfilePage {
  background: var(--qrencia-grey-background);
}

#ch-tooltip {
  background-color: white !important;
}

.highlight {
  fill: #efb04e !important;
  stroke-width: 0px !important;
}

/* .ql-editor {
  word-break: break-all !important;
} */

.modal-open {
  overflow: auto !important;
  padding: 0% !important;
}

.btn {
  font-size: 1em;
  background-color: var(--qrencia-dark-blue);
  border: 1px solid var(--qrencia-dark-blue);
  border-radius: 3px;
  text-align: center;
  padding: 0.5% 2%;
}

.btn:hover,
.btn:focus,
.btn:active {
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='checkbox'] {
  vertical-align: top;
  cursor: pointer;
}

.checkboxInputForm {
  margin-right: 0.5em;
}

input[type='text']:disabled,
input[type='file']:disabled,
input[type='date']:disabled,
input[type='number']:disabled,
input[type='time']:disabled,
input[type='url']:disabled,
.form-control:disabled,
.form-select:disabled {
  background: var(--qrencia-disabled-input-bg) !important;
  border: solid 1px var(--qrencia-disabled-input-border) !important;
  cursor: not-allowed;
}

p {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400 !important;
}

.linkText {
  cursor: pointer;
  text-decoration: underline;
}

input,
textarea,
select,
.form-control,
.form-select {
  padding: 7px 6px 7px 6px !important;
  width: 100%;
  box-sizing: border-box;
  color: var(--qrencia-text-black) !important;
  font-size: 0.82em;
  border-radius: 0px;
  border: solid 1px var(--qrencia-select-box-border);
  background: var(--qrencia-select-box-background);
  line-height: 1.57;
  letter-spacing: 0.28px;
  font-weight: 400;
}

.form-select {
  border-radius: 0px;
}

.form-control:focus {
  background: var(--qrencia-select-box-background) !important;
  border: solid 1px var(--qrencia-select-box-border) !important;
}

.form-select.is-invalid:not([multiple]):not([size]) {
  background: var(--qrencia-select-box-background) !important;
}

::-webkit-file-upload-button {
  background-color: var(--card-grey-background) !important;
  outline: none;
  border: none;
  font-weight: 600;
  border-radius: 5%;
  cursor: pointer;
  font-size: 0.8em;
  margin-left: 0.1em;
  width: 10em;
}

.errorMessage {
  background-color: var(--qrencia-error-message);
  color: var(--qrencia-white);
  font-size: 0.8em;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  margin-bottom: 6px;
  padding: 1.5% 2%;
}

.errorMessage svg {
  margin-right: 2%;
  font-size: 1.2em;
  margin-top: -0.5%;
}

.successMessage {
  background-color: var(--qrencia-success-message);
  color: var(--qrencia-white);
  font-size: 0.78em;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  margin-bottom: 6px;
  padding: 1.5% 2%;
}

.pageLoader {
  color: var(--qrencia-dark-blue);
  font-size: 2.2em;
  text-align: center;
  background-color: var(--qrencia-white);
  padding-bottom: 2%;
}

.templatePageLoader {
  color: var(--qrencia-dark-blue);
  font-size: 2.2em;
  text-align: center;
  padding-bottom: 2%;
}

.goldMedal {
  color: var(--qrencia-gold-medal-color);
}

.silverMedal {
  color: var(--qrencia-silver-medal-color);
}

.bronzeMedal {
  color: var(--qrencia-bronze-medal-color);
}

.spinner {
  animation: spin-animation 0.5s infinite;
}

.loadMore {
  margin-bottom: 10px;
}

.successMessage svg {
  margin-right: 2%;
  font-size: 1.2em;
  margin-top: -0.5%;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--qrencia-placeholder-color);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--qrencia-placeholder-color);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--qrencia-placeholder-color);
}

input:focus,
textarea:focus,
select:focus,
.form-control:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: solid 1px var(--qrencia-select-box-border) !important;
  outline-width: 0;
  font-weight: 400 !important;
}

.form-control.is-valid,
.form-control.is-invalid {
  background-image: none;
}

option {
  font-weight: 400 !important;
}

.row {
  margin: 0;
}

#lpSkillTypeahead.rbt-menu.dropdown-menu.show {
  inset: 0px auto auto 0px !important;
  font-size: 0.9em;
  max-height: 285px !important;
  border-radius: 0px;
  z-index: 1000;
}

.templateDivSection {
  background-color: var(--qrencia-white);
  border-radius: 12px;
  margin-bottom: 4%;
  padding: 2% 2.5%;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.enableButton {
  background-color: var(--qrencia-success-green) !important;
  border: none !important;
}

.disableButton {
  background-color: var(--cancel-red-button) !important;
  border: none !important;
}

.enableButton:hover,
.enableButton:active,
.enableButton:focus {
  background-color: var(--qrencia-success-green) !important;
  border: none !important;
}

.disableButton:hover,
.disableButton:active,
.disableButton:focus {
  background-color: var(--cancel-red-button) !important;
  border: none !important;
}

.templateDivHeading {
  font-size: 1.1em;
  line-height: 1.22;
  letter-spacing: 0.36px;
  font-weight: 600;
  margin-bottom: 2%;
  text-align: left;
}

.formHeading {
  font-size: 1em;
  line-height: 1.22;
  letter-spacing: 0.36px;
  font-weight: 600;
  text-align: left;
}

.templateSectionHeading {
  font-size: 1.04em;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 800;
  margin-bottom: 1.5%;
  text-align: left;
  padding-left: 2.8%;
}

.qrenciaActionButton {
  padding: 6.8% 26% !important;
  line-height: 1.38;
  font-size: 0.8em;
  font-weight: 500;
  border: 1px solid var(--qrencia-white) !important;
  text-align: center;
  border-radius: 4px;
}

.qrenciaActionButton:hover,
.qrenciaActionButton:active,
.qrenciaActionButton:focus {
  padding: 6.8% 26% !important;
  line-height: 1.38;
  font-size: 0.8em;
  font-weight: 500;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--qrencia-white) !important;
  margin: 0;
}

.qrenciaCardsButton {
  padding: 5.8% 15% !important;
  line-height: 1.38;
  font-size: 0.8em;
  font-weight: 500;
  border: 1px solid var(--qrencia-white) !important;
  text-align: center;
  border-radius: 4px;
}

.containerHeadings {
  font-size: 1em;
  font-weight: 650;
  color: var(--qrencia-text-black);
  margin: 0.5em 0.5em 1em 0.5em;
}

.qrenciaCardsButton:hover,
.qrenciaCardsButton:active,
.qrenciaCardsButton:focus {
  padding: 5.8% 15% !important;
  line-height: 1.38;
  font-size: 0.8em;
  font-weight: 500;
  border: 1px solid var(--qrencia-white) !important;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--qrencia-white) !important;
  margin: 0;
}

.alert-dismissible .btn-close {
  padding: 1.5%;
  font-size: 0.85em;
  box-shadow: none !important;
}

.alert-dismissible .btn-close e .active,
.alert-dismissible .btn-close :focus {
  box-shadow: none !important;
}

.loadMoreButtonSection {
  text-align: center;
  margin: 1% 0%;
}

.activeText {
  color: var(--qrencia-success-green) !important;
  font-weight: 400 !important;
}

.inactiveText {
  color: var(--cancel-red-button) !important;
  font-weight: 400 !important;
}

.loadMoreButton,
.loadMoreButton:hover,
.loadMoreButton:active,
.loadMoreButton:focus {
  background: var(--qrencia-load-more-button-background) !important;
  border-radius: 16px;
  font-size: 0.7em;
  font-weight: 600;
  color: var(--qrencia-text-black) !important;
  border: none;
  padding: 0.5% 1.8% !important;
  width: max-content;
}

/* Form Styling */
.formContainer {
  width: 100%;
  margin: 2em auto;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-top: 0px;
  min-height: 14em;
}

.formInputBox {
  width: 100%;
  padding: 6px !important;
}

.formInputBox.invalid {
  border: 1px solid var(--qrencia-astrick-red) !important;
}

.formTextEditorInput {
  border: none;
}

.formTextEditorInput.invalid {
  border: 1px solid var(--qrencia-astrick-red) !important;
}

.formLabel {
  font-size: 0.88em;
}

.validationErrorMessage {
  font-size: 0.8em;
  color: var(--qrencia-error-message);
}

input[type='file']::file-selector-button {
  font-size: 0.9em;
  background: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white);
  cursor: pointer;
  position: relative;
  width: 25%;
  font-weight: 500;
}

.redAstrisk {
  color: var(--qrencia-astrick-red);
}

.noDataAvailableError {
  padding: 3em 1em;
  margin: 1em 0;
  text-align: center;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  color: var(--qrencia-grey-text);
  font-style: italic;
  font-weight: 400 !important;
  border: 1px solid var(--qrencia-cards-border);
}

.obrRejectedError {
  padding: 2em 1em;
  margin: 1em 0;
  text-align: left;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  color: var(--qrencia-grey-text) !important;
  font-style: normal;
  font-weight: 400 !important;
  border: 1px solid var(--qrencia-cards-border);
}

.removeFileText {
  font-size: 0.75em;
  color: var(--twitter-blue);
  cursor: pointer;
  margin-top: -2px;
  margin-left: 2px;
}

.errorText {
  font-size: 0.75em;
  color: var(--qrencia-error-red);
}

.seperator {
  color: var(--qrencia-card-light-grey-background);
  font-weight: 500 !important;
}

.loaderIcon {
  font-size: 3em !important;
  color: var(--qrencia-dark-blue) !important;
}

.templateHomeBannerSection {
  text-align: center;
}

.templateHomeBannerImage {
  width: 100%;
  border-radius: 10px;
}

.spinner {
  animation: spin-animation 0.5s infinite;
}

.errorContainer {
  padding: 2em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-grey-border);
  width: 80%;
  margin: 1em auto;
  text-align: center;
  background-color: var(--qrencia-white);
}

.spinnerColor {
  color: var(--qrencia-dark-blue);
  font-size: 1.2em;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  background-color: var(--qrencia-dark-blue);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.studentContentSection {
  position: relative;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.gridEditButton,
.gridEditButton:hover,
.gridEditButton:active,
.gridEditButton:focus {
  font-weight: 400;
  border: none;
  padding: 1% 6% !important;
  width: max-content;
}

/* Form Styling */
@media (max-width: 1290px) {
  input[type='file']::file-selector-button {
    font-size: 0.82em;
    position: relative;
    width: 35%;
  }

  .formLabel {
    font-size: 0.85em;
  }
}

@media (max-width: 990px) {

  .loadMoreButton,
  .loadMoreButton:hover,
  .loadMoreButton:active,
  .loadMoreButton:focus {
    padding: 2px 8px !important;
  }

  input[type='file']::file-selector-button {
    font-size: 0.82em !important;
    width: 30% !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .templateDivSection {
    padding: 3.5%;
  }

  .templateDivHeading {
    font-size: 0.96em;
  }

  .templateSectionHeading {
    font-size: 0.9em;
  }

  .formHeading {
    font-size: 0.92em;
  }
}

@media (min-width: 767px) and (max-width: 820px) {
  .qrenciaCardsButton {
    font-size: 0.7em;
  }

  input[type='file']::file-selector-button {
    font-size: 0.82em !important;
    width: 35% !important;
  }
}

@media (max-width: 767px) {
  .templateDivSection {
    padding: 5.5%;
  }

  .templateDivHeading {
    font-size: 0.92em;
  }

  .formHeading {
    font-size: 0.86em;
  }

  .templateSectionHeading {
    font-size: 0.85em;
  }

  .qrenciaCardsButton {
    font-size: 0.7em;
  }

  input[type='file']::file-selector-button {
    font-size: 0.82em !important;
    width: auto !important;
  }

  .formLabel {
    font-size: 0.85em;
  }
}

@media (max-width: 767.5px) {
  .studentSection {
    margin-top: 5%;
  }
}

@media (max-width: 580px) {
  .errorText {
    font-size: 0.6em;
  }
}

@media (max-width: 480px) {

  .loadMoreButton,
  .loadMoreButton:hover,
  .loadMoreButton:active,
  .loadMoreButton:focus {
    padding: 1.5px 8px !important;
  }

  .studentSection {
    margin-top: 6%;
  }
}

@media (max-width: 360px) {
  .qrenciaCardsButton {
    font-size: 0.7em;
  }
}

@media (min-width: 1290px) {
  .expertContentSection {
    min-height: 84vh !important;
  }

  .agencyContentSection {
    min-height: 84vh !important;
  }

  .studentContentSection {
    min-height: 84vh !important;
  }

  .adminContentSection {
    min-height: 84vh !important;
  }
}

@media (min-width: 991px) and (max-width: 1290px) {
  .expertContentSection {
    min-height: 95vh !important;
  }

  .agencyContentSection {
    min-height: 95vh !important;
  }

  .studentContentSection {
    min-height: 85vh !important;
  }

  .adminContentSection {
    min-height: 95vh !important;
  }

  .templateDivHeading {
    font-size: 1em;
  }

  .formHeading {
    font-size: 0.92em;
  }
}

@media (min-width: 630.5px) and (max-width: 991.5px) {
  .expertContentSection {
    min-height: 138vh !important;
  }

  .agencyContentSection {
    min-height: 138vh !important;
  }

  .studentContentSection {
    min-height: 98vh !important;
  }

  .adminContentSection {
    min-height: 130vh !important;
  }
}

@media (max-width: 630px) {
  .expertContentSection {
    min-height: 140vh !important;
  }

  .agencyContentSection {
    min-height: 140vh !important;
  }

  .studentContentSection {
    min-height: 98vh !important;
  }

  .adminContentSection {
    min-height: 140vh !important;
  }
}

@media (max-width: 450px) {
  .expertContentSection {
    min-height: 150vh !important;
  }

  .agencyContentSection {
    min-height: 150vh !important;
  }

  .adminContentSection {
    min-height: 150vh !important;
  }
}

@media (min-width: 1140px) and (max-width: 1290px) {
  #lpSkillTypeahead.rbt-menu.dropdown-menu.show {
    font-size: 0.88em;
  }
}

@media (min-width: 900px) and (max-width: 1140px) {
  #lpSkillTypeahead.rbt-menu.dropdown-menu.show {
    font-size: 0.8em;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  #lpSkillTypeahead.rbt-menu.dropdown-menu.show {
    font-size: 0.8em;
  }
}

@media (max-width: 767.5px) {
  #lpSkillTypeahead.rbt-menu.dropdown-menu.show {
    font-size: 0.8em;
  }

  .templateHomeBannerSection {
    margin-top: 1.5em;
  }
}

@media (max-width: 480px) {
  #lpSkillTypeahead.rbt-menu.dropdown-menu.show {
    font-size: 0.8em;
  }
}