.skillProgressionHeader {
  background-color: var(--qrencia-dark-blue);
  padding: 1em;
}

.section {
  width: 85%;
  margin: 1em auto;
}

.pageContainer {
  /* background-color: var(--qrencia-white); */
  border-radius: 10px;
  width: 90%;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  margin: 0em auto;
  padding: 1em 0 0 0;
}

.skillAssessmentReportTitle {
  font-size: 2em;
  color: var(--qrencia-white);
  font-weight: 700;
}

.skillProgressionSection {
  /* display: flex; */
  justify-content: space-between;
}

.skillAssessmentReportSubTitle {
  font-size: 0.8em;
  color: var(--qrencia-white);
  font-weight: 700 !important;
  margin-bottom: 0.3em;
}

.approvedSeal {
  margin-left: 0.3em;
  font-size: 1.1em;
}

.SkillProgressionReport {
  background-color: var(--qrencia-template-background-color);
  /* padding: 1em; */
}

.studentProfileSection {
  background-color: var(--qrencia-white);
  padding: 1em;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
}

.SkillAssessmentReportFooter {
  background-color: var(--qrencia-dark-blue);
  text-align: center;
  font-weight: 200;
  color: var(--qrencia-white);
  padding: 0.3em 0;
  font-size: 1.1em;
  font-weight: 500;
}

.studentDescription {
  font-size: 0.95em;
  margin-bottom: 1em;
  /* letter-spacing: 0.5px; */
  /* margin-top: 7.8em; */
}

.studentDescriptionSection {
  margin-top: 7.8em;
}

.studentName {
  font-size: 1.7em;
  color: var(--qrencia-dark-blue);
  font-weight: 700 !important;
}

.currentAssessment {
  background-color: var(--qrencia-white);
  padding: 1.2em 1em;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 1em;
  margin-bottom: 1em;
}

.currentAssessmentTitle {
  color: var(--qrencia-text-black);
  font-weight: 700;
  font-size: 1.4em;
}

.profileImageSection {
  border-radius: 10px;
  display: inline-block;
  height: 160px;
  overflow: auto;
  width: 170px;
  border: 1px solid var(--qrencia-white);
  color: var(--qrencia-white);
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 2em;
  background-color: var(--qrencia-dark-blue);
}

.studentProfileIdentity {
  display: flex;
  position: absolute;
  top: -45px;
}

.profileImage {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.studentProfile {
  /* display: flex; */
  margin-bottom: 0.7em;
}

.studentAboutUs {
  align-self: end;
  margin-left: 1em;
  margin-bottom: 1.3em;
}

.awardIcon {
  padding: 18%;
  font-size: 2.3em;
  background-color: var(--qrencia-cost-background);
  box-shadow: rgba(67, 71, 85, 0.4) 0px 0px 0.35em;
  border-radius: 10px;
}

.awardSection {
  display: flex;
  margin-bottom: 1.2em;
}

.awardTitle {
  font-size: 0.82em;
  font-weight: 500 !important;
}

.awardSubTitle {
  font-size: 0.7em;
  font-weight: 300 !important;
}

.awardContainers {
  margin-left: 0.7em;
}
.skillReportsBtn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1em;
}

.downloadBtn {
  background-color: var(--card-grey-background);
  display: inline-block;
  padding: 0.1em 1em;
  font-size: 0.9em;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10% / 50%;
}

.downloadBtn svg {
  margin-right: 0.5em;
}
.shareBtn svg {
  margin-right: 0.5em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 1s linear infinite;
  margin-right: 8px;
}

.shareBtn {
  background-color: var(--qrencia-dark-blue);
  color: #fff;
  padding: 0.1em 1em;
  font-size: 0.9em;
  font-weight: 500;
  border-radius: 10% / 50%;
  margin-left: 1em;
  cursor: pointer;
  pointer-events: auto;
  z-index: 10;
}

.chartContainer {
  padding: 3% 1em 2% 1em;
  position: relative;
}

.chartParam1 {
  position: absolute;
  top: 190px;
  transform: rotate(-90deg);
  left: -25px;
}
.chartParam2 {
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.assessmentFooter {
  text-align: center;
}

.recommendationHeading {
  font-size: 1.1em;
  font-weight: 700;
  margin-top: 1em;
}

.assessmentHead {
  font-size: 0.9em;
  font-weight: 700;
}

.recommendationText {
  margin-top: 0.3em;
  font-size: 0.9em;
}

.recommendationText svg {
  margin-right: 1em;
  color: var(--qrencia-dark-blue);
}

.assessmentSubHead {
  font-size: 0.7em;
}

.skillTableContainer {
  margin: 4rem 0 2em 0;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.assessmentTable {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  min-width: 600px;
  border: none !important;
}

.skillTableHeader {
  padding: 1rem;
  background-color: var(--qrencia-cost-background);
  text-align: center;
  border-bottom: 2px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  font-size: 1.1em;
}

.noBorderRight {
  border-right: none !important;
}

.tableRow {
  border-bottom: 1px solid #e0e0e0;
}

/* table {
  border: none !important;
} */
/* th,
td,
tr:last-child {
  border: none !important;
} */

.assessmentTable th.noBorderRight,
.assessmentTable td.noBorder {
  border: none !important;
}

/* Remove adjacent cell borders that might create visual artifacts */
.assessmentTable td.noBorder + td,
.assessmentTable th.noBorderRight + th {
  border-left: none !important;
}

/* Optional: Maintain borders for other columns */
.assessmentTable th:not(.noBorderRight),
.assessmentTable td:not(.noBorder) {
  border: 1px solid #dee2e6; /* Match your desired border style */
}

.skillTableContainer table.assessmentTable {
  border-style: hidden !important;
}

.skillTableContainer table {
  border: none !important;
}

.skillTableContainer tr {
  border: none !important;
}

.tableCell {
  padding: 1rem;
  vertical-align: top;
  border-right: 1px solid #e0e0e0;
  text-align: center;
  font-size: 0.9em;
}

.noBorder {
  border-right: none !important;
  border-bottom: none !important;
}

/* .noBorderRight {
  border-right: none !important;
} */

.tableRow:last-child {
  border-bottom: none;
}

.tableRow:last-child .noBorder {
  border-bottom: none;
}

.noBorder .tableRow {
  border-bottom: none !important;
}

/* .tableCell {
  padding: 1rem;
  vertical-align: top;
} */

/* Footer Styling */
.assessmentFooter {
  margin-top: 5rem;
  text-align: center;
}

.assessmentHead {
  font-weight: bold;
  font-size: 1rem;
}

.description {
  font-size: 1em;
}

.assessmentSubHead {
  color: #666;
  font-size: 0.8rem;
}

.logoImage {
  width: 120px;
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  border-radius: 10px;
}

.reportPresentedBy svg {
  margin-left: 0.2em;
}

.logoProfileImage {
  max-width: 100%;
}

.reportPresentedBy {
  color: var(--qrencia-white);
  font-size: 0.8em;
  font-weight: 400 !important;
  margin-bottom: 0.6em;
  cursor: pointer;
}

.skillAssessmentSection {
  display: grid;
  justify-items: end;
  text-align: right;
}

.shareIconPannelContainer {
  display: grid;
  position: absolute;
  right: 3px;
  top: 7%;
  gap: 5px;
  /* color: var(--qrencia-dark-blue); */
}

.shareIcons,
.shareOnFacebook,
.shareOnLinkedIn,
.shareOnTwitter {
  background-color: var(--qrencia-white);
  border-radius: 20px;
  color: var(--qrencia-dark-blue);
  cursor: pointer;
  font-size: 0.95em;
}

.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  font-size: 0.95em;
  font-weight: bold;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.copyToClipboard,
.shareOnMailButton {
  background-color: var(--qrencia-white);
  color: var(--qrencia-dark-blue);
  cursor: pointer;
  font-size: 0.95em;
}

.preLoaderContainer {
  height: 100vh;
  width: 100%;
  position: relative;
  /* background-color: white;  */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderIcon {
  font-size: 6em !important;
  color: var(--qrencia-dark-blue) !important;
  text-align: center;
}

.spinner {
  animation: spin-animation 0.5s infinite;
}

.loaderText {
  padding: 2em 0 0 0;
  font-size: 1em;
  text-align: center;
}

.qrenciaGlobe {
  width: 80px;
  margin-top: -98px;
}

.preLoaderDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  --b: 8px;
  /* border thickness */
  --n: 12;
  /* number of dashes*/
  --g: 10deg;
  /* gap between dashes*/
  --c: var(--qrencia-dark-blue);
  /* the color */

  width: 120px;
  /* size */
  aspect-ratio: 1;
  border-radius: 100%;
  padding: 1px;
  background: conic-gradient(#0000, var(--c)) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: load 1s infinite steps(var(--n));
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
@keyframes load {
  to {
    transform: rotate(1turn);
  }
}

@media (min-width: 1290px) {
  .skillReportsBtn {
    margin-bottom: 1.5em;
  }

  .skillAssessmentReportTitle {
    padding-top: 0.2em;
  }
}
@media (max-width: 1290px) {
  .skillReportsBtn {
    margin-bottom: 1.5em;
  }
}

@media (max-width: 1145px) {
  .awardTitle {
    font-size: 0.85em;
  }
  .studentDescription {
    font-size: 0.95em;
  }
}

@media (max-width: 1100px) {
  .chartParam2 {
    bottom: -15px;
  }
}

@media (max-width: 1090px) {
  .awardIcon {
    font-size: 2.2em;
  }
  .awardTitle {
    font-size: 0.8em;
  }
  .studentDescription {
    font-size: 0.9em;
  }
}
@media (max-width: 992px) {
  .awardIcon {
    font-size: 2.1em;
  }
  .awardTitle {
    font-size: 0.75em;
  }
  .studentDescription {
    font-size: 0.85em;
  }
  .downloadBtn {
    font-size: 0.8em;
  }
  .shareBtn {
    font-size: 0.8em;
  }
  .studentName {
    font-size: 1.6em;
  }
  .studentAge {
    font-size: 0.9em;
  }
  .reportPresentedBy {
    font-size: 0.75em;
  }
}

@media (max-width: 992px) {
  .skillAssessmentReportTitle {
    font-size: 1.9em;
  }
}

@media (max-width: 900px) {
  .reportPresentedBy {
    font-size: 0.75em;
    margin-bottom: 0.7em;
  }
  .skillAssessmentReportSubTitle {
    font-size: 0.75em;
    margin-bottom: 0.4em;
  }
  .skillAssessmentReportTitle {
    font-size: 1.8em;
  }
  .skillReportsBtn {
    margin-bottom: 2.2em;
  }
  .downloadBtn {
    font-size: 0.7em;
    padding: 0.3em 1em;
  }
  .shareBtn {
    font-size: 0.7em;
    padding: 0.3em 1em;
  }
}

@media (max-width: 835px) {
  .skillAssessmentReportTitle {
    font-size: 1.7em;
  }
}

@media (min-width: 540px) and (max-width: 1290px) {
  .section {
    width: 95%;
  }
}

@media (max-width: 795px) {
  .skillAssessmentReportTitle {
    font-size: 1.6em;
  }
}

@media (max-width: 767px) {
  .chartContainer {
    padding: 5% 1em 2% 1em;
  }
  .currentAssessmentTitle {
    font-size: 1.2em;
  }
  .studentProfileSection {
    display: flex;
    flex-direction: column-reverse;
  }
  .studentDescriptionSection {
    margin-top: 0.5em;
  }

  .studentProfileSection {
    padding: 0.5em 1em 1em 1em;
  }

  .learnerAwardSection {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5.5em;
    /* justify-content: space-evenly; */
  }

  .recommendationHeading {
    font-size: 1em;
  }

  .recommendationText {
    font-size: 0.8em;
  }

  .chartParam2 {
    bottom: -15px;
    font-size: 0.9em;
  }
  .chartParam1 {
    font-size: 0.9em;
  }

  .ssessmentHead {
    font-weight: bold;
    font-size: 0.9rem;
  }

  .description {
    font-size: 0.9em;
  }

  .awardContainers {
    width: 100%;
  }

  .awardSection {
    width: 200px;
    margin-right: 0.5em;
  }
  .awardIcon {
    font-size: 2.2em;
  }
  .studentName {
    font-size: 1.5em;
  }

  .profileImageSection {
    width: 160px;
    height: 150px;
  }

  .skillAssessmentReportTitle {
    font-size: 1.6em;
  }

  .studentProfileIdentity {
    top: -50px;
  }

  .shareIconPannelContainer {
    top: 4%;
  }

  .skillTableHeader {
    font-size: 1em;
  }

  .tableCell {
    font-size: 0.8em;
  }

  .awardTitle {
    font-size: 0.7em;
  }
}

@media (max-width: 752px) {
  .skillAssessmentReportTitle {
    font-size: 1.5em;
  }
}

@media (max-width: 700px) {
  .skillAssessmentReportSubTitle {
    font-size: 0.7em;
  }
  .reportPresentedBy {
    font-size: 0.7em;
  }

  .skillAssessmentReportTitle {
    font-size: 1.4em;
  }
  .logoImage {
    width: 110px;
    height: 90px;
  }
}

@media (max-width: 675px) {
  .skillAssessmentReportTitle {
    font-size: 1.3em;
  }
}

@media (max-width: 675px) {
  .skillAssessmentReportTitle {
    font-size: 1.3em;
  }
}

@media (max-width: 667px) {
  .skillAssessmentReportTitle {
    font-size: 1.3em;
  }
  .skillProgressionSection {
    /* display: flex; */
    justify-content: space-around;
  }
}

@media (max-width: 630px) {
  .profileImageSection {
    width: 160px;
    height: 140px;
  }

  .reportPresentedBy {
    font-size: 0.6em;
  }

  .logoImage {
    width: 110px;
    height: 90px;
  }

  .skillAssessmentReportTitle {
    font-size: 1.2em;
  }

  /* .studentProfileIdentity {
    top: 100px;
  } */
}
@media (max-width: 600px) {
  .chartParam2 {
    bottom: -20px;
    font-size: 0.85em;
  }
  .chartParam1 {
    font-size: 0.85em;
  }
}

@media (max-width: 556px) {
  .chartContainer {
    padding: 7% 0.5em 2% 0.3em;
  }
  .skillTableHeader {
    font-size: 0.85em;
    text-align: left;
  }
  .tableCell {
    font-size: 0.8em;
    text-align: left;
  }
  .assessmentHead {
    font-size: 0.8rem;
  }
  .assessmentSubHead {
    font-size: 0.6rem;
  }
  .SkillAssessmentReportFooter {
    font-size: 0.9em;
  }
  .skillTableContainer {
    margin: 3rem 0 1.6em 0;
  }
  .studentName {
    font-size: 1.3em;
  }

  .studentAge {
    font-size: 0.8em;
  }
  .currentAssessmentTitle {
    font-size: 1.1em;
  }

  .description {
    font-size: 0.8em;
  }

  .loaderText {
    padding: 2em 0 0 0;
    font-size: 0.8em;
    text-align: center;
  }
}

@media (max-width: 540px) {
  .section {
    width: 92%;
  }

  .skillTableHeader {
    text-align: center;
  }

  .assessmentTable thead th:nth-child(2) {
    display: none; /* Hide Remarks header */
  }

  .assessmentTable tbody tr {
    display: block;
    border-bottom: 1px solid #ddd !important;
    padding: 8px 10px;
  }

  .assessmentTable tbody td {
    display: block;
    width: 100% !important; /* Force full width */
    padding: 4px 0;
    border: none;
    word-wrap: break-word !important; /* Break long words */
    overflow-wrap: break-word !important; /* Modern alternative */
    white-space: normal !important; /* Override any nowrap */
  }

  .assessmentTable {
    min-width: 0;
  }

  .assessmentTable th:not(.noBorderRight),
  .assessmentTable td:not(.noBorder) {
    border: none;
  }

  .assessmentTable tbody td:nth-child(2)::before {
    content: 'Remarks: ';
    /* color: #666; */
    font-weight: 600;
    display: inline-block;
    margin-right: 4px;
  }
  .assessmentTable tbody td:nth-child(2) {
    font-size: 0.7em;
  }
  .assessmentTable tbody td:nth-child(1) {
    font-size: 0.8em;
  }
}

@media (max-width: 525px) {
  .awardSection {
    width: 180px;
  }
}

@media (max-width: 515px) {
  .skillAssessmentReportTitle {
    font-size: 1.15em;
  }
}
@media (max-width: 500px) {
  .awardSection {
    width: 170px;
  }

  .skillAssessmentReportSubTitle {
    font-size: 0.6em;
  }

  .skillAssessmentReportTitle {
    font-size: 1.1em;
  }

  .logoImage {
    width: 90px;
    height: 70px;
  }
}

@media (min-width: 447px) and (max-width: 478px) {
  .skillAssessmentReportTitle {
    font-size: 1.3em;
  }
}

@media (max-width: 475px) {
  .SprofileImageSection {
    width: 140px;
    height: 120px;
  }
  .studentName {
    font-size: 1.2em;
  }
  .studentAge {
    font-size: 0.75em;
  }
  .shareBtn {
    font-size: 0.6em;
  }
  .downloadBtn {
    font-size: 0.6em;
  }

  .studentDescription {
    font-size: 0.8em;
  }

  .loaderText {
    padding: 2em 0 0 0;
    font-size: 0.7em;
    text-align: center;
  }

  .studentAboutUs {
    margin-bottom: 1em;
  }

  .tableCell {
    font-size: 0.7em;
  }

  .skillTableHeader {
    font-size: 0.8em;
  }
}

@media (max-width: 460px) {
  /* .skillReportsBtn {
    margin-bottom: -4.2em;
    margin-top: 5.5em;
  } */
  .studentName {
    font-size: 1.1em;
  }
  .profileImageSection {
    width: 130px;
    height: 130px;
  }
  .awardSection {
    width: 165px;
  }

  .studentProfileIdentity {
    top: -45px;
  }

  .shareIconPannelContainer {
    top: 3%;
  }
}

@media (min-width: 437px) and (max-width: 447px) {
  .skillAssessmentReportTitle {
    font-size: 1.2em;
  }

  .studentProfileIdentity {
    top: -35px;
  }
}

@media (max-width: 447px) {
  .shareIconPannelContainer {
    top: 3%;
  }
  .awardSection {
    width: 100%;
  }

  .learnerAwardSection {
    display: block;
  }
  .skillAssessmentReportSubTitle {
    font-size: 0.5em;
  }
  .reportPresentedBy {
    font-size: 0.5em;
  }
  .logoImage {
    width: 85px;
    height: 70px;
  }

  .chartParam2 {
    bottom: -20px;
    font-size: 0.75em;
  }
  .chartParam1 {
    top: 170px;
    font-size: 0.75em;
  }
}

@media (max-width: 437px) {
  .studentProfileIdentity {
    top: -40px;
  }
}

@media (max-width: 426px) {
  .awardSection {
    width: 100%;
  }

  .awardTitle {
    font-size: 0.65em;
  }

  .awardSubTitle {
    font-size: 0.6em;
  }
}
@media (max-width: 400px) {
  .shareIconPannelContainer {
    top: 3%;
  }

  .chartParam2 {
    font-size: 0.65em;
  }
  .chartParam1 {
    font-size: 0.65em;
  }

  .studentAge {
    font-size: 0.65em;
  }

  .studentName {
    font-size: 1em;
  }
  .profileImageSection {
    height: 130px;
    width: 120px;
  }
}

@media (max-width: 360px) {
  .shareBtn {
    font-size: 0.55em;
  }
  .studentName {
    font-size: 0.95em;
  }
}
