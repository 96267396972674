.section {
  background-color: var(--qrencia-white);
  margin: 0.5em 0;
  padding: 0.5em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-cards-border);
}
.secitonHeading {
  font-size: 1.2em;
  font-weight: 600 !important;
  margin-bottom: 0.7em;
  color: var(--qrencia-grey-text-color);
}
.promotionVideoSection {
  flex: 4.5;
  position: relative;
}
.videoContent {
  width: 100%;
  border-radius: 12px;
  background-color: var(--qrencia-text-black);
  aspect-ratio: 3/2.1;
}
.videoPopupSection {
  width: 100%;
  padding: 0;
  display: grid;
  place-items: center;
  aspect-ratio: 3/2.1;
}

.videoPlayIcon {
  width: 3em;
  position: absolute;
  cursor: pointer;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.thumbnailImage {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.videoThumbnailImage {
  cursor: pointer;
  width: 95%;
  border-radius: 12px;
}

.importantLinksSection {
  margin: 0.5em 0;
  margin-top: 1.3em;
  padding: 1em 1em;
  border-radius: 10px;
  background-color: var(--qrencia-white);
  border: 1px solid var(--qrencia-cards-border);
}
.linkSectionHeading {
  font-size: 1.2em;
  font-weight: 600 !important;
  color: var(--qrencia-grey-text-color);
  margin-top: 1.1em;
  margin-bottom: -0.2em;
}
.importantLinksHeading {
  font-size: 1.2em;
  font-weight: 600 !important;
  color: var(--qrencia-grey-text-color);
  margin-bottom: 1.2em;
}
.links {
  margin-top: 0.4em;
  display: flex;
  flex-direction: column;
}
.links > a {
  margin-bottom: 1em;
}
.linkText {
  font-size: 0.9em;
  margin-bottom: 1em;
  margin-right: 0.5em;
  color: var(--qrencia-card-blue);
  cursor: pointer;
  text-decoration: none;
}
.linkText > svg {
  color: var(--qrencia-card-blue);
  margin-right: 0.4em;
}
@media (max-width: 990px) {
  .secitonHeading {
    font-size: 1.1em;
    margin-top: 1em;
  }
}
@media (max-width: 768px) {
  .section {
    margin: 1em 0;
  }
  .secitonHeading {
    font-size: 1em;
    margin-top: 1.4em;
  }
}
@media (max-width: 540px) {
  .secitonHeading {
    font-size: 0.9em;
  }
  .importantLinksHeading {
    font-size: 0.9em;
  }
  .linkText {
    font-size: 0.8em;
    margin-bottom: 0.6em;
    margin-right: 0.5em;
    /* color: var(--qrencia-card-blue);
    cursor: pointer;
    text-decoration: none; */
  }
}
