.webImage {
  display: block;
}

.tabletImage {
  display: none;
}

.mobileImage {
  display: none;
}

.epBannerImage {
  width: 100%;
  aspect-ratio: 2432 / 789;
}

.epBannerItem {
  aspect-ratio: 2432 / 789;
}

.epBannerText {
  text-align: left;
  left: 9%;
  width: 45%;
  color: var(--qrencia-text-black);
  top: 11rem;
  position: absolute;
}

.epBannerText h3 {
  font-size: 2.9em;
  font-weight: 600;
}

.subHeadingText {
  font-size: 1.4em;
  margin-top: 4%;
}

@media (max-width: 1290px) {
  .epBannerText {
    width: 48%;
    top: 10rem;
  }
}

@media (max-width: 990px) {
  .epBannerText h3 {
    font-size: 2.3em;
  }

  .epBannerText {
    width: 50%;
  }

  .subHeadingText {
    font-size: 1.4em;
  }
  .epBannerText {
    text-align: left;
    left: 9%;
    top: 8em;
  }
}

@media (max-width: 680px) {
  .epBannerText h3 {
    font-size: 2.2em;
  }

  .epBannerText {
    width: 90%;
  }

  .subHeadingText {
    font-size: 1.3em;
  }
  .epBannerText {
    text-align: left;
    left: 5%;
    top: 6em;
  }
}

@media (max-width: 550px) {
  .epBannerText h3 {
    font-size: 1.8em;
  }

  .epBannerText {
    width: 90%;
  }

  .subHeadingText {
    font-size: 1.1em;
  }
  .epBannerText {
    text-align: left;
    left: 5%;
    top: 5em;
  }
}

@media (min-width: 480px) and (max-width: 990px) {
  .webImage {
    display: none;
  }

  .tabletImage {
    display: block;
  }

  .mobileImage {
    display: none;
  }

  .epBannerImage {
    width: 100%;
    aspect-ratio: 1229 / 760;
  }

  .epBannerItem {
    aspect-ratio: 1229 / 760;
  }
}

@media (min-width: 1023px) and (max-width: 1025px) {
  .webImage {
    display: none;
  }

  .tabletImage {
    display: block;
  }

  .mobileImage {
    display: none;
  }

  .epBannerText {
    left: 5%;
    top: 9rem;
  }
}

@media (max-width: 480px) and (max-height: 800px) {
  /* .epBannerImage {
    height: 78vh !important;
  } */

  .epBannerText {
    left: 0%;
    padding: 2% 4.5% 4.5% 4.5%;
    width: 100% !important;
    top: 4.5rem;
    text-align: center;
  }

  .epBannerText h3 {
    font-size: 1.45em !important;
  }

  .subHeadingText {
    font-size: 1em;
    margin-top: 4%;
  }
}

@media (min-height: 800px) and (max-width: 480px) {
  .epBannerText {
    left: 0%;
    padding: 4.5% 4.5% 4.5% 4.5%;
    width: 100% !important;
    top: 4.5rem;
    text-align: center;
  }

  .epBannerText h3 {
    font-size: 1.5em !important;
  }

  .subHeadingText {
    font-size: 1em;
    margin-top: 4%;
  }
}

@media (min-width: 820px) and (min-height: 1179px) and (max-width: 821px) {
  .epBannerImage {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .webImage {
    display: none;
  }

  .mobileImage {
    display: block;
  }

  .tabletImage {
    display: none;
  }
  .epBannerImage {
    width: 100%;
    aspect-ratio: 142 / 117;
  }
  .epBannerItem {
    width: 100%;
    aspect-ratio: 142 / 117;
  }

  .epBannerText {
    left: 0%;
    width: 100% !important;
    top: 4.5rem;
    padding: 4.5% 10% 4.5% 4.5%;
    text-align: center;
  }

  .epBannerText h3 {
    font-size: 1.75em !important;
  }

  .subHeadingText {
    font-size: 0.9em;
    margin-top: 4%;
  }
}

@media (max-width: 480px) {
  .epBannerText {
    left: 0%;
    padding: 4.5% 4.5% 4.5% 4.5%;
    width: 100% !important;
    top: 4.5rem;
    text-align: center;
  }

  .epBannerText h3 {
    font-size: 1.2em !important;
  }

  .subHeadingText {
    font-size: 0.75em;
    margin-top: 4%;
  }
}
@media (max-width: 1290px) {
  .epBannerText h3 {
    font-size: 2.7em;
  }
}

@media (max-width: 1076px) {
  .epBannerText h3 {
    font-size: 2.2em;
  }
}

@media (max-width: 990px) {
  .epBannerText h3 {
    font-size: 2em;
  }
}

@media (max-width: 876px) {
  .epBannerText h3 {
    font-size: 2.2em;
  }
}

@media (min-width: 912px) and (min-height: 1368px) and (max-width: 913px) {
  .epBannerImage {
    width: 100%;
  }
}

@media (min-width: 768px) and (min-height: 1024px) and (max-width: 769px) {
  .epBannerImage {
    width: 100%;
  }
}

@media (min-width: 990px) and (max-width: 1200px) and (min-height: 600px) {
  .epBannerImage {
    width: 100%;
  }
}

@media (min-width: 1023px) and (max-width: 1025px) and (min-height: 600px) and (max-height: 602px) {
  .epBannerImage {
    width: 100%;
  }
}

@media (min-width: 1023px) and (min-height: 1365px) and (max-width: 1025px) and (max-height: 1367px) {
  .epBannerImage {
    aspect-ratio: 1229 / 760;
  }
}

@media (min-width: 1155px) and (max-width: 1275px) {
  .epBannerText h3 {
    font-size: 2.5em;
  }
}

@media (min-width: 1076px) and (max-width: 1155px) {
  .epBannerText h3 {
    font-size: 2.3em;
  }
}

@media (min-width: 767px) and (max-width: 878px) {
  .epBannerText h3 {
    font-size: 1.7em;
  }

  .subHeadingText {
    font-size: 0.9em;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .epBannerText h3 {
    font-size: 1.5em;
  }

  .subHeadingText {
    font-size: 0.85em;
  }
}
