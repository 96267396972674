.expertCard {
  margin-left: -1%;
  margin-right: -1%;
  display: flex;
  justify-content: flex-start;
}

.expertCardSection {
  margin-bottom: 2%;
}

.expertCardBody {
  padding: 3% 3% 1% 3%;
  min-height: 302px;
  border-radius: 25px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid var(--qrencia-activity-cards-border);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
  cursor: pointer;
}

.expertCardContainer {
  text-align: center;
}
.expertLink {
  text-decoration: none;
}
.cardTitle {
  font-weight: 600 !important;
  font-size: 0.9em;
  height: 2.3em;
}
.expertCardImage {
  border-radius: 80px;
  height: 125px;
  width: 125px;
  object-fit: cover;
}

.expertMicrositeLinkContainer {
  display: flex;
  margin-bottom: 4%;
}

.expertShareIcon {
  text-align: right;
  width: 50%;
  font-size: 1.5em;
  margin-top: -9px;
}

.topExpertContainer {
  text-align: left;
  width: 50%;
  position: absolute;
}

.topExpertFlagSection {
  background-color: var(--background-light-blue);
  display: inline-flex;
  padding: 2% 4%;
  border-radius: 50px;
  border: 1px solid var(--border-blue-color);
}
.followUnfollowButton {
  margin-top: 8px;
  font-size: 0.7em;
  padding: 2px 14px;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
}
.following {
  color: var(--qrencia-white);
  background-color: var(--qrencia-dark-blue);
}

.follow {
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-white);
  border: 1px solid var(--qrencia-dark-blue);
  font-weight: 500;
}
.expertBadgeIcon {
  color: var(--qrencia-dark-blue);
  margin-right: 0.2em;
  font-size: 0.8em;
}

.expertBadgeText {
  font-size: 0.6em;
  font-weight: 500 !important;
  margin-top: 0.5%;
}

.expertShareIcon,
.expertShareIcon:hover,
.expertShareIcon:visited {
  color: var(--border-blue-color) !important;
  cursor: pointer;
}

.expertNameContainer {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.expertCardTitle {
  color: var(--qrencia-dark-blue);
  text-align: center;
  font-weight: 700;
  font-size: 1.2em;
}

.expertCardSubTitle {
  color: var(--qrencia-grey-text);
  font-weight: 500;
  text-align: center;
  font-size: 0.82em;
  margin-top: -1%;
}

.expertCountryFlag {
  width: 12%;
  border: 1px solid var(--qrencia-white);
}
.expertCardImageBorder {
  border: 1px solid var(--qrencia-dark-blue);
}
.academyCardImageBorder {
  border: 1px solid var(--qrencia-academy-card-top-border);
}

.expertFlagSection {
  margin-top: -4%;
}

.expertShareIconLink,
.expertShareIconLink:hover {
  color: var(--border-blue-color);
}

.loadMoreButton {
  text-align: end;
}

.loadMoreIcon {
  font-size: 1.5em;
  cursor: pointer;
}
.courseActivityCard {
  margin-left: -1%;
  margin-right: -1%;
  display: flex;
  justify-content: flex-start;
}
.courseActivityCardImage {
  /* height: 160px; */
  aspect-ratio: 825/550;
  object-fit: cover;
  z-index: 100;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.courseActivityOrganizer {
  display: flex;
  margin-top: 0.4em;
  font-weight: 500 !important;
  font-size: 0.7em !important;
  color: var(--qrencia-text-black) !important;
}
.courseActivityOrganizerLink {
  display: flex;
  font-weight: 500 !important;
  color: var(--qrencia-text-black) !important;
  text-decoration: none;
}
.courseActivityClasses {
  display: flex;
  font-size: 0.68em;
  margin-top: 0.7em;
  font-weight: 500;
}
.exploreMoreButton {
  margin: 0 auto;
  font-size: 0.7em;
  text-align: center;
}
.courseActivityCardsEnrolSection {
  display: flex;
  font-size: 0.68em;
  margin-top: 2%;
  font-weight: 500;
}
.courseActivityCardMode {
  width: 50%;
  margin-top: 0.2em;
}
.courseActivityCardButton {
  padding: 1% 10%;
}
.courseActivityCardModeIcon {
  margin-top: -0.2em;
  color: var(--qrencia-grey-text);
}
.courseActivityCardEnrol {
  width: 50%;
  text-align: right;
  font-size: 1.18em;
}
.courseActivityCardInfoIcon {
  margin-top: 0.2em;
  color: var(--qrencia-grey-text);
}
.courseActivityCardTooltip {
  font-size: 1em !important;
}
.courseActivityCardBody {
  padding: 0% 3% 3% 3%;
}
.courseActivityCostSection {
  background-color: var(--qrencia-cost-background);
  color: var(--qrencia-text-black);
  display: inline;
  padding: 2.5% 7%;
  font-size: 0.8em;
  border-radius: 0px 12px 0px 0px;
  top: 0em;
  position: absolute;
  z-index: 110;
  right: 0em;
}

.costInfo {
  background-color: var(--qrencia-cost-background);
  border-radius: 14px;
  width: max-content;
  max-width: 12.5em;
  font-size: 1em;
  padding: 0.36em 0.6em;
  text-align: center;
}
.costInfo > svg {
  margin-top: -2px;
}
.courseActivityCardTitle {
  font-weight: 700;
  font-size: 0.95em;
  color: var(--qrencia-text-black);
}
.courseActivityCardTitleLink,
.courseActivityCardTitleLink:hover,
.courseActivityCardTitleLink:focus,
.courseActivityCardTitleLink.active {
  color: var(--qrencia-text-black);
  text-decoration: none;
}
.courseActivityMainCard {
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid var(--qrencia-activity-cards-border);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
  cursor: pointer;
  position: relative;
}
.courseActivityCardSection {
  margin-bottom: 2%;
}
.shareIconUpcomingCards {
  font-size: 1.2em;
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  border-radius: 20px;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 2px;
  z-index: 110;
}
.shareIconPannelContainerUpcomingCards {
  display: grid;
  position: absolute;
  right: -1.15em;
  top: 15px;
  gap: 5px;
}
.shareOnLinkedIn {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}
.shareOnFacebook {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnTwitter {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareIcons {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnMailButton {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  cursor: pointer;
}

.copyToClipboard {
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  font-size: 0.95em;
  font-weight: bold;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

@media (min-width: 1291px) {
  .expertCardSection {
    margin-bottom: 2%;
  }
  .courseActivityCardSection {
    margin-bottom: 2%;
  }
}
@media (min-width: 991px) and (max-width: 1290px) {
  .expertShareIcon {
    font-size: 1.2em;
    margin-top: -2%;
    margin-right: 2%;
  }

  .expertCardImage {
    height: 110px;
    width: 110px;
  }

  .expertCardTitle {
    font-size: 1.1em;
  }

  .expertCardSubTitle {
    font-size: 0.8em;
  }

  .expertCountryFlag {
    width: 11%;
  }
}

@media (min-width: 768px) and (max-width: 990.5px) {
  .expertShareIcon {
    font-size: 1.35em;
  }

  .expertCardImage {
    height: 110px;
    width: 110px;
  }

  .expertCardTitle {
    font-size: 1.1em;
  }

  .expertCardSubTitle {
    font-size: 0.8em;
  }

  .expertCountryFlag {
    width: 11%;
  }
}

@media (max-width: 767.5px) {
  .expertCardSection {
    margin-bottom: 8%;
  }
  .courseActivityCardSection {
    margin-bottom: 8%;
  }

  .expertShareIcon {
    font-size: 1.2em;
    margin-top: -2%;
    margin-right: 1%;
  }

  .expertCardImage {
    height: 110px;
    width: 110px;
  }

  .expertCardTitle {
    font-size: 1.1em;
  }

  .expertCardSubTitle {
    font-size: 0.8em;
  }

  .expertCountryFlag {
    width: 11%;
  }
}

@media (max-width: 481px) {
  .expertCardTitle {
    font-size: 1em;
  }

  .expertCardSubTitle {
    font-size: 0.8em;
  }
}

@media (min-width: 481px) and (max-width: 767.5px) {
  .expertCountryFlag {
    width: 8%;
  }
}
@media (max-width: 580px) {
  .courseActivityClasses {
      font-size: 0.65em;
  }
  .courseActivityOrganizer{
    font-size: 0.65em;
  }
}
@media (max-width: 400px) {
  .courseActivityClasses {
      font-size: 0.55em !important;
  }
  .courseActivityOrganizer{
    font-size: 0.55em !important;
  }
}

