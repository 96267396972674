.shareIconPannelContainerParent {
  position: relative;
}

.agGridMobile {
  display: none;
}

.agGridDesktop {
  display: block;
}

.liveDot {
  font-size: 2em;
  color: var(--qrencia-success-green) !important;
  margin-top: -3px;
  margin-left: 0px;
}

.detailsHeading {
  font-size: .95em !important;
  font-weight: 500 !important;
}

.detailsSubHeading {
  font-size: 0.9em !important;
  font-weight: 400 !important;
  padding-bottom: 7px;
  overflow-wrap: anywhere;
}

.shareIconPannelContainer {
  display: flex;
  right: 10%;
  top: -2em;
  gap: 5px;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  width: max-content;
}

.downloadStatementBtn {
  padding: 0em 2em;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  z-index: 10;
  max-width: 32em;
}

.medalHeading {
  font-size: 1em;
  font-weight: 500 !important;
  padding: 0 0 1em 0;
}

.shareIcon {
  font-size: 1.6em !important;
  background-color: var(--qrencia-card-blue);
  color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
  right: 15px;
  top: 6em;
  padding: 2px;
  margin: 0 0 5px 0.5em;
}

.downloadBtn {
  width: fit-content;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.participantBtn {
  padding: 0.4em 1em;
  margin-top: 1em;
  margin-right: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.shareOnLinkedIn {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1.5em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnFacebook {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1.5em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnTwitter {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1.5em;
  border-radius: 20px;
  cursor: pointer;
}

.copyCodeIcon:active {
  border: none;
  outline: none;
}

.copyCodeIcon svg:active {
  border: none;
  outline: none;
}

.shareOnInstagramButton {
  background: -moz-linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: -webkit-linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  color: var(--qrencia-white);
  font-size: 1.5em;
  cursor: pointer;
}

.shareOnMailButton {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 1.5em;
  cursor: pointer;
}

.copyToClipboard {
  color: var(--qrencia-card-blue);
  font-size: 1.5em;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.copyScoreboardToClipboard {
  color: var(--qrencia-card-blue);
  font-size: 1.1em;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  font-size: 1.5em;
  font-weight: bold;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.showScoreboardLinkCopiedIcon {
  color: var(--qrencia-success-green);
  font-size: 1.1em;
  font-weight: bold;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.modalYesButton,
.modalYesButton:hover,
.modalYesButton:active,
.modalYesButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--qrencia-success-green) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalNoButton,
.modalNoButton:hover,
.modalNoButton:active,
.modalNoButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--cancel-red-button) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalCloseButton,
.modalCloseButton:hover,
.modalCloseButton:active,
.modalCloseButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalCloseButton,
.modalCloseButton:hover,
.modalCloseButton:active,
.modalCloseButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalAlertIcon {
  font-size: 3em;
  color: var(--cancel-red-button);
  text-align: center;
}

.modalSuccessIcon {
  font-size: 3em;
  color: var(--qrencia-success-green);
  text-align: center;
}

.modalErrorIcon {
  font-size: 3em;
  color: var(--qrencia-error-red);
  text-align: center;
}

.modalAlertText {
  font-size: 0.9em;
  text-align: center;
}

.modalBody {
  padding: 1.5em 1em 1em;
}

.modalFooter {
  display: flex;
  justify-content: center;
  padding: 0.5em 1em 1.5em 1em;
  border: none;
  gap: 1em;
}

.disabledButton {
  cursor: not-allowed !important;
}

.judgesCountCntSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}

.judgesCard {
  max-width: 100%;
  display: flex;
  padding: 0.5em 1em;
  margin: 1em auto;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--qrencia-grey-border);
}

.judgeUniqueId {
  background-color: #cccccc;
  padding: 2px 6px;
  border-radius: 6px;
  margin-top: -1.5em;
  font-weight: 600 !important;
}

.judgeName {
  text-align: center;
  padding: 0.5em 0;
  font-weight: 500 !important;
}

.judgeEnableButton,
.judgeEnableButton:hover,
.judgeEnableButton:active,
.judgeEnableButton:focus {
  font-size: 0.9em;
  background-color: var(--qrencia-success-green) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px 8px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.judgeDisableButton,
.judgeDisableButton:hover,
.judgeDisableButton:active,
.judgeDisableButton:focus {
  font-size: 0.9em;
  background-color: var(--cancel-red-button) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px 8px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.sendLinkButton {
  font-size: 0.9em;
  border: none;
  padding: 4px 8px;
  border-radius: 3px;
  margin-right: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.noJudgesErrorMessage {
  padding: 3em 1em;
  margin: 1em 0;
  text-align: center;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  color: var(--qrencia-grey-text);
  font-style: italic;
  font-weight: 400 !important;
  border: 1px solid var(--qrencia-cards-border);
  font-size: 0.75em !important;
}

.participantDetailsTable {
  overflow: scroll;
  margin-bottom: 2%;
}

.judgingEnabledWarningMessage {
  margin: 1em 0;
  background-color: var(--qrencia-extended-page-fun-fact-background);
  border-radius: 6px;
  border: 1px solid var(--instagram-yellow);
  width: max-content;
}

.judgingEnabledWarningMessage svg {
  font-size: 1.5em !important;
  padding: 0;
  color: var(--instagram-yellow);
}

.judgingEnabledWarningMessage p {
  font-size: 0.75em !important;
  font-weight: 500 !important;
  color: var(--instagram-yellow);
  padding: 0.5em;
}

.errorSuccessContainer {
  font-size: 1.1em !important;
}

.tableLabelColumn {
  width: 35% !important;
  font-weight: 500;
}

.tableDescriptionColumn {
  width: 65% !important;
}

.tableHeaderOne {
  min-width: 7em !important;
  font-weight: 500;
}

.tableHeaderEnrolledDate {
  min-width: 12em !important;
  font-weight: 500;
}

.tableHeaderTwo {
  width: 50% !important;
  font-weight: 500;
}

.tableDescriptionEnrolledDate {
  min-width: 12em !important;
}

.tableDescriptionColumnOne {
  min-width: 7em !important;
}

.tableDescriptionColumnTwo {
  width: 65% !important;
}

.guidelinesText h1 {
  font-size: 2em;
  font-weight: 600;
}

.guidelinesText h2 {
  font-size: 1.5em;
  font-weight: 600;
}

.guidelinesText h3 {
  font-size: 1.17em;
  font-weight: 600;
}

.guidelinesText h4 {
  font-size: 1em;
  font-weight: 600;
}

.guidelinesText h5 {
  font-size: 0.83em;
  font-weight: 600;
}

.guidelinesText h6 {
  font-size: 0.67em;
  font-weight: 600;
}

.teamTableContainer {
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.teamTableContainer::-webkit-scrollbar {
  width: 0;
}

.teamNameHeadings {
  font-size: 1.2em;
  color: var(--qrencia-dark-blue);
  margin-top: 1.5em;
  font-weight: 600;
}

.teamTableContainer .tableHead th,
.teamTableContainer .tableBody td {
  width: 16.66%;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

@media (max-width: 990px) {
  .guidelinesText p {
    font-size: 1em;
  }
}

@media (max-width: 580px) {
  .noJudgesErrorMessage {
    font-size: 0.62em !important;
  }

  .judgesCard {
    max-width: 90%;
  }

  .errorSuccessContainer {
    font-size: 1.2em !important;
  }

  .judgingEnabledWarningMessage svg {
    font-size: 1.2em !important;
  }

  .judgingEnabledWarningMessage p {
    font-size: 0.65em !important;
  }

  .judgesCountCntSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
    flex-direction: column;
    gap: 1em;
  }

  .judgesCountText {
    font-size: 1em !important;
  }

  .downloadBtn {
    padding-left: 0.7em;
    padding-right: 0.7em;
  }
}

@media (max-width: 480px) {
  .paymentTable {
    font-size: 0.7em;
  }

  .tableHeaderOne {
    font-size: 0.85em;
  }

  .tableHeaderTwo {
    font-size: 0.85em;
  }

  .tableHeaderThree {
    font-size: 0.85em;
  }

  .tableHeaderFour {
    font-size: 0.85em;
  }

  .questions {
    font-weight: 500;
    font-size: 0.8em;
  }

  .answers {
    font-size: 0.75em;
  }

  .tableHeaderEnrolledDate {
    font-size: 0.85em;
  }
}

@media (max-width: 1500px) {
  .agGridMobile {
    display: block;
  }

  .agGridDesktop {
    display: none;
  }
}