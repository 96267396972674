.formContainer {
    background-color: white;
    border-radius: 2%;
    padding: 1rem;
    margin-bottom: 2rem;
}

.totalMarksHeading {
    font-size: 0.9em;
    font-weight: 500 !important;
}

.skillProgressFormField {
    font-size: 0.8rem;
}

.addParametersBtn {
    width: max-content;
    border-radius: 0.2rem;
    color: var(--qrencia-dark-blue);
    background-color: var(--qrencia-white);
    font-size: 0.8rem;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.deleteButton {
    padding-top: 0.6em;
    font-size: 1.3em;
}

.deleteParamButton {
    background-color: var(--qrencia-white) !important;
    border: none;
    color: var(--qrencia-error-red);
}

.deleteParamButton:hover,
.deleteParamButton:active,
.deleteParamButton:focus {
    background-color: var(--qrencia-white) !important;
    border: none !important;
    color: var(--qrencia-error-red) !important;
    box-shadow: none !important;
    /* Prevents any shadow effects */
    outline: none !important;
    /* Removes focus outline */
}

.buttonSection {
    text-align: end;
}

.saveTemplateBtn {
    border-radius: 0.2rem;
    color: var(--qrencia-white);
    background-color: var(--qrencia-dark-blue);
    font-size: 0.8rem;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.inputBoxError {
    border: 0.1em solid var(--red) !important;
    transition: border 0.3s ease-in-out !important;
}

.skillProgressErrorText {
    font-size: 0.75em;
    color: var(--qrencia-error-red);
}

@media (max-width: 768px) {

    .totalMarksHeading {
        font-size: 0.8em;
        font-weight: 500 !important;
    }

    .addParametersBtn {
        font-size: 0.7rem;
    }

    .saveTemplateBtn {
        font-size: 0.7rem;
    }

    .skillProgressFormField {
        font-size: 0.7rem;
    }

    .skillProgressErrorText {
        font-size: 0.55em;
        color: var(--qrencia-error-red);
    }
}