.headingText {
  font-size: 0.8em;
  margin-top: 1em;
  font-weight: 500;
}
.formLabels {
  margin-top: 0.8em;
  font-weight: 550;
  font-size: 0.9em;
}

.addButton {
  margin-top: 1em;
  width: 7em;
  height: 2.5em;
  font-size: 0.8em;
}
.errorText {
  font-size: 0.65em;
  font-weight: 400;
  color: var(--qrencia-error-red);
  margin-top: -1em;
}
.warningMessage {
  margin: 1em 0;
  background-color: var(--qrencia-extended-page-fun-fact-background);
  border-radius: 6px;
  border: 1px solid var(--instagram-yellow);
  width: max-content;
}

.warningMessage svg {
  font-size: 1.5em !important;
  padding: 0;
  color: var(--instagram-yellow);
}

.warningMessage p {
  font-size: 0.75em !important;
  font-weight: 500 !important;
  color: var(--instagram-yellow);
  padding: 0.5em;
}
@media (max-width: 780px) {
  .headingText {
    font-size: 1em;
  }
  .formLabels {
    font-size: 0.75em;
  }
}

@media (max-width: 580px) {
  .headingText {
    font-size: 0.72em;
    margin-top: 1em;
    font-weight: 550;
  }
  .formLabels {
    font-size: 0.7em;
  }
  .addButton {
    margin-top: 1em;
    width: 7em;
    height: 2.2em;
    margin-bottom: 1em;
  }
  .addButton > Button {
    font-size: 0.9em;
    padding: 0.6em 2em;
  }
  .warningMessage svg {
    font-size: 1.2em !important;
  }

  .warningMessage p {
    font-size: 0.65em !important;
  }
}
