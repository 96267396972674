.courseLayout {
  margin: 1em 7% 2em 7%;
}
.competitionAdsSection {
  display: block;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  padding: 1em;
  margin: 1em 0 1em 0;
  border: 1px solid var(--qrencia-light-grey-border);
}
.competitionAdImage {
  width: 100%;
  border-radius: 10px;
}
.shareIconPannelContainer {
  color: var(--qrencia-white);
  display: grid;
  position: fixed;
  padding: 1% 0.8% 0.5% 0.7%;
  top: 50%;
  right: 0%;
  background: var(--qrencia-dark-blue);
  border-top: 2px solid var(--qrencia-white);
  border-bottom: 2px solid var(--qrencia-white);
  border-left: 2px solid var(--qrencia-white);
  border-radius: 10px 0px 0px 10px;
  z-index: 1000;
}
.thumbnailExpertName {
  cursor: pointer;
}
.workshopExpertName {
  cursor: pointer;
}
.shareIcons {
  margin-bottom: 32%;
  border-radius: 20px;
  cursor: pointer;
  width: 23px;
  height: 23px;
}
.sessionLinkFlexibleCourse {
  margin-top: 6px;
}

.warningMessage p {
  color: var(--instagram-yellow) !important;
}
.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  padding: 10%;
  width: 23px;
  height: 23px;
  margin-bottom: 32%;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.errorContainer {
  padding: 2em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-grey-border);
  width: 80%;
  margin: 1em auto;
  text-align: center;
  background-color: var(--qrencia-white);
}

.loaderIcon {
  font-size: 3em !important;
  color: var(--qrencia-dark-blue) !important;
}

.thumbnailContainer {
  display: flex;
  justify-content: space-between;
  background-color: var(--qrencia-white);
  border: 1px solid var(--qrencia-light-grey-border);
  border-radius: 10px;
  margin: 0.5em 0 0 0;
  width: 100%;
  padding: 1em;
}

.enrolButton {
  font-size: 0.8em;
  font-weight: 450;
  height: 2.5em;
  width: 12em !important;
}

.sessionLinkButton {
  padding-top: 5px !important;
}

.askQuestionButton {
  font-size: 0.8em;
  font-weight: 450;
  outline: none;
  border: none;
  background-color: var(--qrencia-course-skill-name-color);
  border-radius: 3px;
  color: var(--qrencia-text-black);
  height: 2.5em;
  width: 12em !important;
  margin-bottom: 0.5em;
}

.askButtonBelow {
  margin-top: 5em !important;
  margin-right: 0.5em;
  display: none;
  font-size: 0.9em;
  outline: none;
  border: none;
  background-color: var(--qrencia-dark-blue);
  border-radius: 5px;
  color: var(--qrencia-white);
}

.leftCourseDetails {
  display: flex;
  width: 100%;
}

.rightSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.titleContainer {
  display: flex;
  flex-wrap: wrap;
}

.leftCourseDetails > img {
  width: 16em;
}

.courseThumbnailImage {
  height: 10em;
  aspect-ratio: 825/550;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.donwloadPreRead {
  color: var(--qrencia-link-color);
  cursor: pointer;
  font-size: 0.75em !important;
  text-decoration: underline;
}

.leftThumbnailDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 1em;
  gap: 2px;
  width: 80%;
}

.courseTitle {
  font-size: 1.1em;
  font-weight: 600 !important;
  margin-right: 0.8em;
  /* flex-grow: 1; */
}

.courseDuration {
  font-size: 0.9em;
  display: flex;
  font-weight: 600 !important;
  margin: 3px 0 0px 1px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}
.workshopInfo {
  font-size: 0.85em;
  display: flex;
  font-weight: 600 !important;
  margin: 3px 0 2px 1px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.courseDate {
  font-size: 0.9em !important;
  font-weight: 500 !important;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  color: var(--qrencia-text-black);
}

.workshopDate {
  font-size: 0.9em !important;
  font-weight: 500 !important;
  align-items: flex-start;
  display: flex;
  color: var(--qrencia-text-black);
}
.workshopSkillIcon {
  flex-wrap: wrap;
}
.divText {
  font-size: 0.9em;
}

.courseDate > svg {
  margin-right: 0.55em;
}
.workshopDate > svg {
  margin-right: 0.55em;
  margin-top: 0.3em;
}

.skillName {
  font-size: 0.9em;
  font-weight: 500 !important;
  margin-left: 1em;
  background-color: var(--qrencia-course-skill-name-color);
  border-radius: 20px;
  padding: 2px 10px 2px 7px;
}

.costInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 1em;
  width: max-content;
  font-weight: 500 !important;
}

.costInfo > svg {
  margin-right: 0.3em;
}

.courseTimings {
  font-size: 1em !important;
  font-weight: 500 !important;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  color: var(--qrencia-dark-blue);
  cursor: pointer;
}

.section {
  padding: 1em;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  margin: 1em 0 0 0;
  border: 1px solid var(--qrencia-light-grey-border);
}

.descriptionHeading {
  font-size: 1.1em;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.descriptionBody {
  font-size: 0.85em;
}
.videoReviewSection {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  margin: 1em 0 0 0;
  border: 1px solid var(--qrencia-light-grey-border);
  gap: 2em;
  height: 20% !important;
}

.promotionVideoSection {
  flex: 4.5;
  position: relative;
}

.videoContent {
  width: 100%;
  border-radius: 1rem;
  background-color: var(--qrencia-text-black);
  aspect-ratio: 3/2.1;
}

.videoContentThumnail {
  width: 100%;
  border-radius: 1rem;
  background-color: var(--qrencia-text-black);
  aspect-ratio: 3/2.125;
}

.videoIcon {
  top: 15vw;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12%;
  position: absolute;
  cursor: pointer;
}

.closeButton {
  top: 1%;
  right: 1%;
  width: 8%;
  position: absolute;
  cursor: pointer;
  font-size: 1.5em;
  color: var(--qrencia-white);
}

.reviewSection {
  flex: 5.5;
  width: 80%;
}

.reviewCards {
  overflow-y: scroll;
  min-height: fit-content;
  min-height: -webkit-min-content;
  max-height: 20em;
  text-align: justify;
}

.reviewCards::-webkit-scrollbar {
  display: none;
}

.reviewHeading {
  font-size: 1em;
  font-weight: 500;
}

.reviewInput {
  display: flex;
  background-color: var(--qrencia-white);
  transition: 0.5s all ease-in-out;
  border: 1px solid var(--qrencia-certificate-preview-border);
  border-radius: 5px;
  align-items: center;
  margin-top: 10px;
}

.reviewInput > textarea {
  outline: none;
  border: none;
  height: 5em !important;
  resize: none;
  background-color: var(--qrencia-white);
}

.inputTextBox:focus,
.inputTextBox:active {
  outline: none !important;
  border: none !important;
  background-color: var(--qrencia-white) !important;
}

.reviewInput > svg {
  font-size: 1.5em;
  margin-left: 1em;
  margin-right: 0.5em;
  cursor: pointer;
}

.costText {
  margin-top: 0.8%;
  color: var(--qrencia-text-black) !important;
}

.faqHeading {
  font-size: 1.1em;
  font-weight: 600;
}

.modalBtn {
  font-size: 0.8em;
  padding: 0.5em 0.8em;
  margin-right: 0.5em;
  margin-top: 1em;
}

.modalSuccessIcon {
  font-size: 3em;
  color: var(--qrencia-success-green);
  text-align: center;
}

.noReviewText {
  font-size: 0.8em;
}

.reviewErrorMessage {
  font-size: 0.8em;
  color: var(--qrencia-error-red);
}

.onlineText {
  font-weight: 500 !important;
}

.displayInline {
  align-items: center;
  display: flex;
  margin-bottom: 0.4em;
}

.displayInline > svg {
  margin-right: 0.45em;
}

.displayInline > p {
  font-size: 0.9em;
}

.offlineAddress {
  font-size: 0.9em;
  align-items: baseline;
  display: flex;
}

.offlineAddressIcon {
  margin-right: 0.4em;
}

.costTextContainer {
  display: inline-flex;
  /* font-size: 0.9em; */
}

.costRow {
  text-decoration: line-through;
  margin-right: 0.2em;
  color: var(--qrencia-faq-icon-color);
}

.weekDaysContainer {
  display: flex;
  border-radius: 6px;
  border: 1.5px solid var(--qrencia-dark-blue);
  width: fit-content;
}

.weekDaysContainer span {
  font-size: 0.7em !important;
  font-weight: 500 !important;
  padding: 1px 2px;
}

.weekActive {
  color: var(--qrencia-white) !important;
  background-color: var(--qrencia-dark-blue);
}

.spocDetailsContainer {
  display: flex;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
}

.onlineLink {
  color: var(--qrencia-text-black);
  text-decoration: none;
}

.onlineLink:hover {
  color: var(--qrencia-text-black);
}

@media (min-width: 990px) and (max-width: 1290px) {
  .shareIconPannelContainer {
    top: 48%;
  }

  .shareIcons {
    width: 20px;
    height: 20px;
  }

  .showLinkCopiedIcon {
    width: 20px;
    height: 20px;
  }

  .titleContainer {
    justify-content: flex-start;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .shareIconPannelContainer {
    top: 46%;
  }

  .discount {
    font-size: 0.9em !important;
  }

  .shareIcons {
    width: 20px;
    height: 20px;
  }

  .showLinkCopiedIcon {
    width: 20px;
    height: 20px;
  }

  /* .rightSection {
    margin-top: 1em;
  } */
  /* .askQuestionButton {
    width: 25em !important;
  } */
}

@media (max-width: 845px) {
  .titleContainer {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

@media (min-width: 480px) and (max-width: 767.5px) {
  .shareIconPannelContainer {
    top: 45%;
  }

  .offlineAddress {
    font-size: 0.95em;
  }

  .shareIcons {
    width: 18px;
    height: 18px;
  }

  .showLinkCopiedIcon {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 990px) {
  .videoReviewSection {
    display: block;
    justify-content: space-between;
    padding: 1em;
    background-color: var(--qrencia-white);
    border-radius: 10px;
    margin: 1em 0 0 0;
    border: 1px solid var(--qrencia-light-grey-border);
    gap: 2em;
    height: 25em;
  }
  .descriptionBody p {
    font-size: 1em;
  }

  .videoIcon {
    top: 50%;
  }

  .reviewSection {
    width: 100%;
  }

  .reviewHeading {
    margin-top: 1em;
  }

  /* .enrolButton {
    margin-top: 1em;
  } */

  .thumbnailContainer {
    display: block;
  }

  .closeButton {
    top: 2%;
    right: 1%;
    width: 8%;
    font-size: 2em;
  }

  .rightSection {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 1em;
  }

  .askQuestionButton {
    margin-bottom: 0;
  }

  .onlineText {
    line-height: 1.8;
  }
}

@media (max-width: 768px) {
  .courseTitle {
    font-size: 1em;
    margin-right: 0em;
  }

  .courseDuration {
    font-size: 0.8em;
  }
  .workshopInfo {
    font-size: 0.76em;
  }

  .displayInline p {
    font-size: 0.8em;
  }

  .courseDate {
    font-size: 0.8em !important;
    margin-bottom: 0.1em;
  }
  .workshopDate {
    font-size: 0.8em !important;
    margin-bottom: 0.1em;
  }
  .descriptionBody p {
    font-size: 1em;
  }

  .closeButton {
    top: 2%;
    right: 1%;
    width: 8%;
    font-size: 1.6em;
  }

  .displayInline p {
    font-size: 0.8em !important;
  }

  .displayInline svg {
    font-size: 0.85em !important;
  }

  .rightSection {
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.5em;
  }
  .competitionAdsSection {
    display: block;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    background-color: transparent;
    border-radius: 10px;
    padding: 0em;
    margin: 1em 0 1em 0;
    border: none;
  }
}

@media (max-width: 640px) {
  .courseLayout {
    margin: 1em 1em 2em 1em;
  }

  .courseTitle {
    font-size: 0.9em;
  }

  .costText {
    margin-top: 0.8%;
    color: var(--qrencia-text-black) !important;
  }

  .courseDuration {
    font-size: 0.75em;
    margin-bottom: 0.4em;
  }

  .workshopInfo {
    font-size: 0.72em;
  }

  .displayInline p {
    font-size: 0.75em !important;
  }

  .courseDate {
    font-size: 0.75em !important;
    margin-bottom: 0.4em;
  }

  .workshopDate {
    font-size: 0.75em;
    margin-bottom: 0.4em;
  }

  .closeButton {
    top: 1.5%;
    right: 1%;
    width: 8%;
    font-size: 1.4em;
  }

  .leftCourseDetails > img {
    width: 12em;
    height: 9em;
  }
}

@media (max-width: 590px) {
  .costInfo {
    font-size: 0.8em;
  }

  .courseLayout {
    margin: 1em 7% 5em 7%;
  }

  .donwloadPreRead {
    margin-bottom: 0.5em;
  }

  .thumbnailContainer {
    padding: 0.8em;
  }

  .leftCourseDetails {
    padding: 0.3em;
    display: block;
    margin: 0em;
  }

  .courseTitle {
    font-size: 1.2em;
  }

  .descriptionHeading {
    font-size: 1.1em;
  }

  .descriptionBody {
    font-size: 0.85em;
  }

  .leftThumbnailDetails {
    padding-left: 0em;
    margin-top: 0.5em;
    width: 95%;
  }

  .courseDuration {
    font-size: 0.95em;
  }
  .workshopInfo {
    font-size: 0.9em;
  }

  .displayInline p {
    font-size: 0.95em;
  }

  .courseDate {
    font-size: 0.95em;
    margin-bottom: 0.4em;
  }
  .workshopDate {
    font-size: 0.95em;
    margin-bottom: 0.4em;
  }

  .askQuestionButton {
    width: 10em !important;
    font-size: 0.72em;
  }

  .enrolButton {
    margin-top: 0;
    margin-bottom: 0;
    width: 10em !important;
    font-size: 0.72em;
  }

  .leftCourseDetails > img {
    width: 100%;
    height: 100%;
  }

  .rightSection {
    padding: 0.5em;
    margin-top: 0em;
  }

  .section {
    padding: 0.8em;
  }
}

@media (max-width: 480px) {
  .costInfo {
    font-size: 1em;
  }

  .courseTitle {
    font-size: 1em;
  }

  .courseDate {
    font-size: 0.76em !important;
    margin-bottom: 0.4em;
  }

  .workshopDate {
    font-size: 0.76em !important;
    margin-bottom: 0.4em;
  }

  .courseDuration {
    font-size: 0.76em;
  }
  .workshopInfo {
    font-size: 0.72em;
  }

  .displayInline p {
    font-size: 0.8em;
  }

  .askButtonBelow {
    margin-left: 1.1em;
    margin-top: 0em;
  }

  .leftCourseDetails {
    padding: 0.3em;
    display: block;
    margin: 0em;
  }

  .leftCourseDetails > img {
    width: 100%;
  }

  .closeButton {
    top: 1%;
    right: 1.2%;
    width: 8%;
    font-size: 1.2em;
  }
}

@media (max-width: 400px) {
  .costInfo {
    font-size: 1.1em;
  }

  .courseLayout {
    margin: 1em 7% 5em 7%;
  }

  .shareIconPannelContainer {
    top: 44%;
  }

  .leftCourseDetails > img {
    width: 100%;
  }

  .shareIcons {
    width: 18px;
    height: 18px;
  }

  .showLinkCopiedIcon {
    width: 17px;
    height: 17px;
    padding: 5% 0% 5% 0%;
  }

  .thumbnailContainer {
    padding: 0.4em;
  }

  .leftThumbnailDetails {
    padding-left: 0em;
    margin-top: 0.8em;
    width: 95%;
  }

  .leftCourseDetails {
    padding: 0.3em;
    display: block;
    margin: 0em;
  }

  .courseTitle {
    font-size: 0.95em;
  }

  .descriptionHeading {
    font-size: 0.95em;
  }

  .faqHeading {
    font-size: 0.95em;
  }

  .descriptionBody {
    font-size: 0.78em;
  }

  .leftThumbnailDetails {
    padding-left: 0em;
    margin-top: 0.5em;
  }

  .courseDuration {
    font-size: 0.72em;
  }
  .workshopInfo {
    font-size: 0.7em;
  }

  .displayInline p {
    font-size: 0.72em;
  }

  .courseDate {
    font-size: 0.72em;
    margin-bottom: 0.4em;
  }

  .workshopDate {
    font-size: 0.72em;
    margin-bottom: 0.4em;
  }

  .reviewHeading {
    font-size: 0.9em;
  }

  .rightSection {
    padding: 0 0.6em 0.6em 0.6em;
  }
}
