.statusWrapper {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  justify-content: center;
}

.statusText {
  /* font-size: 1rem; */
  font-weight: 200;
  color: #333;
}

.statusDot {
  width: 6px;
  height: 6px;
  border-radius: 50%; /* Makes the dot circular */
}
