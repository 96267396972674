.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.qrenciaLogo {
    width: 30%;
}

.sectionNavItem {
    font-size: 0.9em;
    font-weight: 500 !important;
    color: var(--qrencia-white);
    text-decoration: none;
    padding: 0.6rem 11rem;
    cursor: pointer;
    border-radius: 7px;
    transition: 0.3s ease;
    background-color: var(--qrencia-dark-blue);
}

.popUpImageSection {
    position: relative;
}

.popupBox {
    background: #fff;
    border-radius: 8px;
    padding: 0.5rem 0rem 1.2rem 0rem;
    width: 450px;
    height: 521px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
}

.popUpImage {
    width: 100%;
    margin-bottom: 1.2rem;
}

.popUpDescription {
    font-size: 0.9rem;
    position: absolute;
    font-weight: 700 !important;
    text-align: center;
    bottom: 40px;
    right: 100px;
}

.businessGrow {
    color: var(--qrencia-dark-blue);
    text-align: center;
    font-size: 1.9rem;
    position: absolute;
    top: 20px;
    font-weight: 700 !important;
}


.popupTitle {
    margin: 0;
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    background-color: var(--qrencia-white);
}

.popupContent {
    font-size: 1rem;
    color: #555;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 13px;
    font-size: 1.5rem;
    cursor: pointer;
    color: #888;
}

.closeButton:hover {
    color: #333;
}

.openButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.openButton:hover {
    background-color: #0056b3;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@media (max-width: 1024px) {
    .popupBox {
        width: 380px;
        height: 451px;
    }

    .businessGrow {
        font-size: 1.6rem;
        top: 18px;
    }

    .popUpDescription {
        font-size: 0.8rem;
        right: 74px;
    }

    .sectionNavItem {
        padding: 0.6rem 9rem;
    }
}

@media (max-width: 425px) {
    .popupBox {
        width: 300px;
        height: 371px;
    }

    .businessGrow {
        font-size: 1.3rem;
        top: 15px;
    }

    .popUpDescription {
        font-size: 0.7rem;
        right: 44px;
        top: 217px;
    }

    .sectionNavItem {
        padding: 0.6rem 6rem;
    }
}