.searchContainer {
  display: flex;
  align-items: flex-end;
}
.selectButton {
  font-size: 0.8em;
  height: 2.9em;
}

@media(max-width:480px){
  .selectButton {
    height: 2.3em;
  }
}