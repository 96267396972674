@media screen and (max-width: 480px) {
  #wa-widget {
    width: 90% !important;
    bottom: 8% !important;
    right: 5% !important;
    left: auto !important;
  }

  #wa-chat-window {
    width: 100% !important;
  }

  #wa-widget .font-header {
    font-size: 0.9rem !important;
  }

  #wa-widget p {
    font-size: 0.82rem !important;
  }

  #wa-widget button {
    font-size: 0.82rem !important;
  }

  #wa-chat-window {
    font-size: 0.82rem;
  }

  #wa-chat-window p,
  #wa-chat-window div {
    font-size: 0.82rem;
  }
}