.header {
  margin-bottom: 1.2%;
  display: flex;
  background: var(--qrencia-white);
}

.profileName {
  display: inline-block;
}

.logoSection {
  border-right: 1px solid var(--qrencia-logo-border);
  padding: 1.1% 2.2% 1% 1.3%;
  width: 16%;
}

.headerContentSection {
  width: 84%;
  display: flex;
  justify-content: flex-end;
}

.logo {
  width: 52%;
}

.profileSection {
  width: 15%;
  display: flex;
  margin-block: auto;
  justify-content: left;
}

.profileDropdownItem {
  display: flex;
}

.profileDropdownOption {
  font-size: 0.9em;
}

.profileDropdownOptionIcon {
  font-size: 1em;
  margin-top: 2.5%;
  margin-right: 3%;
  color: var(--qrencia-dark-blue);
}

.profilePopoverButton {
  font-size: 0.86em;
  background-color: transparent !important;
  color: var(--qrencia-user-name) !important;
  font-weight: 500;
  border: none;
  outline: none;
  margin-top: 6%;
}

.profilePopoverButton:hover,
.profilePopoverButton:focus,
.profilePopoverButton:active {
  background-color: transparent !important;
  color: var(--qrencia-user-name) !important;
  font-weight: 500;
  border: none !important;
  margin: 0;
  margin-top: 6%;
}

.profileImage {
  border-radius: 50px;
  height: 34px;
  width: 34px;
  display: block;
  object-fit: cover;
}

.notificationSection {
  width: 5%;
  margin-block: auto;
  padding-left: 0.6%;
  cursor: pointer;
}

.userInitials {
  font-weight: 500;
  background-color: var(--qrencia-dark-blue);
  padding: 14% 0%;
  text-align: center;
  color: var(--qrencia-white);
  border-radius: 50px;
  height: 33px;
  width: 34px;
  display: block;
  font-size: 1em;
}

.notificationBadge {
  position: absolute;
  border-radius: 9px;
  font-size: 0.58em;
  margin-left: -11.5px;
  margin-top: -2.2px;
  width: 16px;
  padding: 0.22% 0.28%;
  font-weight: 500;
}

.notificationImage {
  font-size: 1.4em;
  color: var(--qrencia-icons);
}

.popoverHeader {
  font-size: 1em;
  font-weight: 500;
}

.popoverBody {
  font-size: 1em;
  font-weight: 400;
}

/* Media Quries */
@media (min-width: 991.1px) and (max-width: 1286px) {
  .profileDropdownOption {
    font-size: 0.85em;
  }

  .notificationSection {
    width: 6%;
    text-align: center;
  }

  .profileSection {
    width: 20%;
    padding-right: 2%;
  }

  .logoSection {
    width: 16%;
  }

  .logo {
    width: 60%;
  }

  .profilePopoverButton {
    font-size: 0.82em;
  }

  .userInitials {
    font-size: 0.95em;
    padding: 16% 0%;
  }
}

@media (min-width: 912px) and (max-width: 991.5px) {
  .userInitials {
    font-size: 0.95em;
    padding: 16% 0%;
  }

  .profileDropdownOption {
    font-size: 0.83em;
  }

  .logoSection {
    width: 24%;
  }

  .headerContentSection {
    width: 76%;
    display: flex;
    justify-content: flex-end;
  }

  .headerContentSection {
    width: 80%;
  }

  .profileSection {
    width: 24%;
    padding-left: 1%;
  }

  .logo {
    width: 45%;
  }

  .profilePopoverButton {
    font-size: 0.8em;
  }

  .notificationBadge {
    padding: 0.32% 0.28%;
  }
}

@media (min-width: 820px) and (max-width: 912px) {
  .profileDropdownOption {
    font-size: 0.83em;
  }

  .logoSection {
    width: 24%;
  }

  .headerContentSection {
    width: 76%;
  }

  .logo {
    width: 45%;
  }

  .profileSection {
    width: 26%;
    padding-left: 1%;
  }

  .profilePopoverButton {
    font-size: 0.75em;
  }

  .notificationBadge {
    padding: 0.32% 0.28%;
  }
}

@media (max-width: 820px) {
  .profileDropdownOption {
    font-size: 0.83em;
  }

  .logoSection {
    width: 24%;
  }

  .headerContentSection {
    width: 76%;
  }

  .logo {
    width: 50%;
  }

  .profileSection {
    width: 28%;
    padding-left: 1%;
  }

  .profilePopoverButton {
    font-size: 0.78em;
  }

  .notificationBadge {
    padding: 0.38% 0.28%;
  }
}

@media (min-width: 768px) and (max-width: 991.5px) {
  .logo {
    width: 55%;
  }

  .profileSection {
    width: 28%;
    justify-content: center;
    padding-right: 2%;
  }

  .logoSection {
    padding: 1.5% 2% 1.5% 1%;
    width: 20%;
  }

  .headerContentSection {
    width: 80%;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .notificationSection {
    width: 6%;
  }
}

@media (min-width: 690px) and (max-width: 767.5px) {
  .profileName {
    display: none;
  }

  .logoSection {
    padding: 1.5% 2% 1.5% 1%;
    width: 25%;
  }

  .headerContentSection {
    width: 75%;
  }

  .logo {
    width: 50%;
  }

  .notificationSection {
    width: 6%;
  }

  .profileSection {
    width: 19%;
    justify-content: center;
  }

  .notificationBadge {
    padding: 0.5% 0.35%;
  }
}

@media (min-width: 480px) and (max-width: 690px) {
  .profileName {
    display: none;
  }

  .logoSection {
    padding: 1.5% 2% 1.5% 1%;
    width: 25%;
  }

  .headerContentSection {
    width: 75%;
  }

  .logo {
    width: 55%;
  }

  .notificationSection {
    width: 8%;
  }

  .profileSection {
    width: 24%;
    justify-content: center;
  }

  .notificationImage {
    font-size: 1.25em;
  }
}

@media (max-width: 480px) {
  .profileName {
    display: none;
  }

  .notificationSection {
    width: 10%;
  }

  .userInitials {
    font-size: 0.75em;
    padding: 19% 0%;
  }

  .profileDropdownOption {
    font-size: 0.8em;
  }

  .profileDropdownOptionIcon {
    font-size: 0.85em;
  }

  .userInitials {
    height: 30px;
    width: 30px;
  }

  .profileImage {
    height: 30px;
    width: 30px;
  }

  .profilePopoverButton {
    font-size: 0.6em;
  }

  .notificationImage {
    font-size: 1.1em;
  }

  .logoSection {
    padding: 3% 2% 3% 1%;
    width: 26%;
  }

  .headerContentSection {
    width: 74%;
  }

  .logo {
    width: 55%;
  }

  .profileSection {
    width: 21%;
    justify-content: center;
    padding-left: 1%;
  }

  .notificationBadge {
    padding: 0.65% 1%;
  }
}

@media (max-width: 376px) {
  .profileName {
    display: none;
  }

  .userInitials {
    height: 26px;
    width: 26px;
  }

  .profileImage {
    height: 26px;
    width: 26px;
  }

  .profilePopoverButton {
    font-size: 0.6em;
  }

  .popoverHeader {
    font-size: 0.65em;
    font-weight: 500;
    padding: 6%;
  }

  .popoverBody {
    font-size: 0.65em;
    font-weight: 400;
    padding: 6%;
  }

  .notificationImage {
    font-size: 1.1em;
  }

  .notificationBadge {
    padding: 0.7% 0.35%;
  }

  .logoSection {
    padding: 3% 2% 3% 1%;
    width: 26%;
  }

  .headerContentSection {
    width: 74%;
  }

  .logo {
    width: 55%;
  }
}

@media (max-width: 991.5px) {
  .logoSection {
    text-align: end;
  }
}

@media (min-width: 480px) and (max-width: 600px) {
  .logoSection {
    padding: 2.5% 2% 2.5% 1%;
    width: 25%;
  }

  .headerContentSection {
    width: 75%;
  }
}

@media (max-width: 767.5px) {
  .logoSection {
    text-align: end;
  }
}

@media (min-width: 480px) and (max-width: 600px) {
  .logoSection {
    padding: 2.5% 2% 2.5% 1%;
    width: 25%;
  }

  .headerContentSection {
    width: 75%;
  }

  .notificationBadge {
    padding: 0.55% 0.28%;
  }
}
