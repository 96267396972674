.backgroundImage {
    width: 100%;
    height: 88vh;
    object-position: center top;
}

.contentSection {
    width: 100%;
    text-align: center;
    padding: 4% 8%;
    padding-bottom: 6%;
    position: absolute;
    top: 86%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.homeButton {
    margin-top: 2%;
}

.heading {
    font-size: 2.4em;
    font-weight: 700;
}

.subHeading {
    font-size: 1.2em;
    font-weight: 400;
}

@media(min-width:1290px) and (max-width:1480px) {
    .backgroundImage {
        width: 100%;
        height: 84vh;
        object-fit: cover;
    }

    .contentSection {
        top: 80%;
    }
}

@media(max-width:1290px) {
    .backgroundImage {
        width: 100%;
        height: 84vh;
        object-fit: cover;
    }
}

@media(min-width:991px) and (max-width:1290px) {
    .heading {
        font-size: 1.95em;
    }

    .backgroundImage {
        height: 88vh;
    }
    .subHeading {
        font-size: 1.15em;
    }
    .contentSection {
        top: 83%;
    }
}

@media(min-width:540px) and (max-width:991px) {
    .heading {
        font-size: 1.9em;
    }

    .subHeading {
        font-size: 1.05em;
    }

    .contentSection {
        top: 74%;
    }
}

@media(min-width:540px) and (max-width:767.5px) {
    .heading {
        font-size: 1.5em;
    }

    .subHeading {
        font-size: 0.9em;
    }

    .contentSection {
        top: 66%;
    }

    .backgroundImage {
        height: 75vh;
    }
}

@media(max-width:540px) {
    .heading {
        font-size: 1.2em;
    }

    .subHeading {
        font-size: 0.8em;
    }

    .contentSection {
        top: 44%;
        padding: 4% 2%;
    }

    .backgroundImage {
        aspect-ratio: 1;
        object-fit: cover;
        height: 48vh;
    }

    .homeButton {
        font-size: 0.8em;
    }
}

@media (min-width:768px) and (max-width: 913px) and (min-height: 1024px) and (max-width:1368px) {
    .heading {
        font-size: 1.8em;
    }

    .subHeading {
        font-size: 1.1em;
    }


    .backgroundImage {
        aspect-ratio: 1;
        object-fit: cover;
        height: 42vh;
    }
}

@media (min-width:1022px) and (max-width: 1024px) and (min-height: 1334px) and (max-width:1338px) {
    .heading {
        font-size: 1.8em;
    }

    .subHeading {
        font-size: 1.1em;
    }

    .backgroundImage {
        aspect-ratio: 1;
        object-fit: cover;
        height: 42vh;
    }
}