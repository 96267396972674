.learningActivitySectionContainer {
  height: 100vh;
  width: 100%;
  background-color: var(--performance-page-skyblue-bg);
}

.topHeadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 25vh;
  padding: 0 3em;
}

.headingText {
  font-size: 2.5em;
  padding: 0 20px 0px 0px;
  color: var(--qrencia-grey-text-color);
  text-align: center;
}

.subHeadingText {
  font-size: 1.4em;
  color: #002e51;
  text-align: center;
}

.bottomContentContainer {
  display: flex;
  align-items: center;
  height: 68.5vh;
  margin-top: -1.5em;
}

@media (max-height: 620px) and (min-height: 584px) {
  .bottomContentContainer {
    height: 78vh;
  }
}

@media (min-width: 768px) and (max-width: 1080px) {
  .topHeadingContainer {
    padding: 0;
  }

  .headingText {
    font-size: 2.4em;
    padding: 0 15px 20px 15px;
    color: var(--qrencia-grey-text-color);
    text-align: center;
  }

  .subHeadingText {
    font-size: 1.1em;
    color: var(--qrencia-dark-blue);
    text-align: center;
  }
}

@media (max-width: 768px) {
  .topHeadingContainer {
    padding: 0;
  }

  .headingText {
    font-size: 2em;
    padding: 0 20px 15px 20px;
  }

  .subHeadingText {
    font-size: 1em;
  }
}

@media (max-width: 580px) {
  .topHeadingContainer {
    padding: 0;
    justify-content: center;
  }

  .bottomContentContainer {
    padding: 0;
  }

  .headingText {
    font-size: 1.6em;
    padding: 0 20px 15px 10px;
  }

  .subHeadingText {
    font-size: 1em;
  }
}

@media (min-width: 480px) and (max-width: 580.5px) {
  .topHeadingContainer {
    height: 26vh;
  }

  .bottomContentContainer {
    height: 74vh;
  }
}
