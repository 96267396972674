.qrenciaLogoSection {
  width: 20%;
}

.navItemSection {
  width: 80%;
}

.qrenciaLogo {
  width: 55%;
}

.header {
  margin: auto;
  display: flex;
  justify-content: space-between;
  background: var(--qrencia-template-background-color);
  position: sticky;
  top: 0;
  /* box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2); */
  z-index: 1000;
}

.individualPageHeader {
  padding: 0.65% 6.5%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  background: var(--qrencia-white);
  top: 0;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.list {
  display: flex;
  gap: 1rem;
  text-decoration: none;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding-top: 1.5rem;
}

.listItem {
  width: 16%;
  text-align: center;
  /* padding-top: 2rem; */
}

.listItem:focus {
  outline: none;
}

.skillPageList {
  padding: 0 0.4rem 0 0.4rem;
}

.loginButton {
  text-decoration: none;
  letter-spacing: 1px;
  color: var(--qrencia-dark-blue);
  background: var(--qrencia-white);
  border-radius: 6px;
  font-size: 0.9em;
  font-weight: 500;
  padding: 7% 30%;
}

.signUpButton {
  text-decoration: none;
  letter-spacing: 1px;
  color: var(--qrencia-white);
  background: var(--qrencia-dark-blue);
  border-radius: 6px;
  border: 1px solid var(--qrencia-dark-blue);
  font-size: 0.9em;
  font-weight: 500;
  padding: 5% 16%;
}

.loginButton:hover {
  color: var(--qrencia-dark-blue);
  background: var(--qrencia-light-blue-background);
  border: 1px solid var(--qrencia-dark-blue);
}

.signUpButton:hover {
  color: var(--qrencia-white);
  background: var(--qrencia-blue-darker-contrast);
}

.sectionNavItem {
  all: unset;
  /* Removes all default Bootstrap styling */
  text-decoration: none;
  text-align: center;
  font-size: 0.9em;
  font-weight: 500 !important;
  color: var(--card-grey-title);
  padding: 5px 0;
  margin: 0 5px;
  cursor: pointer;
  background-image: linear-gradient(var(--qrencia-dark-blue),
      var(--qrencia-dark-blue));
  background-size: 0 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
}

.sectionNavItem:hover {
  text-decoration: none;
  text-align: center;
  color: var(--qrencia-text-black);
  background-size: 100% 2px, auto;
}

.sectionNavItemActive {
  text-decoration: none;
  text-align: center;
  color: var(--qrencia-text-black);
  background-size: 100% 2px, auto;
}

/* Reset Bootstrap default styles for the dropdown toggle */
.dropdownToggle {
  all: unset;
  /* Removes all default Bootstrap styling */
  text-decoration: none;
  text-align: center;
  font-size: 0.9em;
  font-weight: 500 !important;
  color: var(--card-grey-title);
  padding: 5px 0;
  margin: 0 5px;
  cursor: pointer;
  background-image: linear-gradient(var(--qrencia-dark-blue),
      var(--qrencia-dark-blue));
  background-size: 0 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
}

.dropdownToggle:hover {
  text-decoration: none;
  text-align: center;
  color: var(--qrencia-text-black);
  background-size: 100% 2px, auto;
}

/* Dropdown specific styling */
.accordionToggle {
  all: unset;
  text-decoration: none;
  text-align: center;
  font-size: 0.9em;
  font-weight: 500;
  color: var(--card-grey-title);
  padding: 5px 0;
  margin: 0 5px;
  cursor: pointer;
  background-image: linear-gradient(var(--qrencia-dark-blue),
      var(--qrencia-dark-blue));
  background-size: 0 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
}

.accordionToggle:hover {
  color: var(--qrencia-text-black);
  background-size: 100% 2px, auto;
}

.accordionMenu {
  display: none;
  flex-direction: column;
  position: static;
  background: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

.accordionMenu.show {
  display: flex;
}

.accordionItem {
  all: unset;
  display: block;
  text-align: left;
  font-size: 0.9em;
  font-weight: 500;
  color: var(--card-grey-title);
  padding: 5px 0;
  margin: 0;
  cursor: pointer;
  background: none;
  transition: background-size 0.2s ease-out;
}

.accordionItem:hover {
  color: var(--qrencia-text-black);
}

.widthNotSet {
  width: unset !important;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 18px;
  height: 2px;
  background-color: var(--qrencia-dark-blue);
  margin: 4px 0;
  border-radius: 6px;
}

.displayOnWeb {
  display: block;
}

.displayOnMobile {
  display: none;
}

.listOnMobile {
  top: 1em;
  display: none;
  gap: 1rem;
  text-decoration: none;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 2% 0%;
}

@media (max-width: 768.5px) {
  .displayOnWeb {
    display: none;
  }

  .qrenciaLogo {
    width: 75% !important;
  }

  .listItem {
    width: 22%;
  }

  .list {
    gap: 1rem;
    padding: 1.3rem 0 0 0;
  }

  .displayOnMobile {
    display: block;
  }

  .hamburger {
    display: block;
    margin: 17px 10px 0 0;
  }

  .listOnMobile {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 3.6em;
    left: -250px;
    background-color: var(--qrencia-white);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all 0.3s ease-in-out;
  }

  .loginButton {
    padding: 5px 22%;
    font-size: 0.8em;
  }


  .signUpButton {
    padding: 5px 12%;
    font-size: 0.8em;
  }

  .listOnMobile.showMenu {
    left: 0;
    display: flex;
  }
}

@media (min-width: 1144px) and (max-width: 1290px) {
  .listItem {
    width: 14%;
  }

  .loginButton {
    font-size: 0.8em;
    padding: 5% 28%;
  }

  .signUpButton {
    font-size: 0.8em;
    padding: 5% 18%;
  }
}

@media (min-width: 1030px) and (max-width: 1144px) {
  .sectionNavItem {
    font-size: 0.75em;
  }

  .list {
    gap: 1rem;
    padding: 1.8rem 0 0 0;
  }

  .listItem {
    width: 13%;
  }

  .loginButton {
    padding: 5% 18%;
    font-size: 0.75em;
  }

  .signUpButton {
    padding: 5% 12%;
    font-size: 0.75em;
  }

  .qrenciaLogo {
    width: 55%;
  }
}

@media (min-width: 900px) and (max-width: 1030px) {
  .sectionNavItem {
    font-size: 0.82em;
  }

  .list {
    gap: 1rem;
    padding: 1.3rem 0 0 0;
  }

  .listItem {
    width: 16%;
  }

  .loginButton {
    padding: 5% 18%;
    font-size: 0.75em;
  }

  .signUpButton {
    padding: 5% 12%;
    font-size: 0.75em;
  }

  .qrenciaLogo {
    width: 55%;
  }

  .individualPageHeader {
    padding: 1% 5%;
  }
}

@media (min-width: 768px) and (max-width: 900.5px) {
  .sectionNavItem {
    font-size: 0.74rem;
    padding: 5px 0;
  }

  .list {
    gap: 1rem;
    padding: 1.5rem 0 0 0;
  }

  .loginButton {
    padding: 5% 18%;
    font-size: 0.64em;
  }

  .signUpButton {
    padding: 5% 13%;
    font-size: 0.64em;
  }

  .qrenciaLogo {
    width: 65% !important;
  }

  .listItem {
    width: 17%;
  }

  .individualPageHeader {
    padding: 1% 3.5%;
  }

  .accordionItem {
    font-size: 0.64em;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .sectionNavItem {
    font-size: 0.7em;
    padding: 5px 0;
  }

  .loginButton {
    padding: 5% 20%;
    font-size: 0.64em;
  }

  .signUpButton {
    padding: 5% 12%;
    font-size: 0.64em;
  }

  .qrenciaLogo {
    width: 65% !important;
  }

  .list {
    gap: 1.4em;
    /* padding: ; */
  }

  .listItem {
    width: 19%;
  }

  .individualPageHeader {
    padding: 1% 3.5%;
  }
}

/* @media (min-width: 665px) and (max-width: 768.5px) {

  .loginButton {
    padding: 5px 22%;
    font-size: 0.8em;
  }


  .signUpButton {
    padding: 5px 12%;
    font-size: 0.8em;
  }

  .qrenciaLogo {
    width: 55%;
  }

  .header {
    padding: 0.5em 1em;
  }

  .individualPageHeader {
    padding: 0.5em 1em;
  }
} */

@media (min-width: 580px) and (max-width: 665px) {
  .sectionNavItem {
    font-size: 0.8em;
  }

  /* .header {
    padding: 0.5em 1.2em;
  } */

  .individualPageHeader {
    padding: 0.5em 1.2em;
  }

  .hamburger {
    margin: 9px 10px 0 0;
  }

  .listOnMobile {
    top: 3.2em;
  }

  .listItem {
    width: 6em;
  }

  .loginButton {
    padding: 5px 22%;
    font-size: 0.65em;
  }

  .signUpButton {
    padding: 5px 16%;
    font-size: 0.65em;
  }

  .qrenciaLogo {
    width: 55%;
    margin-top: 2%;
  }

  .list {
    padding: 3% 0% 0% 0%;
  }
}

@media (min-width: 480px) and (max-width: 580px) {
  .sectionNavItem {
    font-size: 0.8em;
  }

  .individualPageHeader {
    padding: 0.5em 1em;
  }

  .hamburger {
    margin: 15px 8px 0 0;
  }

  .listOnMobile {
    top: 2.9em;
  }

  .listItem {
    width: 5em;
  }

  .loginButton {
    padding: 5px 22%;
    font-size: 0.65em;
  }

  .signUpButton {
    padding: 5px 10%;
    font-size: 0.65em;
  }

  .qrenciaLogo {
    width: 55%;
    margin-top: 2%;
  }

  .list {
    padding: 4% 0% 0% 0%;
  }

  .qrenciaLogoSection {
    width: 26%;
  }
}

@media (max-width: 480px) {
  .sectionNavItem {
    font-size: 0.7em;
  }

  .qrenciaLogoSection {
    width: 40%;
  }

  .bar {
    width: 20px;
    height: 2px;
  }

  .individualPageHeader {
    padding: 0.5em 1em;
  }

  .hamburger {
    margin: 14px 8px 0 0;
  }

  .listOnMobile {
    top: 2.7em;
  }

  .listItem {
    width: 5.4em;
  }

  .loginButton {
    padding: 5px 22%;
    font-size: 0.65em;
  }

  .signUpButton {
    padding: 5.5px 13%;
    font-size: 0.65em;
  }

  .qrenciaLogo {
    width: 65% !important;
  }

  .list {
    padding: 5% 0% 0% 0%;
  }

}

@media (max-width: 480px) {
  .listOnMobile {
    top: 2.6em;
  }
}

@media (max-width: 290px) {
  .listItem {
    width: 35%;
  }

  .loginButton {
    padding: 5% 15%;
    font-size: 0.6em;
  }

  .signUpButton {
    padding: 5% 15%;
    font-size: 0.6em;
  }

  .qrenciaLogo {
    width: 55%;
  }
}

@media (min-width: 1143px) and (max-width: 1217px) {
  .list {
    display: flex;
    gap: 0.6rem;
    text-decoration: none;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 1.9rem 0 0 0;
  }
}

@media (min-width: 1143px) and (max-width: 1164px) {
  .sectionNavItem {
    font-size: 0.8em;
  }
}