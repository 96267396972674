/* .main-container-signup {
  margin: 1.4rem 3rem 8px 3rem;
  padding: 0 3%;
} */

.carousel-container {
  padding: 0 !important;
}

.signup-form-container {
  padding: 0 !important;
  z-index: 22 !important;
}

.main-container-signup .carousel-inner {
  height: 100% !important;
}

.main-container-signup .carousel-indicators {
  gap: 0;
  margin-bottom: 17rem;
  left: 72%;
}
.main-container-signup .carousel-indicators .active {
  height: 8px !important;
  width: 8px !important;
}
.login-form-container {
  padding: 0 !important;
}
@media (max-width: 991px) {
  .main-container-signup {
    margin: 1.4rem 3rem 8px 3rem;
    padding: 0 3%;
  }
}
@media (min-width: 769px) {
  .login-form-container {
    padding: 0;
    z-index: 1;
    margin-left: -0.2%;
  }
}

@media (min-width: 768px) and (max-width: 991.5px) {
  .main-container-signup {
    margin: 1.5rem 20%;
  }
}

@media (min-width: 768px) and (max-width: 930px) {
  .main-container-signup .carousel-indicators [data-bs-target] {
    height: 10px;
  }
}

@media (min-width: 559px) and (max-width: 768px) {
  .main-container-signup {
    margin: 1.5rem 3.5rem;
    padding: 0;
  }
}

@media (min-width: 320px) and (max-width: 560px) {
  .main-container-signup {
    margin: 1.5rem 1rem;
    padding: 0;
  }
}
