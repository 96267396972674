.qrenciaLogoSection {
  width: 20%;
}

.navItemSection {
  width: 80%;
}

.qrenciaLogo {
  width: 55%;
}

.header {
  padding: 0.65% 6.5%;
  background-color: var(--qrencia-white);
  margin: auto;
  display: flex;
  justify-content: space-between;
  top: 0;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  position: sticky;
}

.individualPageHeader {
  padding: 0.65% 6.5%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  background: var(--qrencia-white);
  top: 0;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.list {
  display: flex;
  gap: 1rem;
  text-decoration: none;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 2% 0%;
}

.listItem {
  width: 15%;
  text-align: center;
}

.listItem:focus {
  outline: none;
}

.skillPageList {
  padding: 0 0.4rem 0 0.4rem;
}

.loginButton {
  text-decoration: none;
  letter-spacing: 1px;
  color: var(--qrencia-dark-blue);
  background: var(--qrencia-white);
  border-radius: 8px;
  font-size: 0.9em;
  font-weight: 500;
  padding: 9% 30%;
}

.signUpButton {
  background: var(--qrencia-dark-blue);
  border: 1px solid var(--qrencia-dark-blue);
  border-radius: 4px;
  color: var(--qrencia-white);
  font-size: 0.9em;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 5% 16%;
  text-decoration: none;
}

.BookDemo {
  background: var(--qrencia-white);
  border: 1px solid var(--qrencia-text-black);
  border-radius: 4px;
  color: var(--qrencia-text-black);
  font-size: 0.9em;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 5% 9%;
  text-decoration: none;
  transition: 0.6 ease;
}
.BookDemo:hover {
  background: var(--qrencia-light-blue-background);
  border: 1px solid var(--qrencia-dark-blue);
  color: var(--qrencia-dark-blue);
}

.loginButton:hover {
  color: var(--qrencia-dark-blue);
  background: var(--qrencia-light-blue-background);
  border: 1px solid var(--qrencia-dark-blue);
}

.signUpButton:hover {
  color: var(--qrencia-white);
  background: var(--qrencia-blue-darker-contrast);
}

.sectionNavItem {
  all: unset;
  text-decoration: none;
  text-align: center;
  font-size: 0.9em;
  font-weight: 500 !important;
  color: var(--card-grey-title);
  padding: 5px 0;
  margin: 0 5px;
  cursor: pointer;
  background-image: linear-gradient(
    var(--qrencia-dark-blue),
    var(--qrencia-dark-blue)
  );
  background-size:
    0 2px,
    auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
}

.sectionNavItem:hover {
  text-decoration: none;
  text-align: center;
  color: var(--qrencia-text-black);
  background-size:
    100% 2px,
    auto;
}

.sectionNavItemActive {
  text-decoration: none;
  text-align: center;
  color: var(--qrencia-text-black);
  background-size:
    100% 2px,
    auto;
}

.dropdownToggle {
  all: unset;
  text-decoration: none;
  text-align: center;
  font-size: 0.9em;
  font-weight: 500 !important;
  color: var(--card-grey-title);
  padding: 5px 0;
  margin: 0 5px;
  cursor: pointer;
  background-image: linear-gradient(
    var(--qrencia-dark-blue),
    var(--qrencia-dark-blue)
  );
  background-size:
    0 2px,
    auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
}

.dropdownToggle:hover {
  text-decoration: none;
  text-align: center;
  color: var(--qrencia-text-black);
  background-size:
    100% 2px,
    auto;
}

.accordionToggle {
  all: unset;
  text-decoration: none;
  text-align: center;
  font-size: 0.9em;
  font-weight: 500;
  color: var(--card-grey-title);
  padding: 5px 0;
  margin: 0 5px;
  cursor: pointer;
  background-image: linear-gradient(
    var(--qrencia-dark-blue),
    var(--qrencia-dark-blue)
  );
  background-size:
    0 2px,
    auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
}

.accordionToggle:hover {
  color: var(--qrencia-text-black);
  background-size:
    100% 2px,
    auto;
}

.accordionMenu {
  display: none;
  flex-direction: column;
  position: static;
  background: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

.accordionMenu.show {
  display: flex;
}

.accordionItem {
  all: unset;
  display: block;
  text-align: left;
  font-size: 0.9em;
  font-weight: 500;
  color: var(--card-grey-title);
  padding: 5px 0;
  margin: 0;
  cursor: pointer;
  background: none;
  transition: background-size 0.2s ease-out;
}

.accordionItem:hover {
  color: var(--qrencia-text-black);
}

.dropdownMenu {
  background: var(--qrencia-background);
  box-shadow: none;
  padding: 0;
  margin-top: 5px;
  position: absolute;
  top: 100%;
  left: 2;
  z-index: 500;
}

.dropdownItem {
  all: unset;
  display: block;
  text-align: left;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: var(--card-grey-title);
  padding: 3% 4% 3% 8%;
  cursor: pointer;
  background: none;
  transition: background-size 0.2s ease-out;
}

.dropdownItem:hover {
  color: var(--qrencia-text-black);
  background-size:
    100% 2px,
    auto;
}

.dropdownItem.active {
  color: var(--qrencia-text-black);
  background-size:
    100% 2px,
    auto;
}

.dropdowMenu {
  top: 10px !important;
  right: auto !important;
  bottom: auto !important;
  left: auto !important;
  width: 20% !important;
}

.laIcons {
  width: 21px;
  height: 21px;
}

.dropdownLAMenu {
  width: 80% !important;
}

.dropdown-menu {
  inset: 10px auto auto 1px !important;
  min-width: 4rem;
  padding-left: 10px;
}

.navItemSection .dropdown:hover .dropdown-menu {
  display: block;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 18px;
  height: 2px;
  background-color: var(--qrencia-dark-blue);
  margin: 4px 0;
  border-radius: 6px;
}

.displayOnWeb {
  display: block;
}

.displayOnMobile {
  display: none;
}

.listOnMobile {
  top: 1em;
  display: none;
  gap: 1rem;
  text-decoration: none;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 2% 0%;
}

@media (max-width: 1339px) {
  .listItem {
    width: 16%;
  }
}

@media (max-width: 768.5px) {
  .displayOnWeb {
    display: none;
  }

  .displayOnMobile {
    display: block;
  }

  .hamburger {
    display: block;
    margin: 7.5px 10px 0 0;
  }

  .listOnMobile {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 3.6em;
    left: -250px;
    background-color: var(--qrencia-white);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all 0.3s ease-in-out;
  }

  .listOnMobile.showMenu {
    left: 0;
    display: flex;
    z-index: 2;
  }
}

@media (min-width: 1144px) and (max-width: 1290px) {
  .listItem {
    width: 16%;
  }

  .loginButton {
    font-size: 0.8em;
    padding: 9% 28%;
  }

  .signUpButton {
    font-size: 0.8em;
    padding: 6% 15%;
  }
}

@media (max-width: 1184px) {
  .signUpButton {
    font-size: 0.8em;
    padding: 6% 15%;
  }

  .loginButton {
    font-size: 0.8em;
    padding: 10% 28%;
  }
}

@media (max-width: 1184px) {
  .listItem {
    width: 16.3%;
  }
}

@media (max-width: 1161px) {
  .listItem {
    width: 17%;
  }
}

@media (min-width: 1030px) and (max-width: 1144px) {
  .sectionNavItem {
    font-size: 0.75em;
  }

  .list {
    gap: 0.3rem;
  }

  .listItem {
    width: 17.3%;
  }

  .loginButton {
    padding: 10% 24%;
    font-size: 0.75em;
  }

  .signUpButton {
    padding: 6% 12.8%;
    font-size: 0.75em;
  }

  .qrenciaLogo {
    width: 55%;
  }

  .accordionItem {
    font-size: 0.75em;
  }

  .dropdownItem {
    font-size: 0.75em;
    text-align: left;
    padding-left: 5px;
  }

  .dropdownToggle {
    font-size: 0.75em;
  }
}

@media (max-width: 1094px) {
  .listItem {
    width: 18%;
  }
  .list {
    gap: 0.5rem;
  }
}

@media (min-width: 1030px) and (max-width: 1054px) {
  .listItem {
    width: 16.5% !important;
  }
  .BookDemo {
    font-size: 0.75em;
    padding: 6% 8%;
  }
}

@media (min-width: 900px) and (max-width: 1030px) {
  .sectionNavItem {
    font-size: 0.72em;
  }

  .BookDemo {
    font-size: 0.75em;
    padding: 6% 9%;
  }

  .list {
    gap: 0.3rem;
  }

  .listItem {
    width: 16%;
  }

  .loginButton {
    padding: 10% 24%;
    font-size: 0.75em;
  }

  .signUpButton {
    padding: 6% 10%;
    font-size: 0.75em;
  }

  .qrenciaLogo {
    width: 55%;
  }

  .header {
    padding: 1% 5%;
  }

  .individualPageHeader {
    padding: 1% 5%;
  }

  .accordionItem {
    font-size: 0.72em;
  }

  .dropdownItem {
    font-size: 0.72em;
    text-align: left;
    padding-left: 5px;
  }

  .dropdownToggle {
    font-size: 0.72em;
  }
}

@media (max-width: 976px) {
  .listItem {
    width: 17%;
  }
}

@media (max-width: 924px) {
  .listItem {
    width: 28%;
  }
}

@media (min-width: 768px) and (max-width: 900.5px) {
  .sectionNavItem {
    font-size: 0.64em;
    padding: 5px 0;
  }

  .list {
    gap: 0.3em;
  }

  .BookDemo {
    font-size: 0.64em;
    padding: 7% 9%;
  }

  .loginButton {
    padding: 11% 24%;
    font-size: 0.64em;
  }

  .signUpButton {
    padding: 7% 11%;
    font-size: 0.64em;
  }

  .qrenciaLogo {
    width: 55% !important;
  }

  .list {
    gap: 0.1em;
  }

  .listItem {
    width: 17%;
  }

  .header {
    padding: 1% 3.5%;
  }

  .individualPageHeader {
    padding: 1% 3.5%;
  }

  .accordionItem {
    font-size: 0.64em;
  }

  .accordionItem {
    text-align: left;
    padding-left: 5px;
    font-size: 0.6em;
  }

  .accordionToggle {
    font-size: 0.6em;
  }

  .dropdownItem {
    font-size: 0.6em;
    text-align: left;
    padding-left: 5px;
  }

  .dropdownToggle {
    font-size: 0.6em;
  }
}

@media (max-width: 776px) {
  .BookDemo {
    font-size: 0.64em;
    padding: 7% 8%;
  }
}
@media (max-width: 769px) {
  .BookDemo {
    font-size: 0.8em;
    padding: 6% 8%;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .sectionNavItem {
    font-size: 0.6em;
    padding: 5px 0;
  }

  .loginButton {
    padding: 10% 24%;
    font-size: 0.64em;
  }

  .signUpButton {
    padding: 7% 11%;
    font-size: 0.64em;
  }

  .qrenciaLogo {
    width: 55%;
  }

  .list {
    gap: 0.3em;
  }

  .listItem {
    width: 17%;
  }

  .header {
    padding: 1% 3.5%;
  }

  .individualPageHeader {
    padding: 1% 3.5%;
  }

  .dropdownToggle {
    font-size: 0.6em;
  }

  .accordionMenu {
    margin-top: 5px;
  }

  .accordionToggle {
    font-size: 0.6em;
  }

  .accordionToggle:hover {
    color: none;
    background: none;
  }

  .accordionItem {
    text-align: left;
    padding-left: 5px;
    font-size: 0.6em;
  }

  .dropdownItem {
    font-size: 0.6em;
    text-align: left;
    padding-left: 5px;
  }

  .dropdownToggle {
    font-size: 0.6em;
  }
}

@media (max-width: 767px) {
  .listOnMobile li {
    text-align: left;
  }
}

@media (min-width: 665px) and (max-width: 768.5px) {
  .listItem {
    width: 9em;
  }

  .loginButton {
    padding: 10px 24%;
    font-size: 0.8em;
  }

  .signUpButton {
    padding: 6% 11%;
    font-size: 0.8em;
  }

  .qrenciaLogo {
    width: 55%;
  }

  .header {
    padding: 0.5em 1em;
  }

  .individualPageHeader {
    padding: 0.5em 1em;
  }
}

@media (min-width: 580px) and (max-width: 665px) {
  .sectionNavItem {
    font-size: 0.8em;
  }

  .BookDemo {
    padding: 6% 9%;
    font-size: 0.65em;
  }

  .header {
    padding: 0.5em 1.2em;
  }

  .individualPageHeader {
    padding: 0.5em 1.2em;
  }

  .hamburger {
    margin: 9px 10px 0 0;
  }

  .listOnMobile {
    top: 3.2em;
  }

  .listItem {
    width: 8em;
  }

  .loginButton {
    padding: 10px 24%;
    font-size: 0.65em;
  }

  .signUpButton {
    padding: 6% 12%;
    font-size: 0.65em;
  }

  .qrenciaLogo {
    width: 55%;
    margin-top: 2%;
  }

  .list {
    padding: 1.5% 0% 2.2% 0%;
  }

  .dropdownToggle {
    font-size: 0.8em;
  }

  .accordionMenu {
    margin-top: 5px;
  }

  .accordionToggle {
    font-size: 0.8em;
  }

  .accordionToggle:hover {
    color: none;
    background: none;
  }

  .accordionItem {
    text-align: left;
    padding-left: 5px;
    font-size: 0.8em;
  }

  .qrenciaLogoSection {
    width: 27%;
  }
}

@media (max-width: 580px) {
  .BookDemo {
    font-size: 0.65em;
    padding: 6% 8%;
  }
}

@media (min-width: 480px) and (max-width: 580px) {
  .sectionNavItem {
    font-size: 0.8em;
  }

  .header {
    padding: 0.5em 1em;
  }

  .individualPageHeader {
    padding: 0.5em 1em;
  }

  .hamburger {
    margin: 7px 8px 0 0;
  }

  .listOnMobile {
    top: 2.9em;
  }

  .listOnMobile .listItem {
    width: 8em;
  }

  .loginButton {
    padding: 9px 27%;
    font-size: 0.65em;
  }

  .signUpButton {
    padding: 6% 9%;
    font-size: 0.65em;
  }

  .qrenciaLogo {
    width: 55%;
    margin-top: 2%;
  }

  .list {
    padding: 1.5% 0% 2.2% 0%;
  }

  .dropdownToggle {
    font-size: 0.8em;
  }

  .accordionMenu {
    margin-top: 5px;
  }

  .accordionToggle {
    font-size: 0.8em;
  }

  .accordionToggle:hover {
    color: none;
    background: none;
  }

  .accordionItem {
    text-align: left;
    padding-left: 5px;
    font-size: 0.8em;
  }

  .qrenciaLogoSection {
    width: 26%;
  }
}

@media (max-width: 517px) {
  .listItem {
    width: 30%;
  }
}

@media (min-width: 481px) and (max-width: 586px) {
  .listItem {
    width: 32%;
  }
}

@media (max-width: 480px) {
  .sectionNavItem {
    font-size: 0.7em;
  }

  .qrenciaLogoSection {
    width: 40%;
  }

  .bar {
    width: 20px;
    height: 2px;
  }

  .header {
    padding: 0.5em 1em;
  }

  .individualPageHeader {
    padding: 0.5em 1em;
  }

  .hamburger {
    margin: 4.5px 8px 0 0;
  }

  .listOnMobile {
    top: 2.7em;
  }

  .listItem {
    width: 6.5em;
  }

  .loginButton {
    padding: 7px 22%;
    font-size: 0.65em;
  }

  .signUpButton {
    padding: 6% 9%;
    font-size: 0.65em;
  }

  .qrenciaLogo {
    width: 55%;
  }

  .list {
    padding: 1.2% 0% 2% 0%;
    gap: 5px;
  }

  .dropdownToggle {
    font-size: 0.7em;
  }

  .accordionMenu {
    margin-top: 5px;
  }

  .accordionToggle {
    font-size: 0.7em;
  }

  .accordionToggle:hover {
    color: none;
    background: none;
  }

  .accordionItem {
    text-align: left;
    padding-left: 5px;
    font-size: 0.7em;
  }
}

@media (max-width: 480px) {
  .listOnMobile {
    top: 2.6em;
  }
}

@media (max-width: 290px) {
  .listItem {
    width: 35%;
  }

  .loginButton {
    padding: 5% 15%;
    font-size: 0.6em;
  }

  .signUpButton {
    padding: 5% 15%;
    font-size: 0.6em;
  }

  .qrenciaLogo {
    width: 55%;
  }
}

@media (min-width: 1143px) and (max-width: 1217px) {
  .list {
    display: flex;
    gap: 0.6rem;
    text-decoration: none;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 2% 0%;
  }
}

@media (min-width: 1143px) and (max-width: 1164px) {
  .sectionNavItem {
    font-size: 0.8em;
  }
}

@media (max-width: 767.5px) {
  .laIcons {
    width: 18px;
    height: 18px;
  }

  .accordionItem {
    font-size: 0.82em;
    margin-top: 2%;
    padding-left: 2%;
  }
}

@media (max-width: 480px) {
  .laIcons {
    width: 17px;
    height: 17px;
  }

  .accordionItem {
    font-size: 0.7em;
    margin-top: 2%;
    padding-left: 2%;
  }
}
