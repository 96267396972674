.faqContainer {
  width: 95%;
  margin: 0em auto;
  padding: 0.5em 0em;
}

.textEditor {
  height: 60%;
  margin-top: -18px;
  font-size: 1.2em;
}

.textEditorLabel {
  background: var(--qrencia-white) !important;
  border: none;
  outline: none;
  font-size: 0.8em;
}

.addedFaqs {
  background-color: var(--qrencia-faq-card-bg);
  border: 1px solid var(--qrencia-nudges-card-border);
  border-radius: 10px;
  padding: 1em 0.6em 0.6em 0.6em;
  margin: 1em auto;
  box-shadow: var(--qrencia-template-box-shadow);
  transition: 0.2s all ease-in-out;
  position: relative;
}

.addedFaqs:hover {
  box-shadow: 0 3px 21px 0 rgba(100, 100, 100, 0.2);
}

.editIcon {
  cursor: pointer;
}

.displayedFaqs {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  padding: 0em 0.2em 0em 0.2em;
}

.questionContainer {
  display: flex;
  justify-content: space-between;
  gap: 1em;
  align-items: flex-start;
}

.iconContainer {
  display: flex;
  align-items: center;
  /* position: absolute;
  right: 2%;
  top: 3%;
  padding-left: 1em; */
}

.iconContainer > svg {
  margin-left: 0.8em;
  margin-bottom: 1em;
  font-size: 0.9em;
  color: var(--qrencia-faq-icon-color);
}

.addNewQuestionText {
  font-size: 0.7em;
  width: max-content;
  text-align: center;
  padding: 0.1em 0.8em;
  background-color: var(--qrencia-addfaq-text-bg);
  border: 1px solid var(--qrencia-nudges-card-border);
  margin: 1em auto;
  border-radius: 2px;
  cursor: pointer;
  color: var(--qrencia-dark-blue);
}

.questions {
  width: 100%;
}

.questionText {
  font-weight: 520;
  font-size: 0.9em;
  flex-grow: 1;
}

.answers {
  font-size: 0.8em;
  text-align: justify;
}
.answers>ul{
  margin-bottom: 0;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  z-index: 10;
}

.addBtn {
  font-size: 0.8em;
  padding: 0.5em 0.8em;
  margin-right: 0.5em;
}

.nextBtn {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 100%;
  padding: 0.5em;
  text-align: right;
}

.nextBtn > Button {
  font-size: 0.8em;
  padding: 0.5em 2em;
}
@media (max-width: 990px) {
  .textEditor p {
    font-size: 1em;
  }
  .answers p {
    font-size: 1em;
  }
}
@media (max-width: 480px) {
  .questionText {
    font-size: 0.8em;
  }

  .answers {
    font-size: 0.75em;
  }

  .iconContainer {
    right: 2%;
    top: 1%;
  }

  .iconContainer > svg {
    font-size: 0.8em;
    margin-bottom: 0em;
  }
}
