.customizeCertificateHeading {
  font-size: 1em;
  margin-top: 1em;
  margin-left: 1em;
  font-weight: 550;
  text-align: left;
  position: relative;
}
.configureCertContainer {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  padding: 1em 0.5em;
}
.inputContainer {
  width: 100%;
  flex: 6.5;
}
.previewContainer {
  width: 100%;
  flex: 6.5;
  margin-top: 1em;
  height: fit-content;
  position: sticky;
  top: 3em;
  padding: 0em 1em;
}
.formLabels {
  margin-top: 0.5em;
  margin-bottom: 0.2em;
  font-size: 0.85em;
  font-weight: 550;
}
.uploadFileRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.uploadFileRow div {
  flex: 9;
}
.removeBtn {
  outline: none;
  background-color: var(--qrencia-white);
  border: none;
  color: var(--qrencia-white);
  margin-left: 0.5em;
  padding: 0.6em 0.2em;
  border-radius: 0.3em;
  width: 2em;
}
.infoButton {
  font-size: 1em;
  color: var(--qrencia-dark-blue) !important;
}
.removeBtn > svg {
  font-size: 1.2em;
  color: var(--qrencia-text-black);
}
.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  z-index: 10;
  max-width: 30em;
}
.inputFields > textarea {
  height: 10em;
  resize: none;
}
.inputContainer input::-webkit-file-upload-button {
  font-weight: 550;
  font-size: 0.9em !important;
}
.errorText {
  font-size: 0.75em;
  color: var(--qrencia-error-red);
}
.certificate {
  margin: 0em;
  aspect-ratio: 4 / 3;
  margin-left: 1em;
  margin-right: 1em;
  border: 1px solid var(--qrencia-dark-blue);
  position: relative;
}
.certificateContent {
  margin-left: 22%;
  padding: 8% 1% 8% 0%;
}
.certificateBackground {
  position: absolute;
  object-fit: contain;
  height: 100%;
  width: 100%;
  z-index: -100;
}
.logoSection {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 12%;
  width: 75%;
}
.certificateLogo {
  width: 16%;
  height: 16%;
  margin: 0.2em 0.6em;
  object-fit: contain;
}

.certificateTitle {
  font-size: 1.6em;
  color: var(--qrencia-dark-blue);
  margin-top: 10%;
  margin-left: 4%;
  font-weight: 600;
}
.presentedToText {
  margin-left: 4%;
  font-weight: 550;
  font-size: 0.8em;
}
.nameText {
  font-size: 1.4em;
  margin-left: 4%;
  margin-top: 2%;
  font-weight: 600;
}
.certificateBody {
  margin-left: 4%;
  margin-top: 2%;
  font-size: 0.8em;
  width: 80%;
  text-align: justify;
}
.certificateMessage {
  margin-left: 4%;
  margin-top: 2%;
  font-size: 0.7em;
  width: 80%;
  text-align: justify;
}
.signatorySection {
  position: absolute;
  bottom: 0.9em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.signatoryCard {
  margin-right: 0.2em;
  width: 20%;
  margin-left: 4%;
}
.signatoryDetails {
  border-top: 1px solid var(--qrencia-text-black);
  padding-top: 0.4em;
  height: 2.6em;
}
.signatoryImage {
  height: 1.6em;
  width: 100%;
  margin-bottom: 0.1em;
}
.signatoryImage > img {
  height: 100%;
}
.signatoryName {
  font-size: 0.7em;
  font-weight: 520;
}
.signatoryDesignation {
  font-size: 0.5em;
  font-weight: 520;
}
.signatoryOrg {
  font-size: 0.5em;
  font-weight: 500;
}

input[type="file"]::file-selector-button {
  font-size: 0.7em !important;
  width: 10em !important;
  margin-left: 0em;
  height: 2.6em;
  line-height: 1.6em;
  align-items: center;
  font-weight: 550;
}
.certificateSignature {
  position: absolute;
  object-fit: contain;
  top: 75%;
  left: 50%;
  height: 3em;
  width: 5em;
}
.nextBtn {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 100%;
  padding: 0.6em;
  text-align: right;
}
.nextBtn > Button {
  font-size: 0.8em;
  padding: 0.6em 2em;
}

@media (min-width: 1601px) and (max-width: 1900px) {
  .certificateContent {
    margin-left: 22%;
    padding: 8% 1% 8% 0%;
  }
  .logoSection {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 11%;
    width: 75%;
  }
  .certificateLogo {
    width: 14%;
    height: 14%;
    margin: 0.2em 0.6em;
    object-fit: contain;
  }
  .certificateTitle {
    font-size: 1.4em;
    color: var(--qrencia-dark-blue);
    margin-top: 6%;
    margin-left: 4%;
    font-weight: 600;
  }
  .presentedToText {
    margin-left: 4%;
    font-size: 0.8em;
  }
  .signatoryImage {
    height: 2em;
    width: 100%;
  }
  .signatoryImage > img {
    height: 100%;
  }
  .nameText {
    font-size: 1.2em;
    margin-left: 4%;
    margin-top: 2%;
    font-weight: 600;
  }
  .certificateBody {
    margin-left: 4%;
    margin-top: 2%;
    font-size: 0.6em;
    width: 80%;
    text-align: left;
  }
  .certificateMessage {
    margin-left: 4%;
    margin-top: 2%;
    font-size: 0.6em;
    width: 80%;
    text-align: left;
  }
  .signatorySection {
    position: absolute;
    bottom: 1.4em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  .signatoryCard {
    margin-right: 0.2em;
    width: 21.5%;
    height: 3.6em;
  }
  .signatoryDetails {
    border-top: 1px solid var(--qrencia-text-black);
    padding-top: 0.2em;
    height: 2.6em;
  }
  .signatoryName {
    font-size: 0.5em;
    font-weight: 520;
  }
  .signatoryDesignation {
    font-size: 0.38em;
    font-weight: 520;
  }
  .signatoryOrg {
    font-size: 0.38em;
    font-weight: 500;
  }
}

@media (max-width: 1600px) {
  .fileInput::-webkit-file-upload-button {
    font-size: 0.7em !important;
  }
  .certificateLogo {
    width: 12%;
    height: 12%;
    margin: 0.2em 0.6em;
    object-fit: contain;
  }
  .certificateTitle {
    font-size: 1em;
    margin-top: 5%;
    margin-left: 4%;
  }
  .presentedToText {
    margin-left: 4%;
    font-weight: 500;
    font-size: 0.5em;
  }
  .nameText {
    font-size: 0.9em;
    margin-left: 4%;
    margin-top: 2%;
    font-weight: 600;
  }
  .certificateBody {
    margin-left: 4%;
    margin-top: 2%;
    font-size: 0.6em;
  }
  .certificateMessage {
    margin-left: 4%;
    margin-top: 1%;
    font-size: 0.5em;
  }
  .certificateContent {
    margin-left: 22%;
    padding: 6% 1% 6% 0%;
  }
  .signatoryName {
    font-size: 0.4em;
    font-weight: 520;
  }
  .signatoryDesignation {
    font-size: 0.22em;
    font-weight: 500;
  }
  .signatoryOrg {
    font-size: 0.22em;
    font-weight: 500;
  }
  .signatoryCard {
    margin-right: 0.2em;
    padding-top: 0.1em;
    width: 21%;
    height: 3.6em;
  }
  .signatoryDetails {
    height: 1em;
  }
}
@media (max-width: 1430px) {
  .configureCertContainer {
    display: block;
  }
  .certificate {
    margin-left: 1em;
    margin-right: 1em;
  }
}
@media (min-width: 991px) and (max-width: 1199px) {
  .configureCertContainer {
    display: block;
  }
  .signatoryName {
    font-size: 0.42em;
    font-weight: 550;
  }
  .signatoryDesignation {
    font-size: 0.3em;
    font-weight: 520;
  }
  .signatoryOrg {
    font-size: 0.3em;
    font-weight: 500;
  }
}

@media (min-width: 760px) and (max-width: 990px) {
  .configureCertContainer {
    display: block;
  }
  .certificate {
    margin: 0em auto;
    margin-top: 1em;
    border: 1px solid var(--qrencia-dark-blue);
    padding: 2em;
    position: relative;
  }
  .customizeCertificateHeading {
    text-align: center;
    margin-bottom: 0em;
    margin-top: 1em;
  }
  .certificate {
    aspect-ratio: 4 / 3;
    padding: 0.2em;
  }
  .certificateContent {
    margin-left: 22%;
    padding: 4% 1% 4% 0%;
  }
  .logoSection {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 8%;
    width: 75%;
  }
  .certificateLogo {
    width: 12%;
    height: 12%;
    margin: 0.2em 0.6em;
    object-fit: contain;
  }

  .certificateTitle {
    font-size: 0.9em;
    color: var(--qrencia-dark-blue);
    margin-top: 6%;
    margin-left: 2%;
    margin-bottom: 6px;
  }
  .presentedToText {
    margin-left: 2%;
    font-weight: 550;
    font-size: 0.5em;
    margin-bottom: 4px;
  }
  .nameText {
    font-size: 0.7em;
    margin-left: 2%;
    margin-top: 0px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  .certificateBody {
    margin-left: 2%;
    margin-top: 0px;
    font-size: 0.8em;
    width: 80%;
    text-align: justify;
  }
  .signatorySection {
    bottom: 0.2em;
  }

  .signatoryCard {
    margin-right: 0.2em;
    width: 21%;

    margin-left: 2%;
  }
  .signatoryDetails {
    height: 2em;
    padding-top: 0.4em;
  }
  .signatoryName {
    font-size: 0.7em;
    font-weight: 520;
  }
  .signatoryDesignation {
    font-size: 0.5em;
    font-weight: 520;
  }
  .signatoryOrg {
    font-size: 0.5em;
    font-weight: 500;
  }
  .signatoryName {
    font-size: 0.3em;
    font-weight: 500;
  }
  .signatoryDesignation {
    font-size: 0.2em;
    font-weight: 500;
  }
  .signatoryOrg {
    font-size: 0.2em;
    font-weight: 500;
  }
}
@media (min-width: 541px) and (max-width: 759px) {
  .signatorySection {
    bottom: 1em;
  }
  .signatoryCard {
    margin-right: 0.2em;
    width: 21%;
    margin-left: 2%;
  }
  .signatoryDetails {
    height: 2em;
    padding-top: 0.4em;
  }
}
@media (max-width: 540px) {
  .nextBtn {
    margin-top: 1.5em;
    width: 100%;
    padding: 0.6em;
    text-align: center;
  }
  .formLabels {
    margin-top: 0.4em;
    margin-bottom: 0.2em;
    font-size: 0.7em;
    font-weight: 550;
  }
  .nextBtn > Button {
    font-size: 0.8em;
    padding: 0.6em 2em;
  }
  .customizeCertificateHeading {
    text-align: center;
    margin-bottom: 0em;
    margin-top: 1em;
  }
  .formLabels {
    margin-bottom: 0.2em;
    font-size: 0.85em;
    font-weight: 550;
  }
  .configureCertContainer {
    display: block;
    width: 100%;
    margin-bottom: 1em;
    padding: 0.5em;
  }
  .errorText {
    font-size: 0.6em;
  }
  input[type="file"]::file-selector-button {
    font-size: 0.7em !important;
    width: 10em !important;
    margin-left: 0em;
    height: 2.6em;
  }
  .certificate {
    aspect-ratio: 4 / 3;
    padding: 0.2em;
  }
  .certificateContent {
    margin-left: 22%;
    padding: 4% 1% 4% 0%;
  }
  .logoSection {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 8%;
    width: 75%;
  }
  .certificateLogo {
    width: 12%;
    height: 12%;
    margin: 0.2em 0.6em;
    object-fit: cover;
  }

  .certificateTitle {
    font-size: 0.8em;
    color: var(--qrencia-dark-blue);
    margin-top: 4%;
    margin-left: 2%;
    margin-bottom: 6px;
  }
  .presentedToText {
    margin-left: 2%;
    font-size: 0.4em;
    margin-bottom: 4px;
  }
  .nameText {
    font-size: 0.7em;
    margin-left: 2%;
    margin-top: 0px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  .certificateBody {
    margin-top: 0px;
    font-size: 0.6em;
    width: 80%;
    text-align: left;
  }
  .signatorySection {
    bottom: 0.2em;
  }

  .signatoryCard {
    margin-right: 0.2em;
    width: 21%;
    margin-left: 2%;
  }
  .signatoryDetails {
    height: 2em;
    padding-top: 0.4em;
  }
  .certificateMessage {
    margin-top: 2%;
    font-size: 0.4em;
    width: 80%;
    text-align: left;
  }
  .signatoryOrg {
    font-size: 0.2em;
    font-weight: 500;
  }
  .signatoryName {
    font-size: 0.3em;
    font-weight: 500;
  }
  .signatoryDesignation {
    font-size: 0.2em;
    font-weight: 500;
  }

  .signatoryCard {
    margin-right: 0.2em;
    width: 21%;
  }
  .signatoryDetails {
    height: 1.3em;
    padding-top: 0.1em;
  }
}
@media (max-width: 480px) {
  .formLabels {
    margin-top: 0.4em;
    margin-bottom: 0.2em;
    font-size: 0.7em;
    font-weight: 550;
  }
  .tooltipMessage {
    max-width: 22em;
  }
}
