.SuggestiveCompetitionSearchInput {
  margin-right: 0.8em;
  width: 30em;
}

#suggestedCompetitionSearch.rbt-menu.dropdown-menu {
  inset: 10px auto auto -31px !important;
  margin-left: 2.2em;
  font-size: 0.9em;
  padding: 0;
  border-radius: 0;
  border: solid 1px var(--qrencia-select-box-border);
}
.suggestiveSearchTypeaheadContainer {
  display: flex;
  width: 100%;
}
.SuggestedSearchInputBox {
  height: 2.31em !important;
  border-right: none !important;
}
.suggestiveSearchTypeaheadContainer .SuggestedSearchInputBox {
  flex: 2;
  border-right: none !important;
  height: 2.31em !important;
}
.suggestiveSearchTypeaheadContainer .SuggestedSearchInputBox:focus,
.suggestiveSearchTypeaheadContainer .SuggestedSearchInputBox:active {
  border-right: none !important;
}
.suggestiveSearchTypeaheadContainer .SuggestedSearchInputBox .form-control {
  border-right: none !important;
}
.suggestedSearchClearIcon {
  cursor: pointer;
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  height: 2.31em;
  margin-top: -0.62em;
  border-left: none;
  cursor: pointer;
  width: 1.2em;
}
#suggestedCompetitionSearchSearch {
  border-right: none;
}
#suggestedCompetitionSearchSearch .dropdown-item:focus,
#suggestedCompetitionSearchSearch .dropdown-item:hover,
#suggestedCompetitionSearchSearch .dropdown-item:active,
#suggestedCompetitionSearchSearch .dropdown-item:visited {
  background: var(--qrencia-select-box-background) !important;
}

.mailFormEditor .ql-container {
  font-size: 0.9em;
  min-height: 20em !important;
}

.SuggestiveCompetitionSearch .suggestedSearchLabel {
  font-weight: 500;
  font-size: 0.8em;
  background-color: var(--qrencia-white);
  margin-left: 3px;
  padding: 0em 0.3em;
  z-index: 1;
  background-image: linear-gradient(
    to bottom,
    var(--qrencia-white),
    var(--qrencia-select-box-background)
  );
  position: relative;
}

.SuggestiveCompetitionSearch .SuggestedSearchInputBox {
  margin-top: -10px;
}

.SuggestiveCompetitionSearch .comppossButton {
  margin-bottom: 2px;
}

@media (max-width: 900px) {
  .SuggestiveCompetitionSearchInput {
    width: 20em;
  }
}
@media (max-width: 480px) {
  .SuggestiveCompetitionSearchInput {
    font-size: 0.8em;
    margin-right: 1em;
  }
  .SuggestiveCompetitionSearchInput {
    width: 18em;
  }
  .suggestedSearchClearIcon {
    cursor: pointer;
    background: var(--qrencia-select-box-background);
    border: solid 1px var(--qrencia-select-box-border);
    height: 2.3em;
    margin-top: -0.78em;
    border-left: none;
    cursor: pointer;
    width: 1.2em;
  }
}
