.competitionActivityMainCard {
  border-radius: 12px;
  border: 1px solid var(--qrencia-activity-cards-border);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.workshopActivityMainCard {
  border-radius: 12px;
  border: 1px solid var(--qrencia-activity-cards-border);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  cursor: pointer;
}
.workshopModeInfoBox {
  display: flex;
  align-items: center;
}
.costIconText {
  font-size: 1.05em;
}
.iconTextContainer {
  display: flex;
  align-items: center;
}
.workshopActivityCardMode {
  width: 50%;
  margin-top: 0.2em;
}

.workshopActivityCardModeIcon {
  margin-top: -0.2em;
  color: var(--qrencia-grey-text);
}

.workshopActivityOrganizer {
  display: flex;
  margin-top: 0.4em;
  font-weight: 500 !important;
  font-size: 0.7em !important;
  color: var(--qrencia-text-black) !important;
}

.workshopActivityOrganizerLink {
  display: flex;
  font-weight: 500 !important;
  color: var(--qrencia-text-black) !important;
}

.activityCardBody {
  padding: 3%;
  text-align: center;
}
.competitionActivityOrganizer {
  margin-top: 0.4em;
  font-weight: 500 !important;
  font-size: 0.7em !important;
  color: var(--qrencia-text-black) !important;
}

.competitionActivityOrganizerLink {
  font-weight: 500 !important;
  color: var(--qrencia-text-black) !important;
}
.workshopActivityCardBody {
  padding: 1% 3% 1% 3%;
  display: flex;
}
.workshopTitleSection {
  display: flex;
}
.workshopCardImageSection {
  width: 30%;
  margin-left: -2em;
  display: block;
  margin-top: 0.7em;
}

.workshopInnerCardImageSection {
  padding: 0.35em;
  background: var(--qrencia-white);
  border-radius: 8px;
  box-shadow: 1px 1px 8px 0px rgba(191, 191, 191, 1);
}

.workshopImage {
  border-radius: 8px;
  /* height: 95px; */
  aspect-ratio: 825/550;
  object-fit: cover;
}

.workshopCardContentSection {
  width: 77%;
  text-align: left;
  padding-left: 1.2em;
  margin-top: 0.5em;
}
.workshopCostIcon {
  margin-top: -0.2em;
}
.workshopActivityCostSection {
  background-color: var(--qrencia-cost-background);
  color: var(--qrencia-text-black);
  display: inline;
  padding: 1% 3%;
  font-size: 0.8em;
  border-radius: 0px 12px 0px 0px;
  top: 0em;
  position: absolute;
  z-index: 110;
  right: 0em;
}
.workshopBanner {
  display: none;
}
.defaultBannerImage {
  height: 90px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  opacity: 0.75;
}

.competitionThumbnailImage {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
  /* height: 112px; */
  width: 170px;
  aspect-ratio: 825/550;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
}

.cardTitle {
  font-weight: 600 !important;
  font-size: 0.9em;
  height: 2.3em;
}

.competitionDetails {
  text-align: center;
  margin-top: 15%;
}

.expertCardImage {
  border-radius: 80px;
  height: 125px;
  width: 125px;
}

.dateContainer {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0.6em auto;
  font-weight: 500;
}

.workshopCostText {
  font-size: 0.7em;
  font-weight: 600;
  color: var(--qrencia-white);
  background-color: var(--qrencia-workshop-differentiator-color);
  border-radius: 5px;
  line-height: 1.6;
  padding: 2px 6px;
  min-width: 4em;
  text-align: center;
}

.workshopCardButton {
  font-size: 0.75em;
  padding: 1%;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  text-align: center;
  font-weight: 500 !important;
  border-radius: 3px;
  margin: 2% 0%;
  width: 6.5em;
}

.workshopDateContainer {
  display: flex;
  margin: 0.6em auto;
  font-weight: 500;
}

.dateText {
  text-align: center;
  font-size: 0.68em;
  font-weight: 450 !important;
}

.workshopDateText {
  text-align: left;
  font-size: 0.68em;
  font-weight: 450 !important;
}

.workshopDateText > svg {
  margin-top: -0.3em;
}

.cardIcon {
  color: var(--qrencia-grey-text);
}

.dateText > svg {
  margin-top: -0.3em;
}

.modeInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5em;
}

.enrolButton {
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 50%);
  font-size: 0.8em;
  min-width: 6.5em;
  padding: 0.2em 1em;
  border-radius: 6px;
}

.privateButton {
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 50%);
  font-size: 0.8em;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-color: var(--qrencia-white);
  color: var(--qrencia-dark-blue);
  padding: 0em !important;
}

.privateButton > svg {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.privateButton:hover,
.privateButton:active,
.privateButton:focus {
  background-color: var(--qrencia-white) !important;
  color: var(--qrencia-dark-blue) !important;
}

.expertMicrositeLinkContainer {
  display: flex;
}

.activityCardTitle {
  font-weight: 700;
  font-size: 0.95em;
  color: var(--qrencia-text-black);
}

.activityCardTitleLink,
.activityCardTitleLink:hover,
.activityCardTitleLink:focus,
.activityCardTitleLink.active {
  color: var(--qrencia-text-black);
  text-decoration: none;
}

.loadMoreButton {
  text-align: end;
}
.workshopModeSection {
  display: flex;
}

.loadMoreIcon {
  font-size: 1.5em;
  cursor: pointer;
}

.exploreMoreButton {
  margin: 1.5em auto;
  font-size: 0.7em;
  text-align: center;
}

.courseActivityCard {
  margin-left: -1%;
  margin-right: -1%;
  display: flex;
  justify-content: flex-start;
}

.workshopActivityCard {
  margin-left: -1%;
  margin-right: -1%;
  display: flex;
  justify-content: flex-start;
}

.courseActivityCardSection {
  margin-bottom: 2%;
}
.workshopActivityCardSection {
  margin-bottom: 1.5%;
}
.shareIconUpcomingCards {
  font-size: 1.2em;
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  border-radius: 20px;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 2px;
  z-index: 110;
}
.shareIconPannelContainerUpcomingCards {
  display: grid;
  position: absolute;
  right: -1.15em;
  top: 15px;
  gap: 5px;
}
.shareOnLinkedIn {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}
.shareOnFacebook {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnTwitter {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareIcons {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnMailButton {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  cursor: pointer;
}

.copyToClipboard {
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  font-size: 0.95em;
  font-weight: bold;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}
.modeInfoBox > svg {
  margin-top: -2px;
}
@media (min-width: 1291px) {
  .courseActivityCardSection {
    margin-bottom: 2%;
    /* width: 33%; */
  }
  .workshopActivityCardSection {
    margin-bottom: 1.5%;
  }
}

@media (min-width: 991px) and (max-width: 1220px) {
  .competitionDetails {
    margin-top: 20%;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .competitionDetails {
    margin-top: 10%;
  }
}

@media (min-width: 360px) and (max-width: 400px) {
  .competitionDetails {
    margin-top: 18%;
  }
}

@media (max-width: 767.5px) {
  .courseActivityCardSection {
    margin-bottom: 8%;
  }
  .workshopActivityCardSection {
    margin-bottom: 8%;
  }
}

@media (min-width: 1129px) and (max-width: 1294.5px) {
  .modeInfoBox {
    background-color: #d6eaf8;
    border-radius: 14px;
    font-size: 0.7em;
    padding: 0.35em 0.5em;
    margin: 1em 0.2em 0em 0.2em;
  }
  .workshopModeInfoBox {
    background-color: var(--qrencia-cost-background);
    border-radius: 14px;
    font-size: 0.7em;
    padding: 0.35em 0.5em;
  }
  .costIconText {
    font-size: 1em;
  }

  .workshopCardButton {
    font-size: 0.7em;
    padding: 1.5%;
    background-color: var(--qrencia-dark-blue) !important;
    color: var(--qrencia-white) !important;
    text-align: center;
    font-weight: 500 !important;
    border-radius: 3px;
  }
}

@media (max-width: 1128px) {
  .modeInfoBox {
    background-color: #d6eaf8;
    border-radius: 14px;
    font-size: 0.62em;
    padding: 0.35em 0.5em;
    margin: 1em 0.2em 0em 0.2em;
  }
  .workshopModeInfoBox {
    background-color: var(--qrencia-cost-background);
    border-radius: 14px;
    font-size: 0.695em;
    padding: 0.35em 0.5em;
  }
  .costIconText {
    font-size: 1em;
  }
}

@media (min-width: 1295px) {
  .modeInfoBox {
    background-color: #d6eaf8;
    border-radius: 14px;
    font-size: 0.7em;
    padding: 0.35em 1em;
    margin: 1em 0.3em 0em 0.3em;
  }
  .workshopModeInfoBox {
    background-color: var(--qrencia-cost-background);
    border-radius: 14px;
    font-size: 0.7em;
    padding: 0.35em 1em;
  }
}

@media (max-width: 490px) {
  .modeInfoBox {
    background-color: #d6eaf8;
    border-radius: 14px;
    font-size: 0.7em;
    padding: 0.35em 0.5em;
    margin: 1em 1em 0em 0.5em;
  }
}

@media (min-width: 992px) and (max-width: 1180px) {
  .workshopCardButton {
    font-size: 0.6em;
    padding: 1.5%;
    width: 4.2em;
  }

  .workshopCostText {
    font-size: 0.5em;
  }
}

@media (max-width: 767.8px) {
  .workshopCardButton {
    padding: 0.5%;
    width: 7.5em;
  }
  .workshopBanner {
    display: block;
  }
  .workshopCardImageSection {
    display: none;
  }
  .workshopCardContentSection {
    width: 100%;
    text-align: left;
    padding-left: 0em;
    margin-top: 3em;
  }
  .workshopActivityMainCard {
    width: 100%;
  }
  .workshopActivityCardBody {
    padding: 1% 3% 2% 3%;
    display: flex;
  }
}
@media (min-width: 581px) and (max-width: 991px) {
  .workshopCardImageSection {
    width: 28%;
  }
}
@media (min-width: 991px) and (max-width: 1286px) {
  .workshopCardImageSection {
    width: 40%;
  }
}

@media (max-width: 400px) {
  .workshopModeInfoBox {
    background-color: var(--qrencia-cost-background);
    border-radius: 14px;
    font-size: 0.6em;
    padding: 0.35em 0.5em;
  }
  .costIconText {
    font-size: 1em;
  }
}
