.partnershipSection {
  padding: 1.7rem 2rem;
  border: 1px solid var(--qrencia-cards-border);
  border-radius: 6px;
}

.partnerHeading {
  color: var(--qrencia-dark-blue);
  font-size: 2.3rem;
  padding-top: 2em;
  font-weight: 700;
  margin-bottom: 2rem;
}

.backgroundImage {
  background-image: url('https://qrencia-b2c-application-bucket-dev.s3.ap-south-1.amazonaws.com/Expression-of-Interest/Partnership-Image.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 635px;
  background-position: bottom;
  background-color: var(--qrencia-white);
}

.partnerButton {
  text-decoration: none;
  letter-spacing: 1px;
  color: var(--qrencia-white);
  background-color: var(--qrencia-beryls-count-purple);
  border-radius: 4px;
  border: 1px solid var(--qrencia-beryls-count-purple);
  font-size: 1em;
  font-weight: 600;
  padding: 0.6% 1.9%;
  margin-bottom: 2rem;
}

.partnerDescription {
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
}

.partnerStrongDescription {
  font-weight: 700;
  color: var(--qrencia-black);
}

@media (max-width: 1200px) {
  .backgroundImage {
    height: 580px;
  }
}

@media (max-width: 1020px) {
  .backgroundImage {
    height: 530px;
  }
}

@media (max-width: 990px) {
  .partnerHeading {
    font-size: 2.1rem;
  }

  .partnerStrongDescription {
    font-weight: 700;
    color: var(--qrencia-black);
  }
}

@media (max-width: 900px) {
  .partnerHeading {
    font-size: 2rem;
  }

  .partnerDescription {
    font-size: 1rem;
  }

  .backgroundImage {
    height: 460px;
  }
}

@media (min-width: 830px) and (max-width: 845px) {
  .partnerHeading {
    font-size: 1.9rem !important;
    padding-top: 1.3em !important;
  }
}

@media (max-width: 830px) {
  .partnerHeading {
    font-size: 1.5rem;
    padding-top: 1em;
    margin-bottom: 1.4rem;
  }

  .partnerDescription {
    font-size: 0.9rem;
  }

  .backgroundImage {
    height: 400px;
  }

  .partnerButton {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 570px) and (max-width: 615px) {
  .partnerDescription {
    font-size: 0.8rem !important;
  }
}

@media (max-width: 570px) {
  .partnerHeading {
    font-size: 1.5rem;
    padding-top: 0em;
    margin-bottom: 0.9rem;
  }

  .partnerContent {
    text-align: center;
  }

  .partnerDescription {
    font-size: 0.9rem;
  }

  .backgroundImage {
    height: 430px;
  }

  .partnerButton {
    margin-bottom: 0.9rem;
  }

  .partnershipSection {
    padding: 1.7rem 2rem;
  }
}

@media (min-width: 544px) and (max-width: 600px) {
  .partnerContent {
    text-align: left;
  }
  .partnershipSection {
    padding: 1.7rem 0rem;
  }
}

@media (max-width: 544px) {
  .partnershipSection {
    padding: 1.7rem 0rem !important;
  }
  .backgroundImage {
    height: 430px;
  }
}

@media (max-width: 480px) {
  .partnerHeading {
    font-size: 1.3rem;
    padding-top: 0em;
    margin-bottom: 1.2rem;
  }
  .partnershipSection {
    padding: 1.7rem 0rem;
  }

  .partnerContent {
    text-align: center;
  }

  .partnerDescription {
    font-size: 0.8rem;
  }

  .backgroundImage {
    height: 410px;
  }

  .partnerButton {
    margin-bottom: 1.5rem;
    padding: 0.6% 5%;
  }
}

@media (max-width: 420px) {
  .backgroundImage {
    height: 390px;
  }
}
