.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item:active,
.dropdown-item:visited {
    color: var(--qrencia-black) !important;
    background-color: var(--qrencia-profile-dropdown-background) !important;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-menu {
    inset: 10px auto auto -31px !important;
}

@media(max-width:1196px) {
    .dropdown-menu {
        inset: 10px auto auto -38px !important;
    }
}

@media(max-width:991px) {
    .dropdown-menu {
        inset: 10px -5px auto auto !important;
    }
}

@media(max-width:767px) {
    .dropdown-menu {
        inset: 16px -20px auto auto !important;
    }

}

@media(max-width:560px) {
    .dropdown-menu {
        inset: 13px -1px auto auto !important;
    }

    .dropdown-menu.show {
        padding: 1%;
    }
}