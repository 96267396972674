.uploadFileRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.displayInline {
  font-size: 0.8em;
  margin-left: 6px;
  display: inline !important;
}

.inlineDisplay {
  font-size: 0.9em;
  display: inline !important;
}

.uploadFileRow div {
  flex: 9;
}

.remainingChars {
  font-size: 0.7em;
  font-weight: 500 !important;
}

.removeBtn {
  outline: none;
  background-color: var(--qrencia-white);
  border: none;
  color: var(--qrencia-white);
  margin-left: 0.5em;
  padding: 0.6em 0.2em;
  border-radius: 0.3em;
  width: 2em;
}

.textEditorContainer {
  height: 100%;
}

.textEditorLabel {
  background: var(--qrencia-white) !important;
  border: none;
  outline: none;
}

.textEditor {
  height: 60%;
  margin-top: -18px;
  font-size: 1.2em;
}

.removeBtn>svg {
  font-size: 1.2em;
  color: var(--qrencia-faq-icon-color);
}

.contactLabel {
  font-weight: 500;
  font-size: 0.8em;
  background-color: var(--qrencia-white);
  margin-left: 12px;
  padding: 0em 0.3em;
  width: 20em;
}

.costLabel {
  font-weight: 500;
  font-size: 0.8em;
  background-color: var(--qrencia-white);
  margin-left: 12px;
  padding: 0em 0.3em;
  width: 10em;
  position: relative;
  z-index: 100 !important;
}

.costContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -10px;
}

.currency {
  border: solid 1px var(--qrencia-select-box-border);
  background: var(--qrencia-select-box-background);
  font-size: 0.9em;
  height: 2.5em;
  line-height: 2.5em;
  padding: 0em 0.5em;
}

.paymentLabel {
  font-weight: 500;
  font-size: 0.8em;
  background-color: var(--qrencia-white);
  margin-left: 12px;
  padding: 0em 0.3em;
  width: 20em;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  z-index: 10;
  max-width: 25em;
}

.nextBtn {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 100%;
  padding: 0.5em;
  text-align: right;
}

.nextBtn>Button {
  font-size: 0.8em;
  padding: 0.5em 2em;
}

.iconError {
  margin-top: -1em;
}

.infoButton {
  color: var(--qrencia-dark-blue);
  margin-left: 0.3em;
}

.weekCalendar {
  margin-left: 1em;
  margin-bottom: 1em;
}

.switchContainer {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.switchText {
  font-size: 0.8em;
  width: fit-content;
  display: flex;
  align-items: center;
}

.switchText input {
  margin-top: 0.25em;
  margin-right: 1em;
}

.switchContainer>label {
  line-height: 1em;
}

@media (max-width: 990px) {
  .textEditor p {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .removeBtn {
    margin-left: 0.2em;
    width: 1.2em;
    font-size: 0.8em;
  }

  .tooltipMessage {
    background-color: var(--qrencia-carousel-blue-image-border);
    font-weight: 500 !important;
    font-size: 0.85em;
    transition: all 0.1s ease;
    outline: none;
    border: none;
    z-index: 10;
    max-width: 22em;
  }
}