.expertCard {
  margin-left: -1%;
  margin-right: -1%;
  display: flex;
  justify-content: flex-start;
}

.expertCardSection {
  margin-bottom: 2%;
}

.expertCardBody {
  padding: 2% 2% 1% 2%;
  min-height: 260px;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid var(--qrencia-activity-cards-border);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
  text-align: center;
  cursor: pointer;
}
.followUnfollowButton {
  margin-top: 6px;
  font-size: 0.7em;
  padding: 2px 14px;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
}
.following {
  color: var(--qrencia-white);
  background-color: var(--qrencia-dark-blue);
}

.follow {
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-white);
  border: 1px solid var(--qrencia-dark-blue);
  font-weight: 500;
}
.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.8em;
  z-index: 100;
  width: 70%;
  opacity: 0.95;
}
.titleFixedHeight {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.expertCardContainer {
  text-align: center;
}
.expertLink {
  text-decoration: none;
}
.cardTitle {
  font-weight: 600 !important;
  font-size: 0.9em;
  height: 2.2em;
}
.expertCardImage {
  border-radius: 80px;
  height: 115px;
  width: 115px;
  margin-top: 2%;
  object-fit: cover;
}
.expertCardImageBorder {
  border: 1px solid var(--qrencia-dark-blue);
}
.academyCardImageBorder {
  border: 1px solid var(--qrencia-academy-card-top-border);
}

.expertMicrositeLinkContainer {
  display: flex;
  margin-bottom: 5%;
}

.expertShareIcon {
  text-align: right;
  width: 50%;
  font-size: 0.95em;
  margin-top: -6px;
}

.topExpertContainer {
  text-align: left;
  width: 50%;
  position: absolute;
}

.topExpertFlagSection {
  background-color: var(--background-light-blue);
  display: inline-flex;
  padding: 2% 4%;
  border-radius: 50px;
  border: 1px solid var(--border-blue-color);
}

.expertBadgeIcon {
  color: var(--qrencia-dark-blue);
  margin-right: 0.2em;
  font-size: 0.75em;
}

.expertBadgeText {
  font-size: 0.58em;
  font-weight: 500 !important;
  margin-top: 0.5%;
}

.expertShareIcon,
.expertShareIcon:hover,
.expertShareIcon:visited {
  color: var(--border-blue-color) !important;
  cursor: pointer;
}

.expertNameContainer {
  padding-left: 0;
  padding-right: 0;
}

.expertCardTitle {
  color: var(--qrencia-dark-blue);
  text-align: center;
  font-weight: 700;
  font-size: 0.95em;
}

.expertCardSubTitle {
  color: var(--qrencia-grey-text);
  font-weight: 500;
  text-align: center;
  font-size: 0.78em;
  margin-top: -1%;
}
.expertCardSubTitleHeight {
  min-height: 3em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.expertCountryFlag {
  width: 11%;
  border: 1px solid var(--qrencia-white);
}

.expertFlagSection {
  margin-top: -4%;
}

.expertShareIconLink,
.expertShareIconLink:hover {
  color: var(--border-blue-color);
}

.loadMoreButton {
  text-align: end;
}

.loadMoreIcon {
  font-size: 1.5em;
  cursor: pointer;
}
.courseActivityCard {
  margin-left: -1%;
  margin-right: -1%;
  display: flex;
  justify-content: flex-start;
}
.courseActivityCardImage {
  height: 160px;
  z-index: 100;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
}
.courseActivityOrganizer {
  display: flex;
  margin-top: 0.4em;
  font-weight: 500 !important;
  font-size: 0.7em !important;
  color: var(--qrencia-text-black) !important;
}
.courseActivityOrganizerLink {
  display: flex;
  font-weight: 500 !important;
  color: var(--qrencia-text-black) !important;
}
.courseActivityClasses {
  display: flex;
  font-size: 0.68em;
  margin-top: 4%;
  font-weight: 500;
}
.exploreMoreButton {
  margin: 0 auto;
  font-size: 0.7em;
  text-align: center;
}
.courseActivityCardsEnrolSection {
  display: flex;
  font-size: 0.68em;
  margin-top: 2%;
  font-weight: 500;
}
.courseActivityCardMode {
  width: 50%;
  margin-top: 0.2em;
}
.courseActivityCardButton {
  padding: 1% 10%;
}
.courseActivityCardModeIcon {
  margin-top: -0.2em;
  color: var(--qrencia-grey-text);
}
.courseActivityCardEnrol {
  width: 50%;
  text-align: right;
  font-size: 1.18em;
}
.courseActivityCardInfoIcon {
  margin-top: 0.2em;
  color: var(--qrencia-grey-text);
}
.courseActivityCardTooltip {
  font-size: 1em !important;
}
.courseActivityCardBody {
  padding: 3%;
}
.courseActivityCostSection {
  background-color: var(--qrencia-cost-background);
  color: var(--qrencia-text-black);
  display: inline;
  padding: 2.5% 7%;
  font-size: 0.8em;
  border-radius: 0px 12px 0px 0px;
  top: 0em;
  position: absolute;
  z-index: 110;
  right: 0em;
}
.courseActivityCardTitle {
  font-weight: 700;
  font-size: 0.95em;
  color: var(--qrencia-text-black);
}
.courseActivityCardTitleLink,
.courseActivityCardTitleLink:hover,
.courseActivityCardTitleLink:focus,
.courseActivityCardTitleLink.active {
  color: var(--qrencia-text-black);
  text-decoration: none;
}
.courseActivityMainCard {
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid var(--qrencia-activity-cards-border);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
  cursor: pointer;
}
.courseActivityCardSection {
  margin-bottom: 2%;
}
@media (min-width: 1291px) {
  .expertCardSection {
    margin-bottom: 2%;
  }
  .courseActivityCardSection {
    margin-bottom: 2%;
  }
}
@media (min-width: 991px) and (max-width: 1290px) {
  .expertShareIcon {
    font-size: 1.2em;
    margin-top: -2%;
    margin-right: 2%;
  }

  .expertCardImage {
    height: 110px;
    width: 110px;
    margin-top: 10%;
  }

  .expertCardTitle {
    font-size: 0.85em;
  }

  .expertCardSubTitle {
    font-size: 0.72em;
  }

  .expertCountryFlag {
    width: 10%;
  }
}

@media (min-width: 768px) and (max-width: 990.5px) {
  .expertShareIcon {
    font-size: 1.35em;
  }

  .expertCardImage {
    height: 110px;
    width: 110px;
  }

  .expertCardTitle {
    font-size: 0.85em;
  }

  .expertCardSubTitle {
    font-size: 0.72em;
  }

  .expertCountryFlag {
    width: 10%;
  }
  .expertCardImage {
    margin-top: 5%;
  }
}

@media (max-width: 767.5px) {
  .expertCardSection {
    margin-bottom: 8%;
  }
  .courseActivityCardSection {
    margin-bottom: 8%;
  }

  .expertShareIcon {
    font-size: 1.2em;
    margin-top: -2%;
    margin-right: 1%;
  }

  .expertCardImage {
    height: 110px;
    width: 110px;
  }

  .expertCardTitle {
    font-size: 0.85em;
  }

  .expertCardSubTitle {
    font-size: 0.72em;
  }

  .expertCountryFlag {
    width: 10%;
  }
}

@media (max-width: 481px) {
  .expertCardTitle {
    font-size: 0.8em;
  }

  .expertCardSubTitle {
    font-size: 0.72em;
  }
}

@media (min-width: 481px) and (max-width: 767.5px) {
  .expertCountryFlag {
    width: 7%;
  }
}
