.popupOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popupContainer {
  position: relative;
  display: inline-block;
}

.popUpImageOnly {
  width: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  position: relative;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.closeIcon {
  position: absolute;
  top: -2%;
  left: 69%;
  transform: translate(0%, 0%);
  font-size: 24px;
  color: var(--qrencia-white);
  background-color: var(--qrencia-text-black);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  z-index: 1100;
}

@media (max-width: 992px) {
  .popUpImageOnly {
    width: 50%;
  }

  .closeIcon {
    position: absolute;
    top: -2%;
    left: 74%;
    transform: translate(0%, 0%);
    font-size: 24px;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    z-index: 1100;
  }
}

@media (max-width: 769px) {
  .popUpImageOnly {
    width: 60%;
  }

  .closeIcon {
    position: absolute;
    top: -2%;
    left: 78%;
    transform: translate(9%, -11%);
    font-size: 24px;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    z-index: 1100;
  }
}

@media (max-width: 561px) {
  .popUpImageOnly {
    width: 70%;
  }

  .closeIcon {
    position: absolute;
    top: -2%;
    left: 82%;
    transform: translate(9%, -11%);
    font-size: 24px;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    z-index: 1100;
  }
}

@media (max-width: 431px) {
  .popUpImageOnly {
    width: 85%;
  }

  .closeIcon {
    position: absolute;
    top: -2%;
    left: 82%;
    transform: translate(122%, -11%);
    font-size: 24px;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    z-index: 1100;
  }
}