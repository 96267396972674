.countDownTimerDateBoxContainer {
  display: flex;
  padding: 2% 0%;
  gap: 0.2em;
}

.countDownTimerDateBox {
  padding: 3.5% 4.4%;
  height: 66px;
  border: 0.01em solid var(--qrencia-logo-border);
  background-color: var(--qrencia-template-background-color);
  color: var(--qrencia-black);
  font-weight: 200;
  font-size: 1.6em;
  box-shadow: -0.03em -0.03em 0.2em var(--qrencia-sepration-line);
}

.countDownTimerDateSeperator {
  color: var(--qrencia-black);
  font-weight: 200;
  font-size: 1.6em;
  padding: 3.5% 1.5%;
  height: 66px;
}

.upcomingQtalksContainer {
  display: flex;
  padding: 6% 13% 5.5% 13%;
  gap: 2;
  position: absolute;
  top: 0;
  justify-content: center;
}

.upcomingQtalksContainerRelative {
  position: relative;
}

.upcomingQtalksWebImage {
  width: 100%;
  height: 600px;
  display: block;
  object-fit: cover;
}

.upcomingQtalksTabImage {
  display: none;
}

.upcomingQtalksMobileImage {
  display: none;
}

.aboutQtalkSection {
  width: 55%;
}

.watchNowSectionContainer {
  width: 38%;
  padding: 0% 0% 1.5% 7%;
  padding-top: 2.5% !important;
}

.watchNow {
  padding: 4% 6%;
}

.watchNowMainContainer {
  background-color: var(--qrencia-white);
  border-radius: 10px;
}

.videoPopupSection {
  width: 50%;
  padding: 0% 2% 0% 4%;
}

.videoPlayIcon {
  margin-top: -13%;
  margin-left: 14%;
  width: 4%;
  position: absolute;
  cursor: pointer;
}

.videoThumbnailImage {
  cursor: pointer;
  width: 94.5%;
  position: relative;
  border-radius: 12px;
}

.orangeFrame {
  margin-bottom: -95px;
}

.blueFrame {
  text-align: right;
  margin-top: -95px;
}

.thumbnailImage {
  text-align: center;
}

.qTalkDateTimeContainer {
  display: flex;
  gap: 0.4em;
}

.qTalkCounterContainer {
  display: block;
}

.qTalkWatchNowButtonContainer {
  padding: 2.5% 6%;
  border-radius: 0px 0px 10px 10px;
  background-color: var(--qrencia-load-more-button-background);
}

.watchMoreQtalks {
  font-size: 0.9em;
  color: var(--qrencia-dark-blue);
  text-align: center;
}

.watchMoreQtalksLink {
  color: var(--qrencia-dark-blue);
  font-family: 600;
}

.qTalkyoutubeIconContainer {
  display: flex;
  text-align: left;
}

.qTalkyoutubeIcon {
  width: 60%;
}

.youtubeImage {
  width: 65%;
  border-right: 1px solid var(--qrencia-dark-grey-border);
}

.watchForFreeTextContainer {
  width: 35%;
  text-align: center;
}

.watchForText {
  color: var(--qrencia-text-black);
  font-size: 0.85em;
  font-weight: 500 !important;
}

.freeText {
  color: var(--qrencia-beryls-count-purple);
  font-size: 2.3em;
  font-weight: 500 !important;
  margin: -7% 0% !important;
}

.qTalktitleText {
  color: var(--qrencia-lemon-yellow-heading);
  font-size: 1.2em;
  line-height: 1.2em;
}

.qTalksubTitle {
  color: var(--qrencia-white);
  line-height: 1.4em;
  font-size: 1.8em;
  margin-top: -1em;
}

.titleLink {
  text-decoration: none;
}

.qTalkdescription {
  color: var(--qrencia-white);
  font-weight: 500;
  font-size: 0.875em;
  margin: 4% 0%;
}

.qtalkSeparatorLine {
  background-color: var(--qrencia-lemon-yellow-heading);
  height: 0.15em;
}

.qTalkpurpleTriangleImage {
  margin-left: -0.8em;
  width: 5%;
}

.speakerContainer {
  margin-top: 4%;
  display: flex;
}

.speakerImageContainer {
  width: 35%;
  margin-top: 1%;
}

.speakerDetailsContainer {
  width: 65%;
}

.qTalkSpeakerImage {
  width: 170px;
  height: 170px;
}

.unOrderedList {
  margin-bottom: 1.5% !important;
  padding-left: 0.9rem !important;
}

.unOrderedList > ul {
  color: white;
  font-size: 0.7em;
  font-weight: 300;
  padding-left: 0% !important;
}

li > span {
  font-weight: 100 !important;
}

.speakerListStyle {
  color: var(--qrencia-white);
  font-size: 0.6em;
  font-weight: 200;
  margin-right: 0.5em;
}

.socialMediaIconStyle {
  color: var(--qrencia-white);
}

.disableLink {
  color: var(--qrencia-white);
  text-decoration: none;
  margin: 0em;
  width: 5.5%;
  margin-right: 0.5em;
}

.disableLinkWebsite {
  color: var(--qrencia-white);
  text-decoration: none;
  margin: 0em;
  width: 9.5%;
  margin-right: 0.5em;
}

.speakerList {
  color: var(--qrencia-white);
  list-style-type: none;
  font-size: 0.78em;
  line-height: 2em;
  margin-left: 0.2em;
  font-weight: 300;
}

.qTalkSpeakerName {
  color: var(--qrencia-lemon-yellow-heading);
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 0%;
  margin-top: 2.5%;
}

.qTalkSpeakerDesignation {
  color: var(--qrencia-white);
  font-size: 0.95em;
  font-weight: 500;
}

.socialMediaIconsContainer {
  display: flex;
  justify-content: start;
  gap: 0em;
  margin-top: -2% !important;
}

.socialMediaIconsItem {
  width: 100%;
}

.qTalkStartTime {
  text-align: left;
  padding: 0% 1%;
  width: 31%;
  font-size: 0.8em;
  bottom: -65px;
  position: relative;
  color: var(--qrencia-grey-text);
}

.qTalkDate {
  padding: 20% 0% 17% 0%;
  background-color: var(--qrencia-card-light-grey-background);
  margin-top: -3.5em;
  text-align: center;
  border-radius: 15px;
  font-size: 0.6em;
  color: var(--qrencia-grey-text);
}

.qTalkDateSection {
  padding-bottom: 2%;
  width: 38%;
}

.month {
  font-size: 1.5em;
  letter-spacing: 0.1em;
  font-weight: 300 !important;
}

.boldDate {
  font-size: 3.1em;
  font-weight: 700;
  letter-spacing: 0.1em;
}

.day {
  font-size: 1em;
  letter-spacing: 0.1em;
  margin-top: 3px;
}

.qTalkEndTime {
  padding: 0% 1%;
  text-align: right;
  width: 31%;
  font-size: 0.8em;
  bottom: -65px;
  position: relative;
  color: var(--qrencia-grey-text);
}

.qTalkshareIconContainer {
  position: absolute;
  top: 125px;
  right: 245px;
}

.qTalkshareIcon {
  font-size: 2em;
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  border-radius: 20px;
  padding: 13% 13%;
}

.shareIconPannelContainer {
  display: block;
  position: absolute;
  width: 5%;
  margin-left: 22%;
}

.shareIcons {
  border-radius: 20px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 2em;
  border-radius: 20px;
  margin-bottom: 4%;
  cursor: pointer;
}

.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  padding: 5% 0% 5% 0%;
  font-size: 1.3em;
  margin-right: 2em;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.qTalkTimeLabels {
  border-bottom: 0.4em solid var(--qrencia-black);
  color: var(--qrencia-grey-text);
  letter-spacing: 0.3em;
}

.qTalkTimeContent {
  margin-top: 5%;
}

span {
  color: var(--qrencia-dark-blue);
  font-weight: 800;
}

.watchNowActive {
  background-color: var(--qrencia-beryls-count-purple);
  cursor: pointer;
  border: none;
}

.watchnowInactive {
  background-color: var(--qrencia-card-light-grey-background);
  background-color: var(--qrencia-card-light-grey-background);
  color: var(--qrencia-white);
  cursor: not-allowed !important;
  border: none;
}

.qTalkCounterSectionLabels {
  display: flex;
}

.counterDaysLabel {
  width: 33.33%;
  text-align: center;
  margin-top: 1.5%;
  padding-right: 4%;
  font-size: 0.75em;
  letter-spacing: 0.1em;
  color: var(--qrencia-grey-text);
}

.counterHoursLabel {
  width: 33.33%;
  margin-top: 1.5%;
  text-align: center;
  font-size: 0.75em;
  padding-right: 3%;
  letter-spacing: 0.1em;
  color: var(--qrencia-grey-text);
}

.counterMinutesLabel {
  width: 33.33%;
  margin-top: 1.5%;
  text-align: center;
  font-size: 0.75em;
  letter-spacing: 0.1em;
  color: var(--qrencia-grey-text);
}

.btnWatchNow {
  padding: 3% 8%;
  margin: 3% 0%;
  color: var(--qrencia-white);
  width: 100%;
  font-size: 1.3em;
  font-weight: 500;
  border-radius: 0px 0px 8px 8px;
  cursor: no-drop;
  border: none;
}

.ViewMoreQtalks {
  color: var(--qrencia-lemon-yellow-heading);
  font-size: 1.2em;
  line-height: 1.2em;
  width: 100%;
  justify-content: end;
  position: absolute;
}

@media (min-width: 1290px) and (max-width: 1445px) {
  .qTalkshareIconContainer {
    top: 115px !important;
    right: 16%;
  }

  .countDownTimerDateBox {
    padding: 3.5% 4.2% !important;
    height: 60px !important;
    font-size: 1.4em !important;
  }
}

@media (min-width: 1145px) and (max-width: 1290px) {
  .shareIconPannelContainer {
    margin-left: 25%;
  }
}

@media (min-width: 1120px) and (max-width: 1145px) {
  .shareIconPannelContainer {
    margin-left: 30%;
  }
}

@media (min-width: 975px) and (max-width: 1119px) {
  .shareIconPannelContainer {
    margin-left: 33%;
  }
}

@media (min-width: 835px) and (max-width: 974px) {
  .shareIconPannelContainer {
    margin-left: 39%;
  }
}

@media (min-width: 767px) and (max-width: 835px) {
  .shareIconPannelContainer {
    margin-left: 38%;
  }
}

@media (min-width: 767px) and (max-width: 1290px) {
  .upcomingQtalksContainer {
    padding: 8% 9% 7% 9%;
  }

  .qTalktitleText {
    font-size: 1.1em;
  }

  .qTalksubTitle {
    font-size: 1.5em;
  }

  .qTalkdescription {
    font-size: 0.8em;
  }

  .boldDate {
    font-size: 2.8em;
  }

  .btnWatchNow {
    font-size: 1.15em;
  }

  .freeText {
    font-size: 2em;
  }

  .countDownTimerDateBox {
    padding: 3.5% 4.2%;
    height: 62px;
    font-size: 1.4em;
  }

  .qTalkStartTime {
    font-size: 0.8em;
    bottom: -65px;
  }

  .qTalkEndTime {
    bottom: -65px;
  }

  .qTalkshareIcon {
    font-size: 1.7em;
  }

  .counterDaysLabel {
    margin-top: 1%;
    font-size: 0.72em;
  }

  .counterHoursLabel {
    margin-top: 1%;
    font-size: 0.72em;
  }

  .counterMinutesLabel {
    margin-top: 1%;
    font-size: 0.72em;
  }
}

@media (min-width: 1180px) and (max-width: 1260px) {
  .countDownTimerDateBox {
    padding: 3.5% 4.1% !important;
    height: 60px !important;
    font-size: 1.4em !important;
  }

  .countDownTimerDateBoxContainer {
    padding: 1% 0% !important;
  }
}

@media (min-width: 1145px) and (max-width: 1179px) {
  .countDownTimerDateBox {
    padding: 3.5% 4% !important;
    height: 60px !important;
    font-size: 1.4em !important;
  }

  .countDownTimerDateBoxContainer {
    padding: 1% 0% !important;
  }
}

@media (min-width: 1120px) and (max-width: 1144px) {
  .countDownTimerDateBox {
    padding: 3.5% 4.5% !important;
    height: 60px !important;
    font-size: 1.4em !important;
  }

  .countDownTimerDateBoxContainer {
    padding: 1% 0% !important;
  }

  .aboutQtalkSection {
    width: 58%;
  }

  .watchNowSectionContainer {
    width: 42%;
    padding: 0% 0% 1.5% 4%;
  }
}

@media (min-width: 1030px) and (max-width: 1119px) {
  .countDownTimerDateBox {
    padding: 3.5% 4.7% !important;
    height: 60px !important;
    font-size: 1.4em !important;
  }
}

@media (min-width: 975px) and (max-width: 1029px) {
  .countDownTimerDateBox {
    padding: 3.5% 4.4% !important;
    height: 60px !important;
    font-size: 1.4em !important;
  }
}

@media (min-width: 975px) and (max-width: 1119px) {
  .disableLink {
    width: 7.5%;
  }

  .qTalkshareIconContainer {
    top: 85px;
    right: 9.8%;
  }

  .countDownTimerDateBoxContainer {
    padding: 1% 0% !important;
  }

  .aboutQtalkSection {
    width: 55% !important;
  }

  .qTalkSpeakerImage {
    width: 155px;
    height: 155px;
  }

  .speakerDetailsContainer {
    padding-left: 2.5%;
  }

  .watchNowSectionContainer {
    width: 45% !important;
    padding: 0% 0% 1.5% 4%;
  }

  .qTalktitleText {
    font-size: 1em !important;
  }

  .qTalksubTitle {
    font-size: 1.5em !important;
  }

  .qTalkpurpleTriangleImage {
    width: 6.8%;
  }

  .qTalkdescription {
    font-size: 0.8em !important;
  }

  .qTalkSpeakerName {
    font-size: 1em !important;
  }

  .qTalkSpeakerDesignation {
    font-size: 0.9em !important;
  }
}

@media (min-width: 767px) and (max-width: 849px) {
  .countDownTimerDateBox {
    padding: 3.5% 4.1% !important;
    height: 60px !important;
    font-size: 1.4em !important;
  }
}

@media (min-width: 767px) and (max-width: 974px) {
  .countDownTimerDateBoxContainer {
    padding: 1% 0% !important;
  }

  .aboutQtalkSection {
    width: 48% !important;
  }

  .qTalkshareIconContainer {
    top: 75px !important;
    right: 9.8%;
  }

  .qTalkSpeakerImage {
    width: 100px;
    height: 100px;
  }

  .speakerDetailsContainer {
    padding-left: 2.5%;
  }

  .watchNowSectionContainer {
    width: 52% !important;
    padding: 0% 0% 1.5% 4%;
  }

  .qTalktitleText {
    font-size: 0.85em !important;
  }

  .qTalksubTitle {
    font-size: 1.35em !important;
  }

  .qTalkdescription {
    font-size: 0.8em !important;
  }

  .qTalkSpeakerName {
    font-size: 0.9em !important;
  }

  .qTalkSpeakerDesignation {
    font-size: 0.85em !important;
  }

  .speakerList {
    font-size: 0.7em;
  }

  .month {
    font-size: 1.2em;
  }

  .boldDate {
    font-size: 2.5em;
  }

  .day {
    font-size: 0.85em;
  }

  .qTalkStartTime {
    bottom: -45px !important;
  }

  .qTalkEndTime {
    bottom: -45px !important;
  }

  .disableLink {
    width: 10.5% !important;
  }

  .qTalksubTitle {
    margin-top: -1em;
  }

  .qTalkpurpleTriangleImage {
    width: 8.8%;
  }
}

@media (min-width: 767px) and (max-width: 780px) {
  .qTalkshareIconContainer {
    top: 62px !important;
    right: 9.8%;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .upcomingQtalksWebImage {
    display: none;
  }

  .upcomingQtalksTabImage {
    width: 100%;
    height: 500px;
    display: block;
    object-fit: cover;
  }

  .upcomingQtalksMobileImage {
    display: none;
  }
}

@media (max-width: 767px) {
  .upcomingQtalksContainer {
    padding: 9% 3% 8% 3%;
  }

  .upcomingQtalksContainer {
    display: block;
  }

  .qTalkpurpleTriangleImage {
    width: 4%;
  }

  .aboutQtalkSection {
    width: 100%;
  }

  .watchNowSectionContainer {
    width: 100%;
    padding: 0% 0% 1.5% 7%;
  }

  .countDownTimerDateBox {
    padding: 3.5% 4.5% !important;
    height: 60px !important;
    font-size: 1.4em !important;
  }

  .countDownTimerDateBoxContainer {
    padding: 1% 0% !important;
  }

  .qTalkshareIconContainer {
    top: 450px !important;
    right: 26.8%;
  }

  .qTalkSpeakerImage {
    width: 100px;
    height: 100px;
  }

  .speakerDetailsContainer {
    padding-left: 2.5%;
  }

  .watchNowSectionContainer {
    width: 100% !important;
    padding: 0% 24% 1.5% 24%;
    margin-top: 8%;
  }

  .qTalktitleText {
    text-align: center;
    font-size: 0.85em !important;
  }

  .qTalksubTitle {
    text-align: center;
    font-size: 1.5em !important;
  }

  .qTalkdescription {
    text-align: center;
    font-size: 0.8em !important;
  }

  .qTalkSpeakerName {
    font-size: 0.9em !important;
  }

  .qTalkSpeakerDesignation {
    font-size: 0.85em !important;
  }

  .speakerList {
    font-size: 0.7em;
  }

  .month {
    font-size: 1.2em;
  }

  .boldDate {
    font-size: 2.5em;
  }

  .day {
    font-size: 0.85em;
  }

  .qTalkStartTime {
    bottom: -45px !important;
  }

  .qTalkEndTime {
    bottom: -45px !important;
  }

  .disableLink {
    width: 7% !important;
  }

  .qTalksubTitle {
    margin-top: -1em;
  }

  .speakerImageContainer {
    width: 30%;
    text-align: end;
    margin-top: 1%;
  }

  .speakerDetailsContainer {
    width: 70%;
  }

  .speakerContainer {
    padding: 0% 16%;
    display: flex;
  }

  .upcomingQtalksWebImage {
    display: none;
  }

  .upcomingQtalksTabImage {
    display: none;
  }

  .upcomingQtalksMobileImage {
    width: 100%;
    height: 850px;
    display: block;
    object-fit: cover;
  }

  .youtubeImage {
    width: 55%;
    border-right: 1px solid var(--qrencia-dark-grey-border);
  }

  .watchForText {
    font-size: 0.8em;
  }

  .freeText {
    font-size: 2em;
  }

  .btnWatchNow {
    font-size: 1em;
  }

  .qTalkshareIcon {
    font-size: 1.5em;
  }

  .shareIconPannelContainer {
    margin-left: 48%;
  }
}

@media (min-width: 680px) and (max-width: 700px) {
  .qTalkshareIconContainer {
    top: 435px !important;
    right: 21.8%;
  }

  .shareIconPannelContainer {
    margin-left: 49% !important;
  }

  .watchNowSectionContainer {
    width: 100% !important;
    padding: 0% 24% 1.5% 24%;
    margin-top: 8%;
  }
}

@media (min-width: 633px) and (max-width: 679px) {
  .qTalkshareIconContainer {
    top: 428px !important;
    right: 21.8%;
  }

  .shareIconPannelContainer {
    margin-left: 58% !important;
  }

  .watchNowSectionContainer {
    width: 100% !important;
    padding: 0% 18% 1.5% 18%;
    margin-top: 8%;
  }
}

@media (min-width: 590px) and (max-width: 632px) {
  .qTalkshareIconContainer {
    top: 418px !important;
    right: 21.8%;
  }

  .watchNowSectionContainer {
    width: 100% !important;
    padding: 0% 18% 1.5% 18%;
    margin-top: 8%;
  }

  .shareIconPannelContainer {
    margin-left: 60% !important;
  }
}

@media (max-width: 589px) {
  .countDownTimerDateBox {
    padding: 3.5% 4.8% !important;
    height: 60px !important;
    font-size: 1.4em !important;
  }

  .shareIconPannelContainer {
    margin-left: 69% !important;
  }

  .watchNowSectionContainer {
    width: 100% !important;
    padding: 0% 12% 1.5% 12%;
  }

  .speakerContainer {
    padding: 0% 1%;
    display: flex;
  }

  .qTalkpurpleTriangleImage {
    margin-left: 2em;
  }

  .upcomingQtalksContainer {
    padding: 6% 3% !important;
  }

  .disableLink {
    width: 6% !important;
  }
}

@media (min-width: 535px) and (max-width: 542px) {
  .qTalkshareIconContainer {
    top: 440px !important;
    right: 6.8%;
  }

  .upcomingQtalksContainer {
    padding: 6% 3% !important;
  }

  .countDownTimerDateBox {
    padding: 3.5% 5.2% !important;
    height: 60px !important;
    font-size: 1.4em !important;
  }
}

@media (min-width: 390px) and (max-width: 401px) {
  .qTalkshareIconContainer {
    top: 458px !important;
  }
}

@media (min-width: 850px) and (max-width: 974px) {
  .countDownTimerDateBox {
    padding: 3.5% 4.5% !important;
    height: 60px !important;
    font-size: 1.4em !important;
  }
}

@media (min-width: 539px) and (max-width: 541px) {
  .qTalkpurpleTriangleImage {
    width: 6% !important;
    margin-left: 0% !important;
  }
}

@media (min-width: 480px) and (max-width: 540px) {
  .qTalksubTitle {
    font-size: 1.3em !important;
  }

  .qTalkpurpleTriangleImage {
    margin-left: 1em;
  }
}

@media (max-width: 480px) {
  .countDownTimerDateBoxContainer {
    padding: 1% 0% 1% 4% !important;
  }

  .watchNowSectionContainer {
    width: 100% !important;
    padding: 0% 4% 1.5% 4%;
    padding-top: 4% !important;
  }

  .qTalkDate {
    padding: 10% 0% 10% 0%;
  }

  .month {
    font-size: 1.2em;
  }

  .boldDate {
    font-size: 1.6em;
  }

  .day {
    font-size: 0.85em;
  }

  .qTalkStartTime {
    bottom: -28px !important;
    font-size: 0.85em;
  }

  .qTalkEndTime {
    bottom: -28px !important;
    font-size: 0.85em;
  }

  .countDownTimerDateBox {
    padding: 2.5% 4.8% !important;
    height: 40px !important;
    font-size: 1em !important;
  }

  .countDownTimerDateSeperator {
    padding: 2.5% 1.5%;
    height: 40px !important;
    font-size: 1.1em !important;
  }

  .counterDaysLabel {
    padding-right: 0;
    font-size: 0.65em;
  }

  .counterHoursLabel {
    padding-right: 0;
    font-size: 0.65em;
  }

  .counterMinutesLabel {
    padding-right: 0;
    font-size: 0.65em;
  }

  .qTalkpurpleTriangleImage {
    width: 7%;
    margin-left: 6%;
  }

  .shareIconPannelContainer {
    margin-left: 74% !important;
  }

  .upcomingQtalksContainer {
    padding: 8% 3% !important;
  }

  .qTalksubTitle {
    text-align: center;
    font-size: 1.12em !important;
  }

  .disableLink {
    width: 8% !important;
  }

  .watchNowSectionContainer {
    width: 100% !important;
    padding: 0% 9% 1.5% 9%;
  }

  .qTalkshareIconContainer {
    top: 375px !important;
    right: 48px !important;
  }

  .shareIcons {
    width: 20px;
    height: 20px;
    margin-right: 0em;
  }

  .showLinkCopiedIcon {
    font-size: 1.2em;
    margin-right: 0em;
  }

  .upcomingQtalksMobileImage {
    width: 100%;
    height: 670px;
  }

  .qTalkdescription {
    text-align: center;
    font-size: 0.68em !important;
  }

  .qTalktitleText {
    text-align: center;
    font-size: 0.8em !important;
  }

  .qTalkSpeakerName {
    font-size: 0.8em !important;
  }

  .qTalkSpeakerDesignation {
    font-size: 0.7em !important;
  }

  .speakerList {
    font-size: 0.6em;
  }

  .unOrderedList > ul {
    font-weight: 300;
  }

  .speakerImageContainer {
    width: 34%;
  }

  .speakerDetailsContainer {
    width: 66%;
  }

  .btnWatchNow {
    font-size: 0.85em;
  }

  .watchMoreQtalks {
    font-size: 0.6em;
  }
}

@media (min-width: 768px) and (max-width: 820px) {
  .qTalksubTitle {
    font-size: 1.2em !important;
  }
}

@media (min-width: 819px) and (max-width: 820px) {
  .qTalksubTitle {
    font-size: 1.3em !important;
  }
}

@media (min-width: 1260px) and (max-width: 1290px) {
  .qTalkshareIconContainer {
    top: 135px;
    right: 160px;
  }
}

@media (min-width: 1170px) and (max-width: 1259px) {
  .qTalkshareIconContainer {
    top: 126px;
    right: 150px;
  }
}

@media (min-width: 1145px) and (max-width: 1169px) {
  .qTalkshareIconContainer {
    top: 121px;
    right: 142px;
  }
}

@media (min-width: 1095px) and (max-width: 1145px) {
  .qTalkshareIconContainer {
    top: 119px;
    right: 112px;
  }
}

@media (min-width: 1119px) and (max-width: 1120px) {
  .qTalkshareIconContainer {
    top: 119px;
    right: 138px;
  }
}

@media (min-width: 1015px) and (max-width: 1094px) {
  .qTalkshareIconContainer {
    top: 113px;
    right: 102px;
  }
}

@media (min-width: 990px) and (max-width: 1015px) {
  .qTalkshareIconContainer {
    top: 105px;
    right: 98px;
  }
}

@media (min-width: 974px) and (max-width: 990px) {
  .qTalkshareIconContainer {
    top: 105px;
    right: 98px;
  }
}

@media (min-width: 930px) and (max-width: 974px) {
  .qTalkshareIconContainer {
    top: 105px !important;
    right: 98px;
  }
}

@media (min-width: 870px) and (max-width: 929px) {
  .qTalkshareIconContainer {
    top: 98px !important;
    right: 94px;
  }
}

@media (min-width: 800px) and (max-width: 869px) {
  .qTalkshareIconContainer {
    top: 88px !important;
    right: 85px;
  }
}

@media (min-width: 767px) and (max-width: 799px) {
  .qTalkshareIconContainer {
    top: 82px !important;
    right: 79px;
  }
}

@media (min-width: 766.1px) and (max-width: 766.8px) {
  .qTalkshareIconContainer {
    top: 461px !important;
    right: 199px !important;
  }
}

@media (min-width: 746px) and (max-width: 766px) {
  .qTalkshareIconContainer {
    top: 468px !important;
    right: 200px !important;
  }
}

@media (min-width: 730px) and (max-width: 746px) {
  .qTalkshareIconContainer {
    top: 480px !important;
    right: 200px !important;
  }
}

@media (min-width: 700.8px) and (max-width: 729.8px) {
  .qTalkshareIconContainer {
    top: 478px !important;
    right: 189px !important;
  }
}

@media (min-width: 678px) and (max-width: 700.8px) {
  .qTalkshareIconContainer {
    top: 469px !important;
    right: 180px !important;
  }
}

@media (min-width: 655px) and (max-width: 678px) {
  .qTalkshareIconContainer {
    top: 485px !important;
    right: 145px !important;
  }
}

@media (min-width: 590px) and (max-width: 655px) {
  .qTalkshareIconContainer {
    top: 453px !important;
    right: 133px !important;
  }
}

@media (min-width: 540px) and (max-width: 590px) {
  .qTalkshareIconContainer {
    top: 417px !important;
    right: 88px !important;
  }
}

@media (min-width: 481px) and (max-width: 540px) {
  .qTalkshareIconContainer {
    top: 403px !important;
    right: 78px !important;
  }
}

@media (max-width: 376px) {
  .qTalkshareIconContainer {
    top: 370px !important;
    right: 46px !important;
  }

  .shareIconPannelContainer {
    margin-left: 73.5% !important;
  }

  .countDownTimerDateBox {
    padding: 3.5% 4.2% !important;
  }
}

@media (max-width: 361px) {
  .shareIconPannelContainer {
    margin-left: 72% !important;
  }

  .countDownTimerDateBox {
    padding: 3.5% 4.2% !important;
  }

  .watchNowSectionContainer {
    width: 100% !important;
    padding: 0% 10% 1.5% 10%;
  }
}

@media (max-width: 415px) {
  .countDownTimerDateBox {
    padding: 2.5% 4.2% !important;
  }
}

@media (max-width: 360px) {
  .countDownTimerDateBox {
    padding: 2.5% 3.8% !important;
  }
}

@media (min-width: 539px) and (max-width: 540px) {
  .qTalkshareIconContainer {
    top: 405px !important;
    right: 82px !important;
  }
}

@media (min-width: 411px) and (max-width: 413px) {
  .qTalkshareIconContainer {
    top: 378px !important;
    right: 51px !important;
  }

  .qTalkpurpleTriangleImage {
    width: 7%;
    margin-left: -2%;
  }
}

@media (min-width: 413px) and (max-width: 415px) {
  .qTalkshareIconContainer {
    top: 366px !important;
    right: 51px !important;
  }

  .qTalkpurpleTriangleImage {
    width: 7%;
    margin-left: -2%;
  }
}

@media (min-width: 358px) and (max-width: 362px) {
  .qTalkshareIconContainer {
    top: 366px !important;
    right: 48px !important;
  }
}

@media (min-width: 465px) and (max-width: 480px) {
  .qTalkshareIconContainer {
    top: 387px !important;
    right: 65px !important;
  }
}

@media (min-width: 420px) and (max-width: 464px) {
  .qTalkshareIconContainer {
    top: 377px !important;
    right: 56px !important;
  }
}

@media (min-width: 450px) and (max-width: 480px) {
  .qTalkshareIconContainer {
    top: 384px !important;
    right: 56px !important;
  }

  .upcomingQtalksMobileImage {
    width: 100%;
    height: 700px;
  }
}
