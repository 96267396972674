.faqInputBox p,
.faqInputBox ul,
.faqInputBox ol {
  font-size: 1em !important;
}
.frequentlyAskedQuestions .ql-editor {
  border: solid 1px var(--qrencia-select-box-border);
  border-top: none !important;
  min-height: 14em;
}
.frequentlyAskedQuestions .ql-toolbar {
  border: solid 1px var(--qrencia-select-box-border);
  background: var(--qrencia-select-box-background);
}
.iconContainer button {
  outline: none;
  border: none;
  background: var(--qrencia-faq-card-bg);
  color: var(--qrencia-text-black);
}
.iconContainer button:hover,
.iconContainer button:active,
.iconContainer button:focus {
  background: var(--qrencia-faq-card-bg) !important;
  color: var(--qrencia-text-black) !important;
}
.iconContainer .dropdown-menu {
  width: 1em !important;
}
