.tabsContainer .nav-tabs {
  background: transparent;
  border-radius: 8px;
  border: none;
  padding: 0.5% 1% 0.5% 0%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  justify-content: end;
}

.tabsContainer .nav-tabs .nav-link {
  font-size: 0.7em;
  color: var(--qrencia-tabs-inactive);
  font-weight: 600;
  border: none !important;
  width: 6.7em;
  margin-bottom: -2.5px !important;
}

.tabsContainer .nav-item {
  padding: 0% 4%;
  background-color: var(--qrencia-white);
  border-radius: 30px;
  margin-right: -3%;
  border: solid 1px var(--qrencia-learning-activity-tabs-border);
}
.tabsContainer .nav-item:last-child {
  padding: 0% 4%;
  /* background-color: var(--qrencia-white); */
  border-radius: 30px;
  z-index: 1;
  margin-right: 0%;
}
.tabsContainer .nav-item:first-child {
  padding: 0% 4%;
  /* background-color: var(--qrencia-white); */
  border-radius: 30px;
  z-index: 4;
}
.tabsContainer .nav-item:nth-child(1) {
  margin-left: 10px;
  margin-right: -5%;
}
.tabsContainer .nav-item:nth-child(2) {
  z-index: 3;
  margin-left: 10px;
  margin-right: -5%;
}
.tabsContainer .nav-item:nth-child(3) {
  z-index: 2;
  margin-left: 10px;
  margin-right: -3%;
}

.tabsContainer .nav-item.active {
  padding: 0% 4%;
  background-color: var(--qrencia-dark-blue);
  border: 1px solid var(--qrencia-dark-blue);
  border-radius: 30px;
}

.tabsContainer .nav-item:has(.nav-link.active) {
  background-color: var(--qrencia-dark-blue);
  border: 1px solid var(--qrencia-dark-blue);
  transition: all 0.3s ease;
}

.tabsContainer .nav-fill .nav-item {
  padding-left: 3%;
  padding-right: 3%;
}

.tabsContainer .nav-tabs .nav-link.active {
  font-size: 0.7em;
  color: var(--qrencia-white);
  font-weight: 500;
}

.tabsContainer .nav-link {
  padding-left: 0.2% !important;
  padding-right: 0.2% !important;
}
@media (min-width: 1291px) and (max-width: 1540px) {
  .tabsContainer .nav-item {
    padding: 0% 4%;
    margin-right: -3.8%;
    height: 1.4em;
  }
  .tabsContainer .nav-tabs .nav-link {
    font-size: 0.58em;
    margin-top: -4px !important;
  }
  .tabsContainer .nav-tabs .nav-link.active {
    font-size: 0.58em;
  }

  .tabsContainer .nav-fill .nav-item {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }
  .tabsContainer .nav-item:nth-child(4) {
    padding-left: 6%;
    margin-left: -5px;
  }
}
@media (min-width: 1240px) and (max-width: 1290px) {
  .tabsContainer .nav-item {
    padding: 0% 4%;
    margin-right: -3.8%;
    height: 1.4em;
  }
  .tabsContainer .nav-tabs .nav-link {
    font-size: 0.58em;
    margin-top: -4px !important;
  }
  .tabsContainer .nav-tabs .nav-link.active {
    font-size: 0.58em;
  }

  .tabsContainer .nav-fill .nav-item {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }
}
@media (min-width: 1200px) and (max-width: 1240px) {
  .tabsContainer .nav-item {
    padding: 0% 4%;
    margin-right: -3.8%;
  }
  .tabsContainer .nav-item {
    padding: 0% 4%;
    margin-right: -4%;
    height: 1.4em;
  }
  .tabsContainer .nav-tabs .nav-link {
    font-size: 0.48em;
    margin-top: -4px !important;
    width: 61px;
  }
  .tabsContainer .nav-tabs .nav-link.active {
    font-size: 0.55em;
  }

  .tabsContainer .nav-fill .nav-item {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }
}
@media (min-width: 1130px) and (max-width: 1200px) {
  .tabsContainer .nav-tabs {
    justify-content: flex-start;
  }

  .tabsContainer .nav-item {
    padding: 0% 4%;
    margin-right: -4%;
    height: 1.4em;
  }
  .tabsContainer .nav-tabs .nav-link {
    font-size: 0.55em;
    margin-top: -4px !important;
    width: 62px !important;
  }
  .tabsContainer .nav-tabs .nav-link.active {
    font-size: 0.55em;
  }

  .tabsContainer .nav-fill .nav-item {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }
  .tabsContainer .nav-item:nth-child(1) {
    margin-left: 1px;
  }
  .tabsContainer .nav-item:nth-child(2) {
    margin-left: 12px;
  }
  .tabsContainer .nav-item:nth-child(3) {
    margin-left: 10px;
    padding-left: 5%;
  }
  .tabsContainer .nav-item:nth-child(4) {
    padding-left: 5%;
  }
}

@media (min-width: 1100px) and (max-width: 1130px) {
  .tabsContainer .nav-item {
    margin-right: -3.8%;
    height: 1.4em;
  }
  .tabsContainer .nav-tabs .nav-link {
    font-size: 0.52em;
    margin-top: -4px !important;
    width: 62px;
  }
  .tabsContainer .nav-tabs .nav-link.active {
    font-size: 0.52em;
  }
  .tabsContainer .nav-tabs {
    justify-content: flex-start;
  }
  .tabsContainer .nav-fill .nav-item {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }
}
@media (min-width: 768px) and (max-width: 1100px) {
  .tabsContainer .nav-tabs {
    justify-content: flex-start;
  }
}
@media (min-width: 991px) and (max-width: 1099px) {
  .tabsContainer .nav-item {
    margin-right: -3.8%;
    height: 1.4em;
  }
  .tabsContainer .nav-tabs .nav-link {
    font-size: 0.56em;
    margin-top: -4px !important;
    width: 61px;
  }
  .tabsContainer .nav-tabs .nav-link.active {
    font-size: 0.52em;
  }

  .tabsContainer .nav-tabs {
    margin-bottom: 0%;
  }
}
@media (max-width: 990px) {
  .tabsContainer .nav-item {
    margin-right: -3.8%;
    height: 1.6em;
  }
  .tabsContainer .nav-tabs .nav-link {
    font-size: 0.56em;
    margin-top: -3px !important;
    width: 62px;
  }
  .tabsContainer .nav-tabs .nav-link.active {
    font-size: 0.56em;
  }

  .tabsContainer .nav-tabs {
    margin-bottom: 0%;
  }
}
@media (max-width: 768px) {
  .tabsContainer .nav-item {
    margin-right: -3.8%;
    height: 1.4em;
  }
  .tabsContainer .nav-tabs .nav-link {
    font-size: 0.48em;
    margin-top: -4px !important;
    width: 61px;
  }
  .tabsContainer .nav-tabs .nav-link.active {
    font-size: 0.5em;
  }

  .tabsContainer .nav-tabs {
    margin-bottom: -2%;
  }
  .tabsContainer .nav-item:nth-child(1) {
    margin-left: 4px;
  }
  .tabsContainer .nav-item:nth-child(2) {
    margin-left: 7px;
  }
  .tabsContainer .nav-item:nth-child(3) {
    margin-left: 6px;
  }
}

@media (max-width: 675px) {
  .tabsContainer .nav-item {
    margin-right: -3.8%;
    height: 1.6em;
  }
  .tabsContainer .nav-tabs .nav-link {
    font-size: 0.56em;
    margin-top: -3px !important;
    width: 62px;
  }
  .tabsContainer .nav-tabs .nav-link.active {
    font-size: 0.56em;
  }

  .tabsContainer .nav-tabs {
    margin-bottom: 0%;
  }
  .tabsContainer .nav-tabs {
    justify-content: flex-start;
  }
  .tabsContainer .nav-item:nth-child(1) {
    margin-left: 1px;
  }
  .tabsContainer .nav-item:nth-child(2) {
    margin-left: 12px;
  }
  .tabsContainer .nav-item:nth-child(3) {
    margin-left: 8px;
  }
}

@media (max-width: 480px) {
  .tabsContainer .nav-tabs {
    margin-bottom: 5%;
  }

  .tabsContainer .nav-fill .nav-item {
    padding-left: 2% !important;
    padding-right: 2% !important;
  }

  .tabsContainer .nav-tabs .nav-link {
    font-size: 0.55em !important;
    width: 62px;
    margin-bottom: -2px !important;
  }

  .tabsContainer .nav-tabs .nav-link.active {
    font-size: 0.6em !important;
  }

  .tabsContainer .nav-item {
    margin-right: -4%;
  }
}
@media (max-width: 400px) {
  .tabsContainer .nav-tabs {
    margin-bottom: 5%;
  }

  .tabsContainer .nav-fill .nav-item {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }

  .tabsContainer .nav-tabs .nav-link {
    font-size: 0.58em !important;
    margin-bottom: -2px !important;
    width: 62px;
  }

  .tabsContainer .nav-tabs .nav-link.active {
    font-size: 0.58em !important;
  }

  .tabsContainer .nav-item {
    margin-right: -6%;
  }
  .tabsContainer .nav-item:nth-child(1) {
    margin-left: 4px;
  }
  .tabsContainer .nav-item:nth-child(2) {
    margin-left: 6px;
  }
  .tabsContainer .nav-item:nth-child(3) {
    margin-left: 4px;
  }
}
