body {
  background-color: var(--qrencia-white);
}

.privacyPolicyMainContainer {
  width: 100%;
  padding: 0% 10%;
  background-color: var(--qrencia-white);
  border-top: 1px solid var(--qrencia-grey-border);
  background-repeat: no-repeat;
}

.privacyPolicyMainContainer .privacyPolicySubContainer {
  width: 100%;
  padding: 4% 0%;
  height: auto;
}

.privacyPolicyMainContainer .privacyPolicySubContainer .privacyPolicyHeading {
  width: 100%;
  text-align: center;
}

.privacyPolicyHeadingTitle>p>span,.privacyPolicyDate>p>span,.privacyPolicyDescription>p>span {
  font-weight: 600!important;
  color: var(--qrencia-dark-blue);
  font-size: 1.2em;
}
.privacyPolicyHeadingTitle>h1>span>u>strong,.privacyPolicyDate>h1>span>u>strong,.privacyPolicyDescription>h1>span>u>strong {
  font-weight: 600;
  color: var(--qrencia-dark-blue);
}

.privacyPolicyHeadingTitle>p>strong,.privacyPolicyDate>p>strong,.privacyPolicyDescription>p>strong {
  font-weight: 500;
}

.privacyPolicyMainContainer
  .privacyPolicySubContainer
  .privacyPolicyHeading
  .privacyPolicyHeadingTitle {
  width: auto;
  float: none;
  text-align: center;
  color: var(--qrencia-dark-blue);
  font-weight: 600;
  display: inline-block;
  border-bottom: 3.5px solid var(--qrencia-dark-blue);
  padding-bottom: 4px;
  font-size: 28px;
}

.privacyPolicyMainContainer .privacyPolicySubContainer .privacyPolicyDate {
  width: 100%;
  text-align: right;
  margin-top: 1%;
  font-size: 0.9em;
  color:var(--qrencia-text-black);
  margin-bottom: 20px;
}

.privacyPolicyMainContainer
  .privacyPolicySubContainer
  .privacyPolicyDescription {
  margin-bottom: 24px;
}


@media (max-width: 480px) {
  .privacyPolicyMainContainer .privacyPolicySubContainer {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .privacyPolicyMainContainer
    .privacyPolicySubContainer
    .privacyPolicyHeading
    .privacyPolicyHeadingTitle {
      font-size: 1.3em;
    }
  p {
    font-size: 12px;
  }
  .privacyPolicyDescription>ul>li {
    font-size: 0.8em;
  }
  .privacyPolicyMainContainer .privacyPolicySubContainer .privacyPolicyDate {
    font-size: 0.8em;
  }
  
  .privacyPolicyHeadingTitle>p>strong,.privacyPolicyDate>p>strong,.privacyPolicyDescription>p>strong {
    font-weight: 500;
    font-size: 0.8em;
  }

  .privacyPolicyHeadingTitle>p>strong,.privacyPolicyDate>p>strong,.privacyPolicyDescription>p>strong {
    font-size: 16px;
  }
  .privacyPolicyHeadingTitle>h1>span>u>strong,.privacyPolicyDate>h1>span>u>strong,.privacyPolicyDescription>h1>span>u>strong {
    font-size: 16px;
  }
  .privacyPolicyHeadingTitle>p>span,.privacyPolicyDate>p>span,.privacyPolicyDescription>p>span {
    font-size: 1.1em;
  }
}
@media (min-width: 992px) {
  .privacyPolicyMainContainer {
    text-align: justify;
  }
}
@media (min-width: 1500px) {
  .privacyPolicyMainContainer {
    padding: 0% 10%;

  }
}
@media(max-width:767px){
  .privacyPolicyMainContainer {
    padding: 0% 5%;
  }
}
@media(min-width:767px) and (max-width:991px){
  .privacyPolicyMainContainer
  .privacyPolicySubContainer
  .privacyPolicyHeading
  .privacyPolicyHeadingTitle{
  font-size: 1.5em;
}

p {
  font-size: 0.9em;
}
.privacyPolicyDescription>ul>li {
  font-size: 0.9em;
}
.privacyPolicyDescription>ol>li {
  font-size: 0.9em;
}

.privacyPolicyHeadingTitle>p>strong,.privacyPolicyDate>p>strong,.privacyPolicyDescription>p>strong {
  font-size: 16px;
}
.privacyPolicyHeadingTitle>h1>span>u>strong,.privacyPolicyDate>h1>span>u>strong,.privacyPolicyDescription>h1>span>u>strong {
  font-size: 20px;
}
.privacyPolicyHeadingTitle>p>span,.privacyPolicyDate>p>span,.privacyPolicyDescription>p>span {
  font-size: 1.2em;
}
}

@media(min-width:991px) and (max-width:1290px){
  .privacyPolicyMainContainer
  .privacyPolicySubContainer
  .privacyPolicyHeading
  .privacyPolicyHeadingTitle {
  font-size: 1.5em;
}

p {
  font-size: 0.95em;
}
.privacyPolicyDescription>ul>li {
  font-size: 0.95em;
}
.privacyPolicyDescription>ol>li {
  font-size: 0.95em;
}

.privacyPolicyHeadingTitle>p>strong,.privacyPolicyDate>p>strong,.privacyPolicyDescription>p>strong {
  font-size: 16px;
}
.privacyPolicyHeadingTitle>h1>span>u>strong,.privacyPolicyDate>h1>span>u>strong,.privacyPolicyDescription>h1>span>u>strong {
  font-size: 20px;
}
.privacyPolicyHeadingTitle>p>span,.privacyPolicyDate>p>span,.privacyPolicyDescription>p>span {
  font-size: 1.2em;
}
}
