.header {
  margin-bottom: 1.2%;
  display: flex;
  background: var(--qrencia-white);
}

.statusWrapper {
  border-radius: 10px;
  text-align: center;
  padding: 0 5px;
  margin-top: -2em;
  position: absolute;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  color: var(--qrencia-text-black);
  font-weight: bold;
  text-decoration: none;
  border: 2px solid transparent;
  z-index: 1;
  transition: all 0.3s ease-in-out;

}

.statusWrapper::before {
  content: "";
  position: absolute;
  inset: -2px;
  border-radius: 5px;
  background: linear-gradient(45deg, var(--qrencia-button-pink), var(--qrencia-golden-color), var(--qrencia-blue-background-course-cost));
  background-size: 300%;
  z-index: -1;
  animation: animateBorder 4s ease infinite;
}

.statusWrapper::after {
  content: "";
  position: absolute;
  inset: 0;
  /* Matches button background */
  border-radius: 6px;
  z-index: -1;
}

@keyframes animateBorder {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.statusChipText {
  font-weight: 500;
  font-size: 0.6rem;
}

.myProfileContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}

.subscribedBorder {
  border-bottom: 3px solid goldenrod;
}

.profilePageHeader {
  margin-bottom: 0%;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.profileName {
  display: inline-block;
}

.logoSection {
  border-right: 1px solid var(--qrencia-logo-border);
  padding: 1.1% 2.2% 1% 1.3%;
  width: 16%;
}

.newLabelButtonIcons {
  color: var(--qrencia-white) !important;
}

.showStatusOnMobile {
  display: block !important;
}

.logo {
  width: 52%;
}

.hideListOnMobile {
  width: 22%;
}

.listSection {
  margin-block: auto;
  width: 56%;
}

.list {
  display: flex;
  border: none;
  margin: 0%;
  padding-left: 3%;
  list-style-type: none;
  font-weight: 600;
  align-items: center;
}

.list a {
  text-decoration: none;
  color: var(--qrencia-text-black);
  font-size: 0.88em;
  font-weight: 500;
}

.list a.active {
  border-bottom: 4px solid var(--qrencia-dark-blue) !important;
  color: var(--qrencia-dark-blue);
  padding-bottom: 20px;
}

.list a.active .linkIcons {
  color: var(--qrencia-dark-blue);
}

.linkIcons {
  font-size: 1.4em;
  color: var(--qrencia-icons);
}

.profileSection {
  width: 13.5%;
  display: flex;
  margin-block: auto;
  justify-content: left;
}

.profileDropdownItem {
  display: flex;
}

.profileDropdownOption {
  font-size: 0.9em;
  text-decoration: none;
  color: var(--qrencia-text-black);
}

.profileDropdownOption:hover,
.profileDropdownOption:active,
.profileDropdownOption:focus {
  font-size: 0.9em;
  text-decoration: none !important;
  color: var(--qrencia-text-black) !important;
}

.profileDropdownOptionIcon {
  font-size: 1em;
  margin-top: 2.5%;
  margin-right: 3%;
  color: var(--qrencia-dark-blue);
}

.profilePopoverButton {
  font-size: 0.86em;
  background-color: transparent !important;
  color: var(--qrencia-user-name) !important;
  font-weight: 500;
  border: none;
  outline: none;
  margin-top: 6%;
}

.newLabelButton {
  background-color: var(--qrencia-dark-blue);
  color: var(--qrencia-white) !important;
  padding: 3% 6%;
  border-radius: 6px;
}

.profilePopoverButton:hover,
.profilePopoverButton:focus,
.profilePopoverButton:active {
  background-color: transparent !important;
  color: var(--qrencia-user-name) !important;
  font-weight: 500;
  border: none !important;
  margin: 0;
  margin-top: 6%;
}

.profileImage {
  border-radius: 50px;
  height: 34px;
  width: 34px;
  display: block;
  object-fit: cover;
}

.buttonSection {
  width: 10.5%;
  margin-block: auto;
  display: block;
}

.alignRight {
  margin-left: auto;
}

.referButton {
  font-size: 0.7em;
  padding: 4% 9%;
  font-weight: 500;
}

.notificationSection {
  width: 4.5%;
  margin-block: auto;
  padding-left: 0.6%;
  cursor: pointer;
}

.userInitials {
  font-weight: 500;
  background-color: var(--qrencia-dark-blue);
  padding: 14% 0%;
  text-align: center;
  color: var(--qrencia-white);
  border-radius: 50px;
  height: 33px;
  width: 34px;
  display: block;
  font-size: 1em;
}

.notificationBadge {
  position: absolute;
  border-radius: 9px;
  font-size: 0.58em;
  margin-left: -11.5px;
  margin-top: -2.2px;
  width: 16px;
  padding: 0.22% 0.28%;
  font-weight: 500;
}

.notificationImage {
  font-size: 1.4em;
  color: var(--qrencia-icons);
}

.popoverHeader {
  font-size: 1em;
  font-weight: 500;
}

.popoverBody {
  font-size: 1em;
  font-weight: 400;
}

.profilePageLogoSection {
  text-align: left;
  width: 15%;
}

/* Media Quries */
@media (min-width: 1490px) {
  .list a.active {
    padding-bottom: 22px;
  }
}

@media (max-width: 1372px) {
  .list {
    padding-left: 2%;
  }
}

@media (max-width: 1351px) {
  .hideListOnMobile {
    width: 21%;
  }
}

@media (min-width: 991.1px) and (max-width: 1286px) {
  .buttonSection {
    width: 15%;
    text-align: right;
    padding-right: 1%;
  }

  .profileDropdownOption {
    font-size: 0.85em;
  }

  .listSection {
    width: 46%;
  }

  .notificationSection {
    width: 5%;
  }

  .profileSection {
    width: 18%;
    padding-left: 1%;
  }

  .logoSection {
    width: 16%;
  }

  .logo {
    width: 60%;
  }

  .list {
    padding-left: 6%;
    width: 125%;
  }

  .list a {
    font-size: 0.72em;
  }

  .linkIcons {
    font-size: 1.4em;
  }

  .list a.active {
    padding-bottom: 19px;
  }

  .profilePopoverButton {
    font-size: 0.82em;
  }

  .referButton {
    font-size: 0.7em;
    padding: 3%;
  }

  .userInitials {
    font-size: 0.95em;
    padding: 16% 0%;
  }
}

@media (max-width: 1231px) {
  .hideListOnMobile {
    width: 21%;
  }

  .list {
    padding-left: 3%;
    width: 125%;
  }
}

@media (max-width: 1200px) {
  .hideListOnMobile {
    width: 24%;
  }
}

@media (max-width: 1193px) {
  .hideListOnMobile {
    width: 22%;
  }
}

@media (max-width: 1129px) {
  .list {
    width: 130%;
  }
}

@media (max-width: 1094px) {
  .list {
    width: 130%;
  }
}

@media (min-width: 1141px) and (max-width: 1286px) {
  .list a {
    font-size: 0.76em;
  }
}

@media (max-width: 1045px) {
  .list a {
    font-size: 0.7em;
  }

  .list {
    width: 136%;
  }
}

@media (min-width: 991px) and (max-width: 1030px) {
  .listSection {
    width: 46% !important;
  }
}

@media (min-width: 990px) and (max-width: 1165px) {
  .list a.active {
    padding-bottom: 14px;
  }
}

@media (min-width: 912px) and (max-width: 991.5px) {
  .userInitials {
    font-size: 0.95em;
    padding: 16% 0%;
  }

  .profileDropdownOption {
    font-size: 0.83em;
  }

  .logoSection {
    width: 24%;
  }

  .listSection {
    width: 56%;
  }

  .profileSection {
    width: 20%;
    padding-left: 1%;
  }

  .logo {
    width: 45%;
  }

  .list {
    gap: 1rem;
    font-weight: 500;
    padding-left: 6%;
    width: 100%;
  }

  .list a {
    font-size: 0.9em;
  }

  .linkIcons {
    font-size: 1.4em;
  }

  .list a.active {
    padding-bottom: 12px;
  }

  .profilePopoverButton {
    font-size: 0.8em;
  }

  .referButton {
    font-size: 0.6em;
    padding: 6% 3%;
  }
}

@media (min-width: 820px) and (max-width: 912px) {
  .profileDropdownOption {
    font-size: 0.83em;
  }

  .logoSection {
    width: 24%;
  }

  .logo {
    width: 45%;
  }

  .listSection {
    width: 54%;
  }

  .list {
    gap: 1rem;
    font-weight: 500;
    padding-left: 6%;
    width: 100%;
  }

  .list a {
    font-size: 0.8em;
  }

  .linkIcons {
    font-size: 1.4em;
  }

  .profileSection {
    width: 22%;
    padding-left: 1%;
  }

  .list a.active {
    padding-bottom: 13px;
  }

  .profilePopoverButton {
    font-size: 0.75em;
  }

  .referButton {
    font-size: 0.6em;
    padding: 6% 3%;
  }
}

@media (max-width: 820px) {
  .profileDropdownOption {
    font-size: 0.83em;
  }

  .logoSection {
    width: 24%;
  }

  .logo {
    width: 50%;
  }

  .listSection {
    width: 55%;
  }

  .list {
    gap: 1rem;
    font-weight: 500;
    padding-left: 6%;
    width: 100%;
  }

  .list a {
    font-size: 0.75em;
  }

  .linkIcons {
    font-size: 1.4em;
  }

  .profileSection {
    width: 21%;
    padding-left: 1%;
  }

  .list a.active {
    padding-bottom: 9px;
  }

  .profilePopoverButton {
    font-size: 0.78em;
  }

  .referButton {
    font-size: 0.55em;
    padding: 6% 0.5%;
  }
}

@media (min-width: 767px) and (max-width: 991.5px) {
  .buttonSection {
    display: none;
  }

  .notificationSection {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.5px) {
  .logo {
    width: 55%;
  }

  .profileSection {
    width: 80%;
    justify-content: end;
    padding-right: 2%;
  }

  .logoSection {
    padding: 1.5% 2% 1.5% 1%;
    width: 20%;
  }
}

@media (max-width: 992px) {
  .profileSection {
    width: 60%;
  }
}

@media (min-width: 690px) and (max-width: 767.5px) {
  .profileName {
    display: none;
  }

  .buttonSection {
    display: none;
  }

  .logoSection {
    padding: 1.5% 2% 1.5% 1%;
    width: 26%;
  }

  .logo {
    width: 55%;
  }

  .notificationSection {
    display: none;
  }

  .profileSection {
    width: 80%;
    justify-content: end;
    padding-right: 4%;
  }

  .list a.active {
    padding-bottom: 13px;
  }

  .notificationBadge {
    padding: 0.5% 0.35%;
  }
}

@media (max-width: 886px) {
  .profileSection {
    width: 36%;
  }
}

@media (min-width: 480px) and (max-width: 690px) {
  .profileName {
    display: none;
  }

  .buttonSection {
    display: none;
  }

  .logoSection {
    padding: 1.5% 2% 1.5% 1%;
    width: 30%;
  }

  .logo {
    width: 55%;
  }

  .notificationSection {
    display: none;
  }

  .profileSection {
    width: 75%;
    justify-content: end;
    padding-right: 4%;
  }

  .list a.active {
    padding-bottom: 12.5px;
  }

  .notificationImage {
    font-size: 1.25em;
  }
}

@media (max-width: 757px) {
  .profileSection {
    width: 25%;
  }
}

@media (max-width: 480px) {
  .profileName {
    display: none;
  }

  .userInitials {
    font-size: 0.75em;
    padding: 19% 0%;
  }

  .profileDropdownOption {
    font-size: 0.8em;
  }

  .profileDropdownOptionIcon {
    font-size: 0.85em;
  }

  .notificationImage {
    font-size: 1.25em;
  }

  .buttonSection {
    display: none;
  }

  .logoSection {
    padding: 3% 2% 3% 1%;
    width: 45%;
  }

  .logo {
    width: 55%;
  }

  .profilePageHeader .log {
    width: 60%;
  }

  .profileSection {
    width: 74%;
    justify-content: end;
    padding-right: 2%;
  }

  .userInitials {
    height: 30px;
    width: 30px;
  }

  .profileImage {
    height: 30px;
    width: 30px;
  }

  .profilePopoverButton {
    font-size: 0.6em;
  }

  .notificationImage {
    font-size: 1.1em;
  }

  .notificationSection {
    display: none;
  }
}

@media (max-width: 376px) {
  .profileName {
    display: none;
  }

  .notificationImage {
    font-size: 1.25em;
  }

  .notificationBadge {
    padding: 0.7% 0.35%;
  }

  .buttonSection {
    display: none;
  }

  .logoSection {
    padding: 3% 2% 3% 1%;
    width: 45%;
  }

  .profilePageLogoSection {
    text-align: center !important;
    width: 7em;
  }

  .logo {
    width: 55%;
  }

  .profilePageLogoSection .logo {
    width: 75%;
  }

  .profileSection {
    width: 74%;
    justify-content: end;
    padding-right: 2%;
  }

  .userInitials {
    height: 26px;
    width: 26px;
  }

  .profileImage {
    height: 26px;
    width: 26px;
  }

  .profilePopoverButton {
    font-size: 0.6em;
  }

  .popoverHeader {
    font-size: 0.65em;
    font-weight: 500;
    padding: 6%;
  }

  .popoverBody {
    font-size: 0.65em;
    font-weight: 400;
    padding: 6%;
  }

  .notificationImage {
    font-size: 1.1em;
  }
}

@media (max-width: 991.5px) {
  .logoSection {
    text-align: end;
  }

  .profilePageHeader .logoSection {
    text-align: center;
  }

  .hideListOnMobile {
    display: none;
  }
}

@media (max-width: 609px) {
  .profileSection {
    width: 20%;
  }
}

@media (min-width: 480px) and (max-width: 600px) {
  .logoSection {
    padding: 2.5% 2% 2.5% 1%;
    width: 45%;
  }
}

@media (max-width: 641px) {
  .logoSection {
    width: 25%;
  }
}

@media (max-width: 521px) {
  .list {
    padding-left: 3%;
  }
}

@media (max-width: 505px) {
  .list {
    gap: 1rem;
  }
}

@media (max-width: 472px) {
  .list {
    gap: 1rem;
  }
}

@media (max-width: 372px) {
  .list {
    padding-left: 1.4%;
  }
}