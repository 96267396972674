.tabContentHeading {
  margin: 4% 2% 1% 1%;
  font-weight: 500 !important;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardContainer {
  width: 60vw;
  border: none;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.interestCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  background-color: var(--qrencia-white) !important;
  min-height: 10vh;
}

.skillContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
  padding-bottom: 4%;
}
.skillContainerBox {
  min-height: 25vh;
  max-height: fit-content;
}

.selectedSkillPill {
  width: max-content;
}

.selectedSkillSection {
  display: flex;
  flex-wrap: wrap;
}

.closeIcon {
  font-size: 1em;
  position: relative;
  right: -0.4em;
  margin-right: 5px;
}

.interestitem {
  background-color: #eee;
  border-radius: 20px;
  padding: 4px 10px;
  margin: 5px;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  font-weight: 500;
}

.interestitem:focus,
.interestitem:active {
  background-color: var(--qrencia-addfaq-text-bg) !important;
}

.selected {
  background-color: var(--qrencia-addfaq-text-bg);
  position: relative;
  border: 2px solid #7d6fea;
  color: black;
  overflow: hidden;
  transition: border-color 0.3s ease, transform 0.3s ease;
}

.selected:hover {
  border-color: #5a4ed8;
  transform: scale(1.05);
}

/* Overlay using a pseudo-element */
.selected::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    125,
    111,
    234,
    0.1
  ); /* Using the border color with transparency */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.selected:hover::before {
  opacity: 1;
}
/* Add animation for the overlay */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.selected::before {
  animation: fadeIn 0.3s ease forwards;
}

.interestitem span {
  margin-right: 8px;
  font-size: 16px;
}

.submitButtonCnt {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  width: 100vw;
  background: var(--bs-body-bg);
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
}

.submitButton {
  font-weight: 400 !important;
  font-size: 0.9em;
  padding: 0.1%;
  margin: 0.6% 3%;
  border-radius: 8px;
  width: 10%;
}
.personalDetailsDiv {
  margin-top: 5%;
}

.tabContentHeading {
  margin: 4% 2% 1% 1%;
  text-align: left;
  font-weight: 500 !important;
}
.selectedSkillSection {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 14%;
}
.formGroup .formInput {
  width: 100%;
  margin-bottom: 3.5%;
  font-size: 13px;
  border: none;
  padding: 10px 35px 10px 15px !important;
  border-radius: 6px;
  background: var(--qrencia-white);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.formGroup .formInputPlaceholder {
  width: 100%;
  margin-bottom: 3.5%;
  font-size: 13px;
  border: none;
  padding: 10px 35px 10px 15px !important;
  border-radius: 6px;
  background: var(--qrencia-white);
  transition: border-color 0.3s, box-shadow 0.3s;
  color: var(--qrencia-placeholder-color) !important;
}

.step2Container .formGroup .inputIcon,
.step3ContainerInput .formGroup .inputIcon {
  position: absolute;
  top: 50%;
  right: 3%;
  left: unset;
  transform: translateY(-70%);
  font-size: 18px;
  color: grey;
  pointer-events: none;
}

.step3ContainerInput{
  margin-bottom: 11%;
}

.step2Container .inputWrapper,
.step3ContainerInput .inputWrapper {
  position: relative;
}

.step2Container {
  margin: 0 auto;
  margin-top: 6%;
}

.step3Container {
  margin: 0 auto;
  margin-top: 6%;
}
.form3Container {
  margin: 0 auto;
  margin-top: 6%;
}

.formLocationText {
  text-align: left;
  font-weight: 500 !important;
  font-size: 1.1em;
}

.formLocationSubText {
  font-size: 0.9em;
  margin-bottom: 6%;
  text-align: left;
}

.chooseProfileText {
  text-align: left;
  font-weight: 600 !important;
  margin-bottom: 6em;
  margin-top: -1em;
}

.optionalText {
  text-align: left;
  font-size: 0.8em;
  color: var(--qrencia-error-red);
  margin-bottom: 20%;
}

.imagePreview {
  position: relative;
  border: 5px solid var(--qrencia-dark-blue);
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.userInitials {
  background-color: var(--qrencia-dark-blue);
  position: relative;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  color: var(--qrencia-white);
  font-size: 1.8em;
  line-height: 150px;
}
.deleteProfilePicture {
  position: absolute;
  bottom: 3em;
  font-size: 0.9em;
  cursor: pointer;
  left: 50%;
  color: var(--qrencia-dark-blue);
  transform: translate(-50%, 0%);
}
.errorText {
  position: absolute;
  bottom: 3em;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%, 0%);
}
.imagePreviewContainer {
  background: var(--qrencia-white);
  width: 100%;
  margin-top: 4em;
  background-color: var(--qrencia-white);
}

.topHalf {
  width: 100%;
  background-size: cover;
  background-image: var(--blue-background-image);
  background-color: #d5e9f6;
  max-height: 15.5em;
  position: relative;
}

.defaultImageRow {
  display: flex;
  width: 30vw;
  margin: 0 auto;
  justify-content: space-around;
}

.imagePreviewBox {
  width: 150px;
  display: block;
  margin: -0.5em auto;
  border: none;
  object-fit: cover;
  position: relative;
  background-color: var(--qrencia-white);
  border-radius: 50%;
  overflow: hidden;
  top: -4em;
}
.defaultImageContainer {
  width: 6em;
  height: 6em;
  border: 5px solid var(--qrencia-dark-blue);
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.defaultImagesShape {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.selectedImageOverlay {
  width: 100%;
  display: block;
  // margin: -0.5em auto;
  border: none;
  object-fit: cover;
  cursor: pointer;
  position: absolute;
  bottom: 0%;
  object-fit: fill;
}
.updateProfileInput {
  position: absolute;
  left: 0;
  bottom: 0em;
  height: 4em;
  opacity: 0;
}

.bottomHalf {
  width: 100%;
  position: relative;
  padding: 3%;
  margin-bottom: 3%;
}

.addProfileInput {
  position: absolute;
  width: 14%;
  height: 6.5em;
  border-radius: 50%;
  left: 11em;
  z-index: 100;
  opacity: 0;
  cursor: pointer;
}

/* --------- MEDIA QUERIES -------------*/
@media (max-width: 1300px) {
  .optionalText {
    margin-bottom: 15vh;
  }
  .topHalf {
    max-height: 16em;
  }
  .imagePreviewBox {
    width: 140px;
  }
  .imagePreview {
    width: 140px;
    height: 140px;
  }
  .userInitials {
    width: 140px;
    height: 140px;
    line-height: 140px;
  }
  .defaultImageRow {
    width: 45vw;
  }
  .defaultImagesShape {
    width: 100%;
  }
}

@media (max-width: 1120px) {
  .tabContentHeading {
    margin: 8% 2% 4% 1%;
  }
  .optionalText {
    margin-bottom: 15vh;
  }
  .topHalf {
    max-height: 16em;
  }
  .imagePreviewBox {
    width: 130px;
  }
  .imagePreview {
    width: 130px;
    height: 130px;
  }
  .userInitials {
    width: 130px;
    height: 130px;
    line-height: 130px;
  }
  .defaultImageRow {
    width: 45vw;
  }
}

@media (max-width: 991px) {
  .optionalText {
    margin-bottom: 15vh;
  }
  .topHalf {
    max-height: 16em;
  }
  .imagePreviewBox {
    width: 130px;
  }
  .imagePreview {
    width: 130px;
    height: 130px;
  }
  .userInitials {
    width: 130px;
    height: 130px;
    line-height: 130px;
  }
  .defaultImageRow {
    width: 45vw;
  }
}

@media (max-width: 768px) {
  .form3Container {
    width: 96%;
    margin-top: 6%;
  }
  .chooseProfileText {
    margin-bottom: 7em;
    margin-top: 0em;
  }
  .imagePreviewBox {
    width: 120px;
  }
  .imagePreview {
    width: 120px;
    height: 120px;
  }
  .userInitials {
    width: 120px;
    height: 120px;
    line-height: 120px;
  }
  .defaultImageRow {
    width: 50vw;
  }
  .topHalf {
    max-height: 11em;
  }
}

@media (min-width: 580px) and (max-width: 768px) {
  .tabContentHeading {
    margin: 6% 2% 3% 1%;
    font-size: 1em;
  }
}

@media (max-width: 580px) {
  .imagePreviewBox {
    width: 110px;
  }
  .imagePreview {
    width: 110px;
    height: 110px;
  }
  .userInitials {
    width: 110px;
    height: 110px;
    line-height: 110px;
  }
  .topHalf {
    min-height: 8em;
  }
  .defaultImageRow {
    width: 60vw;
  }
  .imagePreviewBox {
    top: -3em;
  }
  .addProfileInput {
    height: 5.5em;
    left: 6em;
  }
  .deleteProfilePicture {
    bottom: 2.8em;
    font-size: 0.9em;
    transform: translate(-50%, 0%);
  }
  .errorText {
    bottom: 2.8em;
    transform: translate(-50%, 0%);
  }
}

@media (max-width: 480px) {
  .interestitem {
    padding: 0px 6px;
    margin: 3px;
    font-size: 0.78em;
  }
  .tabContentHeading {
    margin: 8% 2% 4% 1%;
  }
  .formLocationText {
    font-size: 0.9em;
    margin-top: 9%;
  }
  .formLocationSubText {
    font-size: 0.75em;
  }
  .chooseProfileText {
    width: 94%;
    margin: 8% auto;
  }
  .imagePreviewContainer {
    margin-top: 5em;
  }
  .imagePreviewBox {
    width: 100px;
  }
  .imagePreview {
    width: 100px;
    height: 100px;
  }
  .userInitials {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
  .defaultImageRow {
    width: 70vw;
  }
  .defaultImagesShape {
    width: 100%;
    border-radius: 50%;
    border: 4px solid var(--qrencia-dark-blue);
  }
  .defaultImageContainer {
    border: 2px solid var(--qrencia-dark-blue);
  }
  .topHalf {
    min-height: 8em;
  }
  .deleteProfilePicture {
    bottom: 3.5em;
  }
  .errorText {
    bottom: 3.5em;
  }
}

@media (max-width: 425px) {
  .tabContentHeading {
    margin: 10% 2% 4% 1%;
  }
  .formLocationText {
    font-size: 0.9em;
    margin-top: 9%;
  }
  .formLocationSubText {
    font-size: 0.75em;
  }
  .chooseProfileText {
    width: 94%;
    margin: 8% auto;
  }
  .imagePreviewContainer {
    margin-top: 5em;
  }
  .imagePreviewBox {
    width: 100px;
  }
  .imagePreview {
    width: 100px;
    height: 100px;
  }
  .userInitials {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
  .defaultImageRow {
    width: 80vw;
  }
  .addProfileInput {
    width: 17%;
    height: 5.5em;
    left: 4.5em;
  }
  .topHalf {
    max-height: 13em;
  }
  .bottomHalf {
    padding: 5% 3% 3% 3%;
  }
  .deleteProfilePicture {
    bottom: 3.5em;
    font-size: 0.9em;
    transform: translate(-50%, 0%);
  }
  .errorText {
    bottom: 3.5em;
  }
}
