.mainContainerSection {
  margin: 2em 1em 16em 2em;
}

.thumbnailSection {
  padding: 1.2em;
  display: flex;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  margin: 1em 0 0 0;
  border: 1px solid var(--qrencia-light-grey-border);
}

.thumbnailContainer {
  width: 12em;
}

.thumbnail {
  width: 100%;
  border-radius: 10px;
}

.thumbnailDetailsSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 1em;
  gap: 2px;
}

.competitionTitle {
  font-size: 1.2em !important;
  font-weight: 600 !important;
}

.competitionDate {
  font-size: 0.9em !important;
  font-weight: 500 !important;
}

.competitionLink {
  font-size: 0.9em !important;
  font-weight: 500 !important;
}

.competitionSkillHashtag {
  font-size: 0.9em !important;
  font-weight: 500 !important;
  background-color: #c2e2ff;
  border-radius: 20px;
  padding: 2px 10px 2px 7px;
  margin-top: 5px;
}

.competitionNavBarContainer {
  width: 100%;
  position: sticky;
  top: 0;
  padding: 0.5em 0;
  background-color: var(--qrencia-template-background-color);
  z-index: 100;
}

.competitionDetailsSection {
  padding: 1em;
  margin: 0 0 0.5em 0;
  background-color: var(--qrencia-white);
  border: 1px solid var(--qrencia-light-grey-border);
  border-radius: 10px;
}

.competitionDetailsHeading {
  font-size: 1.1em !important;
  font-weight: 600 !important;
}

.competitionDetailsSubsection {
  border-left: 2px dashed #c2e2ff;
}

.competitionDetailsListSection {
  border-left: 2px dashed #d8d8d8;
  margin: 0 0 25px 15px;
  padding: 0 0 0 20px;
}

.competitionDetailsListSection ul li:before {
  border: 2px solid rgba(39, 73, 125, 0.24);
  height: 20px;
  width: 20px;
  border: 2px solid #c6c5c5;
  background: #fff;
  left: -31px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.competitionDetailsSubHeading {
  font-size: 0.93em !important;
  font-weight: 400 !important;
  position: relative;
  list-style-type: none;
  margin-bottom: 7px;
  word-break: break-word;
  line-height: 24px;
}

.profilePicCnt {
  margin: 1em 0.5em;
}

.profilePicPreviewCnt {
  display: flex;
  align-items: center;
}

.previewProfilePic {
  display: block;
  width: 90px !important;
  height: 90px !important;
  border-radius: 100%;
  border: 2px solid var(--qrencia-dark-blue);
}

.initialProfilePreview {
  display: flex;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  border: 2px solid var(--qrencia-dark-blue);
  font-size: 2.2em !important;
  justify-content: center;
  align-items: center;
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-select-box-background);
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}

.smallerText {
  font-size: 0.8em !important;
  font-weight: 500 !important;
  color: var(--qrencia-text-black);
}

.profilePicPreviewDetails {
  display: block;
}

.profilePicPreviewDetails p {
  margin-bottom: 0 !important;
}

.profilePicMainCnt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: nowrap;
  gap: 1em;
}

.competitionChallengeContainer {
  background-color: var(--qrencia-light-blue-icon-background);
  padding: 1em 2em;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
}

.competitionChallengeContainer p {
  margin-bottom: 0 !important;
}

.downloadChallengeButton {
  font-size: 0.8em !important;
  padding: 4px 10px;
  color: var(--qrencia-white) !important;
  margin: 1em 0;
}

.uploadChallengeButton {
  font-size: 0.8em !important;
  margin-top: 1.5px;
  padding: 5px 10px;
  color: var(--qrencia-white) !important;
  width: 8em;
}

.downloadSolutionButton {
  font-size: 0.8em !important;
  padding: 4px 10px;
  color: var(--qrencia-dark-blue) !important;
  width: 8em;
  text-decoration: underline;
}

.uploadButtonContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
  gap: 1em;
  margin: 1em 0;
}
.uploadButtonContainer > div > input {
  width: 100%;
}
.uploadButtonContainer > div > p {
  font-size: 0.8em !important;
}
.downloadButtonContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  gap: 0.1em;
  margin: 0.1em 0;
}

.downloadButtonContainer > .fileExistsMessage {
  font-size: 0.8em;
}

.uploadInputBox {
  width: 50%;
  padding: 4px !important;
}

.YourPerformanceContainer {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: -1em;
}

.YourPerformanceCard {
  width: calc(50% - 100px);
  height: 120px;
  font-size: 1em;
  background-color: var(--qrencia-white);
  border-radius: 8px;
  position: relative !important;
  box-shadow: var(--qrencia-your-performance-card-shadow);
  margin-right: 10px;
}

.YourPerformanceCard p {
  margin-bottom: 0 !important;
}

.YourPerformanceCardImage {
  width: 100%;
  height: 100%;
  border-radius: 8px !important;
  object-fit: cover;
}

.YourPerformanceDownloadText {
  font-size: 0.9em !important;
  margin-bottom: 0 !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  color: var(--qrencia-text-black) !important;
  position: absolute;
  top: 30px;
  left: 10px;
}

.YourPerformanceDownloadButton {
  position: absolute;
  top: 75px;
  left: 10px;
  font-size: 0.8em !important;
  padding: 4px 10px;
  color: var(--qrencia-white) !important;
}

.detailsAgeLimit {
  margin-bottom: 0 !important;
  display: inline-block;
}

.iButton {
  margin: 0 5px;
  font-size: 1.2em;
  font-weight: 600;
}

.tooltipMessage {
  font-size: 0.8em !important;
  font-weight: 500 !important;
  background-color: var(--qrencia-carousel-blue-image-border);
  width: 70%;
  color: var(--qrencia-text-black);
}

.spinner {
  animation: spin-animation 0.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@media (min-width: 2000px) {
  .mainContainerSection {
    margin: 2em 1em 1em 4em !important;
  }
}

@media (min-width: 1400px) {
  .mainContainerSection {
    margin: 2em 1em 1em 3em;
  }
}
@media (max-width: 1200px) {
  .YourPerformanceCard {
    width: calc(50% - 60px);
    font-size: 0.9em;
  }
}
@media (max-width: 991.5px) {
  .mainContainerSection {
    margin: 1.5em 2em;
  }

  .YourPerformanceCard {
    width: calc(50% - 100px);
    font-size: 0.9em;
  }

  .thumbnailSection {
    margin-top: -1em;
  }
}
@media (max-width: 768px) {
  .YourPerformanceCard {
    width: calc(50% - 50px);
    font-size: 0.9em;
  }
}
@media (max-width: 650px) {
  .YourPerformanceCard {
    width: calc(50% - 30px);
    font-size: 0.9em;
  }
}
@media (max-width: 580px) {
  .mainContainerSection {
    margin: 1em;
  }
  .uploadChallengeButton {
    padding: 4px 10px;
    width: 7em;
  }

  .thumbnailSection {
    margin-top: -0.5em;
  }

  .downloadButtonContainer {
    flex-wrap: wrap;
  }

  .downloadSolutionButton {
    padding: 4px 0;
  }

  .uploadButtonContainer {
    flex-direction: column;
    gap: 0.5em;
  }

  .uploadInputBox {
    width: 100%;
  }
  .YourPerformanceCard {
    width: calc(50% - 20px);
    font-size: 0.9em;
  }
}

@media (max-width: 500px) {
  .thumbnailSection {
    flex-wrap: wrap;
  }

  .thumbnailDetailsSection {
    padding-left: 0;
    padding-top: 0.5em;
  }

  .thumbnailContainer {
    width: 100%;
  }

  .YourPerformanceCard {
    width: calc(100% - 20px);
    font-size: 0.8em;
  }

  .profilePicMainCnt {
    flex-wrap: wrap;
  }

  .tooltipMessage {
    width: 50%;
  }

  .competitionChallengeContainer {
    padding: 1em;
  }
}

/* ----------------List Styling--------------- */

.competitionDetailsSection p,
.competitionDetailsSection strong,
.competitionDetailsSection ul,
.competitionDetailsSection li,
.competitionDetailsSection span {
  line-height: 28px;
  font-size: 15px;
  word-break: break-word;
  font-weight: 400;
}

@media (max-width: 800px) {
  .competitionDetailsSection p,
  .competitionDetailsSection strong,
  .competitionDetailsSection ul,
  .competitionDetailsSection li,
  .competitionDetailsSection span {
    font-size: 14px;
    line-height: 21px;
  }
}

.competitionDetailsSection p {
  margin-bottom: 25px;
}

@media (max-width: 800px) {
  .competitionDetailsSection p {
    margin-bottom: 20px;
  }
}

.competitionDetailsSection ul,
.competitionDetailsSection ol {
  border-left: 2px dashed var(--qrencia-dark-blue);
  margin: 0 0 25px 15px;
  padding: 0 0 0 20px;
}

.competitionDetailsSection ul li,
.competitionDetailsSection ol li {
  position: relative;
  list-style-type: none;
  margin-bottom: 7px;
  word-break: break-word;
  line-height: 24px;
}

@media (max-width: 800px) {
  .competitionDetailsSection ul li,
  .competitionDetailsSection ol li {
    line-height: 21px;
    margin-bottom: 10px;
  }
}

.competitionDetailsSection ul li:last-child,
.competitionDetailsSection ol li:last-child {
  margin-bottom: 0;
}

.competitionDetailsSection ul li:before,
.competitionDetailsSection ul li:after,
.competitionDetailsSection ol li:before,
.competitionDetailsSection ol li:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  margin: auto;
}

.competitionDetailsSection ul li:before,
.competitionDetailsSection ol li:before {
  height: 16px;
  width: 16px;
  border: 2px solid var(--qrencia-dark-blue);
  background: var(--qrencia-white);
  left: -29px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.competitionDetailsSection ul li:after,
.competitionDetailsSection ol li:after {
  height: 6px;
  width: 6px;
  background: var(--qrencia-dark-blue);
  left: -24px;
  top: 0;
  bottom: 0;
}

.competitionDetailsSection ul li ul,
.competitionDetailsSection ul li ol,
.competitionDetailsSection ol li ul,
.competitionDetailsSection ol li ol {
  border-left: 0px;
  margin-left: 0;
  padding-left: 30px;
  margin-top: 5px;
  margin-bottom: 7px;
}

.competitionDetailsSection ul li ul li,
.competitionDetailsSection ul li ol li,
.competitionDetailsSection ol li ul li,
.competitionDetailsSection ol li ol li {
  margin-bottom: 7px;
}

.competitionDetailsSection ul li ul li:after,
.competitionDetailsSection ul li ul li:before,
.competitionDetailsSection ul li ol li:after,
.competitionDetailsSection ul li ol li:before,
.competitionDetailsSection ol li ul li:after,
.competitionDetailsSection ol li ul li:before,
.competitionDetailsSection ol li ol li:after,
.competitionDetailsSection ol li ol li:before {
  display: none;
}

.competitionDetailsSection ul li ul li,
.competitionDetailsSection ol li ul li {
  list-style-type: disc;
}

.competitionDetailsSection ul li ul li li,
.competitionDetailsSection ol li ul li li {
  list-style-type: circle;
}

.competitionDetailsSection ul li ol li,
.competitionDetailsSection ol li ol li {
  list-style-type: decimal;
}

.competitionDetailsSection ul li ol li li,
.competitionDetailsSection ol li ol li li {
  list-style-type: circle;
}

@media (max-width: 800px) {
  .competitionDetailsSection ul,
  .competitionDetailsSection ol {
    border-left: 1px dashed var(--qrencia-dark-blue);
    margin: 0 0 20px 10px;
    padding: 0 0 0 15px;
  }

  .competitionDetailsSection ul li:before,
  .competitionDetailsSection ol li:before {
    height: 15px;
    width: 15px;
    border: 1px solid var(--qrencia-dark-blue);
    left: -23px;
  }

  .competitionDetailsSection ul li:after,
  .competitionDetailsSection ol li:after {
    height: 5px;
    width: 5px;
    background: var(--qrencia-dark-blue);
    left: -18px;
  }
}

.competitionDetailsSection p + ul,
.competitionDetailsSection p + ol {
  margin-top: -15px;
}

@media (max-width: 800px) {
  .competitionDetailsSection p + ul,
  .competitionDetailsSection p + ol {
    margin-top: -5px;
  }
}

.competitionDetailsSection strong {
  font-weight: 600;
}

.competitionDetailsSection strong a,
.competitionDetailsSection strong u,
.competitionDetailsSection strong span {
  font-weight: 600;
}

.competitionDetailsSection a {
  word-break: break-word;
  color: #0073e6;
  cursor: pointer;
}
