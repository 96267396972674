.myAlbumContainer {
  background-color: var(--qrencia-white);
  padding: 2% 2.5% 2% 2.5%;
  border-radius: 20px;
  margin-top: 1%;
  /* margin-bottom: 1%; */
}

.albumHeading {
  flex: 1;
}

.learnerProfileUploadIcon {
  color: var(--qrencia-silver-medal-color);
  font-size: 2em;
  margin-bottom: 2%;
  cursor: pointer;
  flex: 1;
}

.chooseFileContainer {
  display: flex;
}

.myAlbumContainer h2 {
  color: var(--qrencia-text-black);
  font-weight: 700;
  font-size: 1.4em;
}

.fileOBRUploadSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* .fileOBRUploadSection > button {
  margin-top: 0.2em;
  font-size: 1.2em;
  color: var(--qrencia-dark-blue);
  outline: none;
  border: none;
  background: var(--qrencia-white);
} */

.mediaSection {
  margin: 0.5em 0;
}

.editButtonSection {
  display: flex;
  justify-content: flex-end;
}


.fileOBRUpload input[type='file']::file-selector-button {
  position: absolute;
  right: -1.4em;
  top: 0.6em;
  height: 3.8em;
  line-height: 1.1em;
  z-index: 9;
  width: 9em !important;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.mediaContentContainer {
  display: flex !important;
  justify-content: start;
  align-items: center;
  margin-top: 0.8em;
  flex-wrap: wrap;
}

.imageBox {
  flex: 0 calc(25% - 1em);
  height: 12em;
  /* width: 12em; */
  border: 1px solid var(--qrencia-white) !important;
  padding: 0px !important;
  position: relative;
  overflow-clip-margin: content-box;
  cursor: pointer;
  overflow: hidden;
  border-radius: 2px;
  object-fit: cover;
  margin-right: 2px;
}

.gallaryImage {
  min-width: 100%;
  height: 100%;
  border-radius: 2px;
  transition: transform 200ms;
  position: absolute;
  top: 0;
  left: 0;
}

.gallaryImage:hover {
  transform: scale(1.15);
}

.gallaryVideoPlayIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3em;
  width: 3em;
}

.overLay {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 200ms;
  color: var(--qrencia-white);
  font-size: 1.5em;
  font-weight: 500;
}

.overLay:hover {
  font-size: 1.7em;
}

.gallaryImage:hover {
  transform: scale(1.01);
  transition: 0.2s ease-in-out;
}

.fadeImageBackground {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.55);
  height: 12em;
  width: 12em;
  z-index: 1;
  border-radius: 2px;
  width: 100%;
}

.tooltipMessageDownload {
  font-size: 0.9em !important;
  font-weight: 500 !important;
  background-color: var(--qrencia-carousel-blue-image-border);
  width: 16em !important;
  z-index: 100;
  text-align: center;
  color: var(--qrencia-text-black);
}

.fileOBRUploadInput {
  flex-grow: 1;
}

.errorMessage {
  color: red;
  font-size: 0.8em;
}

.removeBtn {
  outline: none;
  background-color: var(--qrencia-white);
  border: none;
  color: var(--qrencia-white);
  padding: 0.6em 0.4em 0.6em 0.4em;
  border-radius: 0.3em;
  /* width: 2em; */
}

.editButton {
  height: 2.5em !important;
  font-size: 0.8em !important;
  padding: 0 1.5em !important;
}

.removeBtn>svg {
  font-size: 1em;
  color: var(--qrencia-text-black);
}

.viewButton {
  background-color: var(--qrencia-dark-blue);
  border: none;
  color: var(--qrencia-white);
  border-radius: 0.3em;
  margin-top: 1em;
  margin-left: 1.4em;
  font-size: 0.8em;
  padding: 0.6em 2em;
  cursor: pointer;
}

.uploadButton {
  background-color: var(--qrencia-dark-blue);
  border: none;
  color: var(--qrencia-white);
  border-radius: 0.3em;
  margin-top: 1em;
  margin-left: 1em;
  font-size: 0.8em;
  padding: 0.6em 2em;
  cursor: pointer;
}

@media (min-width: 1140px) and (max-width: 1380px) {
  .fileOBRUpload input[type='file']::file-selector-button {
    position: absolute;
    right: -1em;
    top: 1.2em;
    height: 2.6em;
    line-height: 1.1em;
    z-index: 9;
    width: 9em !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (max-width: 1290px) {
  .myAlbumContainer h2 {
    font-size: 1.2em;
  }
}

@media (min-width: 992px) and (max-width: 1140px) {
  .fileOBRUpload input[type='file']::file-selector-button {
    position: absolute;
    right: -1em;
    top: 1.6em;
    height: 2.6em;
    line-height: 1.1em;
    z-index: 9;
    width: 9em !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (min-width: 785px) and (max-width: 840px) {
  .fileOBRUpload input[type='file']::file-selector-button {
    position: absolute;
    right: -0.9em;
    top: 1.2em;
    height: 2.6em;
    line-height: 1.1em;
    z-index: 9;
    width: 9em !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (min-width: 767px) and (max-width: 785px) {
  .fileOBRUpload input[type='file']::file-selector-button {
    position: absolute;
    right: -0.9em;
    top: 1.4em;
    height: 2.6em;
    line-height: 1.1em;
    z-index: 9;
    width: 9em !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (max-width: 767px) {
  .myAlbumContainer h2 {
    font-size: 1.1em;
  }

  .myAlbumContainer {
    padding: 2%;
  }

}

@media (max-width: 540px) {
  .fileOBRUpload input[type='file']::file-selector-button {
    position: absolute;
    right: -0.9em;
    top: 1.4em;
    height: 2.6em;
    line-height: 1.1em;
    z-index: 9;
    width: 9em !important;
    border-bottom-right-radius: 0;
  }
}

@media (min-width: 992px) and (max-width: 1140px) {
  .removeBtn {
    margin-left: 0.2em;
    font-size: 0.8em;
  }
}

@media (max-width: 768px) {
  .imageBox {
    flex: 1 1 calc(50% - 1em);
  }

  .fadeImageBackground {
    flex: 1 1 calc(50% - 1em);
  }

  .removeBtn {
    margin-left: 0.2em;
    /* width: 1.2em; */
    font-size: 0.8em;
  }
}

@media (max-width: 560px) {
  .gallaryImage {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 10px;
  }

  .removeBtn {
    margin-left: 0.2em;
    font-size: 0.8em;
  }
}

@media (max-width: 480px) {
  .shareIconPannelContainer svg {
    font-size: 0.65em !important;
    vertical-align: unset;
  }

  .tooltipMessageDownload {
    width: 15em !important;
  }

  .shareIconPannelContainer a,
  .shareIconPannelContainer a:hover,
  .shareIconPannelContainer a:focus,
  .shareIconPannelContainer a:active {
    padding: 0px 7px 0px 6px;
  }

  .shareIconPannelContainer {
    gap: 0.4em;
  }

  .followUnfollowButton {
    margin-top: 8px;
    font-size: 0.65em;
    padding: 1px 14px;
    border-radius: 10px;
  }

  .imageBox {
    height: 10em !important;
    width: 10em !important;
  }
}

@media (max-width: 444px) {
  .uploadButton {
    margin-top: 1.3em;
    font-size: 0.6em;
    padding: 1.1em 2em;
  }

  .viewButton {
    margin-top: 1.3em;
    font-size: 0.6em;
    padding: 1.1em 2em;
  }
}

@media (max-width: 390px) {
  .imageBox {
    height: 9em !important;
    width: 9em !important;
  }
}

@media (max-width: 992px) {
  .editButton {
    font-size: 0.75em !important;
  }
}

@media (max-width: 900px) {
  .editButton {
    height: 2.3em !important;
    font-size: 0.75em !important;
  }
}

@media (max-width: 438px) {
  .editButton {
    font-size: 0.65em !important;
  }
}

@media (max-width: 650px) {
  .editButton {
    margin-top: 0.5em;
  }
}