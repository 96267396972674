.columnPadding {
  padding: 0.5em;
}
.studentColumnPadding {
  padding: 0.5em 0em;
}
.container {
  background-color: var(--qrencia-white);
  padding: 1em;
  border-radius: 10px;
}

.expertContainer {
  background-color: var(--qrencia-white);
  padding: 1em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-cards-border);
}
.cardsContainer {
  background-color: var(--qrencia-nudges-card-background);
  padding: 0.5em;
  border-radius: 10px;
  margin: 0px 5px;
  border: 1px solid var(--qrencia-nudges-card-border);
}
.studentCardsContainer {
  background-color: var(--qrencia-nudges-card-background);
  padding: 0.5em 0em;
  border-radius: 10px;
  margin: 0px;
  border: 1px solid var(--qrencia-nudges-card-border);
}

.responseCountContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.questionHeading {
  margin-bottom: 0;
  font-size: 0.75em;
  color: var(--qrencia-text-black);
  font-weight: 550 !important;
}

.responseCountText {
  margin-bottom: 0;
  font-size: 0.7em;
  background-color: var(--qrencia-nudges-card-text-bg);
  padding: 3px 8px;
  border-radius: 10px;
  font-weight: 500 !important;
}
.pendingText {
  color: var(--cancel-red-button);
}
.resolvedText {
  color: var(--qrencia-success-green);
}

.summaryContainer {
  height: 34px;
  overflow: hidden;
  line-height: 1.4;
}

.summaryText {
  color: var(--card-grey-title);
  font-size: 0.7em;
  margin: 0;
  text-overflow: ellipsis;
  padding-top: 4px;
  display: -webkit-box;
  /* text-align: justify; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.bottomContainer {
  padding-top: 0.5em;
  display: flex;
  gap: 0.6em;
}

.detailsGroup {
  display: inline-flex;
  padding: 0;
  align-items: flex-start;
}

.detailsIconSection {
  padding: 5.5px 6px;
  margin-right: 5px;
  background-color: var(--qrencia-nudges-card-text-bg);
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.detailsIcon {
  font-size: 12px !important;
  color: var(--qrencia-dark-blue);
}

.detailsSubsection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.detailsHeading {
  font-size: 0.65em;
  font-weight: 400 !important;
  margin-top: -2px;
}

.detailsSubHeading {
  font-size: 0.7em;
  font-weight: 500 !important;
}

.viewButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  align-items: center;
}

.viewButton {
  font-size: 0.76em;
  padding: 2px 10px;
}

.containerFallback {
  background-color: var(--qrencia-white);
  padding: 1em;
  border-radius: 10px;
}

.fallBackMessage {
  padding: 3em 1em;
  margin: 1em 0;
  font-size: 0.9em;
  text-align: center;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  color: var(--qrencia-grey-text);
  font-style: italic;
  font-weight: 400 !important;
  border: 1px solid var(--qrencia-cards-border);
}

.loadMoreContainer {
  text-align: center;
}

@media (min-width: 1191px) and (max-width: 1400px) {
  .detailsIconSection {
    padding: 6.5px 6.5px;
  }
}
@media (max-width: 1400px) and (min-width: 1330px) {
  .detailsHeading {
    font-size: 0.6em;
  }

  .detailsSubHeading {
    font-size: 0.6em;
  }
  .detailsIcon {
    font-size: 10px !important;
  }
}

@media (max-width: 1330px) and (min-width: 1220px) {
  .detailsHeading {
    font-size: 0.5em;
  }

  .detailsSubHeading {
    font-size: 0.5em;
  }
}
@media (max-width: 1220px) and (min-width: 1200px) {
  .detailsHeading {
    font-size: 0.48em;
  }

  .detailsSubHeading {
    font-size: 0.48em;
  }
}

@media (min-width: 991px) and (max-width: 996px) {
  .detailsHeading {
    font-size: 0.55em;
  }

  .detailsSubHeading {
    font-size: 0.55em;
  }

  .viewButton {
    font-size: 0.7em;
    padding: 2px 6px;
  }
}
@media (max-width: 991px) {
  .expertContainer {
    padding: 1em 0.5em;
  }
}
@media (min-width: 996px) and (max-width: 1190px) {
  .detailsHeading {
    font-size: 0.6em;
  }

  .detailsSubHeading {
    font-size: 0.65em;
  }

  .viewButton {
    font-size: 0.7em;
    padding: 2px 6px;
  }
}

@media (min-width: 768px) and (max-width: 991.5px) {
  .columnPadding {
    padding: 0 0 1em 0;
  }

  .bottomContainer {
    gap: 1em;
  }

  .detailsIconSection {
    padding: 7px 7.5px;
  }
}
@media (max-width: 991px) and (min-width: 860px) {
  .detailsHeading {
    font-size: 0.6em;
  }

  .detailsSubHeading {
    font-size: 0.7em;
  }
}
@media (max-width: 859px) and (min-width: 830px) {
  .detailsHeading {
    font-size: 0.55em;
  }

  .detailsSubHeading {
    font-size: 0.68em;
  }
}
@media (max-width: 829px) and (min-width: 801px) {
  .detailsHeading {
    font-size: 0.52em;
  }

  .detailsSubHeading {
    font-size: 0.65em;
  }
}
@media (max-width: 801px) and (min-width: 768.5px) {
  .detailsHeading {
    font-size: 0.5em;
  }
  .detailsIconSection {
    padding: 6px 6px;
  }
  .detailsSubHeading {
    font-size: 0.6em;
  }
  .detailsIcon {
    font-size: 9px !important;
  }
}
@media (max-width: 780px) and (min-width: 768.6px) {
  .detailsHeading {
    font-size: 0.48em;
  }

  .detailsSubHeading {
    font-size: 0.62em;
  }
}
@media (min-width: 601px) and (max-width: 768.5px) {
  .detailsHeading {
    font-size: 0.65em;
    font-weight: 400 !important;
  }

  .detailsSubHeading {
    font-size: 0.65em;
    font-weight: 500 !important;
  }

  .viewButton {
    font-size: 0.7em;
    padding: 2px 5px;
  }
}

@media (min-width: 575px) and (max-width: 620px) {
  .detailsHeading {
    font-size: 0.55em;
    font-weight: 400 !important;
  }

  .detailsSubHeading {
    font-size: 0.55em;
    font-weight: 500 !important;
  }

  .viewButton {
    font-size: 0.7em;
    padding: 2px 5px;
  }

  .questionHeading {
    font-size: 0.65em;
  }

  .responseCountText {
    font-size: 0.65em;
  }

  .summaryText {
    font-size: 0.65em;
  }
}

@media (min-width: 376px) and (max-width: 575.5px) {
  .columnPadding {
    padding: 0 0 1em 0;
  }

  .detailsHeading {
    font-size: 0.7em;
    font-weight: 400 !important;
  }

  .detailsSubHeading {
    font-size: 0.7em;
    font-weight: 500 !important;
  }

  .questionHeading {
    font-size: 0.7em;
  }

  .responseCountText {
    font-size: 0.7em;
  }

  .summaryText {
    font-size: 0.7em;
  }
}

@media (max-width: 375.5px) {
  .columnPadding {
    padding: 0 0 1em 0;
  }

  .detailsHeading {
    font-size: 0.65em;
  }

  .detailsSubHeading {
    font-size: 0.65em;
  }

  .viewButton {
    font-size: 0.65em;
    padding: 2px 6px;
  }

  .questionHeading {
    font-size: 0.65em;
  }

  .responseCountText {
    font-size: 0.65em;
  }

  .summaryText {
    font-size: 0.65em;
  }

  .fallBackMessage {
    font-size: 0.75em;
  }
}
