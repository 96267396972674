.manageCompContainer {
    width: 100%;
}

.manageCompNavigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
}

.manageCompHeading {
    font-size: 1em;
    font-weight: 650;
    color: var(--qrencia-text-black);
    margin: 0.5em 0 1em 0;
}

.competitionTypeHeading {
    font-size: 1em;
    font-weight: 600;
    padding-left: 1em;
    margin: 1em 0;
}

.noCompetitionsError {
    padding: 3em 1em;
    margin: 1em 0;
    text-align: center;
    background-color: var(--qrencia-white);
    border-radius: 10px;
    color: var(--qrencia-grey-text);
    font-style: italic;
    font-weight: 400 !important;
    border: 1px solid var(--qrencia-cards-border);
}

.goBackButton,
.goBackButton:hover,
.goBackButton:active,
.goBackButton:focus{
    font-size: 1.1em;
    margin-bottom: 0.5em;
    background-color: var(--qrencia-white) !important;
    color: var(--qrencia-dark-blue) !important;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}

.viewCompetitionDetailsSection{
    background-color: var(--qrencia-white);
    padding: 1em;
    border-radius: 10px;
    border: 1px solid var(--qrencia-cards-border);
    margin-bottom: 1em;
}

.searchInputBox{
    width: 40%;
    margin: 0 0 1em 0;
    border-radius: 8px;
}

.resetSearchIcon{
    cursor: pointer;
    font-size: 1.2em;
    font-weight: 400;
    margin-left: -1.2em;
    color: var(--qrencia-dark-blue);
    background-color: var(--qrencia-select-box-background);
}

/* accordion classess */
.AccordionItem .accordion-button {
    padding: 0;
}

@media(min-width: 580px) and (max-width: 991px){
    .searchInputBox{
        width: 60%;
    }
}

@media (max-width: 580px){
    .searchInputBox{
        width: 100%;
        margin: 0;
    }
}