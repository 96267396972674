.pageLoader {
  color: var(--qrencia-dark-blue);
  font-size: 2.2em;
  text-align: center;
}

.goBackButton,
.goBackButton:hover,
.goBackButton:active,
.goBackButton:focus {
  font-size: 1.1em;
  margin-bottom: 0.5em;
  background-color: var(--qrencia-white) !important;
  color: var(--qrencia-dark-blue) !important;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.assingedHeading {
  margin-top: 1em;
  font-size: 1em;
}

.assignCheckbox {
  display: flex;
  align-items: center;
}

.saveButton {
  font-size: 0.8em;
  width: max-content;
  /* margin-left: 0.8em; */
}

.checkBox {
  margin: 1.2em 2.2em;
  justify-content: center !important;
  align-items: center !important;
}