.expertViewCompetitionsTabs .nav-fill .nav-item .nav-link,
.expertViewCompetitionsTabs .nav-justified .nav-item .nav-link {
    width: auto;
    margin-left: auto !important;
    margin-right: auto !important;
    font-weight: 600 !important;
}

.expertViewCompetitionsTabs .nav-tabs {
    margin: 0 0 0.8em 0;
}

@media (max-width: 480px){
    .expertViewCompetitionsTabs .nav-tabs .nav-link.active {
        font-size: 0.75em !important;
        font-weight: 600 !important;
    }
    .expertViewCompetitionsTabs .nav-tabs .nav-link {
        font-size: 0.75em !important;
        font-weight: 600 !important;
    }
}