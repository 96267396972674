.reviewSectionContainer {
  background: var(--qrencia-extended-page-light-blue-background);
  padding: 3% 8%;
}

.heading {
  text-align: center;
  color: var(--qrencia-text-black);
  font-weight: 700;
  line-height: 1.2em;
  margin-top: -24px;
  font-size: 1.6em;
  margin-bottom: 3%;
  padding: 2% 1% 0% 1%;
}

.blueTriangleImage {
  margin-right: 580px;
  width: 2.5%;
}

.subHeading {
  font-size: 1.2em;
  margin-top: 0.5%;
  text-align: center;
  font-weight: 400;
}
.reviews {
  padding: 3% 7%;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  margin-bottom: 2em;
}
.loadMoreButton {
  text-align: center;
  margin-top: 1em;
}
.horizontalBreak {
  opacity: 0.1;
}
.loadMoreIcon {
  font-size: 1.5em;
  cursor: pointer;
}
.reviewHeading {
  font-size: 1.6em;
  margin-bottom: 1em;
  font-weight: 700 !important;
  margin-left: 1.5%;
}

@media (min-width: 1700px) {
  .reviewHeading {
    font-size: 1.8em;
    margin-bottom: 1em;
    font-weight: 700 !important;
  }
}
@media (min-width: 2000px) {
  .reviewHeading {
    font-size: 2.2em;
    margin-bottom: 1em;
    font-weight: 700 !important;
  }
}
@media (min-width: 990px) and (max-width: 1185px) {
  .subHeading {
    font-size: 1em;
  }

  .blueTriangleImage {
    margin-right: 490px;
    width: 3%;
  }
}

@media (min-width: 767px) and (max-width: 990px) {
  .backgroundDotsImage {
    display: none;
  }

  .blueTriangleImage {
    margin-right: 430px;
    width: 3.5%;
  }

  .subHeading {
    font-size: 0.9em;
  }
  .reviewHeading {
    font-size: 1.3em;
  }
}

@media (max-width: 767px) {
  .blueTriangleImage {
    margin-right: 432px;
    width: 4%;
  }
  .reviews {
    padding: 5% 4%;
  }

  .subHeading {
    font-size: 0.9em;
  }
  .reviewHeading {
    font-size: 1.1em;
    margin-bottom: 1em;
    margin-left: 2.5%;
  }
}

@media (min-width: 480px) and (max-width: 540px) {
  .blueTriangleImage {
    margin-right: 432px;
    width: 4%;
  }

  .subHeading {
    font-size: 0.8em;
  }

  .blueTriangleImage {
    margin-right: 380px;
    width: 4%;
  }
  .reviews {
    padding: 6% 4%;
  }
  .reviewHeading {
    font-size: 1.1em;
    margin-bottom: 1em;
    margin-left: 2.5%;
  }
}

@media (max-width: 480px) {
  .blueTriangleImage {
    margin-right: 330px;
    width: 6%;
  }

  .subHeading {
    font-size: 0.88em;
    margin-bottom: 5%;
  }
  .reviews {
    padding: 5% 5%;
    border-radius: 15px;
  }
  .reviewHeading {
    font-size: 1.1em;
    margin-bottom: 1em;
    margin-left: 3.5%;
  }
  .reviewSectionContainer {
    padding: 6% 8%;
  }
}

@media (max-width: 360px) {
  .blueTriangleImage {
    margin-right: 330px;
    width: 6%;
  }

  .subHeading {
    font-size: 0.88em;
    margin-bottom: 5%;
  }
}

@media (min-width: 768px) and (max-width: 819px) {
  .heading {
    font-size: 1.35em;
  }

  .blueTriangleImage {
    margin-right: 392px;
  }
}

@media (min-width: 3000px) {
  .heading {
    font-size: 2.5em;
  }
}

@media (min-width: 2600px) and (max-width: 3000px) {
  .heading {
    font-size: 2.4em;
  }
}

@media (min-width: 2000px) and (max-width: 2600px) {
  .heading {
    font-size: 2.2em;
  }
}

@media (min-width: 1701px) and (max-width: 1999px) {
  .heading {
    font-size: 1.8em;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .heading {
    font-size: 1.3em;
  }
}

@media (max-width: 767px) {
  .heading {
    font-size: 1.1em;
    padding: 2% 1% 1% 1%;
  }
}
