.copyLearningActivitySearchContainer {
  background-color: var(--qrencia-white);
  width: 100%;
  padding: 1em;
  height: 5em;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  border-radius: 5px;
}

.copyLearningActivityContainerInput {
  flex: 0.7;
  /* margin-right: 1em; */
}

.copyLearningActivitySearchContainer > button {
  font-size: 0.8em;
  padding: 0.5em 1em;
}

#copyLearningActivitySearch.rbt-menu.dropdown-menu {
  inset: 10px auto auto -31px !important;
  margin-left: 2.2em;
  font-size: 0.9em;
  padding: 0;
  border-radius: 0;
  border: solid 1px var(--qrencia-select-box-border);
}

#copyLearningActivitySearch .dropdown-item:focus,
#copyLearningActivitySearch .dropdown-item:hover,
#copyLearningActivitySearch .dropdown-item:active,
#copyLearningActivitySearch .dropdown-item:visited {
  background: var(--qrencia-select-box-background) !important;
}

.mailFormEditor .ql-container {
  font-size: 0.9em;
  min-height: 20em !important;
}

.copyLearningActivitySearchContainer .copyLearningActivitySearchLabel {
  font-weight: 500;
  font-size: 0.8em;
  background-color: var(--qrencia-white);
  margin-left: 3px;
  padding: 0em 0.3em;
  z-index: 1;
  background-image: linear-gradient(
    to bottom,
    var(--qrencia-white),
    var(--qrencia-select-box-background)
  );
  position: relative;
}

.copyLearningActivitySearchContainer .copyLearningActivitySearchInputBox {
  margin-top: -10px;
}

@media (max-width: 540px) {
  .copyLearningActivityContainerInput {
    flex: 1;
    margin-right: 1em;
  }
}

@media (max-width: 480px) {
  .copyLearningActivitySearchContainerInput {
    font-size: 0.6em;
    flex: 0.7;
    margin-right: 1em;
  }
  .copyLearningActivitySearchContainer {
    width: 100%;
    padding: 0.2em 0.3em;
    height: 3.5em;
    margin-bottom: 1em;
  }
  .copyLearningActivitySearchContainer .copyLearningActivitySearchLabel {
    font-size: 0.7em;
  }
  .copyLearningActivitySearch > input {
    font-size: 0.6em !important;
  }
  .copyLearningActivitySearchContainer .copyLearningActivitySearchInputBox {
    font-size: 0.88em !important;
  }
  .copyLearningActivitySearchContainer > button {
    padding: 0.5em 0.8em;
  }
}
