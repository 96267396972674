.searchCategoryWiseSkillSection {
    padding: 6% 13%;
    text-align: center;
}

.heading {
    color: var(--qrencia-text-black);
    font-weight: 600;
    line-height: 1.2em;
    margin-top: -24px;
    font-size: 2em;
    margin-bottom: 0.8%;
}

.blueTriangleImage {
    margin-right: 580px;
    width: 2.5%;
}

span {
    color: var(--qrencia-dark-blue);
    font-weight: 700 !important;
}

.subHeading {
    font-size: 1.4em;
    margin-top: 0.5%;
}

.exploreMoreButton {
    margin-top: 1%;
    font-size: 0.9em;
    padding: 1% 3%;
    font-weight: 500;
}

@media(min-width:767px) and (max-width:1290px) {
    .searchCategoryWiseSkillSection {
        padding: 6% 9%;
    }
}

@media(max-width:767px) {
    .searchCategoryWiseSkillSection {
        padding: 7% 3% 9% 3%;
    }
}

@media(min-width:990px) and (max-width:1185px) {
    .heading {
        font-size: 1.7em;
        margin-top: -21px;
    }
    .subHeading{
        font-size: 1.1em;
    }
    .blueTriangleImage {
        margin-right: 490px;
        width: 3%;
    }
}

@media(min-width:767px) and (max-width:990px) {
    .heading {
        font-size: 1.5em;
        margin-top: -21px;
    }

    .blueTriangleImage {
        margin-right: 430px;
        width: 3.5%;
    }

    .subHeading {
        font-size: 0.9em;
    }
}

@media(max-width:767px) {
    .heading {
        font-size: 1.5em;
        margin-top: -21px;
    }

    .blueTriangleImage {
        margin-right: 432px;
        width: 4%;
    }

    .subHeading {
        font-size: 0.9em;
    }

}

@media(min-width:480px) and (max-width:540px) {
    .heading {
        font-size: 1.3em;
        margin-top: -21px;
    }

    .blueTriangleImage {
        margin-right: 432px;
        width: 4%;
    }

    .subHeading {
        font-size: 0.8em;
    }
    .blueTriangleImage {
        margin-right: 380px;
        width: 4%;
    }

}

@media(max-width:480px) {
    .heading {
        font-size: 1.12em;
        margin-top: -19px;
        font-weight: 650;
    }

    .blueTriangleImage {
        margin-right: 330px;
        width: 6%;
    }

    .subHeading {
        font-size: 0.88em;
        margin-bottom: 5%;
    }

    .exploreMoreButton {
        margin-top: 1%;
        font-size: 0.7em;
        padding: 1.5% 3%;
        font-weight: 500;
    }
}

@media(max-width:360px) {
    .heading {
        font-size: 1.12em;
        margin-top: -19px;
        font-weight: 650;
    }

    .blueTriangleImage {
        margin-right: 330px;
        width: 6%;
    }

    .subHeading {
        font-size: 0.88em;
        margin-bottom: 5%;
    }
}
@media(min-width:768px) and (max-width:819px){
    .heading {
        font-size: 1.35em;
    }
    .blueTriangleImage {
        margin-right: 392px;
    }
}