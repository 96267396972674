.studentAndCourseSearch {
  display: flex;
  justify-content: flex-start;
  background: var(--qrencia-white);
  padding: 0% 0%;
}

.studentAndCourseSearch > div {
  /*margin: 0.2em 0.9em;*/
}

.containerSearchItem {
  width: 15vw;
  font-size: 1em;
  color: var(--qrencia-text-black) !important;
}

.containerSearchItem input {
  font-size: 0.8em;
  width: 15vw;
  color: var(--qrencia-text-black);
  margin-left: 0.5em;
  border-radius: 5px;
}

.containerSearchItem select {
  font-size: 0.8em;
  width: 15vw;
  color: var(--qrencia-text-black);
  margin-left: 0.5em;
  border-radius: 5px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.resetButton {
  margin-top: 0.6%;
  /*height: 34px;*/
  margin-left: 1%;
  width: 15vw;
  font-size: 0.7em;
}
@media (min-width: 767px) and (max-width: 1290px) {
  .studentAndCourseSearch {
    padding: 0% 9% 0% 9%;
  }

  .studentAndCourseSearch > div {
    margin: 0.2em 0.7em;
  }
}

@media (min-width: 900px) and (max-width: 1290px) {
  .containerSearchItem {
    width: 15vw;
  }

  .containerSearchItem input {
    width: 15vw;
  }

  .containerSearchItem select {
    width: 15vw;
  }
}

@media (max-width: 1190px) {
  .containerSearchItem select {
    font-size: 0.7em;
    padding: 8px 6px 8px 6px !important;
  }

  .containerSearchItem input {
    font-size: 0.7em;
  }

  .containerSearchItem input {
    padding: 8px 6px 8px 6px !important;
  }
}

@media (min-width: 767px) and (max-width: 900px) {
  .containerSearchItem {
    width: 15vw;
  }

  .containerSearchItem input {
    width: 15vw;
  }

  .containerSearchItem select {
    width: 15vw;
  }

  .containerSearchItem input {
    font-size: 0.65em;
  }

  .containerSearchItem select {
    font-size: 0.65em;
  }
}

@media (max-width: 550px) {
  .containerSearchItem {
    width: 29vw;
  }

  .containerSearchItem input {
    width: 29vw;
  }

  .containerSearchItem select {
    width: 29vw;
  }

  .containerSearchItem input {
    font-size: 0.5em;
  }

  .containerSearchItem select {
    font-size: 0.5em;
  }
}

@media (max-width: 767px) {
  .studentAndCourseSearch {
    padding: 0% 3% 0% 3%;
  }

  .studentAndCourseSearch > div {
    margin: 0.8em 0.8em;
  }

  .containerSearchItem {
    width: 30vw;
  }

  .containerSearchItem input {
    width: 13vw;
  }

  .containerSearchItem select {
    width: 13vw;
  }

  .containerSearchItem input {
    font-size: 0.6em;
  }

  .containerSearchItem select {
    font-size: 0.6em;
  }
  .resetButton {
    margin-top: 13px;
    height: 33px;
    margin-left: 3%;
    font-size: 0.75em;
  }
}

@media (max-width: 660px) {
  .containerSearchItem input {
    width: 13vw;
    font-size: 0.5em;
  }

  .containerSearchItem select {
    width: 13vw;
    font-size: 0.5em;
  }
}

@media (max-width: 576px) {
  .containerSearchItem input {
    font-size: 0.5em;
    margin-left: 0em;
    margin-bottom: 0.4em;
    width: 39vw;
    padding: 7px 6px 8px 6px !important;
  }

  .containerSearchItem select {
    margin-left: 0em;
    font-size: 0.5em;
    margin-bottom: 0.4em;
    width: 39vw;
    padding: 6px 6px 8px 6px !important;
  }
}

@media (max-width: 500px) {
  .containerSearchItem input {
    font-size: 0.5em;
    margin-left: 0em;
    margin-bottom: 0.4em;
    width: 37vw;
    padding: 7px 6px 8px 6px !important;
  }

  .containerSearchItem select {
    margin-left: 0em;
    font-size: 0.5em;
    margin-bottom: 0.4em;
    width: 37vw;
    padding: 6px 6px 6px 6px !important;
  }
}

@media (max-width: 480px) {
  .resetButton {
    padding: 0% !important;
    font-size: 0.6em;
    height: 20px;
    width: 13%;
    margin-top: 4.5%;
  }
}

@media (max-width: 467px) {
  .containerSearchItem input {
    width: 40vw;
  }

  .containerSearchItem select {
    width: 40vw;
  }
}

@media (max-width: 450px) {
  .containerSearchItem input {
    width: 39vw !important;
    font-size: 0.6em;
    margin-left: 0em;
    margin-bottom: 0.4em;
    padding: 5px 6px 5px 6px !important;
  }
  .containerSearchItem select {
    width: 39vw !important;
    font-size: 0.6em;
    margin-left: 0em;
    margin-bottom: 0.4em;
    padding: 5px 6px 5px 6px !important;
  }
}
@media (max-width: 400px) {
  .containerSearchItem input {
    width: 37vw !important;
  }
  .containerSearchItem select {
    width: 37vw !important;
  }
}
