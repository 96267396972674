.expertImageContainer {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.expertImageContainer>img {
  width: 100%;
  border-radius: 5px;
}

.expertCarouselContainer {
  display: none;
}

.carouselImage {
  width: 100%;
  border-radius: 5px;
}

.mobileCarousel {
  display: none;
}

@media (min-width: 767px) and (max-width: 1290px) {
  .expertImageContainer {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  .expertImageContainer {
    display: none;
  }

  .expertCarouselContainer {
    display: block;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .mobileCarousel {
    display: block;
  }
}