.closeButton {
  background: transparent;
  border: none;
  font-size: 2em;
  padding: 0%;
  display: flex;
  margin-top: -0.4em;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.closeButton:hover {
  color: var(--qrencia-error-message);
}

.pricingCard {
  position: relative;
  background-color: var(--qrencia-yellow-background);
  border: 2px solid var(--qrencia-text-black);
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px 18px;
}

.verifyOtpText {
  position: absolute;
  right: 6px;
  margin-top: -8px;
  cursor: pointer;
  z-index: 100;
}

.verifyOtpText p {
  font-weight: 500 !important;
  font-size: 0.8em;
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-select-box-background);
  padding: 0 3px;
}

.popularTag {
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--qrencia-popular-yellow-tag);
  color: var(--qrencia-white);
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 8px;
  z-index: 1;
}

.modalBody {
  padding: 3rem 3rem;
}

.planTitle {
  font-size: 1.33rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
}

.plan-price {
  font-size: 2.369rem;
}

.originalPrice {
  color: rgb(14, 14, 14);
  margin-right: 0.5rem;
}

.originalYearlyPrice {
  text-decoration: line-through;
  color: var(--qrencia-price-text);
  margin-right: 0.5rem;
}

.discountedPrice {
  color: rgb(14, 14, 14);
}

.monthTitle {
  font-size: 1rem;
  color: var(--qrencia-grey-text);
  font-weight: 500 !important;
}

.billingInfo {
  font-size: 1rem;
  font-weight: 400;
}

.checkoutButton {
  width: 100%;
  height: 2.5rem;
  border-radius: 0.4rem;
  transition: filter 0.3s ease;
}

.checkoutButton:hover {
  filter: brightness(1.2);
}

.savingsInfo {
  color: var(--qrencia-success-message);
  font-size: 0.75rem;
  margin-bottom: 2.2rem;
}

.featuresList {
  list-style: none;
  padding: 1rem 0 0;
}

.formInputBox {
  width: 100%;
  padding: 7px 6px 7px 6px !important;
}

.featuresList li {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.checkmark {
  color: var(--qrencia-dark-blue);
  margin-right: 8px;
  /* font-size: 1.2rem; */
}

.successMessage {
  color: var(--qrencia-success-message);
  text-align: left;
  font-size: 0.8em;
}

.errorMessage {
  color: var(--qrencia-error-message);
  text-align: left;
  font-size: 0.8em;
}

.referralInputGroup {
  margin-top: 1.2em;
}

@media (min-width: 768px) and (max-width: 899px) {
  .formInputBox {
    width: 100%;
  }
}

@media (min-width: 559px) and (max-width: 767.5px) {
  .formInputBox {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 560px) {
  .formInputBox {
    width: 100%;
  }

  .modalBody {
    padding: 2rem 2rem;
  }

  .pricingCard {
    padding: 20px 0px;
  }
}