.paddingLeftZero {
  padding-left: 0 !important;
}

.courseCardSection {
  display: flex;
}

.cardBody {
  padding: 0;
}

.courseCard {
  padding: 0.8em;
  max-width: 15em;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.courseCard img {
  height: 7.9em;
}

.cardImage {
  border-radius: 8px;
}

.courseName {
  display: flex;
  justify-content: space-between;
  font-weight: 600 !important;
  margin: 0.5em 0 0.3em 0;
  font-size: 0.9em;
}

.uniqueIdText {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 0.7em;
  padding: 0 2px;
  border-radius: 4px;
  font-weight: 500 !important;
  background-color: var(--qrencia-white);
}

.compTimeAndDate {
  font-size: 0.6em;
  display: flex;
  font-weight: 600 !important;
  margin: 0.4em 0;
}

.buttonSection {
  margin: 0.4em 0;
  display: flex;
  gap: 1em;
}

.viewButton {
  font-size: 0.75em;
  padding: 4px;
  width: 7em;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  text-align: center;
  font-weight: 400 !important;
  border-radius: 3px;
  margin: 0.3em 0;
}

.cancelButton,
.cancelButton:hover,
.cancelButton:active,
.cancelButton:focus {
  font-size: 0.75em;
  padding: 4px;
  width: 7em;
  background-color: var(--cancel-red-button) !important;
  color: var(--qrencia-white) !important;
  text-align: center;
  font-weight: 400 !important;
  border-radius: 3px;
  margin: 0.3em 0;
  border: none;
}

.modalYesButton,
.modalYesButton:hover,
.modalYesButton:active,
.modalYesButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--qrencia-success-green) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalNoButton,
.modalNoButton:hover,
.modalNoButton:active,
.modalNoButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--cancel-red-button) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalCloseButton,
.modalCloseButton:hover,
.modalCloseButton:active,
.modalCloseButton:focus {
  font-size: 0.75em;
  width: 6em;
  background-color: var(--qrencia-dark-blue) !important;
  color: var(--qrencia-white) !important;
  border: none;
  padding: 4px;
  border-radius: 3px;
}

.modalAlertIcon {
  font-size: 3em;
  color: var(--cancel-red-button);
  text-align: center;
}

.modalSuccessIcon {
  font-size: 3em;
  color: var(--qrencia-success-green);
  text-align: center;
}

.modalErrorIcon {
  font-size: 3em;
  color: var(--qrencia-error-red);
  text-align: center;
}

.modalAlertText {
  font-size: 0.9em;
  text-align: center;
}

.modalBody {
  padding: 1.5em 1em 1em;
}

.modalFooter {
  display: flex;
  justify-content: center;
  padding: 0.5em 1em 1.5em 1em;
  border: none;
  gap: 1em;
}

.courseUserProfileImageSection {
  display: flex;
  gap: 5px;
}

.courseEnrolledCountSection {
  font-weight: 600;
  font-size: 0.65em;
  width: 64%;
  margin-top: 0.5em;
  width: max-content;
}

.onlineIcon {
  margin-left: auto;
  color: var(--qrencia-dark-blue) !important;
}

.profileImage {
  width: 22px;
  height: 22px !important;
  right: 2%;
  border-radius: 18px;
  border: 1px solid var(--qrencia-white);
  position: relative;
  margin-right: -12%;
}

.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
}

.shareIconPannelContainer {
  display: grid;
  position: absolute;
  right: -1.15em;
  top: 40%;
  gap: 5px;
}

.shareIcon {
  font-size: 1.2em;
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  border-radius: 20px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 8em;
  padding: 2px;
}

.shareIcons {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  border-radius: 20px;
  cursor: pointer;
}

.shareOnMailButton {
  background-color: var(--qrencia-white);
  color: var(--qrencia-card-blue);
  font-size: 0.95em;
  cursor: pointer;
}

.showLinkCopiedIcon {
  color: var(--qrencia-success-green);
  font-size: 0.95em;
  font-weight: bold;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  cursor: pointer;
}

.courseCard a,
p {
  text-decoration: none;
  color: var(--qrencia-text-black);
  font-weight: 600;
}

.loadMoreDiv {
  padding: 2em;
}

.loadMore {
  text-align: center;
}

.loadMorebtn {
  background: var(--qrencia-white);
  color: var(--qrencia-text-black);
  padding: 4px 1em;
  text-decoration: none;
  border-radius: 1em;
  font-size: 0.8em;
  font-weight: 500;
  border: 1px solid var(--qrencia-dark-grey-border) !important;
}

.loadMorebtn:hover {
  color: var(--qrencia-text-black);
  background: var(--qrencia-grey-border);
}

.costIcon {
  transform: scaleX(1.45) scaleY(1.1);
  margin: -1px 2px 0 0;
}

.calendarIconOnMicrosite {
  margin: 1px 2px 0 0;
}

.endDateIcon {
  font-size: 1.2em !important;
  margin-left: -1px;
}

@media (min-width: 992px) and (max-width: 1290px) {
  .courseName {
    font-weight: 600 !important;
    text-align: left;
    font-size: 0.9em;
  }

  .courseCard img {
    height: 8em;
  }

  .shareIcon {
    right: 18px;
    top: 5.8em;
    padding: 2px;
  }

  .uniqueIdText {
    top: 20px;
    right: 20px;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .courseName {
    font-size: 0.9em;
  }

  .courseCard img {
    height: 8em;
  }

  .shareIcon {
    right: 20px;
    top: 6.2em;
    padding: 2px;
  }

  .uniqueIdText {
    top: 20px;
    right: 22px;
  }
}

@media (max-width: 767px) {
  .courseCard img {
    height: 8.2em;
  }

  .shareIcon {
    right: 20px;
    top: 6.2em;
    padding: 2px;
  }

  .uniqueIdText {
    top: 20px;
    right: 20px;
  }
}

@media (max-width: 580px) {
  .courseCard img {
    height: 8.2em;
  }

  .courseName {
    font-size: 0.8em;
  }

  .courseCard {
    margin-top: 0%;
    margin-bottom: 6%;
  }

  .shareIcon {
    right: 30px;
    top: 8em;
    padding: 2px;
  }

  .uniqueIdText {
    top: 30px;
    right: 30px;
  }
}

@media (max-width: 480px) {
  .loadMorebtn {
    font-size: 0.8em;
  }

  .shareIcon {
    right: 25px;
    top: 8.2em;
    padding: 2px;
  }

  .uniqueIdText {
    top: 25px;
    right: 25px;
  }
}

.spinner {
  animation: spin-animation 0.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@media (min-width: 992px) and (max-width: 1180px) {
  .shareIcon {
    right: 18px;
    top: 5em;
    padding: 2px;
  }
}

@media (min-width: 992px) and (max-width: 1180px) {
  .shareIcon {
    position: absolute;
    right: 18px;
    top: 5em;
    padding: 2px;
  }
}

@media (min-width:1181px) and (max-width: 1252px) {
  .shareIcon {
    position: absolute;
    right: 18px;
    top: 5.7em;
    padding: 2px;
  }
}

@media (min-width:992px) and (max-width: 1080px) {
  .shareIcon {
    position: absolute;
    right: 12px;
    top: 4.5em;
    padding: 2px;
  }
}

@media (min-width:898px) and (max-width: 940px) {
  .shareIcon {
    position: absolute;
    right: 20px;
    top: 8em;
    padding: 2px;
  }
}

@media (min-width:824px) and (max-width: 897.8px) {
  .shareIcon {
    position: absolute;
    right: 20px;
    top: 7em;
    padding: 2px;
  }
}

@media (min-width:767px) and (max-width: 823.8px) {
  .shareIcon {
    position: absolute;
    right: 20px;
    top: 6.2em;
    padding: 2px;
  }
}

@media (min-width:717px) and (max-width: 767px) {
  .shareIcon {
    position: absolute;
    right: 20px;
    top: 10em;
    padding: 2px;
  }
}

@media (min-width:665px) and (max-width: 716.8px) {
  .shareIcon {
    position: absolute;
    right: 20px;
    top: 9em;
    padding: 2px;
  }
}

@media (min-width:611px) and (max-width: 664.8px) {
  .shareIcon {
    right: 20px;
    top: 8em;
  }
}

@media (min-width:579px) and (max-width:610.8px) {
  .shareIcon {
    top: 7.5em;
    padding: 2px;
  }
}

@media (min-width:575px) and (max-width:579px) {
  .shareIcon {
    top: 7.5em;
    padding: 2px;
  }
}

@media (min-width:438px) and (max-width:455px) {
  .shareIcon {
    top: 12em;
  }
}

@media (min-width:408px) and (max-width:437.8px) {
  .shareIcon {
    top: 11em;
  }
}

@media (min-width:360px) and (max-width: 407.88px) {
  .shareIcon {
    top: 9.6em;
  }
}

@media (min-width:1290px) and (max-width: 1495px) {
  .shareIcon {
    top: 6.6em !important;
  }
}