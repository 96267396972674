.summaryListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  position: relative;
}

.studentName {
  flex-grow: 1; /* Ensures text takes available space */
}
.awardsParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.awardsList {
  font-size: 0.6em;
  border-radius: 50%;
  align-items: right;
  justify-content: right;
  display: flex;
}

.noStudentsSelected {
  font-size: 1em;
}

.closeButton {
  margin-left: 10px; /* Creates spacing between text and button */
  padding: 5px 6px; /* Adjusts button size */
  font-size: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeButton:hover {
  border: none;
}

.crossCloseButton {
  font-size: 1.2em;
  position: absolute;
  cursor: pointer;
  transition: all 0.3s ease;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
}
.crossCloseButton:hover {
  color: var(--qrencia-astrick-red);
}

.studentsToBeAwardedCardsSection {
  background: var(--qrencia-white);
  display: flex;
  padding: 4% 1%;
}

.cardBody {
  padding: 7% 5% 3% 5%;
  margin-top: 3%;
  margin-bottom: 2%;
}

.studentCard {
  margin-top: 4%;
  margin-bottom: 2%;
}
.userImage {
  max-width: 100%;
  height: 20vh;
  object-fit: cover;
  border: 0.01em solid var(--qrencia-grey-border);
  border-radius: 3%;
}
.userImageContainer {
  display: flex;
  justify-content: center;
}
.userInitialsContainer {
  display: flex;
  justify-content: center;
  border-radius: 3%;
  background-color: var(--qrencia-learning-activity-tabs-border);
}
.userInitials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
  border: 0.1em solid var(--qrencia-learning-activity-tabs-border);
  border-radius: 5%;
  font-weight: 400;
  font-size: 1.5em;
  color: white;
}
.studentName {
  font-weight: 500 !important;
  font-size: 0.7em !important;
  margin-top: 6%;
  color: var(--qrencia-text-black) !important;
}
.courseName {
  font-weight: 300 !important;
  font-size: 0.6em !important;
  color: var(--qrencia-text-black) !important;
}
.summaryListItem .courseName {
  font-size: 0.7em !important;
  margin-right: 10px;
}
.qid {
  font-weight: 300 !important;
  font-size: 0.6em !important;
  display: flex;
  color: var(--qrencia-text-black) !important;
}
.studentCard a,
p {
  text-decoration: none;
  color: var(--qrencia-text-black);
  font-weight: 700;
}
.studentCard {
  cursor: pointer;
}

.selectedCard {
  border: 0.1em solid var(--qrencia-dark-blue) !important; /* Qrencia Dark Blue */
  transition: border 0.3s ease-in-out !important;
}
.updateButton {
  font-size: 0.8em;
  padding: 1%;
}
.summaryHeading {
  font-size: 1rem;
  font-weight: 600;
  color: var(--qrencia-text-black);
  margin-bottom: 3rem;
  margin-top: 0.5rem;
}
.summaryList {
  font-size: 0.8rem;
}
.loadMoreDiv {
  padding: 1em;
}

.loadMore {
  text-align: center;
  background: var(--qrencia-white);
}

.loadMorebtn {
  background: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
  padding: 0.5em 2em;
  text-decoration: none;
  letter-spacing: 0.03rem;
  color: var(--qrencia-white);
  background: var(--qrencia-dark-blue);
  border-radius: 0.3em;
  border: 0.1em solid var(--qrencia-dark-blue);
  font-size: 0.8em;
  font-weight: 500;
}

.loadMorebtn:hover {
  color: var(--qrencia-text-black);
  background: var(--qrencia-white);
  border: 0.1em solid var(--qrencia-text-black);
}

.name {
  font-weight: 600;
  margin-right: 8px;
}

@media (min-width: 767px) and (max-width: 1290px) {
  .studentsCardsSection {
    padding: 1% 9% 1% 9%;
  }
}
@media (max-width: 1226px) {
  .crossCloseButton {
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
  }
}

@media (max-width: 998px) {
  .noStudentsSelected {
    font-size: 0.9em;
  }
}

@media (max-width: 900px) {
  .summaryHeading {
    font-size: 0.9em;
    margin-bottom: 2rem;
  }

  .crossCloseButton {
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
  }

  .summaryListItem .courseName {
    font-size: 0.6em !important;
  }

  .noStudentsSelected {
    font-size: 0.8em;
  }
}

@media (max-width: 800px) {
  .name {
    font-size: 0.9em;
  }
}

@media (max-width: 773px) {
  .summaryHeading {
    font-size: 0.8em;
    margin-bottom: 2rem;
  }

  .summaryListItem .courseName {
    font-size: 0.6em !important;
  }
}

@media (max-width: 767px) {
  .studentsCardsSection {
    padding: 1% 3% 1% 3%;
  }
  .crossCloseButton {
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
  }
}

@media (max-width: 710px) {
  .summaryHeading {
    font-size: 0.7em;
    margin-bottom: 2rem;
  }
}

@media (min-width: 1120px) and (max-width: 1290px) {
  .ageRange {
    background: var(--qrencia-grey-border);
    border-radius: 1.2rem;
    font-size: 0.68em;
    font-weight: 600;
    padding: 1.4% 4%;
    text-align: center;
  }

  .studentName {
    font-weight: 700 !important;
    text-align: left;
    margin-top: 6%;
    font-size: 1em;
  }
}

@media (min-width: 767px) and (max-width: 1119px) {
  .ageRange {
    background: var(--qrencia-grey-border);
    border-radius: 1.2rem;
    font-size: 0.68em;
    font-weight: 600;
    padding: 1.4% 4%;
    text-align: center;
  }

  .studentName {
    font-size: 0.9em;
  }
}

@media (max-width: 767px) {
  .ageRange {
    background: var(--qrencia-grey-border);
    border-radius: 1.2rem;
    font-size: 0.7rem;
    font-weight: 700;
    padding: 1.2% 4%;
  }
}

@media (max-width: 650px) {
  .summaryHeading {
    font-size: 0.6em;
    margin-bottom: 2rem;
  }

  .name {
    font-size: 0.9em;
  }

  .noStudentsSelected {
    font-size: 0.7em;
  }
}
@media (max-width: 585px) {
  .summaryHeading {
    font-size: 0.6em;
    margin-bottom: 1rem;
  }
  .name {
    font-size: 0.8em;
    margin-bottom: 0.2em;
  }
}

@media (max-width: 580px) {
  .ageRange {
    border-radius: 1.2rem;
    font-size: 0.68em;
    font-weight: 600;
    padding: 1.4% 5%;
    text-align: center;
  }

  .studentName {
    font-size: 0.8em;
  }

  .studentCard {
    margin-top: 0%;
    margin-bottom: 6%;
  }
}

@media (max-width: 576px) {
  /* .summaryListItem .courseName {
    display: none;
  } */
  .crossCloseButton {
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
    font-size: 12px;
  }
  .name {
    font-size: 0.7em;
  }
  .summaryListItem {
    padding: 6px;
  }
}

@media (max-width: 490px) {
  .summaryHeading {
    font-size: 0.7em;
  }
  .summaryHeading {
    margin-top: 0.2rem;
  }
}

@media (max-width: 480px) {
  .loadMorebtn {
    border-radius: 0.2em;
    padding: 1.5% 6%;
    letter-spacing: 0.03rem;
    font-size: 0.7em;
    font-weight: 500;
  }
}
@media (max-width: 440px) {
  .summaryHeading {
    font-size: 0.6em;
  }
}
@media (max-width: 425px) {
  .name {
    font-size: 0.6em;
  }
}
@media (max-width: 410px) {
  .summaryHeading {
    font-size: 0.5em;
  }
}
@media (max-width: 400px) {
  .name {
    font-size: 0.5em;
  }
}
