.dragDropContainer {
  width: 95%;
  height: fit-content;
  margin: 1em auto;
  position: relative;
}
.dragDropContainerInactive {
  opacity: 0.6;
  cursor: not-allowed;
}
.uploadFileText {
  font-size: 0.9em !important;
  font-weight: 500 !important;
}
.dropFile {
  padding: 1em;
  width: 100%;
  height: 15em;
  margin: 0.5em auto;
  padding: auto;
  border: 0.15em dashed var(--qrencia-file-upload-container-border);
  position: relative;
  background-color: var(--qrencia-file-upload-container-bg);
}
.dropFile h3 {
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
  color: var(--qrencia-text-black);
  margin-top: 3%;
}
.dropFile > p {
  font-size: 0.8em;
  font-weight: 400;
  text-align: center;
  color: var(--qrencia-text-black);
  position: absolute;
  top: 82%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dropFile > input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.dropFile > svg {
  height: 6em;
  width: 6em;
  color: var(--qrencia-text-black);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.separatorLine {
  position: relative;
  max-width: 90%;
  margin: 0em auto 1em auto;
  text-align: center;

  font-size: 1.1em;
}

.separatorLine:before {
  content: " ";
  height: 2px;
  width: 45%;
  background: var(--qrencia-dark-blue);
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}
.dragDropContainerInactive {
  opacity: 0.3;
}
.separatorLine:after {
  content: " ";
  height: 2px;
  width: 45%;
  background: var(--qrencia-dark-blue);
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}
.enterProblemStatementText {
  text-align: left;
  margin-bottom: 0.8em;
  font-size: 0.9em;
}
.textEditorContainer {
  width: 95%;
  margin: 0.5em auto;
}
.textEditorContainerInactive {
  opacity: 0.7;
  cursor: not-allowed;
}
.textEditor {
  height: 60%;
  margin-top: -18px;
  font-size: 1.2em;
}
.textEditorLabel {
  background: var(--qrencia-white) !important;
  border: none;
  outline: none;
}
.nextBtn {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 97%;
  padding: 0.6em;
  text-align: right;
}
.nextBtn > Button {
  font-size: 0.8em;
  padding: 0.6em 2em;
}
.problemStatementBtnContainer {
  text-align: left;
  margin-left: 1em;
}
.problemStatementBtn {
  border: none;
  outline: none;
  color: var(--qrencia-white);
  background: var(--qrencia-dark-blue);
  font-size: 0.75em;
  padding: 0.4em 1em;
  margin: 0.5em 1em;
  border-radius: 3px;
}
.problemStatementBtn > svg {
  margin-right: 0.5em;
}
.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  z-index: 10;
  max-width: 32em;
}
@media (max-width: 991px) {
  .dropFile {
    padding: 0.8em;
    width: 100%;
    height: 15em;
  }

  .dropFile > svg {
    height: 5em;
    width: 5em;
  }

  .dropFile h3 {
    font-size: 1em;
    margin-top: 8%;
  }
  .dropFile > p {
    font-size: 0.9em;
  }
  .separatorLine {
    font-size: 1em;
  }
  .textEditor {
    height: 50%;
    font-size: 1em;
  }
  .uploadFileText {
    font-size: 1em;
    font-weight: 550;
    margin-top: 2em;
  }
  .enterProblemStatementText {
    font-size: 0.9em;
    font-weight: 550;
  }
  .nextBtn {
    margin-bottom: 1.5em;
    width: 99%;
  }
  .nextBtn > Button {
    margin-right: auto;
    font-size: 0.8em;
    padding: 0.6em 2em;
  }
}
@media (max-width: 767px) {
  .dragDropContainer {
    height: 18.5em;
  }
  .dragDropContainer h4 {
    font-size: 1.2em;
    margin-bottom: 1em;
    margin-top: 1em;
  }
  .dropFile {
    padding: 0.8em;
    width: 90%;
    height: 15em;
    margin: 0.5em auto;
  }
  .dropFile h3 {
    font-size: 0.9em;
    margin-top: 8%;
  }
  .dropFile > p {
    font-size: 0.9em;
  }
  .dropFile > svg {
    height: 4em;
    width: 4em;
  }
  .separatorLine {
    font-size: 1.2em;
  }
  .textEditorContainer {
    width: 90%;
    margin: 0.5em auto;
    margin-bottom: 2em;
  }
  .textEditorContainer h4 {
    font-size: 1.2em;
  }
  .textEditor {
    height: 60%;
    font-size: 1.3em;
  }
}

@media (max-width: 540px) {
  .dragDropContainer {
    height: fit-content;
  }
  .uploadFileText {
    font-size: 0.9em;
    font-weight: 550;
    margin-top: 2em;
    margin-bottom: 0em;
  }
  .enterProblemStatementText {
    font-size: 0.9em;
    font-weight: 550;
    text-align: left;
  }
  .dragDropContainer h4 {
    font-size: 0.9em;
    margin-bottom: 1em;
  }
  .dropFile {
    padding: 1em;
    width: 100%;
    height: 12em;
    margin: 0.5em auto;
  }
  .dropFile h3 {
    font-size: 0.8em;
    margin-top: 8%;
  }
  .dropFile > p {
    font-size: 0.6em;
  }
  .dropFile > svg {
    height: 2.5em;
    width: 2.5em;
  }
  .separatorLine {
    font-size: 0.9em;
  }
  .textEditorContainer {
    width: 90%;
    margin: 0.5em auto;
    margin-bottom: 3em;
  }
  .textEditorContainer h4 {
    font-size: 1em;
  }
  .textEditor {
    height: 60%;
    font-size: 1.2em;
  }
  .nextBtn {
    margin-top: 1.5em;
    width: 100%;
    padding: 0.6em;
    text-align: center;
  }
  .nextBtn > Button {
    font-size: 0.8em;
    padding: 0.6em 2em;
  }
}
@media (max-width: 480px) {
  .tooltipMessage {
    font-size: 0.8em;
    max-width: 28em;
  }
}
