.expertCarouselContainerImage .react-multi-carousel-item,
.expertCarouselContainerImage .react-multi-carousel-item:hover,
.expertCarouselContainerImage .react-multi-carousel-item:active,
.expertCarouselContainerImage .react-multi-carousel-item:focus,
.expertCarouselContainerImage .react-multi-carousel-item:visited {
  padding: 0px 5px !important;
  margin: 0%;
}
.expertCarouselContainerImage .react-multi-carousel-track {
  padding: 0%;
}
.eoiBannerCarouselContainerImage .react-multi-carousel-item,
.eoiBannerCarouselContainerImage .react-multi-carousel-item:hover,
.eoiBannerCarouselContainerImage .react-multi-carousel-item:active,
.eoiBannerCarouselContainerImage .react-multi-carousel-item:focus,
.eoiBannerCarouselContainerImage .react-multi-carousel-item:visited {
  padding: 0px 1px !important;
  margin: 0%;
}
.eoiBannerCarouselContainerImage .react-multi-carousel-track {
  padding: 0%;
}
.eoiBannerCarouselContainerImage .react-multiple-carousel__arrow::before,
.eoiBannerCarouselContainerImage .react-multiple-carousel__arrow::after {
  display: none !important;
}

.eoiBannerCarouselContainerImage .react-multi-carousel-dot button,
.expertCarouselContainerImage .react-multi-carousel-dot button {
  border: 1px solid var(--qrencia-dark-blue);
  height: 7px;
  width: 7px;
}
