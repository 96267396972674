.obrBackButton {
  font-size: 1.5em;
  color: var(--qrencia-white);
  background-color: var(--qrencia-dark-blue);
  border-radius: 1em;
  cursor: pointer;
}
.actionButton {
  font-size: 0.9em !important;
  font-weight: 500 !important;
  padding: 5px 8px;
  margin-top: -0.34em;
  color: var(--qrencia-text-black);
  background-color: var(--qrencia-attendance-action-button-color);
  border: 1px solid var(--qrencia-attendance-action-button-color) !important;
  outline: var(--qrencia-attendance-action-button-color) !important;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 4px;
  cursor: pointer;
}
.showGridSection {
  display: block;
}
.showCardsSection {
  display: none;
  max-height: 50em;
  background-color: #f8f9f9;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.loginSection {
  margin-top: 4em;
}
.infoSection {
  padding: 0em 0.5em;
}
.warningSection {
  padding: 0em 0.5em;
}
.showCardsSection::-webkit-scrollbar {
  display: none;
}

.cardActionButton {
  font-size: 0.75em !important;
  font-weight: 500 !important;
  padding: 5px 8px;
  color: var(--qrencia-text-black);
  background-color: var(--qrencia-attendance-action-button-color);
  border: 1px var(--qrencia-attendance-action-button-color) !important;
  outline: var(--qrencia-attendance-action-button-color) !important;
  margin-right: 5px;
  border-radius: 4px;
  cursor: pointer;
}
.markAllButton {
  font-size: 0.75em !important;
  font-weight: 500 !important;
  padding: 5px 8px;
  color: var(--qrencia-text-black);
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 5px;
}
.cardButtonSection {
  margin-top: 1em;
}
.courseTimings {
  font-size: 1em !important;
  font-weight: 500 !important;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  color: var(--qrencia-dark-blue);
  cursor: pointer;
}
.reminderButton {
  font-size: 0.95em;
  margin-left: 8px;
}
.reminderButtonCard {
  font-size: 0.8em;
}
.disabledMarkButton {
  cursor: not-allowed;
  opacity: 0.7;
}
.pageContainer {
  background-color: var(--qrencia-white);
  border-radius: 10px;
  width: 90%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 1em auto;
  padding: 1.5em;
}
.statusSpan {
  font-size: 0.8em;
  padding: 2px 5px;
  background-color: var(--qrencia-attendance-action-button-color);
  border-radius: 3px;
  margin-right: 1em;
}
.activeSpan {
  color: var(--qrencia-success-green);
}
.expiredSpan {
  color: var(--qrencia-error-red);
}
.classesLeftText {
  font-size: 0.8em;
  margin-top: 0.8em;
}
.lastSubscribedText {
  font-size: 0.78em;
  margin-top: 5px;
}
.markAllButtonSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.absentButton {
  background-color: var(--qrencia-absent-button-color);
  color: var(--qrencia-white);
}
.presentButton {
  background-color: var(--qrencia-present-button-color);
  color: var(--qrencia-white);
}
.markAttendanceHeading {
  font-size: 1.2em;
  margin-top: 1em;
  font-weight: 500;
}
.learnerDetailsHeading {
  font-size: 1em;
  margin-top: 0.7em;
  font-weight: 600;
}
.courseTitle {
  font-size: 1.1em;
  font-weight: 600 !important;
}
.attendanceSummary {
  /* margin: 1.5em 1em; */
  margin-top: 1em;
}

.summaryDescription {
  font-size: 0.85em;
  font-weight: 500;
}
.attendanceSummaryHeading {
  font-weight: 600;
  font-size: 0.9em;
}
.saveButtonRow {
  justify-content: flex-end;
  padding-right: 1em;
}
.saveAttendaceButton {
  font-size: 0.75em;
  padding: 5px 8px;
  width: 8em;
  margin-left: 10px;
  margin-top: 5px;
}
.backButtonDiv {
  background-color: var(--qrencia-dark-blue);
  border-radius: 50%;
  height: 1.6em;
  width: 1.6em;
  margin-top: -1em;
  margin-bottom: 0.8em;
  margin-left: 0.6em;
  position: relative;
  cursor: pointer;
}
.backButtonDiv > svg {
  color: var(--qrencia-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8em;
}

.loaderContainer {
  padding: 2em;
  border-radius: 10px;
  border: 1px solid var(--qrencia-grey-border);
  width: 80%;
  margin: 1em auto;
  text-align: center;
  background-color: var(--qrencia-white);
}

.hyphenText {
  display: contents;
}

.thumbnailSection {
  padding: 1.3%;
  display: flex;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  margin: 1em 0em;
  border: 1px solid var(--qrencia-light-grey-border);
  font-size: 0.9em;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.thumbnailContainer {
  width: 12em;
}

.thumbnail {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  aspect-ratio: 825/550;
}

.thumbnailDetailsSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 1%;
  gap: 2px;
}

.courseDescription {
  font-size: 0.98em;
  font-weight: 400;
}
.location {
  display: contents;
  color: var(--qrencia-text-black);
  font-weight: 400 !important;
}
.dateContainer {
  display: flex;
  align-items: center;
}

.dateField {
  min-width: 16em;
}
.selectButton {
  font-size: 0.8em;
  padding: 5px 8px;
  margin-bottom: -0.8em;
  margin-left: 1em;
  height: 2.8em;
}
.cardContainer {
  margin-top: 0.3em;
  margin-bottom: 0.5em;
  padding: 0.6em 0.8em;
}
.profileImageSection {
  display: flex;
  justify-content: flex-start;
}
.nameInitials {
  height: 2.5em;
  width: 2.5em;
  background-color: var(--qrencia-course-skill-name-color);
  color: var(--qrencia-text-black);
  text-align: center;
  font-size: 0.9em;
  line-height: 2.5em;
  font-weight: 500;
  min-width: 2.5em;
  margin-right: 1em;
}

.learnerName {
  font-size: 0.82em;
  font-weight: 450 !important;
  color: var(--qrencia-dark-blue) !important;
}
.emailText {
  font-size: 0.72em;
  font-weight: 450 !important;
  opacity: 0.6;
  color: var(--qrencia-text-black) !important;
  word-wrap: break-word;
}

.cardContainer {
  height: fit-content;
  padding: 0.8em;
  margin: 1em 0em;
  background-color: var(--qrencia-white);
  box-shadow: var(--qrencia-template-box-shadow);
  transition: 0.5s all ease-in-out;
  border: 1px solid var(--qrencia-nudges-card-border);
}

.profilePicture {
  height: 2.4em;
  width: 2.4em;
}
.noLearnersWarningMessage {
  margin: 1em 0;
  background-color: var(--qrencia-extended-page-fun-fact-background);
  border-radius: 6px;
  border: 1px solid var(--instagram-yellow);
  width: max-content;
}
.dateAttendanceSummarySection {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.noLearnersWarningMessage svg {
  font-size: 1.5em !important;
  padding: 0;
  color: var(--instagram-yellow);
}
.markButtonRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}
.noLearnersWarningMessage p {
  font-size: 0.75em !important;
  font-weight: 500 !important;
  color: var(--instagram-yellow);
  padding: 0.5em;
}
.loaderIcon {
  font-size: 3em !important;
  color: var(--qrencia-dark-blue) !important;
}
.sessionExpiredText {
  font-size: 1em;
}
.sessionExpiredText > span {
  color: var(--linkedin-blue);
  font-weight: 600 !important;
  cursor: pointer;
}
.remainingClassCounterText {
  min-width: 5em;
}
.counterCardButton {
  font-size: 0.7em !important;
  font-weight: 500 !important;
  padding: 3px 6px;
  color: var(--qrencia-white);
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
}
@media (min-width: 1700px) {
  .learnerName {
    font-size: 0.98em;
    font-weight: 450 !important;
    color: var(--qrencia-dark-blue) !important;
  }
  .profilePicture {
    height: 2.6em;
    width: 2.6em;
  }
  .nameInitials {
    height: 2.6em;
    width: 2.6em;
  }
}
@media (min-width: 991px) and (max-width: 1290px) {
  .courseTitle {
    font-size: 1.25em;
  }

  .courseDescription {
    font-size: 0.94em;
  }
}
@media (max-width: 991px) {
  .showGridSection {
    display: none;
  }
  .showCardsSection {
    display: block;
  }
}

@media (max-width: 767.5px) {
  .thumbnailContainer {
    width: 10em;
  }

  .courseTitle {
    font-size: 1.02em;
  }

  .courseDescription {
    font-size: 0.85em;
  }

  .thumbnailSection {
    padding: 2.3%;
  }

  .thumbnailDetailsSection {
    padding-left: 2%;
  }
  .emailText {
    word-wrap: break-word;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .thumbnailContainer {
    width: 10em;
  }

  .courseTitle {
    font-size: 1.2em;
  }

  .thumbnailContainer {
    width: 10em;
  }

  .courseDescription {
    font-size: 0.9em;
  }
}
@media (max-width: 670px) {
  .dateAttendanceSummarySection {
    display: block;
  }
  .markButtonRow {
    display: block;
    margin-bottom: 1em;
  }
  .markAllButtonSection {
    justify-content: flex-start;
    padding-left: 0px !important;
  }
  .markAllButton {
    margin-right: 10px;
    margin-left: 0px;
  }
  .saveAttendaceButton {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
}
@media (max-width: 580px) {
  .noLearnersWarningMessage svg {
    font-size: 1.2em !important;
  }
  .thumbnailSection {
    margin: 1em 0.5em;
  }
  .backButtonDiv {
    margin-top: 0em;
  }
  .noLearnersWarningMessage p {
    font-size: 0.6em !important;
  }
  .emailText {
    word-wrap: break-word;
  }
}
@media (max-width: 500px) {
  .thumbnail {
    height: -webkit-fill-available;
    object-fit: fill;
  }
}
@media (max-width: 480px) {
  .thumbnailSection {
    flex-wrap: wrap;
    margin-bottom: 5%;
  }

  .thumbnailDetailsSection {
    padding-left: 0;
    padding-top: 0.5em;
  }

  .thumbnailContainer {
    width: 100%;
  }

  .thumbnailSection {
    padding: 3.3%;
  }

  .courseTitle {
    font-size: 1em !important;
  }

  .courseDescription {
    font-size: 0.8em;
  }
  .dateField {
    min-width: 12em;
  }
  .emailText {
    word-wrap: break-word;
  }
  .markAttendanceHeading {
    font-size: 1em;
    margin-top: 1em;
    font-weight: 500;
  }
}
@media (max-width: 400px) {
  .nameInitials {
    height: 2.4em;
    width: 2.4em;
    font-size: 0.85em;
    line-height: 2.4em;
    font-weight: 500;
    min-width: 2.4em;
    margin-right: 1em;
  }
  .emailText {
    word-wrap: break-word;
  }
  .learnerName {
    font-size: 0.7em;
  }
  .markAllButtonSection {
    justify-content: center;
  }
  .markAllButton {
    width: 80%;
    text-align: center;
  }
  .saveAttendaceButton {
    width: 80%;
  }
  .saveAttendaceButton {
    margin-left: -10px !important;
  }
}
