.preLoaderContainer {
  height: 100vh;
  width: 100%;
  position: relative;
}

.preLoaderDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrenciaGlobe {
  width: 80px;
  margin-top: -98px;
}

.loaderText {
  padding: 2em 0 0 0;
  font-size: 1em;
  text-align: center;
}

.loaderIcon {
  font-size: 6em !important;
  color: var(--qrencia-dark-blue) !important;
  text-align: center;
}

.spinner {
  animation: spin-animation 0.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.studentWeakAspectCover {
  background-color: var(--performance-page-weak-aspects-bg);
  width: 100%;
  height: 100vh;
}

.studentRaiseNudgeCover {
  background-color: var(--performance-page-raise-nudge-bg);
  width: 100%;
  height: 100vh;
}

.studentWeakAspectContainer {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.bgImageLeft {
  position: absolute;
  top: 2em;
  left: 2em;
  width: 10%;
}

.bgImageRight {
  position: absolute;
  bottom: 2em;
  right: 2em;
  width: 10%;
}

.contentHeadingContainer {
  padding-top: 19vh;
}

.contentHeading {
  text-align: center;
  font-size: 3.5em;
  color: var(--qrencia-white);
  padding: 0 1em;
}

.contentSubHeading {
  text-align: center;
  font-size: 2em;
  color: var(--qrencia-white);
}

.magnifyingHandImage {
  position: absolute;
  bottom: 0;
  width: 24vh;
  right: auto;
  left: auto;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .contentHeading {
    text-align: center;
    font-size: 2.8em;
    color: var(--qrencia-white);
    padding-top: 2vh;
  }

  .contentSubHeading {
    text-align: center;
    font-size: 1.6em;
    color: var(--qrencia-white);
  }
}

@media (max-width: 768.5px) {
  .contentHeading {
    text-align: center;
    font-size: 2.2em;
    color: var(--qrencia-white);
    padding-top: 0;
  }

  .contentSubHeading {
    text-align: center;
    font-size: 1.2em;
    color: var(--qrencia-white);
  }
}

@media (max-width: 580px) {
  .contentHeading {
    text-align: center;
    font-size: 2em;
    color: var(--qrencia-white);
    padding-top: 0;
  }

  .contentSubHeading {
    text-align: center;
    font-size: 1em;
    color: var(--qrencia-white);
    padding: 0 0.5em 0 0.5em;
  }

  .bgImageLeft {
    position: absolute;
    top: 2em;
    left: 2em;
    width: 16%;
  }

  .bgImageRight {
    position: absolute;
    bottom: 2em;
    right: 2em;
    width: 16%;
  }
}

.performancePageNavbar {
  background-color: yellow !important;
}

/* loder animation css */

.loader {
  --b: 8px;
  /* border thickness */
  --n: 12;
  /* number of dashes*/
  --g: 10deg;
  /* gap between dashes*/
  --c: var(--qrencia-dark-blue);
  /* the color */

  width: 120px;
  /* size */
  aspect-ratio: 1;
  border-radius: 100%;
  padding: 1px;
  background: conic-gradient(#0000, var(--c)) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: load 1s infinite steps(var(--n));
}

@keyframes load {
  to {
    transform: rotate(1turn);
  }
}
