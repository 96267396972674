.studentRaiseNudgeHeading {
  font-size: 1.025em;
  margin: 2% 0% 2% 2%;
  font-weight: 650;
  color: var(--qrencia-text-black);
}

.studentRaiseNudgeForm {
  background-color: var(--qrencia-white);
  padding: 1em;
  border-radius: 10px;
}

.studentRaiseNudgeFormInputBox {
  margin: 0 0 1em 0;
}

.studentRaiseNudgeFormInputBox .studentRaiseNudgeTypeahead .form-control {
  height: 3em;
  font-size: 0.8em !important;
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  border-right: none;
  margin-top: -18px;
  width: 100%;
  flex: 1;
  flex-grow: 2;
}
.studentRaiseNudgeForm .formLabel {
  position: relative;
}
.studentRaiseNudgeClearIcon {
  cursor: pointer;
  background: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
  height: 2.4em;
  margin-top: -1.12em;
  border-left: none;
  cursor: pointer;
  width: 1.2em;
}
.studentRaiseNudgeTypeaheadContainer {
  display: flex;
  width: 100%;
}
.studentRaiseNudgeTypeaheadContainer .studentRaiseNudgeTypeahead {
  flex: 2;
}
.studentRaiseNudgeTypeaheadContainer .studentRaiseNudgeTypeahead:focus,
.studentRaiseNudgeTypeaheadContainer .studentRaiseNudgeTypeahead:active {
  border-right: none;
}
.studentRaiseNudgeForm .dropdown-menu {
  inset: 10px auto auto -31px !important;
  margin-left: 2.2em;
  font-size: 0.9em;
  padding: 0;
  border-radius: 0;
  border: solid 1px var(--qrencia-select-box-border);
}

.removeFileOption {
  margin-left: 2px;
  font-size: 0.8em !important;
  margin-top: 2px;
}

.studentRaiseNudgeForm .dropdown-item:focus,
.studentRaiseNudgeForm .dropdown-item:hover,
.studentRaiseNudgeForm .dropdown-item:active,
.studentRaiseNudgeForm .dropdown-item:visited {
  background: var(--qrencia-select-box-background) !important;
}

.studentRaiseNudgeForm .descriptionLabel {
  font-weight: 500;
  font-size: 0.85em;
  margin-left: 6px;
  padding: 0em 0.3em;
  margin-bottom: 0;
  z-index: 1;
  background-image: linear-gradient(
    to bottom,
    var(--qrencia-white),
    var(--qrencia-select-box-background)
  );
  position: relative;
}

.StudentRaiseNudgeQuillInputBox {
  height: 100%;
  /* font-size: 1.2em !important; */
  margin-top: -10px !important;
}

.recommendationForm {
  height: 100%;
  margin-top: -17px !important;
}

.StudentRaiseNudgeQuillInputBox p,
.StudentRaiseNudgeQuillInputBox ul,
.StudentRaiseNudgeQuillInputBox ol {
  font-size: 1em !important;
}

.studentRaiseNudgeForm .ql-editor p {
  font-size: 1.02em;
}

.studentRaiseNudgeForm .ql-editor {
  border: solid 1px var(--qrencia-select-box-border);
  border-top: none !important;
  min-height: 14em;
}

.studentRaiseNudgeForm .ql-toolbar {
  border: solid 1px var(--qrencia-select-box-border);
  background: var(--qrencia-select-box-background);
}

.studentRaiseNudgeForm .ql-snow .ql-tooltip {
  z-index: 10;
}

.studentRaiseNudgeForm .ql-container.ql-snow {
  border: none !important;
}

.studentRaiseNudgeForm .submitButtonContainer {
  text-align: center;
  padding: 1em 0;
}

.studentRaiseNudgeForm .submitButton {
  font-size: 0.8em;
}

.studentRaiseNudgeForm .formErrorMessage {
  color: var(--qrencia-error-red);
  font-size: 0.8em;
  margin-bottom: 0;
}

.studentRaiseNudgeForm .uploadFileLabel {
  font-weight: 500;
  font-size: 0.85em;
  margin-left: 6px;
  padding: 0em 0.3em;
  margin-bottom: 0;
  position: absolute;
  z-index: 1;
  top: -15px;
  background-image: linear-gradient(
    to bottom,
    var(--qrencia-white),
    var(--qrencia-select-box-background)
  );
}

.raiseNudgeFileUpload {
  position: relative;
}

.raiseNudgeFileUpload input[type='file']::file-selector-button {
  position: absolute;
  right: -0.7em;
  top: -0.59em;
  height: 4em;
  line-height: 1.1em;
  z-index: 1;
  width: 9em !important;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

/* file input styles for mobiles */
@media (max-width: 500px) {
  .raiseNudgeFileUpload input[type='file']::file-selector-button {
    position: absolute;
    right: 0.6em;
    top: 0.4em;
    height: 2.2em;
    line-height: 1em;
    z-index: 1;
    width: 9em !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.studentRaiseNudgeBackButton {
  font-size: 1em;
  color: var(--qrencia-dark-blue) !important;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin-bottom: 0.5em;
}

.studentRaiseNudgeIButton {
  font-size: 1em;
  color: var(--qrencia-placeholder-color);
  outline: none;
}

.studentRaiseNudgeTooltip {
  z-index: 10;
  font-size: 0.9em;
  font-weight: 400 !important;
  color: var(--qrencia-text-black);
  background-color: var(--qrencia-nudges-card-text-bg);
}

.studentRaiseNudgeForm .spinner {
  animation: spin-animation 0.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@media (min-width: 992px) and (max-width: 1290px) {
  .studentRaiseNudgeHeading {
    font-size: 1em;
    margin-left: 3%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .studentRaiseNudgeHeading {
    font-size: 1em;
  }
}

@media (max-width: 767px) {
  .studentRaiseNudgeHeading {
    font-size: 0.95em;
  }
}

@media (max-width: 520px) {
  .studentRaiseNudgeForm {
    padding: 1em 0;
  }

  .studentRaiseNudgeForm .submitButton {
    font-size: 0.85em;
    padding: 2px 10px;
  }

  .studentRaiseNudgeForm .ql-editor {
    font-size: 1em;
  }

  .studentRaiseNudgeForm .ql-editor p {
    font-size: 1em;
  }

  .studentRaiseNudgeBackButton {
    font-size: 1.2em;
  }

  .studentRaiseNudgeTooltip {
    width: 80%;
  }
}
