.micrositeReviewSection {
  margin: 0.8em 0 0 0;
  padding: 1em;
  background-color: var(--qrencia-white);
  border: 1px solid var(--qrencia-cards-border);
  border-radius: 10px;
}
.horizontalBreak {
  opacity: 0.1;
}

.loadMore {
  text-align: center;
  margin-top: 1em;
}
