.header {
  margin-bottom: 1.2%;
  display: flex;
  background: var(--qrencia-white);
}

.profileName {
  display: inline-block;
}

.logoSection {
  border-right: 1px solid var(--qrencia-logo-border);
  padding: 1.1% 2.2% 1% 1.3%;
  width: 16%;
}

.logo {
  width: 52%;
}

.listSection {
  margin-block: auto;
  width: 56%;
}
.logoImages {
  height: 18px;
  width: 18px;
  margin-right: 5px;
}
.payoutSection {
  /* width: 27%; */
  width: 0%;
  margin-block: auto;
}

.list {
  display: flex;
  border: none;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin: 0%;
  padding-left: 3%;
  list-style-type: none;
  font-weight: 600;
}

.list a {
  text-decoration: none;
  color: var(--qrencia-text-black);
  font-size: 0.8em;
  font-weight: 500;
  /* margin-right: 1em; */
  white-space: nowrap;
  display: block;
  text-align: left;
}
.sectionNavItem {
  all: unset; /* Removes all default Bootstrap styling */
  text-decoration: none;
  text-align: center;
  /* font-size: 0.9em; */
  margin-right: 0.9em;
  font-weight: 500 !important;
  color: var(--card-grey-title);
  padding: 5px 0;
  cursor: pointer;
  background-image: linear-gradient(
    var(--qrencia-dark-blue),
    var(--qrencia-dark-blue)
  );
  background-size: 0 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
}
.sectionNavItem:hover {
  text-decoration: none;
  text-align: center;
  color: var(--qrencia-text-black);
  background-size: 100% 2px, auto;
}
.list a.active {
  border-bottom: 4px solid var(--qrencia-dark-blue) !important;
  color: var(--qrencia-dark-blue);
  padding-bottom: 20px;
}

.list a.active .linkIcons {
  color: var(--qrencia-dark-blue);
}

.linkIcons {
  font-size: 1.4em;
  margin-right: 2px;
  color: var(--qrencia-icons);
}

.profileSection {
  width: 13.5%;
  display: flex;
  margin-block: auto;
  justify-content: left;
}

.profileDropdownItem {
  display: flex;
}

.profileDropdownOption {
  font-size: 0.88em;
}

.profileDropdownOptionIcon {
  font-size: 1em;
  margin-top: 2.5%;
  margin-right: 3%;
  color: var(--qrencia-dark-blue);
}

.profilePopoverButton {
  font-size: 0.86em;
  background-color: transparent !important;
  color: var(--qrencia-user-name) !important;
  font-weight: 500;
  border: none;
  outline: none;
  margin-top: 6%;
}

.profilePopoverButton:hover,
.profilePopoverButton:focus,
.profilePopoverButton:active {
  background-color: transparent !important;
  color: var(--qrencia-user-name) !important;
  font-weight: 500;
  border: none !important;
  margin: 0;
  margin-top: 6%;
}

.profileImage {
  border-radius: 50px;
  height: 34px;
  width: 34px;
  display: block;
  object-fit: cover;
}

.payoutButton {
  border-radius: 30px;
  font-size: 0.7em;
  padding: 1.5% 4%;
  font-weight: 500;
}

.buttonSection {
  width: 10.5%;
  margin-block: auto;
  display: block;
}

.referButton {
  font-size: 0.7em;
  padding: 4% 9%;
  font-weight: 500;
  cursor: not-allowed;
}

.notificationSection {
  width: 4.5%;
  margin-block: auto;
  padding-left: 0.6%;
  cursor: pointer;
}

.userInitials {
  font-weight: 500;
  background-color: var(--qrencia-dark-blue);
  padding: 14% 0%;
  text-align: center;
  color: var(--qrencia-white);
  border-radius: 50px;
  height: 33px;
  width: 34px;
  display: block;
  font-size: 1em;
}

.notificationBadge {
  position: absolute;
  border-radius: 9px;
  font-size: 0.58em;
  margin-left: -11.5px;
  margin-top: -2.2px;
  width: 16px;
  padding: 0.22% 0.28%;
  font-weight: 500;
}

.notificationImage {
  font-size: 1.4em;
  color: var(--qrencia-icons);
}

.popoverHeader {
  font-size: 1em;
  font-weight: 500;
}

.popoverBody {
  font-size: 1em;
  font-weight: 400;
}

.profileIconContainer {
  position: relative;
}
.profileVerifiedIcon {
  position: absolute;
  font-size: 0.8em;
  top: 93%;
  left: 93%;
  transform: translate(-50%, -50%);
  color: var(--qrencia-white);
  background-color: var(--qrencia-golden-color);
  border-radius: 50%;
  padding: 2px;
  border: 0.5px solid var(--qrencia-white);
}

/* Media Quries */
@media (min-width: 1490px) {
  .list a.active {
    padding-bottom: 22px;
  }
}
@media (max-width: 1200px) {
  .list {
    gap: 0rem;
  }
  .sectionNavItem {
    margin-right: 0.2em;
  }
}
@media (min-width: 991.1px) and (max-width: 1286px) {
  .buttonSection {
    width: 15%;
    text-align: right;
    padding-right: 1%;
  }

  .profileDropdownOption {
    font-size: 0.83em;
  }

  .listSection {
    width: 46%;
  }

  .payoutSection {
    /* width: 16%; */
    width: 0%;
    margin-block: auto;
  }

  .notificationSection {
    width: 5%;
  }

  .profileSection {
    width: 18%;
    padding-left: 1%;
  }

  .logoSection {
    width: 16%;
  }

  .logo {
    width: 60%;
  }

  .list {
    gap: 0.5rem;
    padding-left: 4%;
    width: 100%;
  }

  .list a {
    font-size: 0.7em;
  }

  .linkIcons {
    font-size: 1.4em;
  }

  .list a.active {
    padding-bottom: 19px;
  }

  .profilePopoverButton {
    font-size: 0.82em;
  }

  .referButton {
    font-size: 0.7em;
    padding: 3%;
  }

  .userInitials {
    font-size: 0.95em;
    padding: 16% 0%;
  }
}
@media (min-width: 1141px) and (max-width: 1286px) {
  .list a {
    font-size: 0.76em;
  }
}
@media (min-width: 991px) and (max-width: 1030px) {
  .listSection {
    width: 32% !important;
  }

  .payoutSection {
    width: 14% !important;
  }
}

@media (min-width: 990px) and (max-width: 1165px) {
  .list a.active {
    padding-bottom: 14px;
  }
}

@media (min-width: 993px) and (max-width: 1060px) {
  .payoutSection {
    text-align: center;
  }
}

@media (min-width: 912px) and (max-width: 991.5px) {
  .userInitials {
    font-size: 0.95em;
    padding: 16% 0%;
  }

  .profileDropdownOption {
    font-size: 0.81em;
  }

  .logoSection {
    width: 24%;
  }

  .listSection {
    width: 44%;
  }

  .profileSection {
    width: 20%;
    padding-left: 1%;
  }

  .payoutSection {
    width: 12%;
    margin-block: auto;
  }

  .logo {
    width: 45%;
  }

  .list {
    gap: 1rem;
    font-weight: 500;
    padding-left: 6%;
    width: 100%;
  }

  .list a {
    font-size: 0.9em;
  }

  .linkIcons {
    font-size: 1.4em;
  }

  .list a.active {
    padding-bottom: 12px;
  }

  .profilePopoverButton {
    font-size: 0.8em;
  }

  .referButton {
    font-size: 0.6em;
    padding: 6% 3%;
  }
}

@media (min-width: 820px) and (max-width: 912px) {
  .profileDropdownOption {
    font-size: 0.8em;
  }

  .logoSection {
    width: 24%;
  }

  .logo {
    width: 45%;
  }

  .listSection {
    width: 44%;
  }

  .payoutSection {
    width: 10%;
    margin-block: auto;
  }

  .list {
    gap: 1rem;
    font-weight: 500;
    padding-left: 6%;
    width: 100%;
  }

  .list a {
    font-size: 0.8em;
  }

  .linkIcons {
    font-size: 1.4em;
  }

  .profileSection {
    width: 22%;
    padding-left: 1%;
  }

  .list a.active {
    padding-bottom: 13px;
  }

  .profilePopoverButton {
    font-size: 0.75em;
  }

  .referButton {
    font-size: 0.6em;
    padding: 6% 3%;
  }

  .payoutButton {
    font-size: 0.6em;
    padding: 5.5% 3%;
  }
}

@media (max-width: 820px) {
  .profileDropdownOption {
    font-size: 0.8em;
  }

  .logoSection {
    width: 24%;
  }

  .logo {
    width: 50%;
  }

  .listSection {
    width: 42%;
  }

  .payoutSection {
    width: 13%;
    margin-block: auto;
  }

  .payoutButton {
    font-size: 0.6em;
    padding: 5.5% 6%;
  }

  .list {
    gap: 1rem;
    font-weight: 500;
    padding-left: 6%;
    width: 100%;
  }

  .list a {
    font-size: 0.75em;
  }

  .linkIcons {
    font-size: 1.4em;
  }

  .profileSection {
    width: 21%;
    padding-left: 1%;
  }

  .list a.active {
    padding-bottom: 9px;
  }

  .profilePopoverButton {
    font-size: 0.78em;
  }

  .referButton {
    font-size: 0.55em;
    padding: 6% 0.5%;
  }
}

@media (min-width: 767px) and (max-width: 991.5px) {
  .buttonSection {
    display: none;
  }

  .notificationSection {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.5px) {
  .logo {
    width: 55%;
  }

  .profileSection {
    width: 25%;
    justify-content: center;
    padding-right: 2%;
  }

  .payoutSection {
    width: 55% !important;
    text-align: right;
  }

  .logoSection {
    padding: 1.5% 2% 1.5% 1%;
    width: 20%;
  }

  .payoutButton {
    font-size: 0.6em;
    padding: 1.5% 3%;
  }
}

@media (min-width: 690px) and (max-width: 767.5px) {
  .profileName {
    display: none;
  }

  .payoutButton {
    font-size: 0.6em;
    padding: 1.5% 3%;
  }

  .buttonSection {
    display: none;
  }

  .logoSection {
    padding: 1.5% 2% 1.5% 1%;
    width: 20%;
  }

  .logo {
    width: 55%;
  }

  .notificationSection {
    display: none;
  }

  .profileSection {
    width: 15%;
    justify-content: center;
  }

  .list a.active {
    padding-bottom: 13px;
  }

  .payoutSection {
    width: 65%;
    text-align: right;
  }

  .notificationBadge {
    padding: 0.5% 0.35%;
  }
}

@media (min-width: 480px) and (max-width: 690px) {
  .profileName {
    display: none;
  }

  .payoutButton {
    font-size: 0.6em;
    padding: 1.5% 3%;
  }

  .buttonSection {
    display: none;
  }

  .logoSection {
    padding: 1.5% 2% 1.5% 1%;
    width: 25%;
  }

  .logo {
    width: 55%;
  }

  .notificationSection {
    display: none;
  }

  .profileSection {
    width: 17%;
    justify-content: center;
  }

  .list a.active {
    padding-bottom: 12.5px;
  }

  .payoutSection {
    width: 58%;
    text-align: right;
  }

  .notificationImage {
    font-size: 1.25em;
  }
}

@media (max-width: 480px) {
  .profileName {
    display: none;
  }

  .payoutButton {
    font-size: 0.6em;
    padding: 1.5% 3%;
  }

  .userInitials {
    font-size: 0.75em;
    padding: 19% 0%;
  }

  .payoutSection {
    width: 57%;
    text-align: right;
  }

  .profileDropdownOption {
    font-size: 0.78em;
  }

  .profileDropdownOptionIcon {
    font-size: 0.83em;
  }

  .notificationImage {
    font-size: 1.25em;
  }

  .buttonSection {
    display: none;
  }

  .logoSection {
    padding: 3% 2% 3% 1%;
    width: 26%;
  }

  .logo {
    width: 55%;
  }

  .profileSection {
    width: 17%;
    justify-content: center;
    padding-left: 1%;
  }

  .userInitials {
    height: 30px;
    width: 30px;
  }

  .profileImage {
    height: 30px;
    width: 30px;
  }

  .profilePopoverButton {
    font-size: 0.6em;
  }

  .notificationImage {
    font-size: 1.1em;
  }

  .notificationSection {
    display: none;
  }
}

@media (max-width: 376px) {
  .profileName {
    display: none;
  }

  .payoutSection {
    width: 57%;
    text-align: right;
  }

  .notificationImage {
    font-size: 1.25em;
  }

  .notificationBadge {
    padding: 0.7% 0.35%;
  }

  .buttonSection {
    display: none;
  }

  .logoSection {
    padding: 3% 2% 3% 1%;
    width: 26%;
  }

  .logo {
    width: 55%;
  }

  .profileSection {
    width: 17%;
    justify-content: center;
    padding-left: 1%;
  }

  .userInitials {
    height: 26px;
    width: 26px;
  }

  .profileImage {
    height: 26px;
    width: 26px;
  }

  .profilePopoverButton {
    font-size: 0.6em;
  }

  .popoverHeader {
    font-size: 0.65em;
    font-weight: 500;
    padding: 6%;
  }

  .popoverBody {
    font-size: 0.65em;
    font-weight: 400;
    padding: 6%;
  }

  .notificationImage {
    font-size: 1.1em;
  }
}

@media (max-width: 991.5px) {
  .logoSection {
    text-align: end;
  }

  .listSection {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 600px) {
  .logoSection {
    padding: 2.5% 2% 2.5% 1%;
    width: 25%;
  }
}
