.containerSearchSkill {
    display: flex;
    justify-content: flex-start;
    background: var(--qrencia-white);
    padding: 0% 13%;
}

.containerSearchSkill>div {
    margin: 0.2em 0.9em;
}

.containerSearchItem {
    width: 15vw;
    font-size: 1em;
    color: var(--qrencia-text-black) !important;
}

.containerSearchItem input {
    font-size: 0.8em;
    width: 15vw;
    color: var(--qrencia-text-black);
    margin-right: 1em;
}

.containerSearchItem select {
    font-size: 0.8em;
    width: 15vw;
    color: var(--qrencia-text-black);
    margin-right: 1em;
}
.resetButton{
    margin-top: 0.4%;
    height: 34px;
    margin-left: 1%;
    font-size: 0.85em;
}
@media(min-width:767px) and (max-width:1290px) {
    .containerSearchSkill {
        padding: 0% 9% 0% 9%;
    }

    .containerSearchSkill>div {
        margin: 0.2em 0.7em;
    }
}

@media(min-width:900px) and (max-width:1290px) {
    .containerSearchItem {
        width: 17vw;
    }

    .containerSearchItem input {
        width: 17vw;
    }

    .containerSearchItem select {
        width: 17vw;
    }
}

@media(min-width:767px) and (max-width:900px) {
    .containerSearchItem {
        width: 20vw;
    }

    .containerSearchItem input {
        width: 20vw;
    }

    .containerSearchItem select {
        width: 20vw;
    }

    .containerSearchItem input {
        font-size: 0.75em;

    }

    .containerSearchItem select {
        font-size: 0.75em;

    }
}

@media(max-width:550px) {
    .containerSearchItem {
        width: 29vw;
    }

    .containerSearchItem input {
        width: 29vw;
    }

    .containerSearchItem select {
        width: 29vw;
    }

    .containerSearchItem input {
        font-size: 0.5em;

    }

    .containerSearchItem select {
        font-size: 0.5em;

    }
}

@media(max-width:767px) {
    .containerSearchSkill {
        padding: 0% 3% 0% 3%;
    }

    .containerSearchSkill>div {
        margin: 0.8em 0.8em;
    }

    .containerSearchItem {
        width: 30vw;
    }

    .containerSearchItem input {
        width: 30vw;
    }

    .containerSearchItem select {
        width: 30vw;
    }

    .containerSearchItem input {
        font-size: 0.7em;

    }

    .containerSearchItem select {
        font-size: 0.7em;

    }
    .resetButton{
        margin-top: 13px;
        height: 33px;
        margin-left: 3%;
        font-size: 0.75em;
    }
}
@media (max-width:480px){
    .resetButton{
        padding: 0%!important;
    font-size: 0.6em;
    height: 20px;
    width: 13%;
    margin-top: 4.5%;
    }
    .containerSearchItem input {
        padding:0% 4%!important;
    }
    .containerSearchItem select {
        padding:0%!important;
    }
    
}