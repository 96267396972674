.backgroundImage {
  width: 100%;
}
.textSection {
  width: 100%;
  text-align: center;
  padding: 4% 8%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.backgroundImageTablet {
  display: none;
}
.backgroundImageMobile {
  display: none;
}
.checkCircle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.bannerImage {
  width: 100%;
  height: 380px !important;
  object-fit: fill;
  border-radius: 5px;
}
.contentSection {
  width: 90%;
  background-color: var(--qrencia-white);
  margin: 2.5em auto;
  border-radius: 5px;
  position: relative;
}
.heading {
  color: var(--qrencia-dark-blue);
  font-size: 2em;
  text-align: center;
  margin-bottom: 1em;
}
.descriptionText {
  font-size: 1.2em;
  text-align: center;
}
.certificateText {
  margin-bottom: 1em;
}
.verifiedByText {
  margin-top: 2em;
  font-size: 1.4em;
  text-align: center;
}
.preLoaderContainer {
  height: 100vh;
  width: 100%;
  position: relative;
}

.preLoaderDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrenciaGlobe {
  width: 80px;
  margin-top: -98px;
}

.loaderText {
  padding: 2em 0 0 0;
  font-size: 1em;
  text-align: center;
}
.checkIcon {
  color: green;
  font-size: 0.9em;
}
.loaderIcon {
  font-size: 6em !important;
  color: var(--qrencia-dark-blue) !important;
  text-align: center;
}
.textContainer {
  width: 50%;
  margin: 0em auto;
}
.certificateBoldText {
  font-weight: 500 !important;
}
.verifyCheckImg {
  height: 30px;
  width: 30px;
}
.loader {
  --b: 8px;
  /* border thickness */
  --n: 12;
  /* number of dashes*/
  --g: 10deg;
  /* gap between dashes*/
  --c: var(--qrencia-dark-blue);
  /* the color */

  width: 120px;
  /* size */
  aspect-ratio: 1;
  border-radius: 100%;
  padding: 1px;
  background: conic-gradient(#0000, var(--c)) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: load 1s infinite steps(var(--n));
}
@media (max-width: 1200px) {
  .heading {
    color: var(--qrencia-dark-blue);
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 1em;
  }
  .descriptionText {
    font-size: 1em;
    text-align: center;
  }
  .verifiedByText {
    margin-top: 1em;
    font-size: 1em;
    text-align: center;
  }
  .verifiedByText > p {
    background-color: green;
  }
}
@media (max-width: 991px) {
  .bannerImageContainer {
    display: none;
  }
  .bannerImage {
    width: 100%;
    height: 350px !important;
    object-fit: fill;
    border-radius: 5px;
  }
  .backgroundImage {
    display: none;
  }
  .tabletImageContainer {
    display: block;
    width: 85%;
    height: 350px !important;
    object-fit: fill;
    margin: 1em auto;
  }
  .mobileImageContainer {
    display: none;
  }
  .heading {
    color: var(--qrencia-dark-blue);
    font-size: 1.6em;
    text-align: center;
    margin-bottom: 1em;
  }
  .descriptionText {
    font-size: 1em;
    text-align: center;
  }
  .verifiedByText {
    margin-top: 1em;
    font-size: 1em;
    text-align: center;
  }
  .verifyByText > img {
    height: 25px;
    width: 25px;
  }
  .contentSection {
    margin: 2em auto;
  }
  .backgroundImageTablet {
    display: block;
    width: 100%;
  }
  .backgroundImageMobile {
    display: none;
  }
}
@media (max-width: 650px) {
  .heading {
    color: var(--qrencia-dark-blue);
    font-size: 1.4em;
    text-align: center;
    margin-bottom: 1em;
  }
  .descriptionText {
    font-size: 0.9em;
    text-align: center;
  }
  .verifiedByText {
    margin-top: 1em;
    font-size: 1em;
    text-align: center;
  }
  .verifyCheckImg {
    height: 20px;
    width: 20px;
  }
}
@media (max-width: 650px) {
  .heading {
    color: var(--qrencia-dark-blue);
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 1em;
  }
  .descriptionText {
    font-size: 0.9em;
    text-align: center;
  }
  .verifiedByText {
    margin-top: 1em;
    font-size: 1em;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .heading {
    color: var(--qrencia-dark-blue);
    font-size: 1em;
    text-align: center;
    margin-bottom: 1em;
  }
  .descriptionText {
    font-size: 0.7em;
    text-align: center;
  }
  .verifiedByText {
    margin-top: 1.5em;
    font-size: 0.8em;
    text-align: center;
  }
  .bannerImage {
    object-fit: cover;
    height: 350px;
  }
  .textContainer {
    width: 90%;
    margin: 0em auto;
  }
  .certificateText {
    font-size: 0.8em;
  }
  .backgroundImage {
    width: 100%;
  }
  .backgroundImage {
    display: none;
  }
  .backgroundImageTablet {
    display: none;
  }
  .backgroundImageMobile {
    width: 100%;
    display: block;
  }
  .verifyCheckImg {
    height: 15px;
    width: 15px;
  }
}
@keyframes load {
  to {
    transform: rotate(1turn);
  }
}
