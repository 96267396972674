.sectionHeading {
  font-size: 1em;
  margin: 3% 0% 2% 2%;
  font-weight: 650;
  color: var(--qrencia-text-black);
}
.section {
  margin: 2% 0%;
}
.studentSkillCategoryFilterSection {
  margin-bottom: 2em;
}
@media (min-width: 992px) and (max-width: 1290px) {
  .sectionHeading {
    font-size: 1em;
    margin-left: 3%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sectionHeading {
    font-size: 1em;
  }
}
@media (max-width: 767px) {
  .sectionHeading {
    font-size: 0.95em;
  }
  .section {
    margin: 4% 0%;
  }
}
