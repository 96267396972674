.relevanceSectionContainer {
    display: flex;
    background: var(--qrencia-white);
    padding: 3% 8%;
    gap: 2em;
    text-align: justify;
}

.relevanceSection {
    display: flex;
    justify-content: center;
    gap: 2em;
    padding: 1% 3%;
    width: 100%;
}

.relevanceSectionText {
    width: 50%;
    order: 1;
}

.relevanceSectionImg {
    width: 50%;
}

.relevanceImage {
    cursor: pointer;
    width: 100%;
    position: relative;
    border-radius: 1em;
    text-align: center;
}

.redFrameIsp {
    text-align: left;
    margin-bottom: -6.1rem;
    margin-left: -0.8rem;
}

.blueFrameIsp {
    text-align: right;
    margin-top: -2.2rem;
    margin-right: 0;
}

.heading {
    font-size: 1.6em;
    font-weight: 700 !important;
}

.headingHistory {
    font-size: 1.6em;
    font-weight: 700 !important;
    margin-top: 1em;
}

.overlay {
    z-index: 0;
    position: relative;
    margin-top: -3em;
    margin-right: -2rem;
    padding: 1rem;
    text-align: justify;
    font-style: italic;
}

.overlayText {
    background: var(--qrencia-extended-page-fun-fact-background);
    border-radius: 0.5rem;
    padding: 2em 3em;
}

.funFactStaticImage {
    position: relative;
    z-index: 1;
    margin-top: -3rem;
    width: 22%;
}

@media(min-width:1901px) and (max-width:2400px) {

    .funFactStaticImage {
        position: relative;
        z-index: 1;
        margin-top: -9rem;
    }
}

@media(min-width:1440px) and (max-width:1900px) {

    .funFactStaticImage {
        position: relative;
        z-index: 1;
        margin-top: -7rem;
    }
}

@media(min-width:1290px) and (max-width:1440px) {

    .funFactStaticImage {
        position: relative;
        z-index: 1;
        margin-top: -4rem;
    }
}

@media(min-width:1025px) and (max-width:1290px) {

    .funFactStaticImage {
        position: relative;
        z-index: 1;
        margin-top: -3.5rem;
    }
}

@media(min-width:900px) and (max-width:991.5px) {

    .funFactStaticImage {
        position: relative;
        z-index: 1;
        margin-top: -5.5em;
    }

    .overlay {
        margin-top: -4em;
    }
}

@media(min-width:768px) and (max-width:900px) {

    .funFactStaticImage {
        position: relative;
        z-index: 1;
        margin-top: -5em;
    }

    .overlay {
        margin-top: -3.5em;
    }
}

@media(max-width:768.5px) {

    .funFactStaticImage {
        position: relative;
        z-index: 1;
        margin-top: -4em;
    }

    .overlay {
        margin-top: -3.4em;
    }
}

@media(max-width:580px) {

    .funFactStaticImage {
        position: relative;
        z-index: 1;
        margin-top: -2.5em;
    }

    .overlay {
        margin-top: -2.5em;
    }
}

@media(min-width:3001px) {

    .headingHistory {
        font-size: 2.3em;
    }

    .relevanceContent {
        font-size: 2.1em;
    }

    .overlayText {
        font-size: 1.5em;
    }

}


@media(min-width:2600px) and (max-width:3000px) {
    .headingHistory {
        font-size: 2.2em;
    }

    .relevanceContent {
        font-size: 2em;
    }

    .overlayText {
        font-size: 1.5em;
    }
}

@media(min-width:2000px) and (max-width:2599px) {

    .headingHistory {
        font-size: 2em;
    }

    .relevanceContent {
        font-size: 1.8em;
    }

    .overlayText {
        font-size: 1.3em;
    }
}

@media(min-width:1701px) and (max-width:1999px) {

    .headingHistory {
        font-size: 1.8em;
    }

    .relevanceContent {
        font-size: 1.6em;
    }

    .overlayText {
        font-size: 1em;
    }
}

@media (min-width:1025px) and (max-width:1290px) {
    .relevanceSectionContainer {
        display: flex;
        padding: 3% 8%;
        gap: 2em;
        text-align: justify;
    }
}

@media(max-width:540px) {
    .relevanceSectionContainer {
        text-align: left;
    }

    .overlayText {
        font-size: 0.7em;
        padding: 2em 2em;
    }
}

@media(max-width:991px) {
    .relevanceSectionContainer {
        display: block;
        gap: 2em;
        text-align: left;
    }

    .relevanceSectionText {
        width: 100%;
    }

    .relevanceSectionImg {
        width: 100%;
    }

}

@media(max-width:991.5px) {
    .relevanceSectionContainer {
        display: block;
        gap: 2em;
        text-align: left;
    }

    .relevanceSectionText {
        width: 100%;
    }

    .relevanceSectionImg {
        width: 100%;
        margin-top: 4%;
    }

}

@media(min-width:780px) and (max-width:991px) {
    .relevanceSectionContainer {
        padding: 4% 8%;
    }
}

@media(max-width:767px) {
    .relevanceSectionContainer {
        padding: 6% 8%;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .heading {
        font-size: 1.3em;
    }

    .headingHistory {
        font-size: 1.3em;
    }

    .relevanceContent {
        font-size: 0.9em;
    }
}

@media(max-width:767px) {
    .heading {
        font-size: 1.1em;
    }

    .headingHistory {
        font-size: 1.1em;
    }

    .relevanceContent {
        font-size: 0.8em;
    }
}

@media(min-width:3001px) {

    .heading {
        font-size: 2.5em;
    }

}

@media(min-width:2600px) and (max-width:3000px) {

    .heading {
        font-size: 2.4em;
    }
}

@media(min-width:2000px) and (max-width:2599px) {

    .heading {
        font-size: 2.2em;
    }
}

@media(min-width:1701px) and (max-width:1999px) {

    .heading {
        font-size: 1.8em;
    }
}

@media(min-width:991px) and (max-width:1290px) {

    .relevanceContent {
        font-size: 0.95em;
    }
}

@media(min-width: 1701px) and (max-width: 1999px) {
    .relevanceContent {
        font-size: 1.4em;
    }
}

@media (min-width: 2000px) and (max-width: 2599px) {
    .relevanceContent {
        font-size: 1.8em;
    }
}

@media (min-width: 2600px) and (max-width: 3000px) {
    .relevanceContent {
        font-size: 2em;
    }
}