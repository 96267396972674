.containerTextWrap {
    display: flex;
    gap: 2em;
    background: var(--qrencia-white);
    padding: 3% 8%;
    overflow: hidden;
    justify-content: start;
    text-align: justify;
}

.whatIsSection {
    width: 50%;
    order: 1;
}

.heading {
    font-size: 1.6em;
    font-weight: 700 !important;
}

.headingHistory {
    font-size: 1.6em;
    font-weight: 700 !important;
    margin-top: 1em;
}

.contentSkillsIntro {
    font-size: 1em;
}


.overlay {
    z-index: 0;
    position: relative;
    margin-top: -5rem;
    margin-right: -2rem;
    padding: 1rem;
    text-align: justify;
    font-style: italic;
}

.overlayText {
    background: var(--qrencia-extended-page-fun-fact-background);
    border-radius: 0.5rem;
    padding: 2em 3em;
}

.funFactSection {
    text-align: end;
    width: 50%;
    order: 2;
}

.funFactContentImage {
    width: 100%;
    border-radius: 1rem;
    position: relative;
    background-color: var(--qrencia-text-black);
    aspect-ratio: 3/2.1;
}


.closeButton {
    margin-top: -28.6%;
    margin-left: -6%;
    width: 8%;
    position: absolute;
    cursor: pointer;
    font-size: 2em;
    color: var(--qrencia-white);
}

.funFactStaticImage {
    position: relative;
    z-index: 1;
    margin-top: -8rem;
    width: 22%;
}

.funFactContentImage {
    animation: fade-in 0.5s ease-in;
}

@keyframes fade-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.funFactVideoIcon {
    margin-top: -18%;
    margin-left: -24%;
    width: 6%;
    position: absolute;
    cursor: pointer;
}

.blueFrameTopRight {
    margin-bottom: -6.1rem;
    margin-right: -0.8rem;
}

@media(max-width:991.5px) {

    .closeButton {
        margin-top: -58%;
        margin-left: -7.5%;
    }

    .funFactVideoIcon {
        margin-top: -35%;
        margin-left: -46%;
        width: 10%;
        position: absolute;
        cursor: pointer;
    }
}

@media(max-width:580px) {

    .closeButton {
        margin-top: -59.5%;
        margin-left: -9%;
    }
}

@media(min-width:3001px) {

    .heading {
        font-size: 2.5em;
    }

    .headingHistory {
        font-size: 2.3em;
    }

    .contentSkillsIntro {
        font-size: 2.1em;
    }


    .overlayText {
        font-size: 1.5em;
    }
}

@media(min-width:2600px) and (max-width:3000px) {

    .heading {
        font-size: 2.4em;
    }

    .headingHistory {
        font-size: 2.2em;
    }

    .contentSkillsIntro {
        font-size: 2em;
    }

    .overlayText {
        font-size: 1.5em;
    }
}

@media(min-width:2000px) and (max-width:2599px) {

    .heading {
        font-size: 2.2em;
    }

    .headingHistory {
        font-size: 2em;
    }

    .contentSkillsIntro {
        font-size: 1.8em;
    }

    .overlayText {
        font-size: 1.3em;
    }
}

@media(min-width:1701px) and (max-width:1999px) {

    .heading {
        font-size: 1.8em;
    }

    .headingHistory {
        font-size: 1.6em;
    }

    .contentSkillsIntro {
        font-size: 1.4em;
    }

    .overlayText {
        font-size: 1em;
    }
}

@media(min-width:1025px) and (max-width:1290px) {
    .containerTextWrap {
        display: flex;
        gap: 2em;
        background: var(--qrencia-white);
        padding: 3% 8%;
        justify-content: start;
        text-align: justify;
    }

    .whatIsSection {
        width: 50%;
        order: 1;
    }

    .funFactSection {
        text-align: end;
        width: 50%;
        order: 2;
    }

    .funFactContentImage {
        width: 100%;
        border-radius: 1rem;
        position: relative;
    }

    .funFactStaticImage {
        position: relative;
        z-index: 1;
        margin-top: -8rem;
    }
}

@media(min-width:994px) and (max-width:991px) {
    .containerTextWrap {
        display: block;
        gap: 2em;
        background: var(--qrencia-white);
        padding: 3% 8%;
        justify-content: start;
        text-align: left;
    }

    .whatIsSection {
        width: 100%;
        order: 1;
    }

    .funFactSection {
        text-align: end;
        width: 100%;
        order: 2;
    }

    .funFactContentImage {
        width: 100%;
        border-radius: 1rem;
        position: relative;
    }

    .funFactStaticImage {
        position: relative;
        z-index: 1;
        margin-top: -10rem;
        width: 21%;
    }


}



@media(max-width:991.5px) {

    .funFactSection {
        text-align: end;
        width: 100%;
        order: 2;
        margin-top: 4%;
    }

    .containerTextWrap {
        display: block;
        gap: 2em;
        background: var(--qrencia-white);
        justify-content: start;
        text-align: left;
    }

    .whatIsSection {
        width: 100%;
        order: 1;
    }

    .funFactContentImage {
        width: 100%;
        border-radius: 1rem;

    }

    .funFactStaticImage {
        position: relative;
        z-index: 1;
        margin-top: -8rem;
    }

}

@media(min-width:780px) and (max-width:991px) {
    .funFactStaticImage {
        position: relative;
        z-index: 1;
        margin-top: -10rem;
        width: 20%;
    }

    .containerTextWrap {
        padding: 4% 8%;
    }
}

@media(max-width:767px) {
    .containerTextWrap {
        padding: 6% 8%;
    }
}

@media(max-width:540px) {
    .overlayText {
        font-size: 0.7em;
        padding: 2em 2em;
    }

    .containerTextWrap {
        text-align: left;
        padding: 6% 8%;
    }

}

@media(min-width:768px) and (max-width:991px) {
    .heading {
        font-size: 1.3em;
    }

    .headingHistory {
        font-size: 1.3em;
    }

    .contentSkillsIntro {
        font-size: 0.9em;
    }
}

@media(max-width:767px) {
    .heading {
        font-size: 1.1em;
    }

    .headingHistory {
        font-size: 1.1em;
    }

    .contentSkillsIntro {
        font-size: 0.8em;
    }

    /* .funFactVideoIcon {
        margin-top: -42%;
        margin-left: -50%;
        width: 10%;
        position: absolute;
        cursor: pointer;
    } */
}

@media(max-width:540px) {
    /* .funFactVideoIcon {
        margin-top: -44%;
    } */

}

@media(max-width:480px) {
    /* .funFactVideoIcon {
        margin-top: -48%;
    } */

}

@media(min-width:991px) and (max-width:1290px) {
    .contentSkillsIntro {
        font-size: 0.95em;
    }
}