.rightContainer {
  top: 1em;
  overflow: hidden;
  max-height: 100%;
  position: sticky;
  margin: 2em 15% 0 0;
}

.competitionEnrollSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background-color: white;
  border-radius: 10px;
  padding: 1em;
  margin: 0 0 0.5em 0;
  border: 1px solid var(--qrencia-light-grey-border);
}

.competitionAdsSection {
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  padding: 1em;
  margin: 0.5em 0 1em 0;
  border: 1px solid var(--qrencia-light-grey-border);
}
.competitionAdImage {
  width: 100%;
  border-radius: 10px;
}
.competitionApproveSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  background-color: white;
  border-radius: 10px;
  padding: 1em;
  margin: 0 0 0.5em 0;
  border: 1px solid var(--qrencia-light-grey-border);
}
.competitionApproveTextSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background-color: white;
  border-radius: 10px;
  padding: 1em;
  margin: 0 0 0.5em 0;
  border: 1px solid var(--qrencia-light-grey-border);
}
.competitionApproveTextSection > svg {
  width: 2em;
  margin-right: 0em;
}
.competitionEnrollText {
  font-size: 1em !important;
  font-weight: 500 !important;
  padding-right: 0.5em;
}

.competitionEnrollButton {
  padding: 4px 10px;
  font-size: 0.9em;
  margin: 0 0 0 10px;
}

.competationDetailsCard {
  display: flex;
  gap: 1.5em;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
  background-color: white;
  border-radius: 10px;
  padding: 0.6em 1em;
  margin-bottom: 2%;
  border: 1px solid var(--qrencia-light-grey-border);
}

.detailsGroup {
  display: inline-flex;
  padding: 0.5em 0;
  align-items: center;
}

.detailsIconSection {
  padding: 0.75em 0.8em;
  margin-right: 0.6em;
  background-color: var(--qrencia-light-blue-icon-background);
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.detailsIcon {
  font-size: 1em !important;
  color: var(--qrencia-dark-blue);
}

.detailsSubsection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.detailsHeading {
  font-size: 0.8em;
  font-weight: 400 !important;
}

.detailsSubHeading {
  font-size: 0.9em;
  font-weight: 600 !important;
}

.liveDot {
  font-size: 0.5em;
  color: var(--qrencia-error-red);
  display: inline;
  vertical-align: middle;
}

@media (min-width: 2000px) {
  .rightContainer {
    margin: 2em 15% 0 0 !important;
  }
}

@media (min-width: 1400px) {
  .rightContainer {
    margin: 2em 15% 0 0;
  }
}

@media (min-width: 1151px) and (max-width: 1290px) {
  .competitionEnrollButton {
    font-size: 0.8em;
    padding: 4px 2px;
    width: 7.2em;
  }

  .competitionEnrollText {
    font-size: 0.8em !important;
    font-weight: 500 !important;
  }
}

@media (min-width: 1090px) and (max-width: 1150px) {
  .competitionEnrollButton {
    font-size: 0.75em;
    padding: 4px 2px;
    width: 7.2em;
  }

  .competitionEnrollText {
    font-size: 0.75em !important;
    font-weight: 500 !important;
  }
}

@media (min-width: 991px) and (max-width: 1089px) {
  .competitionEnrollButton {
    font-size: 0.8em;
    padding: 4px 2px;
    width: 7.2em;
  }

  .competitionEnrollText {
    font-size: 0.9em !important;
    font-weight: 500 !important;
  }
}

@media (max-width: 991.5px) {
  .rightContainer {
    margin: 1.5em 8% 0 8%;
    height: auto;
  }
  .competitionAdsSection {
    display: none;
  }
  .competitionApproveTextSection > svg {
    width: 2em;
    margin-right: -0.4em;
  }
}

@media (max-width: 670px) {
  .competitionApproveTextSection > svg {
    width: 2em;
    margin-right: -0.3em;
  }
}

@media (max-width: 580px) {
  .rightContainer {
    margin: 1em 8% 0 8%;
  }

  .competitionEnrollButton {
    font-size: 0.8em;
    padding: 4px 10px;
  }
}
@media (max-width: 480px) {
  .competitionApproveTextSection > svg {
    width: 2em;
    margin-right: -0.1em;
  }
}
@media (max-width: 440px) {
  .competitionEnrollButton {
    font-size: 0.7em;
    padding: 4px 2px;
    width: 7.2em;
  }

  .competitionEnrollText {
    font-size: 0.82em !important;
    font-weight: 500 !important;
  }
}

@media (max-width: 380px) {
  .competitionEnrollButton {
    font-size: 0.68em;
    padding: 4px 2px;
    width: 7.2em;
  }

  .competitionEnrollText {
    font-size: 0.78em !important;
    font-weight: 500 !important;
  }
}
@media (max-width: 35px) {
  .competitionEnrollButton {
    font-size: 0.64em;
    padding: 4px 2px;
    width: 7.2em;
  }

  .competitionEnrollText {
    font-size: 0.74em !important;
    font-weight: 500 !important;
  }
}
