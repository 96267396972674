.desktopImgContainer {
  display: block;
  margin: 0em auto;
}

.tabletImgContainer {
  display: none;
}

.mobileImgContainer {
  display: none;
  width: 85%;
  object-fit: cover;
  margin: 1em auto;
}

.bannerImage {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.bannerImageWeb {
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
  aspect-ratio: 1550 / 450;
  position: relative;
}

.mobileBannerSubtitle {
  position: absolute;
  bottom: 177px;
  color: var(--qrencia-white);
  left: 32px;
  font-size: 0.6rem;
  padding: 0rem 9.2rem 0rem 0rem;
}

.mobileBannerTitle {
  position: absolute;
  font-size: 1.1rem;
  font-weight: 700;
  top: 30px;
  color: var(--qrencia-white);
  left: 32px;
  padding: 0rem 3rem 0rem 0rem;
}

.tabletBannerTitle {
  position: absolute;
  font-size: 2.3rem;
  font-weight: 700;
  top: 50px;
  color: var(--qrencia-white);
  left: 58px;
  padding: 0rem 9.6rem 0rem 0rem;
}

.tabletBannerSubtitle {
  position: absolute;
  bottom: 308px;
  color: var(--qrencia-white);
  left: 60px;
  font-size: 1.2rem;
  padding: 0rem 25rem 0rem 0rem;
}

.bannerTitle {
  position: absolute;
  font-size: 2rem;
  font-weight: 700;
  top: 80px;
  color: var(--qrencia-white);
  left: 78px;
  padding: 0rem 32rem 0rem 0rem;
}

.bannerTitle span {
  color: #3bcfcc;
}

.mobileBannerTitle span {
  color: #3bcfcc;
}

.tabletBannerTitle span {
  color: #3bcfcc;
}

.bannerSubtitle {
  position: absolute;
  bottom: 70px;
  color: var(--qrencia-white);
  left: 80px;
  font-size: 1.1rem;
  padding: 0rem 41rem 0rem 0rem;
}

.bannerSubHeading {
  font-weight: 200;
  font-size: 1em;
  color: var(--qrencia-white);
}

.exploreSkillsText {
  font-size: 1.4em;
  color: var(--qrencia-white);
}

@media (min-width: 2000px) {
  .exploreSkillsText {
    font-weight: 500;
    font-size: 2em;
  }

  .bannerSubHeading {
    font-weight: 500;
    font-size: 1.6em;
  }

  .skillName {
    font-weight: 700;
    font-size: 2.4em;
  }

  .desktopImgContainer {
    display: block;
  }

  .tabletImgContainer {
    display: none;
  }

  .mobileImgContainer {
    display: none;
  }
}

@media (min-width: 1290px) {
  .bannerSubtitle {
    position: absolute;
    bottom: 118px;
    color: var(--qrencia-white);
    left: 80px;
    font-size: 1.1rem;
    padding: 0rem 46rem 0rem 0rem;
  }
}

@media (min-width: 1520px) and (max-width: 1999px) {
  .exploreSkillsText {
    font-weight: 400;
    font-size: 1.6em;
  }

  .bannerSubHeading {
    font-weight: 400;
    font-size: 1.3em;
  }

  .skillName {
    font-weight: 700;
    font-size: 2em;
  }

  .desktopImgContainer {
    display: block;
  }

  .tabletImgContainer {
    display: none;
  }

  .mobileImgContainer {
    display: none;
  }
}

@media (max-width: 1443px) {
  .bannerTitle {
    font-size: 2rem;
    top: 80px;
    left: 78px;
    padding: 0rem 29rem 0rem 0rem;
  }
}
@media (max-width: 1412px) {
  .bannerSubtitle {
    bottom: 88px;
  }
}

@media (max-width: 1383px) {
  .bannerSubtitle {
    position: absolute;
    bottom: 75px;
    color: var(--qrencia-white);
    left: 80px;
    font-size: 1.1rem;
    padding: 0rem 40rem 0rem 0rem;
  }
}

@media (max-width: 1387px) {
  .bannerTitle {
    font-size: 2rem;
    top: 80px;
    left: 78px;
    padding: 0rem 26rem 0rem 0rem;
  }
}

@media (max-width: 1330px) {
  .bannerTitle {
    font-size: 1.9rem;
  }
}

@media (max-width: 1293px) {
  .bannerTitle {
    font-size: 1.9rem;
    padding: 0rem 25rem 0rem 0rem;
  }
}

@media (max-width: 1290px) {
  .bannerTitle {
    font-size: 1.9rem;
    top: 81px;
    padding: 0rem 31rem 0rem 0rem;
  }

  .bannerSubtitle {
    position: absolute;
    bottom: 90px;
    color: var(--qrencia-white);
    left: 80px;
    font-size: 1.1rem;
    padding: 0rem 43rem 0rem 0rem;
  }
}

@media (min-width: 1191px) and (max-width: 1241px) {
  .bannerTitle {
    font-size: 1.9rem;
    top: 80px;
    padding: 0rem 28rem 0rem 0rem;
  }
}

@media (max-width: 1191px) {
  .bannerTitle {
    font-size: 1.9rem;
    top: 80px;
    padding: 0rem 25rem 0rem 0rem;
  }
}

@media (max-width: 1187px) {
  .bannerSubtitle {
    position: absolute;
    bottom: 60px;
    color: var(--qrencia-white);
    left: 80px;
    font-size: 1.1rem;
    padding: 0rem 36.6rem 0rem 0rem;
  }
}

@media (max-width: 1153px) {
  .bannerTitle {
    font-size: 1.9rem;
    top: 70px;
    padding: 0rem 25rem 0rem 0rem;
  }

  .bannerSubtitle {
    bottom: 60px;
    left: 80px;
    padding: 0rem 35rem 0rem 0rem;
  }
}

@media (max-width: 1141px) {
  .bannerTitle {
    font-size: 1.9rem;
    top: 80px;
    padding: 0rem 22rem 0rem 0rem;
  }
}
/* 
@media (max-width: 1117px) {
  .bannerTitle {
    font-size: 1.4rem;
    top: 66px;
    left: 78px;
    padding: 0rem 23rem 0rem 0rem;
  }
} */
@media (min-width: 1077px) and (max-width: 1116px) {
  .bannerTitle {
    font-size: 1.8rem;
    top: 66px;
    left: 78px;
    padding: 0rem 22.5rem 0rem 0rem;
  }
}

@media (max-width: 1076px) {
  .bannerTitle {
    font-size: 1.8rem;
    top: 63px;
    padding: 0rem 22rem 0rem 0rem;
  }
}

@media (max-width: 1065px) {
  .bannerTitle {
    font-size: 1.7rem;
    top: 68px;
    left: 71px;
    padding: 0rem 22rem 0rem 0rem;
  }
}

@media (max-width: 1040px) {
  .bannerTitle {
    font-size: 1.7rem;
    top: 60px;
    left: 71px;
    padding: 0rem 22rem 0rem 0rem;
  }
}

@media (max-width: 1053px) {
  .bannerSubtitle {
    bottom: 52px;
    left: 74px;
    padding: 0rem 29rem 0rem 0rem;
  }
}

@media (max-width: 1019px) {
  .bannerTitle {
    font-size: 1.7rem;
    top: 58px;
    left: 71px;
    padding: 0rem 20rem 0rem 0rem;
  }
}

@media (max-width: 1014px) {
  .bannerTitle {
    font-size: 1.7rem;
    top: 58px;
    left: 71px;
  }
}

@media (min-width: 680px) and (max-width: 991.5px) {
  .desktopImgContainer {
    display: none;
  }

  .tabletImgContainer {
    display: block;
    width: 85%;
    object-fit: cover;
    margin: 1em auto;
  }

  .mobileImgContainer {
    display: none;
  }
}

@media (max-width: 991.5px) {
  .exploreSkillsText {
    font-weight: 400;
    font-size: 1.1em;
    margin-top: -3em;
  }

  .bannerSubHeading {
    font-weight: 400;
    font-size: 0.9em;
  }
}

@media (max-width: 989px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 2.2rem;
    font-weight: 700;
    top: 52px;
    color: var(--qrencia-white);
    left: 58px;
    padding: 0rem 8rem 0rem 0rem;
  }
}

@media (max-width: 967px) {
  .tabletBannerSubtitle {
    bottom: 298px;
    padding: 0rem 25rem 0rem 0rem;
  }
}

@media (max-width: 930px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 2.1rem;
    font-weight: 700;
    top: 45px;
    color: var(--qrencia-white);
    left: 58px;
    padding: 0rem 6.5rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 288px;
    padding: 0rem 25rem 0rem 0rem;
  }
}

@media (max-width: 901px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.9rem;
    font-weight: 700;
    line-height: 2.6rem;
    top: 66px;
    color: var(--qrencia-white);
    left: 58px;
    padding: 0rem 8.9rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 275px;
    padding: 0rem 25rem 0rem 0rem;
  }
}

@media (max-width: 894px) {
  .tabletBannerSubtitle {
    bottom: 275px;
    padding: 0rem 18rem 0rem 0rem;
  }
}

@media (max-width: 876px) {
  .desktopImgContainer {
    display: none;
  }

  .tabletBannerSubtitle {
    bottom: 232px;
    padding: 0rem 18rem 0rem 0rem;
  }

  .tabletImgContainer {
    display: block;
    width: 85%;
    object-fit: cover;
    margin: 1em auto;
  }

  .mobileImgContainer {
    display: none;
  }
}

@media (max-width: 850px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.9rem;
    font-weight: 700;
    top: 37px;
    color: var(--qrencia-white);
    left: 58px;
    padding: 0rem 8.7rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 256px;
    padding: 0rem 18rem 0rem 0rem;
  }
}

@media (max-width: 845px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.9rem;
    font-weight: 700;
    top: 37px;
    color: var(--qrencia-white);
    left: 58px;
    padding: 0rem 5.6rem 0rem 0rem;
  }
}

@media (max-width: 813px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.8rem;
    font-weight: 700;
    top: 37px;
    color: var(--qrencia-white);
    left: 58px;
    padding: 0rem 5.8rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 242px;
    padding: 0rem 18rem 0rem 0rem;
  }
}

@media (max-width: 780px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.8rem;
    font-weight: 700;
    top: 32px;
    color: var(--qrencia-white);
    left: 58px;
    padding: 0rem 5.8rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    font-size: 1.1rem;
    bottom: 230px;
    padding: 0rem 13.7rem 0rem 0rem;
  }
}

@media (max-width: 768px) {
  .desktopImgContainer {
    display: none;
  }

  .tabletImgContainer {
    display: block;
    width: 85%;
    object-fit: cover;
    margin: 1em auto;
  }

  .mobileImgContainer {
    display: none;
  }
}

@media (max-width: 764px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.7rem;
    font-weight: 700;
    top: 32px;
    color: var(--qrencia-white);
    left: 58px;
    padding: 0rem 4.9rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 232px;
    font-size: 1rem;
    padding: 0rem 16rem 0rem 0rem;
  }
}

@media (max-width: 735px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.7rem;
    font-weight: 700;
    top: 29px;
    color: var(--qrencia-white);
    left: 58px;
    padding: 0rem 4.9rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 222px;
    font-size: 1rem;
    padding: 0rem 16rem 0rem 0rem;
  }
}

@media (max-width: 718px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.6rem;
    font-weight: 700;
    top: 32px;
    color: var(--qrencia-white);
    left: 48px;
    padding: 0rem 4.9rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 216px;
    font-size: 1rem;
    left: 50px;
    padding: 0rem 16rem 0rem 0rem;
  }
}

@media (max-width: 693px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.5rem;
    font-weight: 700;
    top: 26px;
    color: var(--qrencia-white);
    left: 48px;
    padding: 0rem 5.1rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 206px;
    font-size: 1rem;
    padding: 0rem 16rem 0rem 0rem;
  }
}

@media (max-width: 679px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.6rem;
    font-weight: 700;
    top: 26px;
    color: var(--qrencia-white);
    padding: 0rem 4.2rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 202px;
    font-size: 0.9rem !important;
    padding: 0rem 13.2rem 0rem 0rem;
  }
}

@media (max-width: 673px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.5rem;
    font-weight: 700;
    top: 25px;
    color: var(--qrencia-white);
    padding: 0rem 5.7rem 0rem 0rem;
  }
}

@media (max-width: 656px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.5rem;
    font-weight: 700;
    top: 25px;
    color: var(--qrencia-white);
    padding: 0rem 3.3rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 192px;
  }
}

@media (max-width: 634px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.5rem;
    font-weight: 700;
    top: 21px;
    color: var(--qrencia-white);
    padding: 0rem 3.3rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 182px;
    font-size: 0.8rem;
    padding: 0rem 12.6rem 0rem 0rem;
  }
}

@media (min-width: 605px) and (max-width: 616px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.4rem;
    font-weight: 700;
    top: 21px;
    color: var(--qrencia-white);
    padding: 0rem 3.3rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 182px;
    font-size: 0.8rem !important;
    padding: 0rem 12.6rem 0rem 0rem;
  }
}

@media (max-width: 605px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.4rem;
    font-weight: 700;
    top: 21px;
    color: var(--qrencia-white);
    padding: 0rem 3.3rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 165px;
    font-size: 0.8rem !important;
    padding: 0rem 12.6rem 0rem 0rem;
  }
}

@media (max-width: 593px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.4rem;
    font-weight: 700;
    top: 20px;
    color: var(--qrencia-white);
    left: 33px;
    padding: 0rem 2.6rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 154px;
    left: 34px;
    font-size: 0.8rem;
    padding: 0rem 12.6rem 0rem 0rem;
  }
}

@media (max-width: 565px) {
  .tabletBannerSubtitle {
    bottom: 147px;
    left: 34px;
    font-size: 0.8rem !important;
    padding: 0rem 12.6rem 0rem 0rem;
  }
}

/* @media (max-width: 565px) {
  .tabletBannerSubtitle {
    bottom: 168px;
    left: 34px;
    font-size: 0.8rem;
    padding: 0rem 12.6rem 0rem 0rem;
  }
} */

@media (max-width: 555px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.3rem;
    font-weight: 700;
    top: 20px;
    color: var(--qrencia-white);
    left: 33px;
    line-height: 2.3rem;
    padding: 0rem 2.6rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 154px;
    left: 34px;
    font-size: 0.74rem !important;
    padding: 0rem 12.6rem 0rem 0rem;
  }
}

/* @media (max-width: 554px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.3rem;
    font-weight: 700;
    top: 24px;
    color: var(--qrencia-white);
    left: 35px;
    padding: 0rem 2.4rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 164px;
    left: 34px;
    font-size: 0.8rem;
    padding: 0rem 12.6rem 0rem 0rem;
  }
} */

@media (min-width: 539px) and (max-width: 541px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.2rem;
    font-weight: 700;
    top: 24px;
    color: var(--qrencia-white);
    padding: 0rem 2.6rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 137px;
    left: 34px;
    font-size: 0.7rem !important;
    padding: 0rem 10rem 0rem 0rem;
  }
}

@media (max-width: 539px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.2rem;
    font-weight: 700;
    top: 22px;
    color: var(--qrencia-white);
    left: 35px;
    padding: 0rem 2.4rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 144px;
    left: 34px;
    font-size: 0.7rem !important;
    padding: 0rem 9.9rem 0rem 0rem;
  }
}

@media (max-width: 520px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.1rem;
    font-weight: 700;
    top: 22px;
    color: var(--qrencia-white);
    line-height: 2rem;
    left: 34px;
    padding: 0rem 3.6rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 134px;
    left: 34px;
    font-size: 0.68rem !important;
    padding: 0rem 9.9rem 0rem 0rem;
  }
}

@media (max-width: 511px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.1rem;
    font-weight: 700;
    top: 20px;
    color: var(--qrencia-white);
    padding: 0rem 3rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 134px;
    left: 34px;
    font-size: 0.7rem;
    padding: 0rem 9.9rem 0rem 0rem;
  }
}

@media (max-width: 483px) {
  .tabletBannerTitle {
    position: absolute;
    font-size: 1.1rem;
    font-weight: 700;
    top: 20px;
    color: var(--qrencia-white);
    left: 29px;
    padding: 0rem 1.6rem 0rem 0rem;
  }

  .tabletBannerSubtitle {
    bottom: 125px;
    font-size: 0.6rem !important;
    padding: 0rem 9.9rem 0rem 0rem;
  }
}

@media (max-width: 479px) {
  .mobileBannerTitle {
    position: absolute;
    font-size: 1.1rem;
    font-weight: 700;
    top: 30px;
    color: var(--qrencia-white);
    left: 32px;
    padding: 0rem 1.2rem 0rem 0rem;
  }
}

@media (max-width: 464px) {
  .mobileBannerSubtitle {
    position: absolute;
    bottom: 155px;
    color: var(--qrencia-white);
    left: 45px;
    font-size: 0.6rem;
    padding: 0rem 9.2rem 0rem 0rem;
  }
}

@media (max-width: 449px) {
  .mobileBannerTitle {
    position: absolute;
    font-size: 1rem;
    font-weight: 700;
    top: 25px;
    color: var(--qrencia-white);
    left: 32px;
    padding: 0rem 1.5rem 0rem 0rem;
  }

  .mobileBannerSubtitle {
    position: absolute;
    bottom: 164px;
    color: var(--qrencia-white);
    left: 39px;
    font-size: 0.6rem;
    padding: 0rem 9.2rem 0rem 0rem;
  }
}

@media (max-width: 438px) {
  .mobileBannerSubtitle {
    position: absolute;
    bottom: 129px;
    color: var(--qrencia-white);
    left: 34px;
    font-size: 0.6rem;
    padding: 0rem 9.4rem 0rem 0rem;
  }
}

@media (max-width: 420px) {
  .mobileBannerTitle {
    position: absolute;
    font-size: 0.9rem;
    font-weight: 700;
    top: 23px;
    color: var(--qrencia-white);
    left: 28px;
    padding: 0rem 2.2rem 0rem 0rem;
  }

  .mobileBannerSubtitle {
    position: absolute;
    bottom: 155px;
    color: var(--qrencia-white);
    left: 34px;
    font-size: 0.5rem;
    padding: 0rem 9.4rem 0rem 0rem;
  }
}

@media (max-width: 406px) {
  .mobileBannerSubtitle {
    position: absolute;
    bottom: 124px;
    color: var(--qrencia-white);
    left: 32px;
    font-size: 0.5rem;
    padding: 0rem 9.4rem 0rem 0rem;
  }
}

@media (max-width: 394px) {
  .mobileBannerTitle {
    position: absolute;
    font-size: 0.9rem;
    font-weight: 700;
    top: 21.2px;
    color: var(--qrencia-white);
    left: 28px;
    padding: 0rem 0.7rem 0rem 0rem;
  }

  .mobileBannerSubtitle {
    position: absolute;
    bottom: 143px;
    color: var(--qrencia-white);
    left: 34px;
    font-size: 0.5rem;
    padding: 0rem 9.4rem 0rem 0rem;
  }
}

@media (max-width: 385px) {
  .mobileBannerSubtitle {
    position: absolute;
    bottom: 140px;
    color: var(--qrencia-white);
    left: 27px;
    font-size: 0.5rem;
    padding: 0rem 6.5rem 0rem 0rem;
  }
}

@media (max-width: 377px) {
  .mobileBannerSubtitle {
    position: absolute;
    bottom: 126px;
    color: var(--qrencia-white);
    left: 30px;
    font-size: 0.5rem;
    padding: 0rem 6.5rem 0rem 0rem;
  }
}

@media (max-width: 369px) {
  .mobileBannerTitle {
    position: absolute;
    font-size: 0.8rem;
    font-weight: 700;
    top: 21px;
    color: var(--qrencia-white);
    left: 24px;
    padding: 0rem 1.6rem 0rem 0rem;
  }

  .mobileBannerSubtitle {
    position: absolute;
    bottom: 134px;
    color: var(--qrencia-white);
    left: 25px;
    font-size: 0.4rem;
    padding: 0rem 8.2rem 0rem 0rem;
  }
}

@media (max-width: 359px) {
  .mobileBannerSubtitle {
    position: absolute;
    bottom: 130px;
    color: var(--qrencia-white);
    left: 25px;
    font-size: 0.4rem;
    padding: 0rem 8.2rem 0rem 0rem;
  }
}

@media (max-width: 346px) {
  .mobileBannerTitle {
    position: absolute;
    font-size: 0.8rem;
    font-weight: 700;
    top: 21px;
    color: var(--qrencia-white);
    left: 24px;
    padding: 0rem 0.3rem 0rem 0rem;
  }

  .mobileBannerSubtitle {
    position: absolute;
    bottom: 125px;
    color: var(--qrencia-white);
    left: 23px;
    font-size: 0.4rem;
    padding: 0rem 8.2rem 0rem 0rem;
  }
}

@media (max-width: 335px) {
  .mobileBannerSubtitle {
    position: absolute;
    bottom: 120px;
    color: var(--qrencia-white);
    left: 26px;
    font-size: 0.4rem;
    padding: 0rem 8.2rem 0rem 0rem;
  }
}

@media (max-width: 325px) {
  .mobileBannerTitle {
    position: absolute;
    font-size: 0.7rem;
    font-weight: 700;
    top: 17px;
    color: var(--qrencia-white);
    left: 21px;
    padding: 0rem 1.3rem 0rem 0rem;
  }

  .mobileBannerSubtitle {
    position: absolute;
    bottom: 116px;
    color: var(--qrencia-white);
    left: 22px;
    font-size: 0.4rem;
    padding: 0rem 5.8rem 0rem 0rem;
  }
}

@media (min-width: 325px) and (max-width: 329px) {
  .mobileBannerTitle {
    position: absolute;
    font-size: 0.7rem;
    font-weight: 700;
    top: 17px;
    color: var(--qrencia-white);
    left: 21px;
    padding: 0rem 1.3rem 0rem 0rem;
  }

  .mobileBannerSubtitle {
    position: absolute;
    bottom: 121px;
    color: var(--qrencia-white);
    left: 22px;
    font-size: 0.4rem;
    padding: 0rem 6.1rem 0rem 0rem;
  }
}

@media (min-width: 1144px) and (max-width: 1290px) {
  .desktopImgContainer {
    display: block;
  }

  .tabletImgContainer {
    display: none;
  }

  .mobileImgContainer {
    display: none;
  }
}

@media (min-width: 1030px) and (max-width: 1144px) {
  .desktopImgContainer {
    display: block;
  }

  .tabletImgContainer {
    display: none;
  }

  .mobileImgContainer {
    display: none;
  }
}

@media (min-width: 665px) and (max-width: 900px) {
  .desktopImgContainer {
    display: none;
  }

  .tabletImgContainer {
    display: block;
    width: 85%;
    object-fit: cover;
    margin: 1em auto;
  }

  .mobileImgContainer {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 665px) {
  .desktopImgContainer {
    display: none;
  }

  .tabletImgContainer {
    display: block;
    width: 85%;
    object-fit: cover;
    margin: 1em auto;
  }

  .mobileImgContainer {
    display: none;
  }
}

@media (max-width: 480px) and (max-height: 720px) {
  .desktopImgContainer {
    display: none;
  }

  .tabletImgContainer {
    display: none;
  }

  .mobileImgContainer {
    display: block;
    width: 100%;
    object-fit: cover;
    margin: 1em auto;
  }
}

@media (max-width: 480px) {
  .desktopImgContainer {
    display: none;
  }

  .tabletImgContainer {
    display: none;
  }

  .bannerImage {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1198 / 884;
  }

  .mobileImgContainer {
    display: block;
    width: 100%;
    object-fit: cover;
    margin: 1em auto;
  }
}

@media (max-width: 480px) {
  .mobileImgContainer {
    display: block;
    width: 100%;
    object-fit: cover;
    margin: 1em auto;
  }
}

@media (max-width: 600px) {
  .eoiPageBannerSection :global(.react-multi-carousel-list) {
    display: block;
  }
}

@media (max-width: 400px) {
  .exploreSkillsText {
    font-size: 0.9em;
  }

  .bannerSubHeading {
    font-size: 0.8em;
  }
}

@media (max-width: 290px) {
  .listItem {
    width: 35%;
  }

  .loginButton {
    padding: 5% 15%;
    font-size: 0.6em;
  }

  .signUpButton {
    padding: 5% 15%;
    font-size: 0.6em;
  }

  .qrenciaLogo {
    width: 100%;
  }

  .desktopImgContainer {
    display: none;
  }

  .tabletImgContainer {
    display: none;
  }

  .mobileImgContainer {
    display: block;
    width: 85%;
    object-fit: cover;
    margin: 0m auto;
  }
}

@media (min-width: 480px) and (max-width: 540px) {
  .desktopImgContainer {
    display: none;
  }

  .tabletImgContainer {
    display: block;
    width: 100%;
    object-fit: cover;
    margin: 1em auto;
  }

  .mobileImgContainer {
    display: none;
  }
}

@media (min-width: 540px) and (max-width: 1290px) {
  .tabletImgContainer {
    width: 100%;
  }

  .desktopImgContainer {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .mobileBannerTitle {
    text-align: center;
    left: 20px;
  }

  .mobileBannerSubtitle {
    text-align: center;
    bottom: 197px;
    left: 50px;
    font-size: 0.54rem;
    padding: 0rem 1rem 0rem 0rem;
  }
}
@media (max-width: 466px) {
  .mobileBannerSubtitle {
    left: 43px;
  }
}
@media (max-width: 458px) {
  .mobileBannerSubtitle {
    bottom: 187px;
    left: 39px;
  }
}
@media (max-width: 449px) {
  .mobileBannerSubtitle {
    left: 33px;
  }
}
@media (max-width: 436px) {
  .mobileBannerSubtitle {
    left: 29px;
  }
}
@media (max-width: 429px) {
  .mobileBannerSubtitle {
    bottom: 178px;
    left: 11px;
    padding-left: 1rem;
  }
}
@media (max-width: 421px) {
  .mobileBannerSubtitle {
    bottom: 178px;
    left: 6px;
    padding-left: 1rem;
  }
}
@media (max-width: 416px) {
  .mobileBannerSubtitle {
    bottom: 175px;
    left: 2px;
    padding-left: 1rem;
  }
}

@media (max-width: 411px) {
  .mobileBannerSubtitle {
    bottom: 162px;
    left: 0px;
    padding-left: 1rem;
  }
}

@media (max-width: 389px) {
  .mobileBannerSubtitle {
    bottom: 152px;
  }
}

@media (max-width: 371px) {
  .mobileBannerSubtitle {
    bottom: 142px;
  }
}

@media (max-width: 352px) {
  .mobileBannerSubtitle {
    bottom: 133px;
  }
}

@media (max-width: 338px) {
  .mobileBannerSubtitle {
    bottom: 126px;
  }
}
