.qrenciaAtGlanceSection {
    background: var(--qrencia-white);
    display: flex;
    padding: 4.5% 13%;
}

.contentSection {
    width: 50%;
}

.videoPopupSection {
    width: 40%;
    padding: 0;
    display: grid;
    place-items: center;
}

.videoPlayIcon {
    margin-top: -12%;
    margin-left: 12%;
    width: 4%;
    position: absolute;
    cursor: pointer;
}

.videoThumbnailImage {
    cursor: pointer;
    width: 94.5%;
    position: relative;
    border-radius: 12px;
}

.titleText {
    color: var(--qrencia-orange-heading);
    font-size: 0.875em;
    font-weight: 600;
    line-height: 1.2em;
}

.subTitle {
    color: var(--qrencia-text-black);
    font-weight: 600;
    line-height: 1.2em;
    margin-top: -22px;
    font-size: 2em;
}

.description {
    color: var(--qrencia-text-black);
    font-weight: 500;
    font-size: 0.875em;
    margin-top: 2%;
}

.blueTriangleImage {
    margin-left: -8px;
    width: 5%;
}

span {
    color: var(--qrencia-dark-blue);
    font-weight: 800;
}

.orangeFrame {
    margin-bottom: -65px;
}

.orangeImage {
    width: 80px;
}

.blueFrame {
    text-align: right;
    margin-top: -69px;
}

.blueImage {
    width: 80px;
}

.thumbnailImage {
    text-align: center;
}

@media(min-width:767px) and (max-width:1290px) {
    .qrenciaAtGlanceSection {
        padding: 4.5% 9%;
    }

    .blueTriangleImage {
        margin-left: -8px;
        width: 6%;
    }
}

@media(max-width:767px) {
    .qrenciaAtGlanceSection {
        padding: 4.5% 3%;
    }

    .videoPopupSection {
        padding: 0% !important;
    }
}

@media(min-width:1160px) and (max-width:1290px) {
    .videoThumbnailImage {
        width: 93%;
    }
}

@media(min-width:900px) and (max-width:1160px) {
    .videoThumbnailImage {
        width: 92%;
    }

    .blueTriangleImage {
        width: 7%;
    }
}

@media(min-width:767px) and (max-width:900px) {
    .videoThumbnailImage {
        width: 91%;
    }
}

@media(min-width:990px) and (max-width:1185px) {
    .subTitle {
        font-size: 1.7em;
    }
}

@media(min-width:767px) and (max-width:990px) {
    .subTitle {
        font-size: 1.5em;
    }

    .titleText {
        font-size: 0.8em;
    }

    .description {
        font-size: 0.8em;
    }
}

@media(max-width:500px) {
    .blueTriangleImage {
        margin-left: -290px;
    }
}

@media(min-width:501px) and (max-width:767px) {
    .blueTriangleImage {
        margin-left: -288px;
    }
}

@media(max-width:767px) {
    .qrenciaAtGlanceSection {
        display: block;
        padding: 10% 0%;
    }

    .blueTriangleImage {
        margin-left: -350px;
        width: 4%;
    }

    .contentSection {
        width: 100%;
        text-align: center;
    }

    .videoPopupSection {
        width: 100%;
        margin-top: 4%;
    }

    .subTitle {
        font-size: 1.5em;
    }

    .titleText {
        font-size: 0.8em;
    }

    .description {
        font-size: 0.8em;
    }

    .videoPlayIcon {
        margin-top: -36%;
        margin-left: 44%;
        width: 9%;
    }
}

@media(max-width:480px) {
    .subTitle {
        font-size: 1.25em;
        font-weight: 650;
    }

    .blueTriangleImage {
        margin-left: -290px;
        width: 7%;
    }
}

@media(max-width:360px) {
    .subTitle {
        font-size: 1.2em !important;
    }
}