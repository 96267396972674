.payment_callback_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa; /* Light background */
  padding: 1rem;
}

.loading_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loading_spinner {
  margin-bottom: 1rem;
  font-size: 2rem; /* Adjust spinner size */
}

.alert {
  text-align: center;
  max-width: 500px;
  /* width: 100%; */
  padding: 1.5rem;
  /* font-size: 1.2rem; */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
