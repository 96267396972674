body {
    background-color: var(--qrencia-white);
  }
  
  .refundPolicyMainContainer {
    width: 100%;
    padding: 0% 10%;
    background-color: var(--qrencia-white);
    border-top: 1px solid var(--qrencia-grey-border);
    background-repeat: no-repeat;
  }
  
  .refundPolicyHeadingTitle>p>span,.refundPolicyDate>p>span,.refundPolicyDescription>p>span {
    font-weight: 600!important;
    color: var(--qrencia-dark-blue);
    font-size: 1.2em;
  }
  .refundPolicyHeadingTitle>h1>span>u>strong,.refundPolicyDate>h1>span>u>strong,.refundPolicyDescription>h1>span>u>strong {
    font-weight: 600;
    color: var(--qrencia-dark-blue);
  }
  
  .refundPolicyHeadingTitle>p>strong,.refundPolicyDate>p>strong,.refundPolicyDescription>p>strong {
    font-weight: 500;
  }
  
  .refundPolicyMainContainer .refundPolicySubContainer {
    width: 100%;
    padding: 4% 0%;
    height: auto;
  }
  
  
  .refundPolicyMainContainer
    .refundPolicySubContainer
    .refundPolicyHeading {
    width: 100%;
    text-align: center;
  
  }
  
  .refundPolicyMainContainer
    .refundPolicySubContainer
    .refundPolicyHeading
    .refundPolicyHeadingTitle {
    width: auto;
    float: none;
    text-align: center;
    color: var(--qrencia-dark-blue);
    font-weight: 600;
    display: inline-block;
    border-bottom: 3.5px solid var(--qrencia-dark-blue);
    padding-bottom: 4px;
    font-size: 28px;
  
  }
  
  
  .refundPolicyMainContainer
    .refundPolicySubContainer
    .refundPolicyDate {
    width: 100%;
    text-align: right;
    margin-top: 1%;
    font-size: 0.9em;
    color: var(--qrencia-text-black);
    margin-bottom: 20px;
  }
  
  @media (max-width: 1112px) {
    .refundPolicyMainContainer {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  
  @media (max-width: 480px) {
    .refundPolicyMainContainer {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .refundPolicyMainContainer .refundPolicySubContainer {
      margin-bottom: 15px;
    }
    .refundPolicyMainContainer
    .refundPolicySubContainer
    .refundPolicyDate {
    font-size: 0.8em;
  }
  }
  
  @media (max-width: 767px) {
    .refundPolicyMainContainer
      .refundPolicySubContainer
      .refundPolicyHeading
      .refundPolicyHeadingTitle {
      font-size: 1.05em;
    }
  
    .refundPolicyMainContainer
      .refundPolicySubContainer
      .refundPolicyHeading
      .refundPolicyDate {
      font-size: 12px;
    }
  
    p {
      font-size: 0.8em;
    }
    .refundPolicyDescription>ul>li {
      font-size: 0.8em;
    }
    .refundPolicyDescription>ol>li {
      font-size: 0.8em;
    }
  
    .refundPolicyHeadingTitle>p>strong,.refundPolicyDate>p>strong,.refundPolicyDescription>p>strong {
      font-size: 16px;
    }
    .refundPolicyHeadingTitle>h1>span>u>strong,.refundPolicyDate>h1>span>u>strong,.refundPolicyDescription>h1>span>u>strong {
      font-size: 16px;
    }
    .refundPolicyHeadingTitle>p>span,.refundPolicyDate>p>span,.refundPolicyDescription>p>span {
      font-size: 1.1em;
    }
  }
  @media (min-width: 992px) {
    .refundPolicyMainContainer {
      text-align: justify;
    }
  }
  @media (min-width: 1500px) {
    .refundPolicyMainContainer {
      padding: 0% 10%;
  
    }
  }
  @media (min-width:767px) and (max-width: 1499px) {
    .refundPolicyMainContainer {
      padding: 0% 8%;
    }
  }
  @media(max-width:767px){
    .refundPolicyMainContainer {
      padding: 0% 5%;
    }
  }
  @media(min-width:767px) and (max-width:991px){
    .refundPolicyMainContainer
    .refundPolicySubContainer
    .refundPolicyHeading
    .refundPolicyHeadingTitle {
    font-size: 1.5em;
  }
  
  .refundPolicyMainContainer
    .refundPolicySubContainer
    .refundPolicyHeading
    .refundPolicyDate {
    font-size: 12px;
  }
  
  p {
    font-size: 0.9em;
  }
  .refundPolicyDescription>ul>li {
    font-size: 0.9em;
  }
  .refundPolicyDescription>ol>li {
    font-size: 0.9em;
  }
  
  .refundPolicyHeadingTitle>p>strong,.refundPolicyDate>p>strong,.refundPolicyDescription>p>strong {
    font-size: 16px;
  }
  .refundPolicyHeadingTitle>h1>span>u>strong,.refundPolicyDate>h1>span>u>strong,.refundPolicyDescription>h1>span>u>strong {
    font-size: 20px;
  }
  .refundPolicyHeadingTitle>p>span,.refundPolicyDate>p>span,.refundPolicyDescription>p>span {
    font-size: 1.2em;
  }
  }
  
  @media(min-width:991px) and (max-width:1290px){
    .refundPolicyMainContainer
    .refundPolicySubContainer
    .refundPolicyHeading
    .refundPolicyHeadingTitle {
    font-size: 1.5em;
  }
  
  .refundPolicyMainContainer
    .refundPolicySubContainer
    .refundPolicyHeading
    .refundPolicyDate {
    font-size: 12px;
  }
  
  p {
    font-size: 0.95em;
  }
  .refundPolicyDescription>ul>li {
    font-size: 0.95em;
  }
  .refundPolicyDescription>ol>li {
    font-size: 0.95em;
  }
  
  .refundPolicyHeadingTitle>p>strong,.refundPolicyDate>p>strong,.refundPolicyDescription>p>strong {
    font-size: 16px;
  }
  .refundPolicyHeadingTitle>h1>span>u>strong,.refundPolicyDate>h1>span>u>strong,.refundPolicyDescription>h1>span>u>strong {
    font-size: 20px;
  }
  .refundPolicyHeadingTitle>p>span,.refundPolicyDate>p>span,.refundPolicyDescription>p>span {
    font-size: 1.2em;
  }
  }