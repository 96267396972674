.heading {
  font-size: 1em;
  padding: 0px 10px;
}

.modal {
  max-height: 60vh;
  display: flex;
}

.uploadButton {
  background-color: var(--qrencia-dark-blue);
  border: none;
  color: var(--qrencia-white);
  border-radius: 0.3em;
  font-size: 0.8em;
  padding: 0.6em 2em;
  cursor: pointer;
}

.contentContainer {
  display: grid;
  gap: 15px;
  padding: 10px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  overflow-y: scroll;
  flex: 4;
  overflow-x: hidden;
}

.contentContainerFullScreen {
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
}

.contentContainer::-webkit-scrollbar {
  width: 7px;
  height: 5px !important;
  background-color: var(--qrencia-white);
}

.contentContainer::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 5px;
}

.imageBox {
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--qrencia-light-grey-border);
}

.gallaryImage {
  cursor: pointer;
  width: 100%;
  /* height: 100%; */
  height: 12em;
  object-fit: fill;
  border-radius: 10px;
}

.iconSection {
  position: absolute;
  top: 5px;
  left: 5px;
}

.iconButton,
.iconButton:hover,
.iconButton:active {
  background-color: var(--qrencia-dark-blue) !important;
  /* opacity: 0.8; */
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin-right: 15px;
  font-size: 14px !important;
}

.iconSection>svg {
  font-size: 5px !important;
  cursor: pointer;
}

.gallaryImage:hover {
  transform: scale(1.01);
  transition: 0.2s ease-in-out;
}

.selectedImage {
  border: 1px solid var(--qrencia-nudges-card-border) !important;
  opacity: 0.9;
}

.fullScreenContent {
  position: relative;
  flex: 6;
  padding: 10px;
  align-items: center;
  display: flex;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid var(--qrencia-light-grey-border);
  justify-content: center;
}

.fullScreenImage {
  width: 100%;
  max-height: 60vh;
  border-radius: 10px;
  object-fit: contain !important;
  align-items: center;
  margin: auto;
  vertical-align: middle;
}

.crossButton {
  top: 1%;
  right: 0%;
  width: 8%;
  position: absolute;
  cursor: pointer;
  font-size: 2em;
  color: var(--qrencia-white);
}

.gallaryVideoPlayIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 2.5em;
  width: 2.5em;
}

.swipeButtons {
  position: absolute;
  top: 50%;
  /* background-color: var(--qrencia-text-black) !important; */
  /* opacity: 0.6; */
  background-color: var(--qrencia-dark-blue) !important;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  text-align: center;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.swipeButtons>svg {
  text-align: center;
  font-size: 20px !important;
}

.leftSwipeButton {
  left: 0%;
  transform: translate(50%, -50%);
}

.rightSwipeButton {
  right: 0%;
  transform: translate(-50%, -50%);
}

@media (max-width: 560px) {
  .contentContainer {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  .gallaryImage {
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 10px;
  }
}