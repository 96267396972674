.nav-tabs {
  background: var(--qrencia-white);
  border-radius: 8px;
  border: 1px solid var(--qrencia-tabs-border);
  padding: 0.5% 1% 0.5% 0%;
  justify-content: space-evenly;
}

.nav-tabs .nav-link {
  font-size: 0.9em;
  margin-bottom: -5.5px;
  color: var(--qrencia-tabs-inactive);
  font-weight: 650;
  width: max-content;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: auto;
}

.nav-fill .nav-item {
  padding-left: 3%;
  padding-right: 3%;
}

.nav-tabs .nav-link.active {
  font-size: 0.9em;
  color: var(--qrencia-text-black);
  font-weight: 650;
  border-color: transparent;
  background-color: transparent;
  border-bottom: 4px solid var(--qrencia-text-black) !important;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-item:hover,
.nav-item:focus {
  border-color: transparent;
}

.nav-link {
  padding-left: 0.2%;
  padding-right: 0.2%;
  /* padding-left: 0.2% !important;
  padding-right: 0.2% !important; */
}

@media (min-width: 1130px) and (max-width: 1160px) {
  .nav-tabs .nav-link {
    font-size: 0.8em;
  }

  .nav-tabs .nav-link.active {
    font-size: 0.8em;
  }

  .nav-fill .nav-item {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }
}

@media (min-width: 990px) and (max-width: 1130px) {
  .nav-tabs .nav-link {
    font-size: 0.8em;
  }

  .nav-tabs .nav-link.active {
    font-size: 0.8em;
  }

  .nav-fill .nav-item {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
}

@media (max-width: 990px) {
  .profileSkillCategoryDiv .nav-tabs .nav-link {
    font-size: 0.7em;
  }

  .nav-fill .nav-item {
    padding-left: 1%;
    padding-right: 1%;
  }

  .nav-tabs .nav-link {
    font-size: 0.75em;
  }

  .nav-tabs .nav-link.active {
    font-size: 0.75em;
    margin-bottom: -3px;
  }

  .nav-tabs {
    margin-bottom: 4%;
  }
}

@media (max-widt: 768px) {
  .profileSkillCategoryDiv .nav-tabs .nav-link {
    font-size: 0.75em !important;
  }
}

@media (max-width: 480px) {
  .nav-tabs {
    margin-bottom: 8%;
  }

  .nav-tabs .nav-link.active {
    border-bottom: 3px solid var(--qrencia-text-black) !important;
  }

  /* .nav-fill .nav-item {
    padding-left: 2% !important;
    padding-right: 2% !important;
  } */

  .nav-tabs .nav-link {
    font-size: 0.6em !important;
  }

  .nav-tabs .nav-link.active {
    font-size: 0.6em !important;
  }

  .nav-tabs .nav-link {
    margin-bottom: -2px !important;
  }
}