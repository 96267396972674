.achievementBtn {
  font-size: 0.8em;
  padding: 0.3em 1em;
}

.achievmentTitle {
  font-size: 1.3em;
  font-weight: 600;
}

.achievmentHeader {
  padding: 0.6em 1em;
}

.achievementErrorText {
  font-size: 0.75em;
  color: var(--qrencia-error-red);
}
