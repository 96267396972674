.forgotPasswordContainer {
  margin-top: -1em;
}
.forgotPasswordText {
  color: var(--linkedin-blue);
  font-size: 0.78em;
  font-weight: 400 !important;
  text-align: right;
  cursor: pointer;
}
.formContainer {
  max-width: 30em;
  margin: 1.5em auto 3em auto;
}
.loginFormHeading {
  color: var(--qrencia-dark-blue);
  font-size: 1.4em;
  font-weight: 600;
  margin-top: 0.5%;
  margin-bottom: 6%;
  text-align: center;
}
.loginFormSection {
  background-color: var(--qrencia-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 2rem;
  height: 35em;
  border-radius: 7px;
  box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 20%);
}
.signUpFormSection {
  background-color: var(--qrencia-white);
  padding: 0.9em 2.4em;
  font-size: 14.3px;
  box-shadow: 1px 1px 10px 2px rgb(0 0 0 / 20%);
  border-radius: 7px;
  margin-left: -5px;
  height: fit-content;
  min-height: 30rem;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.signupFormHeading {
  color: var(--qrencia-dark-blue);
  font-size: 1.4em;
  font-weight: 600;
  margin-top: 0.5%;
  margin-bottom: 6%;
  text-align: center;
}
.qrenciaLogoSection {
  padding-left: auto;
  padding-right: auto;
  margin: 0em 0em 1em 0em;
}
.qrenciaLogoSection a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
}
.formSection {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 40em !important;
}
.qrenciaLogo {
  height: 7em;
  object-fit: contain;
}

@media (max-width: 540px) {
  .formContainer {
    margin: 2em 1em;
  }
  .qrenciaLogo {
    height: 6em;
  }
}
