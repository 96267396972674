.cardContainer {
  height: fit-content;
  padding: 0.8em;
  margin: 1em 0em;
  background-color: var(--qrencia-white);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: 0.5s all ease-in-out;
  border: 1px solid var(--qrencia-nudges-card-border);
}
.profileImageSection {
  display: flex;
  justify-content: flex-start;
}
.nameInitials {
  height: 2.5em;
  width: 2.5em;
  background-color: var(--qrencia-course-skill-name-color);
  color: var(--qrencia-text-black);
  text-align: center;
  font-size: 0.9em;
  line-height: 2.5em;
  font-weight: 500;
  min-width: 2.5em;
  margin-right: 1em;
}
.profilePicture {
  height: 2.4em;
  width: 2.4em;
}

.learnerName {
  font-size: 0.82em;
  font-weight: 450 !important;
  color: var(--qrencia-dark-blue) !important;
}
.feedbackButton {
  font-size: 0.8em;
  margin-top: 0.5em;
}
.emailText {
  font-size: 0.72em;
  font-weight: 450 !important;
  opacity: 0.6;
  color: var(--qrencia-text-black) !important;
  word-wrap: break-word;
}
.cardButtonSection {
  margin-top: 0.6em;
  font-size: 0.8em;
}
@media (max-width: 767.5px) {
  .emailText {
    word-wrap: break-word;
  }
}
@media (max-width: 580px) {
  .emailText {
    word-wrap: break-word;
  }
}
@media (max-width: 480px) {
  .emailText {
    word-wrap: break-word;
  }
}
@media (max-width: 400px) {
  .nameInitials {
    height: 2.4em;
    width: 2.4em;
    font-size: 0.85em;
    line-height: 2.4em;
    font-weight: 500;
    min-width: 2.4em;
    margin-right: 1em;
  }
  .emailText {
    word-wrap: break-word;
  }
  .learnerName {
    font-size: 0.7em;
  }
}
