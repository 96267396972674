.cardsContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0.6em;
  margin-top: 0.5em;
}
.learningActivityCards {
  border-left: 0.5em solid var(--qrencia-dark-blue);
  border-radius: 0.4em;
  color: var(--qrencia-dark-blue);
  padding: 0em;
}
.learningActivityCardsIcon {
  color: var(--qrencia-dark-blue);
}
.learningActivityCardsColumn {
  padding: 1em 0em;
  font-size: 0.8em;
  color: var(--qrencia-dark-blue);
}
.showGrid {
  display: block;
}
.tableRoW{
  padding-left: 0;
  padding-right: 0;
}
.showCards {
  display: none;
}
.courseTextColor {
  color: var(--qrenica-course-differentiator-color);
}
.noDataError {
  padding: 3em 1em;
  margin: 1em 0;
  text-align: center;
  background-color: var(--qrencia-white);
  border-radius: 10px;
  color: var(--qrencia-grey-text);
  font-style: italic;
  font-weight: 400 !important;
  border: 1px solid var(--qrencia-cards-border);
}
.competitionTextColor {
  color: var(--qrenica-competition-differentiator-color);
}

.eventTypeField {
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg);
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  font-weight: 600 !important;
}

span.eventTypeField {
  font-size: 0.8em;
  background-color: var(--qrencia-competition-info-bg) !important;
  /* color: var(--qrencia-text-black); */
  border-radius: 0.4em;
  width: 8em;
  text-align: center;
  font-weight: 600 !important;
}

.loadMoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
}
.obrCardsButtonContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0.6em;
  margin: 0.5em 0;
}
@media (max-width: 991px) {
  .showGrid {
    display: none;
  }
  .showCards {
    display: block;
  }
}
