.reviewInput {
  display: flex;
  background-color: var(--qrencia-white);
  transition: 0.5s all ease-in-out;
  border: 1px solid var(--qrencia-certificate-preview-border);
  align-items: center;
  margin-top: 10px;
  margin-bottom: 0.5em;
  border: none;
}
.reviewInput > textarea {
  outline: none !important;
  min-height: 6.5em !important;
  resize: none;
  background-color: var(--qrencia-white);
  border-radius: 5px;
  border: solid 1px var(--qrencia-select-box-border);
}
.reviewInput > textarea:active,
.reviewInput > textarea:focus {
  border: solid 1px var(--qrencia-select-box-border) !important;
}
.inputTextBox,
.inputTextBox:active {
  padding: 0.5em 1em !important;
  border: solid 1px var(--qrencia-select-box-border);
}
.inputTextBox:focus,
.inputTextBox:active {
  outline: none !important;
  border: none !important;
  background-color: var(--qrencia-white) !important;
  border: solid 1px var(--qrencia-select-box-border);
}
.reviewInput > svg {
  font-size: 1.5em;
  margin-left: 1em;
  margin-right: 0.5em;
  cursor: pointer;
}
.ratingStars {
  color: var(--qrencia-golden-color) !important;
  font-size: 1.5em !important;
  margin: 0;
  cursor: pointer;
}
.starContainer {
  text-align: center !important;
}
.addReviewHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.captureRatingStarContainer {
  margin-top: 1em;
  margin-bottom: 10px;
  text-align: center !important;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.doneButton {
  text-align: center;
}
.ratingTitle {
  font-size: 1em;
  color: var(--qrencia-text-black);
  text-align: center;
  opacity: 0.7;
  font-weight: 450;
}
.yourRatingText {
  font-size: 0.8em;
  margin-right: 10px;
  color: var(--qrencia-placeholder-color);
}
.postReviewContainer {
  padding: 0.2em 1em;
}
.postButton {
  font-size: 0.8em;
  margin-top: 0.9em;
  color: var(--qrencia-white);
  cursor: pointer;
  text-align: left;
}
.closeButton {
  font-size: 0.8em;
  margin-top: 0.9em;
  color: var(--qrencia-text-black);
  background-color: var(--qrencia-white);
  cursor: pointer;
  text-align: left;
}
.displayReview {
  overflow-y: scroll;
  min-height: fit-content;
  min-height: -webkit-min-content;
  max-height: 31em;
  text-align: justify;
}
.buttonSection {
  text-align: left;
  display: flex;
  border: none;
  gap: 1em;
}
.doneSection {
  display: block !important;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.doneSection > button {
  padding: 0.4em 1em;
  font-size: 0.8em;
  margin-left: auto;
  margin-right: auto;
}
.buttonSection > button {
  padding: 0.4em 1em;
  font-size: 0.8em;
}
.displayReview::-webkit-scrollbar {
  display: none;
}
.formErrorMessage {
  color: var(--qrencia-error-red);
  font-size: 0.7em;
  margin-bottom: 0.4em;
  margin-top: 0.2em;
}
.loadMore {
  text-align: center;
  margin-top: 1em;
}
.modalFooter {
  border-top: none !important;
}
.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.8em;
  z-index: 100;
  width: 60%;
  opacity: 0.95;
}
.emojiIcons {
  margin-left: 5px;
  margin-top: 3px;
}

.profileImageSection {
  display: flex;
  justify-content: flex-start;
  margin-top: 1.5em;
}
.reviewInitials {
  height: 2.5em;
  width: 2.5em;
  background-color: var(--qrencia-course-skill-name-color);
  color: var(--qrencia-text-black);
  text-align: center;
  font-size: 0.9em;
  line-height: 2.5em;
  font-weight: 500;
  min-width: 2.5em;
  margin-right: 1em;
}
.profilePicture {
  height: 2.5em;
  width: 2.5em;
}
.reviewerName {
  font-size: 0.9em;
  font-weight: 450 !important;
  color: var(--qrencia-dark-blue) !important;
}
.description {
  font-size: 0.74em;
  opacity: 0.7;
}
