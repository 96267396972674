.formLayout {
  background-color: var(--qrencia-white);
  border: 1px solid var(--qrencia-cards-border);
  margin: 0em auto;
  padding: 1.3em 1em 2em 1em;
  border-radius: 6px;
}

.sectionDesc {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 2rem;
  font-weight: 700 !important;
  color: var(--qrencia-dark-blue);
}

.checkboxContainer {
  margin-top: 1em;
  margin-bottom: 0.5em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkboxText {
  font-weight: 500;
  margin-right: 1em;
  font-size: 0.95em;
  width: fit-content;
  display: flex;
  align-items: center;
}

.checkboxText input {
  margin-right: 1.2em;
  margin-top: 0.4em;
}

.checkboxContainer>label {
  line-height: 1em;
}

.formStyle {
  display: flex;
  justify-content: center;
}

.typeahead ::placeholder {
  color: #b6b7bc;
}

.typeahead {
  margin-top: -1em;
}

.selectedSkillsList {
  font-size: 0.8em;
}

.formHeading {
  margin-left: 0.3em;
  margin-right: 0.3em;
  font-size: 1.2em;
  font-weight: 600 !important;
  color: var(--qrencia-grey-text-color);
}

.formSubHeading {
  color: var(--qrencia-grey-text-color);
  font-size: 0.9em;
  font-weight: 500 !important;
  text-align: center;
  margin-bottom: 2em;
}

.buttonSection {
  width: 100%;
  margin-top: 1em;
  text-align: center;
}

.buttonSection>button {
  font-size: 0.9em;
}

.showInLargeScreen {
  display: block;
}

.showInMobileScreen {
  display: none;
}

.tncText {
  font-size: 0.8rem;
}

.tncTextHyper {
  text-decoration: none;
}

@media (max-width: 990px) {
  .formHeading {
    font-size: 1.1em;
  }
}

@media (max-width: 768px) {
  .formHeading {
    font-size: 1em;
  }

  .sectionDesc {
    font-size: 1.7rem;
  }

  .showInLargeScreen {
    display: none;
  }

  .showInMobileScreen {
    display: block;
  }
}

@media (max-width: 580px) {
  .formLayout {
    padding: 1em 0.5em 2em 0.5em;
    border-radius: 10px;
  }

  .sectionDesc {
    font-size: 1.5rem;
  }

  .formSubHeading {
    font-size: 1em;
  }
}

@media (max-width: 540px) {
  .formHeading {
    font-size: 0.95em;
  }

  .buttonSection>button {
    font-size: 1rem;
    padding: 1.3% 6%;
  }

  .formSubHeading {
    font-size: 0.8em;
  }
}