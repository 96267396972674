.accordionItem:first-of-type .accordionButton button {
  padding: 0.5rem 1rem;
}

.aboutUs {
  border-radius: 6px;
}

.accordionItem:last-of-type .accordionButton.collapsed {
  color: var(--bs-accordion-btn-color);
  background-color: var(--qrencia-white) !important;
}

.boldAnswer2 {
  cursor: pointer;
}

.aboutUsQuestions .accordionButton button {
  margin-top: 0 !important;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  background-color: var(--qrencia-white);
}

.accordionItem:last-of-type .accordionButton:not(.collapsed) button {
  color: #212529;
  font-weight: 400;
  font-size: 0.8rem;
}

.accordionBody span {
  color: var(--qrencia-black);
}

.aboutUsQuestions .accordionButton .collapsed button {
  background-color: var(--qrencia-white) !important;
}

.aboutUsQuestions
  :global(.accordion-item:last-of-type .accordion-button:not(.collapsed)) {
  background-color: var(--qrencia-card-light-grey-background) !important;
  color: var(--bs-accordion-btn-color) !important;
}

.aboutUsQuestions
  :global(
    .aboutUsQuestions
      .accordion-item:first-of-type
      .accordion-button:not(.collapsed)
  ) {
  background-color: var(--qrencia-card-light-grey-background) !important;
  color: var(--bs-accordion-btn-color) !important;
}

.aboutUsQuestions :global(.accordion-button:not(.collapsed)) {
  background-color: var(--qrencia-card-light-grey-background) !important;
  color: var(--bs-accordion-btn-color) !important;
}

.aboutUs {
  background-color: var(--qrencia-blue-background);
  padding: 3rem 2rem 1.8rem 2rem;
}

.aboutUsHelp {
  padding: 6rem 8rem 3rem 1.5rem;
  text-align: left;
}

.aboutUsHelp p {
  font-size: 1.2rem;
}

.aboutUsHelp h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: var(--qrencia-dark-blue);
}

.accordionBody {
  padding: 0.6rem 1rem;
  font-size: 0.8rem;
}

.accordionItem {
  margin-bottom: 1rem;
  border-radius: 7px;
  border: none;
}

.accordionItem:first-of-type .accordion-button {
  padding: 0.7rem 1rem;
  font-size: 0.8rem;
}

.accordionItem .accordion-button {
  margin-top: 0;
}

.accordionButton:not(.collapsed) {
  color: var(--bs-accordion-btn-color);
  background-color: none;
  box-shadow: none;
  border-radius: 7px;
}

.helpContact {
  color: var(--qrencia-text-black);
  font-size: 1.2rem;
  font-weight: 600 !important;
  margin-top: 1rem;
  cursor: pointer;
}

.helpContact svg {
  color: var(--qrencia-carousel-blue-card-background);
  font-size: 1.5rem;
  margin-right: 0.1rem;
}

.accordionButton {
  background-color: var(--bs-accordion-btn-bg);
}

@media (max-width: 1080px) {
  .aboutUsHelp p {
    font-size: 1.1rem;
  }

  .aboutUsHelp h2 {
    font-size: 1.9rem;
  }
}

@media (max-width: 916px) {
  .aboutUsHelp {
    padding: 3rem 5rem 3rem 1.5rem;
  }

  .aboutUsHelp p {
    font-size: 1rem;
  }

  .aboutUsHelp h2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 858px) {
  .aboutUsHelp p {
    font-size: 1rem;
  }

  .aboutUsHelp h2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 789px) {
  .aboutUsHelp {
    padding: 3rem 4rem 3rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .aboutUsHelp {
    padding: 0rem 1rem 2rem 1rem;
    text-align: center;
  }

  .aboutUsHelp p {
    font-size: 1.2rem;
  }

  .aboutUs {
    padding: 3rem 1.3rem 1.8rem 1.3rem;
  }
}

@media (max-width: 568px) {
  .aboutUsHelp p {
    font-size: 1rem;
  }

  .aboutUsHelp h2 {
    font-size: 1.5rem;
    margin-bottom: 0.8rem;
  }

  .aboutUs {
    padding: 2rem 1rem 1.8rem 1 rem;
  }
}

@media (max-width: 576px) {
  .aboutUsHelp h2 {
    font-size: 1.5rem;
  }

  .aboutUsHelp p {
    font-size: 1rem;
  }
}

@media (max-width: 430px) {
  .aboutUsHelp {
    padding: 0rem 1rem 2rem 1rem;
  }

  .aboutUsHelp p {
    font-size: 0.8rem;
  }

  .aboutUsHelp h2 {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }
}
