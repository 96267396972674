.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.2em;
}
.sectionHeading {
  font-size: 1.025em;
  margin: 2% 0% 0% 2%;
  font-weight: 650;
  color: var(--qrencia-text-black);
}
.learningActivityTabs {
  flex-grow: 1;
}
.searchInputBox {
  width: 40%;
  border-radius: 4px;
}
.resetSearchIcon {
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 400;
  margin-left: -1.2em;
  color: var(--qrencia-dark-blue);
  background-color: var(--qrencia-select-box-background);
}
.inputBoxContainer {
  padding: 0.8em;
  margin-bottom: 1em;
  margin-top: 0.4em;
}
@media (max-width: 1200px) {
  .header {
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1290px) {
  .sectionHeading {
    font-size: 1em;
    margin-left: 3%;
  }
}
@media (min-width: 768px) and (max-width: 1100px) {
  .sectionHeading {
    font-size: 1em;
  }
  .header {
    display: block;
  }
  .searchInputBox {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .sectionHeading {
    font-size: 0.95em;
  }
  .learningActivityTabs {
    margin-bottom: 0em;
  }
  .header {
    display: flex;
  }
  .searchInputBox {
    width: 50%;
  }
}
@media (max-width: 675px) {
  .header {
    display: block;
  }
  .learningActivityTabs {
    margin-bottom: 0em;
  }
  .searchInputBox {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .header {
    display: block;
  }
  .learningActivityTabs {
    margin-bottom: -1.2em;
  }
  .searchInputBox {
    width: 100%;
  }
  .inputBoxContainer {
    padding: 0.8em;
    margin-bottom: 0em;
    margin-top: 2em;
  }
}
