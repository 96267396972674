.qrenciaLogoSection {
    width: 20%;
    justify-content: flex-end;
}
.qrenciaLogo {
    width: 55%;
}

.header {
    padding: 0.65% 6.5%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    background: var(--qrencia-white);
}

@media (min-width:1030px) and (max-width:1144px) {
    .qrenciaLogo {
        width: 60%;
    }
}

@media (min-width:900px) and (max-width:1030px) {
    .qrenciaLogo {
        width: 65%;
    }

    .header {
        padding: 1% 6.5%;
    }
}

@media (min-width:665px) and (max-width:900px) {
    .qrenciaLogo {
        width: 70%;
    }

    .header {
        padding: 1% 6.5%;
    }
}

@media (min-width:480px) and (max-width:665px) {
    .qrenciaLogo {
        width: 75%;
        margin-top: 2%;
    }
}

@media (max-width:480px) {
    .qrenciaLogo {
        width: 100%;
    }

    .header {
        padding: 1.5% 6.5%;
    }
}

@media (max-width:290px) {
    .qrenciaLogo {
        width: 100%;
    }
}