.mobileCarouselSignup {
  display: block;
}

.carouselImage {
  width: 100%;
  height: 48rem;
  min-height: 100vh;
  object-fit: contain;
}

/* Background colors for specific slides */
.backgroundSlide1 {
  background-color: var(--qrencia-slider-bg-one);
}

.backgroundSlide2 {
  background-color: var(--qrencia-slider-bg-one);
}

.backgroundSlide3 {
  background-color: var(--qrencia-slider-bg-two);
}

.backgroundSlide4 {
  background-color: var(--qrencia-slider-bg-three);
}

.backgroundSlide5 {
  background-color: var(--qrencia-slider-bg-four);
}

.backgroundSlide6 {
  background-color: var(--qrencia-slider-bg-five);
}

.carouselImageLogin {
  width: 100%;
  height: 41rem;
  min-height: 100vh;
  object-fit: contain;
}

/* 
.carouselItem {
  height: 30em !important;
} */

.carouselCaption {
  left: 0%;
  width: 100% !important;
  top: 0;
  padding: 4.5% 10% 4.5% 4.5%;
  position: absolute;
}

.headingText {
  font-size: 1.75em !important;
  color: var(--qrencia-text-black);
}

.subHeadingText {
  font-size: 0.9em;
  margin-top: 4%;
}

.imageContainer {
  position: relative;
}

@keyframes animateCaption {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.headingText {
  font-size: 3em;
  font-weight: 600;
}

.subHeadingText {
  font-size: 1.12em;
  margin-top: 4%;
}

@media (max-width: 991.5px) {
  .mobileCarouselSignup {
    display: none;
  }
}

@media (min-width: 1586px) and (max-width: 2600px) {
  .carouselItem {
    height: 30rem !important;
  }

  .carouselSignupItem {
    height: 35rem !important;
  }

  .carouselEnrolSignupItem {
    height: 29rem !important;
  }
}

@media (min-width: 1441px) and (max-width: 1585.5px) {
  .carouselItem {
    height: 30rem !important;
  }

  .carouselSignupItem {
    height: 35rem !important;
  }

  .carouselEnrolSignupItem {
    height: 29rem !important;
  }
}

@media (min-width: 1424px) and (max-width: 1441.5px) {
  .carouselItem {
    height: 30rem !important;
  }

  .carouselSignupItem {
    height: 35rem !important;
  }

  .carouselEnrolSignupItem {
    height: 29rem !important;
  }
}

@media (min-height: 1365px) and (max-height: 1367px) and (min-width: 1023px) and (max-width: 1025px) {
  .signUpFormSection {
    max-height: 50em;
  }

  .carouselImage,
  .carouselImageLogin {
    max-height: 37.5em;
    min-height: 37.5em;
    object-fit: cover;
  }
}

@media (min-width: 1279px) and (max-width: 1281px) and (min-height: 1023px) and (max-height: 1025px) {

  .carouselImage,
  .carouselImageLogin {
    max-height: 48em !important;
    min-height: 48em;
    object-fit: cover;
  }
}

@media (min-height: 767px) and (max-height: 768px) and (min-width: 1023px) and (max-width: 1025px) {

  .carouselImage,
  .carouselImageLogin {
    max-height: 40em;
    min-height: 40em;
    object-fit: cover;
  }
}

@media (min-width: 1188px) and (max-width: 1424px) {
  .carouselItem {
    height: 30rem !important;
  }

  .carouselSignupItem {
    height: 35.4rem !important;
  }

  .carouselEnrolSignupItem {
    height: 29rem !important;
  }
}

@media (min-width: 1150px) and (max-width: 1187px) {
  .carouselSignupItem {
    height: 35.2rem !important;
  }

  .carouselItem {
    height: 30rem !important;
  }

  .carouselEnrolSignupItem {
    height: 29rem !important;
  }
}

@media (min-width: 1028px) and (max-width: 1150px) {
  .carouselItem {
    height: 30rem !important;
  }

  .carouselSignupItem {
    height: 32.2rem !important;
    margin-top: 4%;
  }

  .carouselEnrolSignupItem {
    height: 29rem !important;
  }
}

@media (min-width: 900px) and (max-width: 1027.5px) {
  .carouselItem {
    height: 30rem !important;
  }

  .carouselSignupItem {
    height: 30.8rem !important;
    margin-top: 7%;
  }

  .carouselEnrolSignupItem {
    height: 29rem !important;
  }
}

@media (min-width: 860px) and (max-width: 900px) {
  .carouselItem {
    height: 31.8rem !important;
  }

  .carouselSignupItem {
    height: 35rem !important;
  }

  .carouselEnrolSignupItem {
    height: 29rem !important;
  }
}

@media (min-width: 847px) and (max-width: 860px) {
  .carouselItem {
    height: 31.8rem !important;
  }

  .carouselSignupItem {
    height: 35rem !important;
  }

  .carouselEnrolSignupItem {
    height: 29rem !important;
  }
}

@media (min-width: 804px) and (max-width: 847px) {
  .carouselItem {
    height: 31.8rem !important;
  }

  .carouselSignupItem {
    height: 35rem !important;
  }

  .carouselEnrolSignupItem {
    height: 29rem !important;
  }
}

@media (min-width: 768px) and (max-width: 804px) {
  .carouselItem {
    height: 31.8rem !important;
  }

  .carouselSignupItem {
    height: 35rem !important;
  }

  .carouselEnrolSignupItem {
    height: 29rem !important;
  }
}

@media (max-width: 767.5px) {
  .carouselImage {
    height: 88vh !important;
  }
}

@media (max-width: 1290px) {
  .headingText {
    font-size: 2.7em;
  }
}

@media (max-width: 1162px) {
  .carouselCaption {
    width: 49%;
  }
}

@media (max-width: 1076px) {
  .headingText {
    font-size: 2.5em;
  }
}

@media (max-width: 990px) {
  .headingText {
    font-size: 2.3em;
  }

  .carouselCaption {
    width: 50%;
  }

  .subHeadingText {
    font-size: 1em;
  }
}

@media (max-width: 876px) {
  .headingText {
    font-size: 2.2em;
  }

  .carouselCaption {
    width: 62%;
  }
}