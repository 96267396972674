.loginSection {
    margin-top: 4em;
}

.loaderIcon {
    font-size: 3em !important;
    color: var(--qrencia-dark-blue) !important;
}

.sessionExpiredText {
    font-size: 1em;
}

.sessionExpiredText>span {
    color: var(--linkedin-blue);
    font-weight: 600 !important;
    cursor: pointer;
}

.loaderContainer {
    padding: 2em;
    border-radius: 10px;
    border: 1px solid var(--qrencia-grey-border);
    width: 80%;
    margin: 1em auto;
    text-align: center;
    background-color: var(--qrencia-white);
    margin-top: 5em;
}

.pageContainer {
    width: 90%;
    margin: 4em auto 4em auto;
    padding: 1.5em 3em;
    background-color: var(--qrencia-white);
    border-radius: 5px;
}

.pageContentSection {
    margin-bottom: 20%;
}

.learnerDetailsSection {
    display: flex;
    justify-content: space-between;
}

.heading {
    font-size: 1.4em;
    color: var(--qrencia-text-black);
    margin: 0.5em 0.5em 0.8em 0em;
}

.selectButton {
    font-size: 0.8em !important;
    padding: 0.61em 1em;
    margin-top: 1em;
    width: 9em;
}

.viewRecommendationsButton {
    font-size: 0.7em !important;
    padding: 0.61em 1em;
    margin-top: 1em;
}

.idInputFieldSection {
    padding: 0 !important;
}

.detailsColumHrContainer {
    display: flex;
    justify-content: flex-start;
    gap: 0.5em;
    margin-top: 1em;
    color: var(--qrencia-dark-blue);
    font-size: 1em;
}

.detailsHeading {
    font-size: 1em;
}

.learnerDetailsLinkLabel,
.learnerDetailsLinkLabel:hover,
.learnerDetailsLinkLabel:active,
.learnerDetailsLinkLabel:focus {
    text-decoration: none;
    font-size: 0.75em !important;
    cursor: pointer;
    color: var(--qrencia-text-black);
    font-size: 0.8em;
    background-color: var(--qrencia-competition-info-bg);
    border-radius: 0.4em;
    width: 8em;
    text-align: center;
    padding: 1px 8px;
}

.columnContainer {
    margin-top: 1em;
}

.learnerDetailsLabel {
    color: var(--qrencia-dark-blue);
    font-size: 0.8em;
    font-weight: 500 !important;
}

.learnerDetailsText {
    font-size: 0.8em;
    word-wrap: break-word;
}

@media (max-width: 767.5px) {
    .pageContainer {
        width: 90%;
        margin: 1em auto;
        padding: 1.5em 2em;
    }

    .buttonFieldSection {
        padding: 0 !important;
    }
}

@media (max-width: 500px) {
    .heading {
        font-size: 1.2em;
        color: var(--qrencia-text-black);
        margin: 0.5em 0.5em 0.8em 0em;
    }
}

@media (max-width: 400px) {
    .pageContainer {
        padding: 1em;
    }

    .viewRecommendationsButton {
        font-size: 0.5em !important;
        padding: 0.61em 1em;
        margin-top: 1.5em;
    }
}