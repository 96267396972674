.pricingCard {
  position: relative;
  background-color: var(--qrencia-select-box-background);
  border: 2px solid black;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px 18px;
}

.popularTag {
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffbf00;
  color: white;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 8px;
  z-index: 1;
}

.closeButton {
  background: transparent;
  border: none;
  font-size: 2em;
  padding: 0%;
  display: flex;
  margin-top: -0.4em;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.closeButton:hover {
  color: var(--qrencia-error-message)
}

.modalBody {
  padding: 4rem 4rem;
}

.planTitle {
  font-size: 1.33rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
}

.plan-price {
  font-size: 2.369rem;
  /* font-weight: bold; */
}

.originalPrice {
  text-decoration: line-through;
  color: var(--qrencia-grey-text);
  margin-right: 0.5rem;
}

.originalYearlyPrice {
  text-decoration: line-through;
  color: var(--qrencia-grey-text);
  margin-right: 0.5rem;
}

.discountedPrice {
  color: var(--qrencia-text-black);
}

.discountedYearlyPrice {
  /* font-weight: 500;
  color: rgb(14, 14, 14); */
}

.monthTitle {
  font-size: 1rem;
  color: var(--qrencia-grey-text);
  font-weight: 500 !important;
}

.billingInfo {
  font-size: 1rem;
  font-weight: 400;
}

.checkoutButton {
  width: 100%;
  height: 3rem;
  border-radius: 0.4rem;
  transition: filter 0.3s ease;
}

.checkoutButton:hover {
  filter: brightness(1.2);
}

.savingsInfo {
  color: #27ae60;
  font-size: 0.75rem;
  margin-bottom: 2.2rem;
}

.featuresList {
  list-style: none;
  padding: 0;
}

.featuresList li {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  /* color: #2c3e50; */
  margin-bottom: 0.5rem;
}

.checkmark {
  color: #224b7f;
  margin-right: 8px;
  /* font-size: 1.2rem; */
}

@media (max-width: 500px) {
  .modalBody {
    padding: 4rem 1rem;
  }
}
