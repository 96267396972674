.certificate {
  margin: 0em;
  aspect-ratio: 4 / 3;
  margin-left: 1em;
  margin-right: 1em;
  position: relative;
  font-size: 16px;
}
.certificateContent {
  padding: 5% 5% 5% 5%;
  position: relative;
  height: 100%;
  width: 100%;
}
.certificateCourseTitle {
  color: var(--qrencia-dark-blue);
}
.certificateWorkshopTitle {
  color: var(--qrencia-dark-blue);
}
.certificateBackground {
  position: absolute;
  object-fit: contain;
  height: 100%;
  width: 100%;
  z-index: -100;
}
.logoSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 4%;
}
.certificateLogo {
  width: 7%;
  height: 80%;
  margin: 0.2em 0.6em;
  object-fit: contain;
  margin-right: 8%;
}
.certificateTitle {
  font-size: 80%;
  color: var(--qrencia-dark-blue);
  margin-top: 3%;

  font-weight: 600;
  text-align: center;
}
.presentedToText {
  font-weight: 550;
  font-size: 40%;
  text-align: center;
}
.nameText {
  font-size: 50%;

  margin-top: 2%;
  font-weight: 600;
  text-align: center;
}
.bodyContainer {
  margin-top: 2.5%;
}
.certificateBody {
  margin-top: 2%;
  font-size: 45%;
  /* width: 60%; */
  text-align: center;
}
.certificateDescription {
  font-size: 35%;
  text-align: center;
}
.certificateMessage {
  margin-top: 2%;
  font-size: 30%;
  width: 70%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.signatorySection {
  position: absolute;
  bottom: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: -5%;
}
.signatoryCard {
  margin-right: 1.5%;
  margin-left: 1.5%;
  width: 20%;
}
.signatoryDetails {
  border-top: 1px solid var(--qrencia-text-black);
  padding-top: 0.4em;
  height: 2.6em;
}
.signatoryImage {
  height: 1.6em;
  width: 100%;
  text-align: center;
}
.signatoryImage > img {
  height: 100%;
}
.signatoryName {
  font-size: 22%;
  font-weight: 520;
  text-align: center;
}
.signatoryDesignation {
  font-size: 19%;
  font-weight: 520;
  text-align: center;
}
.signatoryOrg {
  font-size: 19%;
  font-weight: 500;
  text-align: center;
}
.certificateContent h3,
.certificateContent h6 {
  margin-bottom: 0.4em;
}
.certificateSignature {
  object-fit: contain;
  top: 75%;
  left: 50%;
  height: 10%;
  width: 10%;
}
@media (min-width: 1431px) {
  .certificateTitle {
    font-size: 95%;
  }
  .certificateBody {
    font-size: 40%;
  }
  .presentedToText {
    font-size: 40%;
  }
  .nameText {
    font-size: 55%;
  }
  .signatoryName {
    font-size: 24%;
  }
  .signatoryDesignation {
    font-size: 20%;
  }
  .signatoryOrg {
    font-size: 20%;
  }
  .signatorySection {
    bottom: 1%;
  }
  .certificateMessage {
    font-size: 35%;
  }
  .certificateDescription {
    font-size: 42%;
    text-align: center;
  }
}
@media (min-width: 1600px) {
  .certificateTitle {
    font-size: 105%;
  }
  .certificateBody {
    font-size: 50%;
  }
  .presentedToText {
    font-size: 45%;
  }
  .nameText {
    font-size: 60%;
  }
  .signatoryName {
    font-size: 26%;
  }
  .signatoryDesignation {
    font-size: 24%;
  }
  .signatoryOrg {
    font-size: 24%;
  }
  .signatorySection {
    bottom: 5%;
  }
  .certificateMessage {
    font-size: 40%;
  }
  .certificateDescription {
    font-size: 50%;
    text-align: center;
  }
}
@media (min-width: 1700px) {
  .certificateTitle {
    font-size: 115%;
  }
  .certificateBody {
    font-size: 55%;
  }
  .presentedToText {
    font-size: 50%;
  }
  .nameText {
    font-size: 65%;
  }
  .signatoryName {
    font-size: 28%;
  }
  .signatoryDesignation {
    font-size: 26%;
  }
  .signatoryOrg {
    font-size: 26%;
  }
  .signatorySection {
    bottom: 5%;
  }
  .certificateMessage {
    font-size: 45%;
  }
  .certificateDescription {
    font-size: 55%;
    text-align: center;
  }
}
@media (min-width: 1800px) {
  .certificateTitle {
    font-size: 125%;
  }
  .certificateBody {
    font-size: 58%;
  }
  .presentedToText {
    font-size: 55%;
  }
  .nameText {
    font-size: 70%;
  }
  .signatoryName {
    font-size: 30%;
  }
  .signatoryDesignation {
    font-size: 28%;
  }
  .signatoryOrg {
    font-size: 28%;
  }
  .signatorySection {
    bottom: 5%;
  }
  .certificateMessage {
    font-size: 50%;
  }
  .certificateDescription {
    font-size: 60%;
    text-align: center;
  }
}
@media (min-width: 2000px) {
  .certificateLogo {
    width: 7%;
  }
  .certificateTitle {
    font-size: 150%;
  }
  .certificateBody {
    font-size: 65%;
  }
  .presentedToText {
    font-size: 60%;
  }
  .nameText {
    font-size: 80%;
  }
  .signatoryName {
    font-size: 35%;
  }
  .signatoryDesignation {
    font-size: 30%;
  }
  .signatoryOrg {
    font-size: 30%;
  }
  .signatorySection {
    bottom: 5%;
  }
  .certificateMessage {
    font-size: 55%;
  }
  .certificateDescription {
    font-size: 65%;
    text-align: center;
  }
}
@media (min-width: 1400px) and (max-width: 1430px) {
  .certificateTitle {
    font-size: 130%;
  }
  .certificateBody {
    font-size: 65%;
  }
  .presentedToText {
    font-size: 60%;
  }
  .nameText {
    font-size: 80%;
  }
  .signatoryName {
    font-size: 35%;
  }
  .signatoryDesignation {
    font-size: 30%;
  }
  .signatoryOrg {
    font-size: 30%;
  }
  .signatorySection {
    bottom: 5%;
  }
  .certificateMessage {
    font-size: 55%;
  }
  .certificateDescription {
    font-size: 65%;
    text-align: center;
  }
}

@media (min-width: 1100px) and (max-width: 1400px) {
  .certificateLogo {
    width: 7%;
  }
  .certificateTitle {
    font-size: 130%;
  }
  .certificateBody {
    font-size: 65%;
  }
  .presentedToText {
    font-size: 60%;
  }
  .nameText {
    font-size: 80%;
  }
  .signatoryName {
    font-size: 35%;
  }
  .signatoryDesignation {
    font-size: 30%;
  }
  .signatoryOrg {
    font-size: 30%;
  }
  .signatorySection {
    bottom: 5%;
  }
  .certificateMessage {
    font-size: 55%;
  }
  .certificateDescription {
    font-size: 65%;
    text-align: center;
  }
}
@media (min-width: 1050px) and (max-width: 1120px) {
  .certificateLogo {
    width: 7%;
  }
  .certificateTitle {
    font-size: 120%;
  }
  .certificateBody {
    font-size: 62%;
  }
  .presentedToText {
    font-size: 58%;
  }
  .nameText {
    font-size: 76%;
  }
  .signatoryName {
    font-size: 33%;
  }
  .signatoryDesignation {
    font-size: 28%;
  }
  .signatoryOrg {
    font-size: 28%;
  }
  .signatorySection {
    bottom: 5%;
  }
  .certificateMessage {
    font-size: 50%;
  }
  .certificateDescription {
    font-size: 60%;
    text-align: center;
  }
}
@media (min-width: 1000px) and (max-width: 1050px) {
  .certificateLogo {
    width: 7%;
  }
  .certificateTitle {
    font-size: 110%;
  }
  .certificateBody {
    font-size: 60%;
  }
  .presentedToText {
    font-size: 56%;
  }
  .nameText {
    font-size: 75%;
  }
  .signatoryName {
    font-size: 32%;
  }
  .signatoryDesignation {
    font-size: 26%;
  }
  .signatoryOrg {
    font-size: 26%;
  }
  .signatorySection {
    bottom: 5%;
  }
  .certificateMessage {
    font-size: 48%;
  }
  .certificateDescription {
    font-size: 55%;
    text-align: center;
  }
}
@media (min-width: 840px) and (max-width: 999px) {
  .certificateLogo {
    width: 7%;
  }
  .certificateTitle {
    font-size: 100%;
  }
  .certificateBody {
    font-size: 50%;
  }
  .presentedToText {
    font-size: 40%;
  }
  .nameText {
    font-size: 50%;
  }
  .signatoryName {
    font-size: 30%;
  }
  .signatoryDesignation {
    font-size: 25%;
  }
  .signatoryOrg {
    font-size: 25%;
  }
  .signatorySection {
    bottom: 3%;
  }
  .certificateMessage {
    font-size: 50%;
  }
  .certificateDescription {
    font-size: 55%;
    text-align: center;
  }
}
@media (min-width: 800px) and (max-width: 840px) {
  .certificateTitle {
    font-size: 95%;
  }
  .certificateBody {
    font-size: 48%;
  }
  .presentedToText {
    font-size: 38%;
  }
  .nameText {
    font-size: 48%;
  }
  .signatoryName {
    font-size: 28%;
  }
  .signatoryDesignation {
    font-size: 24%;
  }
  .signatoryOrg {
    font-size: 24%;
  }
  .signatorySection {
    bottom: 3%;
  }
  .certificateMessage {
    font-size: 48%;
  }
  .certificateDescription {
    font-size: 48%;
    text-align: center;
  }
}
@media (min-width: 767px) and (max-width: 800px) {
  .certificateTitle {
    font-size: 92%;
  }
  .certificateBody {
    font-size: 46%;
  }
  .presentedToText {
    font-size: 36%;
  }
  .nameText {
    font-size: 46%;
  }
  .signatoryName {
    font-size: 26%;
  }
  .signatoryDesignation {
    font-size: 24%;
  }
  .signatoryOrg {
    font-size: 24%;
  }
  .signatorySection {
    bottom: 3%;
  }
  .certificateMessage {
    font-size: 46%;
  }
  .certificateDescription {
    font-size: 46%;
    text-align: center;
  }
}
@media (min-width: 710px) and (max-width: 766.5px) {
  .certificateTitle {
    font-size: 150%;
  }
  .certificateBody {
    font-size: 80%;
  }
  .presentedToText {
    font-size: 80%;
  }
  .nameText {
    font-size: 85%;
  }
  .signatoryName {
    font-size: 45%;
  }
  .signatoryDesignation {
    font-size: 40%;
  }
  .signatoryOrg {
    font-size: 40%;
  }
  .signatorySection {
    bottom: 8%;
  }
  .certificateDescription {
    font-size: 75%;
    text-align: center;
  }
  .certificateMessage {
    font-size: 65%;
  }
}
@media (min-width: 660px) and (max-width: 710px) {
  .certificateTitle {
    font-size: 140%;
  }
  .certificateBody {
    font-size: 70%;
  }
  .presentedToText {
    font-size: 70%;
  }
  .nameText {
    font-size: 75%;
  }
  .signatoryName {
    font-size: 40%;
  }
  .signatoryDesignation {
    font-size: 35%;
  }
  .signatoryOrg {
    font-size: 35%;
  }
  .signatorySection {
    bottom: 8%;
  }
  .certificateDescription {
    font-size: 65%;
    text-align: center;
  }
  .certificateMessage {
    font-size: 60%;
  }
}
@media (min-width: 640px) and (max-width: 660px) {
  .certificateTitle {
    font-size: 130%;
  }
  .certificateBody {
    font-size: 65%;
  }
  .presentedToText {
    font-size: 65%;
  }
  .nameText {
    font-size: 70%;
  }
  .signatoryName {
    font-size: 36%;
  }
  .signatoryDesignation {
    font-size: 32%;
  }
  .signatoryOrg {
    font-size: 32%;
  }
  .signatorySection {
    bottom: 6%;
  }
  .certificateDescription {
    font-size: 60%;
    text-align: center;
  }
  .certificateMessage {
    font-size: 60%;
  }
}
@media (min-width: 590px) and (max-width: 639px) {
  .certificateLogo {
    width: 6%;
  }
  .certificateTitle {
    font-size: 120%;
  }
  .certificateBody {
    font-size: 55%;
  }
  .presentedToText {
    font-size: 55%;
  }
  .nameText {
    font-size: 60%;
  }
  .signatoryName {
    font-size: 30%;
  }
  .signatoryDesignation {
    font-size: 25%;
  }
  .signatoryOrg {
    font-size: 25%;
  }
  .signatorySection {
    bottom: 2%;
  }
  .certificateDescription {
    font-size: 65%;
    text-align: center;
  }
  .certificateMessage {
    width: 85%;
    font-size: 55%;
  }
}
@media (min-width: 550px) and (max-width: 589px) {
  .certificateLogo {
    width: 6%;
  }
  .certificateTitle {
    font-size: 110%;
  }
  .certificateBody {
    font-size: 50%;
  }
  .presentedToText {
    font-size: 50%;
  }
  .nameText {
    font-size: 55%;
  }
  .signatoryName {
    font-size: 26%;
  }
  .signatoryDesignation {
    font-size: 22%;
  }
  .signatoryOrg {
    font-size: 22%;
  }
  .signatorySection {
    bottom: 2%;
  }
  .certificateDescription {
    font-size: 60%;
    text-align: center;
  }
  .certificateMessage {
    width: 85%;
    font-size: 50%;
  }
}
@media (max-width: 550px) {
  .certificateLogo {
    width: 6%;
  }
  .certificateDescription {
    font-size: 50%;
    text-align: center;
  }
  .certificateMessage {
    width: 85%;
    font-size: 50%;
  }
}
@media (max-width: 480px) {
  .certificateBody {
    font-size: 35%;
  }
  .certificateContent h3,
  .certificateContent h6 {
    margin-bottom: 0.4em;
  }
  .certificateDescription {
    font-size: 40%;
    text-align: center;
  }
  .certificateMessage {
    width: 85%;
    font-size: 35%;
  }
  .certificateTitle {
    font-size: 100%;
  }
  .certificateBody {
    font-size: 45%;
  }
  .presentedToText {
    font-size: 45%;
  }
  .nameText {
    font-size: 50%;
  }
  .signatoryName {
    font-size: 24%;
  }
  .signatoryDesignation {
    font-size: 22%;
  }
  .signatoryOrg {
    font-size: 22%;
  }
  .signatorySection {
    bottom: 2%;
  }
}
@media (max-width: 430px) {
  .certificateDescription {
    font-size: 35%;
    text-align: center;
  }
  .certificateMessage {
    width: 85%;
    font-size: 30%;
  }
  .certificateTitle {
    font-size: 90%;
  }
  .certificateBody {
    font-size: 40%;
  }
  .presentedToText {
    font-size: 42%;
  }
  .nameText {
    font-size: 48%;
  }
  .signatoryName {
    font-size: 24%;
  }
  .signatoryDesignation {
    font-size: 22%;
  }
  .signatoryOrg {
    font-size: 22%;
  }
  .signatorySection {
    bottom: 2%;
  }
}
@media (max-width: 400px) {
  .certificateTitle {
    font-size: 60%;
  }
  .presentedToText {
    font-size: 40%;
  }
  .nameText {
    font-size: 45%;
  }
  .signatoryName {
    font-size: 20%;
  }
  .signatoryDesignation {
    font-size: 19%;
  }
  .signatoryOrg {
    font-size: 19%;
  }
  .signatorySection {
    bottom: -2%;
  }
  .certificateContent h3,
  .certificateContent h6 {
    margin-bottom: 0.4em;
  }
  .certificateDescription {
    font-size: 30%;
    text-align: center;
  }
}
@media (max-width: 365px) {
  .certificateTitle {
    font-size: 50%;
  }
  .presentedToText {
    font-size: 37%;
  }
  .nameText {
    font-size: 40%;
  }
  .signatoryName {
    font-size: 18%;
  }
  .signatoryDesignation {
    font-size: 16%;
  }
  .signatoryOrg {
    font-size: 16%;
  }
  .signatorySection {
    bottom: -4%;
  }
  .certificateDescription {
    font-size: 28%;
    text-align: center;
  }
  .certificateMessage {
    font-size: 28%;
    text-align: center;
  }
}
