.sectionDesc {
  font-size: 2rem;
  font-weight: 700 !important;
  color: var(--qrencia-dark-blue);
}

.pricingCard {
  padding: 1.7rem 1.5rem 3rem 1.5rem;
  background-color: var(--qrencia-blue-background);
  border-radius: 6px;
}

.pricingDescription {
  font-size: 1.3rem;
  padding: 10rem 1rem 1rem 1rem;
}

.subscriptionImage {
  width: 100%;
}

.pricingDescription p {
  margin-bottom: 2rem;
}

.priceCard:first-child {
  margin-right: 1.2rem;
}

.priceCard {
  border-radius: 15px;
  background-color: var(--qrencia-dark-blue);
  padding: 1rem 0rem 1.4rem 0rem;
  width: 48.4%;
}

.pricePlan1 {
  margin-bottom: 0.5rem;
}

.pricePlan1 svg {
  font-size: 3.7rem;
  color: var(--qrencia-white);
  margin-bottom: 0.5rem;
}

.pricePlan2 svg {
  font-size: 3.7rem;
  color: var(--qrencia-white);
  margin-bottom: 0.5rem;
}

.pricePlan2 {
  margin-bottom: 0.5rem;
}

.signUpButton {
  background: var(--qrencia-dark-blue);
  border: 1px solid var(--qrencia-dark-blue);
  border-radius: 4px;
  color: var(--qrencia-white);
  font-size: 0.7em;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 1.5% 8%;
  text-decoration: none;
}

.mambershipCard {
  text-align: center;
  border-radius: 15px;
  position: relative;
}

.plus {
  padding-top: 1px;
  font-size: 1.6rem;
  color: var(--qrencia-dark-blue);
  font-weight: 500 !important;
}

.plusCircle {
  display: inline-block;
  background: var(--qrencia-blue-background);
  height: 40px;
  color: var(--qrencia-white);
  width: 40px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  transform: translate(-55%, 195%);
}

.price {
  color: var(--qrencia-white);
  font-weight: 700;
  font-size: 2.5rem;
  margin-right: 0.1rem;
}

.priceSuffix {
  color: var(--qrencia-white);
  font-size: 0.8rem;
  font-weight: 300 !important;
}

.membershipButton {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  background-color: var(--qrencia-white);
  color: var(--qrencia-grey-text-color);
  margin-bottom: 1rem;
}

.cardDesc {
  color: var(--qrencia-white);
  font-size: 0.8rem;
  font-weight: 200 !important;
}

.cardDesc span {
  color: var(--qrencia-white);
  font-size: 0.7rem;
  font-weight: 700 !important;
}

.priceIcon {
  width: 25%;
  margin-top: -0.7rem;
  margin-bottom: -0.8rem;
}

@media (max-width: 1400px) {
  .pricingDescription {
    padding: 9rem 1rem 1rem 1rem;
  }
}

@media (max-width: 1333px) {
  .sectionDesc {
    font-size: 1.9rem;
  }
}

@media (min-width: 1290px) {
  .priceCard:first-child {
    margin-right: 1rem;
  }
}

@media (max-width: 1246px) {
  .pricingDescription {
    padding: 8rem 1rem 1rem 1rem;
  }
}

@media (max-width: 1200px) {
  .priceCard:first-child {
    margin-right: 1.1rem;
  }
}

@media (max-width: 1141px) {
  .sectionDesc {
    font-size: 1.8rem;
  }
  .pricingDescription {
    padding: 7rem 1rem 1rem 1rem;
  }
}

@media (max-width: 1110px) {
  .priceCard:first-child {
    margin-right: 1rem;
  }

  .membershipButton {
    font-size: 0.8rem;
  }

  .cardDesc {
    font-size: 0.7rem;
  }

  .pricingDescription p {
    margin-bottom: 2rem;
  }
}

@media (max-width: 1091px) {
  .sectionDesc {
    font-size: 1.7rem;
  }
}

@media (max-width: 1080px) {
  .plusCircle {
    transform: translate(-55%, 180%);
  }
}

@media (max-width: 1067px) {
  .priceCard:first-child {
    margin-right: 1rem;
  }

  .membershipButton {
    font-size: 0.8rem;
  }
}

@media (max-width: 1040px) {
  .sectionDesc {
    font-size: 1.6rem;
  }
  .pricingDescription {
    padding: 6rem 1rem 1rem 1rem;
  }
}

@media (max-width: 1020px) {
  .priceCard:first-child {
    margin-right: 0.8rem;
  }

  .pricingDescription {
    font-size: 1.3rem;
    padding: 5.5rem 1rem 1rem 1rem;
  }

  .pricingContent {
    font-size: 0.9em;
  }

  .pricingCard {
    padding: 1.7rem 1rem 2rem 1rem;
    background-color: var(--qrencia-blue-background);
    border-radius: 6px;
  }
}

@media (max-width: 973px) {
  .sectionDesc {
    font-size: 1.5rem;
  }

  .pricingContent {
    font-size: 0.85em;
  }
}

@media (max-width: 950px) {
  .cardDesc {
    font-size: 0.6rem;
    padding: 0 1em;
  }
}

@media (max-width: 934px) {
  .plusCircle {
    transform: translate(-55%, 150%);
  }
}

@media (max-width: 921px) {
  .sectionDesc {
    font-size: 1.4rem;
  }

  .pricingDescription {
    padding: 4.5rem 1rem 1rem 1rem;
  }

  .pricingContent {
    font-size: 0.8em;
  }
}

@media (max-width: 990px) {
  .membershipButton {
    font-size: 0.7rem;
  }
}

@media (max-width: 920px) {
  .membershipButton {
    font-size: 0.6rem;
  }
}

@media (max-width: 871px) {
  .sectionDesc {
    font-size: 1.3rem;
  }

  .pricingDescription {
    padding: 4.5rem 1rem 1rem 1rem;
  }

  .pricingContent {
    font-size: 0.75em;
  }
}
@media (max-width: 841px) {
  .pricingContent {
    font-size: 0.85em;
  }
  .pricingDescription {
    padding: 4rem 1rem 1rem 1rem;
  }
}

@media (max-width: 840px) {
  .priceCard:first-child {
    margin-right: 0.7rem;
  }

  .pricingDescription {
    font-size: 1.1rem;
  }

  .signUpButton {
    padding: 2.5% 8%;
  }
}

@media (max-width: 821px) {
  .sectionDesc {
    font-size: 1.2rem;
  }
}
@media (max-width: 800px) {
  .pricingContent {
    font-size: 0.8em;
  }
}

@media (max-width: 768px) {
  .sectionDesc {
    font-size: 1.7rem;
    margin-bottom: 0.5rem !important;
  }

  .pricingDescription {
    padding: 4.5rem 1rem 1rem 1rem;
  }

  .pricingDescription p {
    margin-bottom: 1rem;
  }

  .pricingContent {
    font-size: 1.1rem !important;
  }
  .signUpButton {
    font-size: 0.6em;
  }
}

@media (max-width: 769px) {
  .sectionDesc {
    margin-bottom: 0rem;
    font-size: 1.7rem;
  }

  .pricingDescription {
    font-size: 1.3rem;
    padding: 0.2rem 1rem 1.2rem 1rem;
    text-align: center;
  }

  .pricingDescription p {
    /* margin-bottom: 1.4rem; */
    font-size: 1.7rem;
  }

  .signUpButton {
    padding: 1.3% 8%;
  }

  .plusCircle {
    transform: translate(-62%, 200%);
  }

  .cardDesc {
    font-size: 0.7rem;
    padding: 0 1em;
  }
}

@media (max-width: 700px) {
  .pricingContent {
    font-size: 1rem !important;
  }
}

@media (max-width: 570px) {
  .sectionDesc {
    margin-bottom: 0rem;
    font-size: 1.5rem !important;
  }

  .pricingDescription {
    font-size: 1.3rem;
    padding: 0.4rem 0rem 1.4rem 0rem;
    text-align: center;
  }

  .pricingContent {
    font-size: 0.9rem !important;
  }

  .pricingDescription p {
    margin-bottom: 0.9rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 570px) {
  .priceCard {
    width: 100%;
    margin-bottom: 20px;
  }

  .membershipButton {
    font-size: 0.9rem;
  }

  .price {
    font-size: 2.9rem;
  }

  .cardDesc {
    font-size: 0.8rem;
  }

  .pricingCard {
    padding: 1.5rem 0rem 1.5rem 0rem;
  }

  .plusCircle {
    transform: translate(-62%, 675%);
  }
}

@media (max-width: 541px) {
  .plusCircle {
    transform: translate(-61%, 646%);
  }
}

@media (max-width: 530px) {
  .plusCircle {
    transform: translate(-62%, 645%);
  }
}
@media (max-width: 511px) {
  .plusCircle {
    transform: translate(-62%, 634%);
  }
}

@media (max-width: 520px) {
  .sectionDesc {
    margin-bottom: 0rem;
    font-size: 1.6rem;
  }
}

@media (max-width: 484px) {
  .priceCard:first-child {
    margin-right: 0.6rem;
  }

  .price {
    font-size: 2.9rem;
  }

  .sectionDesc {
    margin-bottom: 0rem;
    font-size: 1.6rem;
  }

  .plusCircle {
    transform: translate(-62%, 615%);
  }
}

@media (max-width: 475px) {
  .sectionDesc {
    margin-bottom: 0rem;
    font-size: 1.5rem;
  }
}

@media (max-width: 461px) {
  .plusCircle {
    transform: translate(-62%, 605%);
  }
}

@media (max-width: 446px) {
  .plusCircle {
    transform: translate(-62%, 595%);
  }
}

@media (max-width: 430px) {
  .priceCard:first-child {
    margin-right: 0.4rem;
  }

  .sectionDesc {
    font-size: 1.3rem;
  }

  .pricingDescription {
    font-size: 1.3rem;
    padding: 1rem 0.6rem 1.4rem 0.6rem;
    text-align: center;
  }

  .plusCircle {
    transform: translate(-62%, 585%);
  }
}

@media (max-width: 409px) {
  .plusCircle {
    transform: translate(-62%, 575%);
  }
}
@media (max-width: 391px) {
  .plusCircle {
    transform: translate(-62%, 565%);
  }
}
@media (max-width: 366px) {
  .plusCircle {
    transform: translate(-62%, 550%);
  }
}
@media (max-width: 364px) {
  .plusCircle {
    transform: translate(-62%, 595%);
  }
}
@media (max-width: 346px) {
  .plusCircle {
    transform: translate(-62%, 585%);
  }
}
@media (max-width: 321px) {
  .plusCircle {
    transform: translate(-62%, 623%);
  }
}
