.studentSkillCategoryTabs .nav-tabs {
  background: var(--qrencia-white);
  border-radius: 8px;
  border: 1px solid var(--qrencia-tabs-border);
  padding: 0.5% 1% 0.5% 0%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  justify-content: space-around;
}

.studentSkillCategoryTabs .nav-tabs,
.studentSkillCategoryTabs .nav-link {
  font-size: 0.9em !important;
  margin-bottom: -5.5px;
  color: var(--qrencia-tabs-inactive);
  font-weight: 600 !important;
  width: max-content;
}

.studentSkillCategoryTabs .nav-fill,
.studentSkillCategoryTabs .nav-justified .nav-item .nav-link {
  width: auto;
}

.studentSkillCategoryTabs .nav-fill .nav-item {
  padding-left: 3%;
  padding-right: 3%;
}

.studentSkillCategoryTabs .nav-tabs,
.studentSkillCategoryTabs .nav-link.active {
  font-size: 0.9em;
  color: var(--qrencia-text-black);
  font-weight: 600 !important;
  border-color: transparent;
  background-color: transparent;
  border-bottom: 4px solid var(--qrencia-text-black) !important;
}

.studentSkillCategoryTabs .nav-tabs .nav-link:hover,
.studentSkillCategoryTabs .nav-tabs .nav-link:focus,
.studentSkillCategoryTabs .nav-item:hover,
.studentSkillCategoryTabs .nav-item:focus {
  border-color: transparent;
}

.studentSkillCategoryTabs .nav-link {
  padding-left: 0.2% !important;
  padding-right: 0.2% !important;
}

@media (min-width: 1130px) and (max-width: 1160px) {
  .studentSkillCategoryTabs .nav-tabs .nav-link {
    font-size: 0.8em;
  }

  .studentSkillCategoryTabs .nav-tabs .nav-link.active {
    font-size: 0.8em;
  }

  .studentSkillCategoryTabs .nav-fill .nav-item {
    padding-left: 1% !important;
    padding-right: 1% !important;
  }
}

@media (min-width: 990px) and (max-width: 1130px) {
  .studentSkillCategoryTabs .nav-tabs,
  .studentSkillCategoryTabs .nav-link {
    font-size: 0.8em;
  }

  .studentSkillCategoryTabs .nav-tabs,
  .studentSkillCategoryTabs .nav-link.active {
    font-size: 0.8em;
  }

  .studentSkillCategoryTabs .nav-fill,
  .studentSkillCategoryTabs .nav-item {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
}

@media (max-width: 990px) {
  .studentSkillCategoryTabs .nav-fill .nav-item {
    padding-left: 1%;
    padding-right: 1%;
  }

  .studentSkillCategoryTabs .nav-tabs,
  .studentSkillCategoryTabs .nav-link {
    font-size: 0.75em !important;
  }

  .studentSkillCategoryTabs .nav-tabs,
  .studentSkillCategoryTabs .nav-link.active {
    font-size: 0.75em;
    margin-bottom: -3px;
  }

  .studentSkillCategoryTabs .nav-tabs {
    margin-bottom: 4%;
  }
}

@media (max-width: 480px) {
  .studentSkillCategoryTabs .nav-tabs {
    margin-bottom: 8%;
  }

  .studentSkillCategoryTabs .nav-tabs,
  .studentSkillCategoryTabs .nav-link.active {
    border-bottom: 3px solid var(--qrencia-text-black) !important;
  }

  .studentSkillCategoryTabs .nav-tabs,
  .studentSkillCategoryTabs .nav-link {
    font-size: 0.6em !important;
  }

  .studentSkillCategoryTabs .nav-tabs,
  .studentSkillCategoryTabs .nav-link.active {
    font-size: 0.6em !important;
  }

  .studentSkillCategoryTabs .nav-tabs,
  .studentSkillCategoryTabs .nav-link {
    margin-bottom: -2px !important;
  }
}
@media (max-width: 380px) {
  .studentSkillCategoryTabs .nav-tabs {
    margin-bottom: 8%;
  }

  .studentSkillCategoryTabs .nav-tabs,
  .studentSkillCategoryTabs .nav-link.active {
    border-bottom: 3px solid var(--qrencia-text-black) !important;
  }

  .studentSkillCategoryTabs .nav-tabs,
  .studentSkillCategoryTabs .nav-link {
    font-size: 0.58em !important;
  }

  .studentSkillCategoryTabs .nav-tabs,
  .studentSkillCategoryTabs .nav-link.active {
    font-size: 0.58em !important;
  }

  .studentSkillCategoryTabs .nav-tabs,
  .studentSkillCategoryTabs .nav-link {
    margin-bottom: -2px !important;
  }
}

/* .studentSkillCategoryTabs .nav-fill .nav-item .nav-link,
.studentSkillCategoryTabs .nav-justified .nav-item .nav-link {
  width: auto;
  margin-left: auto !important;
  margin-right: auto !important;
  font-weight: 200 !important;
}

.studentSkillCategoryTabs .nav-tabs {
  margin: 0 0 0.8em 0;
}

@media (max-width: 480px) {
  .studentSkillCategoryTabs .nav-tabs .nav-link.active {
    font-size: 0.75em !important;
    font-weight: 600 !important;
  }
  .studentSkillCategoryTabs .nav-tabs .nav-link {
    font-size: 0.75em !important;
    font-weight: 600 !important;
  }
} */
