.tableSection {
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.9em;
}

.tableSection::-webkit-scrollbar {
  display: none;
}

.criteriaHeading {
  font-size: 1.1em;
  margin-top: 0.8em;
  margin-bottom: 0.5em;
}

.tableRows {
  text-align: center;
}

.totalScoreHeading {
  font-weight: 600;
  font-size: 1.4em;
}

.tableData {
  min-width: 4em;
}

.disabledHeader {
  background-color: var(--qrencia-disabled-input-bg) !important;
}

.saveButton {
  margin-right: 1em;
}
.inputField {
  margin-left: 10% !important;
  margin-right: 10% !important;
}
@media (max-width: 580px) {
  .inputField {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
}
