.footerSection {
  background: var(--qrencia-dark-blue);
  color: var(--qrencia-white);
}

.footerContentSection {
  display: flex;
  padding: 4.5% 13% 2% 13%;
}

.footerSocialMediaLinkSection {
  padding: 0% 13% 1% 13%;
  display: flex;
}

.footerLowerSections {
  width: 33%;
  font-size: 0.75em;
  margin-top: 0.5%;
}

.watchNowBtn {
  background-color: var(--qrencia-beryls-count-purple);
  border: 1px solid var(--qrencia-beryls-count-purple);
  border-radius: 4px;
  text-align: center;
  font-weight: 300;
  font-size: 0.9rem;
  padding: 4.5% 15%;
  color: var(--qrencia-white);
}

.footerIconsLowerSections {
  width: 25%;
  display: flex;
  justify-content: flex-end;
  margin-right: 3%;
}

.socialLinkIcons {
  width: 90%;
  text-align: right;
  width: 25px;
  height: 25px;
}

.socialLinkIconSection {
  margin-left: 3%;
  margin-right: 3%;
}

.footerTermsPrivacyLowerSections {
  width: 42%;
  text-align: center;
  padding-right: 0%;
}

.footerTermsPrivacyLink,
.footerTermsPrivacyLink:hover {
  color: var(--qrencia-white);
  font-size: 0.75em;
  text-decoration: none;
}

.addressSection {
  width: 28%;
}

.externalLinksSection {
  width: 72%;
}

.qrenciaLogo {
  width: 45%;
}

.addressContentSection {
  margin-top: 6%;
}

.addressContent {
  font-size: 0.75em;
  line-height: 1.375em;
  font-weight: 400;
}

.externalLinksSection {
  display: flex;
  padding-left: 4%;
  padding-top: 4%;
}

.externalLinkListSection {
  width: 25%;
  text-align: left;
  padding-left: 3%;
}

.externalLinkListContactSection {
  width: 27%;
  text-align: left;
  padding-left: 4dvh;
}

.externalLinkTitle {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 12%;
}

.externalLinkListElements {
  font-size: 0.75em;
  word-break: break-all;
  line-height: 1.375em;
  text-align: left;
  margin-bottom: 10%;
  cursor: pointer;
}

@media (max-width: 990px) {
  .externalLinkTitle {
    font-size: 0.75em;
  }

  .externalLinkListElements {
    font-size: 0.65em;
    font-weight: 400;
  }

  .addressContent {
    font-size: 0.65em;
  }

  .watchNowBtn {
    padding: 4.5% 10%;
    font-size: 0.7rem;
  }

  .externalLinkListSection {
    width: 25%;
    text-align: left;
    margin-left: 1%;
  }

  .externalLinkListContactSection {
    width: 30%;
    text-align: left;
  }

  .footerLowerSections {
    width: 35%;
    font-size: 0.65em;
    margin-top: 1%;
  }

  .footerTermsPrivacyLink,
  .footerTermsPrivacyLink:hover {
    font-size: 0.65em;
  }

  .footerTermsPrivacyLowerSections {
    width: 50%;
  }

  .footerIconsLowerSections {
    width: 20%;
  }

  .socialLinkIcons {
    width: 90%;
    text-align: right;
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 1178px) {
  .watchNowBtn {
    padding: 4.5% 11%;
  }
}

@media (min-width: 768px) and (max-width: 783px) {
  .externalLinkListElements {
    font-size: 0.64em !important;
  }
}

@media (min-width: 849px) and (max-width: 856px) {
  .externalLinkListElements {
    font-size: 0.64em !important;
  }
}

@media (min-width: 767px) and (max-width: 1290px) {
  .footerContentSection {
    padding: 4.5% 9% 2% 9%;
  }

  .footerSocialMediaLinkSection {
    padding: 0% 9% 1.5% 9%;
  }

  .socialLinkIconSection {
    margin-right: 1%;
  }

  .footerIconsLowerSections {
    margin-right: 0%;
  }
}

@media (min-width: 767px) and (max-width: 1200px) {
  .socialLinkIconSection {
    margin-right: 0%;
  }

  .footerIconsLowerSections {
    margin-right: 0%;
  }
}

@media (min-width: 1000px) and (max-width: 1127px) {
  .externalLinkListSection {
    padding-left: 4% !important;
  }

  .externalLinkListContactSection {
    padding-left: 5% !important;
  }
}

@media (max-width: 767px) {
  .watchNowBtn {
    padding: 0.8% 2.9%;
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
  }
}

@media (min-width: 850px) and (max-width: 1000px) {
  .externalLinkListSection {
    padding-left: 4% !important;
  }

  .externalLinkListContactSection {
    padding-left: 7% !important;
  }
}

@media (min-width: 767px) and (max-width: 850px) {
  .externalLinkListSection {
    padding-left: 2% !important;
  }

  .externalLinkListContactSection {
    padding-left: 5% !important;
  }
}

@media (min-width: 1128px) and (max-width: 1290px) {
  .externalLinkListSection {
    padding-left: 5%;
  }

  .externalLinkListContactSection {
    padding-left: 5%;
  }
}

@media (min-width: 990px) and (max-width: 1000px) {
  .externalLinkListContactSection {
    padding-left: 5.8% !important;
  }
}

@media (min-width: 767px) and (max-width: 1127px) {
  .externalLinkListSection {
    padding-left: 0%;
  }

  .externalLinkListContactSection {
    padding-left: 5%;
  }

  .watchNowBtn {
    padding: 4.5% 10%;
  }
}

@media (min-width: 780px) and (max-width: 1127px) {
  .watchNowBtn {
    padding: 4.5% 11%;
    font-size: 0.8rem;
  }
}

@media (max-width: 1003px) {
  .externalLinkListSection {
    padding-left: 2% !important;
  }
}

@media (max-width: 780px) {
  .externalLinkListSection {
    padding-left: 0% !important;
  }
}

@media (max-width: 767.5px) {
  .footerContentSection {
    padding: 2.5% 3% 0% 3%;
    display: block;
  }

  .qrenciaLogo {
    width: 21%;
  }

  .footerSocialMediaLinkSection {
    padding: 0% 3% 4% 3%;
  }

  .externalLinkListSection {
    width: 100%;
    text-align: center;
  }

  .externalLinkListContactSection {
    width: 100%;
    text-align: center;
    padding: 0;
  }

  .externalLinkTitle {
    margin-bottom: 2%;
  }

  .addressSection {
    width: 100%;
    text-align: center;
  }

  .externalLinksSection {
    width: 100%;
    padding-left: 0%;
    margin-left: 0em;
    display: inline-block;
    text-align: center;
    text-align: -webkit-center;
  }

  .qrenciaLogo {
    width: 20%;
  }

  .externalLinkListElements {
    text-align: center;
    margin-bottom: 2%;
  }

  .footerLowerSections {
    width: 100%;
    text-align: center;
    font-size: 0.65em;
    margin-top: 0.5%;
  }

  .footerTermsPrivacyLink,
  .footerTermsPrivacyLink:hover {
    font-size: 0.65em;
  }

  .footerTermsPrivacyLowerSections {
    width: 100%;
    margin-top: 4%;
  }

  .footerSocialMediaLinkSection {
    display: block;
  }

  .footerIconsLowerSections {
    width: 100%;
    margin-top: 4%;
    display: flex;
    justify-content: center;
  }

  .socialLinkIconSection {
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
}

@media (min-width: 480px) and (max-width: 767.5px) {
  .footerTermsPrivacyLowerSections {
    width: 100%;
    margin-top: 2% !important;
  }

  .footerSocialMediaLinkSection {
    display: block;
  }

  .footerIconsLowerSections {
    width: 100%;
    margin-top: 2% !important;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .socialLinkIcons {
    width: 90%;
    text-align: right;
    width: 20px !important;
    height: 20px !important;
  }
}
@media (min-width: 780px) and (max-width: 783px) {
  .externalLinkListContactSection {
    padding-left: 4.8% !important;
  }
}
@media (min-width: 767px) and (max-width: 772px) {
  .externalLinkListContactSection {
    padding-left: 4.7% !important;
  }
}

@media (max-width: 550px) {
  .socialLinkIcons {
    width: 90%;
    text-align: right;
    width: 22px !important;
    height: 22px !important;
  }
}

@media (min-width: 990px) and (max-width: 1100px) {
  .footerTermsPrivacyLowerSections {
    padding-right: 1% !important;
    text-align: end;
  }
}

@media (min-width: 830px) and (max-width: 990px) {
  .footerTermsPrivacyLowerSections {
    padding-right: 6% !important;
    text-align: center;
  }
}

@media (min-width: 767px) and (max-width: 830px) {
  .footerTermsPrivacyLowerSections {
    padding-right: 1% !important;
    text-align: center;
  }
}

@media (min-width: 990px) and (max-width: 1485px) {
  .externalLinkTitle {
    font-size: 0.86em;
  }
}
