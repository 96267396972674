.searchFormSection {
  margin: -4% 18% 2% 18%;
  background-color: var(--qrencia-dark-blue);
  position: relative;
  border-radius: 12px;
  padding: 2% 3% 2.5% 3%;
}
.searchButton {
  display: block;
}

.searchIcon {
  display: none;
}
.formHeading {
  font-size: 1.4em;
  color: var(--qrencia-white);
  font-weight: 600;
  margin-top: 0.5%;
  margin-bottom: 2%;
}

.searchFormElementsSection {
  display: flex;
  gap: 3%;
}

.formDropdown {
  width: 40%;
}
.formSearchButton {
  width: 20%;
}

.searchFormField {
  font-size: 0.9em;
  font-weight: 400;
  background-color: var(--qrencia-white);
  padding: 2.3% !important;
}

.typeaheadFormField input,
.typeaheadFormField input:focus,
.typeaheadFormField input:active,
.typeaheadFormField input.visited {
  font-size: 0.9em !important;
  font-weight: 400 !important;
  background-color: var(--qrencia-white);
  padding: 2.3% 2.1% !important;
}

.typeaheadFormField input:focus,
.typeaheadFormField input:active,
.typeaheadFormField input.visited {
  font-size: 0.9em !important;
  font-weight: 400 !important;
  background-color: var(--qrencia-white) !important;
  padding: 2.3% 2.1% !important;
}

.typeaheadFormField input::placeholder {
  color: var(--qrencia-text-black);
}

.skillErrorMessage {
  font-size: 0.8em;
  color: var(--qrencia-white);
  text-align: left;
}

.searchButton,
.searchButton:hover,
.searchButton:active,
.searchButton:focus {
  padding: 5% 33% !important;
  line-height: 1.38;
  font-size: 0.9em;
  font-weight: 500;
  border: 1px solid var(--qrencia-white) !important;
  text-align: center;
  border-radius: 4px;
}

.noCompetitionsForSearchErrorSection {
  text-align: center;
  margin-top: 3%;
}

.noCompetitionsErrorTitle {
  font-size: 1.25em;
  line-height: 1.4em;
  font-weight: 600;
  color: var(--qrencia-dark-blue);
  margin-bottom: 0.5%;
}

.noCompetitionsErrorDescription {
  font-size: 0.8em;
  color: var(--qrencia-text-black);
  font-weight: 500 !important;
  margin-bottom: 1%;
}

.skillUrl {
  color: var(--qrencia-dark-blue);
  font-weight: 600;
}

.skillUrl:hover,
.skillUrl.active {
  color: var(--qrencia-dark-blue);
  font-weight: 600;
}

@media (max-width: 1400px) {
  .searchFormSection {
    /* margin: -2.5% 18% 2% 18%; */
  }
}
@media (max-width: 1346px) {
  .searchFormSection {
    /* margin: -3.5% 18% 2% 18%; */
  }
}

@media (max-width: 1290px) {
  .noCompetitionsErrorTitle {
    font-size: 1.1em;
  }
  .searchFormSection {
    /* margin: -4.5% 18% 2% 18%; */
  }
}

@media (max-width: 1120px) {
  .searchFormSection {
    /* margin: -5% 18% 2% 18%; */
  }
}

@media (min-width: 1140px) and (max-width: 1290px) {
  .searchFormField {
    font-size: 0.88em;
  }

  .typeaheadFormField input,
  .typeaheadFormField input:focus,
  .typeaheadFormField input:active,
  .typeaheadFormField input.visited {
    font-size: 0.88em !important;
  }

  .typeaheadFormField input:focus,
  .typeaheadFormField input:active,
  .typeaheadFormField input.visited {
    font-size: 0.88em !important;
  }

  .searchButton,
  .searchButton:hover,
  .searchButton:active,
  .searchButton:focus {
    padding: 4% 30% !important;
  }
}

@media (min-width: 900px) and (max-width: 1140px) {
  .searchFormField {
    font-size: 0.8em;
  }

  .typeaheadFormField input,
  .typeaheadFormField input:focus,
  .typeaheadFormField input:active,
  .typeaheadFormField input.visited {
    font-size: 0.8em !important;
    padding: 2.2% !important;
  }

  .typeaheadFormField input:focus,
  .typeaheadFormField input:active,
  .typeaheadFormField input.visited {
    font-size: 0.8em !important;
    padding: 2.2% !important;
  }

  .searchButton,
  .searchButton:hover,
  .searchButton:active,
  .searchButton:focus {
    padding: 4% 25% !important;
  }
}

@media (max-width: 1030px) {
  .searchFormSection {
    /* margin: -8% 18% 2% 18%; */
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .searchFormField {
    font-size: 0.8em;
  }

  .typeaheadFormField input,
  .typeaheadFormField input:focus,
  .typeaheadFormField input:active,
  .typeaheadFormField input.visited {
    font-size: 0.8em !important;
    padding: 2.3% !important;
  }

  .typeaheadFormField input:focus,
  .typeaheadFormField input:active,
  .typeaheadFormField input.visited {
    font-size: 0.8em !important;
    padding: 2.3% !important;
  }

  .searchButton,
  .searchButton:hover,
  .searchButton:active,
  .searchButton:focus {
    padding: 5% 28% !important;
    font-size: 0.8em;
  }

  .searchFormSection {
    margin: -5% 10% 2% 10%;
    padding: 2.5% 3% 3% 3%;
  }
}

@media (max-width: 767.5px) {
  .noCompetitionsForSearchErrorSection {
    display: none;
  }
  .searchButton {
    display: none;
  }

  .searchIcon {
    display: inline;
    font-size: 0.9em;
    margin-top: -0.2em;
  }

  .searchFormElementsSection {
    display: flex;
    text-align: center;
    gap: 3%;
  }

  .searchFormSection {
    display: block;
    margin: -1% 4% 2% 4%;
    border-radius: 9px;
    padding: 4% 3% 4% 3%;
  }

  .searchFormField {
    font-size: 0.8em;
    margin-bottom: 3%;
    padding: 1.5% !important;
  }

  .typeaheadFormField input,
  .typeaheadFormField input:focus,
  .typeaheadFormField input:active,
  .typeaheadFormField input.visited {
    font-size: 0.8em !important;
    padding: 1.6% 1.5% !important;
  }

  .typeaheadFormField input:focus,
  .typeaheadFormField input:active,
  .typeaheadFormField input.visited {
    font-size: 0.8em !important;
    padding: 1.6% 1.5% !important;
  }

  .formHeading {
    text-align: left;
  }

  .searchButton,
  .searchButton:hover,
  .searchButton:active,
  .searchButton:focus {
    padding: 1% 2% !important;
    font-size: 0.8em;
    width: 25%;
  }

  .formDropdown {
    width: 45%;
  }

  .formSearchButton {
    width: 25px;
    height: 25px;
    background-color: var(--qrencia-white);
    border-radius: 50%;
    margin-top: 0.12em;
    cursor: pointer;
  }
  .formSearchButton > svg {
    margin-left: auto;
    margin-right: auto;
  }

  .noCompetitionsErrorTitle {
    font-size: 1em;
    margin-top: 10%;
  }
}

@media (max-width: 480px) {
  .searchFormField {
    font-size: 0.65em !important;
    margin-top: 0% !important;
    margin-bottom: 5% !important;
    padding: 0.2% 1% !important;
    padding-left: 1% !important;
  }

  .typeaheadFormField input,
  .typeaheadFormField input:focus,
  .typeaheadFormField input:active,
  .typeaheadFormField input.visited {
    font-size: 0.65em !important;
    padding: 0.2% 1% !important;
  }

  .typeaheadFormField input:focus,
  .typeaheadFormField input:active,
  .typeaheadFormField input.visited {
    font-size: 0.65em !important;
    padding: 0.2% 1% !important;
  }

  .formHeading {
    text-align: center;
    margin-bottom: 3%;
  }

  .searchButton,
  .searchButton:hover,
  .searchButton:active,
  .searchButton:focus {
    padding: 0.3% 0% !important;
  }

  .formSearchButton {
    width: 23px;
    height: 22px;
    background-color: var(--qrencia-white);
    border-radius: 50%;
    margin-top: -0.1em;
    cursor: pointer;
  }
  .formSearchButton > svg {
    margin-left: auto;
    margin-right: auto;
  }
  .skillErrorMessage {
    font-size: 0.6em;
  }
  .searchIcon {
    display: inline;
    font-size: 0.9em;
    margin-top: -0.3em;
  }
}
@media (min-width: 990px) and (max-width: 1185px) {
  .formHeading {
    font-size: 1.1em;
  }
}
@media (min-width: 767px) and (max-width: 990px) {
  .formHeading {
    font-size: 0.9em;
  }
}
@media (max-width: 767px) {
  .formHeading {
    font-size: 1.1em;
    text-align: center;
  }
}
@media (min-width: 480px) and (max-width: 540px) {
  .formHeading {
    font-size: 1em;
  }
}
@media (max-width: 480px) {
  .formHeading {
    font-size: 1em !important;
  }
}
@media (max-width: 420px) {
  .searchFormField {
    font-size: 0.6em !important;
  }
  .formSearchButton {
    width: 20px;
    height: 20px;
    background-color: var(--qrencia-white);
    border-radius: 50%;
    margin-top: -0.1em;
    cursor: pointer;
  }
  .formSearchButton > svg {
    margin-left: auto;
    margin-right: auto;
    font-size: 0.7em;
    margin-top: -7px;
  }
  .searchFormField {
    font-size: 0.6em !important;
  }

  .typeaheadFormField input,
  .typeaheadFormField input:focus,
  .typeaheadFormField input:active,
  .typeaheadFormField input.visited {
    font-size: 0.6em !important;
  }
}
