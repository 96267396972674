.formContainer {
  width: 98%;
  margin: 0.6em auto 2em auto;
  position: relative;
}
.formLabelInput {
  margin: 0.3em 0em;
  width: 100%;
}
.basicDetailsLabels {
  font-weight: 550;
  font-size: 0.9em;
}
.schoolName {
  font-weight: 500;
  font-size: 0.75em;
}
.description {
  resize: none;
  height: 6em !important;
}
.removeIcon {
  font-size: 0.7em;
  margin-left: 1em;
  width: 2em;
}
.removeIcon > svg {
  font-size: 2em;
  cursor: pointer;
}
.remainingChars {
  font-size: 0.7em;
  font-weight: 500 !important;
}
.basicDetailsInputs {
  height: 2.8em;
  font-size: 0.8em;
  background-color: var(--qrencia-select-box-background);
  border: solid 1px var(--qrencia-select-box-border);
}
.defaultAiImageState {
  text-align: center;
}
.defaultAiImageState > img {
  width: 90%;
}
.errorText {
  font-size: 0.75em;
  color: var(--qrencia-error-red);
}
.textEditorContainer {
  height: 100%;
}
.radioLabel {
  font-weight: 500;
  font-size: 0.85em;
  background-color: var(--qrencia-white) !important;
  margin-left: 6px;
  padding: 0em 0.3em 0em 0em;
  z-index: 1;
  width: 100%;
}
.aiGenerateImageText {
  font-weight: 500;
  font-size: 0.85em;
  background-color: var(--qrencia-white) !important;
  margin-left: 6px;
  padding: 0em 0.3em 0em 0em;
  z-index: 1;
  width: 100%;
  text-align: center;
}
.radioLabelButton {
  font-weight: 500;
  font-size: 0.85em;
  background-color: var(--qrencia-white) !important;
  margin-left: 6px;
  padding: 0em 0.3em 0em 0em;
  z-index: 1;
  width: 5.5em;
}
.radioDiv {
  display: flex;
  width: 100%;
}
.formcheck input {
  padding: 7px !important;
  cursor: pointer;
  font-size: 0;
  margin-top: 2px;
}
.formcheck label {
  margin-left: 4px;
}
.uploadFileRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.uploadFileRow div {
  flex: 9;
}

.textEditorInactive {
  cursor: not-allowed;
  opacity: 0.5;
}
.textEditor {
  height: 60%;
  margin-top: -18px;
  font-size: 1.2em;
}
.textEditorLabel {
  background: var(--qrencia-white) !important;
  border: none;
  outline: none;
}
.removeBtn {
  outline: none;
  background-color: var(--qrencia-white);
  border: none;
  color: var(--qrencia-white);
  margin-left: 0.5em;
  padding: 0.6em 0.2em;
  border-radius: 0.3em;
  width: 2em;
}
.removeBtn > svg {
  font-size: 1.2em;
  color: var(--qrencia-faq-icon-color);
}
.removeBtnInactive > svg {
  color: #b5b5b5 !important;
}
.tooltipMessage {
  background-color: var(--qrencia-carousel-blue-image-border);
  font-weight: 500 !important;
  font-size: 0.9em;
  transition: all 0.1s ease;
  outline: none;
  border: none;
  z-index: 10;
  max-width: 32em;
}
.nextBtn {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  width: 100%;
  padding: 0.6em;
  text-align: right;
}

.nextBtn > Button {
  font-size: 0.8em;
  padding: 0.6em 2em;
}
.registrationDetails {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.registrationDetailsLabel {
  margin: auto 1em auto 0em;
  width: fit-content;
  line-height: auto;
}
.registrationDetailsInput {
  flex: 0.1;
}
.separatorLine {
  position: relative;
  max-width: 90%;
  margin: 0.5em auto;
  text-align: center;
  font-size: 1.1em;
}
.separatorLine:before {
  content: " ";
  height: 2px;
  width: 45%;
  background: var(--qrencia-dark-blue);
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}
.separatorLine:after {
  content: " ";
  height: 2px;
  width: 45%;
  background: var(--qrencia-dark-blue);
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}
/* .switchContainer {
  margin-top: 0.5em;
  margin-left: 1em;
  margin-right: 1.1em;
  margin-bottom: 0.8em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.switchText {
  margin-right: 1em;
  font-size: 0.85em;
  width: fit-content;
  margin-top: 1em;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.switchText > svg {
  margin-left: 0.2em;
}
.switchInput {
  margin-left: 1em;
  margin-top: 0.4em;
} */
.switchContainer {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.switchText {
  font-weight: 500;
  margin-right: 1em;
  font-size: 0.8em;
  width: fit-content;
  display: flex;
  align-items: center;
}
.switchText input {
  margin-left: 0.8em;
  margin-top: 0.3em;
}
.switchContainer > label {
  line-height: 1em;
}
.infoButton {
  font-size: 1em;
  color: var(--qrencia-dark-blue) !important;
}

.thumbnailPreviewImage {
  width: 40%;
}
.webPreviewImage {
  width: 70%;
}
.tabPreviewImage {
  width: 50%;
}
.mobilePreviewImage {
  width: 30%;
}
.previewContainer {
  margin-top: 0.8em;
  margin-bottom: 0.6em;
}

.previewContainer > img {
  border: 1px solid var(--qrencia-certificate-preview-border);
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.generateImageBtn {
  font-size: 0.74em;
  text-align: center;
}
.generateImageBtn button {
  background-color: var(--qrencia-beryls-count-purple);
  border: none;
  outline: none;
}
.generateImageBtn button:hover,
.generateImageBtn button:active {
  background-color: var(--qrencia-beryls-count-purple) !important;
  border: none;
  outline: none;
}
.modal {
  outline: none !important;
  border: none !important;
  border-radius: 0px !important;
}
.modalBody {
  border-radius: 0px !important;
  text-align: center;
}
.modalText {
  font-size: 2em;
}
.modalText p {
  font-size: 0.5em;
}
.preLoaderDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrenciaGlobe {
  width: 80px;
  margin-top: -98px;
}

.loaderText {
  padding: 2em 0 0 0;
  font-size: 1em;
  text-align: center;
}

.loaderIcon {
  font-size: 6em !important;
  color: var(--qrencia-dark-blue) !important;
  text-align: center;
}

.spinner {
  animation: spin-animation 0.5s infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
@keyframes load {
  to {
    transform: rotate(1turn);
  }
}
.loader {
  --b: 8px;
  /* border thickness */
  --n: 12;
  /* number of dashes*/
  --g: 10deg;
  /* gap between dashes*/
  --c: var(--qrencia-dark-blue);
  /* the color */

  width: 120px;
  /* size */
  aspect-ratio: 1;
  border-radius: 100%;
  padding: 1px;
  background: conic-gradient(#0000, var(--c)) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: load 1s infinite steps(var(--n));
}

@media (min-width: 1500px) {
  .basicDetailsLabels {
    font-size: 0.9em;
  }
  .basicDetailsInputs {
    height: 2.8em;
    font-size: 0.8em;
  }
}

@media (min-width: 541px) and (max-width: 760px) {
  .formContainer {
    width: 100%;
  }
  .basicDetailsLabels {
    font-size: 0.8em;
  }
  .basicDetailsInputs {
    height: 2.8em;
    font-size: 0.7em;
  }
}

@media (min-width: 761px) and (max-width: 900px) {
  .basicDetailsLabels {
    font-size: 0.9em;
  }
  .basicDetailsInputs {
    height: 2.8em;
    font-size: 0.8em;
  }
  .separatorLine {
    font-size: 1em;
  }
}
@media (max-width: 990px) {
  .textEditor p {
    font-size: 1em;
  }
}
@media (min-width: 900px) and (max-width: 1500px) {
  .basicDetailsLabels {
    font-size: 0.9em;
  }
  .basicDetailsInputs {
    height: 2.8em;
    font-size: 0.8em;
  }
}
@media (max-width: 540px) {
  .basicDetailsLabels {
    font-size: 0.8em;
  }
  .basicDetailsInputs {
    height: 2.8em;
    font-size: 0.6em;
    margin-bottom: 0.2em;
    padding: 0em;
  }
  .errorText {
    font-size: 0.6em;
  }
  .nextBtn {
    margin-top: 1.5em;
    width: 100%;
    padding: 0.6em;
    text-align: center;
  }
  .nextBtn > Button {
    font-size: 0.8em;
    padding: 0.6em 2em;
  }
  .formContainer {
    width: 100%;
  }
  .uploadFileRow > input::-webkit-file-upload-button {
    font-weight: 600;
    border-radius: 5%;
    cursor: pointer;
    font-size: 0.6em;
    height: 2.4em;
  }
  .selectImageInput {
    font-size: 0.8em;
  }
  .separatorLine {
    font-size: 0.9em;
  }
}
@media (max-width: 480px) {
  .basicDetailsLabels {
    font-size: 0.7em;
  }
  .basicDetailsInputs {
    height: 3em;
    font-size: 0.6em;
    margin-bottom: 0.2em;
  }
  .errorText {
    font-size: 0.6em;
  }
  .nextBtn {
    margin-top: 1.5em;
    width: 100%;
    padding: 0.6em;
    text-align: center;
  }
  .nextBtn > Button {
    font-size: 0.8em;
    padding: 0.6em 2em;
  }
  .formContainer {
    width: 100%;
  }
  .selectImageInput {
    font-size: 0.8em;
  }
  .tooltipMessage {
    font-size: 0.8em;
    max-width: 28em;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .radioLabel {
    font-size: 0.8em;
  }
}
