.skillGardenSection {
  padding: 2% 0% 0% 0%;
  background-color: var(--qrencia-white);
  border-radius: 20px;
  margin-top: 1%;
}

.skillGardenImage {
  width: 100%;
  border-radius: 0px 0px 20px 20px;
}

.skillGardenHeading {
  color: var(--qrencia-text-black);
  font-weight: 700;
  font-size: 1.4em;
  margin-bottom: 1%;
  margin-left: 2%;
}

@media (max-width: 1290px) {
  .skillGardenHeading {
    font-size: 1.2em;
  }
}

@media (max-width: 767px) {
  .skillGardenHeading {
    font-size: 1.1em;
  }
}

@media (max-width: 437px) {
  .skillGardenSection {
    padding: 4% 0% 0% 0%;
  }
}