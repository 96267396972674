.preLoaderContainer {
  height: 100vh;
  width: 100%;
  position: relative;
}

.preLoaderDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrenciaGlobe {
  width: 80px;
  margin-top: -98px;
}

.loaderText {
  padding: 2em 0 0 0;
  font-size: 1em;
  text-align: center;
}

.loaderIcon {
  font-size: 6em !important;
  color: var(--qrencia-dark-blue) !important;
  text-align: center;
}

.loader {
  --b: 8px;
  /* border thickness */
  --n: 12;
  /* number of dashes*/
  --g: 10deg;
  /* gap between dashes*/
  --c: var(--qrencia-dark-blue);
  /* the color */

  width: 120px;
  /* size */
  aspect-ratio: 1;
  border-radius: 100%;
  padding: 1px;
  background: conic-gradient(#0000, var(--c)) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg calc(360deg / var(--n) - var(--g) - 1deg),
      #0000 calc(360deg / var(--n) - var(--g)) calc(360deg / var(--n))
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(98% - var(--b)),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: load 1s infinite steps(var(--n));
}

@keyframes load {
  to {
    transform: rotate(1turn);
  }
}
