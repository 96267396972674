.studentPassSection {
  padding: 1.7rem 1.5rem 1.7rem 1.5rem;
  background-color: var(--qrencia-blue-background);
  border-radius: 6px;
}

.studentPassSection h2 {
  color: var(--qrencia-dark-blue);
  font-weight: 700;
  font-size: 2rem;
}

.studentPassDescription {
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 0.6rem;
}

.studentPassPricing {
  color: var(--qrencia-grey-text-color);
  font-size: 1.6rem;
  font-weight: 700 !important;
}

.studentPassPricing span {
  color: var(--qrencia-grey-text-color);
  margin-left: 0.4rem;
  margin-right: 0.2rem;
  background-color: var(--qrencia-card-light-grey-background);
  padding: 0rem 0.4rem;
  font-size: 1.6rem;
}

.studentPassImage {
  width: 95%;
}

.studentPassImagecontent {
  text-align: center;
}

.studentPass2 {
  padding-right: 0;
}

.studentPassImageSection {
  display: flex;
  justify-content: center;
}

@media (max-width: 1120px) {
  .studentPassImage {
    width: 100%;
  }
}

@media (max-width: 1135px) {
  .studentPassDescription {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 1068px) {
  .studentPassDescription {
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
  }
}

@media (max-width: 1000px) {
  .studentPassSection {
    padding: 1.7rem 0rem 1.7rem 0rem;
  }
}

@media (max-width: 996px) {
  .studentPassDescription {
    font-size: 1.3rem;
  }

  .studentPassSection h2 {
    font-size: 2rem;
  }

  .studentPassPricing {
    font-size: 1.5rem;
  }

  .studentPassDescription {
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
  }
}

@media (max-width: 990px) {
  .studentPassSection h2 {
    font-size: 1.8rem;
  }

  .studentPassDescription {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .studentPassSection {
    text-align: center;
  }

  .studentPassImagecontent {
    padding-left: 0rem;
  }

  .studentPassSection h2 {
    font-size: 1.7rem;
  }

  .studentPassPricing {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .studentPassPricing span {
    font-size: 1.2rem;
  }

  .studentPassDescription {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  .studentPassImage {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .studentPassDescription {
    font-size: 1rem;
  }
}

@media (max-width: 580px) {
  .studentPassSection h2 {
    font-size: 1.5rem;
  }

  .studentPassDescription {
    font-size: 0.9rem;
  }

  .studentPassPricing {
    font-size: 1.1rem;
  }

  .studentPassPricing span {
    font-size: 1.1rem;
  }
}

@media (max-width: 580px) {
  .studentPassSection {
    padding: 1.7rem 0rem 1.7rem 0rem;
    background-color: var(--qrencia-blue-background);
    border-radius: 6px;
  }
}

@media (max-width: 540px) {
  .studentPassSection h2 {
    font-size: 1.5rem;
  }

  .studentPassDescription {
    font-size: 0.9rem;
  }

  .studentPassPricing {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  .studentPassPricing span {
    font-size: 1.1rem;
  }

  .studentPassImage {
    width: 100%;
  }
}

@media (max-width: 475px) {
  .studentPassDescription {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .studentPassPricing {
    font-size: 0.9rem;
  }

  .studentPassSection h2 {
    font-size: 1.3rem;
  }

  .studentPassImage {
    width: 100%;
  }
}

@media (max-width: 376px) {
  .studentPassSection h2 {
    font-size: 1.2rem;
  }
  .studentPassDescription {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
}
